//Funciones para gestionar las filas de las tablas sin hacer peticiones al
//servidor

export const addRow = (key, cells, setRows, disponible='') => {
    setRows(rows => ([...rows, 
        {
            key: key,
            cells: cells,
            disponible: disponible
        }
    ]))
}

export const findRow = (key, rows) => {
    if(key){
        return rows.find( row => (row.key === key) )
    }
}

export const deleteRow = (key, setRows) => {
    setRows(
        rows => {
            const withoutDeleted = rows.filter(
                (row) => {
                    return row.key !== key
                }
            )
            return [...withoutDeleted]
        }
    )
}

export const updateRow = (key, cells, setRows, disponible='') => {
    setRows(
        rows => {
            const indexToUpdate = rows.findIndex((row => row.key === key))
            if(indexToUpdate === -1){
                return rows
            }else{
                //Se copian los valores de rows a una variable para actualizarlos
                let updatedRows = [...rows]
                updatedRows[indexToUpdate].cells = cells
                return [...updatedRows]
            }
        }
    )
}