// import React from 'react'
import AppFrame from '../../components/AppFrame'
import { Link as RouterLink } from "react-router-dom";
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import DataTable from '../../components/DataTable'
import Alert from '../../components/Alert/Alert'
import Typography from '@material-ui/core/Typography'
import Progress from '../../components/Progress/Progress'
import DatePickerRange from '../../components/DatePickerRange/DatePickerRange'
import Pagination from '../../components/Pagination'
import useSolicitudesPage from '../../hooks/cotizacion/useSolicitudesPage';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
}))

const encabezado = ["Solicitud", "Institucion", "Estado", "Hora de ultima modificación"]

const SolicitudesPage = ({tipoSolicitud}) => {
    const classes = useStyles()
    const { data, actions } = useSolicitudesPage(tipoSolicitud)
    const { rows, alertOpen,
        alertSeverity, alertMessage, loading, numPages, page, value, idRol } = data
    const { setAlertOpen, handleSelectDate, handlePageChange,
        botonesDataTable, handleLimpiarFechas } = actions

    return (
        <AppFrame loged={true}>
            <Progress open={loading} setOpen={setAlertOpen} />
            {
                //1 es el id del permiso gestion ministerio
                //useCheckPermiso(1) ?
                <Grid container spacing={1}>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} md={10}>
                            <Paper className={classes.paper}>
                                <Alert open={alertOpen} setOpen={setAlertOpen}
                                    severity={alertSeverity}
                                    message={alertMessage} />

                                {/* Titulo y Boton*/}
                                <Grid className={classes.input} container direction="row"
                                    alignItems="center">
                                    <Grid container direction="row" justifyContent="flex-start"
                                        item xs={8}>
                                        <Typography className={classes.subtitulo} variant="h5">Solicitudes Realizadas</Typography>
                                    </Grid>
                                    {[1, 2, 100].find(n => n === tipoSolicitud) ? null :
                                        idRol === 8 ? // Rol del cliente
                                            <Grid container direction="row" justifyContent="center"
                                                alignItems="center" item xs={4}>
                                                <Grid item xs={8}>
                                                    <Button
                                                        component={RouterLink}
                                                        to={"/nueva_solicitud"}
                                                        variant="contained"
                                                        text="Crear Solicitud"
                                                    />
                                                </Grid>
                                            </Grid>
                                        : null
                                    }
                                </Grid>
                                {/*Codigo para los Combobox*/}
                                <Grid className={classes.input} container direction="row"
                                    alignItems="center" spacing={1} justifyContent="flex-end">
                                    <Grid container item xs={12} sm={4} md={3}>
                                        <DatePickerRange
                                            onCallback={handleSelectDate} value={value} />
                                    </Grid>
                                    <Grid item xs = {1}>
                                        <Button variant='outlined' text = 'Limpiar' func={handleLimpiarFechas}/>
                                    </Grid>
                                    <Grid item xs = {1}>

                                    </Grid>
                                </Grid>

                                {/*Codigo para la Tabla*/}
                                <Grid className={classes.input} item xs={12}>
                                    <DataTable headers={encabezado} rows={rows}
                                        customBtn={botonesDataTable(tipoSolicitud)}
                                    />
                                </Grid>
                                <Pagination page={Number(page)}
                                    numPages={Number(numPages)}
                                    onChangePage={handlePageChange}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                // :
                // <PermisoDenegado/>
            }
        </AppFrame>
    )
}


export default SolicitudesPage
