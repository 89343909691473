import axios from "axios"
import url from "./utils/url"
import headers from "./utils/headers"
import useErrorHandler from './utils/useErrorHandler'
import { useCallback } from 'react'

const eventoUrl = `${url}eventosDosimetria`

const useTecnicoPlanificacionService = () => {
  const { errorHandler } = useErrorHandler()

  const createEvento = useCallback(
    async (evento) => {
      try {
        const response = await axios.post(eventoUrl, evento, {
          headers: headers(),
        })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getEventoById = useCallback(
    async (evento) => {
      const requestUrl = `${eventoUrl}/${evento}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getAllEvento = useCallback(
    async () => {
      const requestUrl = `${eventoUrl}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getEventosByStatus = useCallback(
    async (status) => {
      const requestUrl = `${eventoUrl}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const updateEvento = useCallback(
    async (evento, cambios) => {
      const requestUrl = `${eventoUrl}/${evento}`
      try {
        const response = await axios.put(requestUrl, cambios, {
          headers: headers(),
        })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const deleteEvento = useCallback(
    async (evento) => {
      const requestUrl = `${eventoUrl}/${evento}`
      try {
        const response = await axios.delete(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

    const getEventosDosimetriaByTipo = useCallback(
        async (params) => {
            const requestUrl = `${eventoUrl}/tipo`
            try {
                const response = await axios.get(requestUrl, {headers: headers(),
                params: params})
                const {data} = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const recibirEventosDosimetria = useCallback(
        async (params) => {
            const requestUrl = `${eventoUrl}/recibir`
            try {
                const response = await axios.post(requestUrl, params, {headers: headers()})
                const {data} = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const entregarEventosDosimetria = useCallback(
        async (params) => {
            const requestUrl = `${eventoUrl}/entregar`
            try {
                const response = await axios.post(requestUrl, params, {headers: headers()})
                const {data} = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    return {
        createEvento, updateEvento, getEventoById,
        getAllEvento, getEventosByStatus, deleteEvento, getEventosDosimetriaByTipo,
        recibirEventosDosimetria, entregarEventosDosimetria
    }
}

export default useTecnicoPlanificacionService
