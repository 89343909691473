import React from 'react'
import { useState } from 'react';

const Myinput = () => {

    const [nombre,setNombre] = useState ('');
    const [mensaje, setMensaje] = useState('');

    const handlerImput = (e) => {

        setNombre(e.target.value)
        if (e.target.value.length > 3) {
            setMensaje('Mensaje válido');
          } else {
            setMensaje('Mensaje no válido');
          }
    };

    return (
        <div>
            <input value = {nombre} onChange={handlerImput}/>
            <h3>{mensaje}</h3>
        </div> 
    );
}

export default Myinput