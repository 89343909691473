import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import './FormDivider.css'

const FormDivider = ({text}) => {
    return (
        <div className = "formDivider">
            <Typography>
                {text}
            </Typography>
        </div>
    )
}

FormDivider.propTypes = {
    text: PropTypes.string.isRequired,
}

export default FormDivider
