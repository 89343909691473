import { useState, useEffect } from "react"
// import { useHistory } from 'react-router-dom'
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"
import useIsLoged from '../utils/useIsLoged'

const useLecturasPage = () => {
    useIsLoged()
    // const history = useHistory()
    const {
        getDosimetrosControlLecturaByArea,
        finalizarLoteLectura,
    } = useDosimetriaService()

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([]);

    // Almacena la información de los discos y las posiciones
    const [discoPosicionLectura, setDiscoPosicionLectura] = useState([])

    // Variable que controla la apertura y cierre del modal de iniciar lectura
    const [openIniciarLecturaModal, setOpenIniciarLecturaModal] = useState(false)

    // Para mostrar los datos en el modal de realizar lectura
    const [datosModalIniciarLectura, setDatosModalIniciarLectura] = useState("")

    // Para abrir el componente CircleProgress que indica que se están leyendo los datos
    const [lecturaProgress, setLecturaProgress] = useState(false)

    // Almacena la fecha de inicio de la lectura
    const [fechaLecturaInicio, setFechaLecturaInicio] = useState(new Date())

    // Almacena el index al abrir el modal de Iniciar Lectura
    const [posicionElemento, setPosicionElemento] = useState(0)

    // Almacena los datos del elemento al abrir el modal de Iniciar Lectura
    const [datosElementoIniciarLectura, setDatosElementoIniciarLectura] = useState([])

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    // Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    // const queryParams = new URLSearchParams(window.location.search);

    // Estado que indica la pagina actua
    // const [page, setPage] = useState(queryParams.get('page'))

    // Encabezado de la tabla
    const encabezado = ["Código", "Tipo de dosímetro", "Posición", "Observaciones"]

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    // if (!page) {
    //     setPage(1)
    //     history.push(`${window.location.pathname}?page=1`)
    // }

    //Controlador cambio de pagina
    // const handlePageChange = (event, value) => {
    //     setPage(value)
    //     history.push(`${window.location.pathname}?page=${value}`)
    // }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }


    const getDosimetrosLecturaControlByArea = async () => {
        startLoading()
        // console.log("getDosimetrosLecturaControlByArea")
        try {
            const response = await getDosimetrosControlLecturaByArea()
            // console.log(response)
            setRows([])
            let posicionContador = 1

            if(response?.status === 422) {
                stopLoading()
                return
            }

            if (response?.length > 0){
                // Se obtiene un nuevo arreglo con los atributos deseados
                const nuevoArray = response?.map( ({id, dosimetro_asignado}) => {
                    return ({
                        "dosimetria_control_id": id,
                        "codigo": dosimetro_asignado?.dosimetro_id,
                        "cantidad_cristales": dosimetro_asignado?.dosimetro?.cantidad_cristales,
                        "tipo_dosimetro": dosimetro_asignado?.dosimetro?.tipo_dosimetro === 2 ? 'Anillo' : 'Cuerpo completo',
                        "observacion": "",
                    })
                })

                let acumulador = 0, actual = 0, matriz = [], array = [], final = nuevoArray?.length, limite = 50,
                    resta_limite_acumulador = 0, resta_actual_restante = 0, cantidadPosicionCompletado = false,
                    dosimetroAnterior = {}

                nuevoArray?.forEach( (elemento, j) => {
                    actual = elemento?.cantidad_cristales
                    let arrayCantidadCristales = []
                    let suma_acumulador_actual = acumulador + actual

                    // Verifica si de un dosímetro anterior quedaron posiciones pendientes para ser agregadas
                    if (resta_actual_restante > 0) {
                        dosimetroAnterior.detalles = arrayCantidadCristales
                        // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                        for (let i = 1; i <= resta_actual_restante; i++) {
                            arrayCantidadCristales.push({posicion: posicionContador})
                            posicionContador++
                        }
                        array.push(dosimetroAnterior)
                        arrayCantidadCristales = []
                        resta_limite_acumulador = 0
                        resta_actual_restante = 0
                    }

                    let dosimetro = {...elemento} // Se realiza una copia del dosimetro
                    dosimetro.detalles = arrayCantidadCristales
                    // Se verifica que al sumar el valor actual de la cantidad de cristales sea menor o igual que 50 posiciones
                    // si no, se crea un nuevo arreglo
                    if (suma_acumulador_actual <= limite) {
                        // console.log("suma_acumulador_actual <= limite")
                        acumulador += elemento?.cantidad_cristales

                        // Verifica si es el último elemento del arreglo, en caso sea verdadero lo agrega al arreglo y a la matriz,
                        // si no, el elemento solo se agrega al arreglo
                        if ((j + 1) === final){
                            // console.log("(j + 1) === final")
                            // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                            for (let i = 1; i <= elemento?.cantidad_cristales; i++) {
                                arrayCantidadCristales.push({posicion: posicionContador})
                                posicionContador++
                            }
                            array.push(dosimetro)
                            matriz.push(array)
                        } else {
                            // console.log("ELSE")
                            // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                            for (let i = 1; i <= elemento?.cantidad_cristales; i++) {
                                arrayCantidadCristales.push({posicion: posicionContador})
                                posicionContador++
                            }
                            array.push(dosimetro)
                        }
                    } else {
                        // Verifica si la cantidad de posiciones ha sido completada sin tener algún sobrante, sino es true
                        // quiere decir que hay posciones sobrantes que deben ser llenadas
                        if (acumulador === limite) {
                            matriz.push(array)
                            // Reseteo de las variables
                            array = []
                            acumulador = 0
                            actual = 0
                            posicionContador = 1
                            cantidadPosicionCompletado = false

                            // Verifica si es el último elemento del arreglo, en caso sea verdadero lo agrega al arreglo y a la matriz,
                            // si no, el elemento solo se agrega al arreglo
                            if ((j + 1) === final){
                                // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                                for (let i = 1; i <= elemento?.cantidad_cristales; i++) {
                                    arrayCantidadCristales.push({posicion: posicionContador})
                                    posicionContador++
                                }
                                array.push(dosimetro)
                                matriz.push(array)
                            } else {
                                // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                                for (let i = 1; i <= elemento?.cantidad_cristales; i++) {
                                    arrayCantidadCristales.push({posicion: posicionContador})
                                    posicionContador++
                                }
                                array.push(dosimetro)
                                acumulador += elemento?.cantidad_cristales
                            }

                        } else {
                            // Como es mayor que 50 se procede a restarle el acumulador al límite
                            resta_limite_acumulador = limite - acumulador
                            resta_actual_restante = actual - resta_limite_acumulador

                            // Verifica si es el último elemento del arreglo, en caso sea verdadero lo agrega al arreglo y a la matriz,
                            // si no, agrega el elemento al arreglo y crea la matriz
                            if ((j + 1) === final){
                                // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                                for (let i = 1; i <= resta_limite_acumulador; i++) {
                                    arrayCantidadCristales.push({posicion: posicionContador})
                                    posicionContador++
                                }
                                matriz.push(array)

                                // Se crea un nuevo arreglo que contendrá el resto de la cantidad de cristales del dosímetro
                                dosimetro.detalles = []
                                arrayCantidadCristales = []
                                dosimetro.detalles = arrayCantidadCristales
                                posicionContador = 1
                                // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                                for (let i = 1; i <= resta_actual_restante; i++) {
                                    arrayCantidadCristales.push({posicion: posicionContador})
                                    posicionContador++
                                }
                                array.push(dosimetro)
                                matriz.push(array)
                            } else {
                                // Se agrega suma la cantidad de posiciones que posee el dosimetro mediante la cantidad de cristales
                                for (let i = 1; i <= resta_limite_acumulador; i++) {
                                    arrayCantidadCristales.push({posicion: posicionContador})
                                    posicionContador++
                                }
                                array.push(dosimetro)
                                matriz.push(array)

                                // Reseteo de las variables
                                array = []
                                acumulador = resta_actual_restante
                                actual = 0
                                posicionContador = 1
                                dosimetroAnterior = {...dosimetro}
                            }
                        }

                    }
                })

                // Obteniendo la cantidad de elementos de la matriz
                setDiscoPosicionLectura([])
                let discoPosicion = []
                for (let i = 1; i <= matriz?.length; i++) {
                    discoPosicion.push({
                        disco: i,
                        posicionLectura: i,
                    })
                }
                setDiscoPosicionLectura(discoPosicion)

                setRows(rows => [...rows, matriz])
            } else {
                stopLoading()
                return
            }
            stopLoading()
        } catch (e) {
            stopLoading()
        }
    }

    const openModalIniciarLectura = (datos, dato, index) => {
        setOpenIniciarLecturaModal(true)
        setPosicionElemento(index)
        setDatosElementoIniciarLectura(dato)
        // console.log(datos)
        // console.log(dato)
        // console.log(index)
        setDatosModalIniciarLectura({...discoPosicionLectura[index]})
        setFechaLecturaInicio(new Date().toLocaleString('en'))

    }
    const closeModalIniciarLectura = () => {
        setOpenIniciarLecturaModal(false)
        setLecturaProgress(false)
        setPosicionElemento(0)
        setDatosElementoIniciarLectura([])

    }

    // Método que ejecuta el CircleProgress indicando que se está realizando la lectura
    const iniciarTerminarLecturaProgress = () => {
        setLecturaProgress(true)
    }

    const handleObservaciones = (value, index, item) => {
        // Creando una copia de los elementos en la fila
        let filas = [...rows]
        // Obteniendo el elemento mediante la posición
        let filaByIndex = filas[0][index]
        // console.log("filaByIndex", filaByIndex)
        // Buscando el elemento mediante el dosimetria_control_id del elemento de la fila seleccionada
        let filaArray = filaByIndex.filter( (fila) => fila.dosimetria_control_id === item.dosimetria_control_id)
        // console.log("filaArray", filaArray)
        // Obteniendo el objeto del array
        let filaObject = filaArray[0]
        // console.log("filaObject", filaObject)
        // Agregando una observación a la fila
        filaObject.observacion = value.target.value
        // Seteando los valores a la fila
        setRows(filas)
    }

    // Método que finaliza la lectura de los dosímetros
    const finalizarLectura = async () => {
        // console.log("Finalizando...")
        // console.log("index", posicionElemento)
        // console.log("row", datosElementoIniciarLectura)
        // console.log("row typeof", typeof datosElementoIniciarLectura)
        // console.log("datosModalIniciarLectura", datosModalIniciarLectura)
        // console.log("Fecha Inicio", fechaLecturaInicio)
        // console.log("Fecha Fin", new Date())

        let datosEnviar = {}
        let detalles = []
        datosElementoIniciarLectura?.forEach(elemento => {
            // console.log(elemento)
            elemento?.detalles?.forEach(({posicion}) => {
                let detalleDosimeto = {
                    "posicion": posicion,
                    "dosimetria_control_id": elemento?.dosimetria_control_id,
                    "observacion": elemento?.observacion
                }
                detalles.push(detalleDosimeto)
            })
        })

        datosEnviar.disco = datosModalIniciarLectura?.disco
        datosEnviar.tiempo_inicio_lectura = fechaLecturaInicio
        datosEnviar.tiempo_fin_lectura = new Date().toLocaleString('en')
        datosEnviar.detalles = detalles
        // console.log(datosEnviar)
        const response = await finalizarLoteLectura(datosEnviar)
        if (response?.error) {
            handleOpenAlert('error', "Ocurrió un error, intente de nuevo más tarde")
        }else {
            handleOpenAlert('success', "Lectura realizada correctamente")
            closeModalIniciarLectura()
            await getDosimetrosLecturaControlByArea()
        }
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        rows,
        encabezado,
        discoPosicionLectura,
        openIniciarLecturaModal,
        datosModalIniciarLectura,
        lecturaProgress,
    }

    const actions = {
        setAlertOpen,
        openModalIniciarLectura,
        closeModalIniciarLectura,
        iniciarTerminarLecturaProgress,
        handleObservaciones,
        finalizarLectura,
    }

    // Para cargar los eventos de dosimetría
    useEffect(async () => {
        await getDosimetrosLecturaControlByArea()
    }, [])

    return { data, actions }
}

export default useLecturasPage
