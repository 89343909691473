import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import useSolicitudCotizacionService from '../../services/solicitudCotizacion'
import {mensajeCamposNoValidos} from '../../utils/messages'
import useIsLoged from '../utils/useIsLoged'
import useUsuarioService from '../../services/administracion/useUsuarioService'
import moment from 'moment-timezone';

const useHomePageCliente = () => {

    const { createSolicitud, getTipoSolicitudes, createComentario,
        institucionByUserId, getTipoSolicitudById, subirArchivo }
        = useSolicitudCotizacionService()

    const { getMe } = useUsuarioService()

    useIsLoged()
    const [error, setError] = useState(null)
    const history = useHistory()
    const [fecha, setFecha] = useState("")
    const [tipoSolicitud, setTipoSolicitud] = useState('')
    const [tiposDeSolicitudes, setTiposDeSolicitudes] = useState([])
    const [comentario, setComentario] = useState("")
    const [nombre, setNombre] = useState("")
    const [errorTipoSolicitud, setErrorTipoSolicitud] = useState(false)
    const [archivo, setArchivo] = useState(null)
    const [objArchivo, setObjArchivo] = useState(null)
    const [loading, setLoading] = useState(0)
    //Estado para ProgressBar para subir/descargar archivos
    const [progress, setProgress] = useState(-1)

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    // Funcion que devuelve los tipos de solicitudes
    const getTiposDeSolicitudes = async () => {
        startLoading()
        const tiposDeSolicitudes = await getTipoSolicitudes()
        if (tiposDeSolicitudes.error) {
            setError(tiposDeSolicitudes.error)
        }
        else {
            setTiposDeSolicitudes([])
            tiposDeSolicitudes.forEach(({ id, nombre }) => {
                const solicitud = {
                    value: id,
                    label: nombre,
                }
                setTiposDeSolicitudes((tipoDeSolicitudes) => [...tipoDeSolicitudes, solicitud])
            })
        }
        stopLoading()
    }

    //Calculo de la fecha
    const date = moment().tz("America/El_Salvador");
    const datestring = date.format("DD/MM/YYYY HH:mm:ss");
    //estado_dosimetro_ids para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    // Manejador de archivos
    const handleOnChangeArchivo = (file) => {
        setArchivo(file[0])
        setObjArchivo([{
            name: file[0].name, deleteFile: () => {
                setObjArchivo(null)
                setArchivo(null)
            }
        }])
    }

    // Manejador de error de archivos
    const handleErrorArchivo = (archivo, comentario) => {
        if (archivo) { //Verifica si existe el archivo
            if (comentario === "") {
                return true
            } else {
                return false
            }
        }
    }

    // Manejador de error de tipo de archivo
    const handleErrorTipoArchivo = (archivo) => {
        // Devuelve el tipo de archivo
        const { type } = archivo
        const tipoArchivoPermitido = ["application/pdf", "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]
        let tipoArchivoDiferente = true
        // Verifica si el tipo de archivo subido tiene una extension permitida
        for (let i = 0; i < tipoArchivoPermitido.length; i++) {
            if (type === tipoArchivoPermitido[i]) {
                tipoArchivoDiferente = false
            }
        }
        return tipoArchivoDiferente
    }

    // Manejador de error de tipo de solicitud
    const handleOnChangeTipoSolicitud = (value) => {
        if (value === "") { // Verifica si no existe un tipo de solicitud
            setErrorTipoSolicitud(true)
            stopLoading()
        } else {
            setErrorTipoSolicitud(false)
            setTipoSolicitud(value)
        }
    }

    // Manejador de comentarios
    const handleOnChangeComentario = (value) => {
        setComentario(value)
    }

    const handleOnProgress = (loaded, total) => {
        const progress = (loaded / total) * 100
        setProgress(progress)
    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorTipoSolicitud === true || tipoSolicitud === "") {
            return true
        }
        return false
    }

    const onCreateSolicitud = async () => {
        startLoading()
        const myUser = await getMe() //Obteniendo el usuario logueado
        // *** VERIFICANDO ERRORES ***
        // Verifica si hay usuario logueado
        if (myUser.error) {
            handleOpenAlert('error', myUser.error)
            stopLoading()
            return
        }
        const { id: idUsuario } = myUser
        // Obteniendo la institucion a la que pertenece el cliente
        const institucion = await institucionByUserId(idUsuario)
        // Verifica si existe una institución
        if (institucion.error || institucion === "" || institucion[0] === undefined) {
            handleOpenAlert('error', "No posee una institución asociada")
            stopLoading()
            return
        }
        const { id } = institucion[0] //Obteniendo id de la institucion

        // Verifica los campos obligatorios
        if (checkForErrors()) {
            handleOpenAlert('warning', mensajeCamposNoValidos)
            stopLoading()
            return
        }

        if (archivo) {
            // Verifica el tipo de archivo
            if (handleErrorTipoArchivo(archivo)) {
                handleOpenAlert('warning', "El tipo de archivo no es válido")
                stopLoading()
                return
            }
        }
        if (archivo) {
            // Verifica si existe un comentario al subir un archivo
            if (handleErrorArchivo(archivo, comentario)) {
                handleOpenAlert('warning', "Para subir un archivo debe de ingresar un comentario")
                stopLoading()
                return
            }
        }

        // *** CREANDO LA SOLICITUD ***
        const result = await crearSolicitud(tipoSolicitud, id)
        // Verifica si hay errores al crear la solicitud
        if (result.error) {
            handleOpenAlert('error', result.error)
            stopLoading()
            return
        }

        let resultComentario = ""
        // Obteniendo el id de la solicitud y el id del tipo de solicitud de la cotizacion
        const { id: idSolicitud, tipo_solicitud_id } = result
        // Verifica que idSolicitud no sea undefined
        if (!idSolicitud) {
            handleOpenAlert('error', "Error al crear la solicitud, idSolicitud no encontrado")
            stopLoading()
            return
        }

        // *** CREANDO UN COMENTARIO ***
        // Verifica si existe un comentario pero no hay archivo
        if (comentario) {
            const resultadoComentario = await crearComentario(comentario, idUsuario, idSolicitud)
            if (resultadoComentario.error) {
                handleOpenAlert('error', resultadoComentario.error)
            } else {
                resultComentario = resultadoComentario
            }
        }

        // *** SUBIENDO EL ARCHIVO ***
        // Verifica si existe un comentario y un archivo 
        if (comentario !== "" && archivo !== null) {
            // Obteniendo el id del comentario y el tipo de comentario
            const { id: idComentario, tipo_comentario } = resultComentario
            const resultFile = await uploadFile(archivo, idComentario, tipo_comentario, handleOnProgress)
            if (resultFile.error) {
                handleOpenAlert('error', resultFile.error)
            }
        }

        const tipoSolicitudById = await getTipoSolicitudById(tipo_solicitud_id)
        const { nombre: nombreSolicitud } = tipoSolicitudById
        handleOpenAlert('success', `La solicitud "${nombreSolicitud}" se creo correctamente.`)

        editCancel()
        stopLoading()
    }

    const crearSolicitud = async (tipoSolicitud, id) => {
        const fechaSolicitud = moment().tz("America/El_Salvador").format("YYYY-MM-DDTHH:mm:ssZ");
        const newSolicitud = {
            "fecha_aprobada": fechaSolicitud,
            "tipo_solicitud_id": tipoSolicitud,
            "institucion_id": id
        }
        const result = await createSolicitud(newSolicitud)
        return result
    }

    const crearComentario = async (comentario, idUsuario, idSolicitud, tipo_comentario) => {
        // Creacion del comentario
        const coment = {
            "cuerpo": comentario,
            "user_id": idUsuario,
            "solicitud_id": idSolicitud,
            "tipo_comentario": (tipo_comentario ? tipo_comentario : 0) // 0 - Representa que es externo (Cliente-CIAN)
        }
        // console.log(coment)
        const result = await createComentario(coment)
        // console.log("Resultado comentario ", result)
        return result
    }

    const uploadFile = async (archivo, idComentario, tipo_comentario, handleOnProgress) => {
        // Subida del archivo
        let formData = new FormData()
        formData.append("historial", archivo)
        formData.append("comentario_id", idComentario)
        formData.append("tipo", tipo_comentario)
        setNombre(`Subiendo archivo: ${archivo.name}`)
        return await subirArchivo(formData, handleOnProgress)
    }

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const cancel = () => {
        setFecha("")
        setTipoSolicitud("")
        setComentario("")
        setArchivo(null)
        setObjArchivo(null)
        setNombre("")
        setProgress(-1)
    }

    const editCancel = () => {
        cancel()
        history.push(`/nueva_solicitud`)
    }

    const cancelarSolicitud = () => {
        cancel()

        const dataSessionStorage = sessionStorage.getItem('tipoSolicitud')

        let url = `/solicitudes_`

        if (dataSessionStorage === '3') url += `contratacion`
        else if (dataSessionStorage === '4') url += `cotizacion`
        else if (dataSessionStorage === '5') url += `modificacion`
        else url = `/nueva_solicitud`

        history.push(url)
    }

    //decodeURIComponent(text1)
    const data = {
        datestring, tiposDeSolicitudes, tipoSolicitud, fecha,
        comentario, alertOpen, alertSeverity, alertMessage,
        loading, progress, nombre, objArchivo
    }

    const actions = {
        handleOnChangeArchivo, onCreateSolicitud, setAlertOpen,
        handleOnChangeTipoSolicitud, handleOnChangeComentario, crearComentario,
        uploadFile, handleErrorTipoArchivo, cancelarSolicitud
    }

    //useEffect para los tipos de solicitud
    useEffect(() => {
        getTiposDeSolicitudes()
    }, [])


    return { error, data, actions }
}

export default useHomePageCliente