import React from 'react'
import FormHeader from './../../components/FormHeader'
import Input from './../../components/Input'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import FormDivider from './../../components/FormDivider'
import ComboBox from './../../components/ComboBox'
import { makeStyles } from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import FileInput from '../../components/FileInput'
import MaterialDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import PdfViewer from '../../components/PdfViewer'
import Pagination from '../../components/Pagination'
import {MaskField} from "react-mask-field";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    }
}))

function CustomMaskNumberField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="____-____" replacement={{ _: /\d/ }} {...otherProps} />;
}
function CustomMaskNITField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="____-______-___-_" replacement={{ _: /\d/ }} {...otherProps} />;
}

const DatosInstitucionPage = ({data, actions}) => {
    const classes = useStyles()
    const {
            tiposInstitucion,
            sectores,
            ministerios,
            municipiosCombo,
            departamentosCombo,
            nombreInstitucion,
            errorNombreInstitucion,
            helperNombreInstitucion,
            nitInstitucion,
            errorNitInstitucion,
            helperNitInstitucion,
            objArchivoInstitucion,
            telefonoInstitucion,
            errorTelefonoInstitucion,
            helperTelefonoInstitucion,
            celularInstitucion,
            errorCelularInstitucion,
            helperCelularInstitucion,
            departamentoInstitucion,
            municipioInstitucion,
            direccionInstitucion,
            errorDireccionInstitucion,
            helperDireccionInstitucion,
            tipoInstitucion,
            ministerioInstitucion,
            sectorInstitucion,
            openInstitucionFileModal, 
            institucionFilePageNumber,
            institucionFileNumPages, archivoInstitucion
        } = data

    const {onChangeNombreInstitucion,
        onChangeNitInstitucion,
        onChangeTelefonoInstitucion,
        onChangeCelularInstitucion,
        setDepartamentoInstitucion,
        setMunicipioInstitucion,
        onChangeDireccionInstitucion,
        setTipoInstitucion,
        setMinisterioInstitucion,
        setSectorInstitucion, handleOnChangeArchivoInstitucion,
        handleCloseInstitucionFileModal, //*** Para ver el archivo PDF en la edicion de datos ***
        onInstitucionDocumentLoadSuccess, handleInstitucionFilePageChange,} = actions



    return (
        <Grid container direction = "row" justifyContent = "center">
            <Grid item xs={12} sm= {8}>
                <Paper className={classes.paper}>
                    <FormHeader title="Registro de inscripcion"
                        subtitle="Datos de la institucion">
                        <FaRegUserCircle/>
                    </FormHeader>
                    <Grid container direction="row" justifyContent="center"
                        spacing = {2}>
                        <Grid container item xs = {10}>
                            <FormDivider text="Datos generales"/>
                            <Grid className={classes.input} item xs = {12} sm={6}>
                                <Input type = "text" label="Nombre de la 
                                institucion" 
                                    placeholder="Ingrese el nombre de la 
                                    institucion"
                                    value = {nombreInstitucion}
                                    onChange={onChangeNombreInstitucion}
                                    error = {errorNombreInstitucion}
                                    helper = {helperNombreInstitucion}
                                    required = {true}
                                    />
                            </Grid>
                            <Grid  item xs = {12} sm={6}>
                                <Input type = "text" label="NIT" 
                                    placeholder="Ingrese el NIT de la 
                                    institucion"
                                    value = {nitInstitucion}
                                    onChange={onChangeNitInstitucion}
                                    error = {errorNitInstitucion}
                                    helper = {helperNitInstitucion}
                                    required = {true}
                                    InputProps={{ inputComponent: CustomMaskNITField }}
                                    />
                            </Grid>
                            {
                                handleOnChangeArchivoInstitucion &&
                                <Grid className={classes.input} item xs={12}>
                                    <FileInput label="Comprobante NIT (ambos lados)"
                                        placeholder="Seleccione el archivo a subir"
                                        onChange={handleOnChangeArchivoInstitucion}
                                        required={true}
                                        files={objArchivoInstitucion}
                                    />
                                    <MaterialDialog
                                        open={openInstitucionFileModal}
                                        onClose={handleCloseInstitucionFileModal}
                                        maxWidth='lg'
                                    >
                                        <DialogContent>
                                            <PdfViewer file={archivoInstitucion} onDocumentLoadSuccess={onInstitucionDocumentLoadSuccess}
                                                pageNumber={institucionFilePageNumber} numPages={institucionFileNumPages} scale={1} />
                                            <Pagination page={Number(institucionFilePageNumber)}
                                                numPages={Number(institucionFileNumPages)}
                                                onChangePage={handleInstitucionFilePageChange}
                                            />
                                        </DialogContent>
                                    </MaterialDialog>
                                </Grid>
                            }
                            <Grid className={classes.input} item xs = {12} sm={6}>
                                <Input type = "text" label="Telefono" 
                                    placeholder="Ingrese el telefono de 
                                    la institucion"
                                    value = {telefonoInstitucion}
                                    onChange={
                                        (text)=>{onChangeTelefonoInstitucion(text)}
                                    }
                                    error = {errorTelefonoInstitucion}
                                    helper = {helperTelefonoInstitucion}
                                    required={true}
                                        InputProps={{ inputComponent: CustomMaskNumberField }}
                                    />
                            </Grid>
                            <Grid  item xs = {12} sm={6}>
                                <Input type = "text" label="Celular" 
                                    placeholder="Ingrese el celular de la
                                        institucion"
                                    value = {celularInstitucion}
                                    onChange={
                                        (text)=>{onChangeCelularInstitucion(text)}
                                    }
                                    error = {errorCelularInstitucion}
                                    helper = {helperCelularInstitucion}
                                    required={true}
                                        InputProps={{ inputComponent: CustomMaskNumberField }}
              
                                    />
                            </Grid>
                            <FormDivider text="Ubicacion"/>
                            <Grid className={classes.input} item xs = {12} sm={6}>
                                <ComboBox label = "Departamento"
                                    value={departamentoInstitucion} list = {departamentosCombo}
                                    onChange = {(value)=>{setDepartamentoInstitucion(value)}}
                                    required = {true}
                                    />
                            </Grid>
                            <Grid className={classes.input} item xs = {12} sm={6}>
                                <ComboBox label = "Municipio"
                                    value={municipioInstitucion} list = {municipiosCombo}
                                    onChange = {(value)=>{setMunicipioInstitucion(value)}}
                                    required = {true}
                                    error = {municipiosCombo.length > 0 ? false : true}
                                    disabled = {municipiosCombo.length > 0 ? false : true}
                                    helper = { municipiosCombo.length > 0 ? "" : "El departamento seleccionado no contiene municipios disponibles en el sistema" }
                                    />
                            </Grid>
                            <Grid className={classes.input} item xs = {12}>
                                <Input type = "text" label="Especificacion 
                                    de la direccion" 
                                    placeholder="Ingrese la direccion de la 
                                    institucion"
                                    value = {direccionInstitucion}
                                    onChange={onChangeDireccionInstitucion}
                                    error = {errorDireccionInstitucion}
                                    helper = {helperDireccionInstitucion}
                                    required = {true}
                                    />
                            </Grid>
                            <FormDivider text="Datos especificos"/>
                            <Grid className={classes.input} item xs = {6}>
                                <ComboBox label="Tipo de institucion"
                                    value={tipoInstitucion} list = {tiposInstitucion}
                                    onChange={(value)=>{setTipoInstitucion(value)}}/>
                            </Grid>
                            {
                                tipoInstitucion === 1 ?
                                    <Grid className={classes.input} item
                                        xs = {6}>
                                        <ComboBox label="Ministerio"
                                            value={ministerioInstitucion}
                                            list = {ministerios}
                                            onChange = {(value) => {setMinisterioInstitucion(value)}}
                                            />
                                    </Grid>
                                    :
                                    null
                            }
                            <Grid className={classes.input} item xs = {6}>
                                <ComboBox label = "Sector"
                                    value={sectorInstitucion} list = {sectores}
                                    onChange={(value)=>{setSectorInstitucion(value)}}/>
                            </Grid>
                            <Grid container item direction="row"
                                justifyContent="center" spacing = {2}>
                                <Grid className={classes.input} item 
                                    xs={5} sm= {3}>
                                    <Button
                                        component={RouterLink}
                                        to={data.idSolicitud ? '/inscripcion/1/edit/'+ data.idSolicitud : '/inscripcion/1/'}
                                        variant = "outlined"
                                        text="REGRESAR"/>
                                </Grid>
                                <Grid className={classes.input} item
                                    xs={5} sm= {3}>
                                    <Button
                                        component={RouterLink}
                                        to={data.idSolicitud ? '/inscripcion/3/edit/'+ data.idSolicitud : '/inscripcion/3/'}
                                        variant = "contained"
                                        text="SIGUIENTE"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default DatosInstitucionPage
