import React, { useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  TextareaAutosize
} from '@material-ui/core';

function ControlAvanceTable({ tipos_control_avance, setTipos_control_avance, cantidadNotEdit }) {
  const handleCantidadReportesChange = (index, value) => {
    const updatedTiposControlAvance = [...tipos_control_avance];
    updatedTiposControlAvance[index].cantidad_reporte = value;
    setTipos_control_avance(updatedTiposControlAvance);
  };

  const handleComentarioChange = (index, value) => {
    const updatedTiposControlAvance = [...tipos_control_avance];
    updatedTiposControlAvance[index].comentario = value;
    setTipos_control_avance(updatedTiposControlAvance);
  };

  const inputRefs = useRef([]); // Crear una referencia para los campos de entrada

  const handleInputClick = (index) => {
    const input = inputRefs.current[index];
    if (input) {
      input.select(); // Seleccionar todo el texto en el campo de entrada
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tipo de Equipo</TableCell>
            <TableCell>Cantidad de Equipos</TableCell>
            <TableCell>Cantidad de Reportes</TableCell>
            <TableCell>Comentario</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tipos_control_avance.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell>{item.nombre_equipo}</TableCell>
              <TableCell>{item.cantidad_equipo}</TableCell>
              <TableCell>
                {cantidadNotEdit ? ( // Comprobar si la columna no es editable
                  item.cantidad_reporte || 0
                ) : (
                  <Input
                    type="number"
                    value={item.cantidad_reporte || 0}
                    onClick={() => handleInputClick(index)} // Seleccionar texto al hacer clic
                    onChange={(e) => handleCantidadReportesChange(index, e.target.value)}
                    inputProps={{ min: 0 }}
                    inputRef={(el) => (inputRefs.current[index] = el)} // Asignar referencia al campo de entrada
                  />
                )}
              </TableCell>
              <TableCell>
                <TextareaAutosize
                  value={item.comentario || ''}
                  onChange={(e) => handleComentarioChange(index, e.target.value)}
                  rowsMin={3}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ControlAvanceTable;
