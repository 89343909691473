import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";
import { addRow, findRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import { BsFillChatDotsFill, BsFillChatFill } from "react-icons/bs";
import { MdSearch } from "react-icons/md";
import useUsuarioService from "../../services/administracion/useUsuarioService";

const useSolicitudesPage = (tipoSolicitud) => {
  const { getSolicitudesByTipoAndFecha, getSolicitudById } =
    useSolicitudCotizacionService();

  const history = useHistory();

  useIsLoged();
  const { getMe } = useUsuarioService();
  const [error, setError] = useState(null);
  const [fecha, setFecha] = useState("");
  const [estado, setEstado] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [value, setValue] = useState("Seleccione un rango de fecha");
  const [rows, setRows] = useState([]);
  // Para el rol del usuario
  const [idRol, setIdRol] = useState("");
  //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  //Estado que indica la pagina actua
  const [page, setPage] = useState(queryParams.get("page"));
  //Estado que indica el numero de paginas en total
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(false);

  //estado_dosimetro_ids para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  //Controlador cambio de pagina
  const handlePageChange = (event, value) => {
    setPage(value);
    history.push(`${window.location.pathname}?page=${value}`);
  };

  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleLimpiarFechas = () => {
    setDateRange({
      startDate: "",
      endDate: "",
    });
    setValue("Seleccione un rango de fecha");
  };

  const handleSelectDate = (start, end) => {
    // Obteniendo la fecha del componente del rango de fecha
    const { _d: fechaInicio } = start;
    const { _d: fechaFin } = end;
    setDateRange({
      start: new Date(fechaInicio).toISOString(),
      end: new Date(fechaFin).toISOString(),
    });
    // Texto que aparecerá en el componente del rango de fecha
    setValue(start.format("DD-MMM-YYYY") + "  -  " + end.format("DD-MMM-YYYY"));
  };

  const getSolicitudesPorTipoSolicitudFecha = useCallback(async () => {
    setLoading(true);
    const { start, end } = dateRange;
    const filtro = {
      tipo_solicitud: tipoSolicitud,
      fecha_inicial: start,
      fecha_final: end,
    };
    const solicitud = await getSolicitudesByTipoAndFecha(page, filtro);
    if (solicitud.error) {
      handleOpenAlert("error", solicitud.error);
      setError(solicitud.error);
      setLoading(false);
      return;
    } else {
      setRows([]);
      setNumPages(solicitud.last_page);
      let solicitudEtapa_uno,
        solicitudEtapa_dos,
        solicitudModificacion = null;
      if (tipoSolicitud === 100) {
        // console.log("Solicitud 100")
        solicitud.data.forEach((soli) => {
          if (soli.tipo_solicitud.id === 1) {
            solicitudEtapa_uno = soli;
            // console.log("Solicitud etapa 1", solicitudEtapa_uno)
          }
          if (soli.tipo_solicitud.id === 2) {
            solicitudEtapa_dos = soli;
            // console.log("Solicitud etapa 2", solicitudEtapa_dos)
          }
          if (soli.tipo_solicitud.id === 5) {
            solicitudModificacion = soli;
            // console.log("Solicitud etapa modificacion", solicitudModificacion)
          }
        });
        // Verifica si hay una solicitud de modificacion
        if (solicitudModificacion) {
          if (solicitudModificacion.fecha_aprobada) {
            // console.log("Existe solicitudModificacion")
            if (solicitudEtapa_uno) {
              const {
                id,
                tipo_solicitud,
                institucion,
                fecha_aprobada,
                estado_solicitud,
                estado_solicitud_id,
              } = solicitudEtapa_uno;
              const { nombre: nombre_estado_solicitud } = estado_solicitud;
              const { nombre: nombre_tipo_solicitud } = tipo_solicitud;
              const key = id; // Id de la solicitud
              if (Number(estado_solicitud_id) === 6) {
                const cell = [
                  nombre_tipo_solicitud,
                  institucion.nombre,
                  nombre_estado_solicitud,
                  fecha_aprobada,
                ];
                addRow(key, cell, setRows, true);
              }
            }
            if (solicitudEtapa_dos) {
              const {
                id,
                tipo_solicitud,
                institucion,
                fecha_aprobada,
                estado_solicitud,
              } = solicitudEtapa_dos;
              const { nombre: nombre_estado_solicitud } = estado_solicitud;
              const { nombre: nombre_tipo_solicitud } = tipo_solicitud;
              const key = id; // Id de la solicitud
              const cell = [
                nombre_tipo_solicitud,
                institucion.nombre,
                nombre_estado_solicitud,
                fecha_aprobada,
              ];
              addRow(key, cell, setRows, true);
            }
          }
        }
      } else {
        solicitud.data.forEach(
          ({
            id,
            tipo_solicitud,
            institucion,
            fecha_aprobada,
            estado_solicitud,
          }) => {
            const { nombre: nombre_estado_solicitud } = estado_solicitud;
            const { nombre: nombre_tipo_solicitud } = tipo_solicitud;
            const key = id; // Id de la solicitud
            // console.log(key)
            const cell = [
              nombre_tipo_solicitud,
              institucion.nombre,
              nombre_estado_solicitud,
              fecha_aprobada,
            ];
            addRow(key, cell, setRows, true);
          }
        );
      }
      if (page > solicitud.last_page) {
        setPage(solicitud.last_page);
        history.push(`${window.location.pathname}?page=${solicitud.last_page}`);
      }
    }
    setLoading(false);
  }, [history, page, dateRange, getSolicitudesByTipoAndFecha, tipoSolicitud]);

  const getRolUsuarioLogueado = async () => {
    // setLoading(true)
    // Obteniendo los datos del usuario logueado
    const userMe = await getMe();
    const { role_id } = userMe; //Obteniendo el rol del usuario logueado
    setIdRol(role_id);
    // console.log('UserMe', userMe)
    // setLoading(false)
  };

  //Metodos que redireccionan a las pantallas de resumen inscripcion p1
  const verInscripcionE1 = (id) => {
    history.push(`/solicitudes-inscripcion-etapa-1/${id}`);
  };
  const verInscripcionE2 = (id) => {
    history.push(`/solicitudes-inscripcion-etapa-2/${id}`);
  };

  //Metodos que redireccionan a las pantallas de chat
  const secretariaClienteChat = (id) => {
    history.push(`/proceso_solicitud/${id}/0`);
  };
  const secretariaCoordinadorChat = (id) => {
    history.push(`/proceso_solicitud/${id}/1`);
  };

  // Metodos que redireccionan a las pantallas de edicion
  const edicionEtapa1 = async (id) => {
    const soli = await getSolicitudById(Number(id));
    if (soli.error) {
      handleOpenAlert(
        "error",
        "Ocurrió un error al obtener los datos de la solicitud"
      );
    } else {
      // console.log('Solicitud', soli)
      // Solicitud de Etapa 1
      if (soli[0].tipo_solicitud_id === 1) {
        history.push(`/inscripcion/1/edit/${id}`);
      } else if (soli[0].tipo_solicitud_id === 2) {
        // Solicitud de etapa 2
        history.push(`/cliente/delegados/`);
      }
    }
  };

  const botonesDataTable = (tipoSolicitud) => {
    switch (tipoSolicitud) {
      case 1:
        return [
          {
            func: (key) => verInscripcionE1(key),
            icon: MdSearch,
            tooltip: "Ver solicitud",
          },
        ];
      case 2:
        return [
          {
            func: (key) => verInscripcionE2(key),
            icon: MdSearch,
            tooltip: "Ver solicitud",
          },
        ];
      case [3, 4, 5].find((n) => n === Number(tipoSolicitud)):
        if (Number(idRol) === 3) {
          //Rol de la secretaria
          return [
            {
              func: (key) => secretariaClienteChat(key),
              icon: BsFillChatDotsFill,
              tooltip: "Cliente",
            },
            {
              func: (key) => secretariaCoordinadorChat(key),
              icon: BsFillChatFill,
              tooltip: "Coordinador",
            },
          ];
        }
        if (Number(idRol) === 2) {
          //Rol de la coordinador de dosimetria
          return [
            {
              func: (key) => secretariaCoordinadorChat(key),
              icon: BsFillChatFill,
              tooltip: "Secretaria",
            },
          ];
        }
        if (Number(idRol) === 8) {
          // Rol del cliente
          return [
            {
              func: (key) => secretariaClienteChat(key),
              icon: BsFillChatDotsFill,
              tooltip: "Cliente",
            },
          ];
        }
        break;
      case 100:
        return [
          {
            func: (key) => edicionEtapa1(key),
            icon: MdSearch,
            tooltip: "Editar solicitud",
          },
        ];
      default:
        return [];
    }
  };

  console.log({
    borramePeroMirame: rows,
  });
  const data = {
    rows,
    estado,
    fecha,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    page,
    numPages,
    value,
    idRol,
  };

  const actions = {
    setEstado,
    setFecha,
    findRow,
    setAlertOpen,
    handleSelectDate,
    handlePageChange,
    botonesDataTable,
    handleLimpiarFechas,
  };

  //useEffect para cargar datos en la tabla
  useEffect(() => {
    const dataSessionStorage = sessionStorage.getItem("tipoSolicitud");
    if (dataSessionStorage !== tipoSolicitud)
      sessionStorage.setItem("tipoSolicitud", JSON.stringify(tipoSolicitud));
    getRolUsuarioLogueado();
    getSolicitudesPorTipoSolicitudFecha();
  }, [getSolicitudesPorTipoSolicitudFecha]);

  return { error, data, actions };
};

export default useSolicitudesPage;
