import { useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { allLetterAndSpace, isDUI, isEmail, isNIT, isPhone, validPassword }
    from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import useDepartamentosService from '../../services/inscripcion/useDepartamentosService'
import useResumenService from '../../services/inscripcion/useResumenService'
import useUsuarioService from '../../services/administracion/useUsuarioService'
import useSolicitudCotizacionService from '../../services/solicitudCotizacion'
import useIsLoged from '../utils/useIsLoged'
import { getFilenameFromContentDisposition } from '../../utils/download'

const useSolicitudInscripcionEditPage = () => {
    useIsLoged()
    const { getDepartamentos } = useDepartamentosService()

    // const history = useHistory()
    const {paso, idSolicitud} = useParams()
    const { getCargosSolicitud, getMinisteriosSolicitud, getSectoresSolicitud,
        updateSolicitudInscripcion } = useSolicitudInscripcionService()
    //Estados para controlar los inputs
    //(errorInput y helperInput sirven para mostrar un error en el input)

    //Estado para la barra de progreso
    //const history = useHistory()
    const [progress, setProgress] = useState(-1)

    //Estados para usuario
    const [nombreUsuario, setNombreUsuario] = useState('')
    const [errorNombreUsuario, setErrorNombreUsuario] = useState(false)
    const [helperNombreUsuario, setHelperNombreUsuario] = useState('')
    const [duiUsuario, setDuiUsuario] = useState('')
    const [errorDuiUsuario, setErrorDuiUsuario] = useState(false)
    const [helperDuiUsuario, setHelperDuiUsuario] = useState('')
    const [archivoUsuario, setArchivoUsuario] = useState(null)
    const [objArchivoUsuario, setObjArchivoUsuario] = useState(null)
    const [telefonoUsuario, setTelefonoUsuario] = useState('')
    const [errorTelefonoUsuario, setErrorTelefonoUsuario] = useState(false)
    const [helperTelefonoUsuario, setHelperTelefonoUsuario] = useState('')
    const [celularUsuario, setCelularUsuario] = useState('')
    const [errorCelularUsuario, setErrorCelularUsuario] = useState(false)
    const [helperCelularUsuario, setHelperCelularUsuario] = useState('')
    const [correoElectronicoUsuario, setCorreoElectronicoUsuario] = useState('')
    const [errorCorreoElectronicoUsuario, setErrorCorreoElectronicoUsuario] = useState(false)
    const [helperCorreoElectronicoUsuario, setHelperCorreoElectronicoUsuario] = useState('')
    const [contrasenia1Usuario, setContrasenia1Usuario] = useState('')
    const [errorContrasenia1Usuario, setErrorContrasenia1Usuario] = useState(false)
    const [helperContrasenia1Usuario, setHelperContrasenia1Usuario] = useState('')
    const [contrasenia2Usuario, setContrasenia2Usuario] = useState('')
    const [errorContrasenia2Usuario, setErrorContrasenia2Usuario] = useState(false)
    const [helperContrasenia2Usuario, setHelperContrasenia2Usuario] = useState('')
    const [fileNumPages, setFileNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [openFileModal, setOpenFileModal] = useState(false)

    //Estados para institucion
    const [ministerios, setMinisterios] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [departamentosCombo, setDepartamentosCombo] = useState([])
    const [municipiosCombo, setMunicipiosCombo] = useState([])
    const [sectores, setSectores] = useState([])
    const tiposInstitucion = [
        {
            value: 1,
            label: "Pública"
        },
        {
            value: 2,
            label: "Privada"
        },
        {
            value: 3,
            label: "Empresa mixta"
        },
        {
            value: 4,
            label: "Empresa extranjera"
        },
        {
            value: 5,
            label: "Militar"
        },
        {
            value: 6,
            label: "Personal"
        },
        {
            value: 7,
            label: "Autónoma"
        },
    ]
    const [nombreInstitucion, setNombreInstitucion] = useState('')
    const [errorNombreInstitucion, setErrorNombreInstitucion] = useState(false)
    const [helperNombreInstitucion, setHelperNombreInstitucion] = useState('')
    const [nitInstitucion, setNitInstitucion] = useState("")
    const [errorNitInstitucion, setErrorNitInstitucion] = useState(false)
    const [helperNitInstitucion, setHelperNitInstitucion] = useState('')
    const [archivoInstitucion, setArchivoInstitucion] = useState(null)
    const [objArchivoInstitucion, setObjArchivoInstitucion] = useState(null)
    const [telefonoInstitucion, setTelefonoInstitucion] = useState("")
    const [errorTelefonoInstitucion, setErrorTelefonoInstitucion] = useState(false)
    const [helperTelefonoInstitucion, setHelperTelefonoInstitucion] = useState('')
    const [celularInstitucion, setCelularInstitucion] = useState("")
    const [errorCelularInstitucion, setErrorCelularInstitucion] = useState(false)
    const [helperCelularInstitucion, setHelperCelularInstitucion] = useState('')
    const [departamentoInstitucion, setDepartamentoInstitucion] = useState('')
    const [municipioInstitucion, setMunicipioInstitucion] = useState('')
    const [direccionInstitucion, setDireccionInstitucion] = useState("")
    const [errorDireccionInstitucion, setErrorDireccionInstitucion] = useState(false)
    const [helperDireccionInstitucion, setHelperDireccionInstitucion] = useState('')
    const [tipoInstitucion, setTipoInstitucion] = useState(1)
    const [sectorInstitucion, setSectorInstitucion] = useState('')
    const [ministerioInstitucion, setMinisterioInstitucion] = useState('')
    const [institucionFileNumPages, setInstitucionFileNumPages] = useState(null)
    const [institucionFilePageNumber, setInstitucionFilePageNumber] = useState(1)

    //Estados para responsable
    const [cargos, setCargos] = useState([])
    const [nombresResponsable, setNombresResponsable] = useState('')
    const [errorNombresResponsable, setErrorNombresResponsable] = useState(false)
    const [helperNombresResponsable, setHelperNombresResponsable] = useState('')
    const [apellidosResponsable, setApellidosResponsable] = useState('')
    const [errorApellidosResponsable, setErrorApellidosResponsable] = useState(false)
    const [helperApellidosResponsable, setHelperApellidosResponsable] = useState('')
    const [duiResponsable, setDuiResponsable] = useState('')
    const [errorDuiResponsable, setErrorDuiResponsable] = useState(false)
    const [helperDuiResponsable, setHelperDuiResponsable] = useState('')
    const [archivoResponsable, setArchivoResponsable] = useState(null)
    const [objArchivoResponsable, setObjArchivoResponsable] = useState(null)
    const [telefonoResponsable, setTelefonoResponsable] = useState('')
    const [errorTelefonoResponsable, setErrorTelefonoResponsable] = useState(false)
    const [helperTelefonoResponsable, setHelperTelefonoResponsable] = useState('')
    const [celularResponsable, setCelularResponsable] = useState('')
    const [errorCelularResponsable, setErrorCelularResponsable] = useState(false)
    const [helperCelularResponsable, setHelperCelularResponsable] = useState('')
    const [cargoResponsable, setCargoResponsable] = useState('')
    const [representanteLegalFileNumPages, setRepresentanteLegalFileNumPages] = useState(null)
    const [representanteLegalFilePageNumber, setRepresentanteLegalFilePageNumber] = useState(1)
    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openRepetido, setOpenRepetido] = useState(false)

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estado para progress, el setLoading siempre debe ser llamado en funciones
    //asincronas para funcionar correctamente
    const [loading, setLoading] = useState(false)

    // *** Funciones para mostrar los datos al editar ***

    const {getSolicitudById} = useSolicitudCotizacionService()
    const { getInstitucionById, getArchivoInstitucion, getArchivoRepresentanteLegal } = useResumenService()
    const { getArchivoUsuario } = useUsuarioService()
    const [solicitud, setSolicitud] = useState('') // Almacena la solicitud si se encuentra en el modo editar
    const [idUsuario, setIdUsuario] = useState('')
    const [idInstitucion, setIdInstitucion] = useState('')
    const [idResponsable, setIdResponsable] = useState('')
    const [idDireccion, setIdDireccion] = useState('')

    //Estados para el archivo del usuario

    function onDocumentLoadSuccess({ numPages }) {
        setFileNumPages(numPages);
    }

    const handleCloseFileModal = () => {
        setOpenFileModal(false)
    }
    const handleFilePageChange = (event, value) => {
        setPageNumber(value)
    }

     //Estados del archivo de la institucion

     function onInstitucionDocumentLoadSuccess({ numPages }) {
         setInstitucionFileNumPages(numPages);
     }
     const [openInstitucionFileModal, setOpenInstitucionFileModal] = useState(false)
     const handleCloseInstitucionFileModal = () => {
         setOpenInstitucionFileModal(false)
     }
     const handleInstitucionFilePageChange = (event, value) => {
         setInstitucionFilePageNumber(value)
     }

     //Estados del archivo del representante legal

     function onRepresentanteLegalDocumentLoadSuccess({ numPages }) {
         setRepresentanteLegalFileNumPages(numPages);
     }
     const [openRepresentanteLegalFileModal, setOpenRepresentanteLegalFileModal] = useState(false)
     const handleCloseRepresentanteLegalFileModal = () => {
         setOpenRepresentanteLegalFileModal(false)
     }
     const handleRepresentanteLegalFilePageChange = (event, value) => {
         setRepresentanteLegalFilePageNumber(value)
     }

    //  Funcion que obtiene el archivo del usuario
    const handleGetUsuarioFile = useCallback(async (usuario) => {
        startLoading()
        const file = await getArchivoUsuario(usuario)
        if (file.error) {
            handleOpenAlert('error', 'Ocurrió un error al cargar el archivo del usuario')
            // stopLoading()
        }
        else {
            const blob = new Blob([file.data])
            setArchivoUsuario(blob)
            setObjArchivoUsuario([{name: getFilenameFromContentDisposition
                (file.headers['content-disposition']), deleteFile: () => {
                    setObjArchivoUsuario(null)
                    setArchivoUsuario(null)
            },
            onClickChip: () => {setOpenFileModal(true)}
            }])
        }
        stopLoading()
    }, [getArchivoUsuario])

    // Funcion que obtiene el archivo de la institucion
    const handleGetInstitucionFile = useCallback(async (institucion) => {
        startLoading()
        const file = await getArchivoInstitucion(institucion)
        if (file.error){
            handleOpenAlert('error', 'Ocurrió un error al cargar el archivo de la institución')
        }
        else{
            const blob = new Blob([file.data])
            setArchivoInstitucion(blob)
            setObjArchivoInstitucion([{ name: getFilenameFromContentDisposition
                (file.headers['content-disposition']), deleteFile: () => {
                setObjArchivoInstitucion(null)
                setArchivoInstitucion(null)
            },
            onClickChip: () => {setOpenInstitucionFileModal(true)}
            }])
        }
        stopLoading()
    }, [getArchivoInstitucion])

    // Funcion que obtiene el archivo del representante legal
    const handleGetRepresentanteLegalFile = useCallback(async (institucion) => {
        const file = await getArchivoRepresentanteLegal(institucion)
        if (file.error){
            handleOpenAlert('error', 'Ocurrió un error al cargar el archivo del representante legal')
        }
        else{
            const blob = new Blob([file.data])
            setArchivoResponsable(blob)
            setObjArchivoResponsable([{ name: getFilenameFromContentDisposition
                (file.headers['content-disposition']), deleteFile: () => {
                setObjArchivoResponsable(null)
                setArchivoResponsable(null)
            },
            onClickChip: () => {setOpenRepresentanteLegalFileModal(true)}
            }])
        }
    }, [getArchivoRepresentanteLegal])

    // ******

    const handleAcceptDialog = () => {
        onUpdateSolicitud(true)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorNombreUsuario || nombreUsuario === "" || errorCelularUsuario ||
            errorCorreoElectronicoUsuario || correoElectronicoUsuario === "" ||
            errorDuiUsuario || duiUsuario === "" || errorTelefonoUsuario ||
            errorNombreInstitucion || nombreInstitucion === "" ||
            errorNitInstitucion || nitInstitucion === "" ||
            errorTelefonoInstitucion || errorCelularInstitucion ||
            municipioInstitucion === "" || errorDireccionInstitucion ||
            direccionInstitucion === "" || sectorInstitucion === "" ||
            errorNombresResponsable || nombresResponsable === "" ||
            errorApellidosResponsable || apellidosResponsable === "" ||
            errorDuiResponsable || duiResponsable === "" ||
            errorTelefonoResponsable || errorCelularResponsable ||
            archivoUsuario || archivoInstitucion ||
            archivoResponsable)
        {
            //TODO verificar validaciones
            //return true
        }
        return false
    }

    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    //Funciones para controlar cambios en inputs de usuario
    const onChangeNombreUsuario = (text)  =>
    {
        setNombreUsuario(text)
        if(!allLetterAndSpace(text)){
            setErrorNombreUsuario(true)
            setHelperNombreUsuario("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombreUsuario(false)
            setHelperNombreUsuario("")
        }
    }

    const onChangeDuiUsuario = (text)=>
    {
        setDuiUsuario(text)
        if(!isDUI(text) && text !== ""){
            setErrorDuiUsuario(true)
            setHelperDuiUsuario("Debe ingresar un DUI valido. Ej. 99999999-9")
        }else{
            setErrorDuiUsuario(false)
            setHelperDuiUsuario("")
        }
    }

    const handleOnChangeArchivoUsuario = (file) => {
        setArchivoUsuario(file[0])
        setObjArchivoUsuario([{ name: file[0].name, deleteFile: () => {
            setObjArchivoUsuario(null)
            setArchivoUsuario(null)
        },
        onClickChip: () => {setOpenFileModal(true)}
        }])
    }

    const onChangeTelefonoUsuario = (text)=>
    {
        setTelefonoUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoUsuario(true)
            setHelperTelefonoUsuario("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorTelefonoUsuario(false)
            setHelperTelefonoUsuario("")
        }
    }

    const onChangeCelularUsuario = (text)=>
    {
        setCelularUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularUsuario(true)
            setHelperCelularUsuario("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorCelularUsuario(false)
            setHelperCelularUsuario("")
        }
    }

    const onChangeCorreoElectronicoUsuario = (text)=>
    {
        setCorreoElectronicoUsuario(text)
        if(!isEmail(text) && text !== ""){
            setErrorCorreoElectronicoUsuario(true)
            setHelperCorreoElectronicoUsuario("Debe ingresar un correo electronico valido.")
        }else{
            setErrorCorreoElectronicoUsuario(false)
            setHelperCorreoElectronicoUsuario("")
        }
    }

    const onChangeContrasenia1Usuario = (text)=>
    {
        setContrasenia1Usuario(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia1Usuario(true)
            setHelperContrasenia1Usuario("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia1Usuario(false)
            setHelperContrasenia1Usuario("")
        }
    }

    const onChangeContrasenia2Usuario = (text)=>
    {
        setContrasenia2Usuario(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia2Usuario(true)
            setHelperContrasenia2Usuario("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia2Usuario(false)
            setHelperContrasenia2Usuario("")
        }
    }

    //Funciones manejar datos de institucion
    const onChangeNombreInstitucion = (text)  =>
    {
        setNombreInstitucion(text)
        if(!allLetterAndSpace(text)){
            setErrorNombreInstitucion(true)
            setHelperNombreInstitucion("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombreInstitucion(false)
            setHelperNombreInstitucion("")
        }
    }

    const onChangeNitInstitucion = (text)=>
    {
        setNitInstitucion(text)
        if(!isNIT(text) && text !== ""){
            setErrorNitInstitucion(true)
            setHelperNitInstitucion("Debe ingresar un NIT valido. Ej. 9999-999999-999-9")
        }else{
            setErrorNitInstitucion(false)
            setHelperNitInstitucion("")
        }
    }

    const handleOnChangeArchivoInstitucion = (file) => {
        setArchivoInstitucion(file[0])
        setObjArchivoInstitucion([{ name: file[0].name, deleteFile: () => {
            setObjArchivoInstitucion(null)
            setArchivoInstitucion(null)
        },
        onClickChip: () => {setOpenInstitucionFileModal(true)}
        }])
    }

    const onChangeTelefonoInstitucion = (text)=>
    {
        setTelefonoInstitucion(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoInstitucion(true)
            setHelperTelefonoInstitucion("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorTelefonoInstitucion(false)
            setHelperTelefonoInstitucion("")
        }
    }

    const onChangeCelularInstitucion = (text)=>
    {
        setCelularInstitucion(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularInstitucion(true)
            setHelperCelularInstitucion("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorCelularInstitucion(false)
            setHelperCelularInstitucion("")
        }
    }

    const onChangeDireccionInstitucion = (text)=>
    {
        setDireccionInstitucion(text)
    }

    //Funciones para controlar cambios en inputs de responsable
    const onChangeNombresResponsable = (text)  =>
    {
        setNombresResponsable(text)
        if(!allLetterAndSpace(text)){
            setErrorNombresResponsable(true)
            setHelperNombresResponsable("Los nombres solo deben consistir de letras.")
        }else{
            setErrorNombresResponsable(false)
            setHelperNombresResponsable("")
        }
    }

    const onChangeApellidosResponsable = (text)  =>
    {
        setApellidosResponsable(text)
        if(!allLetterAndSpace(text)){
            setErrorApellidosResponsable(true)
            setHelperApellidosResponsable("Los apellidos solo deben consistir de letras.")
        }else{
            setErrorApellidosResponsable(false)
            setHelperApellidosResponsable("")
        }
    }

    const onChangeDuiResponsable = (text)=>
    {
        setDuiResponsable(text)
        if(!isDUI(text) && text !== ""){
            setErrorDuiResponsable(true)
            setHelperDuiResponsable("Debe ingresar un DUI valido. Ej. 99999999-9")
        }else{
            setErrorDuiResponsable(false)
            setHelperDuiResponsable("")
        }
    }

    const handleOnChangeArchivoResponsable = (file) => {
        setArchivoResponsable(file[0])
        setObjArchivoResponsable([{ name: file[0].name, deleteFile: () => {
            setObjArchivoResponsable(null)
            setArchivoResponsable(null)
        },
        onClickChip: () => {setOpenRepresentanteLegalFileModal(true)}
        }])
    }

    const onChangeTelefonoResponsable = (text)=>
    {
        setTelefonoResponsable(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoResponsable(true)
            setHelperTelefonoResponsable("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorTelefonoResponsable(false)
            setHelperTelefonoResponsable("")
        }
    }

    const onChangeCelularResponsable = (text)=>
    {
        setCelularResponsable(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularResponsable(true)
            setHelperCelularResponsable("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorCelularResponsable(false)
            setHelperCelularResponsable("")
        }
    }

    const handleOnProgress = (loaded, total) => {
        const progress = (loaded/total)*100
        setProgress(progress)
    }

    //Funcion para manejar la creacion de una solicitud de inscripcion
    const onCreateSolicitud = async (responsableRepetido) => {
    }

    //Para manejar la edicion de los datos de una solicitud de inscripcion
    const onUpdateSolicitud = async (responsableRepetido) => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        if(contrasenia1Usuario !== contrasenia2Usuario){
            handleOpenAlert('error', "Las contraseñas del usuario introducidas no coinciden.")
            return
        }
        if(tipoInstitucion === 1 && !ministerioInstitucion){
            handleOpenAlert('error', "Debe seleccionar un ministerio para las instituciones publicas.")
            return
        }
        // console.log('responsableRepetido', responsableRepetido)
        let updateSolicitud = new FormData()
        // Datos del usuario
        updateSolicitud.append("user[id]", idUsuario)
        updateSolicitud.append("user[nombres]", nombreUsuario)
        updateSolicitud.append("user[email]", correoElectronicoUsuario)
        // updateSolicitud.append("user[password]", contrasenia1Usuario)
        // updateSolicitud.append("user[password_confirmation]", contrasenia2Usuario)
        updateSolicitud.append("user[dui]", duiUsuario)
        updateSolicitud.append("user[telefono]", telefonoUsuario)
        updateSolicitud.append("user[telefono_celular]", celularUsuario)
        updateSolicitud.append("user[archivo_documento_identidad]", archivoUsuario)
        // Datos institucion
        updateSolicitud.append("institucion[id]", idInstitucion)
        updateSolicitud.append("institucion[nombre]", nombreInstitucion)
        updateSolicitud.append("institucion[nit]", nitInstitucion)
        updateSolicitud.append("institucion[telefono]", telefonoInstitucion)
        updateSolicitud.append("institucion[telefono_celular]", celularInstitucion)
        updateSolicitud.append("institucion[sector_id]", sectorInstitucion)
        updateSolicitud.append("institucion[tipo_institucion_id]", tipoInstitucion)
        updateSolicitud.append("institucion[direccion_id]", idDireccion)
        //Se envia ministerio_id solo si el tipo de institucion es publica
        if(tipoInstitucion === 1){
            updateSolicitud.append("institucion[ministerio_id]", ministerioInstitucion)
        }
        updateSolicitud.append("institucion[direccion][detalle]", direccionInstitucion)
        updateSolicitud.append("institucion[direccion][municipio_id]", municipioInstitucion)
        updateSolicitud.append("institucion[archivo_documento_identidad]", archivoInstitucion)
        // Datos del responsable
        updateSolicitud.append("responsable[id]", idResponsable)
        updateSolicitud.append("responsable[nombres]", nombresResponsable)
        updateSolicitud.append("responsable[apellidos]", apellidosResponsable)
        updateSolicitud.append("responsable[dui]", duiResponsable)
        updateSolicitud.append("responsable[cargo]", cargoResponsable)
        updateSolicitud.append("responsable[telefono]", telefonoResponsable)
        updateSolicitud.append("responsable[telefono_celular]", celularResponsable)
        updateSolicitud.append("responsable[archivo_documento_identidad]", archivoResponsable)
        updateSolicitud.append("responsable[responsable_existente]", responsableRepetido ? 1 : 0)
        // console.log("Solicitud editada en modo de edicion")
        // Display the key/value pairs
        // for(var pair of updateSolicitud.entries()) {
        //     console.log(pair[0]+ ', '+ pair[1]);
        // }
        startLoading()
        // const result = await enviarSolicitudInscripcionEdit(updateSolicitud)
        // console.log({progress})
        const result = await updateSolicitudInscripcion(updateSolicitud, handleOnProgress)
        if (result.error){
            if(result.data){
                if(result.data.error.responsable_existente){
                    setOpenRepetido(true)
                }else{
                    setOpenRepetido(false)
                    handleOpenAlert('error', result.error)
                }
            }
        }
        else{
            setOpenRepetido(false)
            handleOpenAlert('success', `Se envio la solicitud actualizada correctamente.`)
        }
        stopLoading()
    }

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        usuarioData: {
            nombreUsuario,
            errorNombreUsuario,
            helperNombreUsuario,
            duiUsuario,
            objArchivoUsuario,
            errorDuiUsuario,
            helperDuiUsuario,
            telefonoUsuario,
            errorTelefonoUsuario,
            helperTelefonoUsuario,
            celularUsuario,
            errorCelularUsuario,
            helperCelularUsuario,
            correoElectronicoUsuario,
            errorCorreoElectronicoUsuario,
            helperCorreoElectronicoUsuario,
            contrasenia1Usuario,
            errorContrasenia1Usuario,
            helperContrasenia1Usuario,
            contrasenia2Usuario,
            errorContrasenia2Usuario,
            helperContrasenia2Usuario,
            idSolicitud, // *** Variable que contiene el id de la solicitud ***
            openFileModal, fileNumPages, pageNumber, idUsuario,
            archivoUsuario
        },
        institucionData: {
            tiposInstitucion,
            sectores,
            ministerios,
            municipiosCombo,
            departamentosCombo,
            nombreInstitucion,
            errorNombreInstitucion,
            helperNombreInstitucion,
            nitInstitucion,
            objArchivoInstitucion,
            errorNitInstitucion,
            helperNitInstitucion,
            telefonoInstitucion,
            errorTelefonoInstitucion,
            helperTelefonoInstitucion,
            celularInstitucion,
            errorCelularInstitucion,
            helperCelularInstitucion,
            departamentoInstitucion,
            municipioInstitucion,
            direccionInstitucion,
            errorDireccionInstitucion,
            helperDireccionInstitucion,
            tipoInstitucion,
            ministerioInstitucion,
            sectorInstitucion,
            idSolicitud, // *** Variable que contiene el id de la solicitud ***
            idInstitucion,
            openInstitucionFileModal,
            institucionFilePageNumber,
            institucionFileNumPages,
            archivoInstitucion
        },
        responsableData: {
            cargos,
            nombresResponsable,
            errorNombresResponsable,
            helperNombresResponsable,
            apellidosResponsable,
            errorApellidosResponsable,
            helperApellidosResponsable,
            duiResponsable,
            objArchivoResponsable,
            errorDuiResponsable,
            helperDuiResponsable,
            telefonoResponsable,
            errorTelefonoResponsable,
            helperTelefonoResponsable,
            celularResponsable,
            errorCelularResponsable,
            helperCelularResponsable,
            cargoResponsable,
            openRepetido,
            idSolicitud, // *** Variable que contiene el id de la solicitud ***
            openRepresentanteLegalFileModal,
            representanteLegalFilePageNumber, representanteLegalFileNumPages, idInstitucion,
            archivoResponsable
        },
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        paso,
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        usuarioActions: {
            onChangeNombreUsuario,
            onChangeDuiUsuario,
            handleOnChangeArchivoUsuario,
            onChangeTelefonoUsuario,
            onChangeCelularUsuario,
            onChangeCorreoElectronicoUsuario,
            onChangeContrasenia1Usuario,
            onChangeContrasenia2Usuario,
            // *** Para las opciones de editar documento ***
            handleCloseFileModal, onDocumentLoadSuccess, handleFilePageChange,
        },
        institucionActions: {
            onChangeNombreInstitucion,
            onChangeNitInstitucion,
            handleOnChangeArchivoInstitucion,
            onChangeTelefonoInstitucion,
            onChangeCelularInstitucion,
            setDepartamentoInstitucion,
            setMunicipioInstitucion,
            onChangeDireccionInstitucion,
            setTipoInstitucion,
            setMinisterioInstitucion,
            setSectorInstitucion,
            handleCloseInstitucionFileModal, //*** Para ver el archivo PDF en la edicion de datos ***
            onInstitucionDocumentLoadSuccess, handleInstitucionFilePageChange,
        },
        responsableActions: {
            onChangeNombresResponsable,
            onChangeApellidosResponsable,
            onChangeDuiResponsable,
            handleOnChangeArchivoResponsable,
            onChangeTelefonoResponsable,
            onChangeCelularResponsable,
            setCargoResponsable,
            onCreateSolicitud,
            setOpenRepetido,
            handleAcceptDialog,
            handleCloseRepresentanteLegalFileModal, // *** Para ver el archivo PDF al editar ***
            onRepresentanteLegalDocumentLoadSuccess, handleRepresentanteLegalFilePageChange,
            onUpdateSolicitud,
        },
        setAlertOpen
    }

    const handleGetMinisterios = useCallback(async () => {
        startLoading()
        const ministerios = await getMinisteriosSolicitud()
        if(ministerios.error){
            handleOpenAlert('error', ministerios.error)
        }
        else{
            setMinisterios([])
            ministerios.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const ministerioComboBox = {
                    label,
                    value: value.toString()
                }
                setMinisterios((ministerios) => [...ministerios, ministerioComboBox])
            })
        }
        stopLoading()
    }, [getMinisteriosSolicitud])

    const handleGetSectores = useCallback(async () => {
        startLoading()
        const sectores = await getSectoresSolicitud()
        if(sectores.error){
            handleOpenAlert('error', sectores.error)
        }
        else{
            setSectores([])
            sectores.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const sectorComboBox = {
                    label,
                    value: value.toString()
                }
                setSectores((sectores) => [...sectores, sectorComboBox])
            })
        }
        stopLoading()
    }, [getSectoresSolicitud])

    const handleGetCargos = useCallback(async () => {
        startLoading()
        const cargos = await getCargosSolicitud()
        if(cargos.error){
            handleOpenAlert('error', cargos.error)
        }
        else{
            cargos.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const cargoComboBox = {
                    label,
                    value: value.toString()
                }
                setCargos((cargos) => [...cargos, cargoComboBox])
            })
        }
        stopLoading()
    }, [getCargosSolicitud])

    const handleGetDepartamentos = useCallback(async () => {
        startLoading()
        const departamentos = await getDepartamentos()
        if(departamentos.error){
            handleOpenAlert('error', departamentos.error)
        }
        else{
            setDepartamentos(departamentos)
            setDepartamentosCombo([])
            departamentos.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const departamentoComboBox = {
                    label,
                    value: value.toString()
                }
                setDepartamentosCombo((departamentosCombo) => [...departamentosCombo, departamentoComboBox])
            })
        }
        stopLoading()
    }, [getDepartamentos])

    // *** Para cargar datos de la solicitud al editar ***
    const handleGetSolicitud = useCallback(async () => {
        startLoading()
        const soli = await getSolicitudById(Number(idSolicitud))
        if (soli.error){
            handleOpenAlert('error', 'Ocurrió un error al obtener los datos de la solicitud')
        }
        else {
            if (soli.length === 0) {
                handleOpenAlert('error', 'No existe la solicitud')
            } else {
                if (soli[0].tipo_solicitud_id === 1) {
                    setSolicitud(soli[0])
                    setIdInstitucion(soli[0].institucion_id)
                    //setEstadoSolicitud(Number(soli[0].estado_solicitud_id))
                }
            }
        }
        stopLoading()
    }, [getSolicitudById, idSolicitud])

    const handleGetDatosEditarEtapa1 = useCallback(async () => {
        startLoading()
        const institucionSolicitud = await getInstitucionById(solicitud.institucion_id)
        stopLoading()
        if (institucionSolicitud.error){
            handleOpenAlert('error', 'Ocurrió un error al obtener los datos de la institucion')
            stopLoading()
            return
        }
        else{
            stopLoading()
            const { institucion, representanteLegal } = institucionSolicitud
            const { direccion, tipo_institucion, usuario } = institucion

            // Para los datos del usuario
            const { nombres: nombre_usuario, dui: dui_usuario, telefono: telefono_usuario,
                telefono_celular: celular_usuario, email: email_usuario, id: id_usuario,
                role_id } = usuario

            // Para los datos de la institucion
            const { nombre: nombre_institucion, nit: nit_institucion, telefono: telefono_institucion,
                telefono_celular: celular_institucion } = institucion

            // Para la direccion, el municipio y el departamento de la institucion
            const { detalle: direccion_institucion, municipio, id: id_direccion } = direccion
            const { id: id_municipio, departamento_id } = municipio

            // Para el tipo de institucion
            const { id: tipo_institucion_id, nombre: nombre_tipo_institucion } = tipo_institucion

            // Para el sector de la institucion
            const { sector_id } = institucion

            // Para el ministerio de la institucion
            const { ministerio_id } = institucion

            // Para el representante legal
            const { nombres: nombre_representante_legal, apellidos: apellido_representante_legal,
                cargo: cargo_representante_legal, dui: dui_representante_legal, telefono: telefono_representante_legal,
                telefono_celular: celular_representante_legal, id: id_representante_legal } = representanteLegal

            // Datos del usuario
            setNombreUsuario(nombre_usuario)
            setDuiUsuario(dui_usuario)
            setTelefonoUsuario(telefono_usuario)
            setCelularUsuario(celular_usuario)
            setCorreoElectronicoUsuario(email_usuario)
            setContrasenia1Usuario("")
            setContrasenia2Usuario("")
            setIdUsuario(id_usuario)

            // Datos de la institucion
            setNombreInstitucion(nombre_institucion)
            setNitInstitucion(nit_institucion)
            setTelefonoInstitucion(telefono_institucion)
            setCelularInstitucion(celular_institucion)
            setDireccionInstitucion(direccion_institucion)
            setMunicipioInstitucion(id_municipio.toString())
            setDepartamentoInstitucion(departamento_id.toString())
            setTipoInstitucion(tipo_institucion_id.toString())
            setSectorInstitucion(sector_id.toString())
            setIdDireccion(id_direccion)
            // Verifica si la institución es pública
            if (ministerio_id !== null) {
                setMinisterioInstitucion(ministerio_id.toString())
            }
            // Datos del representante legal
            setNombresResponsable(nombre_representante_legal)
            setApellidosResponsable(apellido_representante_legal)
            setDuiResponsable(dui_representante_legal)
            setTelefonoResponsable(telefono_representante_legal)
            setCelularResponsable(celular_representante_legal)
            setCargoResponsable(cargo_representante_legal)
            setIdResponsable(id_representante_legal)
            await Promise.allSettled([
                handleGetUsuarioFile(institucion.usuario.id),
                handleGetInstitucionFile(institucion.id),
                handleGetRepresentanteLegalFile(institucion.id)
            ])
        }
    }, [getInstitucionById, solicitud, handleGetUsuarioFile, handleGetInstitucionFile,
        handleGetRepresentanteLegalFile])


    const loadDataEditar = useCallback(async() => {
        if (Number(idSolicitud)) {
            await Promise.allSettled([
                handleGetMinisterios(),
                handleGetSectores(),
                handleGetDepartamentos(),
                handleGetSolicitud(),
            ])
        }
    }, [idSolicitud])

    useEffect(() => {
        loadDataEditar()
    }, [loadDataEditar])


    useEffect(() => {
        if (Number(idSolicitud)) {
            if (solicitud) {
                handleGetDatosEditarEtapa1()
            }
        }
    }, [idSolicitud, solicitud, handleGetDatosEditarEtapa1])

    // ****

    //useEffect para cargar combobox de municipios al seleccionar departamento
    useEffect(() => {
        if(Number(paso) === 2){
            setMunicipiosCombo([])
            const municipiosDepartamento = departamentos.find( departamento => departamento.id === Number(departamentoInstitucion) )
            municipiosDepartamento && municipiosDepartamento.municipios.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const municipioComboBox = {
                    label,
                    value: value.toString()
                }
                setMunicipiosCombo((municipiosCombo) => [...municipiosCombo, municipioComboBox])
            })
        }
    }, [paso, departamentos, departamentoInstitucion])

    return {data, actions}
}

export default useSolicitudInscripcionEditPage
