import React from "react";
import Alert from "../../components/Alert";
import AppFrame from "../../components/AppFrame";
import ComboBox from "../../components/ComboBox";
import FormHeader from "../../components/FormHeader";
import Input from "../../components/Input";
import Button from "../../components/Button";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import Pagination from "../../components/Pagination";
import Grid from "@material-ui/core/Grid";
import { RiGroupLine, RiRestartLine } from "react-icons/ri";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import useUsuarioPage from "../../hooks/administracion/useUsuarioPage";
import Progress from "../../components/Progress/Progress";
import FileInput from "../../components/FileInput";
import MaterialDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PdfViewer from "../../components/PdfViewer";
import { MaskField } from "react-mask-field";
import useClientePage from "../../hooks/controlDeCalidad/useClientePage";
import useDepartamentosService from "../../services/inscripcion/useDepartamentosService";
import { useState, useEffect, useCallback } from "react";
import MunicipioSelector from "./inputDepartamentoyMunicipio";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

function CustomMaskField({ inputRef, ...otherProps }) {
  return (
    <MaskField
      ref={inputRef}
      mask="________-_"
      replacement={{ _: /\d/ }}
      {...otherProps}
    />
  );
}

function CustomMaskNumberField({ inputRef, ...otherProps }) {
  return (
    <MaskField
      ref={inputRef}
      mask="____-____"
      replacement={{ _: /\d/ }}
      {...otherProps}
    />
  );
}

export const FormCliente = ({ data, actions, classes }) => {
  const {
    id,
    nombreCliente,
    errorNombreCliente,
    helperNombreCliente,
    detalleDireccion,
    errorDetalleDireccion,
    helperDetalleDireccion,
    departamentos,
    idDepartamento,
    helperIdDepartamento,
    errorIdDepartamento,
    idMunicipio,
    helperIdMunicipio,
    errorIdMunicipio,
    correoCliente,
    errorCorreoCliente,
    helperCorreoCliente,
    telefonoCliente,
    errorTelefonoCliente,
    helperTelefonoCliente,
    convencionalMovil,
    errorConvencionalMovil,
    helperConvencionalMovil,
    convencionalFijo,
    errorConvencionalFijo,
    helperConvencionalFijo,
    convencionalCflouro,
    errorConvencionalCflouro,
    helperConvencionalCflouro,
    brazoC,
    errorBrazoC,
    helperBrazoC,
    mamografico,
    errorMamografico,
    helperMamografico,
    dentalPeriapical,
    errorDentalPeriapical,
    helperDentalPeriapical,
    dentalPanoramico,
    errorDentalPanoramico,
    helperDentalPanoramico,
    tomografiaComputarizada,
    errorTomografiaComputarizada,
    helperTomografiaComputarizada,
    cleanCombobox,
  } = data;

  const {
    onChangeNombreCliente,
    onChangeDetalleDireccion,
    onChangeIdDepartamento,
    onChangeIdMunicipio,
    onChangeConvencionalMovil,
    onChangeConvencionalFijo,
    onChangeConvencionalCflouro,
    onChangeBrazoC,
    onChangeMamografico,
    onChangeDentalPeriapical,
    onChangeDentalPanoramico,
    onChangeTomograficaComputarizada,
    onChangeCorreoCliente,
    onChangeTelefonoCliente,
  } = actions;

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Input
          type="text"
          label="Nombre"
          placeholder="Ingrese el nombre"
          value={nombreCliente}
          onChange={onChangeNombreCliente}
          error={errorNombreCliente}
          helper={helperNombreCliente}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          type="text"
          label="Detalle de dirección"
          placeholder="Ingrese el detalle de la dirección"
          value={detalleDireccion}
          onChange={onChangeDetalleDireccion}
          error={errorDetalleDireccion}
          helper={helperDetalleDireccion}
          required={true}
          //InputProps={{ inputComponent: CustomMaskField }}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          type="text"
          label="Telefono "
          placeholder="Ingrese el telefono"
          value={telefonoCliente}
          onChange={onChangeTelefonoCliente}
          error={errorTelefonoCliente}
          helper={helperTelefonoCliente}
          required={false}
          InputProps={{ inputComponent: CustomMaskNumberField }}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          type="text"
          label="Correo Electrónico "
          placeholder="Ingrese el correo electronico"
          value={correoCliente}
          onChange={onChangeCorreoCliente}
          error={errorCorreoCliente}
          helper={helperCorreoCliente}
          required={false}
        />
      </Grid>

      <Grid item xs={12}>
        <MunicipioSelector
          departamentos={departamentos}
          onChangeIdDepartamento={onChangeIdDepartamento}
          onChangeIdMunicipio={onChangeIdMunicipio}
          classes={classes}
          isIdSelected={id ? true : false}
          idDepartamento={idDepartamento}
          idMunicipio={idMunicipio}
          cleanCombobox={cleanCombobox}
        />
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Input
            type="text"
            label="Convencional Movil"
            placeholder="Ingrese la cantidad de Convencional Movil"
            value={convencionalMovil}
            onChange={onChangeConvencionalMovil}
            error={errorConvencionalMovil}
            helper={helperConvencionalMovil}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="text"
            label="Convencional Fijo"
            placeholder="Ingrese la cantidad del Convencional Fijo"
            value={convencionalFijo}
            onChange={onChangeConvencionalFijo}
            error={errorConvencionalFijo}
            helper={helperConvencionalFijo}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Input
            type="text"
            label="Convencional c/Flouro"
            placeholder="Ingrese la cantidad de Convencional c/Flouro"
            value={convencionalCflouro}
            onChange={onChangeConvencionalCflouro}
            error={errorConvencionalCflouro}
            helper={helperConvencionalCflouro}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>

        <Grid item xs={6}>
          <Input
            type="text"
            label="BrazoC"
            placeholder="Ingrese la cantidad de BrazoC"
            value={brazoC}
            onChange={onChangeBrazoC}
            error={errorBrazoC}
            helper={helperBrazoC}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Input
            type="text"
            label="Mamográfico"
            placeholder="Ingrese la cantidad de Mamográfico"
            value={mamografico}
            onChange={onChangeMamografico}
            error={errorMamografico}
            helper={helperMamografico}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>

        <Grid item xs={6}>
          <Input
            type="text"
            label="Dental Periapical"
            placeholder="Ingrese la cantidad de Dental Periapical"
            value={ dentalPeriapical}
            onChange={onChangeDentalPeriapical}
            error={errorDentalPeriapical}
            helper={helperDentalPeriapical}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Input
            type="text"
            label="Dental Panoramico"
            placeholder="Ingrese la cantidad de Dental Panoramico"
            value={dentalPanoramico}
            onChange={onChangeDentalPanoramico}
            error={errorDentalPanoramico}
            helper={helperDentalPanoramico}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>

        <Grid item xs={6}>
          <Input
            type="text"
            label="Tomografica Computarizada"
            placeholder="Ingrese la cantidad de TomograficaComputarizada"
            value={tomografiaComputarizada}
            onChange={onChangeTomograficaComputarizada}
            error={errorTomografiaComputarizada}
            helper={helperTomografiaComputarizada}
            required={true}
            //InputProps={{ inputComponent: CustomMaskField }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const encabezado = [
  "Nombre",
  // "Departamento",
  // "Municipio",
  // "Detalle de dirección",
  "Telefono",
  "Correo electronico",
  // "Convencional Movil",
  // "Convencional Fijo",
  // "Convencional c/Flouro",
  // "BrazoC",
  // "Mamografico",
  // "Dental Periapical",
  // "Dental Panoramico",
  // "Tomografica Computarizada",
];

const ClientePage = (props) => {
  const classes = useStyles();
  const { data, actions } = useClientePage();
  const {
    rows,
    nombreCliente,
    id,
    openDelete,
    openEdit,
    clienteToDelete,
    alertOpen,
    alertMessage,
    alertSeverity,
    page,
    numPages,
    loading,
  } = data;
  const {
    onDeleteCliente,
    onCreateCliente,
    onUpdateCliente,
    editClick,
    cancel,
    editCancel,
    handleClickOpenDelete,
    handleClickOpenEdit,
    handleCloseDelete,
    handleCloseEdit,
    findRow,
    setClienteToDelete,
    setAlertOpen,
    handlePageChange,
  } = actions;

  return (
    <AppFrame loged={false}>
      <Progress open={loading > 0} />
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper}>
            <Alert
              open={alertOpen}
              setOpen={setAlertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
            <FormHeader
              title="Registro de Clientes"
              subtitle="Datos Requeridos *"
            >
              <RiGroupLine />
            </FormHeader>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid container item xs={10}>
                <FormCliente data={data} actions={actions} classes={classes} />
                <Grid
                  container
                  item
                  className={classes.button}
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      func={id ? editCancel : cancel}
                      variant="outlined"
                      text="CANCELAR"
                    />
                  </Grid>
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      variant="contained"
                      text={id ? "EDITAR" : "AGREGAR"}
                      func={id ? handleClickOpenEdit : onCreateCliente}
                    />
                    <Dialog
                      open={openEdit}
                      handleClose={handleCloseEdit}
                      handleAccept={onUpdateCliente}
                      title={"Desea actualizar el registro?"}
                      content={`Los datos del usuario "${nombreCliente}" se actualizaran.`}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    headers={encabezado}
                    rows={rows}
                    editFnc={(key) => editClick(key)}
                    deleteFnc={(key) => handleClickOpenDelete(key)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Pagination
                    page={Number(page)}
                    numPages={Number(numPages)}
                    onChangePage={handlePageChange}
                  />
                </Grid>
                <Dialog open={openDelete}
                                    handleClose = {handleCloseDelete}
                                    handleAccept = {onDeleteCliente}
                                    title = {"Desea eliminar el registro?"}
                                    content = {
                                        findRow(clienteToDelete, rows) ?
                                        `El cliente con nombre "${ findRow(clienteToDelete, rows).cells[0] }" sera eliminado del sistema.`
                                        :
                                        ""
                                        }
                                    transitionFunc = {() =>
                                        setClienteToDelete(null)}/>

                {/* <Dialog open={openDelete}
                                    handleClose = {handleCloseDelete}
                                    handleAccept = {onDeleteUsuario}
                                    title = {"Desea eliminar el registro?"}
                                    content = {
                                        findRow(usuarioToDelete, rows) ?
                                        `El usuario con nombre "${ findRow(usuarioToDelete, rows).cells[0] }" sera desactivado del sistema.`
                                        :
                                        ""
                                        }
                                    transitionFunc = {() =>
                                        setUsuarioToDelete(null)}/> */}

                {/* } */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </AppFrame>
  );
};
export default ClientePage;
