import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useMinisterioService from '../../services/administracion/useMinisterioService'
import { mensajeCamposNoValidos } from '../../utils/messages'
import { addRow, findRow, updateRow } from '../../utils/rows'
import { allLetterAndSpace } from '../../utils/validations'
import useIsLoged from '../utils/useIsLoged'

const useMinisterioPage = () => {
    const { createMinisterio, getMinisterioById, getMinisteriosByStatus, 
        updateMinisterio, deleteMinisterio } = useMinisterioService()

    const history = useHistory()
    useIsLoged()
    const {id} = useParams()
    
    const [error, setError] = useState(null)
    const [rows, setRows] = useState([])
    const [nombre, setNombre] = useState('')
    const [errorNombre, setErrorNombre] = useState(false)
    const [helperNombre, setHelperNombre] = useState('')
    
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [ministerioToDelete, setMinisterioToDelete] = useState(null)

    //Estado para ver registros con estado = false (desactivados)
    const [verDesactivados, setVerDesactivados] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    const [loading, setLoading] = useState(0)
    
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    const handleDesactivadosChange = () => {
        setVerDesactivados(
            (verDesactivados) => !verDesactivados
        )
    }

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setMinisterioToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const onChangeNombre = (text)=>
    {
        setNombre(text)
        if(!allLetterAndSpace(text) && text !== ""){
            setErrorNombre(true)
            setHelperNombre("El nombre debe consistir solo de letras.")
        }else{
            setErrorNombre(false)
            setHelperNombre("")
        }
    }

    const checkForErrors = () => {
        if (errorNombre || nombre === "")
        {
            return true
        }
        return false
    }

    const onCreateMinisterio = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const newMinisterio = {
            "nombre": nombre
        }
        startLoading()
        const result = await createMinisterio(newMinisterio)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            //const cell = [nombre]
            //const key = id
            //addRow(key, cell, setRows)
            getMinisterios()
            handleOpenAlert('success', `El ministerio "${nombre}" se creo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onUpdateMinisterio = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const ministerioActualizado = {
            "nombre": nombre
        }
        startLoading()
        const result = await updateMinisterio(id, ministerioActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre} = result
            const newCells = [nombre]
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `El ministerio "${nombre}" se actualizo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onDeleteMinisterio = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteMinisterio(ministerioToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            //deleteRow(id, setRows)
            getMinisterios()
            editCancel()
            handleOpenAlert('success', `El ministerio "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }


    const getMinisterio = useCallback(async (id) => {
        startLoading()
        const ministerio = await getMinisterioById(id)
        if (ministerio.error){
            handleOpenAlert('error', ministerio.error)
        }
        else{
            setNombre(ministerio.nombre)
        }
        stopLoading()
        }, [getMinisterioById])

    const getMinisterios = useCallback(async () => {
        const estado = verDesactivados ? 0 : 1
        startLoading()
        const ministerios = await getMinisteriosByStatus(estado, page)
        if (ministerios.error){
            setError(ministerios.error)
        }
        else{
            setRows([])
            setNumPages(ministerios.last_page)
            ministerios.data.forEach(({id, nombre}) => {
                const key = id
                const cell = [nombre]
                addRow(key, cell, setRows)
            })
            if(page > ministerios.last_page){
                setPage(ministerios.last_page)
                history.push(`${window.location.pathname}?page=${ministerios.last_page}`)
            }
        }
        stopLoading()
    }, [getMinisteriosByStatus, history, page, verDesactivados])

    const editClick = (id) => {
        history.push(`/admin/ministerio/${id}?page=${page}`)
    }

    const editCancel = () => {
        cancel()
        history.push(`/admin/ministerio?page=${page}`)
    }

    const cancel = () => {
        setNombre("")
        setErrorNombre(false)
    }

    const data = {
        rows,
        nombre,
        errorNombre,
        helperNombre,
        id,
        openEdit,
        openDelete,
        ministerioToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        verDesactivados,
        loading
    }

    const actions = {
        onDeleteMinisterio,
        onCreateMinisterio,
        onUpdateMinisterio,
        editClick,
        onChangeNombre,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setMinisterioToDelete,
        setAlertOpen,
        handlePageChange,
        handleDesactivadosChange
    }

    //useEffect para cargar datos en la tabla
    useEffect(() => {
        getMinisterios()
    }, [getMinisterios])
    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getMinisterio(id)
        }
    }, [id, getMinisterio])
    return {error, data, actions}
}

export default useMinisterioPage