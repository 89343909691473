import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useEffect } from "react";

function HorarioComponent({ setHorario, horario }) {
  const [horaInicio, setHoraInicio] = useState("");
  const [horaFin, setHoraFin] = useState("");
  const [error, setError] = useState(false);

  const handleHoraInicioChange = (e) => {
    const newHoraInicio = e.target.value;
    setHoraInicio(newHoraInicio);
    if (new Date(newHoraInicio) >= new Date(horaFin)) {
      setError(true);
    } else {
      setError(false);
      setHorario({
        horaInicio: newHoraInicio,
        horaFin,
      });
    }
  };

  const handleHoraFinChange = (e) => {
    const newHoraFin = e.target.value;
    setHoraFin(newHoraFin);
    if (new Date(horaInicio) >= new Date(newHoraFin)) {
      setError(true);
    } else {
      setError(false);
      setHorario({
        horaInicio,
        horaFin: newHoraFin,
      });
    }
  };

  useEffect(() => {
    const { horaInicio: hora_inicio, horaFin: hora_fin } = horario;

    if (hora_inicio === "08:00" && hora_fin === "12:00") {
      setHoraInicio("08:00");
      setHoraFin("12:00");
    } else {
      setHoraInicio(hora_inicio);
      setHoraFin(hora_fin);
    }
  }, []);

  useEffect(() => {
    const { horaInicio: hora_inicio, horaFin: hora_fin } = horario;
    setHoraInicio(hora_inicio);
    setHoraFin(hora_fin);
    // Manejar cambios en 'horario' aquí
  }, [horario]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Hora de inicio"
          type="time"
          variant="outlined"
          value={horaInicio}
          onChange={handleHoraInicioChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 1800, // 30 minutos
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Hora de fin"
          type="time"
          variant="outlined"
          value={horaFin}
          onChange={handleHoraFinChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 1800, // 30 minutos
          }}
          error={error}
          helperText={
            error ? "La hora de fin debe ser posterior a la hora de inicio" : ""
          }
        />
      </Grid>
    </Grid>
  );
}

export default HorarioComponent;
