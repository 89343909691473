import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const Alert = ({open, setOpen, severity, message}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };
    let title
    switch (severity) {
        case "info":
            title = "Informacion"
            break
        case "warning":
            title = "Advertencia"
            break
        case "error":
            title = "Error"
            break
        case "success":
            title = "Exito"
            break
        default:
            title = "Exito"
            break
    }
    return (
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <MuiAlert elevation = {6} variant = "filled" onClose={handleClose}
                severity={severity}>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

Alert.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    severity: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]).isRequired
}

export default Alert
