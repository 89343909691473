import { React } from 'react'
import PropTypes from 'prop-types'
// No comentar la importación de los estilos para que se muestre el estilo del chat
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput
} from "@chatscope/chat-ui-kit-react"
import { FaDownload } from 'react-icons/fa'

const Chat = ({ messages, placeholder, onSend, onChange, value, ref, onButtonClick }) => {
    // const inputRef = useRef()
    // const onChangeFunction = (file) => {
    //     console.log(file)
    // }
    const onAttachButtonClick = () => {
        let f = document.createElement('input');
        f.style.display = 'none';
        f.type = 'file';
        f.name = 'file';
        f.click();
    }
    return (
        <div style={{ position: "relative", height: "500px" }}>
            <MainContainer>
                <ChatContainer>
                    <MessageList>
                        {messages && messages.map((m, i) =>
                            <Message key={i} model={m} > 
                                {m.payload ?
                                    <Message.CustomContent>
                                        {m.message}&nbsp;&nbsp;
                                        <a href={m.payload}>
                                            <FaDownload color='white' size='2em' />
                                        </a>
                                    </Message.CustomContent>
                                    : null
                                }
                            </Message>
                        )}
                    </MessageList>
                    <MessageInput placeholder={placeholder} onSend={onSend}
                        onChange={onChange} value={value} ref={ref} attachButton={false}
                        onAttachClick={onAttachButtonClick}
                        />
                        
                </ChatContainer>
            </MainContainer>
        </div>
    )
}

Chat.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            message: PropTypes.string.isRequired,
            direction: PropTypes.string.isRequired,
        }),
    ).isRequired,
    placeholder: PropTypes.string,
    onSend: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    ref: PropTypes.func,
}

export default Chat
