import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import useIsLoged from '../utils/useIsLoged'
import { useParams } from 'react-router-dom'
import useSolicitudCotizacionService from '../../services/solicitudCotizacion'

const useEvaluacionEtapa2Page = () => {
    const {evaluarSolicitud, evaluarSolicitudEdit} = useSolicitudInscripcionService()
    const {getSolicitudById} = useSolicitudCotizacionService()

    useIsLoged()
    const history = useHistory()
    const [loading, setLoading] = useState(0)
    const {id} = useParams()
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    const [solicitud, setSolicitud] = useState(null)
    const [comentario, setComentario] = useState('')
    const [estadoSolicitud, setEstadoSolicitud] = useState(5)
    // Estado que almacena un valor booleano y servira como variable de control para
    // el proceso de edicion
    const [modoEdicion, setModoEdicion] = useState(false)

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const onChangeComentario = (event) => {
        setComentario(event.target.value)
    }

    const handleGetSolicitud = useCallback(async () => {
        startLoading()
        const soli = await getSolicitudById(id)
        if (soli.error){
            handleOpenAlert('error', 'Ocurrió un error al obtener los datos de la solicitud')
        }
        else{
            if(soli[0].tipo_solicitud_id === 2){
                setSolicitud(soli[0])
                setEstadoSolicitud(Number(soli[0].estado_solicitud_id))
                setModoEdicion(Number(soli[0].estado_solicitud_id))
            }
        }
        stopLoading()
    }, [getSolicitudById, id])

    const onChangeRadio = (event) => {
        setEstadoSolicitud(Number(event.target.value))
    }

    const handleEvaluarSolicitud = async () => {
        startLoading()
        const evaluacion = {
            estado_solicitud: estadoSolicitud
        }
        // Verifica si se habia hecho una modificacion a la solicitud
        const result = Number(modoEdicion) === 6 ? await evaluarSolicitudEdit(id, evaluacion) :
            await evaluarSolicitud(id, evaluacion)
        if (result.error){
            handleOpenAlert('error', 'Ocurrió un error al evaluar la solcitud')
        }
        else{
            handleOpenAlert('success', 'Se evaluó la solicitud con éxito')
            history.push(`/solicitudes-inscripcion-etapa-2`)
        }
        stopLoading()
    }

    const data = {
        loading,
        estadoSolicitud,
        comentario,
        id,
        alertOpen,
        alertSeverity,
        alertMessage
    }

    const actions = {
        onChangeRadio,
        handleEvaluarSolicitud,
        onChangeComentario,
        setAlertOpen
    }

    //useEffect para cargar datos de las pestanias
    useEffect(() => {
        handleGetSolicitud()
    }, [handleGetSolicitud])


    return {data, actions}
}

export default useEvaluacionEtapa2Page
