import {useState} from 'react'
import { useHistory } from 'react-router-dom'
import useLoginService from '../../services/sesion/useLoginService'
import Session from 'react-session-api'
import { useCookies } from 'react-cookie'
import useUsuarioService from '../../services/administracion/useUsuarioService'

const useLoginPage = () => {
    const { getMe } = useUsuarioService()
    const { login } = useLoginService()

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    //Cookies
    const setCookie = useCookies(['token', 'rol', 'id'])[1]

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const onLogin = async () => {
        setLoading(true)
        const credentials = {
            "email": email,
            "password": password
        }
        const result = await login(credentials)
        if (result.error){
            handleOpenAlert('error', result.error)
            
        }
        else{
            const access_token = result
            // console.log(result)
            const expireIn = access_token.expires_in
            Session.set("token", access_token)
            setCookie('token', access_token, {path: '/',
            maxAge: expireIn})
            const resultMe = await getMe()
            if (resultMe.error){
                handleOpenAlert('error', resultMe.error)
                
            }
            else{
                const {id, role} = resultMe
                // console.log("login id type", typeof(id))
                Session.set("id", id)
                Session.set("rol", role)
                // console.log("Rol", role)
                setCookie('id', id, {path: '/',
                    maxAge: expireIn})
                setCookie('rol', role, {path: '/',
                    maxAge: expireIn})
                //TODO redirigir hacia pantalla de inicio
                // if (role.id === 4) history.push(`/control_dosimetria`)
                // else history.push(`/admin/ministerio`)
                history.push(`/inicio`)
            }
        }
        setLoading(false)
    }

    // const cancel = () => {
    //     setEmail("")
    //     setPassword("")
    // }

    const data = {
        email,
        password,
        alertOpen,
        alertSeverity,
        alertMessage,
        loading
    }

    const actions = {
        setEmail,
        setPassword,
        setAlertOpen,
        onLogin
    }


    return {data, actions}
}

export default useLoginPage