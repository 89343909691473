import { useCallback } from 'react'
import { useCookies } from 'react-cookie'
import Session from 'react-session-api'

//Hook para cerrar la sesion local (la sesion en el servidor aun existe)
const useLogoutLocal = () => {
    const removeCookie = useCookies(['token', 'rol', 'id'])[2]
    const logoutLocal = useCallback(
        () => {
            Session.remove('token')
            Session.remove('rol')
            Session.remove('id')
            removeCookie('token', {path: '/'})
            removeCookie('rol', {path: '/'})
            removeCookie('id', {path: '/'})
        },
        [removeCookie],
    ) 
    return { logoutLocal }
}

export default useLogoutLocal