import React from 'react'
import PropTypes from 'prop-types'
import { Accordion as MaterialAccordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { RiArrowDownSLine } from 'react-icons/ri'
  
const Accordion = ({summary, details, expanded, onChange}) => {
    return (
        <MaterialAccordion expanded = {expanded} onChange = {onChange}>
            <AccordionSummary
                expandIcon={<RiArrowDownSLine/>}
                >
                {summary}
            </AccordionSummary>
            <AccordionDetails>
                {details}
            </AccordionDetails>
        </MaterialAccordion>
    )
}

Accordion.propTypes = {
    summary: PropTypes.node.isRequired,
    details: PropTypes.node.isRequired,
    expanded: PropTypes.bool,
    onChange: PropTypes.func,
}

export default Accordion
