import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    titulo: {
        textAlign: 'center'
    },
    progress: {
        margin: "1em"
    }
})

const Progress = ({open}) => {
    const classes = useStyles()
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            keepMounted
            PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
            }}
            >
            
            <DialogContent>
                <CircularProgress size = "7em" className={classes.progress}/>
            </DialogContent>
        </Dialog>
    )
}

Progress.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default Progress
