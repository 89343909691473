import React from 'react'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import DatePickerRange from '../../components/DatePickerRange/DatePickerRange'
import Pagination from '../../components/Pagination'
import moment from "moment";
import useReportesPage from "../../hooks/asignacionDosimetria/useReportesPage"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import {AiFillFile} from 'react-icons/ai'
import Typography from "@material-ui/core/Typography"
import IconButton from "../../components/IconButton"
import {IoMdCloseCircle} from 'react-icons/io'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AiOutlineClose} from 'react-icons/ai'
import CircularProgress from '@material-ui/core/CircularProgress';
import FileInput from "../../components/FileInput";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
    cell_long: {
        width: 50,
        minWidth: 50
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: "primary",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))


const ReportesPage = ({handleChange}) => {
    const classes = useStyles()
    const {data, actions} = useReportesPage()
    const {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        openProcesarReporteModal,
        reporteProgress,
        objArchivoASC,
        archivos_ASC,
        value: valueDate,
        page,
        numPages,
    } = data
    const {
        setAlertOpen,
        closeModalProcesarReporte,
        subirArchivoReporte,
        handleLimpiarFechas,
        handleSelectDate,
        handleOnChangeArchivoASC,
        deleteASC,
        descargarASC,
        handlePageChange,
    } = actions


    return (
        <Grid container xs={12} md={12} lg={12} justifyContent={"center"} alignItems={"center"}>
            <Progress open={loading}/>
            <Alert open={alertOpen} setOpen={setAlertOpen}
                   severity={alertSeverity}
                   message={alertMessage}/>
            <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                <Progress open={loading}/>
            </Grid>
            <Grid container xs={12} md={12} lg={12} justifyContent={"center"} alignItems={"center"}>
                <Grid xs={8} md={8} lg={8}>
                    <FileInput label="Archivo con reporte modificado"
                               helper="Ingrese el archivo con las lecturas realizadas (IdentificadorXXX.asc)"
                               placeholder="Ingrese el archivo con las lecturas realizadas (IdentificadorXXX.asc)"
                               required={false}
                               onChange={handleOnChangeArchivoASC}
                               files={objArchivoASC}
                    />
                </Grid>
                <Grid xs={4} md={4} lg={4}>
                    <Button
                        variant="contained"
                        text={"Agregar archivo"}
                        func={subirArchivoReporte}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={3}>
                <DatePickerRange onCallback={handleSelectDate} value={valueDate}/>
            </Grid>
            <Grid item xs={1}>
                <Button variant='outlined' text='Limpiar' func={handleLimpiarFechas}/>
            </Grid>
            <div style={{width: '75%'}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                archivos_ASC?.length > 0 ?
                                archivos_ASC && archivos_ASC.map(archivo => {
                                    return (
                                        <TableRow key={archivo.id}>
                                            <TableCell>{archivo.fecha_lectura}.asc</TableCell>
                                            <TableCell>
                                                <Grid container xs={8} md={8} lg={8} justifyContent={"center"}
                                                      alignItems={"center"}>
                                                    <IconButton
                                                        func={() => descargarASC(archivo)}
                                                        icon={<AiFillFile/>}
                                                        tooltip={"Descargar Archivo"}
                                                    />
                                                    <IconButton
                                                        func={() => deleteASC(archivo)}
                                                        icon={<IoMdCloseCircle color='red'/>}
                                                        tooltip={"Eliminar archivo"}
                                                    />
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                    :
                                    <TableRow>
                                        <TableCell>No hay archivos .ASC</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Pagination page={Number(page)}
                        numPages={Number(numPages)}
                        onChangePage={handlePageChange}
            />
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                maxWidth={"sm"}
                open={openProcesarReporteModal}
                onClose={closeModalProcesarReporte}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container xs={12} md={12} lg={12} justifyContent="center"
                          alignItems="center" alignContent="center">
                        <Grid xs={10} md={10} lg={10}>
                            {`Lecturas del ${moment(Date.now()).format("DD MMMM YYYY")}`}
                        </Grid>
                        <Grid xs={2} md={2} lg={2}>
                            <IconButton
                                func={closeModalProcesarReporte}
                                icon={<AiOutlineClose/>}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container item xs={12} md={12} lg={12} justifyContent="center"
                              alignItems="center" alignContent="center">
                            {
                                reporteProgress ?
                                    <Typography component={"div"} variant="h5" paragraph={false}>
                                        Se está generando el reporte, al finalizar se mostrará en la
                                        siguiente pestaña
                                        de reportes
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <CircularProgress size={50}/>
                                        </div>
                                    </Typography>

                                    :
                                    <Typography component={"div"} variant="h5" paragraph={false}>
                                        Reporte generado exitosamente
                                    </Typography>
                            }

                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={
                    {
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                    }
                }>
                    {
                        !reporteProgress &&
                        <div className={classes.wrapper} style={{justifyContent: 'center'}}>
                            <Button func={(event) => {
                                handleChange(event, 1);
                                closeModalProcesarReporte();
                            }} color="primary" text={"REPORTE"}
                                    variant={"contained"}/>
                        </div>
                    }

                </DialogActions>
            </Dialog>
        </Grid>
    )
}


export default ReportesPage