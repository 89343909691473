import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'react-bootstrap-daterangepicker'
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css'
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'moment'
import moment from 'moment'
// Agregando estilos propios
import './DatePickerRange.css'


const DatePickerRange = ({ onCallback, value }) => {
    return (
        <DateRangePicker
            initialSettings={{
                // startDate: moment(),
                // endDate: moment(),
                // startDate: start.toDate(),
                // endDate: end.toDate(),
                ranges: {
                    'Ahora': [moment(), moment()],
                    'Ayer': [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                    ],
                    'Últimos 7 días': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'Últimos 30 días': [
                        moment().subtract(29, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'Este mes': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                    ],
                    'Mes pasado': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                    ],
                },
            }}
            onCallback={onCallback}>
            <input type="text" className="form-control col-4" value={value} onChange={()=>{}} />
            {/* <input type="text" className="form-control col-4" placeholder='Fecha' /> */}
        </DateRangePicker>
    )
}

DatePickerRange.propTypes = {
    // placeholder: PropTypes.string,
    onCallback: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
    // value: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         startDate: PropTypes.string,
    //         endDate: PropTypes.string,
    //     })
    // )
}

export default DatePickerRange
