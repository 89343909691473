import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DataTableButton from '../DataTableButton/DataTableButton'
import Typography from '@material-ui/core/Typography'
import Switch from '../Switch/Switch'

//import useTheme from '@mui/material/styles/useTheme'

const renderActionButtons = (editFnc, deleteFnc, customBtn, key, disableFnc,
    disponible) => {
    if(deleteFnc === undefined && editFnc === undefined && customBtn === undefined 
        && disableFnc === undefined){
        return null
    }
    return (
        <TableCell  align="center">
            {
                editFnc ? 
                <DataTableButton type = "edit" func = {editFnc} tooltip="Editar"
                    idValue={key}/> 
                : 
                null
            }
            {
                deleteFnc ? 
                <DataTableButton type = "delete" func = {deleteFnc} 
                    tooltip="Eliminar"
                    idValue={key}/> 
                : 
                null
            }
            {
                disableFnc ? 
                    <Switch checked={disponible} handleChange={disableFnc} 
                        label={disponible === true ? 'Disponible' : 'Desactivado'}
                        idValue={key.toString()}/>
                :
                null
            }
            {
                customBtn && customBtn.map(
                    (button) => {
                        return <DataTableButton key = {key} type = "edit" 
                            func = {button.func} idValue={key} 
                            customIcon={button.icon} 
                            tooltip={button.tooltip}
                            />
                    })
            }
        </TableCell>
    )
}

const DataTable = ({headers, rows, editFnc, deleteFnc, customBtn, disableFnc}) => {
      const useStyles = makeStyles({
      header: {
            fontWeight: 'bold'
        },
        table: {
            minWidth: `${ editFnc || deleteFnc || customBtn || disableFnc? (18*headers.length)+15 : 18*headers.length}em`,
            wordSpacing: '0'
        },
        pagination: {
            marginTop: '.5em', 
            marginBottom: '.5em'
        }
    })
    const classes = useStyles();
    return (
        <Box component={Paper}>
            <TableContainer style={{ width: '100%' , overflow: 'auto'}}>
                <Table className={classes.table} size = {"small"}>
                    <TableHead>
                        <TableRow style={{ height: '3em'}}>
                            {
                                headers && headers.map(
                                    (header, index) => {
                                        return index === 0 ? 
                                        <TableCell key = {header}>
                                            <Typography className={classes.header}>
                                                {header}
                                            </Typography>
                                        </TableCell>
                                        :
                                        <TableCell key = {header}>
                                            <Typography className={classes.header}>
                                                {header}
                                            </Typography>
                                        </TableCell>
                                    }
                                )
                            }
                            {
                                (editFnc || deleteFnc || customBtn || disableFnc) ? 
                                <TableCell align="center">
                                    <Typography className={classes.header}>
                                        Acciones
                                    </Typography>
                                </TableCell> :
                                null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows && rows.map((row) => (
                        <TableRow style={{ height: '2em'}} key={row.key}>
                            {row.cells && row.cells.map( (cell, index) => {
                                return index === 0 ? 
                                <TableCell key = {`${row.key}-${cell}-${index}`}>
                                    {cell}
                                </TableCell>
                                :
                                <TableCell 
                                    key = {`${row.key}-${cell}-${index}`}>
                                    {cell}
                                </TableCell>
                            })}
                            {
                                renderActionButtons(editFnc, deleteFnc, customBtn, row.key, disableFnc, row?.disponible)
                            }
                        </TableRow>
                    ))}
                    { rows.length === 0 ?
                        <TableRow style={{ height: '2em'}}>
                            <TableCell colSpan = {editFnc || deleteFnc || customBtn || disableFnc ? (headers.length + 1) : headers.length} align="center">
                                No hay datos que mostrar.
                            </TableCell>
                        </TableRow>
                        :
                        null
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

DataTable.propTypes = {
    headers: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            cells: PropTypes.array.isRequired,
            disponible: PropTypes.bool,
        }),
    ).isRequired,
    editFnc: PropTypes.func,
    deleteFnc: PropTypes.func,
    customBtn: PropTypes.arrayOf(
        PropTypes.shape({
            func: PropTypes.func.isRequired,
            icon: PropTypes.func.isRequired,
            tooltip: PropTypes.string,
        }).isRequired,
    ),
    disableFnc: PropTypes.func,
    checked: PropTypes.bool,
}

export default DataTable
