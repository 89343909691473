import React from 'react'
import Alert from '../../components/Alert'
import AppFrame from '../../components/AppFrame'
import Progress from '../../components/Progress/Progress'
import useSolicitudInscripcionPage from '../../hooks/inscripcion/useSolicitudInscripcionPage'
import DatosInstitucionPage from './DatosInstitucionPage'
import DatosResponsablesPage from './DatosResponsablesPage'
import DatosUsuarioPage from './DatosUsuarioPage'

const renderPaso = (data, actions) => {
    const { usuarioData, institucionData, responsableData, paso  } = data
    const { usuarioActions, institucionActions, responsableActions } = actions
    switch(Number(paso)){
        case 1:
            return <DatosUsuarioPage data = { usuarioData } 
                actions = { usuarioActions }/>
        case 2:
            return <DatosInstitucionPage  data = { institucionData } 
                actions = { institucionActions }/>
        case 3:
            return <DatosResponsablesPage data = {responsableData}
                actions = {responsableActions}/>
        default:
            return <DatosUsuarioPage  data = { usuarioData } 
                actions = { usuarioActions }/>
    }
}

const SolicitudInscripcionPage = () => {
    const {data, actions} = useSolicitudInscripcionPage()
    const {alertOpen, alertSeverity, alertMessage, loading} = data
    const { setAlertOpen } = actions

    return (
        <AppFrame>
            <Progress open = {loading>0}/>
            <Alert open = {alertOpen} setOpen={setAlertOpen}
                severity = {alertSeverity} 
                message = {alertMessage}/>
            {renderPaso(data, actions)}
        </AppFrame>
    )
}

export default SolicitudInscripcionPage