import React from 'react'
import FormHeader from './../../components/FormHeader'
import Input from './../../components/Input'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import FormDivider from './../../components/FormDivider'
import { makeStyles } from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import Dialog from '../../components/Dialog'
import FileInput from '../../components/FileInput'
import MaterialDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import PdfViewer from '../../components/PdfViewer'
import Pagination from '../../components/Pagination'
import {MaskField} from "react-mask-field";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    }
}));

function CustomMaskField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="________-_" replacement={{ _: /\d/ }} {...otherProps} />;
}

function CustomMaskNumberField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="____-____" replacement={{ _: /\d/ }} {...otherProps} />;
}

const DatosResponsablesPage = ({data, actions}) => {
    const classes = useStyles()

    const {
        nombresResponsable,
        errorNombresResponsable,
        helperNombresResponsable,
        apellidosResponsable,
        errorApellidosResponsable,
        helperApellidosResponsable,
        duiResponsable,
        errorDuiResponsable,
        helperDuiResponsable,
        objArchivoResponsable,
        telefonoResponsable,
        errorTelefonoResponsable,
        helperTelefonoResponsable,
        celularResponsable,
        errorCelularResponsable,
        helperCelularResponsable,
        cargoResponsable,
        openRepetido, 
        openRepresentanteLegalFileModal, // *** Para ver el archivo PDF al editar ***
        representanteLegalFilePageNumber, representanteLegalFileNumPages, archivoResponsable} = data
    
    const {onChangeNombresResponsable,
        onChangeApellidosResponsable,
        onChangeDuiResponsable,
        onChangeTelefonoResponsable,
        onChangeCelularResponsable,
        setCargoResponsable, onCreateSolicitud, setOpenRepetido,
        handleAcceptDialog, handleOnChangeArchivoResponsable,
        handleCloseRepresentanteLegalFileModal, // *** Para ver el archivo PDF al editar ***
        onRepresentanteLegalDocumentLoadSuccess, handleRepresentanteLegalFilePageChange,
        onUpdateSolicitud} = actions
    
    return (
        <Grid container direction = "row" justifyContent = "center">
            <Dialog open={openRepetido} 
                handleClose = {()=>setOpenRepetido(false)} 
                handleAccept = {handleAcceptDialog} 
                title = {"Representante legal repetido"} 
                content = {
                    "Se ha encontrado un representante legal con el mismo DUI en otra institucion, desea utilizar el mismo representante legal?."
                }/>
            <Grid item xs={12} sm= {5}>
                <Paper className={classes.paper}>                        
                    <Grid container direction="row" justifyContent="center"
                        spacing = {2}>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Datos de responsables">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container item xs = {9}>
                            <Grid container item xs = {12}>
                                <Grid item xs = {12}>
                                    <FormDivider text="Datos del 
                                    representante legal"/>
                                </Grid>
                                <Grid className={classes.input} item
                                    xs = {6}>
                                    <Input type = "text" label="Nombres" 
                                        placeholder="Ingrese los nombres"
                                        value = {nombresResponsable}
                                        onChange={onChangeNombresResponsable}
                                        error = {errorNombresResponsable}
                                        helper = {helperNombresResponsable}
                                        required = {true}
                                        />
                                </Grid>
                                <Grid className={classes.input} item
                                    xs = {6}>
                                    <Input type = "text" label="Apellidos" 
                                        placeholder="Ingrese los apellidos"
                                        value = {apellidosResponsable}
                                        onChange={onChangeApellidosResponsable}
                                        error = {errorApellidosResponsable}
                                        helper = {helperApellidosResponsable}
                                        required = {true}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="DUI" 
                                        placeholder="Ingrese el DUI"
                                        value = {duiResponsable}
                                        onChange={onChangeDuiResponsable}
                                        error = {errorDuiResponsable}
                                        helper = {helperDuiResponsable}
                                        required = {true}
                                        InputProps={{ inputComponent: CustomMaskField }}
                                        />
                                </Grid>
                                {handleOnChangeArchivoResponsable &&
                                    <Grid className={classes.input} item xs={12}>
                                        <FileInput label="Comprobante DUI (ambos lados)"
                                            placeholder="Seleccione el archivo a subir"
                                            onChange={handleOnChangeArchivoResponsable}
                                            required={true}
                                            files={objArchivoResponsable}
                                        />
                                        <MaterialDialog
                                            open={openRepresentanteLegalFileModal}
                                            onClose={handleCloseRepresentanteLegalFileModal}
                                            maxWidth='lg'
                                        >
                                            <DialogContent>
                                                <PdfViewer file={archivoResponsable} onDocumentLoadSuccess={onRepresentanteLegalDocumentLoadSuccess}
                                                    pageNumber={representanteLegalFilePageNumber} numPages={representanteLegalFileNumPages} scale={1} />
                                                <Pagination page={Number(representanteLegalFilePageNumber)}
                                                    numPages={Number(representanteLegalFileNumPages)}
                                                    onChangePage={handleRepresentanteLegalFilePageChange}
                                                />
                                            </DialogContent>
                                        </MaterialDialog>
                                    </Grid>
                                }
                                <Grid  item xs = {12}>
                                    <Input type = "text" label="Telefono" 
                                        placeholder="Ingrese el telefono"
                                        value = {telefonoResponsable}
                                        onChange={
                                            (text)=>{onChangeTelefonoResponsable(text)}
                                        }
                                        error = {errorTelefonoResponsable}
                                        helper = {helperTelefonoResponsable}
                                        required = {true}
                                        InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text"
                                        label="Telefono celular" 
                                        placeholder="Ingrese el telefono 
                                        celular"
                                        value = {celularResponsable}
                                        onChange={
                                            (text)=>{onChangeCelularResponsable(text)}
                                        }
                                        error = {errorCelularResponsable}
                                        helper = {helperCelularResponsable}
                                        required = {true}
                                        InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                                <Grid className={classes.input} item
                                    xs = {12}>
                                    <Input type = "text" label="Cargo" 
                                        placeholder="Ingrese el cargo"
                                        value = {cargoResponsable}
                                        onChange={setCargoResponsable}
                                        required = {true}
                                        />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row"
                                justifyContent="center" spacing = {2}>
                                <Grid className={classes.input} item 
                                    xs={6} sm= {5}>
                                    <Button
                                        component={RouterLink}
                                        to={data.idSolicitud ? '/inscripcion/2/edit/'+ data.idSolicitud : '/inscripcion/2/'}
                                        variant = "outlined"
                                        text="REGRESAR"/>
                                </Grid>
                                <Grid className={classes.input} item
                                    xs={6} sm= {6}>
                                    <Button
                                        variant = "contained"
                                        text={data.idSolicitud ?"EDITAR":"FINALIZAR"}
                                        func = {data.idSolicitud ? () => onUpdateSolicitud(false): () => onCreateSolicitud(false)}
                                        />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default DatosResponsablesPage
