import { useState, useEffect, useCallback } from "react"
import { useHistory } from 'react-router-dom'
import useAreaService from "../../services/area"
import useInstitucionService from "../../services/instituciones"
import useDosimetroService from "../../services/tecnicoPlanificacion"
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"
import useIsLoged from '../utils/useIsLoged'
import moment from "moment";

const useRecibidosPage = () => {
    useIsLoged()
    const history = useHistory()
    const { getAreasByIdInstalacion } = useAreaService()
    const { getInstitucionesByStatus, getInstalacionesByInstitucionId } = useInstitucionService()
    const { getEventosDosimetriaByTipo } = useDosimetroService()
    const { getDosimetrosAsignadosByArea, chequearDosimetro } = useDosimetriaService()

    // Para almacenar el nombre con el id del dato seleccionado en el combobox
    const [selectedInstitucion, setSelectedInstitucion] = useState(null)
    const [selectedInstalacion, setSelectedInstalacion] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([]);

    // Almacena la información de los dosímetros a chequear
    const [dosimetrosChequear, setDosimetrosChequear] = useState([])

    // Para abrir y cerrar el modal para guardar las recepciones de dosimetro
    const [openModalChequear, setOpenModalChequear] = useState(false)

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);

    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    if (!page) {
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    // Funcion que almacena el id de la seleccion de una institicion
    const handleChangeInstitucion = (value) => {
        if (value == null) {
            // setSelectedValueInstitucion(null)
            setSelectedInstitucion(null)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        } else {
            // setSelectedValueInstitucion(value.value)
            setSelectedInstitucion(value)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        }
    }
    // Funcion que almacena el id de la seleccion de una instalacion
    const handleChangeInstalacion = (value) => {
        if (value == null) {
            // setSelectedValueInstalacion(null)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        } else {
            // setSelectedValueInstalacion(value.value)
            setSelectedInstalacion(value)
            setSelectedArea(null)
        }
    }
    // Funcion que almacena el id de la seleccion de un area
    const handleChangeArea = (value) => {
        if (value == null) {
            // setSelectedValueArea(null)
            setSelectedArea(null)
        } else {
            // setSelectedValueArea(value.value)
            setSelectedArea(value)
        }
    }

    // Funcion que almacena actualiza el estado de recibido del dosímetro
    const handleChangeRecibido = (item) => {
        // Creando una copia de los elementos en la fila
        let filas = [...dosimetrosChequear]
        // Buscando el elemento mediante el id del elemento de la fila seleccionada
        let filaArray = filas.filter( (fila) => fila.dosimetro_id === item.dosimetro_id)
        // Obteniendo el objeto del array
        let filaObject = filaArray[0]
        // Cambiando el estado de la variable
        filaObject.recibido = !item.recibido
        // Volviendo a asignar el objeto modificado al arreglo
        filaArray[0] = filaObject
        // En el arreglo de los elementos se vuelve a poner el objeto modificado
        filas.forEach((fila) => {
            if( fila.dosimetro_id === item.dosimetro_id) fila = filaArray[0]
        })
        // Actualizando las filas
        setDosimetrosChequear(filas)
    }

    // Funcion que almacena actualiza el estado de recibirá lectura del dosímetro
    const handleChangeRecibiraLectura = (item) => {
        // Creando una copia de los elementos en la fila
        let filas = [...dosimetrosChequear]
        // Buscando el elemento mediante el id del elemento de la fila seleccionada
        let filaArray = filas.filter( (fila) => fila.dosimetro_id === item.dosimetro_id)
        // Obteniendo el objeto del array
        let filaObject = filaArray[0]
        // Cambiando el estado de la variable
        filaObject.apto_lectura = !item.apto_lectura
        // Volviendo a asignar el objeto modificado al arreglo
        filaArray[0] = filaObject
        // En el arreglo de los elementos se vuelve a poner el objeto modificado
        filas.forEach((fila) => {
            if( fila.dosimetro_id === item.dosimetro_id) fila = filaArray[0]
        })
        // Actualizando las filas
        setDosimetrosChequear(filas)
    }

    // Funcion que almacena actualiza el estado de dañado del dosímetro
    const handleChangeDañado = (item) => {
        // Creando una copia de los elementos en la fila
        let filas = [...dosimetrosChequear]
        // Buscando el elemento mediante el id del elemento de la fila seleccionada
        let filaArray = filas.filter( (fila) => fila.dosimetro_id === item.dosimetro_id)
        // Obteniendo el objeto del array
        let filaObject = filaArray[0]
        // Cambiando el estado de la variable
        filaObject.dañado = !item.dañado
        // Volviendo a asignar el objeto modificado al arreglo
        filaArray[0] = filaObject
        // En el arreglo de los elementos se vuelve a poner el objeto modificado
        filas.forEach((fila) => {
            if( fila.dosimetro_id === item.dosimetro_id) fila = filaArray[0]
        })
        // Actualizando las filas
        setDosimetrosChequear(filas)
    }

    // Funcion que almacena actualiza el estado de extraviado del dosímetro
    const handleChangeExtraviado = (item) => {
        // Creando una copia de los elementos en la fila
        let filas = [...dosimetrosChequear]
        // Buscando el elemento mediante el id del elemento de la fila seleccionada
        let filaArray = filas.filter( (fila) => fila.dosimetro_id === item.dosimetro_id)
        // Obteniendo el objeto del array
        let filaObject = filaArray[0]
        // Cambiando el estado de la variable
        filaObject.extraviado = !item.extraviado
        // Volviendo a asignar el objeto modificado al arreglo
        filaArray[0] = filaObject
        // En el arreglo de los elementos se vuelve a poner el objeto modificado
        filas.forEach((fila) => {
            if( fila.dosimetro_id === item.dosimetro_id) fila = filaArray[0]
        })
        // Actualizando las filas
        setDosimetrosChequear(filas)
    }

    // Funcion que almacena actualiza el estado de recibirá reposición del dosímetro
    const handleChangeRecibiraReposicion = (item) => {
        // Creando una copia de los elementos en la fila
        let filas = [...dosimetrosChequear]
        // Buscando el elemento mediante el id del elemento de la fila seleccionada
        let filaArray = filas.filter( (fila) => fila.dosimetro_id === item.dosimetro_id)
        // Obteniendo el objeto del array
        let filaObject = filaArray[0]
        // Cambiando el estado de la variable
        filaObject.reposicion = !item.reposicion
        // Volviendo a asignar el objeto modificado al arreglo
        filaArray[0] = filaObject
        // En el arreglo de los elementos se vuelve a poner el objeto modificado
        filas.forEach((fila) => {
            if( fila.dosimetro_id === item.dosimetro_id) fila = filaArray[0]
        })
        // Actualizando las filas
        setDosimetrosChequear(filas)
    }

    // Para almacenar el comentario del dosimetro a chequear
    const handleOnChangeComentario = (item, value) => {
        // Creando una copia de los elementos en la fila
        let filas = [...dosimetrosChequear]
        // Buscando el elemento mediante el id del elemento de la fila seleccionada
        let filaArray = filas.filter( (fila) => fila.dosimetro_id === item.dosimetro_id)
        // Obteniendo el objeto del array
        let filaObject = filaArray[0]
        // Cambiando el estado de la variable
        filaObject.observacion = value.target.value
        // Volviendo a asignar el objeto modificado al arreglo
        filaArray[0] = filaObject
        // En el arreglo de los elementos se vuelve a poner el objeto modificado
        filas.forEach((fila) => {
            if( fila.dosimetro_id === item.dosimetro_id) fila = filaArray[0]
        })
        // Actualizando las filas
        setDosimetrosChequear(filas)
    }

    // Verifica si hay campos vacíos
    const checkErrors = () => {
        console.log(dosimetrosChequear)
        // console.log(dosimetrosChequear.some((dosimetro) => (dosimetro.dañado && dosimetro.apto_lectura)))
        if (dosimetrosChequear.some((dosimetro) => (dosimetro.dañado && dosimetro.apto_lectura))){
            // console.log("No puede recibir lectura un dosímetro dañado")
            // setErrorMensaje("No puede recibir lectura un dosímetro dañado")
            handleOpenAlert('error', "No puede recibir lectura un dosímetro dañado")
            return true
        }
        if (dosimetrosChequear.some((dosimetro) => (dosimetro.extraviado && dosimetro.apto_lectura))){
            // console.log("No puede recibir lectura un dosímetro extraviado")
            // setErrorMensaje("No puede recibir lectura un dosímetro extraviado")
            handleOpenAlert('error', "No puede recibir lectura un dosímetro extraviado")
            return true
        }
        if (dosimetrosChequear.some((dosimetro) => (dosimetro.extraviado && dosimetro.recibido))){
            // console.log("No se puede recibir un dosímetro extraviado")
            // setErrorMensaje("No se puede recibir un dosímetro extraviado")
            handleOpenAlert('error', "No se puede recibir un dosímetro extraviado")
            return true
        }
        if (dosimetrosChequear.some((dosimetro) => (dosimetro.recibido && (
            !dosimetro.apto_lectura && !dosimetro.dañado && !dosimetro.reposicion)))){
            handleOpenAlert('error', "Si se recibió un dosímetro debe seleccionar: " +
                "'Recibirá lectura, Dañado o Recibirá reposición'")
            return true
        }

        if (dosimetrosChequear.some((dosimetro) => (dosimetro.apto_lectura && dosimetro.reposicion
            && !dosimetro.recibido))){
            handleOpenAlert('error', "No puede recibir lectura un dosímetro que no ha sido recibido")
            return true
        }

        if (dosimetrosChequear.some((dosimetro) => (dosimetro.apto_lectura && !dosimetro.recibido))){
            handleOpenAlert('error', "No puede recibir lectura un dosímetro que no ha sido recibido")
            return true
        }

        if (dosimetrosChequear.some((dosimetro) => (dosimetro.reposicion && (!dosimetro.recibido &&
            !dosimetro.dañado && !dosimetro.extraviado)))){
            handleOpenAlert('error', "Para recibir reposición debe seleccionar: " +
                "Recibido, Dañado o Extraviado")
            return true
        }

        // Verifica que todos los dosímetros hayan sido seleccionados
        if (dosimetrosChequear.some((dosimetro) => (!dosimetro.recibido && !dosimetro.apto_lectura
            && !dosimetro.dañado && !dosimetro.reposicion && !dosimetro.extraviado))){
            handleOpenAlert('error', "Debe seleccionar todos los dosímetros")
            return true
        }

        return false
    }

    // Funcion que filtra los datos de acuerdo a un valor escrito
    const dataFiltrada = async (data, value) => {
        // Devuelve los datos filtrados por el nombre
        const results = data.filter((resp) =>
            resp.nombre.toLowerCase().includes(value)
        )
        let options = []
        results.forEach(({ id, nombre }) => {
            const value = id
            const label = nombre
            const resultado = {
                label,
                value: value.toString(),
            }
            options = [...options, resultado]
        })
        // console.log("options", options)
        return options
    }
    // Funcion que carga las institucions
    const loadInstituciones = useCallback(async (inputValue) => {
        const response = await getInstitucionesByStatus(1)
        if (inputValue) {
            // Verifica si hay un valor ingresado
            const resultadoFiltrado = dataFiltrada(response, inputValue)
            return resultadoFiltrado
        } else {
            let options = []
            response.forEach(({id, nombre}) => {
                const value = id
                const label = nombre
                const resultado = {
                    label,
                    value: value.toString(),
                }
                options = [...options, resultado]
            })
            // console.log(options)
            return options
        }
    }, [getInstitucionesByStatus])

    // Funcion que carga las instalaciones de acuerdo a la institucion seleccionada
    const loadInstalaciones = useCallback(async (inputValue) => {
        // console.log("Este es un cambio", selectedInstitucion)
        // if (selectedValueInstitucion) {
        if (inputValue) {
            // Verifica si hay un valor ingresado
            const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
            const {instalaciones} = response
            const resultadoFiltrado = dataFiltrada(instalaciones, inputValue)
            return resultadoFiltrado
        } else {
            const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
            const {instalaciones} = response
            let optionsCombo = []
            // console.log("loadInstalaciones", instalaciones)
            instalaciones.forEach(({id, nombre}) => {
                const value = id
                const label = nombre
                const resultadoComboBox = {
                    label,
                    value: value.toString(),
                }
                optionsCombo = [...optionsCombo, resultadoComboBox]
            })
            // console.log(optionsCombo)
            return optionsCombo
        }
        // }
    }, [getInstalacionesByInstitucionId, selectedInstitucion?.value])

    // Funcion que carga las areas de acuerdo a la instalacion seleccionada
    const loadAreas = useCallback(
        async (inputValue) => {
            // Verifica si se ha seleccionado una instalacion
            if (selectedInstalacion) {
                const response = await getAreasByIdInstalacion(selectedInstalacion?.value)
                const {data} = response
                if (inputValue) {
                    // Verifica si hay un valor ingresado
                    return dataFiltrada(data, inputValue)
                } else {
                    let optionsCombo = []
                    data?.forEach(({id, nombre}) => {
                        const value = id
                        const label = nombre
                        const resultadoComboBox = {
                            label,
                            value: value.toString(),
                        }
                        optionsCombo = [...optionsCombo, resultadoComboBox]
                    })
                    return optionsCombo
                }
            }
        }, [selectedInstalacion?.value, getAreasByIdInstalacion])

    const encabezado = ["Institución", "Instalación", "Área", "Status", "Fecha asignada", "Fecha recibido"]
    const encabezadoDosimetrosChequear = ["Código", "Tipo de dosímetro", "Recibido", "Recibirá lectura", "Dañado",
        "Extraviado", "Recibirá reposición", "Observaciones"]
    const columns = [
        {
            field: 'institucion',
            headerName: 'Institucion',
            width: 300,
            resize: true,
            sortable: false,
        },
        {
            field: 'instalacion',
            headerName: 'Instalacion',
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'area',
            headerName: 'Area',
            width: 110,
            editable: false,
            sortable: false,
        },
        {
            field: 'fechaAsignada',
            headerName: 'Fecha Asignada',
            width: 180,
            editable: false,
            sortable: false,
        },
        {
            field: 'fechaRecepcion',
            headerName: 'Fecha Recepcion',
            width: 200,
            editable: false,
            sortable: false,
        },
        // {
        //   field: 'fullName',
        //   headerName: 'Full name',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (params) =>
        //     `${params.getValue(params.id, 'instalacion') || ''} ${
        //       params.getValue(params.id, 'institucion') || ''
        //     }`,
        // },
    ];

    const getDosimetrosByTipo = useCallback(async () => {
        const filtro = {}
        filtro.estado_evento = "RECEPCIONADO"
        filtro.tipo_evento = "RECEPCION DE DOSIMETRO"
        if (selectedArea) filtro.area_id = Number(selectedArea?.value)
        const response = await getEventosDosimetriaByTipo(filtro)
        // console.log(response)
        let data = []
        setRows([])
        const fechaActual = moment(new Date())
        response?.forEach(({id, area, fecha_asignada, fecha, estado_evento}) => {
            const datos = {}
            datos.id = id
            datos.institucion = area.instalacion.institucion.nombre
            datos.instalacion = area.instalacion.nombre
            datos.area = area.nombre
            datos.estado = estado_evento
            datos.fechaAsignada = fecha_asignada
            datos.fechaRecibido = fecha
            datos.sinCuerentena = fechaActual.diff(moment(fecha_asignada), 'hours') > 72
            data.push(datos)
        })
        setRows(data)
    }, [selectedArea, getEventosDosimetriaByTipo])

    const getAllDosimetrosAsignadosByArea = async (item) => {
        startLoading()
        const filtro = {}
        // filtro.area_id = 3
        filtro.area_id = Number(selectedArea?.value)
        const response = await getDosimetrosAsignadosByArea(filtro)
        setDosimetrosChequear([])
        for (let element of response) {
            const data = {
                dosimetro_asignado_id: element.id,
                evento_dosimetria_id: item.id,
                fecha_inicio_control: new Date(),
                recibido: false,
                apto_lectura: false,
                dañado: false,
                extraviado: false,
                reposicion: false,
                observacion: "",
                dosimetro_id: element.dosimetro_id,
                tipo_dosimetro: element.dosimetro.tipo_dosimetro === 2 ? 'Anillo' : 'Cuerpo completo'
            }
            setDosimetrosChequear((dosimetrosChequear) => [...dosimetrosChequear,data ])
        }
        stopLoading()
    }

    // const openModalRecepcionesDosimetro = () => {
    //     if(selectedRows.length > 0) {
    //         setOpenModalRecepciones(true)
    //     } else {
    //         handleOpenAlert('error', `Debe seleccionar uno o más elementos`)
    //         return
    //     }
    // }


    // Metodo que guarda los dosimetros chequeados
    const saveDosimetrosChequeados = async () => {
        if (checkErrors() === false) {
            //console.log("Guardando dosimetros chequeados", dosimetrosChequear)
            const response = await chequearDosimetro(dosimetrosChequear)
            if (response.error) {
                handleOpenAlert("error", response.error)
            } else {
                handleOpenAlert(
                    "success",
                    `Dosímetros chequeados correctamente.`
                )
                await getDosimetrosByTipo()
                // Cerrando modal de chequear dosímetros
                setOpenModalChequear(false)
            }
        }
    }

    const openCloseModalChequear = async (item) => {
        // Cierra el modal si se encuentra abierto
        if (openModalChequear) {
            setOpenModalChequear(false)
        } else {
            // Abre el modal si se encuentra cerrado
            setOpenModalChequear(true)
            await getAllDosimetrosAsignadosByArea(item)
        }
    }

    const clearData = () => {
        setSelectedInstitucion(null)
        setSelectedInstalacion(null)
        setSelectedArea(null)
        setRows([])
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        selectedInstitucion,
        selectedInstalacion,
        selectedArea,
        rows, columns, encabezado,
        dosimetrosChequear,
        openModalChequear,
        encabezadoDosimetrosChequear,
    }

    const actions = {
        handleChangeInstitucion,
        handleChangeInstalacion,
        handleChangeArea,
        loadInstituciones,
        loadInstalaciones,
        loadAreas,
        setAlertOpen,
        handlePageChange,
        clearData,
        openCloseModalChequear,
        handleOnChangeComentario,
        handleChangeRecibido,
        handleChangeRecibiraLectura,
        handleChangeDañado,
        handleChangeExtraviado,
        handleChangeRecibiraReposicion,
        saveDosimetrosChequeados,
    }

    useEffect(async () => {
        if (selectedArea) {
            await getDosimetrosByTipo()
        }
    }, [selectedArea])

    return { data, actions }
}

export default useRecibidosPage
