import React from 'react'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import DatePickerRange from '../../components/DatePickerRange/DatePickerRange'
import Pagination from '../../components/Pagination'
import useReportesPageLeido from "../../hooks/asignacionDosimetria/useReportesPageLeido"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import {AiFillEdit, AiFillFile, AiFillCheckCircle} from 'react-icons/ai'
import IconButton from "../../components/IconButton"
import { IoMdCloseCircle} from 'react-icons/io'
import { FaRegRegistered} from 'react-icons/fa'
import FileInput from "../../components/FileInput";
import Session from 'react-session-api'
import { GrPowerReset} from 'react-icons/gr'
import Dialog from "../../components/Dialog";
import moment from "moment/moment";

const ReportesLeidoPage = () => {
    const rol = Session?.get('rol')
    // const [rol, setRole] = useState(Session?.get('rol'))
    const {data, actions} = useReportesPageLeido()
    const {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        value: valueDate,
        archivos_DOCX,
        page,
        numPages,
        openCloseInputFile,
        objArchivoDOCX,
        openCloseModalReinicioReporte,
    } = data

    const {
        setAlertOpen,
        handleLimpiarFechas,
        handleSelectDate,
        eliminarArchivosDocx,
        descargarArchivosDocx,
        handlePageChange,
        actualizarEstadoReporteDocx,
        openCloseInputToEditWord,
        handleOnChangeArchivoDocx,
        uploadWord,
        openCloseModalResetearReporte,
        reinicioReporte,
    } = actions

    /***
     * @description: Función que retorna los botones del técnico de dosimetría
     * @param archivo
     * @param editar
     * @param eliminar
     * @param revision
     * @param reiniciar
     * @returns {JSX.Element}
     */
    const botonesTecnicoDosimetria = (archivo, editar = true, eliminar = true, revision = true, reiniciar = true) => {
        return(
            <>
                {editar &&
                    <IconButton
                    func={() => openCloseInputToEditWord(archivo)}
                    icon={<AiFillEdit color='green'/>}
                    tooltip={"Editar"}
                    />
                }
                { eliminar &&
                    <IconButton
                        func={() => eliminarArchivosDocx(archivo)}
                        icon={<IoMdCloseCircle color='red'/>}
                        tooltip={"Eliminar"}
                    />
                }
                {revision &&
                    <IconButton
                        func={() => actualizarEstadoReporteDocx(archivo, 4)}
                        icon={<FaRegRegistered/>}
                        tooltip={"Revisión"}
                    />
                }
                {reiniciar &&
                    <IconButton
                        func={() => openCloseModalResetearReporte(archivo)}
                        icon={<GrPowerReset/>}
                        tooltip={"Reiniciar control"}
                    />
                }
            </>
        )
    }


    return (
        // <Grid container direction="row" justifyContent="center">

            <Grid container xs={12} md={12} lg={12} justifyContent={"center"} alignItems={"center"}>
                {/*<p>{JSON.stringify(rol)}</p>*/}
                <Progress open={loading}/>
                <Alert open={alertOpen} setOpen={setAlertOpen}
                       severity={alertSeverity}
                       message={alertMessage}/>
                <Grid container item xs={3}>
                    <DatePickerRange
                        onCallback={handleSelectDate} value={valueDate}/>
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' text='Limpiar' func={handleLimpiarFechas}/>
                </Grid>
                <div style={{width: '100%'}}>
                    <TableContainer >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Estado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (archivos_DOCX && archivos_DOCX?.length > 0) ?
                                        archivos_DOCX.map(archivo => {
                                        return (
                                            <TableRow key={archivo.id}>
                                                <TableCell>reporte_area_{archivo.area_id}_{archivo.fecha_lectura}.docx</TableCell>
                                                <TableCell>
                                                    <Grid container xs={10} md={10} lg={10}  alignItems={"center"}>
                                                        <IconButton
                                                            func={() => descargarArchivosDocx(archivo)}
                                                            icon={<AiFillFile/>}
                                                            tooltip={"Descargar archivo"}
                                                        />
                                                        {/* Opciones para el técnico de dosimetría */}
                                                        {rol?.id === 4 &&
                                                            <>
                                                                {/* Estado: Sin Aprobar */}
                                                                { [1].includes(archivo?.estado_reporte_lectura_id) &&
                                                                    botonesTecnicoDosimetria(archivo, true, true, true, false)
                                                                }

                                                                {/* Estado: Rechazado */}
                                                                { [3].includes(archivo?.estado_reporte_lectura_id) &&
                                                                    botonesTecnicoDosimetria(archivo, false, false, false, true)
                                                                }

                                                                {/* Estado: Observación */}
                                                                { [5].includes(archivo?.estado_reporte_lectura_id) &&
                                                                    botonesTecnicoDosimetria(archivo, true, false, false, false)
                                                                }
                                                            </>
                                                        }
                                                        {/* Opciones para el coordinador de dosimetría */}
                                                        {rol?.id === 2 &&
                                                            <>
                                                                <IconButton
                                                                    func={() => actualizarEstadoReporteDocx(archivo, 2)}
                                                                    icon={
                                                                        <AiFillCheckCircle
                                                                            color={
                                                                                [1, 4].includes(archivo?.estado_reporte_lectura_id) ? "green" :
                                                                                    archivo?.estado_reporte_lectura_id === 2 ? "green" : "gray"
                                                                            }
                                                                        />
                                                                    }
                                                                    tooltip={"Aprobado"}
                                                                />
                                                                <IconButton
                                                                    func={() => actualizarEstadoReporteDocx(archivo, 5)}
                                                                    icon={
                                                                        <FaRegRegistered
                                                                            color={
                                                                                [1, 4].includes(archivo?.estado_reporte_lectura_id) ? "" :
                                                                                    archivo?.estado_reporte_lectura_id === 5 ? "" : "gray"
                                                                            }
                                                                        />
                                                                    }
                                                                    tooltip={"Solicitar corrección"}
                                                                />
                                                                <IconButton
                                                                    func={() => actualizarEstadoReporteDocx(archivo, 3)}
                                                                    icon={
                                                                        <IoMdCloseCircle
                                                                            color={
                                                                                [1, 4].includes(archivo?.estado_reporte_lectura_id) ? "red" :
                                                                                    archivo?.estado_reporte_lectura_id === 3 ? "red" : "gray"
                                                                            }
                                                                        />
                                                                    }
                                                                    tooltip={"Rechazado"}
                                                                />
                                                            </>
                                                        }

                                                    </Grid>
                                                </TableCell>
                                                <TableCell>{archivo?.estado_reporte_lectura?.nombre}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                   :
                                        <TableRow>
                                            <TableCell>No hay archivos .docx</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Pagination page={Number(page)}
                            numPages={Number(numPages)}
                            onChangePage={handlePageChange}
                />
                &nbsp;&nbsp;
                {
                    openCloseInputFile &&
                    <Grid container xs={12} md={12} lg={12} justifyContent={"center"} alignItems={"center"}>
                        <Grid xs={8} md={8} lg={8}>
                            <FileInput label="Archivo con reporte modificado"
                                       helper="Ingrese el archivo con reporte modificado (IdentificadorXXX.docx)"
                                       placeholder="Ingrese el archivo con reporte modificado (IdentificadorXXX.docx)"
                                       required={false}
                                       onChange={handleOnChangeArchivoDocx}
                                       files={objArchivoDOCX}
                            />
                        </Grid>
                        <Grid xs={4} md={4} lg={4}>
                            <Button
                                variant="contained"
                                text={"Agregar archivo"}
                                func={uploadWord}
                            />
                        </Grid>
                    </Grid>
                }
                <Dialog
                    open={openCloseModalReinicioReporte}
                    handleAccept={reinicioReporte}
                    handleClose={openCloseModalResetearReporte}
                    title={`Reiniciar reporte`}
                    maxWidth='lg'
                    content={'El contenido del reporte y los datos asociados serán reiniciados'}
                />
            </Grid>
        // </Grid>
    )
}


export default ReportesLeidoPage