import React from "react"
import PropTypes from "prop-types"
import { Calendar, momentLocalizer } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import moment from "moment"
require('moment/locale/es')
const localizer = momentLocalizer(moment);

const styles = {
  height: 700,
  margin: "50px",
};

const BigCalendar = ({ events, handleSelect }) => {
  return (
    <Calendar
    selectable
    localizer={localizer}
    events={events}
    startAccessor="start"
    endAccessor="end"
    style={styles}
    onSelectSlot={handleSelect}
    messages={{
        next: "Siguiente",
        previous: "Anterior",
        today: "Hoy",
        month: "Mes",
        week: "Semana",
        day: "Día",
    }}
    // views={['month', 'day', 'week', 'agenda']}
    views={['month']}
    // views={{month: true, day: false, week: false}}
    />
  );
};

BigCalendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      start: PropTypes.any.isRequired,
      end: PropTypes.any.isRequired,
      allDay: PropTypes.bool,
    })
  ),
    handleSelect: PropTypes.func.isRequired,
};

export default BigCalendar;
