import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import { getNowInSeconds } from '../../utils/time'
import Session from 'react-session-api'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const loginUrl = `${url}auth`

const useLoginService = () => {
    const { errorHandler } = useErrorHandler()

    const login = useCallback(
        async (credentials) => {
            const requestUrl = `${loginUrl}/login`
            try {
                const response = await axios.post(requestUrl, credentials)
                const { data } = response
                return {...data, expire_date: (getNowInSeconds()+data.expires_in)}
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const logout = useCallback(
        async () => {
            const requestUrl = `${loginUrl}/logout`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const refreshToken = useCallback(
        async () => {
            const requestUrl = `${loginUrl}/refresh`
            try {
                const token = Session.get("token").refresh_token
                const response = await axios.post(requestUrl, {refresh_token: token}, 
                    {headers: headers()})
                const { data } = response
                return {...data, expire_date: (getNowInSeconds()+data.expires_in)}
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getSessionData = useCallback(
        async (token) => {
            const requestUrl = `${url}/users/me`
            try {
                const response = await axios.get(requestUrl)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const solicitarEnlace = useCallback(
        async (usuario) => {
            const requestUrl = `${url}/password/request`
            try {
                const response = await axios.post(requestUrl, usuario)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const cambioContrasenia = useCallback(
        async (nuevaContrasenia) => {
            const requestUrl = `${url}/password/reset`
            try {
                const response = await axios.post(requestUrl, nuevaContrasenia)
                const { data } = response
                return {...data, expire_date: (getNowInSeconds()+data.expires_in)}
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const activarCuenta = useCallback(
        async (dataActivar) => {
            const requestUrl = `${url}email/activate`
            try {
                const response = await axios.post(requestUrl, dataActivar, { headers : {
                    'Authorization' : 'Bearer ' + dataActivar.token
                }})
                const { data } = response
                return {...data, expire_date: (getNowInSeconds()+data.expires_in)}
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { login, logout, refreshToken, getSessionData, solicitarEnlace, 
        cambioContrasenia, activarCuenta }
}

export default useLoginService