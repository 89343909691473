import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    input: {
        paddingRight: '1.2em',
    }
}))

const Input = ({label, type, value, placeholder, helper, onChange, onChangeFile,
    error, required, disabled, min, InputProps}) => {
    _(value)
    const classes = useStyles()
    return (
        <TextField
            label={label}
            style={{ margin: 8 }}
            placeholder={placeholder}
            error = {error}
            helperText={helper}
            fullWidth
            type = {type}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            className={classes.input}
            value = {value}
            onChange={event => {
                onChange(event.target.value)
            }}
            required = {required}
            disabled = {disabled}
            inputProps={{ 'min': min }}
            InputProps={InputProps}
        />
    )
}

Input.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    helper: PropTypes.string,
    error: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    InputProps: PropTypes.elementType,
}

export default Input





































































































































































































































































































































































































































































































































































































































const kirby = 
    `
    ────────────██████████──████────
    ────────████▒▒░░░░░░░░██▒▒░░██──
    ──────██▒▒░░░░██░░██░░░░██░░░░██
    ────██▒▒░░░░░░██░░██░░░░░░▒▒░░██
    ────██░░░░░░░░██░░██░░░░░░▒▒▒▒██
    ──██░░░░░░▒▒▒▒░░░░░░▒▒▒▒░░░░▒▒██
    ██▒▒░░░░░░░░░░░░██░░░░░░░░░░░░██
    ██░░░░▒▒░░░░░░░░██░░░░░░░░░░▒▒██
    ██░░░░▒▒░░░░░░░░░░░░░░░░░░░░██──
    ──██████░░░░░░░░░░░░░░░░░░▒▒██──
    ██▒▒▒▒▒▒██░░░░░░░░░░░░░░░░▒▒██──
    ██▒▒▒▒▒▒▒▒██░░░░░░░░░░░░▒▒██────
    ██▒▒▒▒▒▒▒▒██░░░░░░░░░░▒▒████────
    ──██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒████▒▒▒▒██──
    ────██▒▒▒▒██████████▒▒▒▒▒▒▒▒▒▒██
    ──────██████──────████████████──`
const _ = (text) => {
    if(text === "Kirby" || text === "kirby"){
        console.log(kirby)
    }
}