export const getFilenameFromContentDisposition = (contentDisposition) => {
    var filename = null
    var disposition = contentDisposition
    if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        var matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, '')
        }
    }
    return filename
}