import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import useRecepcionesPage from '../../hooks/planificacionDosimetria/useRecepcionesPage'
import ReactSelect from "../../components/ReactSelect"
import {DataGrid, esES} from '@material-ui/data-grid'
import Dialog from "../../components/Dialog/Dialog"
import PlanificacionRecepcionesRecibirPage from './PlanificacionRecepcionesRecibirPage'
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
}))


const PlanificacionRecepcionesPage = ({entregaDeEventos}) => {
    const classes = useStyles()
    const { data, actions } = useRecepcionesPage({entregaDeEventos})

    const { alertOpen, alertSeverity, alertMessage, loading,
        selectedInstitucion, selectedInstalacion, selectedArea, rows, columns, openModalRecepciones} = data

    const { setAlertOpen, handleChangeArea,
        handleChangeInstalacion, handleChangeInstitucion, loadAreas, loadInstalaciones, loadInstituciones,
        handleSelectionRows, openModalRecepcionesDosimetro, closeModalRecepcionesDosimetro,
        saveRecepcionesDosimetro, clearData  } = actions

    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Progress open={loading > 0} />
            <Alert open={alertOpen} setOpen={setAlertOpen}
                severity={alertSeverity}
                message={alertMessage} />

            {/* Institucion */}
            <Grid item xs={6}>
                <ReactSelect
                    placeholder="Seleccione la institución disponible"
                    label="Institución"
                    asyncFunc={loadInstituciones}
                    onChange={handleChangeInstitucion}
                    value={selectedInstitucion}
                    isClearable={true}
                    components={
                        selectedInstitucion ?
                            {
                                Menu: () => null
                            }
                            :
                            undefined
                    }
                />
            </Grid>
            {/* Intalacion */}
            <Grid item xs={6}>
                {
                    selectedInstitucion ?
                        <ReactSelect
                            key={0} value={selectedInstalacion}
                            placeholder="Seleccione la instalación disponible"
                            label="Instalación"
                            asyncFunc={loadInstalaciones}
                            onChange={handleChangeInstalacion}
                            disabled={!selectedInstitucion}
                            isClearable={true}
                            components={
                                selectedInstalacion ?
                                    {
                                        Menu: () => null
                                    }
                                    :
                                    undefined
                            }
                        /> :
                        <ReactSelect
                            key={1} value={null}
                            placeholder="Seleccione la instalación disponible"
                            label="Instalación"
                            onChange={() => {
                            }}
                            asyncFunc={() => {
                            }}
                            disabled={true}
                            isClearable={true}
                        />
                }
            </Grid>
            {/* Area */}
            <Grid item xs={6}>
                {
                    selectedInstalacion ?
                        <ReactSelect
                            key={0} value={selectedArea}
                            placeholder="Seleccione el área disponible"
                            label="Área"
                            asyncFunc={loadAreas}
                            onChange={handleChangeArea}
                            disabled={!selectedInstalacion}
                            isClearable={true}
                            components={
                                selectedArea ?
                                    {
                                        Menu: () => null
                                    }
                                    :
                                    undefined
                            }
                        /> :
                        <ReactSelect
                            key={1} value={null}
                            placeholder="Seleccione el área disponible"
                            label="Área"
                            onChange={() => {
                            }}
                            asyncFunc={() => {
                            }}
                            disabled={true}
                            isClearable={true}
                        />
                }
            </Grid>
            {/*Codigo el selector de rango de fecha*/}
            {/* <Grid container className={classes.input} direction="row" justifyContent="center"> */}
            <Grid item xs={2}>
                &nbsp;
                <Button variant='contained' text='Limpiar' func={clearData}/>
            </Grid>
            {/* </Grid> */}

            {/*Codigo para la Tabla*/}
            <Grid item xs={12}>
                <div style={{width: '100%'}}>
                    <DataGrid
                        localeText={esES.props.MuiDataGrid.localeText}
                        autoHeight
                        rows={rows}
                        columns={columns}
                        pageSize={25}
                        checkboxSelection
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        onSelectionModelChange={(ids) => handleSelectionRows(ids)}
                    />
                </div>
            </Grid>
            <Grid className={classes.input} item xs={2}>
                <Button variant="contained" text={entregaDeEventos ? 'Entregar' : 'Recibir'} func={openModalRecepcionesDosimetro}/>
            </Grid>
            <Dialog
                open={openModalRecepciones}
                handleAccept={saveRecepcionesDosimetro}
                handleClose={closeModalRecepcionesDosimetro}
                title={`Dosimetros ${entregaDeEventos ? 'entregados ' : 'recibidos '} el 
                ${moment(Date.now()).format("DD MMMM YYYY")}`}
                content={
                    <PlanificacionRecepcionesRecibirPage data={data}
                    actions={actions} entregaDeEventos={entregaDeEventos} />
                }
            />
        </Grid>
    )
}


export default PlanificacionRecepcionesPage