import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useResponsabilidadService from '../../services/administracion/useResponsabilidadService'
import { mensajeCamposNoValidos } from '../../utils/messages'
import { addRow, findRow, updateRow } from '../../utils/rows'
import { allLetterAndSpace } from '../../utils/validations'
import useIsLoged from '../utils/useIsLoged'

const useResponsabilidadPage = () => {
    const { createResponsabilidad, getResponsabilidadById, getResponsabilidadesByStatus, 
        updateResponsabilidad, deleteResponsabilidad } = useResponsabilidadService()

    const {id} = useParams()
    useIsLoged()
    const history = useHistory()
    const [error, setError] = useState(null)
    const [rows, setRows] = useState([])
    const [nombre, setNombre] = useState('')
    const [errorNombre, setErrorNombre] = useState(false)
    const [helperNombre, setHelperNombre] = useState('')
    
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [responsabilidadToDelete, setResponsabilidadToDelete] = useState(null)

    //Estado para ver registros con estado = false (desactivados)
    const [verDesactivados, setVerDesactivados] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    const [loading, setLoading] = useState(0)
    
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    const handleDesactivadosChange = () => {
        setVerDesactivados(
            (verDesactivados) => !verDesactivados
        )
    }

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setResponsabilidadToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const onChangeNombre = (text)=>
    {
        setNombre(text)
        if(!allLetterAndSpace(text) && text !== ""){
            setErrorNombre(true)
            setHelperNombre("El nombre debe consistir solo de letras.")
        }else{
            setErrorNombre(false)
            setHelperNombre("")
        }
    }

    const checkForErrors = () => {
        if (errorNombre || nombre === "")
        {
            return true
        }
        return false
    }
    
    const onCreateResponsabilidad = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const newResponsabilidad = {
            "nombre": nombre
        }
        startLoading()
        const result = await createResponsabilidad(newResponsabilidad)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            getResponsabilidades()
            handleOpenAlert('success', `La responsabilidad "${nombre}" se creo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onUpdateResponsabilidad = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const responsabilidadActualizado = {
            "nombre": nombre
        }
        startLoading()
        const result = await updateResponsabilidad(id, responsabilidadActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre} = result
            const newCells = [nombre]
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `La responsabilidad "${nombre}" se actualizo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onDeleteResponsabilidad = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteResponsabilidad(responsabilidadToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const { nombre} = result
            getResponsabilidades()
            editCancel()
            handleOpenAlert('success', `La responsabilidad "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }


    const getResponsabilidad = useCallback(async (id) => {
        startLoading()
        const responsabilidad = await getResponsabilidadById(id)
        if (responsabilidad.error){
            handleOpenAlert('error', responsabilidad.error)
        }
        else{
            setNombre(responsabilidad.nombre)
        }
        stopLoading()
    }, [getResponsabilidadById])

    const getResponsabilidades = useCallback(async () => {
        const estado = verDesactivados ? 0 : 1
        startLoading()
        const responsabilidades = await getResponsabilidadesByStatus(estado, page)
        if (responsabilidades.error){
            setError(responsabilidades.error)
        }
        else{
            setRows([])
            setNumPages(responsabilidades.last_page)
            responsabilidades.data.forEach(({id, nombre}) => {
                const key = id
                const cell = [nombre]
                addRow(key, cell, setRows)
            })
            if(page > responsabilidades.last_page){
                setPage(responsabilidades.last_page)
                history.push(`${window.location.pathname}?page=${responsabilidades.last_page}`)
            }
        }
        stopLoading()
    }, [getResponsabilidadesByStatus, history, page, verDesactivados])

    const editClick = (id) => {
        history.push(`/admin/responsabilidad/${id}?page=${page}`)
    }

    const editCancel = () => {
        cancel()
        history.push(`/admin/responsabilidad?page=${page}`)
    }

    const cancel = () => {
        setNombre("")
        setErrorNombre(false)
    }

    const data = {
        rows,
        nombre,
        errorNombre,
        helperNombre,
        id,
        openEdit,
        openDelete,
        responsabilidadToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        verDesactivados,
        loading
    }

    const actions = {
        onDeleteResponsabilidad,
        onCreateResponsabilidad,
        onUpdateResponsabilidad,
        editClick,
        onChangeNombre,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setResponsabilidadToDelete,
        setAlertOpen,
        handlePageChange,
        handleDesactivadosChange
    }

    //useEffect para cargar datos en la tabla
    useEffect(() => {
        getResponsabilidades()
    }, [getResponsabilidades])
    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getResponsabilidad(id)
        }
    }, [id, getResponsabilidad])
    return {error, data, actions}
}

export default useResponsabilidadPage