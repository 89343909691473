import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";
import { addRow, findRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import { BsFillChatDotsFill, BsFillChatFill } from "react-icons/bs";
import { MdSearch } from "react-icons/md";
import useUsuarioService from "../../services/administracion/useUsuarioService";
import useSolicitudesRecibidasCCService from "../../services/solicitudes_recibidas/solicitudesRecibidasService";
import useControlAvanceCCService from "../../services/solicitudes_recibidas/controlAvanceService";
import useCCPlanificacionService from "../../services/controlDeCalidad/useActividadesServiceCC";
import usePasoSolicitudesRecibidasService from "../../services/solicitudes_recibidas/pasoService";
import { mensajeCamposNoValidos } from "../../utils/messages";
import useEstadoSolicitudesRecibidasService from "../../services/solicitudes_recibidas/estadoService";
import useClienteServiceCC from "../../services/controlDeCalidad/useClienteServiceCC";
import useReporteEficienciaGlobalService from "../../services/controlDeCalidad/useReporteEficienciaGlobalService";
import ControlAvanceTable from "../../pages/solicitudes_recibidas/tablaControlAvanceCC";

const useCantidadTiposPage = () => {
  const { getReporteCantidadEquipos, descargarReporteCantidadEquipos } =
    useReporteEficienciaGlobalService();

  const { getControlAvancePorIdCC, createControlAvance } =
    useControlAvanceCCService();

  const history = useHistory();

  useIsLoged();
  const { getMe } = useUsuarioService();
  const [error, setError] = useState(null);
  const [fecha, setFecha] = useState("");
  const [estado, setEstado] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [value, setValue] = useState("Seleccione un rango de fecha");
  const [rows, setRows] = useState([]);

  // Para el rol del usuario
  const [idRol, setIdRol] = useState("");
  //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  //Estado que indica la pagina actua
  const [page, setPage] = useState(queryParams.get("page"));
  //Estado que indica el numero de paginas en total
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(false);

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  //Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [tipos_control_avance, setTipos_control_avance] = useState([]);
  const [fecha_solicitud, setFecha_solicitud] = useState("");
  const [fecha_visita_tecnica, setFecha_visita_tecnica] = useState("");
  const [
    fecha_entrega_de_informe_tecnico,
    setFecha_entrega_de_informe_tecnico,
  ] = useState("");
  const [fecha_entrega_certificado, setFecha_entrega_certificado] =
    useState("");
  const [fecha_notificacion, setFecha_notificacion] = useState("");
  const [fecha_entrega, setfecha_entrega] = useState("");
  const [selectedPaso, setSelectedPasos] = useState(null);

  const [firstHeaders, setFirstHeaders] = useState([""]);
  const [secondRowHeaders, setSecondRowHeaders] = useState([""]);
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [nombre_institucion, setNombreInstitucion] = useState("");
  const [catchErrorOExitoDescargaExcel, setCatchErrorOExitoDescargaExcel] =
    useState("");
  const [
    idInstitucionSeleccionadaEnTabla,
    setIdInstitucionSeleccionadaEnTabla,
  ] = useState(null);

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  const checkForErrors = () => {};

  const cancel = () => {
    setFechaInicio("");
    setFechaFin("");
    setNombreInstitucion("");
    setIdInstitucionSeleccionadaEnTabla(null);
    const load = async () => {
      startLoading();
      await getReporteEficienciaGlobalcc();
      stopLoading();
    };

    load();
  };

  // Para indicar cuando se está consumiendo la API
  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const onChangeInstitucion = (text) => {
    setNombreInstitucion(text);
    // if (!letterNumbersSpaceForAddress(text)) {
    //   setErrorComentario(true);
    //   setHelperComentario(
    //     "La dirección solo debe contener numeros,letras y los caracteres: # - / ,  . ª ° "
    //   );
    // } else {
    //   setErrorComentario(false);
    //   setHelperComentario("");
    // }
  };

  //Controlador cambio de pagina
  const handlePageChange = (event, value) => {
    setPage(value);
    //getSolicitudesRecibidasCC();
    history.push(`${window.location.pathname}?page=${value}`);
  };
  const handleClickOpenDialog = (idControlAvance) => {
    //setActividad_id(id);
    getControlAvanceByIdCC(idControlAvance);
    setOpenDialog(true);
  };
  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    setIdInstitucionSeleccionadaEnTabla(null);
    //handleChangePaso(null);
    //cancel();
  };
  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const descargarReporteEficienciaGlobalcc = useCallback(
    async (
      fecha_inicio = false,
      fecha_fin = false,
      nombre_institucion = false
    ) => {
      setLoading(true);

      await descargarReporteCantidadEquipos(
        fecha_inicio,
        fecha_fin,
        nombre_institucion,
        setCatchErrorOExitoDescargaExcel
      );

      setLoading(false);
    },
    [getReporteCantidadEquipos]
  );
  useEffect(() => {
    if (catchErrorOExitoDescargaExcel === "error") {
      handleOpenAlert("error", "Error al intentar descargar el archivo");
      setError("Error al intentar descargar el archivo");

      setCatchErrorOExitoDescargaExcel("");
    }
    if (catchErrorOExitoDescargaExcel === "exito") {
      handleOpenAlert(
        "success",
        `El archivo se ha descargado satisfactoriamente`
      );
      setCatchErrorOExitoDescargaExcel("");
    }
  }, [catchErrorOExitoDescargaExcel]);

  const getReporteEficienciaGlobalcc = useCallback(
    async (
      pagination,
      fecha_inicio = false,
      fecha_fin = false,
      nombre_institucion = false
    ) => {
      setLoading(true);

      const { pagination: { last_page }, data: response } = await getReporteCantidadEquipos(
        fecha_inicio,
        fecha_fin,
        nombre_institucion,
        pagination
      );
      if (response.error) {
        handleOpenAlert("error", response.error);
        setError(response.error);
        setRows([]);
        setNumPages(1);
        setLoading(false);
        setFirstHeaders([]);
        setSecondRowHeaders([]);
        return;
      } else {
        setRows([]);
        setNumPages(last_page);

        const { controlesAvance } = response;

        const headers = ["", "", "", "Tipo", "de", "Equipos", "", "", "", ""];
        const secondRowHeaders = [
          "Cliente",
          "Convencional Movil",
          "Convencional Fijo",
          "Convencional Cflouro",
          "Brazo C",
          "Mamografia",
          "Dental Periapical",
          "Dental Panoramico",
          "Tomografia Computarizada",
          "Cantidad de equipos",
        ];

        setFirstHeaders([]);
        setSecondRowHeaders([]);

        setFirstHeaders(headers);
        setSecondRowHeaders(secondRowHeaders);

        controlesAvance.forEach(
          ({ tipoControlAvance, cantidad_equipos, nombre_institucion }) => {
            const key = cantidad_equipos;
            const tiposCOntrolAvances = {};
            //const cell = [nombre_institucion];

            tipoControlAvance.forEach(
              ({ nombre_tipo_equipo, cantidad_equipo }) => {
                //cell.push(cantidad_equipo);
                tiposCOntrolAvances[nombre_tipo_equipo] = cantidad_equipo;
              }
            );
            const {
              brazoC,
              convencionalMovil,
              convencionalFijo,
              convencionalCflouro,
              mamografico,
              dentalPeriapical,
              dentalPanoramico,
              tomografiaComputarizada,
            } = tiposCOntrolAvances;

            const cell = [
              nombre_institucion,
              convencionalMovil,
              convencionalFijo,
              convencionalCflouro,
              brazoC,
              mamografico,
              dentalPeriapical,
              dentalPanoramico,
              tomografiaComputarizada,
            ];
            cell.push(cantidad_equipos);

            addRow(key, cell, setRows);
          }
        );
      }
      setLoading(false);
    },
    [getReporteCantidadEquipos]
  );

  const getControlAvanceByIdCC = useCallback(async (idControlAvance) => {
    const response = await getControlAvancePorIdCC(idControlAvance);
    if (response.error) {
      handleOpenAlert("error", response.error);
      setError(response.error);
      setLoading(false);
      return;
    } else {
      setTipos_control_avance(response.tipos_control_avance);

      //setSelectedPaso({label:response.paso_control_calidad.nombre,value:response.paso_control_calidad.id})
      setFecha_solicitud(response.fecha_solicitud);
      setFecha_visita_tecnica(response.fecha_visita_tecnica);
      setFecha_entrega_de_informe_tecnico(
        response.fecha_entrega_de_informe_tecnico
      );
      setFecha_entrega_certificado(response.fecha_entrega_certificado);
      setFecha_notificacion(response.fecha_notificacion);
      setfecha_entrega(response.fecha_entrega);
      //setSelectedPaso({label:response.paso_control_calidad.nombre,value:response.paso_control_calidad.id})
      setSelectedPasos({
        label: response.paso_control_calidad.nombre,
        value: response.paso_control_calidad.id,
      });
    }
  });

  const onUpdateControlAvanceCC = useCallback(async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const { actividad_id } = await getControlAvancePorIdCC(
      idInstitucionSeleccionadaEnTabla
    );
    startLoading();

    //createControlAvance
    const controlAvance = {
      actividad_id,
      paso_id: selectedPaso.value,
      fecha_visita_tecnica: fecha_visita_tecnica || null,
      fecha_entrega_de_informe_tecnico:
        fecha_entrega_de_informe_tecnico || null,
      fecha_entrega_certificado: fecha_entrega_certificado || null,
      fecha_notificacion: fecha_notificacion || null,
      fecha_entrega: fecha_entrega || null,
      tipos_control_avance: tipos_control_avance.map((tipo) => ({
        tipo_equipo_id: tipo.tipo_equipo_id,
        cantidad_reporte: tipo.cantidad_reporte || 0,
        comentario: tipo.comentario || "",
      })),
    };
    const result = await createControlAvance(controlAvance);
    if (result.error) {
      setErrorMessage(result.error);
      // setErrorMessage(result.error.data.message);
      handleOpenAlert("error", result.error);
    } else {
      const { control_avance } = result;
      handleOpenAlert(
        "success",
        `El control de avance se actualizó correctamente.`
      );
      handleClickCloseDialog();
    }
    stopLoading();
  });

  const handleFiltro = () => {
    const load = async () =>
      await getReporteEficienciaGlobalcc(
        page,
        fecha_inicio || false,
        fecha_fin || false,
        nombre_institucion || false
      );
    load();
  };

  const descargarArchivo = () => {
    const load = async () => {
      await descargarReporteEficienciaGlobalcc(
        fecha_inicio || false,
        fecha_fin || false,
        nombre_institucion || false
      );
    };
    load();
  };

  // Funcion que filtra los datos de acuerdo a un valor escrito
  const dataFiltrada = useCallback(async (data, value) => {
    // Devuelve los datos filtrados por el nombre
    const results = data.filter((resp) =>
      resp.nombre.toLowerCase().includes(value)
    );
    let options = [];
    results.forEach(({ id, nombre }) => {
      const value = id;
      const label = nombre;
      const resultado = {
        label,
        value: value.toString(),
      };
      options = [...options, resultado];
    });
    return options;
  }, []);

  //Carga inical al cargar el componente
  useEffect(() => {
    const load = async () => {
      startLoading();
      await getReporteEficienciaGlobalcc();
      stopLoading();
    };

    load();
  }, []);

  useEffect(() => {
    const load = async () =>
      await getReporteEficienciaGlobalcc(
        page,
        fecha_inicio || false,
        fecha_fin || false,
        nombre_institucion || false
      );
    //if (!anio || anio.length > 3) {
    load();
    //}
  }, [page]);

  useEffect(() => {
    if (idInstitucionSeleccionadaEnTabla !== null) {
      handleClickOpenDialog(idInstitucionSeleccionadaEnTabla);
    }
  }, [idInstitucionSeleccionadaEnTabla]);

  const data = {
    rows,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    numPages,
    page,
    value,
    idRol,
    MdSearch,
    openDialog,
    secondRowHeaders,
    firstHeaders,
    setFechaFin,
    setFechaInicio,
    fecha_inicio,
    fecha_fin,
    nombre_institucion,
    idInstitucionSeleccionadaEnTabla,
    setIdInstitucionSeleccionadaEnTabla,
    tipos_control_avance,
    fecha_solicitud,
    fecha_visita_tecnica,
    fecha_entrega_de_informe_tecnico,
    fecha_entrega_certificado,
    fecha_notificacion,
    fecha_entrega,
    selectedPaso,
    setTipos_control_avance,
  };
  const actions = {
    handlePageChange,
    handleOpenAlert,
    onChangeInstitucion,
    handleFiltro,
    cancel,
    setAlertOpen,
    handleClickCloseDialog,
    onUpdateControlAvanceCC,
    descargarArchivo,
  };

  return { data, actions };
};

export default useCantidadTiposPage;
