import {useCallback, useEffect, useState} from "react"
import {useHistory} from 'react-router-dom'
import useIsLoged from '../utils/useIsLoged'
import {addRow} from '../../utils/rows'
import {BsPlusCircleFill} from 'react-icons/bs'
// Services
import useAreaService from "../../services/area"
import useInstitucionService from "../../services/instituciones"
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"

const useAsignacionDosimetriaPage = () => {
    useIsLoged()
    const history = useHistory()

    //Obteniendo funciones de los services
    const {getAreasByIdInstalacion} = useAreaService()
    const {getInstitucionesByStatus, getInstalacionesByInstitucionId} = useInstitucionService()
    const {getAllAsignacionesByAreas} = useDosimetriaService()

    // Para almacenar el nombre con el id del dato seleccionado en el combobox
    const [selectedInstitucion, setSelectedInstitucion] = useState(null)
    const [selectedInstalacion, setSelectedInstalacion] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([]);

    // Para capturar las filas seleccionadas en la tabla mediante el checkbox
    const [selectedRows, setSelectedRows] = useState([]);

    // Para abrir y cerrar el modal para guardar las recepciones de dosimetro
    const [openModalRecepciones, setOpenModalRecepciones] = useState(false)

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);

    //Estado que indica la página actual
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el número de páginas en total
    const [numPages, setNumPages] = useState(0)

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    if (!page) {
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de página
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    // Funcion que almacena el id de la seleccion de una institicion
    const handleChangeInstitucion = (value) => {
        if (value == null) {
            // setSelectedValueInstitucion(null)
            setSelectedInstitucion(null)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        } else {
            // setSelectedValueInstitucion(value.value)
            setSelectedInstitucion(value)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        }
    }
    // Funcion que almacena el id de la seleccion de una instalacion
    const handleChangeInstalacion = (value) => {
        if (value == null) {
            // setSelectedValueInstalacion(null)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        } else {
            // setSelectedValueInstalacion(value.value)
            setSelectedInstalacion(value)
            setSelectedArea(null)
        }
    }
    // Funcion que almacena el id de la seleccion de un area
    const handleChangeArea = (value) => {
        if (value == null) {
            // setSelectedValueArea(null)
            setSelectedArea(null)
        } else {
            // setSelectedValueArea(value.value)
            setSelectedArea(value)
        }
    }

    // Funcion que filtra los datos de acuerdo a un valor escrito
    const dataFiltrada = async (data, value) => {
        // Devuelve los datos filtrados por el nombre
        const results = data.filter((resp) =>
            resp.nombre.toLowerCase().includes(value)
        )
        let options = []
        results.forEach(({id, nombre}) => {
            const value = id
            const label = nombre
            const resultado = {
                label,
                value: value.toString(),
            }
            options = [...options, resultado]
        })
        // console.log(options)
        return options
    }

    // Funcion que carga las institucions
    const loadInstituciones = useCallback(async (inputValue) => {
        const response = await getInstitucionesByStatus(1)
        if (inputValue) {
            // Verifica si hay un valor ingresado
            const resultadoFiltrado = dataFiltrada(response, inputValue)
            return resultadoFiltrado
        } else {
            let options = []
            response.forEach(({id, nombre}) => {
                const value = id
                const label = nombre
                const resultado = {
                    label,
                    value: value.toString(),
                }
                options = [...options, resultado]
            })
            // console.log(options)
            return options
        }
    }, [getInstitucionesByStatus])

    // Funcion que carga las instalaciones de acuerdo a la institucion seleccionada
    const loadInstalaciones = useCallback(async (inputValue) => {
        // console.log("Este es un cambio", selectedInstitucion)
        // if (selectedValueInstitucion) {
        if (inputValue) {
            // Verifica si hay un valor ingresado
            const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
            const {instalaciones} = response
            const resultadoFiltrado = dataFiltrada(instalaciones, inputValue)
            return resultadoFiltrado
        } else {
            const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
            const {instalaciones} = response
            let optionsCombo = []
            // console.log("loadInstalaciones", instalaciones)
            instalaciones.forEach(({id, nombre}) => {
                const value = id
                const label = nombre
                const resultadoComboBox = {
                    label,
                    value: value.toString(),
                }
                optionsCombo = [...optionsCombo, resultadoComboBox]
            })
            // console.log(optionsCombo)
            return optionsCombo
        }
        // }
    }, [getInstalacionesByInstitucionId, selectedInstitucion?.value])

    // Funcion que carga las areas de acuerdo a la instalacion seleccionada
    const loadAreas = useCallback(
        async (inputValue) => {
            // Verifica si se ha seleccionado una instalacion
            if (selectedInstalacion) {
                const response = await getAreasByIdInstalacion(selectedInstalacion?.value)
                const {data} = response
                if (inputValue) {
                    // Verifica si hay un valor ingresado
                    return dataFiltrada(data, inputValue)
                } else {
                    let optionsCombo = []
                    data?.forEach(({id, nombre}) => {
                        const value = id
                        const label = nombre
                        const resultadoComboBox = {
                            label,
                            value: value.toString(),
                        }
                        optionsCombo = [...optionsCombo, resultadoComboBox]
                    })
                    return optionsCombo
                }
            }
        }, [selectedInstalacion?.value, getAreasByIdInstalacion])

    const limpiarFiltros = () => {
        setSelectedInstitucion(null)
        setSelectedInstalacion(null)
        setSelectedArea(null)
    }

    const handleLimpiar = async () => {
        limpiarFiltros()
        // setRows([])
        await listAllAsignacionesByAreas()
    }

    const encabezado = ["Institucion", "Instalación", "Área"]

    const listAllAsignacionesByAreas = useCallback(async () => {
        startLoading()
        const params = {area_id: selectedArea?.value}
        params.page = page
        const response = await getAllAsignacionesByAreas(params)
        // console.log(response)
        setRows([])
        setNumPages(response.last_page)
        response?.data?.forEach(({id, nombre: area, instalacion}) => {
            // Obteniendo datos de la instalacion
            const {nombre: nombreInstalacion, institucion} = instalacion

            // Agregando los datos a la fila para ser mostrados en la databale
            const key = id
            const cell = [institucion.nombre, nombreInstalacion, area]
            addRow(key, cell, setRows)
        })
        // Para la paginación en la URL
        if (page > response.last_page) {
            setPage(response.last_page)
            history.push(`${window.location.pathname}?page=${response.last_page}`)
        }
        stopLoading()
    }, [history, page, selectedArea, getAllAsignacionesByAreas])

    const handleSelectionRows = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedData = rows.filter((row) =>
            selectedIDs.has(row.id),
        )
        setSelectedRows([])
        // let a = []
        selectedData.forEach(({id, institucion, instalacion, area, fechaAsignada}) => {
            const key = id
            const cell = [institucion, instalacion, area, fechaAsignada]
            // const datos_seleccionados = {
            //     'key': key,
            //     'cell': cell
            // }
            // a.push(datos_seleccionados)
            addRow(key, cell, setSelectedRows)
            // setSelectedRows((selectedRows)=>[...selectedRows, datos_seleccionados])
        })
        // setSelectedRows(a)
        //setSelectedRows(selectedRows);
        // console.log(selectedRows)
    }

    const openModalRecepcionesDosimetro = () => {
        setOpenModalRecepciones(true)
    }

    const closeModalRecepcionesDosimetro = () => {
        setOpenModalRecepciones(false)
    }

    // Metodo que guarda las recepciones de los dosimetros seleccionados
    const saveRecepcionesDosimetro = () => {
    }

    // Funcion que muestra una página para asignar dosímetros
    const asignarDosimetro = (key) => {
        history.push(`/control_dosimetria/toe/area/${key}`)
    }

    // Función que muestra el botón de asignar con su tooltip en la datatable
    const botonesDataTable = () => {
        return [
            {
                func: (key) =>
                    asignarDosimetro(key),
                icon: BsPlusCircleFill,
                tooltip: "Asignar dosímetro"
            }
        ]
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        selectedInstitucion,
        selectedInstalacion,
        selectedArea,
        rows, encabezado, selectedRows,
        openModalRecepciones,
        page,
        numPages,
    }

    const actions = {
        handleChangeInstitucion,
        handleChangeInstalacion,
        handleChangeArea,
        loadInstituciones,
        loadInstalaciones,
        loadAreas,
        setAlertOpen,
        handleLimpiar,
        handlePageChange,
        handleSelectionRows,
        openModalRecepcionesDosimetro,
        closeModalRecepcionesDosimetro,
        saveRecepcionesDosimetro,
        botonesDataTable,
        handleOpenAlert,
        setSelectedInstitucion
    }

    useEffect(() => {
        listAllAsignacionesByAreas()
    }, [page, selectedArea])

    return {data, actions}
}

export default useAsignacionDosimetriaPage
