import { useState, useEffect, useCallback } from "react"
import { useHistory } from 'react-router-dom'
import useAreaService from "../../services/area"
import useInstitucionService from "../../services/instituciones"
import useDosimetroService from "../../services/tecnicoPlanificacion"
import useIsLoged from '../utils/useIsLoged'
import { addRow } from '../../utils/rows'
import moment from "moment";

const useRecepcionesPage = ({entregaDeEventos}) => {
    useIsLoged()
    const history = useHistory()
    const { getAreasByIdInstalacion } = useAreaService()
    const { getInstitucionesByStatus, getInstalacionesByInstitucionId } = useInstitucionService()
    const { getEventosDosimetriaByTipo, recibirEventosDosimetria, entregarEventosDosimetria } = useDosimetroService()

    // Para almacenar el nombre con el id del dato seleccionado en el combobox
    const [selectedInstitucion, setSelectedInstitucion] = useState(null)
    const [selectedInstalacion, setSelectedInstalacion] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([]);

    // Para capturar las filas seleccionadas en la tabla mediante el checkbox
    const [selectedRows, setSelectedRows] = useState([]);

    // Para abrir y cerrar el modal para guardar las recepciones de dosimetro
    const [openModalRecepciones, setOpenModalRecepciones] = useState(false)

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);

    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))

    // Para almacenar los datos de la persona que entrega los dosímetros
    const [nombres, setNombres] = useState('')
    const [apellidos, setApellidos] = useState('')
    const [DUI, setDUI] = useState('')
    const [licencia, setLicencia] = useState('')
    const [pasaporte, setPasaporte] = useState('')

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    if (!page) {
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    // Funcion que almacena el id de la seleccion de una institicion
    const handleChangeInstitucion = (value) => {
        if (value == null) {
            setSelectedInstitucion(null)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        } else {
            setSelectedInstitucion(value)
            setSelectedInstalacion(null)
            setSelectedArea(null)
        }
    }
    // Funcion que almacena el id de la seleccion de una instalacion
    const handleChangeInstalacion = (value) => {
        if (value == null) {
            setSelectedInstalacion(null)
            setSelectedArea(null)
        } else {
            setSelectedInstalacion(value)
            setSelectedArea(null)
        }
    }
    // Funcion que almacena el id de la seleccion de un area
    const handleChangeArea = (value) => {
        if (value == null) {
            setSelectedArea(null)
        } else {
            setSelectedArea(value)
        }
    }

    // Para almacenar el nombre de la persona que entrega el dosimetro
    const handleOnChangeNombres = (value) => {
        const result = value.replace(/^[0-9]/g, '');
        setNombres(result)
    }

    // Para almacenar los apellidos de la persona que entrega el dosimetro
    const handleOnChangeApellidos = (value) => {
        const result = value.replace(/^[0-9]$/g, '');
        setApellidos(result)
    }

    // Para almacenar el DUI de la persona que entrega el dosimetro
    const handleOnChangeDUI = (value) => {
        // const result = value.replace(/\D/g, '');
        setDUI(value)
    }

    // Para almacenar la licencia de la persona que entrega el dosimetro
    const handleOnChangeLicencia = (value) => {
        const result = value.replace(/\D/g, '');
        setLicencia(result)
    }

    // Para almacenar el pasaporte de la persona que entrega el dosimetro
    const handleOnChangePasaporte = (value) => {
        const result = value.replace(/\D/g, '');
        setPasaporte(result)
    }

    // Verifica si hay campos vacíos
    const checkErrors = () => {
        if (nombres && apellidos && (DUI || licencia || pasaporte)){
            return false
        } else {
            return true
        }
    }

    // Funcion que filtra los datos de acuerdo a un valor escrito
    const dataFiltrada = async (data, value) => {
        // Devuelve los datos filtrados por el nombre
        const results = data.filter((resp) =>
            resp.nombre.toLowerCase().includes(value)
        )
        let options = []
        results.forEach(({ id, nombre }) => {
            const value = id
            const label = nombre
            const resultado = {
                label,
                value: value.toString(),
            }
            options = [...options, resultado]
        })
        // console.log("options", options)
        return options
    }
    // Funcion que carga las institucions
    const loadInstituciones = useCallback(async (inputValue) => {
        const response = await getInstitucionesByStatus(1)
        if (inputValue) {
            // Verifica si hay un valor ingresado
            const resultadoFiltrado = dataFiltrada(response, inputValue)
            return resultadoFiltrado
        } else {
            let options = []
            response.forEach(({id, nombre}) => {
                const value = id
                const label = nombre
                const resultado = {
                    label,
                    value: value.toString(),
                }
                options = [...options, resultado]
            })
            // console.log(options)
            return options
        }
    }, [getInstitucionesByStatus])

    // Funcion que carga las instalaciones de acuerdo a la institucion seleccionada
    const loadInstalaciones = useCallback(async (inputValue) => {
        // console.log("Este es un cambio", selectedInstitucion)
        // if (selectedValueInstitucion) {
        if (inputValue) {
            // Verifica si hay un valor ingresado
            const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
            const {instalaciones} = response
            const resultadoFiltrado = dataFiltrada(instalaciones, inputValue)
            return resultadoFiltrado
        } else {
            const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
            const {instalaciones} = response
            let optionsCombo = []
            // console.log("loadInstalaciones", instalaciones)
            instalaciones.forEach(({id, nombre}) => {
                const value = id
                const label = nombre
                const resultadoComboBox = {
                    label,
                    value: value.toString(),
                }
                optionsCombo = [...optionsCombo, resultadoComboBox]
            })
            // console.log(optionsCombo)
            return optionsCombo
        }
        // }
    }, [getInstalacionesByInstitucionId, selectedInstitucion])

    // Funcion que carga las areas de acuerdo a la instalacion seleccionada
    const loadAreas = useCallback(
        async (inputValue) => {
            // Verifica si se ha seleccionado una instalacion
            if (selectedInstalacion) {
                const response = await getAreasByIdInstalacion(selectedInstalacion?.value)
                const {data} = response
                if (inputValue) {
                    // Verifica si hay un valor ingresado
                    return dataFiltrada(data, inputValue)
                } else {
                    let optionsCombo = []
                    data?.forEach(({id, nombre}) => {
                        const value = id
                        const label = nombre
                        const resultadoComboBox = {
                            label,
                            value: value.toString(),
                        }
                        optionsCombo = [...optionsCombo, resultadoComboBox]
                    })
                    return optionsCombo
                }
            }
        }, [selectedInstalacion, getAreasByIdInstalacion])

    const encabezado = ["Institucion", "Instalacion", "Area", "Fecha Asignada"]
    const columns = [
        {
            field: 'institucion',
            headerName: 'Institucion',
            width: 300,
            resize: true,
            sortable: false,
        },
        {
            field: 'instalacion',
            headerName: 'Instalacion',
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: 'area',
            headerName: 'Area',
            width: 110,
            editable: false,
            sortable: false,
        },
        {
            field: 'fechaAsignada',
            headerName: 'Fecha Asignada',
            width: 180,
            editable: false,
            sortable: false,
        },
        {
            field: 'fechaRecepcion',
            headerName: 'Fecha Recepcion',
            width: 200,
            editable: false,
            sortable: false,
        },
        // {
        //   field: 'fullName',
        //   headerName: 'Full name',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (params) =>
        //     `${params.getValue(params.id, 'instalacion') || ''} ${
        //       params.getValue(params.id, 'institucion') || ''
        //     }`,
        // },
    ];

    const getDosimetrosByTipo = useCallback(async () => {
        startLoading()
        const filtro = {}
        filtro.estado_evento = "EN ESPERA DE RECEPCION"
        filtro.tipo_evento = entregaDeEventos ? "ENTREGA DE DOSIMETRO" : "RECEPCION DE DOSIMETRO"
        filtro.area_id = Number(selectedArea?.value)
        try {
            const response = await getEventosDosimetriaByTipo(filtro)
            // console.log(response)
            let data = []
            setRows([])
            response.forEach(({id, area, fecha_asignada, fecha}) => {
                const datos = {}
                datos.id = id
                datos.institucion = area?.instalacion?.institucion?.nombre
                datos.instalacion = area?.instalacion?.nombre
                datos.area = area?.nombre
                datos.fechaAsignada = fecha_asignada
                datos.fechaRecepcion = fecha
                data.push(datos)
            })
            setRows(data)
        } catch (e) {
            handleOpenAlert('error', `Ocurrió un error, intente de nuevo mas tarde`)
        }
        stopLoading()
    }, [selectedArea, getEventosDosimetriaByTipo])

    const handleSelectionRows = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedData = rows.filter((row) =>
            selectedIDs.has(row.id),

        )
        setSelectedRows([])
        // let a = []
        selectedData.forEach(({id, institucion, instalacion, area, fechaAsignada}) => {
            const key = id
            const cell = [institucion, instalacion, area, fechaAsignada]
            // const datos_seleccionados = {
            //     'key': key,
            //     'cell': cell
            // }
            // a.push(datos_seleccionados)
            addRow(key, cell, setSelectedRows)
            // setSelectedRows((selectedRows)=>[...selectedRows, datos_seleccionados])
        })
        // setSelectedRows(a)
        //setSelectedRows(selectedRows);
        // console.log(selectedRows)
    }

    const openModalRecepcionesDosimetro = () => {
        if(selectedRows.length > 0) {
            setOpenModalRecepciones(true)
        } else {
            handleOpenAlert('error', `Debe seleccionar uno o más elementos`)
            return
        }
    }

    const closeModalRecepcionesDosimetro = () => {
        setOpenModalRecepciones(false)
    }

    // Metodo que guarda las recepciones de los dosimetros seleccionados
    const saveRecepcionesDosimetro = async () => {
        startLoading()
        if (checkErrors()) {
            handleOpenAlert('error', `Debe llenar todos los campos`)
            stopLoading()
            return
        } else {
            // console.log("Guardando recepciones dosimetros")
            const sendData = {
                "nombres": nombres,
                "apellidos": apellidos,
                "dui": DUI,
                "licencia": licencia,
                "pasaporte": pasaporte,
                "fecha": moment(Date.now()).format("YYYY-MM-DD"),
            }
            // Procesando los dosímetros seleccionados a recibir
            const eventos = []
            selectedRows.forEach(seleccion => {
                eventos.push({"id": seleccion.key})
            })
            sendData.eventos = eventos
            const response = entregaDeEventos ? await entregarEventosDosimetria(sendData) : await recibirEventosDosimetria(sendData)
            if (response.error) {
                handleOpenAlert("error", response.error)
            } else {
                handleOpenAlert(
                    "success",
                    `${entregaDeEventos ? 'Entrega ' : 'Recepción '}realizada correctamente.`
                )
                await getDosimetrosByTipo()
                closeModalRecepcionesDosimetro()
                setNombres("")
                setApellidos("")
                setDUI("")
                setLicencia("")
                setPasaporte("")
            }
        }
        stopLoading()
    }

    const clearData = () => {
        setSelectedInstitucion(null)
        setSelectedInstalacion(null)
        setSelectedArea(null)
        setRows([])
        setSelectedRows([])
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        selectedInstitucion,
        selectedInstalacion,
        selectedArea,
        rows, columns, encabezado, selectedRows,
        openModalRecepciones,
        nombres, apellidos, DUI, licencia, pasaporte
    }

    const actions = {
        handleChangeInstitucion,
        handleChangeInstalacion,
        handleChangeArea,
        loadInstituciones,
        loadInstalaciones,
        loadAreas,
        setAlertOpen,
        handlePageChange,
        handleSelectionRows,
        openModalRecepcionesDosimetro,
        closeModalRecepcionesDosimetro,
        saveRecepcionesDosimetro,
        clearData,
        handleOnChangeNombres,
        handleOnChangeApellidos,
        handleOnChangeDUI,
        handleOnChangeLicencia,
        handleOnChangePasaporte,
    }

    useEffect(async () => {
        if (selectedArea) {
            await getDosimetrosByTipo()
        }
    }, [selectedArea])

    return { data, actions }
}

export default useRecepcionesPage
