import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useRolService from '../../services/administracion/useRolService'
import { addRow, findRow, updateRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
import { allLetterAndSpace, isDUI, isEmail, isPhone, validPassword } 
    from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'
import Session from 'react-session-api'
import { useCookies } from 'react-cookie'
import { getNowInSeconds } from '../../utils/time'
import useLoginService from '../../services/sesion/useLoginService'
import useUserProfileService from '../../services/administracion/useUserProfileService'

const useUserProfilePage = () => {
    const { solicitarEnlace } = useLoginService()

    const { getAllRoles } = useRolService()

    const { createUsuario, getUsuarioById, getUsuarios, updateUsuario, deleteUsuario, getMe, updatePassword } = useUserProfileService()

    const history = useHistory()
    useIsLoged()
    const {id} = useParams()
    //Estado para mensajes de error
    const [error, setError] = useState(null)
    //Estado para las filas de la tabla
    const [rows, setRows] = useState([])
    //Estado para almacenar los roles que se mostraran en el combobox
    const [roles, setRoles] = useState([])
    //Estados para controlar los inputs 
    //(errorInput y helperInput sirven para mostrar un error en el input)
    const [nombreUsuario, setNombreUsuario] = useState('')
    const [errorNombreUsuario, setErrorNombreUsuario] = useState(false)
    const [helperNombreUsuario, setHelperNombreUsuario] = useState('')
    const [duiUsuario, setDuiUsuario] = useState('')
    const [errorDuiUsuario, setErrorDuiUsuario] = useState(false)
    const [helperDuiUsuario, setHelperDuiUsuario] = useState('')
    const [rol, setRol] = useState('')
    const [telefonoUsuario, setTelefonoUsuario] = useState('')
    const [errorTelefonoUsuario, setErrorTelefonoUsuario] = useState(false)
    const [helperTelefonoUsuario, setHelperTelefonoUsuario] = useState('')
    const [celularUsuario, setCelularUsuario] = useState('')
    const [errorCelularUsuario, setErrorCelularUsuario] = useState(false)
    const [helperCelularUsuario, setHelperCelularUsuario] = useState('')
    const [correoElectronicoUsuario, setCorreoElectronicoUsuario] = useState('')
    const [errorCorreoElectronicoUsuario, setErrorCorreoElectronicoUsuario] = useState(false)
    const [helperCorreoElectronicoUsuario, setHelperCorreoElectronicoUsuario] = useState('')
    const [contrasenia1Usuario, setContrasenia1Usuario] = useState('')
    const [errorContrasenia1Usuario, setErrorContrasenia1Usuario] = useState(false)
    const [helperContrasenia1Usuario, setHelperContrasenia1Usuario] = useState('')
    const [contrasenia2Usuario, setContrasenia2Usuario] = useState('')
    const [errorContrasenia2Usuario, setErrorContrasenia2Usuario] = useState(false)
    const [helperContrasenia2Usuario, setHelperContrasenia2Usuario] = useState('')
    
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [usuarioToDelete, setUsuarioToDelete] = useState(null)

    //Estado para progress, el setLoading siempre debe ser llamado en funciones 
    //asincronas para funcionar correctamente,aqui nos quedamos
    const [loading, setLoading] = useState(0)

    //Cookies
    const [cookies, setCookie, removeCookie] = useCookies(['token','rol', 'id'])
    
    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Controladores mensajes confirmacion
    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setUsuarioToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorNombreUsuario || nombreUsuario === "" ||  errorCorreoElectronicoUsuario 
            || correoElectronicoUsuario === ""  || errorDuiUsuario ||  duiUsuario === "" || rol === "")
        {
            return true            
        }
        if (!id && (errorContrasenia1Usuario || contrasenia1Usuario === "" || 
            errorContrasenia2Usuario || contrasenia2Usuario  === "") ){
            return true
        }
        return false
    }

    //Manejador boton recuperar contraseña
    const enviarCorreoRecuperacion = async (id) => {
        const usuario = await getUsuarioById(id)
        if (usuario.error){
            handleOpenAlert('error', usuario.error)
        }
        else{
            const correo = {
                "email": usuario.email
            }
            const result = await solicitarEnlace(correo)
            if (result.error){
                handleOpenAlert('error', result.error)
            }
            else{
                handleOpenAlert('success', 'Se envio un correo al correo electronico del usuario con un enlace para cambiar su contraseña.')
            }   
        }
    }

    //Funciones para controlar cambios en inputs
    const onChangeNombreUsuario = (text)  =>
    {
        setNombreUsuario(text)
        if(!allLetterAndSpace(text)){
            setErrorNombreUsuario(true)
            setHelperNombreUsuario("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombreUsuario(false)
            setHelperNombreUsuario("")
        }
    }

    const onChangeDuiUsuario = (text)=>
    {
        setDuiUsuario(text)
        if(!isDUI(text) && text !== ""){
            setErrorDuiUsuario(true)
            setHelperDuiUsuario("Debe ingresar un DUI valido.")
        }else{
            setErrorDuiUsuario(false)
            setHelperDuiUsuario("")
        }
    }

    const onChangeTelefonoUsuario = (text)=>
    {
        setTelefonoUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoUsuario(true)
            setHelperTelefonoUsuario("Debe ingresar un telefono valido valido.")
        }else{
            setErrorTelefonoUsuario(false)
            setHelperTelefonoUsuario("")
        }
    }

    const onChangeCelularUsuario = (text)=>
    {
        setCelularUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularUsuario(true)
            setHelperCelularUsuario("Debe ingresar un telefono valido valido.")
        }else{
            setErrorCelularUsuario(false)
            setHelperCelularUsuario("")
        }
    }

    const onChangeCorreoElectronicoUsuario = (text)=>
    {
        setCorreoElectronicoUsuario(text)
        if(!isEmail(text) && text !== ""){
            setErrorCorreoElectronicoUsuario(true)
            setHelperCorreoElectronicoUsuario("Debe ingresar un correo electronico valido.")
        }else{
            setErrorCorreoElectronicoUsuario(false)
            setHelperCorreoElectronicoUsuario("")
        }
    }

    const onChangeContrasenia1Usuario = (text)=>
    {
        setContrasenia1Usuario(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia1Usuario(true)
            setHelperContrasenia1Usuario("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia1Usuario(false)
            setHelperContrasenia1Usuario("")
        }
    }

    const onChangeContrasenia2Usuario = (text)=>
    {
        setContrasenia2Usuario(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia2Usuario(true)
            setHelperContrasenia2Usuario("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia2Usuario(false)
            setHelperContrasenia2Usuario("")
        }
    }

    //Funcion para manejar la creacion de un usuario
    const onCreateUsuario = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        if(contrasenia1Usuario !== contrasenia2Usuario){
            handleOpenAlert('error', "Las contraseñas introducidas no coinciden.")
            return
        }
        let newUsuario = new FormData()
        newUsuario.append("nombres", nombreUsuario)
        newUsuario.append("dui", duiUsuario)
        if(telefonoUsuario){
            newUsuario.append("telefono", telefonoUsuario)
        }
        if(celularUsuario){
            newUsuario.append("telefono_celular", celularUsuario)
        }
        newUsuario.append("email", correoElectronicoUsuario)
        newUsuario.append("role_id", rol)
        newUsuario.append("password", contrasenia1Usuario)
        newUsuario.append("password_confirmation", contrasenia2Usuario)
        startLoading()
        const result = await createUsuario(newUsuario)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombres} = result
            //const cell = [nombres, dui, telefono, telefono_celular, email]
            //const key = id
            //addRow(key, cell, setRows)
            cancel()
            handleOpenAlert('success', `El usuario "${nombres}" se creo correctamente.`)
            handleGetUsuarios()
            
        }
        stopLoading()
        
    }

    //Funcion para manejar una actualizacion en los datos de un usuario
    const onUpdateUsuario = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let usuarioActualizado = new FormData()
        usuarioActualizado.append("nombres", nombreUsuario)
        usuarioActualizado.append("dui", duiUsuario)
        if(telefonoUsuario){
            usuarioActualizado.append("telefono", telefonoUsuario)
        }
        if(celularUsuario){
            usuarioActualizado.append("telefono_celular", celularUsuario)
        }
        usuarioActualizado.append("email", correoElectronicoUsuario)
        usuarioActualizado.append("role_id", rol)
        usuarioActualizado.append("_method", "PUT")
        startLoading()
        const result = await updateUsuario(id, usuarioActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombres, dui, telefono, telefono_celular, email} = result
            const newCells = [nombres, dui, telefono, telefono_celular, email]
            updateRow(id, newCells, setRows)
            if(Session.get("id") === Number(id)){
                const resultMe = await getMe()
                if (resultMe.error){
                    handleOpenAlert('error', resultMe.error)
                }else{
                    // console.log('se actualizo este usuario', resultMe)
                    console.log('Token en update', cookies.token)
                    Session.set("rol", resultMe.role)
                    removeCookie('rol', {path: '/'})
                    setCookie('rol', resultMe.role, {path: '/',
                        maxAge: (cookies['token'].expire_date - getNowInSeconds())})
                    }
                }
            handleOpenAlert('success', `El usuario "${nombres}" se actualizo correctamente.`)
            cancel()
        }
        stopLoading()
    }
     //Funcion que actualiza la contraseña de un usuario
    const onUpdatePassword = async () => {
        const userId = Session.get("id");
        if (!userId) {
            handleOpenAlert('error', 'No se pudo obtener el ID del usuario en curso.');
            return;
        }
        if (contrasenia1Usuario !== contrasenia2Usuario) {
            handleOpenAlert('error', "Las contraseñas introducidas no coinciden.");
            return;
        }
        startLoading();
        const result = await updatePassword(userId, contrasenia1Usuario, contrasenia2Usuario);
        if (result.error) {
            handleOpenAlert('error', result.error);
        } else {
            handleOpenAlert('success', `La contraseña del usuario "${nombreUsuario}" se actualizó correctamente.`);
        }
        stopLoading();
    };

    //Funcion para manejar el boton eliminar usuario
    const onDeleteUsuario = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteUsuario(usuarioToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombres} = result
            //deleteRow(id, setRows)
            handleGetUsuarios()
            editCancel()
            handleOpenAlert('success', `El usuario "${nombres}" se desactivo correctamente.`)
            if(Session.get("id") === Number(id)){
                Session.clear()
                removeCookie('token', {path: '/'})
                removeCookie('rol', {path: '/'})
                removeCookie('id', {path: '/'})
                history.push('/iniciar-sesion')
            }
        }
        stopLoading()
    }

    //Funciones para manejar la consulta de los datos de un usuario
    const getUsuario = useCallback(async (id) => {
        startLoading()
        const usuario = await getUsuarioById(id)
        if (usuario.error){
            handleOpenAlert('error', usuario.error)
        }
        else{
            setNombreUsuario(usuario.nombres)
            setDuiUsuario(usuario.dui)
            setTelefonoUsuario(usuario.telefono)
            setCelularUsuario(usuario.telefono_celular)
            setRol(usuario.role_id.toString())
            setCorreoElectronicoUsuario(usuario.email)
            setContrasenia1Usuario("")
            setContrasenia2Usuario("")
        }
        stopLoading()
    }, [getUsuarioById])

    
    const handleGetUsuarios = useCallback(async () => {
        startLoading()
        const usuarios = await getUsuarios(page)
        if (usuarios.error){
            setError(usuarios.error)
        }
        else{
            setRows([])
            setNumPages(usuarios.last_page)
            usuarios.data.forEach(({id, nombres, dui, telefono, 
                telefono_celular, email}) => {
                const key = id
                const cell = [nombres, dui, telefono, telefono_celular, email]
                addRow(key, cell, setRows)
            })
            if(page > usuarios.last_page){
                setPage(usuarios.last_page)
                history.push(`${window.location.pathname}?page=${usuarios.last_page}`)
            }
        }
        stopLoading()
    }, [getUsuarios, history, page])

    // Function to get the current user's ID from the session
    const getCurrentUserId = () => {
        return Session.get("id");
    };

    // Function to fetch the current user's data
    const fetchCurrentUserData = useCallback(async () => {
        const userId = getCurrentUserId();
        if (userId) {
            await getUsuario(userId);
        } else {
            handleOpenAlert('error', 'No se pudo obtener el ID del usuario en curso.');
        }
    }, [getUsuario]);

    //Funcion maneja boton editar
    const editClick = (id) => {
        history.push(`/admin/usuario/${id}?page=${page}`)
    }


    //Funcion maneja boton cancelar edicion
    const editCancel = () => {
        cancel()
        history.push(`/admin/usuario?page=${page}`)
    }

    //Funcion cancelar (regresa los estados al valor inicial)
    const cancel = () => {
        setNombreUsuario("")
        setDuiUsuario("")
        setTelefonoUsuario("")
        setCelularUsuario("")
        setRol("")
        setCorreoElectronicoUsuario("")
        setContrasenia1Usuario("")
        setContrasenia2Usuario("")
        setErrorCelularUsuario(false)
        setErrorContrasenia1Usuario(false)
        setErrorContrasenia2Usuario(false)
        setErrorCorreoElectronicoUsuario(false)
        setErrorDuiUsuario(false)
        setErrorNombreUsuario(false)
        setErrorTelefonoUsuario(false)
    }

    const clearPasswordFields = () => {
        setContrasenia1Usuario("");
        setContrasenia2Usuario("");
        setErrorContrasenia1Usuario(false);
        setErrorContrasenia2Usuario(false);
        setHelperContrasenia1Usuario("");
        setHelperContrasenia2Usuario("");
    };

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        rows,
        roles,
        nombreUsuario,
        errorNombreUsuario,
        helperNombreUsuario,
        duiUsuario,
        errorDuiUsuario,
        helperDuiUsuario,
        rol,
        telefonoUsuario,
        errorTelefonoUsuario,
        helperTelefonoUsuario,
        celularUsuario,
        errorCelularUsuario,
        helperCelularUsuario,
        correoElectronicoUsuario,
        errorCorreoElectronicoUsuario,
        helperCorreoElectronicoUsuario,
        contrasenia1Usuario,
        errorContrasenia1Usuario,
        helperContrasenia1Usuario,
        contrasenia2Usuario,
        errorContrasenia2Usuario,
        helperContrasenia2Usuario,
        id,
        openEdit,
        openDelete,
        usuarioToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        onDeleteUsuario,
        onCreateUsuario,
        onUpdateUsuario,
        onUpdatePassword,
        editClick,
        enviarCorreoRecuperacion,
        onChangeNombreUsuario,
        onChangeDuiUsuario,
        setRol,
        onChangeTelefonoUsuario, 
        onChangeCelularUsuario, 
        onChangeCorreoElectronicoUsuario, 
        onChangeContrasenia1Usuario, 
        onChangeContrasenia2Usuario,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setUsuarioToDelete,
        setAlertOpen,
        handlePageChange,
        clearPasswordFields,
    }

    const handleGetRoles = useCallback(async () => {
        startLoading()
        const roles = await getAllRoles()
        if(roles.error){
            handleOpenAlert('error', roles.error)
        }
        else{
            setRoles([])
            roles.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const rolComboBox = {
                    label,
                    value: value.toString()
                }
                setRoles((roles) => [...roles, rolComboBox])
            })
        }
        stopLoading()
    }, [getAllRoles])

    const loadData = useCallback(async () => {
        await handleGetRoles()
        await handleGetUsuarios()
    }, [handleGetRoles, handleGetUsuarios])

    //useEffect para cargar datos en la tabla y combobox de roles
    useEffect(() => {
        loadData()
        //Solo se ejecuta cuando cambian las funciones setRows o setRoles
    }, [loadData])

    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getUsuario(id)
        }
        //Solo se ejecuta cuando cambia el valor de id o getUsuario
    }, [id, getUsuario])

    // useEffect to load current user's data on component mount
    useEffect(() => {
        fetchCurrentUserData();
    }, [fetchCurrentUserData]);

    return {error, data, actions}
}

export default useUserProfilePage