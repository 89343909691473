import axios from "axios";
import { useCallback } from "react";
import headers from "../utils/headers";
import url from "../utils/url";
import useErrorHandler from "../utils/useErrorHandler";

const estadoUrl = `${url}estadoca`;

const useEstadoSolicitudesRecibidasService = () => {
  const { errorHandler } = useErrorHandler();

  const createEstado = useCallback(
    async (estado) => {
      try {
        const response = await axios.post(estadoUrl, estado, {
          headers: headers(),
        });

        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getEstadoById = useCallback(
    async (idEstado) => {
      const requestUrl = `${estadoUrl}/${idEstado}`;
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const cambiarNombreAName = (listaDeObjetos) => {
    const nuevaLista = listaDeObjetos.map((objeto) => {
      // Clonamos el objeto para evitar modificar el original
      const nuevoObjeto = { ...objeto };

      // Cambiamos la variable 'nombre' a 'name'
      if (nuevoObjeto.hasOwnProperty("nombre")) {
        nuevoObjeto.name = nuevoObjeto.nombre;
        delete nuevoObjeto.nombre;
      }

      return nuevoObjeto;
    });

    return nuevaLista;
  };

  const deleteEstado = useCallback(
    async (idEstado) => {
      const requestUrl = `${estadoUrl}/${idEstado}`;
      try {
        const response = await axios.delete(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getAllEstados = useCallback(async () => {
    const requestUrl = `${estadoUrl}`;
    try {
      const response = await axios.get(requestUrl, { headers: headers() });
      const { data } = response;
      return cambiarNombreAName(data);
    } catch (error) {
      return errorHandler(error);
    }
  }, [errorHandler]);

  const updateEstado = useCallback(
    async (idEstado, cambios) => {
      const requestUrl = `${estadoUrl}/${idEstado}`;
      try {
        const response = await axios.put(requestUrl, cambios, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    getEstadoById,
    getAllEstados,
    createEstado,
    deleteEstado,
    updateEstado,
  };
};

export default useEstadoSolicitudesRecibidasService;
