import { useEffect } from 'react'
import { getNowInSeconds } from '../../utils/time'
import Session from 'react-session-api'

const useHasExpired = () => {
    useEffect(() => {
        //Verifica si hay una sesion activa, si hay verifica si ha expirado
        const token = Session.get('token')
        const isLoged = token ? true : false
        if(isLoged){
            if(token.expire_date <= getNowInSeconds()){
                Session.clear()
                Session.set("expiredSession", true)
            }
        }
    })
}

export default useHasExpired