import React from "react";
import Grid from "@material-ui/core/Grid";
import FormDivider from "../../components/FormDivider";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, List } from "@material-ui/core";
import { BsCalendar } from "react-icons/bs";
import ComboBox from "../../components/ComboBox";
import ReactSelect from "../../components/ReactSelect";
import Progress from "../../components/Progress/Progress";
import Input from "../../components/Input/Input";
import TipoComponent from "../../components/ControlDeCalidad/TipoComponent";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import HorarioComponent from "../../components/ControlDeCalidad/HorarioComponent";
//import ControlAvanceTable from "./tablaControlAvanceCC";
import DateInput from "../../components/Date/DateInput";
import ControlAvanceTable from "../solicitudes_recibidas/tablaControlAvanceCC";
import ControlAvanceTableHistorial from "./controlAvanceTableHistorial";
import AppFrame from "../../components/AppFrame";
import FormHeader from "../../components/FormHeader";
import FileInput from "../../components/FileInput";
import Button from "../../components/Button";
import { RiAdminLine } from "react-icons/ri";
import Paper from "@material-ui/core/Paper";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import useContratoPage from "../../hooks/administracion/useContratoPage";
import Alert from "../../components/Alert";
import { FiDownload } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import ProgressBar from "../../components/ProgressBar";
import { Typography } from "@material-ui/core";
import FileTable from "./tablaArchivoHistorial";
import FileInputWithClose from "../../components/FileInput/FileInputWIthClose";
// import FileTable from "./tablaArchivosHistorial";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
    //marginBottom: ".5em",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export const EficienciaGlobalControlAvanceHistorialCC = (props) => {
  const classes = useStyles();

  return (
    <>
      <ControlAvanceTableHistorial
        tipos_control_avance={props.data.tipos_control_avance}
        setTipos_control_avance={props.data.setTipos_control_avance}
        cantidadNotEdit={true}
      />
      {/* Nombre de Actividad */}
      <Grid className={classes.input} item xs={12}>
        <Input
          label="Nombre del representante"
          size="small"
          disabled
          defaultValue="Nombre representante"
          variant="outlined"
          value={props.data.nombrePersonaRepresentante}
        />
      </Grid>

      {/* Paso */}
      <Grid className={classes.input} item xs={12}>
        <ReactSelect
          placeholder="Seleccione el paso"
          label="Paso*"
          asyncFunc={props?.actions?.loadPasos}
          onChange={
            props?.data?.selectedPaso
              ? undefined
              : props?.actions?.handleChangePaso
          }
          value={props?.data?.selectedPaso}
          isClearable={true}
          isDisabled={true}
          components={
            props?.data?.selectedPaso
              ? {
                  Menu: () => null,
                }
              : undefined
          }
        />
      </Grid>
      <DateInput
        fecha={props.data.fecha_solicitud}
        setFecha={props.data.setFecha_solicitud}
        isEditable={false}
        label="Fecha de Solicitud"
      />
      <DateInput
        fecha={props.data.fecha_visita_tecnica}
        setFecha={props.data.setFecha_visita_tecnica}
        isEditable={false}
        label="Fecha de Visita Tecnica"
      />
      <DateInput
        fecha={props.data.fecha_entrega_de_informe_tecnico}
        setFecha={props.data.setFecha_entrega_de_informe_tecnico}
        isEditable={false}
        label="Fecha de Entrega De Informe Tecnico"
      />
      <DateInput
        fecha={props.data.fecha_entrega_certificado}
        setFecha={props.data.setFecha_entrega_certificado}
        isEditable={false}
        label="Fecha de Entrega de Certificado"
      />
      <DateInput
        fecha={props.data.fecha_notificacion}
        setFecha={props.data.setFecha_notificacion}
        isEditable={false}
        label="Fecha de Notificacion"
      />
      <DateInput
        fecha={props.data.fecha_entrega}
        setFecha={props.data.setfecha_entrega}
        isEditable={false}
        label="Fecha de Entrega"
      />
      <Grid className={classes.input} item xs={12}>
        {props.data.progress >= 0 ? (
          <div>
            <Typography>Porcentaje de carga</Typography>
            <ProgressBar value={props.data.progress} />
          </div>
        ) : (
          <div>
            <FileInput
              label="Subida de archivos"
              placeholder="Seleccione el archivo a subir"
              onChange={props.actions.handleOnChangeHistorialActividad}
              required={true}
            />
            <Typography variant="caption">
              Para subir un archivo, seleccione el campo de subida de archivos,
              elegir el archivo y hacer clic en el botón de aceptar que aparece
              al final de este modal.
            </Typography>
          </div>
        )}
      </Grid>
      <FileTable
        files={props?.data?.listArchivos}
        onDownload={props?.actions?.descargarReporteHistorialActividad}
        onDelete={props?.actions?.handleClickOpenDelete}
      />
      <Dialog
        open={props?.data?.openDelete}
        handleClose={props?.actions?.handleCloseDelete}
        // handleAccept={() => console.log('hola')}
        handleAccept={props?.actions?.onDeleteContrato}
        title={"Desea eliminar el registro?"}
        content={"El archivo sera eliminado del sistema."}
        // transitionFunc={() => props?.data?.archivoToDelete(null)}
      />
    </>
  );
};

export default EficienciaGlobalControlAvanceHistorialCC;
