import React from 'react'
import AppFrame from '../components/AppFrame'
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import logoCian from "../assets/CIAN.png"
import { makeStyles } from '@material-ui/core/styles';
import useIsLoged from '../../src/hooks/utils/useIsLoged'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const InicioPage = () => {

    useIsLoged()

    const classes = useStyles();

    return (
        <AppFrame loged={false}>
            <Container maxWidth="sm">
                <Paper className={classes.paper}>
                    <img src={logoCian} alt="Logo CIAN FIA UES"/>
                </Paper>
            </Container>
        </AppFrame>
    )
}


export default InicioPage