import React from 'react'
import Grid from '@material-ui/core/Grid'

const PermisoDenegado = props => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            >
            No tiene permisos para acceder a este sitio.
        </Grid>
    )
}


export default PermisoDenegado
