import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function SelectComponent({
  data,
  setListaSeleccionados,
  placeholder,
  label,
  valueSet,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [availableOptions, setAvailableOptions] = useState(data);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // Si el arreglo data no está vacío, agregar los elementos a selectedItems
    if (valueSet.length > 0) {
      const idsValueSet = valueSet.map((value) => value.id)

      setSelectedItems(valueSet);
      setListaSeleccionados(valueSet)

      const updatedOptions = availableOptions.filter(
        // (option) => option.id !== newValue.id
        (option) => idsValueSet.every((id) => id !== option.id)
      );
      setAvailableOptions(updatedOptions);
    }
  }, [valueSet]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleAddItem = () => {
    if (inputValue.trim() !== "") {
      const newValue = availableOptions.find(
        (option) => option.name === inputValue.trim()
      );

      if (newValue) {
        setSelectedItems([...selectedItems, newValue]);
        setListaSeleccionados([...selectedItems, newValue]);

        const updatedOptions = availableOptions.filter(
          (option) => option.id !== newValue.id
        );
        setAvailableOptions(updatedOptions);

        setInputValue("");
      }
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    const updatedItems = selectedItems.filter(
      (item) => item.id !== itemToRemove.id
    );
    setSelectedItems(updatedItems);
    setListaSeleccionados(updatedItems);
    setAvailableOptions([...availableOptions, itemToRemove]);

    setInputValue(""); // Restablecer el valor del input después de eliminar
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Autocomplete
        options={availableOptions}
        getOptionLabel={(option) => option.name}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={placeholder} />
        )}
      />
      <Button variant="contained" color="primary" onClick={handleAddItem}>
        Agregar
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveItem(item)}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SelectComponent;