import axios from 'axios'
import url from "./utils/url"
import headers from './utils/headers'
import useErrorHandler from './utils/useErrorHandler'
import { useCallback } from 'react'

const instalacionUrl = `${url}instalaciones`

const useInstalacionService = () => {
    const { errorHandler } = useErrorHandler()

    const createInstalacion = useCallback(
        async (instalacion) => {
            try {
                const response = await axios.post(instalacionUrl, instalacion,
                    { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getInstalacionById = useCallback(
        async (instalacion) => {
            const requestUrl = `${instalacionUrl}/${instalacion}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getInstalacionesByStatus = useCallback(
        async (estado) => {
            const requestUrl = `${instalacionUrl}/status/${estado}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getInstalacionesByInstitucion = useCallback(
        async (institucion) => {
            const requestUrl = `${instalacionUrl}/institucion/${institucion}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const updateInstalacion = useCallback(
        async (instalacion, cambios) => {
            const requestUrl = `${instalacionUrl}/${instalacion}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const deleteInstalacion = useCallback(
        async (instalacion) => {
            const requestUrl = `${instalacionUrl}/${instalacion}`
            try {
                const response = await axios.delete(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    return {
        createInstalacion, getInstalacionById, getInstalacionesByStatus,
        getInstalacionesByInstitucion, updateInstalacion, deleteInstalacion
    }
}
export default useInstalacionService