import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LoginPage from './pages/sesion/LoginPage'
import DatosAreasPage from './pages/inscripcion/DatosAreasPage'
import DatosInstalacionesPage
    from './pages/inscripcion/DatosInstalacionesPage'
import DatosPersonalInstitucionPage
    from './pages/inscripcion/DatosPersonalInstitucionPage'
import DatosTOEPage from './pages/inscripcion/DatosTOEPage'
import ResumenPage from './pages/inscripcion/ResumenPage'
import MinisterioPage from './pages/administracion/MinisterioPage'
import CargoPage from './pages/administracion/CargoPage'
import PracticaPage from './pages/administracion/PracticaPage'
import ResponsabilidadPage from './pages/administracion/ResponsabilidadPage'
import SectorPage from './pages/administracion/SectorPage'
import RolPage from './pages/administracion/RolPage'
import UsuarioPage from './pages/administracion/UsuarioPage'
import ContratoPage from './pages/administracion/ContratoPage'
import DosimetroPage from './pages/administracion/DosimetroPage'
import { useCookies } from 'react-cookie'
import Session from 'react-session-api'
import ThemeProvider from './components/ThemeProvider'
import RecuperarPage from './pages/sesion/RecuperarPage'
import SolicitudInscripcionPage from './pages/inscripcion/SolicitudInscripcionPage'
import SolicitudInscripcionEditPage from './pages/inscripcion/SolicitudInscripcionEditPage'
import Planificacion from "./pages/planificacionDosimetria/PlanificacionPage";
import Error404Page from './pages/Error404Page'
import DatosDelegadoPage from './pages/inscripcion/DatosDelegadoPage'
import CotizacionHomePage from './pages/cotizacion/CotizacionHomePage'
import NuevaSolicitud from './pages/cotizacion/NuevaSolicitud'
import ProcesoSolicitudSecretariaClientePage from './pages/cotizacion/ProcesoSolicitudSecretariaCliente'
import EvaluacionEtapa2Page from './pages/inscripcion/EvaluacionEtapa2Page'
import SolicitudesPage from './pages/cotizacion/SolicitudesPage'
import EvaluacionEtapa1Page from './pages/inscripcion/EvaluacionEtapa1Page'
import DosimetriaControlPage from "./pages/dosimetriaControl/DosimetriaControlPage"
import AsignacionDosimetrosAreaToePage from "./pages/dosimetriaControl/AsignacionDosimetrosAreaToePage"
import DosimetriaControlCoordinadorPage from "./pages/dosimetriaControl/Coordinador/DosimetriaControlCoordinadorPage"
import InicioPage from "./pages/InicioPage"
import ConfiguracionReporteLecturaPage
    from "./pages/dosimetriaControl/ConfiguracionReporteLectura/ConfiguracionReporteLecturaPage";
import ClientePage from './pages/controlDeCalidad/clientePage'
import BorrameApp from './pages/controlDeCalidad/borrameMainPage'
import ActividadControlCalidad from './pages/controlDeCalidad/actividadesControlCalidadPage'
import TransportePage from './pages/transporte/transportePage'
import PersonalCCPage from './pages/controlDeCalidad/personalPage'
import EstadoSolicitudesRecibidasPage from './pages/solicitudes_recibidas/estadoPage'
import PasoSolicitudesRecibidasPage from './pages/solicitudes_recibidas/pasoPage'
import SolicitudesRecibidasCCPage from './pages/solicitudes_recibidas/solicitudesRecibidasPage'
import ReporteEficienciaGlobalPage from './pages/controlDeCalidad/ReporteEficienciaGlobalPage'
import ReporteIndicadoresPromedioPage from './pages/controlDeCalidad/ReporteIndicadoresPromedioPage'
import ReporteCantidadTiposPage from './pages/controlDeCalidad/ReporteCantidadTiposPage'
import ReporteIndicadoresDeCalidadPage from './pages/controlDeCalidad/ReporteIndicadoresDeCalidadPage'
import ClienteHistorialPage from './pages/historialDeReportes/clienteHistorialPage'
import ActividadHistorialPage from './pages/historialDeReportes/actividadHistorialPage'

const App = () => {
    const [cookies] = useCookies(['token', 'rol'])

    if (cookies['token'] && cookies['rol'] && cookies['id']) {
        Session.set("token", cookies['token'])
        Session.set("rol", cookies['rol'])
        Session.set("id", Number(cookies['id']))
    }
    console.log('Render app', Session.get("token"))
    // const isLoged = Session.get("token")

    return (
        <ThemeProvider>
            <Router>
                <Switch>
                    {
                    //TODO pantalla inicio
                    }
                    <Route exact path="/">
                         <LoginPage />
                    </Route>
                    <Route exact path="/inicio">
                        <InicioPage />
                    </Route>
                    <Route exact path="/admin/ministerio/:id?">
                        <MinisterioPage />
                    </Route>
                    <Route exact path="/admin/cargo/:id?">
                        <CargoPage />
                    </Route>
                    <Route exact path="/admin/dosimetro/:codigoEditar?">
                        <DosimetroPage />
                    </Route>
                    <Route exact path="/admin/practica/:id?">
                        <PracticaPage />
                    </Route>
                    <Route exact path="/admin/responsabilidad/:id?">
                        <ResponsabilidadPage />
                    </Route>
                    <Route exact path="/admin/sector/:id?">
                        <SectorPage />
                    </Route>
                    <Route exact path="/admin/rol/:id?">
                        <RolPage />
                    </Route>
                    <Route exact path="/admin/usuario/:id?">
                        <UsuarioPage />
                    </Route>
                    <Route exact path="/admin/contrato">
                        <ContratoPage />
                    </Route>
                    <Route exact path="/iniciar-sesion">
                        <LoginPage />
                    </Route>
                    <Route exact path="/iniciar-sesion/recuperar">
                        <RecuperarPage mode="recuperar" />
                    </Route>
                    <Route exact path="/cuenta/activar">
                        <RecuperarPage mode="activar" />
                    </Route>
                    <Route exact path="/inscripcion/:paso">
                        <SolicitudInscripcionPage />
                    </Route>
                    <Route exact path="/inscripcion/:paso/edit/:idSolicitud">
                        <SolicitudInscripcionEditPage />
                    </Route>
                    <Route exact path="/solicitudes_edicion">
                        <SolicitudesPage tipoSolicitud={100} />
                    </Route>
                    <Route exact path="/cliente/delegados/:id?">
                        <DatosDelegadoPage />
                    </Route>
                    <Route exact path="/cliente/instalaciones/:id?">
                        <DatosInstalacionesPage />
                    </Route>
                    <Route exact path="/cliente/areas/:id?">
                        <DatosAreasPage />
                    </Route>
                    <Route exact path="/cliente/personal/:id?">
                        <DatosPersonalInstitucionPage />
                    </Route>
                    <Route exact path="/cliente/toe/:id?">
                        <DatosTOEPage />
                    </Route>
                    <Route exact path="/cliente/resumen">
                        <ResumenPage />
                    </Route>
                    <Route exact path="/planificacion">
                        <Planificacion />
                    </Route>
                    <Route exact path="/solicitudes-inscripcion-etapa-1">
                        <SolicitudesPage tipoSolicitud={1} />
                    </Route>
                    <Route exact path="/solicitudes-inscripcion-etapa-1/:id">
                        <EvaluacionEtapa1Page />
                    </Route>
                    <Route exact path="/solicitudes-inscripcion-etapa-2">
                        <SolicitudesPage tipoSolicitud={2} />
                    </Route>
                    <Route exact path="/solicitudes-inscripcion-etapa-2/:id">
                        <EvaluacionEtapa2Page />
                    </Route>
                    <Route exact path="/cotizacion">
                        <CotizacionHomePage />
                    </Route>
                    <Route exact path="/nueva_solicitud">
                        <NuevaSolicitud />
                    </Route>
                    <Route exact path="/solicitudes_contratacion">
                        <SolicitudesPage tipoSolicitud={3} />
                    </Route>
                    <Route exact path="/solicitudes_cotizacion">
                        <SolicitudesPage tipoSolicitud={4} />
                    </Route>
                    <Route exact path="/solicitudes_modificacion">
                        <SolicitudesPage tipoSolicitud={5} />
                    </Route>
                    <Route exact path="/proceso_solicitud/:id/:tipo_comentario">
                        <ProcesoSolicitudSecretariaClientePage />
                    </Route>
                    <Route exact path="/control_dosimetria">
                        <DosimetriaControlPage />
                    </Route>
                    <Route exact path="/control_dosimetria/reporte">
                        <DosimetriaControlCoordinadorPage />
                    </Route>
                    <Route exact path="/control_dosimetria/toe/area/:area_id">
                        <AsignacionDosimetrosAreaToePage />
                    </Route>
                    <Route exact path="/configuracion_reporte_lectura">
                        <ConfiguracionReporteLecturaPage/>
                    </Route>
                    <Route exact path="/cliente/administrar/:id?">
                        <ClientePage/>
                    </Route>
                    <Route exact path="/control_de_calidad/actividades/:id?">
                        <ActividadControlCalidad/>
                    </Route>
                    <Route exact path="/transporte/administrar/:id?">
                        <TransportePage/>
                    </Route>
                    <Route exact path="/personalcc/administrar/:id?">
                        <PersonalCCPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/solicitudes_recibidas/estados/:id?">
                        <EstadoSolicitudesRecibidasPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/solicitudes_recibidas/pasos/:id?">
                        <PasoSolicitudesRecibidasPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/solicitudes_recibidas/:id?">
                        <SolicitudesRecibidasCCPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/reportes/eficiencia_global/:id?">
                        <ReporteEficienciaGlobalPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/reportes/indicadores_promedio/:id?">
                        <ReporteIndicadoresPromedioPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/reportes/cantidad_tipos/:id?">
                        <ReporteCantidadTiposPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/reportes/indicadores_de_calidad/:id?">
                        <ReporteIndicadoresDeCalidadPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/reportes/historial_clientes">
                        <ClienteHistorialPage/>
                    </Route>
                    <Route exact path="/control_de_calidad/reportes/actividad_clientes/:id?">
                        <ActividadHistorialPage/>
                    </Route>
                    <Route>
                        <Error404Page />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

export default App;
