import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useSolicitudCotizacionService from '../../services/solicitudCotizacion'
import { addRow, findRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
import useUsuarioService from '../../services/administracion/useUsuarioService'
import { BsFillChatDotsFill, BsFillChatFill } from 'react-icons/bs'

const useCotizacionPage = () => {

    const { getSolicitudsByStatus, getTipoSolicitudes,
        getSolicitudesByTipoAndFecha,
        getTipoSolicitudSecretaria, getSecretariaSolicitudes }
        = useSolicitudCotizacionService()
        
    useIsLoged()
    const { getMe } = useUsuarioService()

    const history = useHistory()

    //useIsLoged()
    const [error, setError] = useState(null)
    const [tipoSolicitud, setTipoSolicitud] = useState("")
    const [tiposDeSolicitudes, setTiposDeSolicitudes] = useState([])
    const [fecha, setFecha] = useState("")
    const [estado, setEstado] = useState("")
    const [dateRange, setDateRange] = useState({
        startDate: "",
        endDate: ""
    })
    const [value, setValue] = useState('Seleccione un rango de fecha')
    const [rows, setRows] = useState([])
    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)
    const [loading, setLoading] = useState(0)
    // Para el rol del usuario
    const [idRol, setIdRol] = useState("")

    //estado_dosimetro_ids para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }
    
    if (!page) {
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }


    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    // Funcion para getTiposDeSolicitud
    const getOptionsTipoDeSolicitudes = (tiposDeSolicitudes, role_id) => {
        setTiposDeSolicitudes([])
        tiposDeSolicitudes.forEach(({ id, nombre }) => {
            const solicitud = {
                value: id,
                label: nombre,
            }
            setTiposDeSolicitudes((tipoDeSolicitudes) => [...tipoDeSolicitudes, solicitud])
        })
        // Agregando la opcion de todas las solicitudes dependiendo del rol del usuario
        if (role_id === 7) {
            const solicitud = {
                value: 200,
                label: 'Todas las solicitudes',
            }
            setTiposDeSolicitudes((tipoDeSolicitudes) => [...tipoDeSolicitudes, solicitud])
        } else {
            const solicitud = {
                value: 100,
                label: 'Todas las solicitudes',
            }
            setTiposDeSolicitudes((tipoDeSolicitudes) => [...tipoDeSolicitudes, solicitud])
        }
    }

    // Funcion que devuelve los tipos de solicitudes
    const getTiposDeSolicitudes = async () => {
        startLoading()
        // Obteniendo los datos del usuario logueado
        const userMe = await getMe()
        const { role_id } = userMe //Obteniendo el rol del usuario logueado
        setIdRol(role_id)
        if (role_id === 7) { // Para el rol de secretaria
            const tiposDeSolicitudes = await getTipoSolicitudSecretaria()
            if (tiposDeSolicitudes.error) {
                setError(tiposDeSolicitudes.error)
            } else {
                getOptionsTipoDeSolicitudes(tiposDeSolicitudes, role_id)
            }
        } else { // Para el rol de cliente
            const tiposDeSolicitudes = await getTipoSolicitudes()
            if (tiposDeSolicitudes.error) {
                setError(tiposDeSolicitudes.error)
            }
            else {
                getOptionsTipoDeSolicitudes(tiposDeSolicitudes, role_id)
            }
        }
        stopLoading()
    }

    const handleSelectDate = (start, end) => {
        // Obteniendo la fecha del componente del rango de fecha
        const { _d: fechaInicio } = start
        const { _d: fechaFin } = end
        setDateRange({
            start: new Date(fechaInicio).toISOString(),
            end: new Date(fechaFin).toISOString()
        })
        // Texto que aparecerá en el componente del rango de fecha
        setValue(start.format('DD-MMM-YYYY') + "  -  " + end.format('DD-MMM-YYYY'))
    }

    const getSolicitudesPorTipoSolicitudFecha = useCallback(async () => {
        startLoading()
        const { start, end } = dateRange
        if (start === undefined || end === undefined) {
            handleOpenAlert('warning', 'Debe elegir un rango de fecha')
            stopLoading()
            return
        }
        const filtro = {
            tipo_solicitud: tipoSolicitud,
            fecha_inicial: start,
            fecha_final: end
        }
        const solicitud = await getSolicitudesByTipoAndFecha(page, filtro)
        if (solicitud.error) {
            handleOpenAlert('error', solicitud.error)
            setError(solicitud.error)
            stopLoading()
            return
        } else {
            setRows([])
            setNumPages(solicitud.last_page)
            solicitud.data.forEach(({ id, tipo_solicitud, fecha_aprobada, estado_solicitud }) => {
                const { nombre: nombre_estado_solicitud } = estado_solicitud
                const { nombre: nombre_tipo_solicitud } = tipo_solicitud
                const key = id // Id de la solicitud
                // console.log(key)
                const cell = [nombre_tipo_solicitud, nombre_estado_solicitud, fecha_aprobada]
                addRow(key, cell, setRows)
            })
            if (page > solicitud.last_page) {
                setPage(solicitud.last_page)
                history.push(`${window.location.pathname}?page=${solicitud.last_page}`)
            }
        }
        stopLoading()
    }, [history, page, dateRange, getSolicitudesByTipoAndFecha, tipoSolicitud])

    const getSolicitudes = useCallback(async () => {
        startLoading()
        // const idUsuario = Session.get('id')
        const userMe = await getMe()
        const {role_id} = userMe
        const solicitud = role_id === 7 ? await getSecretariaSolicitudes(page) : await getSolicitudsByStatus(page)
        // const solicitud = await getSolicitudsByStatus(page)
        if (solicitud.error) {
            setError(solicitud.error)
        }
        else {
            setRows([])
            setNumPages(solicitud.last_page)
            solicitud.data.forEach(({ id, tipo_solicitud, fecha_aprobada, estado_solicitud }) => {
                const { nombre: nombre_estado_solicitud } = estado_solicitud
                const { nombre: nombre_tipo_solicitud } = tipo_solicitud
                const key = id // Id de la solicitud
                // console.log(key)
                const cell = [nombre_tipo_solicitud, nombre_estado_solicitud, fecha_aprobada]
                addRow(key, cell, setRows)
            })
            if (page > solicitud.last_page) {
                setPage(solicitud.last_page)
                history.push(`${window.location.pathname}?page=${solicitud.last_page}`)
            }
        }
        stopLoading()
    }, [getMe, getSolicitudsByStatus, getSecretariaSolicitudes, history, page])

    //Metodos que redireccionan a las pantallas de chat
    const secretariaClienteChat = (id) => {
        history.push(`/proceso_solicitud/${id}/0`)
    }

    const secretariaCoordinadorChat = (id) => {
        history.push(`/proceso_solicitud/${id}/1`)
    }

    const botonesDataTable = (idRol) => {
        switch(idRol){
            case 2:
                return [
                    {
                        func: (key) =>
                            secretariaCoordinadorChat(key),
                        icon: BsFillChatFill,
                        tooltip: "Secretaria"
                    }
                ]
            case 3:
                return [
                    {
                        func: (key) =>
                            secretariaClienteChat(key),
                        icon: BsFillChatDotsFill,
                        tooltip: "Cliente"
                    },
                    {
                        func: (key) =>
                            secretariaCoordinadorChat(key),
                        icon: BsFillChatFill,
                        tooltip: "Coordinador"
                    }
                ]
            default: 
                return [
                    {
                        func: (key) =>
                            secretariaClienteChat(key),
                        icon: BsFillChatDotsFill,
                        tooltip: "Cliente"
                    }
                ]
        }
    }

    // const cargarTablaCombo = useCallback( async (tipoSolicitud) => {
    //     // Verifica si existen las variables
    //     if (tipoSolicitud) {
    //         // Para obtener las solicitudes por el tipo de solicitud seleccionada
    //         await getSolicitudesPorTipoSolicitudFecha()
    //     } else {
    //         // Para obtener todas las solicitudes al cargar la pagina por primera vez
    //         await getSolicitudes()
    //     }
    //     await getTiposDeSolicitudes()
    // }, [getSolicitudes, getSolicitudesPorTipoSolicitudFecha, getTiposDeSolicitudes])

    const data = {
        rows, tiposDeSolicitudes, tipoSolicitud, estado, fecha, 
        alertOpen, alertSeverity, alertMessage, loading, page, 
        numPages, value, idRol
    }

    const actions = {
        setTipoSolicitud, setEstado, setFecha, findRow, setAlertOpen,
        handleSelectDate, handlePageChange, secretariaClienteChat,
        secretariaCoordinadorChat, botonesDataTable
    }

    //useEffect para cargar datos en la tabla
    useEffect(() => {
        // Verifica si existen las variables
        if (tipoSolicitud) {
            // Para obtener las solicitudes por el tipo de solicitud seleccionada
            getSolicitudesPorTipoSolicitudFecha()
        } else {
            // Para obtener todas las solicitudes al cargar la pagina por primera vez
            getSolicitudes()
        }
    }, [getSolicitudes, getSolicitudesPorTipoSolicitudFecha, tipoSolicitud])


    //useEffect para los tipos de solicitud
    useEffect(() => {
        getTiposDeSolicitudes()
    }, [])

    return { error, data, actions }
}

export default useCotizacionPage