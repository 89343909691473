import React from 'react'
import AppFrame from '../../components/AppFrame'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import AppColors from '../../utils/colors'
import useProcesoSolicitudSecretariaClientePage from
        "../../hooks/cotizacion/useProcesoSolicitudSecretariaClientePage"
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chat from "../../components/Chat"
import FileInput from "../../components/FileInput"

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
        paddingRight: '1em',
        paddingLeft: '1em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
    },
    typography: {
        fontSize: 18,
    },
    titulo: {
        fontSize: 16,
    },
    typo: {
        color: AppColors.blue,
    },

    root: {
        '& .MuiTextField-root': {
            paddingTop: '3em',
            margin: theme.spacing(1),
            width: '100%',
        },
    },

}));

const hoy = new Date()
const datestring = ("0" + hoy.getDate()).slice(-2) + "/" + ("0" + (hoy.getMonth() + 1)).slice(-2) + "/" +
    hoy.getFullYear() + " " + ("0" + hoy.getHours()).slice(-2) + ":" + ("0" + hoy.getMinutes()).slice(-2) + ":" + ("0" + hoy.getSeconds()).slice(-2)


const ProcesoSolicitudSecretaria = () => {
    const classes = useStyles()
    const {data, actions} = useProcesoSolicitudSecretariaClientePage()
    const {
        msgInputValue, messages, loading, alertOpen,
        alertSeverity, alertMessage, idRol, tipoSolicitud, estadoTipoSolicitud,
        objArchivoUsuario, idEstadoSolicitud
    } = data
    const {
        handleSend, setMsgInputValue, setAlertOpen, handleOnChangeArchivoUsuario,
        sendFile, onChangeRadio, actualizarEstadoSolicitud
    } = actions

    return (
        <AppFrame loged={true}>
            <Progress open={loading}/>
            {
                //1 es el id del permiso gestion ministerio
                //useCheckPermiso(1) ?
                <Grid container spacing={1}>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} md={10}>
                            <Paper className={classes.paper}>
                                <Alert open={alertOpen} setOpen={setAlertOpen}
                                       severity={alertSeverity}
                                       message={alertMessage}/>

                                {/**/}
                                <Grid className={classes.input} container spacing={2} columns={16}>
                                    <Grid item xs={8}>
                                        <Grid justifyContent="flex-start"
                                              alignItems="center" item xs={12}>
                                            <Typography variant="h5">Proceso de Solicitud</Typography>
                                        </Grid>
                                        {idRol === 3 ? // 7 indica el rol de la secretaria
                                            <div>
                                                <Grid container direction="row" item xs={12}>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.typography}>Tipo
                                                            Solicitud:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography
                                                            className={classes.typography}>{tipoSolicitud}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction="row" item xs={12}>
                                                    <Grid item xs={2}>
                                                        <Typography className={classes.typography}>Estado:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography
                                                            className={classes.typography}>{estadoTipoSolicitud}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            : null
                                        }

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper width="50%">
                                            <Grid item xs={12}>
                                                <Typography className={classes.typography}
                                                            align="center">Fecha</Typography>
                                                <Typography className={classes.typo}
                                                            variant="h6" align="center"
                                                            value={hoy}>{datestring} </Typography>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>

                                {/*Codigo para el Chat*/}
                                <Grid className={classes.input} container spacing={2} columns={16}>
                                    <Grid item xs={10}>
                                        <Grid direction="row" item xs={12}>
                                            <Chat placeholder="Escribe tu mensaje" onSend={handleSend}
                                                  onChange={setMsgInputValue} value={msgInputValue}
                                                  messages={messages}/>
                                            <Grid container direction="row" alignItems="center" item xs={12}>
                                                <Grid item xs={4}>
                                                    <FileInput label="Agregar archivo (opcional)"
                                                               placeholder="Seleccione el archivo a subir"
                                                               onChange={handleOnChangeArchivoUsuario}
                                                               required={true}
                                                               files={objArchivoUsuario}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant="contained"
                                                        text={"Agregar archivo"}
                                                        func={sendFile}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2}>
                                        {idRol === 3 ? // 7 indica el rol de la secretaria
                                            <div>
                                                <Grid justifyContent="center">
                                                    <Typography className={classes.titulo}>Finalizar
                                                        Proceso</Typography>
                                                    <RadioGroup value={idEstadoSolicitud} onChange={onChangeRadio}>
                                                        <FormControlLabel value={4} control={<Radio color="primary"/>}
                                                                          label="Aprobado"/>
                                                        <FormControlLabel value={3} control={<Radio color="primary"/>}
                                                                          label="Inconclusa"/>
                                                        <FormControlLabel value={5} control={<Radio color="primary"/>}
                                                                          label="Denegada"/>
                                                    </RadioGroup>

                                                </Grid>
                                                <Grid item xs={3} sm={3} md={10}>
                                                    <Button
                                                        variant="contained"
                                                        text={"Guardar"}
                                                        func={actualizarEstadoSolicitud}
                                                    />
                                                </Grid>
                                            </div>
                                            : null}
                                    </Grid>

                                </Grid>

                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                // :
                // <PermisoDenegado/>
            }
        </AppFrame>
    )
}


export default ProcesoSolicitudSecretaria