import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import { IconContext } from 'react-icons'
import {GrFormClose, GrEdit} from 'react-icons/gr'
import { createTheme , ThemeProvider} 
    from '@material-ui/core/styles'
import { red, green } from '@material-ui/core/colors'
import Tooltip from '@material-ui/core/Tooltip'

const theme = createTheme({
  palette: {
    primary: green,
    secondary: red
  },
});

export const validTypes = [
    'edit',
    'delete'
]

const color = {
    edit: 'primary',
    delete: 'secondary'
}

const icons = {
    edit: GrEdit,
    delete: GrFormClose
}

const DataTableButton = ({type, func, idValue, customIcon, tooltip}) => {
    const Icon = customIcon ? customIcon : icons[type]
    return (    
        <ThemeProvider theme = {theme}>
            <Tooltip title={tooltip} arrow placement = "top"
                disableFocusListener = {tooltip ? false : true}
                disableHoverListener = {tooltip ? false : true}
                disableTouchListener = {tooltip ? false : true}
                >
                <IconButton variant="contained" color = {color[type]} aria-label="delete" onClick = {
                    () => {
                        func(idValue)
                    }
                    }>
                    <IconContext.Provider value = {{
                        size: '.7em',
                        color: 'black'
                    }} >
                        <Icon/>
                    </IconContext.Provider>
                </IconButton>
            </Tooltip> 
        </ThemeProvider>)
}

DataTableButton.propTypes = {
    type: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
}

export default DataTableButton
