import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import useRolService from "../../services/administracion/useRolService";
import { addRow, findRow, updateRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import {
  allLetterAndSpace,
  allNumbers,
  isDUI,
  isEmail,
  isPhone,
  letterNumbersAndSpace,
  letterNumbersSpaceForAddress,
  validPassword,
} from "../../utils/validations";
import { mensajeCamposNoValidos } from "../../utils/messages";
import Session from "react-session-api";
import { useCookies } from "react-cookie";
import { getNowInSeconds } from "../../utils/time";
import useLoginService from "../../services/sesion/useLoginService";
import usePersonalCCService from "../../services/controlDeCalidad/usePersonalService";

// import { getFilenameFromContentDisposition } from '../../utils/download'

const usePersonalccPage = () => {
  const {
    createPersonal,
    getAllPersonal,
    deletePersonal,
    getPersonalById,
    updatePersonal,
  } = usePersonalCCService();

  const ruta = `/personalcc/administrar`;
  const history = useHistory();
  useIsLoged();
  const { id } = useParams();
  //Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);
  //Estado para las filas de la tabla
  const [rows, setRows] = useState([]);

  //Estados para controlar los inputs
  //(errorInput y helperInput sirven para mostrar un error en el input)

  //Estado de cuantos registros se estan mostrando en la tabla
  const [registrosEnTabla, setRegistrosEnTabla] = useState(0);
  const [lastPagePagination, setLastPagePagination] = useState(0);

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  //Estados para dialogos confirmar actualizacion, creacion y eliminacion
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [personalToDelete, setPersonalToDelete] = useState(null);

  //Estado para progress, el setLoading siempre debe ser llamado en funciones
  //asincronas para funcionar correctamente,aqui nos quedamos
  const [loading, setLoading] = useState(0);

  //Cookies
  const [cookies, setCookie, removeCookie] = useCookies(["token", "rol", "id"]);

  //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  //Estado que indica la pagina actua
  const [page, setPage] = useState(queryParams.get("page"));
  //Estado que indica el numero de paginas en total
  const [numPages, setNumPages] = useState(0);

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorApellido, setErrorApellido] = useState(false);
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [helperNombre, setHelperNombre] = useState("");
  const [helperApellido, setHelperApellido] = useState("");
  const [helperCorreo, setHelperCorreo] = useState("");

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  //Controlador cambio de pagina
  const handlePageChange = (event, value) => {
    setPage(value);
    history.push(`${window.location.pathname}?page=${value}`);
  };

  //Controladores alerta
  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  //Controladores mensajes confirmacion
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenDelete = (id) => {
    setPersonalToDelete(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const startLoading = () => {
    setLoading((loading) => ++loading);
  };

  const stopLoading = () => {
    setLoading((loading) => --loading);
  };

  //Funciones para controlar cambios en inputs
  const onChangeNombre = (text) => {
    setNombre(text);
    if (!allLetterAndSpace(text)) {
      setErrorNombre(true);
      setHelperNombre("El nombre solo debe contener letras y espacios");
    } else {
      setErrorNombre(false);
      setHelperNombre("");
    }
  };

  const onChangeApellido = (text) => {
    setApellido(text);
    if (!allLetterAndSpace(text)) {
      setErrorApellido(true);
      setHelperApellido("El apellido solo debe contener letras y espacios");
    } else {
      setErrorApellido(false);
      setHelperApellido("");
    }
  };

  const onChangeCorreo = (text) => {
    setCorreo(text);
    if (!isEmail(text)) {
      setErrorCorreo(true);
      setHelperCorreo("El correo no es válido");
    } else {
      setErrorCorreo(false);
      setHelperCorreo("");
    }
  };

  const cancel = () => {
    setNombre("");
    setApellido("");
    setCorreo("");
  };

  const editClick = (id) => {
    history.push(`${ruta}/${id}?page=${page}`);
  };

  const editCancel = () => {
    cancel();
    history.push(`${ruta}?page=${page}`);
  };

  const checkForErrors = () => {
    if (
      nombre &&
      nombre !== "" &&
      apellido &&
      apellido !== "" &&
      correo &&
      correo !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getPersonal = useCallback(
    async (id) => {
      startLoading();
      const transporte = await getPersonalById(id);
      if (transporte.error) {
        handleOpenAlert("error", transporte.error);
      } else {
        const { nombre, apellido, correo } = transporte;

        setNombre(nombre);
        setApellido(apellido);
        setCorreo(correo);
      }
      stopLoading();
    },
    [getPersonalById]
  );

  const handleGetPersonal = useCallback(async () => {
    startLoading();
    const personal = await getAllPersonal();
    if (personal.error) {
      setErrorMessage(personal.error);
    } else {
      setRows([]);
      personal.forEach(({ id, name, apellido, correo }) => {
        const key = id;
        const cell = [name, apellido, correo];
        addRow(key, cell, setRows);
      });
    }
    stopLoading();
  }, [getAllPersonal, id]);

  const onCreatePersonal = async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const nuevoPersonal = {
      nombre,
      apellido,
      correo,
      disponible: true,
    };

    startLoading();
    const result = await createPersonal(nuevoPersonal);
    if (result.error) {
      setErrorMessage(result.error);
      handleOpenAlert("error", result.error);
    } else {
      const { nombre } = result;
      cancel();
      history.push(`${ruta}?page=${page}`);
      handleOpenAlert(
        "success",
        `"${nombre} ${apellido}" se creo correctamente.`
      );
      await handleGetPersonal();
    }
    stopLoading();
  };

  const onDeletePersonal = async () => {
    setOpenDelete(false);
    startLoading();
    const result = await deletePersonal(personalToDelete);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { nombre, apellido } = result;
      //deleteRow(id, setRows)
      await handleGetPersonal();
      cancel();
      handleOpenAlert(
        "success",
        `"${nombre} ${apellido}" se elimino correctamente.`
      );
    }
    stopLoading();
  };

  const onUpdatePersonal = async () => {
    setOpenEdit(false);
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const transporteAactualizar = {
      nombre,
      apellido,
      correo,
      disponible: true,
    };
    startLoading();
    const result = await updatePersonal(id, transporteAactualizar);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { nombre, apellido, correo } = result;
      const newCells = [nombre, apellido, correo];
      updateRow(id, newCells, setRows);
      await handleGetPersonal();
      handleOpenAlert(
        "success",
        `"${nombre} ${apellido}" se actualizo correctamente.`
      );
    }
    editCancel();
    stopLoading();
  };

  useEffect(() => {
    const loadData = async () => {
      await handleGetPersonal();
    };

    loadData();
  }, [handleGetPersonal]);

  useEffect(() => {
    if (id) {
      cancel();
      getPersonal(id);
    }
    //Solo se ejecuta cuando cambia el valor de id o getUsuario
  }, [id, getPersonal]);

  //Objeto con los datos del custom hook que se usaran en la pagina
  const data = {
    alertMessage,
    alertOpen,
    alertSeverity,
    apellido,
    correo,
    errorApellido,
    errorCorreo,
    errorMessage,
    errorNombre,
    helperApellido,
    helperCorreo,
    helperNombre,
    id,
    loading,
    nombre,
    numPages,
    openDelete,
    openEdit,
    page,
    personalToDelete,
    rows,
    setAlertOpen,
    setPersonalToDelete,
  };

  //Objeto con las acciones que se usaran en la pagina
  const actions = {
    handlePageChange,
    handleOpenAlert,
    handleClickOpenEdit,
    handleCloseEdit,
    handleClickOpenDelete,
    handleCloseDelete,
    onChangeNombre,
    onChangeApellido,
    onChangeCorreo,
    cancel,
    editCancel,
    editClick,
    onCreatePersonal,
    findRow,
    onDeletePersonal,
    onUpdatePersonal,
  };

  return { errorMessage, data, actions };
};

export default usePersonalccPage;
