import { useCallback, useEffect, useState } from "react";
// import useTecnicoPlanificacionService from "../../services/tecnicoPlanificacion"
// import useAreaService from "../../services/area"
// import useInstalacionService from "../../services/instalacion"
// import useInstitucionService from "../../services/instituciones"
// import {addRow} from "../../utils/rows"
import { useParams } from "react-router-dom";
import format from "date-fns/format";
import useIsLoged from "../utils/useIsLoged";
import { addRow } from "../../utils/rows";
import useCCPlanificacionService from "../../services/controlDeCalidad/useActividadesServiceCC";
import useTransporteService from "../../services/controlDeCalidad/useTransporteService";
import useClienteServiceCC from "../../services/controlDeCalidad/useClienteServiceCC";
import { letterNumbersSpaceForAddress } from "../../utils/validations";
import usePersonalCCService from "../../services/controlDeCalidad/usePersonalService";
import { mensajeCamposNoValidos } from "../../utils/messages";
import { checkGridRowIdIsValid } from "@material-ui/data-grid";

const usePlanificacionCCPage = () => {
  useIsLoged();

  const {
    getActividadesByDate,
    getAllActividades,
    getStatusFromActividades,
    createActividad,
    getActividadById,
    deleteActividad,
    updateActividad,
  } = useCCPlanificacionService();

  const { getAllTransporte } = useTransporteService();

  const { getAllPersonal } = usePersonalCCService();

  const { getClientesWithoutPagination, getClienteByIdNew } =
    useClienteServiceCC();

  // Para almacenar el id de un evento a editar
  const [idEventoEditar, setIdEventoEditar] = useState();

  //Estado que almacena todos los eventos
  const [allEvents, setAllEvents] = useState([]);

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  //Estados para dialogos consultar, aditar, creacion y eliminacion
  const [openResearch, setOpenResearch] = useState(false);
  const [show, setShow] = useState(false);
  const [idEventoConsultar, setIdEventoConsultar] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [eventoToDelete, setEventoToDelete] = useState(null);
  const [loading, setLoading] = useState(0);
  //Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);

  const [nombrePersonaRepresentante, setNombrePersonaRepresentante] =
    useState("");

  const [observacion, setObservacion] = useState("");

  // Para la capturar la fecha de inicio y fecha de fin del evento
  const [fechaInicioEvento, setFechaInicioEvento] = useState("");
  const [fechaEvento, setFechaEvento] = useState("");

  const { id } = useParams();
  const [error, setError] = useState(null);
  const [rows, setRows] = useState([]);

  //Hooks que tendran los id y nombres seleccionados
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedTransporte, setSelectedTransporte] = useState({});
  const [selectedInstituciones, setSelectedInstituciones] = useState({});

  const [brazoC, setBrazoC] = useState(0);
  const [convencionalCflouro, setConvencionalCflouro] = useState(0);
  const [convencionalFijo, setConvencionalFijo] = useState(0);
  const [convencionalMovil, setConvencionalMovil] = useState(0);
  const [dentalPanoramico, setDentalPanoramico] = useState(0);
  const [dentalPeriapical, setDentalPeriapical] = useState(0);
  const [departamento, setDepartamento] = useState("");
  const [detalleDireccion, setDetalleDireccion] = useState("");
  const [errorTipo, setErrorTipo] = useState(false);
  const [fechaSolicitado, setFechaSolicitado] = useState("");
  const [helperTipo, setHelperTipo] = useState(false);
  const [institucion, setInstitucion] = useState(1);
  const [mamografico, setMamografico] = useState(0);
  const [municipio, setMunicipio] = useState("");
  const [personalList, setPersonalList] = useState([]);
  const [personalListToUpdate, setPersonalListToUpdate] = useState([]);
  const [status, setStatus] = useState(1);
  const [tipos, setTipos] = useState({});
  const [tiposDiferentesDeCero, setTiposDiferentesDeCero] = useState({});
  const [tiposString, setTiposString] = useState("");
  const [todosLosTipos, setTodosLosTipos] = useState({});
  const [tomografiaComputarizada, setTomografiaComputarizada] = useState(0);
  const [valueTipos, setValueTipos] = useState({});

  const [tiposDeInstrumentos, setTiposDeInstrumentos] = useState({});
  const [tiposAModificar, setTiposAModificar] = useState({});

  const [transporte, setTransporte] = useState(1);
  const [personal, setPersonal] = useState([]);
  const [comentario, setComentario] = useState("");
  const [errorComentario, setErrorComentario] = useState("");
  const [helperComentario, setHelperComentario] = useState("");
  const [nombreActividad, setNombreActividad] = useState("");
  const [errorNombreActividad, setErrorNombreActividad] = useState("");
  const [helperNombreActividad, setHelperNombreActividad] = useState("");
  const [horaInicio, setHoraInicio] = useState("08:00");
  const [horaFin, setHoraFin] = useState("12:00");
  const [horario, setHorario] = useState({
    horaInicio: "08:00",
    horaFin: "12:00",
  });
  const [nombreHorario, setNombreHorario] = useState("Mañana");

  const onChangeComentario = (text) => {
    setComentario(text);
    // if (!letterNumbersSpaceForAddress(text)) {
    //   setErrorComentario(true);
    //   setHelperComentario(
    //     "La dirección solo debe contener numeros,letras y los caracteres: # - / ,  . ª ° "
    //   );
    // } else {
    //   setErrorComentario(false);
    //   setHelperComentario("");
    // }
  };
  const onChangeNombreActividad = (text) => {
    setNombreActividad(text);
    // if (!letterNumbersSpaceForAddress(text)) {
    //   setErrorComentario(true);
    //   setHelperComentario(
    //     "La dirección solo debe contener numeros,letras y los caracteres: # - / ,  . ª ° "
    //   );
    // } else {
    //   setErrorComentario(false);
    //   setHelperComentario("");
    // }
  };

  const cancel = () => {
    setBrazoC(0);
    setComentario("");
    setConvencionalCflouro(0);
    setConvencionalFijo(0);
    setConvencionalMovil(0);
    setDentalPanoramico(0);
    setDentalPeriapical(0);
    setDepartamento("");
    setDetalleDireccion("");
    setErrorTipo(false);
    setFechaSolicitado("");
    setHelperTipo(false);
    setHoraFin("12:00");
    setHoraInicio("08:00");
    setHorario({
      horaInicio: "08:00",
      horaFin: "12:00",
    });
    setNombrePersonaRepresentante("");
    setObservacion("");
    setInstitucion(1);
    setMamografico(0);
    setMunicipio("");
    setNombreActividad("");
    setPersonalListToUpdate([]);
    setSelectedInstituciones(null);
    setSelectedStatus(null);
    setSelectedTransporte(null);
    setStatus(1);
    setTipos({});
    setTiposDiferentesDeCero({});
    setTiposString("");
    setTodosLosTipos({});
    setTomografiaComputarizada(0);
  };

  // Controlador que abre el modal de editar un evento
  const handleClickOpenEdit = (id) => {
    setIdEventoEditar(id);
    setShow(false);
    cancel();
    const exec = async () => {
      startLoading();
      await loadValoresActividades(id);
      stopLoading();
    };

    exec();
    setOpenEdit(true);
    //loadValoresActividades(id);
  };
  // Controlador que cierra el modal de editar un evento
  const handleClickCloseEdit = () => {
    cancel();
    setShow(false);
    setOpenEdit(false);
    setValueTipos({});
    setIdEventoEditar(null);
  };

  // Controlador que abre el modal de eliminar un evento
  const handleClickOpenDelete = (id) => {
    setEventoToDelete(id);
    setOpenDelete(true);
  };

  // Controlador que cierra el modal de eliminar un evento
  const handleClickCloseDelete = () => {
    setOpenDelete(false);
  };

  // Controlador que abre el modal para crear un evento
  const handleClickOpenCreate = () => {
    cancel();
    setShow(false);
    setOpenCreate(true);
  };

  // Controlador que cierra el modal para crear un evento
  const handleClickCloseCreate = () => {
    setOpenCreate(false);
    cancel();
  };

  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  // Para indicar cuando se está consumiendo la API
  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const handleClickOpenResearch = () => {
    setOpenResearch(!openResearch);
    // Si consultar evento está abierto, lo cierra
    if (show === true) {
      setShow(false);
    }
  };

  // Funcion que almacena el id del estado seleccionado
  const handleChangeEstado = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedStatus(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedStatus(value);
    }
  };

  const handleChangeTransporte = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedTransporte(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedTransporte(value);
    }
  };

  const handleChangeInstituciones = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedInstituciones(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedInstituciones(value);
    }
  };

  // Funcion para editar un evento
  const onUpdateActividad = async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }
    const actividadAActualizar = {
      estado: selectedStatus.value,
      institucion: selectedInstituciones.value,
      fecha_solicitado: fechaEvento,
      tipo: {
        ...todosLosTipos,
        ...tiposAModificar,
      },
      transporte: selectedTransporte.value,
      personal_asignado: personal.map((persona) => persona.id),
      comentarios: comentario,
      horario: {
        hora_inicio:
          horario.horaInicio.split(":") > 2
            ? horario.horaInicio
            : horario.horaInicio.split(":").slice(0, 2).join(":"),
        hora_fin:
          horario.horaFin.split(":") > 2
            ? horario.horaFin
            : horario.horaFin.split(":").slice(0, 2).join(":"),
      },
      nombre: nombreActividad,
    };
    startLoading();
    const result = await updateActividad(idEventoEditar, actividadAActualizar);
    if (result.error) {
      setErrorMessage(result.data.error);
      handleOpenAlert("error", result.error);
    } else {
      const { nombre_actividad } = result;
      handleOpenAlert(
        "success",
        `La actividad "${nombre_actividad}" se actualizó correctamente.`
      );
      await getEventos();
      await getEventosByDate(fechaEvento);
      setOpenEdit(false);
      handleClickCloseEdit();
    }
    stopLoading();
  };

  const TodosLosValoresDelObjetoSonCero = (objeto) => {
    for (const key in objeto) {
      if (objeto[key] !== 0) {
        return false; // Devuelve falso si al menos un valor es diferente de cero.
      }
    }
    return true; // Devuelve false si todos los valores son cero.
  };

  const checkForErrors = () => {
    return !(
      nombreActividad !== "" &&
      selectedStatus &&
      Object.keys(selectedStatus).length !== 0 &&
      selectedInstituciones &&
      Object.keys(selectedInstituciones).length !== 0 &&
      tiposAModificar &&
      Object.keys(tiposAModificar).length !== 0 &&
      !TodosLosValoresDelObjetoSonCero(tiposAModificar) &&
      selectedTransporte &&
      Object.keys(selectedTransporte).length !== 0 &&
      personal &&
      Object.keys(personal).length !== 0 &&
      horario &&
      Object.keys(horario).length !== 0
    );
  };

  const onCreateActividad = async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const nuevaActividad = {
      estado: selectedStatus?.value,
      institucion: selectedInstituciones?.value,
      fecha_solicitado: fechaEvento,
      tipo: {
        ...todosLosTipos,
        ...tiposAModificar,
      },
      transporte: selectedTransporte?.value,
      personal_asignado: personal?.map((persona) => persona.id),
      comentarios: comentario,
      horario: {
        hora_inicio: horario.horaInicio,
        hora_fin: horario.horaFin,
      },
      nombre: nombreActividad,
    };

    // handleOpenAlert("success", `Se ha dado click al crear`);

    startLoading();

    const result = await createActividad(nuevaActividad);

    if (result.error) {
      setErrorMessage(result.data.error);
      handleOpenAlert("error", result.error);
    } else {
      handleOpenAlert("success", `Se ha creado la actividad correctamente.`);
      handleClickCloseCreate();
      await getEventosByDate(fechaEvento);
      await getEventos();
      cancel();
    }
    stopLoading();
  };

  // Funcion que filtra los datos de acuerdo a un valor escrito
  const dataFiltrada = useCallback(async (data, value) => {
    // Devuelve los datos filtrados por el nombre
    const results = data.filter((resp) =>
      resp.nombre.toLowerCase().includes(value)
    );
    let options = [];
    results.forEach(({ id, nombre }) => {
      const value = id;
      const label = nombre;
      const resultado = {
        label,
        value: value.toString(),
      };
      options = [...options, resultado];
    });
    // console.log(options)
    return options;
  }, []);

  // const loadActividadData = useCallback(
  //   async (idInstitucion) => {
  //     // Devuelve los datos filtrados por el nombre
  //     const response = await getActividadById();
  //     console.log(response);
  //   },
  //   [getActividadById]
  // );

  // Funcion que carga los estados
  const loadEstados = useCallback(
    async (inputValue) => {
      const { data: response } = await getStatusFromActividades();
      //   const response = [{ id: 1, nombre: "juancho" }]
      if (inputValue) {
        // Verifica si hay un valor ingresado
        return dataFiltrada(response, inputValue);
      } else {
        let options = [];
        response?.forEach(({ id, nombre }) => {
          const value = id;
          const label = nombre;
          const resultado = {
            label,
            value: value.toString(),
          };
          options = [...options, resultado];
        });
        // console.log(options)
        return options;
      }
    },
    [getStatusFromActividades]
  );

  const loadTransporte = useCallback(
    async (inputValue) => {
      const response = await getAllTransporte();
      //   const response = [{ id: 1, nombre: "juancho" }]
      if (inputValue) {
        // Verifica si hay un valor ingresado
        return dataFiltrada(response, inputValue);
      } else {
        let options = [];
        const responsevalidada = Array.isArray(response) ? response : [];
        responsevalidada?.forEach(({ id, nombre }) => {
          const value = id;
          const label = nombre;
          const resultado = {
            label,
            value: value.toString(),
          };
          options = [...options, resultado];
        });
        // console.log(options)
        return options;
      }
    },
    [getAllTransporte]
  );

  const loadInstituciones = useCallback(
    async (inputValue) => {
      //   const response = await getAllTransporte();
      const { data: response } = await getClientesWithoutPagination();

      if (inputValue) {
        // Verifica si hay un valor ingresado
        return dataFiltrada(response, inputValue);
      } else {
        let options = [];
        response?.forEach(({ id, nombre }) => {
          const value = id;
          const label = nombre;
          const resultado = {
            label,
            value: value.toString(),
          };
          options = [...options, resultado];
        });
        // console.log(options)
        return options;
      }
    },
    [getClientesWithoutPagination]
  );

  const loadValoresInstituciones = useCallback(async (id) => {
    try {
      const {
        data: {
          departamento,
          municipio,
          detalle_direccion,
          tipo,
          observacion,
          nombreRepresentante,
        },
      } = await getClienteByIdNew(id);

      const {
        convencionalMovil,
        convencionalFijo,
        convencionalCflouro,
        brazoC,
        mamografico,
        dentalPeriapical,
        dentalPanoramico,
        tomografiaComputarizada,
      } = tipo;

      setTodosLosTipos(tipo);

      //TODO cambiar a tipo

      setNombrePersonaRepresentante(nombreRepresentante);
      setObservacion(observacion);
      setMunicipio(municipio.nombre);
      setDepartamento(departamento.nombre);
      setDetalleDireccion(detalle_direccion);

      setConvencionalMovil(convencionalMovil);
      setConvencionalFijo(convencionalFijo);
      setConvencionalCflouro(convencionalCflouro);
      setBrazoC(brazoC);
      setMamografico(mamografico);
      setDentalPeriapical(dentalPeriapical);
      setDentalPanoramico(dentalPanoramico);
      setTomografiaComputarizada(tomografiaComputarizada);

      return tipo;
    } catch (error) {
      console.error("Error al cargar los valores de las instituciones:", error);
    }
  });

  const loadValoresActividades = useCallback(async (id) => {
    try {
      const { data: actividadArray } = await getActividadById(id);

      const {
        estado,
        institucion,
        fecha_solicitado,
        departamento,
        municipio,
        detalle_direccion,
        tipo,
        transporte,
        personal,
        comentarios,
        horario,
        nombre_actividad,
        observacion,
        nombreRepresentante,
      } = actividadArray[0];

      setStatus(estado.estado);
      setSelectedStatus({
        value: estado.id,
        label: estado.estado,
      });
      setSelectedInstituciones({
        value: institucion.id,
        label: institucion.nombre,
      });

      setSelectedTransporte({
        value: transporte.id,
        label: transporte.placa,
      });

      setInstitucion(institucion.nombre);
      setFechaSolicitado(fecha_solicitado);
      setTransporte(transporte.placa);
      const personalString = personal.map(
        ({ nombres, apellidos }) => `${nombres} ${apellidos}`
      );
      setPersonal(personalString);
      setPersonalListToUpdate(
        personal.map((persona) => ({
          ...persona,
          name: `${persona.nombres} ${persona.apellidos}`,
        }))
      );
      setComentario(comentarios);
      const horaInicioRecortada = horario.hora_inicio
        .split(":")
        .slice(0, 2)
        .join(":");
      const horaFinRecortada = horario.hora_fin
        .split(":")
        .slice(0, 2)
        .join(":");

      setHoraInicio(horaInicioRecortada);
      setHoraFin(horaFinRecortada);
      //setHorario(`${horario.hora_inicio} - ${horario.hora_fin}`);
      setHorario({
        horaInicio: horaInicioRecortada,
        horaFin: horaFinRecortada,
      });

      setNombreActividad(nombre_actividad);

      setNombrePersonaRepresentante(nombreRepresentante);
      setObservacion(observacion);

      const {
        convencionalMovil,
        convencionalFijo,
        convencionalCflouro,
        brazoC,
        mamografico,
        dentalPeriapical,
        dentalPanoramico,
        tomografiaComputarizada,
      } = tipo;

      setValueTipos(tipo);
      setTodosLosTipos(tipo);

      setMunicipio(municipio.nombre);
      setDepartamento(departamento.nombre);
      setDetalleDireccion(detalle_direccion);
      setConvencionalMovil(convencionalMovil);
      setConvencionalFijo(convencionalFijo);
      setConvencionalCflouro(convencionalCflouro);
      setBrazoC(brazoC);
      setMamografico(mamografico);
      setDentalPeriapical(dentalPeriapical);
      setDentalPanoramico(dentalPanoramico);
      setTomografiaComputarizada(tomografiaComputarizada);

      const valoresMayoresA0 = (objeto) => {
        let mensaje = "";

        for (const clave in objeto) {
          const valor = objeto[clave];
          if (valor > 0) {
            mensaje += `${clave}: ${valor} \n`;
          }
        }
        return mensaje; // Elimina el espacio en blanco al final del mensaje
      };

      const mensajeResumenTipos = valoresMayoresA0(tipo[0]);

      setTiposString(mensajeResumenTipos);
    } catch (error) {
      console.error("Error al cargar los valores de las actividades:", error);
    }
  });

  useEffect(() => {
    console.log({ horarioSegunUseEffect: horario });
  }, [horario]);

  // Funcion para consular los datos de un evento
  const showMore = (key) => {
    setShow(!show);
    setIdEventoConsultar(key);

    if (!show) {
      const exec = async () => {
        await loadValoresActividades(key);
      };

      exec();
    }
  };

  // Funcion que obtiene todos los eventos
  const getEventos = async () => {
    startLoading();
    // const eventos = await getEventosByStatus(estado)
    const { data: eventos } = await getAllActividades();
    if (eventos.error) {
      setError(eventos.error);
    } else {
      //setNewEvent({ id: "", title: "", start: "", end: "" })
      setAllEvents([]);
      eventos.forEach((evento) => {
        const fecha_asignada = new Date(evento.fecha_solicitado);
        fecha_asignada.setDate(fecha_asignada.getDate() + 1);
        const newEvent = {
          id: evento.id,
          title: evento.nombre_actividad,
          start: fecha_asignada,
          end: fecha_asignada,
        };
        setAllEvents((allEvents) => [...allEvents, newEvent]);
      });
    }
    stopLoading();
  };

  // Funcion que obtiene los eventos por fecha
  const getEventosByDate = async (date) => {
    const { data } = await getActividadesByDate(date);

    const eventos = Array.isArray(data) ? data : [];

    if (eventos.error) {
      setError(eventos.error);
    } else {
      setRows([]);
      eventos.forEach(({ id, nombre_actividad, estado: { estado } }) => {
        const key = id;
        const cell = [nombre_actividad, estado];
        addRow(key, cell, setRows);
      });
    }
  };

  // Funcion para eliminar un evento
  const onDeleteEvento = async () => {
    setOpenDelete(false);
    startLoading();
    const result = await deleteActividad(eventoToDelete);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const {
        data: { nombre },
      } = result;
      await getEventos();
      await getEventosByDate(fechaEvento);
      cancel();
      handleOpenAlert(
        "success",
        `La actividad "${nombre}" se eliminó correctamente.`
      );
    }
    stopLoading();
  };

  // Funcion que se ejecuta al seleccionar una fecha del calendario
  const handleSelect = async ({ start }) => {
    // Opciones para el formato de fecha del modal
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    // console.log("Abriendo modal", start.toLocaleDateString("es", options))
    //Cuando usas un setState, el estado va a cambiar hasta despues de hacer un rerenderizacion
    setFechaInicioEvento(start.toLocaleDateString("es", options));
    setFechaEvento(format(start, "yyyy-MM-dd"));
    //Por en este console.log el estado de fechaEvento va a ser el anterior a la rerenderizacion
    // console.log("Fecha evento 1 ", fechaEvento)
    //await getEventosByDate(fechaEvento)
    //Siempre pone un await en las funciones que usan cosas asincronas, asi el
    //modal se va a abrir hasta que ya haya respuesto el servidor con los datos
    //y no mostrar el modal vacio
    await getEventosByDate(format(start, "yyyy-MM-dd"));
    handleClickOpenResearch(); //Abriendo modal de consultar
  };

  useEffect(async () => {
    await getEventos();
    // console.log("Fecha evento ", fechaEvento)
  }, [rows, fechaEvento]);

  // Para mostrar las instituciones si se ha abierto el modal de crear o editar
  useEffect(() => {
    if (openCreate === true || openEdit === true) {
      // loadInstituciones()
    }
  }, [openCreate, openEdit]);

  // Para mostrar los estados si se ha abierto el modal de crear o editar
  useEffect(() => {
    // if (openCreate === true || openEdit === true) {
    if (openCreate === true) {
      loadEstados();
      loadTransporte();
      loadInstituciones();
    }
  }, [openCreate, openEdit]);

  useEffect(() => {
    const fetchData = async () => {
      const personal = await getAllPersonal();
      const personalConNameModificado = personal.map((persona) => ({
        ...persona,
        name: `${persona.name} ${persona.apellido}`,
      }));
      setPersonalList(personalConNameModificado);
    };

    fetchData();
  }, []);

  const obtenerTiposMayoresACero = (objeto) => {
    const resultado = {};

    for (const tipo in objeto) {
      if (objeto[tipo] > 0) {
        resultado[tipo] = objeto[tipo];
      }
    }

    return resultado;
  };

  // useEffect(async () => {
  //   if (selectedInstituciones?.value) {

  //     const idInstitucion = selectedInstituciones.value;
  //     const tipos = await loadValoresInstituciones(idInstitucion);
  //     // const tiposFiltrados = obtenerTiposMayoresACero(tiposDeInstrumentos);
  //     const tiposFiltrados = obtenerTiposMayoresACero(tipos);

  //     // Aquí borramos el contenido de tiposDiferentesDeCero
  //     setTiposDiferentesDeCero({});

  //     // Luego, actualizamos con los nuevos valores
  //     setTiposDiferentesDeCero(tiposFiltrados);
  //   }
  //   // Puedes realizar otras operaciones asíncronas aquí si es necesario
  // }, [selectedInstituciones]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedInstituciones?.value) {
        const idInstitucion = selectedInstituciones.value;
        const tipos = await loadValoresInstituciones(idInstitucion);
        const tiposFiltrados = obtenerTiposMayoresACero(tipos);

        // Aquí borramos el contenido de tiposDiferentesDeCero
        setTiposDiferentesDeCero({});
        //setValueTipos({});

        // Luego, actualizamos con los nuevos valores
        setTiposDiferentesDeCero(tiposFiltrados);
      } else {
        setTiposDiferentesDeCero({});
        setValueTipos({});
      }
      // Puedes realizar otras operaciones asíncronas aquí si es necesario
    };

    fetchData();
  }, [selectedInstituciones]);

  const determinarPeriodoDelDia = (horas) => {
    if (!horas || !horas.horaInicio || !horas.horaFin) {
      // Manejo de error o valor por defecto si no se proporcionan las horas
      return "Mañana";
    }
    const horaInicioParts = horas.horaInicio.split(":");
    const horaFinParts = horas.horaFin.split(":");

    const horaInicio = new Date(
      0,
      0,
      0,
      horaInicioParts[0],
      horaInicioParts[1]
    );
    const horaFin = new Date(0, 0, 0, horaFinParts[0], horaFinParts[1]);

    const horaLimiteManana = new Date(0, 0, 0, 12, 59); // 12:59
    const horaLimiteTarde = new Date(0, 0, 0, 13, 0); // 13:00

    if (horaInicio <= horaLimiteManana && horaFin <= horaLimiteManana) {
      return "Mañana";
    } else if (horaInicio >= horaLimiteTarde) {
      return "Tarde";
    } else {
      return "Todo el día";
    }
  };

  useEffect(() => {
    setNombreHorario(determinarPeriodoDelDia(horario));
  }, [horario]);

  const data = {
    // selectedStatus,
    // selectedTransporte,
    alertMessage,
    alertOpen,
    alertSeverity,
    allEvents,
    brazoC,
    comentario,
    convencionalCflouro,
    convencionalFijo,
    convencionalMovil,
    dentalPanoramico,
    dentalPeriapical,
    departamento,
    detalleDireccion,
    errorComentario,
    errorNombreActividad,
    errorTipo,
    eventoToDelete,
    fechaInicioEvento,
    fechaSolicitado,
    helperComentario,
    helperNombreActividad,
    helperTipo,
    horaFin,
    horaInicio,
    horario,
    idEventoEditar,
    institucion,
    loading,
    mamografico,
    municipio,
    nombreActividad,
    nombreHorario,
    openCreate,
    openDelete,
    openEdit,
    openResearch,
    personal,
    personalList,
    rows,
    selectedInstituciones,
    selectedStatus,
    selectedTransporte,
    setComentario,
    setErrorTipo,
    setHelperTipo,
    setHorario,
    setPersonal,
    setSelectedInstituciones,
    setTipos,
    setTiposAModificar,
    show,
    status,
    tipos,
    tiposAModificar,
    tiposDiferentesDeCero,
    tiposString,
    tomografiaComputarizada,
    transporte,
    valueTipos,
    personalListToUpdate,
    observacion,
    nombrePersonaRepresentante,
  };

  const actions = {
    setPersonalListToUpdate,
    onUpdateActividad,
    handleClickCloseEdit,
    handleClickOpenEdit,
    onDeleteEvento,
    handleChangeEstado,
    handleChangeInstituciones,
    handleChangeTransporte,
    handleClickCloseCreate,
    handleClickCloseDelete,
    handleClickOpenCreate,
    handleClickOpenDelete,
    handleClickOpenResearch,
    handleOpenAlert,
    handleSelect,
    loadEstados,
    loadInstituciones,
    loadTransporte,
    onChangeComentario,
    onChangeNombreActividad,
    onCreateActividad,
    setAlertOpen,
    showMore,
    startLoading,
    stopLoading,
  };
  return { data, actions };
};

export default usePlanificacionCCPage;
