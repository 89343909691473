import React from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Grid from "@material-ui/core/Grid";
import FormDivider from "../../components/FormDivider";
import Dialog from "../../components/Dialog/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListSubheader, Collapse, ListItem } from "@material-ui/core";
// import TecnicoPlanificacionCrearEditar from "./TecnicoPlanificacionCrearEditar"
import ItemList from "../../components/ItemList";
import { findRow } from "../../utils/rows";
import ActividadCCEditarCrear from "./actividadCCEditarCrear";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
    //marginBottom: ".5em",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const ActividadCCConsultar = (props) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justifyContent="center">
      <FormDivider />
      <Grid container direction="row" spacing={2}>
        <Grid className={classes.input} justifyContent="flex-start" item xs={6}>
          <p>
            <strong>Actividades</strong>
          </p>
        </Grid>
        {/* Boton de nuevo evento */}
        <Grid className={classes.input} justifyContent="flex-end" item xs={6}>
          <Button
            func={props.actions.handleClickOpenCreate}
            variant="contained"
            text="Nueva actividad"
          />
        </Grid>
        {/* Fin Boton de nuevo evento */}
      </Grid>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <strong>Planeados</strong>
          </ListSubheader>
        }
      >
        <ItemList
          rows={props.data.rows}
          consultFunc={(key) => props.actions.showMore(key)}
          editFnc={(key) => props.actions.handleClickOpenEdit(key)}
          // editFnc={(key) => console.log('Esta es la key a editar: ' + key)}
          deleteFnc={(key) => props.actions.handleClickOpenDelete(key)}
          //deleteFnc={(key) => console.log(props.actions)}
        />
        {/** AQUI TIENE QUE IR EL SIGUIENTE COLLAPSE PARA VER EL DETALLE */}
        <Collapse in={props.data.show} timeout="auto">
          <FormDivider text="Detalles" />
          <List component="div" disablePadding>
            <ListItem className={classes.nested}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                 {/* Nombre de Actividad */}
                 <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Nombre de Actividad"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.nombreActividad}
                  />
                </Grid>

                {/* Institucion */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Institución"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.institucion}
                  />
                </Grid>

                {/* Equipo a evaluar */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Equipo a evaluar"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.tiposString}
                  />
                </Grid>

                {/* Estado */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Estado"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.status}
                  />
                </Grid>

                {/* Fecha solicitada */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Fecha solicitada"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.fechaSolicitado}
                  />
                </Grid>

                {/* Departamento */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Departamento"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.departamento}
                  />
                </Grid>

                {/* Municipio */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Municipio"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.municipio}
                  />
                </Grid>

                {/* Detalle de direccion */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Detalle de direccion"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.detalleDireccion}
                  />
                </Grid>

                {/* Transporte */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Transporte"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.transporte}
                  />
                </Grid>

                {/* Personal asignado */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Personal asignado"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.personal}
                  />
                </Grid>

                {/* Comentarios */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Comentario"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.comentario}
                  />
                </Grid>

                {/* Horario */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Horario"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={`${props.data.horario.horaInicio} - ${props.data.horario.horaFin}`}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Collapse>
      </List>
      {/* MODAL PARA CREAR/EDITAR */}
      <Dialog
        open={
          props.data.idEventoEditar
            ? props.data.openEdit
            : props.data.openCreate
        }
        handleClose={
          props.data.idEventoEditar
            ? props.actions.handleClickCloseEdit
            : props.actions.handleClickCloseCreate
        }
        handleAccept={
          props.data.idEventoEditar
            ? props.actions.onUpdateActividad
            : props.actions.onCreateActividad
        }
        title={
          props.data.idEventoEditar
            ? `Editar actividad para ${props.data.fechaInicioEvento}`
            : `Nueva actividad para ${props.data.fechaInicioEvento}`
        }
        content={
          <ActividadCCEditarCrear data={props.data} actions={props.actions} />
        }
      />
      {/* MODAL PARA ELIMINAR */}
      <Dialog
        open={props.data.openDelete}
        handleAccept={props.actions.onDeleteEvento}
        handleClose={props.actions.handleClickCloseDelete}
        title={"Eliminación de la actividad"}
        content={
          findRow(props.data.eventoToDelete, props.data.rows)
            ? `El evento con nombre "${
                findRow(props.data.eventoToDelete, props.data.rows).cells[0]
              }" sera eliminado del sistema.`
            : ""
        }
      />
    </Grid>
  );
};

export default ActividadCCConsultar;
