import axios from "axios";
import { useCallback } from "react";
// import headers from "../utils/headers";
import url from "../utils/url";
import useErrorHandler from "../utils/useErrorHandler";
import headers from "../utils/headers";

const pasoUrl = `${url}pasosca`;

const usePasoSolicitudesRecibidasService = () => {
  const { errorHandler } = useErrorHandler();

  const createPaso = useCallback(
    async (paso) => {
      try {
        const response = await axios.post(pasoUrl, paso, {
          headers: headers(),
        });

        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getPasoById = useCallback(
    async (idPaso) => {
      const requestUrl = `${pasoUrl}/${idPaso}`;
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const cambiarNombreAName = (listaDeObjetos) => {
    const nuevaLista = listaDeObjetos.map((objeto) => {
      // Clonamos el objeto para evitar modificar el original
      const nuevoObjeto = { ...objeto };

      // Cambiamos la variable 'nombre' a 'name'
      if (nuevoObjeto.hasOwnProperty("nombre")) {
        nuevoObjeto.name = nuevoObjeto.nombre;
        delete nuevoObjeto.nombre;
      }

      return nuevoObjeto;
    });

    return nuevaLista;
  };

  const deletePaso = useCallback(
    async (idPaso) => {
      const requestUrl = `${pasoUrl}/${idPaso}`;
      try {
        const response = await axios.delete(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getAllPasos = useCallback(async () => {
    const requestUrl = `${pasoUrl}`;
    try {
      const response = await axios.get(requestUrl, { headers: headers() });
      const { data } = response;
      const { data: dataInterna } = data;
      return cambiarNombreAName(dataInterna);
      //   return cambiarNombreAName([{id: 1, nombre: "juancho"}]);
    } catch (error) {
      return errorHandler(error);
    }
  }, [errorHandler]);

  const updatePaso = useCallback(
    async (idPaso, cambios) => {
      const requestUrl = `${pasoUrl}/${idPaso}`;
      console.log({
        id: "chanchona",
        requestUrl,
        cambios,
      });
      try {
        const response = await axios.put(requestUrl, cambios, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    getPasoById,
    getAllPasos,
    createPaso,
    deletePaso,
    updatePaso,
  };
};

export default usePasoSolicitudesRecibidasService;
