import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { addRow, findRow, updateRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
import { allLetterAndSpace, isPhone } from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";

const useDatosAreasPage = () => {
    const history = useHistory()
    const { createArea, deleteArea, getAreaById, getAreasByInstalacion,
        getInstalacionesPorNombre, updateArea, createAreaEdit,
        updateAreaEdit, updateStatusArea } = useSolicitudInscripcionService()
    const { comprobarEstadoEdicionSolicitud } = useSolicitudCotizacionService()
    useIsLoged()
    const {id} = useParams()
    //Estado para las filas de la tabla
    const [rows, setRows] = useState([])
    //Estados para controlar los inputs
    //(errorInput y helperInput sirven para mostrar un error en el input)
    //Estados area
    const [nombre, setNombre] = useState('')
    const [errorNombre, setErrorNombre] = useState(false)
    const [helperNombre, setHelperNombre] = useState('')
    //Trimestre semestre
    const [periodoControl, setPeriodoControl] = useState("")
    const [telefono, setTelefono] = useState('')
    const [errorTelefono, setErrorTelefono] = useState(false)
    const [helperTelefono, setHelperTelefono] = useState('')
    const [celular, setCelular] = useState('')
    const [errorCelular, setErrorCelular] = useState(false)
    const [helperCelular, setHelperCelular] = useState('')
    const [instalacion, setInstalacion] = useState(null)
    // Estado que almacena un valor booleano y servira como variable de control para
    // el proceso de edicion
    const [idSolicitud, setIdSolicitud] = useState(false)
    // Almacena el booleano de aprobado, como control para el proceso de edicion
    const [aprobado, setAprobado] = useState('')

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [areaToDelete, setAreaToDelete] = useState(null)

    //Estado para progress, el setLoading siempre debe ser llamado en funciones
    //asincronas para funcionar correctamente
    const [loading, setLoading] = useState(0)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Controladores mensajes confirmacion
    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setAreaToDelete(id)
        setOpenDelete(true)
    }

    const handleCloseDelete = () => {
        setOpenDelete(false)

    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorNombre || nombre === "" || errorCelular || errorTelefono || periodoControl === "" || instalacion === "")
        {
            return true
        }
        return false
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Funciones para controlar cambios en inputs
    const onChangeNombre = (text)  =>
    {
        setNombre(text)
        if(!allLetterAndSpace(text)){
            setErrorNombre(true)
            setHelperNombre("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombre(false)
            setHelperNombre("")
        }
    }

    const onChangeTelefono = (text)=>
    {
        setTelefono(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefono(true)
            setHelperTelefono("Debe ingresar un telefono valido valido.")
        }else{
            setErrorTelefono(false)
            setHelperTelefono("")
        }
    }

    const onChangeCelular = (text)=>
    {
        setCelular(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelular(true)
            setHelperCelular("Debe ingresar un telefono valido valido.")
        }else{
            setErrorCelular(false)
            setHelperCelular("")
        }
    }

    //Funcion para manejar la creacion de una area
    const onCreateArea = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let newArea = {
            nombre: nombre,
            periodo_control: periodoControl,
            telefono: telefono,
            telefono_celular: celular,
            instalacion_id: instalacion && instalacion.value
        }
        startLoading()
        const result = idSolicitud ? await createAreaEdit(newArea) : await createArea(newArea)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            handleOpenAlert('success', `El area "${nombre}" se creo correctamente.`)
            handleGetAreas()
        }
        stopLoading()
        cancel()
    }

    //Funcion para manejar una actualizacion en los datos de una area
    const onUpdateArea = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let areaActualizada = {
            nombre: nombre,
            periodo_control: periodoControl,
            telefono: telefono,
            telefono_celular: celular,
        }
        startLoading()
        const result = aprobado ? await updateAreaEdit(id, areaActualizada) : await updateArea(id, areaActualizada)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre, telefono, telefono_celular, periodo_control}
                = result
            const newCells = [nombre, telefono, telefono_celular, periodo_control]
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `Los datos del area "${nombre}" se actualizo correctamente.`)
            await handleGetAreas()
        }
        stopLoading()
    }

    //Funcion para manejar el boton eliminar area
    const onDeleteArea = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteArea(areaToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            handleGetAreas()
            editCancel()
            handleOpenAlert('success', `El area "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    //Funcion para manejar la consulta de los datos de una area
    const getArea = useCallback(async (id) => {
        startLoading()
        const area = await getAreaById(id)
        if (area.error){
            handleOpenAlert('error', area.error)
        }
        else{
            //Datos area
            setInstalacion({label: area.instalacion.nombre, value: area.instalacion.id})
            setNombre(area.nombre)
            setPeriodoControl(area.periodo_control)
            setTelefono(area.telefono)
            setCelular(area.telefono_celular)
            setAprobado(area.aprobado)
        }
        stopLoading()
    }, [getAreaById])

    const handleGetInstalaciones = async (value) => {
        const instalaciones = await getInstalacionesPorNombre(value)
        if(instalaciones.error){
            return []
        }
        else{
            let options = []
            instalaciones.forEach(({id, nombre, disponible}) => {
                const label = nombre
                const value = id
                if(disponible){
                    const instalacionComboBox = {
                        label,
                        value: value.toString()
                    }
                    options = [...options, instalacionComboBox]
                }
            })
            return options
        }
    }

    const handleGetAreas = useCallback(async () => {
        startLoading()
        const areas = await getAreasByInstalacion(instalacion?.value, page)
        if (areas.error){
            handleOpenAlert('error', areas.error)
        }
        else{
            setRows([])
            setNumPages(areas.last_page)
            areas.data.forEach(({id, nombre, telefono,
                telefono_celular, periodo_control, disponible : estadoDisponible}) => {
                const key = id
                const cell = [nombre, telefono, telefono_celular, periodo_control]
                const disponible = estadoDisponible
                addRow(key, cell, setRows, disponible)
            })
            if(page > areas.last_page){
                setPage(areas.last_page)
                history.push(`${window.location.pathname}?page=${areas.last_page}`)
            }
        }
        stopLoading()
    }, [getAreasByInstalacion, history, instalacion, page])

    //Funcion que activa y desactiva las areas
    const handleChangeEstadoDisponible = async(key) => {
        startLoading()
        const area = await getAreaById(id)
        if (area.error){
            handleOpenAlert('error', area.error)
        }
        else {
            const activarDesactivarArea = area.aprobado === true ?
                await updateStatusArea(key.target.id) :
                await deleteArea(key.target.id)
            if (activarDesactivarArea.error) {
                handleOpenAlert('error', activarDesactivarArea.error)
            }
        }
        editCancel()
        await handleGetAreas()
        stopLoading()
    }

    //Funcion maneja boton editar
    const editClick = (id) => {
        history.push(`/cliente/areas/${id}?page=${page}`)
    }

    //Funcion maneja boton cancelar edicion
    const editCancel = async () => {
        cancel()
        history.push(`/cliente/areas?page=${page}`)
    }

    //Funcion cancelar (regresa los estados al valor inicial)
    const cancel = () => {
        setNombre("")
        setInstalacion(null)
        setErrorNombre(false)
        setHelperNombre("")
        setTelefono("")
        setErrorTelefono(false)
        setHelperTelefono("")
        setCelular("")
        setErrorCelular(false)
        setHelperCelular("")
        setAprobado('')
    }

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        rows,
        nombre,
        errorNombre,
        helperNombre,
        telefono,
        errorTelefono,
        helperTelefono,
        celular,
        errorCelular,
        helperCelular,
        periodoControl,
        id,
        openEdit,
        openDelete,
        areaToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        instalacion,
        idSolicitud
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        onCreateArea,
        onUpdateArea,
        onDeleteArea,
        editClick,
        onChangeNombre,
        onChangeTelefono,
        onChangeCelular,
        setPeriodoControl,
        setInstalacion,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setAreaToDelete,
        setAlertOpen,
        handlePageChange,
        handleGetInstalaciones,
        handleChangeEstadoDisponible
    }

    //useEffect para cargar datos en la tabla y combobox de roles
    useEffect(() => {
        if(instalacion){
            handleGetAreas()
        }
        //Solo se ejecuta cuando cambian las funciones setRows, instalacion o handleGetAreas
    }, [setRows, instalacion, handleGetAreas])

    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getArea(id)
        }
        //Solo se ejecuta cuando cambia el valor de id o getArea
    }, [id, getArea])

    useEffect( async () => {
        const edicion = await comprobarEstadoEdicionSolicitud()
        setIdSolicitud(edicion)
    })

    return {data, actions}
}

export default useDatosAreasPage
