import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import DataTable from '../../components/DataTable'
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import Pagination from '../../components/Pagination'
import useAsignacionDosimetriaPage from '../../hooks/asignacionDosimetria/useAsignacionDosimetriaPage'
import ReactSelect from "../../components/ReactSelect"
import React from 'react'

const AsignacionDosimetrosPage = () => {
    const {data, actions} = useAsignacionDosimetriaPage()
    const {
        alertOpen, alertSeverity, alertMessage, loading,
        selectedInstitucion, selectedInstalacion, selectedArea, rows, encabezado, page, numPages
    } = data
    const {
        setAlertOpen, handlePageChange, handleLimpiar, handleChangeArea,
        handleChangeInstalacion, handleChangeInstitucion, loadAreas, loadInstalaciones, loadInstituciones,
        botonesDataTable
    } = actions

    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Progress open={loading}/>
            <Alert open={alertOpen} setOpen={setAlertOpen}
                   severity={alertSeverity}
                   message={alertMessage}/>

            {/* Institucion */}
            <Grid item xs={6}>
                <ReactSelect
                    placeholder="Seleccione la institución disponible"
                    label="Institución"
                    asyncFunc={loadInstituciones}
                    onChange={handleChangeInstitucion}
                    value={selectedInstitucion}
                    isClearable={true}
                    components={
                        selectedInstitucion ?
                            {
                                Menu: () => null
                            }
                            :
                            undefined
                    }
                />
            </Grid>
            {/* Intalacion */}
            <Grid item xs={6}>
                {
                    selectedInstitucion ?
                        <ReactSelect
                            key={0} value={selectedInstalacion}
                            placeholder="Seleccione la instalación disponible"
                            label="Instalación"
                            asyncFunc={loadInstalaciones}
                            onChange={handleChangeInstalacion}
                            disabled={!selectedInstitucion}
                            isClearable={true}
                            components={
                                selectedInstalacion ?
                                    {
                                        Menu: () => null
                                    }
                                    :
                                    undefined
                            }
                        /> :
                        <ReactSelect
                            key={1} value={null}
                            placeholder="Seleccione la instalación disponible"
                            label="Instalación"
                            onChange={() => {
                            }}
                            asyncFunc={() => {
                            }}
                            disabled={true}
                            isClearable={true}
                        />
                }
            </Grid>
            {/* Area */}
            <Grid item xs={6}>
                {
                    selectedInstalacion ?
                        <ReactSelect
                            key={0} value={selectedArea}
                            placeholder="Seleccione el área disponible"
                            label="Área"
                            asyncFunc={loadAreas}
                            onChange={handleChangeArea}
                            disabled={!selectedInstalacion}
                            isClearable={true}
                            components={
                                selectedArea ?
                                    {
                                        Menu: () => null
                                    }
                                    :
                                    undefined
                            }
                        /> :
                        <ReactSelect
                            key={1} value={null}
                            placeholder="Seleccione el área disponible"
                            label="Área"
                            onChange={() => {
                            }}
                            asyncFunc={() => {
                            }}
                            disabled={true}
                            isClearable={true}
                        />
                }
            </Grid>
            <Grid item xs={2}>
                &nbsp;
                <Button variant='contained' text='Limpiar' func={handleLimpiar}/>
            </Grid>
            {/* </Grid> */}

            {/*Codigo para la Tabla*/}
            <Grid item xs={12}>
                <DataTable headers={encabezado} rows={rows}
                           customBtn={botonesDataTable()}
                />
                <Pagination page={Number(page)}
                            numPages={Number(numPages)}
                            onChangePage={handlePageChange}
                />
            </Grid>
        </Grid>
    )
}


export default AsignacionDosimetrosPage
