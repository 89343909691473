import AppFrame from '../../components/AppFrame'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import useAsignacionDosimetriaAreaToePage from '../../hooks/asignacionDosimetria/useAsignacionDosimetriaAreaToePage'
import {DataGrid, esES} from '@material-ui/data-grid'
import React from 'react'
import Dialog from "../../components/Dialog/Dialog"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from "../../components/Input";


const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
}))

const AsignacionDosimetrosAreaToePage = () => {
    const classes = useStyles()
    const {data, actions} = useAsignacionDosimetriaAreaToePage()
    const {
        alertOpen, alertSeverity, alertMessage, loading,
        encabezado, openModalAsignar, rows, rowsDisponibles, encabezadoDosimetroDisponible,
        tipoDosimetro, area, institucion, instalacion, codigoDosimetro
    } = data
    const {
        setAlertOpen, openModalAsignarDosimetro, closeModalAsignarDosimetro,
        saveDosimetroAsignados, handleSelectionRows, handleCodigoDosimetro
    } = actions

    return (
        <AppFrame loged={false}>
            <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                <Progress open={loading}/>
                <Alert open={alertOpen} setOpen={setAlertOpen}
                       severity={alertSeverity}
                       message={alertMessage}/>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid className={classes.input} item xs={6}>
                            <p>
                                <strong>Institución:</strong> {institucion}
                            </p>
                            <p>
                                <strong>Instalación:</strong> {instalacion}
                            </p>
                            <p>
                                <strong>Área:</strong> {area}
                            </p>
                        </Grid>
                    </Paper>
                </Grid>
                {/*Codigo para la Tabla*/}
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {
                                        encabezado.map((header) => {
                                            return <TableCell>{header}</TableCell>
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows?.length > 0 ?
                                        rows.map((row) => (
                                        <TableRow key={row.key}>
                                            <TableCell>{row.nombres}</TableCell>
                                            {row.codigo === undefined || row.codigo === null || row.codigo === "" ?
                                                <TableCell>
                                                    <Button variant="contained" text="Asignar"
                                                            func={() => openModalAsignarDosimetro(row)}/>
                                                </TableCell>
                                                :
                                                <TableCell>
                                                    {row.codigo}
                                                </TableCell>
                                            }
                                            <TableCell>{row.tipo_dosimetro}</TableCell>
                                        </TableRow>
                                    ))
                                        :
                                        <TableRow>
                                            <TableCell>No hay TOE asignado</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*<DataTable headers={encabezado} rows={rows}*/}
                    {/*     customBtn={botonesDataTable()}*/}
                    {/* />*/}
                </Grid>
                <Dialog
                    open={openModalAsignar}
                    handleAccept={saveDosimetroAsignados}
                    handleClose={closeModalAsignarDosimetro}
                    title={"Dosimetros disponibles"}
                    maxWidth='md'
                    content={
                        <Grid container xs={12}>
                            <Grid className={classes.input} xs={8} sm={8}>
                                <Input type="text" label="Código de dosímetro"
                                       placeholder="Código de dosímetro"
                                       value={codigoDosimetro}
                                       onChange={handleCodigoDosimetro}
                                />
                            </Grid>
                            <Grid className={classes.input} item xs={6}>
                                <p>
                                    <strong>Dosimetros: {tipoDosimetro}</strong>
                                </p>
                            </Grid>
                            <Grid className={classes.input} item xs={12}>
                                <DataGrid
                                    localeText={esES.props.MuiDataGrid.localeText}
                                    autoHeight
                                    rows={rowsDisponibles}
                                    columns={encabezadoDosimetroDisponible}
                                    pageSize={25}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    disableColumnMenu={true}
                                    onSelectionModelChange={(ids) => handleSelectionRows(ids)}
                                />
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
        </AppFrame>
    )
}


export default AsignacionDosimetrosAreaToePage
