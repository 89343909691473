import React from 'react'
import PropTypes from 'prop-types'
import { createTheme , ThemeProvider as MaterialTP } 
    from '@material-ui/core/styles'
import AppColors from '../../utils/colors';

const theme = createTheme({
  palette: {
    primary: AppColors.blue,
    secondary: AppColors.red,
  },
});

const ThemeProvider = ({children}) => {
    return (
        <MaterialTP theme={theme}>
            {children}
        </MaterialTP>
    )
}

ThemeProvider.propTypes = {
  children: PropTypes.node
}

export default ThemeProvider