import axios from "axios";
// import url from "./utils/url"
// import headers from "./utils/headers"
// import useErrorHandler from './utils/useErrorHandler'
import { useCallback } from "react";
import headers from "../utils/headers";
import url from "../utils/url";
import useErrorHandler from "../utils/useErrorHandler";

const personalUrl = `${url}personalca`;
// const personalUrl = `http://168.232.49.152/personalca`

const usePersonalCCService = () => {
  const { errorHandler } = useErrorHandler();

  const createPersonal = useCallback(
    async (personal) => {
      try {
        //const response = await axios.post(clienteUrl, personal)

        const response = await axios.post(personalUrl, personal, {
          headers: headers(),
        });

        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getPersonalById = useCallback(
    async (idPersonal) => {
      const requestUrl = `${personalUrl}/${idPersonal}`;
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const cambiarNombreAName = (listaDeObjetos) => {
    const nuevaLista = listaDeObjetos.map((objeto) => {
      // Clonamos el objeto para evitar modificar el original
      const nuevoObjeto = { ...objeto };

      // Cambiamos la variable 'nombre' a 'name'
      if (nuevoObjeto.hasOwnProperty("nombre")) {
        nuevoObjeto.name = nuevoObjeto.nombre;
        delete nuevoObjeto.nombre;
      }

      return nuevoObjeto;
    });

    return nuevaLista;
  };

  const deletePersonal = useCallback(
    async (idPersonal) => {
      const requestUrl = `${personalUrl}/${idPersonal}`;
      try {
        const response = await axios.delete(requestUrl, { headers: headers() });
        // const response = await axios.delete(requestUrl, { data: payload })
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getAllPersonal = useCallback(async () => {
    const requestUrl = `${personalUrl}`;
    try {
      const response = await axios.get(requestUrl, { headers: headers() });
      const { data } = response;
      return cambiarNombreAName(data);
    } catch (error) {
      return errorHandler(error);
    }
  }, [errorHandler]);

  const updatePersonal = useCallback(
    async (idTransporte, cambios) => {
      const requestUrl = `${personalUrl}/${idTransporte}`;
      try {
        const response = await axios.put(requestUrl, cambios, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    getPersonalById,
    getAllPersonal,
    createPersonal,
    deletePersonal,
    updatePersonal,
  };
};

export default usePersonalCCService;
