import { useState, useEffect, useCallback } from 'react'
import useResumenService from '../../services/inscripcion/useResumenService'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import useSolicitudCotizacionService from '../../services/solicitudCotizacion'
import useIsLoged from '../utils/useIsLoged'
import { useHistory } from 'react-router-dom'

const useResumenPage = (idSolicitud = null) => {
    const history = useHistory()
    const { getInstitucion, getInstitucionById, getInstalacionesByInstitucion, getInstalaciones, getInstalacion, getArea,
        getPersonal, getDatosPersonal } = useResumenService()

    const { getSolicitudById } = useSolicitudCotizacionService()
    const { comprobarEstadoEdicionSolicitud } = useSolicitudCotizacionService()
    const {enviarSolicitudInscripcion, getArchivoPersonal, enviarSolicitudInscripcionEtapa2Edit} = useSolicitudInscripcionService()

    useIsLoged()
    const [loading, setLoading] = useState(0)
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    const [pestania, setPestania] = useState(0)
    const [institucion, setInstitucion] = useState(null)
    const [representanteLegal, setRepresentanteLegal] = useState(null)
    const [instalaciones, setInstalaciones] = useState(null)
    const [instalacionesPersonal, setInstalacionesPersonal] = useState(null)
    const [solicitud, setSolicitud] = useState(null)
    const [archivoPersonal, setArchivoPersonal] = useState(null)
    const [fileNumPages, setFileNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    // Estado que almacena un valor booleano y servira como variable de control para
    // el proceso de edicion
    const [modoEdicion, setModoEdicion] = useState(false)

    function onDocumentLoadSuccess({ numPages }) {
        setFileNumPages(numPages);
    }
    const [openPersonalFileModal, setOpenPersonalFileModal] = useState(false)
    const handleClosePersonalFileModal = () => {
        setOpenPersonalFileModal(false)
    }
    const handlePersonalFilePageChange = (event, value) => {
        setPageNumber(value)
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const handleChangePestania = (event, newValue) => {
        setPestania(newValue);
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleGetSolicitud = useCallback(async () => {
        startLoading()
        const soli = await getSolicitudById(idSolicitud)
        if (soli.error){
            handleOpenAlert('error', 'Ocurrio un error al obtener la solicitud')
        }
        else{
            if(soli[0].tipo_solicitud_id === 2){
                setSolicitud(soli[0])
            }
        }
        stopLoading()
    }, [getSolicitudById, idSolicitud])

    const handleGetInstitucion = useCallback(async () => {
        startLoading()
        const miInstitucion = solicitud ? await getInstitucionById(solicitud.institucion_id) : await getInstitucion()
        if (miInstitucion.error){
            handleOpenAlert('error', 'Ocurrio un error al obtener los datos de la institucion')
        }
        else{
            setInstitucion(miInstitucion.institucion)
            setRepresentanteLegal(miInstitucion.representanteLegal)
        }
        stopLoading()
    }, [getInstitucion, getInstitucionById, solicitud])

    const handleGetInstalaciones = useCallback(async () => {
        startLoading()
        const misInstalaciones = solicitud ? await getInstalacionesByInstitucion(solicitud.institucion_id) : await getInstalaciones()
        if (misInstalaciones.error){
            handleOpenAlert('error', 'Ocurrio un error al obtener los datos de las instalaciones')
        }
        else{
            const newInstalaciones = misInstalaciones.map(
                (instalacion) => {

                    return ({...instalacion, open: false})
                }
            )
            // console.log('nuevas instalaciones', newInstalaciones)
            // console.log(misInstalaciones)
            switch (pestania) {
                case 1:
                    setInstalaciones(newInstalaciones)
                    break
                case 2:
                    setInstalacionesPersonal(newInstalaciones)
                    break
                default:
                    break
            }
        }
        stopLoading()
    }, [getInstalaciones, getInstalacionesByInstitucion, pestania, solicitud])

    const handleOnChangeAccordionInstalacion = async (instalacionId) => {
        const instalacionIndex = instalaciones.findIndex(instalacion => instalacion.id === instalacionId)
        let newInstalaciones = [...instalaciones]
        newInstalaciones[instalacionIndex] = {
            ...newInstalaciones[instalacionIndex],
             open: !newInstalaciones[instalacionIndex].open
        }
        if(!newInstalaciones[instalacionIndex].detalle){
            startLoading()
            const instalacion = await getInstalacion(instalacionId)
            if (instalacion.error){
                handleOpenAlert('error', 'Ocurrio un error al cargar los datos de la instalacion')
            }
            else{
                const newAreas = instalacion.areas.map(
                    (area) => {
                        return ({...area, open: false})
                    }
                )
                newInstalaciones[instalacionIndex] = {
                    ...newInstalaciones[instalacionIndex],
                     detalle: {...instalacion, areas: newAreas}
                }
            }
            stopLoading()
        }
        setInstalaciones(newInstalaciones)
    }

    const handleOnChangeAccordionInstalacionPersonal = async (instalacionId) => {
        const instalacionIndex = instalacionesPersonal.findIndex(instalacion => instalacion.id === instalacionId)
        let newInstalaciones = [...instalacionesPersonal]
        newInstalaciones[instalacionIndex] = {
            ...newInstalaciones[instalacionIndex],
             open: !newInstalaciones[instalacionIndex].open
        }
        if(!newInstalaciones[instalacionIndex].personal){
            startLoading()
            const personal = await getPersonal(instalacionId)

            if (personal.error){
                handleOpenAlert('error', 'Ocurrio un error al obtener los datos del personal')
            }
            else{
                const newPersonal = personal.map(
                    (persona) => {
                        return ({...persona, open: false})
                    }
                )
                newInstalaciones[instalacionIndex] = {
                    ...newInstalaciones[instalacionIndex],
                    personal: newPersonal
                }
            }
            stopLoading()
        }
        setInstalacionesPersonal(newInstalaciones)
    }

    const handleOnChangeAccordionArea = async (instalacionId, areaId) => {
        const instalacionIndex = instalaciones.findIndex(instalacion => instalacion.id === instalacionId)
        let newInstalaciones = [...instalaciones]
        let areas = newInstalaciones[instalacionIndex].detalle.areas
        const areaIndex = areas.findIndex(area => area.id === areaId)
        areas[areaIndex] = {
            ...areas[areaIndex],
            open: !areas[areaIndex].open
        }
        if(!areas[areaIndex].detalle){
            startLoading()
            const area = await getArea(areaId)
            if (area.error){
                handleOpenAlert('error', 'Ocurrio un error al obtener los datos del area')
            }
            else{
                areas[areaIndex].detalle = area
                newInstalaciones[instalacionIndex] = {
                    ...newInstalaciones[instalacionIndex],
                     detalle: {...newInstalaciones[instalacionIndex].detalle, areas: areas}
                }
            }
            stopLoading()
        }
        // console.log('nueva instalaciones despues de abrir area', newInstalaciones)
        setInstalaciones(newInstalaciones)
    }

    const handleGetFilePersonal = useCallback(async (personal) => {
        startLoading()
        const file = await getArchivoPersonal(personal)
        if (file.error){
            handleOpenAlert('error', 'Ocurrio un error al cargar el archivo del personal')
        }
        else{
            const blob = new Blob([file.data])
            setArchivoPersonal(blob)
        }
        stopLoading()
    }, [getArchivoPersonal])

    const openArchivoPersonalFile = async (personal) => {
        await handleGetFilePersonal(personal)
        setOpenPersonalFileModal(true)
    }

    const handleOnChangeAccordionPersonal = async (instalacionId, personalId) => {
        const instalacionIndex = instalacionesPersonal.findIndex(instalacion => instalacion.id === instalacionId)
        let newInstalaciones = [...instalacionesPersonal]
        let newPersonal = newInstalaciones[instalacionIndex].personal
        const personalIndex = newPersonal.findIndex(personal => personal.id === personalId)
        newPersonal[personalIndex] = {
            ...newPersonal[personalIndex],
            open: !newPersonal[personalIndex].open
        }
        if(!newPersonal[personalIndex].detalle){
            startLoading()
            const datosPersonal = await getDatosPersonal(personalId)
            if (datosPersonal.error){
                handleOpenAlert('error', 'Ocurrio un error al cargar los datos del personal')
            }
            else{
                newPersonal[personalIndex].detalle = datosPersonal
                newInstalaciones[instalacionIndex] = {
                    ...newInstalaciones[instalacionIndex],
                    personal: newPersonal
                }
            }
            stopLoading()
        }
        // console.log('nueva instalaciones despues de abrir personal', newInstalaciones)
        setInstalacionesPersonal(newInstalaciones)
    }

    const handleEnviarSolicitud = async () => {
        startLoading()
        const solicitud = modoEdicion ? await enviarSolicitudInscripcionEtapa2Edit() :
            await enviarSolicitudInscripcion()
        if (solicitud.error){
            handleOpenAlert('error', 'Ocurrió un error al enviar la solicitud')
        }
        else{
            handleOpenAlert('success', 'Se envió la solicitud de inscripcion con éxito')
            history.push(`/inicio`)
        }
        stopLoading()
    }

    const data = {
        pestania,
        loading,
        institucion,
        representanteLegal,
        instalaciones,
        instalacionesPersonal,
        openPersonalFileModal,
        pageNumber,
        fileNumPages,
        archivoPersonal,
        alertOpen,
        alertSeverity,
        alertMessage
    }

    const actions = {
        handleChangePestania,
        handleOnChangeAccordionInstalacion,
        handleOnChangeAccordionArea,
        handleOnChangeAccordionInstalacionPersonal,
        handleOnChangeAccordionPersonal,
        handleEnviarSolicitud,
        handlePersonalFilePageChange,
        handleClosePersonalFileModal,
        onDocumentLoadSuccess,
        openArchivoPersonalFile,
        setAlertOpen
    }

    //useEffect para cargar datos de las pestanias
    useEffect(() => {
        //Verdaero solo si existe idSolicitud y datos de solicitud, o no existen ni uno
        if((idSolicitud && solicitud) || (!idSolicitud && !solicitud)){
            switch (pestania) {
                case 0:
                    if(!institucion && !representanteLegal){
                        handleGetInstitucion()
                    }
                    break
                case 1:
                    if(!instalaciones){
                        handleGetInstalaciones()
                    }
                    break
                case 2:
                    if(!instalacionesPersonal){
                        handleGetInstalaciones()
                    }
                    break
                default:
                    break
            }
        }
        //Verdaero solo si existe idSolicitud pero no se ha cargado los datos de la solicitud
        if(idSolicitud && !solicitud){
            handleGetSolicitud()
        }
    }, [handleGetInstalaciones, handleGetInstitucion, handleGetSolicitud, idSolicitud, instalaciones, instalacionesPersonal, institucion, pestania, representanteLegal, solicitud])

    // useEffect para verificar si se encuentra en modo de edicion
    useEffect( async () => {
        const edicion = await comprobarEstadoEdicionSolicitud()
        setModoEdicion(edicion)
    })

    return {data, actions}
}

export default useResumenPage
