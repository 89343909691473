import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Session from 'react-session-api'

const useIsLoged = () => {
    const history = useHistory()
        
    useEffect(() => {
        const token = Session.get('token')
        const isLoged = token ? true : false
        //Verifica si hay una sesion activa
        if(!isLoged){
            // console.log("isLoged false")
            history.push(`/iniciar-sesion`)
        }
    })
}

export default useIsLoged