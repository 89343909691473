import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import MaterialDrawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { AiOutlineFileText,AiOutlineCarryOut } from 'react-icons/ai'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FaIndustry, FaBookOpen, FaFileContract, FaClipboardList }
    from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { BsListCheck, BsFillStarFill, BsPersonPlusFill, BsFillCalendarWeekFill, BsCheckSquare, BsClipboardData } from 'react-icons/bs'
import { RiBuilding4Line } from 'react-icons/ri'
import { MdHome, MdGroups, MdFolder, MdOutlineAutoStories,IoMdPersonAdd,MdArticle} from 'react-icons/md'
import { CgWorkAlt } from 'react-icons/cg'
import { HiOutlineBriefcase } from 'react-icons/hi'
import Session from 'react-session-api'
import { MdGroupAdd,MdOutlineFrontHand,MdCommute,MdAssignmentInd,MdFactCheck,MdAssignment} from "react-icons/md";


//Iconos GR no se cambia color con IconContext
//import { GrCatalog } from 'react-icons/gr'
import {GiNuclearWaste, GiDoctorFace} from 'react-icons/gi'
import Collapse from '@material-ui/core/Collapse';
import { IconContext } from 'react-icons/lib'

export const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#D5EDFF',
        overflowX: 'hidden', // Evita desbordamiento horizontal
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}))

//Menus usuarios
//TODO filtrar por permisos del usuario
const menu = () => {
    if(Session.get('rol')){
        const rol = Session.get('rol')
        const estado = 'Aprobado'

        const solicitudes = () => {
            return {
                label: 'Solicitudes',
                icon: <MdFolder/>,
                sub_menu: [
                    {label:'Cotización', url: '/solicitudes_cotizacion', icon: <MdOutlineAutoStories/>},
                    {label:'Contratación', url: '/solicitudes_contratacion', icon: <MdOutlineAutoStories/>},
                    {label:'Modificación', url: '/solicitudes_modificacion', icon: <MdOutlineAutoStories/>},
                ],
                open: false
            }
        }

        const admin = [
            {
                label: 'Administracion',
                icon: <FaBookOpen/>,
                sub_menu: [
                    {label:'Ministerio', url: '/admin/ministerio', icon: <RiBuilding4Line/>},
                    {label:'Cargo', url: '/admin/cargo', icon: <CgWorkAlt/>},
                    {label:'Practica', url: '/admin/practica', icon: <HiOutlineBriefcase/>},
                    {label:'Responsabilidad', url: '/admin/responsabilidad', icon: <BsListCheck/>},
                    {label:'Sector', url: '/admin/sector', icon: <FaIndustry/>},
                    {label:'Rol', url: '/admin/rol', icon: <BsFillStarFill/>},
                    {label:'Usuario', url: '/admin/usuario', icon: <FiUsers/>},
                    {label:'Contrato', url: '/admin/contrato', icon: <FaFileContract/>},
                    {label:'Dosimetro', url: '/admin/dosimetro', icon: <GiNuclearWaste/>},
                    {label:'Transporte', url: '/transporte/administrar/', icon: <MdCommute/>}
                ],
                open: false
            },
            {
                label: 'Solicitudes',
                icon: <MdFolder/>,
                sub_menu: [
                    {label:'Inscripcion: Etapa 1', url: '/solicitudes-inscripcion-etapa-1', icon: <MdOutlineAutoStories/>},
                    {label:'Inscripcion: Etapa 2', url: '/solicitudes-inscripcion-etapa-2', icon: <MdOutlineAutoStories/>},
                    {label:'Cotización', url: '/solicitudes_cotizacion', icon: <MdOutlineAutoStories/>},
                    {label:'Contratación', url: '/solicitudes_contratacion', icon: <MdOutlineAutoStories/>},
                    {label:'Modificación', url: '/solicitudes_modificacion', icon: <MdOutlineAutoStories/>},
                ],
                open: false
            },
            {label: 'Planificación', url: '/planificacion', icon: <BsFillCalendarWeekFill/>},
            {
                label: 'Control de Calidad',
                icon: <MdOutlineFrontHand/>,
                sub_menu: [
                    {label:'Clientes', url: '/cliente/administrar/', icon: <MdGroupAdd/>},
                    {label:'Actividades', url: '/control_de_calidad/actividades/', icon: <AiOutlineCarryOut/>},
                    {label:'Personal', url: '/personalcc/administrar/', icon: <MdAssignmentInd/>},
                    {label:'Estado', url: '/control_de_calidad/solicitudes_recibidas/estados/', icon: <MdFactCheck/>},
                    {label:'Pasos', url: '/control_de_calidad/solicitudes_recibidas/pasos/', icon: <MdArticle/>},
                    {label:'Solicitudes Recibidas', url: '/control_de_calidad/solicitudes_recibidas/', icon: <MdOutlineAutoStories/>},
                    {label:'Reporte de Eficiencia Global', url: '/control_de_calidad/reportes/eficiencia_global/', icon: <MdAssignment/>},
                    {label:'Reporte de Indicadores Promedio', url: '/control_de_calidad/reportes/indicadores_promedio/', icon: <MdAssignment/>},
                    {label:'Reporte de Cantidad de Tipos', url: '/control_de_calidad/reportes/cantidad_tipos/', icon: <MdAssignment/>},
                    {label:'Reporte de Indicadores de Calidad', url: '/control_de_calidad/reportes/indicadores_de_calidad/', icon: <MdAssignment/>},
                    {label:'Reporte de Historial de Clientes', url: '/control_de_calidad/reportes/historial_clientes', icon: <MdAssignment/>}
                    
                    
                    
                    // {label:'Inscripcion: Etapa 2', url: '/solicitudes-inscripcion-etapa-2', icon: <MdOutlineAutoStories/>},
                    // {label:'Cotización', url: '/solicitudes_cotizacion', icon: <MdOutlineAutoStories/>},
                    // {label:'Contratación', url: '/solicitudes_contratacion', icon: <MdOutlineAutoStories/>},
                    // {label:'Modificación', url: '/solicitudes_modificacion', icon: <MdOutlineAutoStories/>},
                ],
                open: false
            },
        ]

        const cliente = [
            {
                label: 'Inscripcion',
                icon: <AiOutlineFileText/>,
                sub_menu: [
                    ...rol.id === 8 ? [{label:'Delegados', url: '/cliente/delegados', icon: <BsPersonPlusFill/>}] : [],
                    {label:'Instalaciones', url: '/cliente/instalaciones', icon: <RiBuilding4Line/>},
                    {label:'Areas', url: '/cliente/areas', icon: <MdHome/>},
                    {label:'Personal', url: '/cliente/personal', icon: <MdGroups/>},
                    {label:'Trabajador operacionalmente expuesto', url: '/cliente/toe', icon: <GiDoctorFace/>},
                    {label:'Resumen', url: '/cliente/resumen', icon: <FaClipboardList/>},
                ],
                open: false
            },
            solicitudes(),
            {label: 'Edición de datos', url: estado === 'Aprobado' ? '/solicitudes_edicion': '',icon: <MdOutlineAutoStories/>}
        ]

        const coordinadorDosimetria = [
            solicitudes(),
            {
                label: 'Dosimetría',
                icon: <GiNuclearWaste/>,
                sub_menu: [
                    {label:'Control', url: rol.id === 4 ?
                            `/control_dosimetria` : `/control_dosimetria/reporte`, icon: <BsCheckSquare/>},
                    {label:'Configuración reporte lectura', url: `/configuracion_reporte_lectura`, icon: <BsClipboardData/>}
                ]
            }
        ]

        const tecnicoDosimetria = [
            {
                label: 'Dosimetría',
                icon: <GiNuclearWaste/>,
                sub_menu: [
                    {label:'Control', url: rol.id === 4 ?
                            `/control_dosimetria` : `/control_dosimetria/reporte`, icon: <BsCheckSquare/>},
                    {label:'Configuración reporte lectura', url: `/configuracion_reporte_lectura`, icon: <BsClipboardData/>}
                ]
            }
        ]
        return rol.id === 8 || rol.id === 9 ? cliente :
            rol.id === 4 ? tecnicoDosimetria : rol.id === 2 ? coordinadorDosimetria : admin
    }else{
        return []
    }
}

const Drawer = (props) => {
    const { window } = props
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const [menus, setMenus] = useState(menu())
    const handleOpenMenu = (index) => {
        setMenus((menus) => {
            let newMenus = menus
            newMenus[index].open = !newMenus[index].open
            return [...newMenus]
        })
    }

    const drawer = (
        <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)', overflowX: 'hidden' }}>
            <div className={classes.toolbar} />
            {
                menus.map(
                    (menuItem, index) => {
                        if(menuItem.sub_menu){
                            return (
                            <div key = {menuItem.label}>
                                <ListItem button onClick={() => handleOpenMenu(index)}>
                                    <ListItemIcon>
                                        <IconContext.Provider value = {{
                                            size: '1.6em',
                                        }}>
                                            {menuItem.icon}
                                        </IconContext.Provider>
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.label} />
                                </ListItem>
                                <Collapse in={menuItem.open ? true : false} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className={classes.nested}>
                                        {menuItem.sub_menu.map((obj, index) => (
                                            <ListItem button key={obj.label} onClick = {()=>history.push(obj.url)}>
                                                <ListItemIcon>
                                                <IconContext.Provider value = {{
                                                    size: '1.6em',
                                                    color: 'gray',
                                                    path: {
                                                        stroke: 'gray'
                                                    }
                                                }}>
                                                    {obj.icon}
                                                </IconContext.Provider>
                                                </ListItemIcon>
                                                <ListItemText primary={obj.label} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                                <Divider />
                            </div>
                            )
                        }else{
                            return (
                                <div key = {menuItem.label}>
                                    <ListItem button key={menuItem.label} onClick = {()=>history.push(menuItem.url)}>
                                        <ListItemIcon>
                                        <IconContext.Provider value = {{
                                            size: '1.6em',
                                            color: 'gray',
                                            path: {
                                                stroke: 'gray'
                                            }
                                        }}>
                                            {menuItem.icon}
                                        </IconContext.Provider>
                                        </ListItemIcon>
                                        <ListItemText primary={menuItem.label} />
                                    </ListItem>
                                    <Divider />
                                </div>
                            )
                        }
                    }
                )
            }
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
                <MaterialDrawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={props.mobileOpen}
                    onClose={props.handleDrawerToggle}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </MaterialDrawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <MaterialDrawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </MaterialDrawer>
            </Hidden>
        </nav>
    );
}

Drawer.propTypes = {
    mobileOpen: PropTypes.bool.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
}

export default Drawer
