import { useCallback, useEffect, useState } from 'react'
import Session from 'react-session-api'
import { useCookies } from 'react-cookie'
import useLoginService from '../../services/sesion/useLoginService'
import { getNowInSeconds } from '../../utils/time'

const useRefreshToken = () => {
    const { refreshToken } = useLoginService()
    //Se obtiene el token de la sesion
    const [loading, setLoading] = useState(false)
    const setCookie = useCookies(['token', 'rol', 'id'])[1]
    const removeCookie = useCookies(['token', 'rol', 'id'])[2]
    const onRefreshToken = useCallback(async () => {
        if(!loading){
            console.log("Se intenta extender la sesion")
            setLoading(true)
            const result = await refreshToken()
            if (result.error){
                console.log("No se pudo extender la sesion.")
            }
            else{
                console.log("Se extendio la sesion")
                const access_token = result
                Session.set("token", access_token)
                removeCookie('token', {path: '/'})
                removeCookie('id', {path: '/'})
                removeCookie('rol', {path: '/'})
                setCookie('token', access_token, {path: '/',
                maxAge: access_token.expires_in})
                const id = Session.get("id")
                const rol = Session.get("rol")
                setCookie('id', id, {path: '/',
                    maxAge: access_token.expires_in})
                setCookie('rol', rol, {path: '/',
                    maxAge: access_token.expires_in})
            }
            setLoading(false)
        }
    }, [loading, refreshToken, removeCookie, setCookie])
    useEffect(() => {
        //Effect que se ejecuta cuando necesitaRefresh o onRefreshToken cambie
        //Verifica si necesita extenderse la sesion
        const token = Session.get('token')
        const isLoged = token ? true : false
        //Verifica si ha iniciado sesion
        if(isLoged){
            if(((token.expire_date - getNowInSeconds()) <= 300)){
                console.log("Se intenta refrecar el token")
                //Se extiende la sesion
                onRefreshToken()
            }
        }
    }, [onRefreshToken]);
}

export default useRefreshToken