import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { allLetterAndSpace, isDUI, isEmail, isNIT, isPhone, validPassword } 
    from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import useDepartamentosService from '../../services/inscripcion/useDepartamentosService'

const useSolicitudInscripcionPage = () => {
    const { getDepartamentos } = useDepartamentosService()

    const history = useHistory()
    const {paso} = useParams()
    const { createSolicitudInscripcion,
        getMinisteriosSolicitud, getSectoresSolicitud } = useSolicitudInscripcionService()
    //Estados para controlar los inputs 
    //(errorInput y helperInput sirven para mostrar un error en el input)

    //Estado para la barra de progreso
    //const history = useHistory()
    const [progress, setProgress] = useState(-1)

    //Estados para usuario
    const [nombreUsuario, setNombreUsuario] = useState('')
    const [errorNombreUsuario, setErrorNombreUsuario] = useState(false)
    const [helperNombreUsuario, setHelperNombreUsuario] = useState('')
    const [duiUsuario, setDuiUsuario] = useState('')
    const [errorDuiUsuario, setErrorDuiUsuario] = useState(false)
    const [helperDuiUsuario, setHelperDuiUsuario] = useState('')
    const [archivoUsuario, setArchivoUsuario] = useState(null)
    const [objArchivoUsuario, setObjArchivoUsuario] = useState(null)
    const [telefonoUsuario, setTelefonoUsuario] = useState('')
    const [errorTelefonoUsuario, setErrorTelefonoUsuario] = useState(false)
    const [helperTelefonoUsuario, setHelperTelefonoUsuario] = useState('')
    const [celularUsuario, setCelularUsuario] = useState('')
    const [errorCelularUsuario, setErrorCelularUsuario] = useState(false)
    const [helperCelularUsuario, setHelperCelularUsuario] = useState('')
    const [correoElectronicoUsuario, setCorreoElectronicoUsuario] = useState('')
    const [errorCorreoElectronicoUsuario, setErrorCorreoElectronicoUsuario] = useState(false)
    const [helperCorreoElectronicoUsuario, setHelperCorreoElectronicoUsuario] = useState('')
    const [contrasenia1Usuario, setContrasenia1Usuario] = useState('')
    const [errorContrasenia1Usuario, setErrorContrasenia1Usuario] = useState(false)
    const [helperContrasenia1Usuario, setHelperContrasenia1Usuario] = useState('')
    const [contrasenia2Usuario, setContrasenia2Usuario] = useState('')
    const [errorContrasenia2Usuario, setErrorContrasenia2Usuario] = useState(false)
    const [helperContrasenia2Usuario, setHelperContrasenia2Usuario] = useState('')

    //Estados para institucion
    const [ministerios, setMinisterios] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [departamentosCombo, setDepartamentosCombo] = useState([])
    const [municipiosCombo, setMunicipiosCombo] = useState([])
    const [sectores, setSectores] = useState([])
    const tiposInstitucion = [
        {
            value: 1,
            label: "Pública"
        },
        {
            value: 2,
            label: "Privada"
        },
        {
            value: 3,
            label: "Empresa mixta"
        },
        {
            value: 4,
            label: "Empresa extranjera"
        },
        {
            value: 5,
            label: "Militar"
        },
        {
            value: 6,
            label: "Personal"
        },
        {
            value: 7,
            label: "Autónoma"
        },
    ]
    const [nombreInstitucion, setNombreInstitucion] = useState('')
    const [errorNombreInstitucion, setErrorNombreInstitucion] = useState(false)
    const [helperNombreInstitucion, setHelperNombreInstitucion] = useState('')
    const [nitInstitucion, setNitInstitucion] = useState("")
    const [errorNitInstitucion, setErrorNitInstitucion] = useState(false)
    const [helperNitInstitucion, setHelperNitInstitucion] = useState('')
    const [archivoInstitucion, setArchivoInstitucion] = useState(null)
    const [objArchivoInstitucion, setObjArchivoInstitucion] = useState(null)
    const [telefonoInstitucion, setTelefonoInstitucion] = useState("")
    const [errorTelefonoInstitucion, setErrorTelefonoInstitucion] = useState(false)
    const [helperTelefonoInstitucion, setHelperTelefonoInstitucion] = useState('')
    const [celularInstitucion, setCelularInstitucion] = useState("")
    const [errorCelularInstitucion, setErrorCelularInstitucion] = useState(false)
    const [helperCelularInstitucion, setHelperCelularInstitucion] = useState('')
    const [departamentoInstitucion, setDepartamentoInstitucion] = useState('')
    const [municipioInstitucion, setMunicipioInstitucion] = useState('')
    const [direccionInstitucion, setDireccionInstitucion] = useState("")
    const [errorDireccionInstitucion, setErrorDireccionInstitucion] = useState(false)
    const [helperDireccionInstitucion, setHelperDireccionInstitucion] = useState('')
    const [tipoInstitucion, setTipoInstitucion] = useState(1)
    const [sectorInstitucion, setSectorInstitucion] = useState('')
    const [ministerioInstitucion, setMinisterioInstitucion] = useState('')
    
    //Estados para responsable
    const [cargos, setCargos] = useState([])
    const [nombresResponsable, setNombresResponsable] = useState('')
    const [errorNombresResponsable, setErrorNombresResponsable] = useState(false)
    const [helperNombresResponsable, setHelperNombresResponsable] = useState('')
    const [apellidosResponsable, setApellidosResponsable] = useState('')
    const [errorApellidosResponsable, setErrorApellidosResponsable] = useState(false)
    const [helperApellidosResponsable, setHelperApellidosResponsable] = useState('')
    const [duiResponsable, setDuiResponsable] = useState('')
    const [errorDuiResponsable, setErrorDuiResponsable] = useState(false)
    const [helperDuiResponsable, setHelperDuiResponsable] = useState('')
    const [archivoResponsable, setArchivoResponsable] = useState(null)
    const [objArchivoResponsable, setObjArchivoResponsable] = useState(null)
    const [telefonoResponsable, setTelefonoResponsable] = useState('')
    const [errorTelefonoResponsable, setErrorTelefonoResponsable] = useState(false)
    const [helperTelefonoResponsable, setHelperTelefonoResponsable] = useState('')
    const [celularResponsable, setCelularResponsable] = useState('')
    const [errorCelularResponsable, setErrorCelularResponsable] = useState(false)
    const [helperCelularResponsable, setHelperCelularResponsable] = useState('')
    const [cargoResponsable, setCargoResponsable] = useState('')
    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openRepetido, setOpenRepetido] = useState(false)

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estado para progress, el setLoading siempre debe ser llamado en funciones 
    //asincronas para funcionar correctamente
    const [loading, setLoading] = useState(0)

    const handleAcceptDialog = () => {
        onCreateSolicitud(true)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorNombreUsuario || nombreUsuario === "" || errorCelularUsuario || 
            errorCorreoElectronicoUsuario || correoElectronicoUsuario === "" || 
            errorDuiUsuario || duiUsuario === "" || errorTelefonoUsuario || 
            errorNombreInstitucion || nombreInstitucion === "" || 
            errorNitInstitucion || nitInstitucion === "" || 
            errorTelefonoInstitucion || errorCelularInstitucion || 
            municipioInstitucion === "" || errorDireccionInstitucion || 
            direccionInstitucion === "" || sectorInstitucion === "" ||
            errorNombresResponsable || nombresResponsable === "" || 
            errorApellidosResponsable || apellidosResponsable === "" || 
            errorDuiResponsable || duiResponsable === "" || 
            errorTelefonoResponsable || errorCelularResponsable || 
            archivoUsuario || archivoInstitucion ||
            archivoResponsable)
        {
            // console.log(archivoUsuario)
            // console.log(archivoInstitucion)
            // console.log(archivoResponsable)
            //TODO verificar validaciones
            //return true
        }
        return false
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Funciones para controlar cambios en inputs de usuario
    const onChangeNombreUsuario = (text)  =>
    {
        setNombreUsuario(text)
        if(!allLetterAndSpace(text)){
            setErrorNombreUsuario(true)
            setHelperNombreUsuario("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombreUsuario(false)
            setHelperNombreUsuario("")
        }
    }

    const onChangeDuiUsuario = (text)=>
    {
        setDuiUsuario(text)
        if(!isDUI(text) && text !== ""){
            setErrorDuiUsuario(true)
            setHelperDuiUsuario("Debe ingresar un DUI valido. Ej. 99999999-9")
        }else{
            setErrorDuiUsuario(false)
            setHelperDuiUsuario("")
        }
    }

    const handleOnChangeArchivoUsuario = (file) => {
        setArchivoUsuario(file[0])
        setObjArchivoUsuario([{ name: file[0].name, deleteFile: () => {
            setObjArchivoUsuario(null)
            setArchivoUsuario(null)
        }}])
    }

    const onChangeTelefonoUsuario = (text)=>
    {
        setTelefonoUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoUsuario(true)
            setHelperTelefonoUsuario("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorTelefonoUsuario(false)
            setHelperTelefonoUsuario("")
        }
    }

    const onChangeCelularUsuario = (text)=>
    {
        setCelularUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularUsuario(true)
            setHelperCelularUsuario("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorCelularUsuario(false)
            setHelperCelularUsuario("")
        }
    }

    const onChangeCorreoElectronicoUsuario = (text)=>
    {
        setCorreoElectronicoUsuario(text)
        if(!isEmail(text) && text !== ""){
            setErrorCorreoElectronicoUsuario(true)
            setHelperCorreoElectronicoUsuario("Debe ingresar un correo electronico valido.")
        }else{
            setErrorCorreoElectronicoUsuario(false)
            setHelperCorreoElectronicoUsuario("")
        }
    }

    const onChangeContrasenia1Usuario = (text)=>
    {
        setContrasenia1Usuario(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia1Usuario(true)
            setHelperContrasenia1Usuario("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia1Usuario(false)
            setHelperContrasenia1Usuario("")
        }
    }

    const onChangeContrasenia2Usuario = (text)=>
    {
        setContrasenia2Usuario(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia2Usuario(true)
            setHelperContrasenia2Usuario("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia2Usuario(false)
            setHelperContrasenia2Usuario("")
        }
    }

    //Funciones manejar datos de institucion
    const onChangeNombreInstitucion = (text)  =>
    {
        setNombreInstitucion(text)
        if(!allLetterAndSpace(text)){
            setErrorNombreInstitucion(true)
            setHelperNombreInstitucion("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombreInstitucion(false)
            setHelperNombreInstitucion("")
        }
    }

    const onChangeNitInstitucion = (text)=>
    {
        setNitInstitucion(text)
        if(!isNIT(text) && text !== ""){
            setErrorNitInstitucion(true)
            setHelperNitInstitucion("Debe ingresar un NIT valido. Ej. 9999-999999-999-9")
        }else{
            setErrorNitInstitucion(false)
            setHelperNitInstitucion("")
        }
    }

    const handleOnChangeArchivoInstitucion = (file) => {
        setArchivoInstitucion(file[0])
        setObjArchivoInstitucion([{ name: file[0].name, deleteFile: () => {
            setObjArchivoInstitucion(null)
            setArchivoInstitucion(null)
        }}])
    }

    const onChangeTelefonoInstitucion = (text)=>
    {
        setTelefonoInstitucion(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoInstitucion(true)
            setHelperTelefonoInstitucion("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorTelefonoInstitucion(false)
            setHelperTelefonoInstitucion("")
        }
    }

    const onChangeCelularInstitucion = (text)=>
    {
        setCelularInstitucion(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularInstitucion(true)
            setHelperCelularInstitucion("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorCelularInstitucion(false)
            setHelperCelularInstitucion("")
        }
    }

    const onChangeDireccionInstitucion = (text)=>
    {
        setDireccionInstitucion(text)
    }

    //Funciones para controlar cambios en inputs de responsable
    const onChangeNombresResponsable = (text)  =>
    {
        setNombresResponsable(text)
        if(!allLetterAndSpace(text)){
            setErrorNombresResponsable(true)
            setHelperNombresResponsable("Los nombres solo deben consistir de letras.")
        }else{
            setErrorNombresResponsable(false)
            setHelperNombresResponsable("")
        }
    }

    const onChangeApellidosResponsable = (text)  =>
    {
        setApellidosResponsable(text)
        if(!allLetterAndSpace(text)){
            setErrorApellidosResponsable(true)
            setHelperApellidosResponsable("Los apellidos solo deben consistir de letras.")
        }else{
            setErrorApellidosResponsable(false)
            setHelperApellidosResponsable("")
        }
    }

    const onChangeDuiResponsable = (text)=>
    {
        setDuiResponsable(text)
        if(!isDUI(text) && text !== ""){
            setErrorDuiResponsable(true)
            setHelperDuiResponsable("Debe ingresar un DUI valido. Ej. 99999999-9")
        }else{
            setErrorDuiResponsable(false)
            setHelperDuiResponsable("")
        }
    }

    const handleOnChangeArchivoResponsable = (file) => {
        setArchivoResponsable(file[0])
        setObjArchivoResponsable([{ name: file[0].name, deleteFile: () => {
            setObjArchivoResponsable(null)
            setArchivoResponsable(null)
        }}])
    }

    const onChangeTelefonoResponsable = (text)=>
    {
        setTelefonoResponsable(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoResponsable(true)
            setHelperTelefonoResponsable("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorTelefonoResponsable(false)
            setHelperTelefonoResponsable("")
        }
    }

    const onChangeCelularResponsable = (text)=>
    {
        setCelularResponsable(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularResponsable(true)
            setHelperCelularResponsable("Debe ingresar un telefono valido valido. Ej. 9999-9999")
        }else{
            setErrorCelularResponsable(false)
            setHelperCelularResponsable("")
        }
    }

    const handleOnProgress = (loaded, total) => {
        const progress = (loaded/total)*100
        setProgress(progress)
    }

    //Funcion para manejar la creacion de una solicitud de inscripcion
    const onCreateSolicitud = async (responsableRepetido) => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        if(contrasenia1Usuario !== contrasenia2Usuario){
            handleOpenAlert('error', "Las contraseñas del usuario introducidas no coinciden.")
            return
        }
        if(tipoInstitucion === 1 && !ministerioInstitucion){
            handleOpenAlert('error', "Debe seleccionar un ministerio para las instituciones publicas.")
            return
        }
        // console.log('responsableRepetido', responsableRepetido)
        let newSolicitud = new FormData()
        newSolicitud.append("user[nombres]", nombreUsuario)
        newSolicitud.append("user[dui]", duiUsuario)
        newSolicitud.append("user[telefono]", telefonoUsuario)
        newSolicitud.append("user[telefono_celular]", celularUsuario)
        newSolicitud.append("user[email]", correoElectronicoUsuario)
        newSolicitud.append("user[password]", contrasenia1Usuario)
        newSolicitud.append("user[password_confirmation]", contrasenia2Usuario)
        newSolicitud.append("user[archivo_documento_identidad]", archivoUsuario)
        newSolicitud.append("institucion[nombre]", nombreInstitucion)
        newSolicitud.append("institucion[nit]", nitInstitucion)
        newSolicitud.append("institucion[telefono]", telefonoInstitucion)
        newSolicitud.append("institucion[telefono_celular]", celularInstitucion)
        newSolicitud.append("institucion[tipo_institucion_id]", tipoInstitucion)
        newSolicitud.append("institucion[sector_id]", sectorInstitucion)
        //Se envia ministerio_id solo si el tipo de institucion es publica
        if(tipoInstitucion === 1){
            newSolicitud.append("institucion[ministerio_id]", ministerioInstitucion)
        }
        newSolicitud.append("institucion[direccion][detalle]", direccionInstitucion)
        newSolicitud.append("institucion[direccion][municipio_id]", municipioInstitucion)
        newSolicitud.append("institucion[archivo_documento_identidad]", archivoInstitucion)
        newSolicitud.append("responsable[nombres]", nombresResponsable)
        newSolicitud.append("responsable[apellidos]", apellidosResponsable)
        newSolicitud.append("responsable[dui]", duiResponsable)
        newSolicitud.append("responsable[cargo]", cargoResponsable)
        newSolicitud.append("responsable[telefono]", telefonoResponsable)
        newSolicitud.append("responsable[telefono_celular]", celularResponsable)
        newSolicitud.append("responsable[archivo_documento_identidad]", archivoResponsable)
        newSolicitud.append("responsable[responsable_existente]", responsableRepetido ? 1 : 0)
        startLoading()
        const result = await createSolicitudInscripcion(newSolicitud, handleOnProgress)
        if (result.error){ 
            if(result.data){
                if(result.data.error.responsable_existente){
                    setOpenRepetido(true)
                }else{
                    setOpenRepetido(false)
                    handleOpenAlert('error', result.error)
                }   
            }
        }
        else{
            setOpenRepetido(false)
            handleOpenAlert('success', `Se envio la solicitud correctamente.`)
            setTimeout(() => {
                history.push('/')
              }, 6000);
            
        }
        stopLoading()
    }

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        usuarioData: {
            nombreUsuario,
            errorNombreUsuario,
            helperNombreUsuario,
            duiUsuario,
            objArchivoUsuario,
            errorDuiUsuario,
            helperDuiUsuario,
            telefonoUsuario,
            errorTelefonoUsuario,
            helperTelefonoUsuario,
            celularUsuario,
            errorCelularUsuario,
            helperCelularUsuario,
            correoElectronicoUsuario,
            errorCorreoElectronicoUsuario,
            helperCorreoElectronicoUsuario,
            contrasenia1Usuario,
            errorContrasenia1Usuario,
            helperContrasenia1Usuario,
            contrasenia2Usuario,
            errorContrasenia2Usuario,
            helperContrasenia2Usuario,
        },
        institucionData: {
            tiposInstitucion,
            sectores,
            ministerios,
            municipiosCombo,
            departamentosCombo,
            nombreInstitucion,
            errorNombreInstitucion,
            helperNombreInstitucion,
            nitInstitucion,
            objArchivoInstitucion,
            errorNitInstitucion,
            helperNitInstitucion,
            telefonoInstitucion,
            errorTelefonoInstitucion,
            helperTelefonoInstitucion,
            celularInstitucion,
            errorCelularInstitucion,
            helperCelularInstitucion,
            departamentoInstitucion,
            municipioInstitucion,
            direccionInstitucion,
            errorDireccionInstitucion,
            helperDireccionInstitucion,
            tipoInstitucion,
            ministerioInstitucion,
            sectorInstitucion
        },
        responsableData: {
            cargos,
            nombresResponsable,
            errorNombresResponsable,
            helperNombresResponsable,
            apellidosResponsable,
            errorApellidosResponsable,
            helperApellidosResponsable,
            duiResponsable,
            objArchivoResponsable,
            errorDuiResponsable,
            helperDuiResponsable,
            telefonoResponsable,
            errorTelefonoResponsable,
            helperTelefonoResponsable,
            celularResponsable,
            errorCelularResponsable,
            helperCelularResponsable,
            cargoResponsable,
            openRepetido
        },
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        paso,
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        usuarioActions: {
            onChangeNombreUsuario,
            onChangeDuiUsuario,
            handleOnChangeArchivoUsuario,
            onChangeTelefonoUsuario, 
            onChangeCelularUsuario, 
            onChangeCorreoElectronicoUsuario, 
            onChangeContrasenia1Usuario, 
            onChangeContrasenia2Usuario,
        },
        institucionActions: {
            onChangeNombreInstitucion,
            onChangeNitInstitucion,
            handleOnChangeArchivoInstitucion,
            onChangeTelefonoInstitucion,
            onChangeCelularInstitucion,
            setDepartamentoInstitucion,
            setMunicipioInstitucion,
            onChangeDireccionInstitucion,
            setTipoInstitucion,
            setMinisterioInstitucion,
            setSectorInstitucion
        },
        responsableActions: {
            onChangeNombresResponsable,
            onChangeApellidosResponsable,
            onChangeDuiResponsable,
            handleOnChangeArchivoResponsable,
            onChangeTelefonoResponsable,
            onChangeCelularResponsable,
            setCargoResponsable,
            onCreateSolicitud,
            setOpenRepetido,
            handleAcceptDialog
        },
        setAlertOpen
    }

    const handleGetMinisterios = useCallback(async () => {
        startLoading()
        const ministerios = await getMinisteriosSolicitud()
        if(ministerios.error){
            handleOpenAlert('error', ministerios.error)
        }
        else{
            setMinisterios([])
            ministerios.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const ministerioComboBox = {
                    label,
                    value: value.toString()
                }
                setMinisterios((ministerios) => [...ministerios, ministerioComboBox])
            })
        }
        stopLoading()
    }, [getMinisteriosSolicitud])

    const handleGetSectores = useCallback(async () => {
        startLoading()
        const sectores = await getSectoresSolicitud()
        if(sectores.error){
            handleOpenAlert('error', sectores.error)
        }
        else{
            setSectores([])
            sectores.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const sectorComboBox = {
                    label,
                    value: value.toString()
                }
                setSectores((sectores) => [...sectores, sectorComboBox])
            })
        }
        stopLoading()
    }, [getSectoresSolicitud])

    // const handleGetCargos = useCallback(async () => {
    //     startLoading()
    //     const cargos = await getCargosSolicitud()
    //     if(cargos.error){
    //         handleOpenAlert('error', cargos.error)
    //     }
    //     else{
    //         cargos.forEach(({id, nombre}) => {
    //             const label = nombre
    //             const value = id
    //             const cargoComboBox = {
    //                 label,
    //                 value: value.toString()
    //             }
    //             setCargos((cargos) => [...cargos, cargoComboBox])
    //         })
    //     }
    //     stopLoading()
    // }, [getCargosSolicitud])

    const handleGetDepartamentos = useCallback(async () => {
        startLoading()
        const departamentos = await getDepartamentos()
        if(departamentos.error){
            handleOpenAlert('error', departamentos.error)
        }
        else{
            setDepartamentos(departamentos)
            setDepartamentosCombo([])
            departamentos.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const departamentoComboBox = {
                    label,
                    value: value.toString()
                }
                setDepartamentosCombo((departamentosCombo) => [...departamentosCombo, departamentoComboBox])
            })
        }
        stopLoading()
    }, [getDepartamentos])

    //useEffect para cargar combobox de municipios al seleccionar departamento
    useEffect(() => {
        if(Number(paso) === 2){
            setMunicipiosCombo([])
            // console.log('departamentos',departamentos)
            const municipiosDepartamento = departamentos.find( departamento => departamento.id === Number(departamentoInstitucion) )
            // console.log('municipiosDepartamento', municipiosDepartamento)
            municipiosDepartamento && municipiosDepartamento.municipios.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const municipioComboBox = {
                    label,
                    value: value.toString()
                }
                setMunicipiosCombo((municipiosCombo) => [...municipiosCombo, municipioComboBox])
            })
        }
    }, [paso, departamentos, departamentoInstitucion])

    const loadData = useCallback(async () => {
        if(Number(paso) === 2 && ministerios.length === 0 && sectores.length === 0 && departamentos.length === 0){
            await handleGetMinisterios()
            await handleGetSectores()
            await handleGetDepartamentos()
        }
        if(Number(paso) === 3 && cargos.length === 0)
        {
            //await handleGetCargos()
        }
    }, [cargos, departamentos, handleGetDepartamentos, handleGetMinisterios, 
            handleGetSectores, ministerios, paso, sectores])

    //useEffect para cargar datos en la tabla y combobox
    useEffect(() => {
        loadData()
    }, [loadData])

    return {data, actions}
}

export default useSolicitudInscripcionPage