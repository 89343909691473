import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialSwitch from '@material-ui/core/Switch'

const Switch = ({checked, handleChange, label, idValue}) => {
    return (
        <FormControlLabel
            control={<MaterialSwitch checked={checked} 
            onChange={handleChange}
            color="primary"
            id={idValue}
            />}
            label={label}
        />
    )
}

Switch.propTypes = {
    checked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    idValue: PropTypes.string,
}

export default Switch
