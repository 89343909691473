import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useSolicitudCotizacionService from '../../services/solicitudCotizacion'
import Session from 'react-session-api'
import useUsuarioService from '../../services/administracion/useUsuarioService'
import useNuevaSolicitudPage from './useNuevaSolicitudPage'
import useIsLoged from '../utils/useIsLoged'
import url from "./../../services/utils/url"
import useSolicitudInscripcionService from "../../services/inscripcion/useSolicitudInscripcionService";

const useProcesoSolicitudSecretariaClientePage = () => {
    useIsLoged()
    // Importacion de funciones de otros custom hooks
    const {getComentariosByIdSolicitud, getSolicitudById }
        = useSolicitudCotizacionService()
    const { getMe } = useUsuarioService()
    const { actions: actionsNuevaSolicitudPage } = useNuevaSolicitudPage()
    const { crearComentario, uploadFile, handleErrorTipoArchivo } = actionsNuevaSolicitudPage
    const {evaluarSolicitud} = useSolicitudInscripcionService()
    // Obteniendo ID de la solicitud mediante useParams()
    const { id, tipo_comentario } = useParams()

    // Para los mensajes que se ingresen en el input
    const [msgInputValue, setMsgInputValue] = useState("")
    // Almacena la lista de mensajes
    const [messages, setMessages] = useState([])
    const [idRol, setIdRol] = useState("")
    const [tipoSolicitud, setTipoSolicitud] = useState("")
    const [estadoTipoSolicitud, setEstadoTipoSolicitud] = useState("")
    const [loading, setLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    const [archivoUsuario, setArchivoUsuario] = useState(null)
    const [objArchivoUsuario, setObjArchivoUsuario] = useState(null)
    const [openFileModal, setOpenFileModal] = useState(false)
    //Estado para ProgressBar para subir/descargar archivos
    const [progress, setProgress] = useState(-1)
    // Estado para almacenar el nombre del archivo
    const [nombreArchivo, setNombreArchivo] = useState(null)
    const [idEstadoSolicitud, setIdEstadoSolicitud] = useState(null)
    const [idTipoSolicitud, setIdTipoSolicitud] = useState(null)

    // const handleCloseFileModal = () => {
    //     setOpenFileModal(false)
    // }

    // Manejador de mensajes enviados
    const handleSend = async (message) => {
        setMessages((messages) => [...messages, {
            message,
            direction: 'outgoing'
        }])
        setMsgInputValue("")
        // console.log(messages)
        const idUsuario = Session.get('id') //Obteniendo id del usuario logueado
        const mensaje = await crearComentario(message, idUsuario, id, tipo_comentario)
        // console.log("Mensaje --> ", mensaje)
        return mensaje
    }

    // Manejador de alertas
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    const handleOnProgress = (loaded, total) => {
        const progress = (loaded / total) * 100
        setProgress(progress)
    }

    // Funcion para obtener todos los mensajes
    const getMensajes = async () => {
        // Se obtiene y se verifica si el usuario está logueado
        const myUser = await getMe()
        if (myUser.error) {
            setLoading(true)
            handleOpenAlert('error', myUser.error)
            setLoading(false)
            return
        }
        // console.log(myUser)
        const { id: idUsuario, role_id } = myUser
        setIdRol(role_id) // Asignando el id del rol del usuario
        // Obteniendo los mensajes
        const mensajes = await getComentariosByIdSolicitud(id, tipo_comentario)
        setMessages([])
        mensajes.forEach(({ cuerpo, user_id, historial_solicitud, id:id_comentario }) => {
            let direction = 'outgoing'
            if (user_id === idUsuario) {
                direction = 'outgoing'
            } else {
                direction = 'incoming'
            }
            // Verifica si existe un archivo en el comentario
            if(historial_solicitud.length !== 0){
                let resultado = historial_solicitud.find(
                    archivo => archivo.comentario_id === id_comentario
                )
                // Obteniendo el id del archivo
                const {id: id_historial_solicitud} = resultado
                setMessages((messages) => [...messages, {
                    message: cuerpo,
                    payload:  `${url}comentarios/historial/descarga/${id_historial_solicitud}`,
                    direction: direction
                }])
            } else { // Si no existe archivo en el mensaje
                setMessages((messages) => [...messages, {
                    message: cuerpo,
                    direction: direction
                }])
            }
        })
    }

    // Obteniendo el tipo de solicitud y el estado de la solicitud
    const datosProcesoSolicitud = async () => {
        startLoading()
        const datos_solicitud = await getSolicitudById(id)
        const {estado_solicitud, tipo_solicitud} = datos_solicitud[0]
        // console.log("DATOS SOLICITUD --", datos_solicitud)
        // console.log("ESTADO SOLICITUD --", estado_solicitud)
        // console.log("TIPO SOLICITUD --", tipo_solicitud)
        // const {nombre : nombre_estado_solicitud, id : id_estado_solicitud} = estado_solicitud
        // const {nombre : nombre_tipo_solicitud, id : id_tipo_solicitud} = tipo_solicitud
        setEstadoTipoSolicitud(estado_solicitud.nombre)
        setTipoSolicitud(tipo_solicitud.nombre)
        setIdTipoSolicitud(tipo_solicitud.id)
        // Tipos de solicitud
        // 3 -> Inconclusa || 4 -> Aprobado || 5 -> Denegado
        if (estado_solicitud.id === 3 || 4 || 5){
            setIdEstadoSolicitud(Number(estado_solicitud.id))
        }
        stopLoading()
    }

    // Para capturar y mostrar el nombre del archivo en la vista del usuario
    const handleOnChangeArchivoUsuario = (file) => {
        setNombreArchivo(file[0].name)
        setArchivoUsuario(file[0])
        setObjArchivoUsuario([{ name: file[0].name, deleteFile: () => {
            setObjArchivoUsuario(null)
            setArchivoUsuario(null)
            setNombreArchivo(null)
        },
        onClickChip: () => {setOpenFileModal(true)}
        }])
    }

    // Funcion que guarda el archivo
    const sendFile = async () => {
        startLoading()
        // Se obtiene y se verifica si el usuario está logueado
        const myUser = await getMe()
        if (myUser.error) {
            startLoading()
            handleOpenAlert('error', myUser.error)
            stopLoading()
            return
        }
        // Se verifica si existe un archivo
        if(nombreArchivo === null){
            handleOpenAlert('warning', "No se ha seleccionado ningún archivo")
            stopLoading()
            return
        }
         // Verifica el tipo de archivo
         if (handleErrorTipoArchivo(archivoUsuario)) {
            handleOpenAlert('warning', "El tipo de archivo no es válido")
            stopLoading()
            return
        }
        // Creacion de comentario con el nombre del archivo y se obtiene el id del mismo
        const comentario = await handleSend(nombreArchivo)
        const { id: idComentario, tipo_comentario} = comentario
        // Subida y verificacion de resultado del archivo
        const result = await uploadFile(
            archivoUsuario, idComentario, tipo_comentario, handleOnProgress
            )
        if (result.error) {
            handleOpenAlert('error', result.error)
            return
        } else{
            handleOpenAlert('success', "Archivo subido con éxito")
        }
        stopLoading()
    }

    //Para manejar los cambios del RadioButton
    const onChangeRadio = (event) => {
        setIdEstadoSolicitud(Number(event.target.value))
    }

    // Funcion para la secretaria que permite actualizar el estado de la solicitud
    // a aprobada, inconclusa y denegada
    const actualizarEstadoSolicitud = async() => {
        startLoading()
        let cambios = {
            "estado_solicitud": idEstadoSolicitud,
        }
        const actualizarSolicitud = await evaluarSolicitud(id, cambios)
        if (actualizarSolicitud.error){
            handleOpenAlert('error', actualizarSolicitud.error)
            stopLoading()
            return
        } else {
            handleOpenAlert('success', "Estado de la solicitud actualizado con éxito")
        }
        await datosProcesoSolicitud()
        stopLoading()
    }

    const data = {
        msgInputValue, messages, loading, alertOpen,
        alertSeverity, alertMessage, idRol, tipoSolicitud, estadoTipoSolicitud,
        objArchivoUsuario, idEstadoSolicitud, openFileModal, progress, idTipoSolicitud
    }
    const actions = { handleSend, setMsgInputValue, setAlertOpen, handleOnChangeArchivoUsuario,
        sendFile, onChangeRadio, actualizarEstadoSolicitud }

    useEffect(() => {
        /*
        la funcion getMensajes() dentro del if será ejecutada una vez al cargar la página
        por primera vez, luego se estará ejecutando cada 10 segundos mediante la función
        window.setInterval()
        */
        let contador = 0
        if (contador === 0) {
            getMensajes()
            contador++
        }
        datosProcesoSolicitud()
        // Cada 10 segundos se va a ejecutar la funcion (10000ms = 10s)
        window.setInterval(getMensajes, 10000)
    }, [])

    return { data, actions }
}

export default useProcesoSolicitudSecretariaClientePage
