import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'//s r

const dosimetroUrl = `${url}dosimetros`

const useDosimetroService = () => {
    const history = useHistory(); //s r Definir history aquí
    const { errorHandler } = useErrorHandler()

    const createDosimetro = useCallback(
        async (dosimetro) => {
            try {
                
               const response = await axios.post(dosimetroUrl, dosimetro, 
                    {headers: headers()})
                    
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getDosimetroById = useCallback(
        async (dosimetro) => {
            const requestUrl = `${dosimetroUrl}/${dosimetro}`
            console.log(requestUrl)
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                console.log(requestUrl)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getDosimetrosByStatus = useCallback(
        async (status, page) => {
            const requestUrl = `${dosimetroUrl}/status/${status}?page=${page}`
            console.log(requestUrl)
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                console.log(response)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getEstadosDosimetros = useCallback(
        async () => {
            const requestUrl = `${url}estadosdosimetro`
            console.log(requestUrl)
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                console.log(response)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const updateDosimetro = useCallback(
        async (dosimetro, cambios) => {
            const requestUrl = `${dosimetroUrl}/${dosimetro}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {headers: headers()})
                const { data } = response
                // Redireccionar a otra página después de la actualización exitosa
                history.push('./'); //s r Cambia '/ruta-deseada' por la ruta a la que quieres redireccionar

                return data
            } catch (error) {
                // Redireccionar a otra página después de la actualización exitosa
                history.push('./'); //s r Cambia '/ruta-deseada' por la ruta a la que quieres redireccionar
                return errorHandler(error)
            }
        },
        [errorHandler,history],//s r agregamos history
        
    )
    
    const deleteDosimetro = useCallback(
        async (dosimetro) => {
            const requestUrl = `${dosimetroUrl}/${dosimetro}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createDosimetro, getDosimetroById, getDosimetrosByStatus, 
        getEstadosDosimetros, updateDosimetro, deleteDosimetro }
}

export default useDosimetroService