import React, { useState, useEffect  } from "react"
import Alert from "../../components/Alert/Alert";
import AppFrame from "../../components/AppFrame/AppFrame";
import Progress from "../../components/Progress/Progress";
import usePlanificacionTecnicoPage from "../../hooks/planificacionDosimetria/usePlanificacionTecnicoPage";



import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import "react-day-picker/lib/style.css"
import BigCalendar from "../../components/BigCalendar/BigCalendar"
import TabPanel from "../../components/TabPanel"
import Dialog from "../../components/Dialog/Dialog";
import usePlanificacionCCPage from "../../hooks/controlDeCalidad/useActividadesControlCalidadPage";
import ActividadCCConsultar from "./actividadCCConsultar";


const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
    //marginBottom: ".5em",
  },
  root: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}))

const ActividadControlCalidad = (props) => {

  const classes = useStyles()

  // const [value, setValue] = useState(0)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }

  // const { data: data2, actions: actions2 } = usePlanificacionTecnicoPage()
  // const {
  //   allEvents,
  // } = data2
  // const {
  // } = actions2

//   useEffect(() => {
//     console.log('allEvents:', allEvents);
//     console.log('handleSelect:', handleSelect);
// }, []);

  const { data, actions, others } = usePlanificacionCCPage()
  const {
    loading,
    alertOpen,
    alertSeverity,
    alertMessage,
    openResearch,
    fechaInicioEvento,
    allEvents,
  } = data
  const {
    startLoading,
    stopLoading,
    handleOpenAlert,
    setAlertOpen,
    handleClickOpenResearch,
    handleSelect,
  } = actions



  return (

    <AppFrame loged={false}>
      <Progress open={loading > 0} />
      <Alert
        open={alertOpen}
        setOpen={setAlertOpen}
        severity={alertSeverity}
        message={alertMessage}
      />
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
                <BigCalendar events={allEvents} handleSelect={handleSelect} />
                {/* <BigCalendar events={allEvents} handleSelect={handleSelect} /> */}
                {/* Para los botones */}
                {/* <Grid container item direction="row" justifyContent="center" spacing={2}>
                              <Grid className={classes.input} item xs={4}>
                                  <Button
                                      component={RouterLink}
                                      to={"/inscripcion_parte_1"}
                                      variant="outlined"
                                      text="CANCELAR"
                                  />
                              </Grid>
                              <Grid className={classes.input} item xs={4}>
                                  <Button
                                      component={RouterLink}
                                      to={"/inscripcion_parte_3"}
                                      variant="contained"
                                      text="SIGUIENTE"
                                  />
                              </Grid>
                          </Grid> */}
                {/* Fin Grid de botones */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/* MODAL PARA CONSULTAR */}
      <Dialog
          open={openResearch}
          handleClose={handleClickOpenResearch}
          title={"Evento del " + fechaInicioEvento}
          content={
              <ActividadCCConsultar data={data} actions={actions}/>
          }
      />
    </AppFrame>

  );
};


export default ActividadControlCalidad;
