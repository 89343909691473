import AppFrame from '../../../components/AppFrame'
import FormHeader from '../../../components/FormHeader'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import Grid from '@material-ui/core/Grid'
import { BsFillFileEarmarkWordFill } from 'react-icons/bs'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import ComboBox from '../../../components/ComboBox'
import DataTable from '../../../components/DataTable'
import Dialog from '../../../components/Dialog/Dialog'
import useConfiguracionReporteLecturaPage from '../../../hooks/asignacionDosimetria/useConfiguracionReporteLecturaPage'
import Alert from '../../../components/Alert'
import Progress from '../../../components/Progress/Progress'
import Pagination from '../../../components/Pagination'
//import useCheckPermiso from '../hooks/useCheckPermiso'
//import { PermisoDenegado } from '../components/PermisoDenegado/PermisoDenegado'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    div: {
        display: 'inline-block'
    }
}))

const encabezado = ["Código", "Nombre del factor", "Tipo", "Valor"]

const ConfiguracionReporteLecturaPage = () => {
    const classes = useStyles()
    const {data, actions} = useConfiguracionReporteLecturaPage()
    const {
        loading,
        alertOpen,
        alertSeverity,
        alertMessage,
        tiposDosimetro,
        codigoFactor,
        errorCodigoFactor,
        helperCodigoFactor,
        nombreFactor,
        tipoFactor,
        valorFactor,
        rows,
        page,
        numPages,
        openDelete,
        factorToDelete,
        editar,
    } = data

    const {
        setAlertOpen,
        onChangeCodigoFactor,
        onChangeNombreFactor,
        onChangeTipoFactor,
        onChangeValorFactor,
        handlePageChange,
        handleCloseDelete,
        onDeleteFactor,
        setFactorToDelete,
        findRow,
        handleOnChangeEditar,
        cancel,
        saveConfiguracionReporteLectura,
    } = actions

    return (
        <AppFrame >
            <Progress open = {loading}/>
            {
                //1 es el id del permiso gestion ministerio
               // useCheckPermiso(1) ? loged = {false}
                <Grid container direction = "row" justifyContent = "center">
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.paper}>
                            <Alert open = {alertOpen} setOpen={setAlertOpen}
                                severity = {alertSeverity}
                                message = {alertMessage}/>
                            <FormHeader title="Dosimetría"
                                subtitle="Configuración reporte lectura para archivos .docx">
                                <BsFillFileEarmarkWordFill/>
                            </FormHeader>
                            <Grid container direction="row" justifyContent="center" spacing = {2}>
                                <Grid container item xs = {11}>
                                    <Grid className={classes.input} item xs = {6}>
                                        <Input type = "text" label="Código del factor"
                                            placeholder="Ingrese el código del factor"
                                            value = {codigoFactor}
                                            onChange={onChangeCodigoFactor}
                                            error = {errorCodigoFactor}
                                            helper = {helperCodigoFactor}
                                            required={true}
                                            />
                                    </Grid>
                                    <Grid className={classes.input} item xs = {6}>
                                        <Input type = "text" label="Nombre del factor"
                                               placeholder="Ingrese el nombre del factor"
                                               value={nombreFactor}
                                               onChange={onChangeNombreFactor}
                                               required={true}
                                        />
                                    </Grid>
                                    <Grid className={classes.input} item xs = {6}>
                                        <ComboBox
                                            label="Tipo de dosimetro"
                                            list={tiposDosimetro}
                                            value={tipoFactor}
                                            onChange={(value)=>{onChangeTipoFactor(value)}}
                                            required={true}
                                            />
                                    </Grid>
                                    <Grid className={classes.input} item xs = {6}>
                                        <Input type = "Number" label="Valor del factor"
                                               placeholder="Ingrese el valor del factor"
                                               value = {valorFactor}
                                               onChange={onChangeValorFactor}
                                               required={true}
                                               min={1}
                                        />
                                    </Grid>
                                    <Grid container item direction="row"
                                        justifyContent="center" spacing = {2}>
                                        <Grid className={classes.input} item 
                                            xs = {6} sm={6} md= {3}>
                                            <Button
                                                func = {cancel}
                                                variant = "outlined"
                                                text="CANCELAR"/>
                                        </Grid>
                                        <Grid className={classes.input} item
                                            xs = {6} sm={6} md={3}>
                                            <Button
                                                variant = "contained"
                                                text={editar?"EDITAR":"AGREGAR"}
                                                func={editar? ()=>{} : saveConfiguracionReporteLectura}
                                            />
                                            {/*<Dialog open={openEdit} */}
                                            {/*    handleClose = {handleCloseEdit} */}
                                            {/*    handleAccept = {onUpdateDosimetro} */}
                                            {/*    title = {"Desea actualizar el registro?"} */}
                                            {/*    content = {*/}
                                            {/*        findRow(codigoEditar, rows) ?*/}
                                            {/*        `Los datos del dosimetro con codigo "${codigo}" se actualizaran.`*/}
                                            {/*        :*/}
                                            {/*        "Error al cargar datos."*/}
                                            {/*    }/>*/}
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.table} item xs={12}>
                                        <DataTable headers={encabezado} 
                                            rows={rows}
                                            //editFnc={(key)=> handleOnChangeEditar(key)}
                                        />
                                    </Grid>
                                    <Grid item xs = {12}>
                                        <Pagination page={Number(page)}
                                            numPages={Number(numPages)}
                                            onChangePage = {handlePageChange}
                                            />
                                    </Grid>
                                    <Dialog open={openDelete} 
                                        handleClose = {handleCloseDelete} 
                                        handleAccept = {onDeleteFactor}
                                        title = {"Desea eliminar el registro?"} 
                                        content = { 
                                            findRow(factorToDelete, rows) ?
                                            `El factor con codigo "${ findRow(factorToDelete, rows).cells[0] }" sera desactivado del sistema.`
                                            :
                                            ""
                                            }
                                        transitionFunc = {() => 
                                            setFactorToDelete(null)}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
               // :
                //<PermisoDenegado/>
            }
        </AppFrame>
    )
}

export default ConfiguracionReporteLecturaPage