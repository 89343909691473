import React, { useRef } from 'react'
import AppFrame from '../../components/AppFrame'
import FormHeader from '../../components/FormHeader'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import {RiAdminLine} from 'react-icons/ri'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import ComboBox from '../../components/ComboBox'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog/Dialog'
import useDosimetroPage from '../../hooks/administracion/useDosimetroPage'
import Alert from '../../components/Alert'
import Barcode from '../../components/Barcode'
import Progress from '../../components/Progress/Progress'
import Pagination from '../../components/Pagination'
import { FaTrashRestoreAlt } from 'react-icons/fa'
import Switch from '../../components/Switch'
import html2canvas from 'html2canvas'
//import useCheckPermiso from '../hooks/useCheckPermiso'
//import { PermisoDenegado } from '../components/PermisoDenegado/PermisoDenegado'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    div: {
        display: 'inline-block'
    }
}))

const encabezado = ["Codigo", "Cantidad de Cristales", "Tipo de Dosimetro", "Estado"]

const DosimetroPage = () => {
    const classes = useStyles()
    const {data, actions} = useDosimetroPage()
    const {rows,
        tiposDosimetro,
        estadosDosimetro,
        codigo,
        errorCodigo,
        helperCodigo,
        tipoDosimetro,
        cantidadCristales,
        errorCantidadCristales,
        helperCantidadCristales,
        estadoDosimetro,
        codigoEditar,
        openEdit,
        openDelete,
        dosimetroToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        verDesactivados,
        loading} = data

    const {onDeleteDosimetro,
        onCreateDosimetro,
        onUpdateDosimetro,
        editClick,
        onChangeCodigo,
        onChangeCantidadCristales,
        setTipoDosimetro,
        setEstadoDosimetro,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setDosimetroToDelete,
        setAlertOpen,
        handlePageChange,
        handleDesactivadosChange} = actions
    
        const inputEl = useRef(null)
        const dowloadBarcode = () => {
            html2canvas(inputEl.current).then(canvas => {
                const image = canvas.toDataURL("image/png")
                var aDownloadLink = document.createElement('a')
                aDownloadLink.download = `Barcode dosimetro ${codigo}.png`
                aDownloadLink.href = image
                aDownloadLink.click()
            })
        }
    return (
        <AppFrame >
            <Progress open = {loading>0}/>
            {
                //1 es el id del permiso gestion ministerio
               // useCheckPermiso(1) ? loged = {false}
                <Grid container direction = "row" justifyContent = "center">
                    <Grid item xs={12} sm={7}>
                        <Paper className={classes.paper}>
                            <Alert open = {alertOpen} setOpen={setAlertOpen}
                                severity = {alertSeverity} 
                                message = {alertMessage}/>
                            <FormHeader title="Dosimetria"
                                subtitle="Dosimetro">
                                <RiAdminLine/>
                            </FormHeader>
                            <Grid container direction="row" justifyContent="center"
                                spacing = {2}>
                                <Grid container item xs = {11}>
                                    <Grid className={classes.input} item xs = {12}>
                                        <Input type = "text" label="Codigo del 
                                        dosimetro" 
                                            placeholder="Ingrese el codigo del 
                                            dosimetro"
                                            value = {codigo}
                                            disabled = {
                                                codigoEditar ?
                                                true
                                                :
                                                false
                                            }
                                            onChange={
                                                !codigoEditar ?
                                                onChangeCodigo
                                                :
                                                () => {}
                                            }
                                            error = {errorCodigo}
                                            helper = {helperCodigo}
                                            required={true}
                                            />
                                    </Grid>
                                    <Grid className={classes.input} item xs = {12}>
                                        {
                                            codigo.length > 0 ?
                                            <div>
                                                <div className = {classes.div} ref={inputEl}>
                                                    <Barcode codigo = {codigo}/>
                                                </div>
                                                <Button
                                                    variant = "contained"
                                                    text="Guardar codigo"
                                                    func = {dowloadBarcode}
                                                    />
                                            </div>
                                            :
                                            null
                                        }
                                    </Grid>
                                    <Grid className={classes.input} item xs = {12}>
                                        <ComboBox 
                                            label="Tipo de dosimetro"
                                            list={tiposDosimetro}
                                            value={tipoDosimetro}
                                            onChange={(value)=>{setTipoDosimetro(value)}}
                                            required={true}
                                            />
                                    </Grid>
                                    <Grid className={classes.input} item xs = {12}>
                                        <Input type = "Number" label="Cantidad de Cristales" 
                                            placeholder="Ingrese la cantidad de cristales
                                            que posee un dosimetro"
                                            value = {cantidadCristales}
                                            onChange={onChangeCantidadCristales}
                                            error = {errorCantidadCristales}
                                            helper = {helperCantidadCristales}
                                            required={true}
                                            min={1}
                                            />
                                    </Grid>
                                    <Grid className={classes.input} item xs = {12}>
                                        <ComboBox 
                                            label="Estado del dosimetro"
                                            list={estadosDosimetro}
                                            value={estadoDosimetro}
                                            onChange={(value)=>{setEstadoDosimetro(value)}}
                                            required={true}
                                            />
                                    </Grid>
                                    <Grid container item direction="row"
                                        justifyContent="center" spacing = {2}>
                                        <Grid className={classes.input} item 
                                            xs = {6} sm={6} md= {3}>
                                            <Button
                                                func = {
                                                    codigoEditar?
                                                    editCancel
                                                    :
                                                    cancel
                                                }
                                                variant = "outlined"
                                                text="CANCELAR"/>
                                        </Grid>
                                        <Grid className={classes.input} item
                                            xs = {6} sm={6} md={3}>
                                            <Button
                                                variant = "contained"
                                                text={codigoEditar?"EDITAR":"AGREGAR"}
                                                func = {codigoEditar?
                                                    handleClickOpenEdit
                                                    :
                                                    onCreateDosimetro}
                                                />
                                            <Dialog open={openEdit} 
                                                handleClose = {handleCloseEdit} 
                                                handleAccept = {onUpdateDosimetro} 
                                                title = {"Desea actualizar el registro?"} 
                                                content = {
                                                    findRow(codigoEditar, rows) ?
                                                    `Los datos del dosimetro con codigo "${codigo}" se actualizaran.`
                                                    :
                                                    "Error al cargar datos."
                                                }/>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction = "row" 
                                        justifyContent = "flex-end" item xs={12}>
                                        <Grid item>
                                            <Switch checked={verDesactivados} 
                                                handleChange = {handleDesactivadosChange} 
                                                label = "Registros desactivados"
                                                />
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.table} item xs={12}>
                                        <DataTable headers={encabezado} 
                                            rows={rows}
                                            editFnc={ !verDesactivados ?
                                                (key)=>
                                                editClick(key)
                                                :
                                                undefined
                                            }
                                            deleteFnc={!verDesactivados ?
                                                (key)=>
                                                handleClickOpenDelete(key)
                                                :
                                                undefined
                                            }
                                            customBtn = {
                                                verDesactivados ?
                                                [
                                                    {
                                                        func: (key)=>
                                                            handleClickOpenDelete(key),
                                                        icon: FaTrashRestoreAlt,
                                                        tooltip: "Reactivar"
                                                    }
                                                ]
                                                : 
                                                undefined
                                                
                                            }/>
                                    </Grid>
                                    <Grid item xs = {12}>
                                        <Pagination page={Number(page)}
                                            numPages={Number(numPages)}
                                            onChangePage = {handlePageChange}
                                            />
                                    </Grid>
                                    <Dialog open={openDelete} 
                                        handleClose = {handleCloseDelete} 
                                        handleAccept = {onDeleteDosimetro} 
                                        title = {"Desea eliminar el registro?"} 
                                        content = { 
                                            findRow(dosimetroToDelete, rows) ?
                                            `El dosimetro con codigo "${ findRow(dosimetroToDelete, rows).cells[0] }" sera desactivado del sistema.`
                                            :
                                            ""
                                            }
                                        transitionFunc = {() => 
                                            setDosimetroToDelete(null)}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
               // :
                //<PermisoDenegado/>
            }
        </AppFrame>
    )
}

export default DosimetroPage