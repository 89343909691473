import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useSectorService from '../../services/administracion/useSectorService'
import { addRow, findRow, updateRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
import { allLetterAndSpace } from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'

const useSectorPage = () => {
    const { createSector, getSectorById, getSectoresByStatus, 
        updateSector, deleteSector } = useSectorService()

    const {id} = useParams()
    useIsLoged()
    const history = useHistory()
    const [rows, setRows] = useState([])
    const [nombre, setNombre] = useState('')
    const [errorNombre, setErrorNombre] = useState(false)
    const [helperNombre, setHelperNombre] = useState('')
    
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [sectorToDelete, setSectorToDelete] = useState(null)

    //Estado para ver registros con estado = false (desactivados)
    const [verDesactivados, setVerDesactivados] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    const [loading, setLoading] = useState(0)
    
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    const handleDesactivadosChange = () => {
        setVerDesactivados(
            (verDesactivados) => !verDesactivados
        )
    }

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setSectorToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const onChangeNombre = (text)=>
    {
        setNombre(text)
        if(!allLetterAndSpace(text) && text !== ""){
            setErrorNombre(true)
            setHelperNombre("El nombre debe consistir solo de letras.")
        }else{
            setErrorNombre(false)
            setHelperNombre("")
        }
    }

    const checkForErrors = () => {
        if (errorNombre || nombre === "")
        {
            return true
        }
        return false
    }

    const onCreateSector = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const newSector = {
            "nombre": nombre
        }
        startLoading()
        const result = await createSector(newSector)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            //const cell = [nombre]
            //const key = id
            //addRow(key, cell, setRows)
            getSectores()
            handleOpenAlert('success', `La sector "${nombre}" se creo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onUpdateSector = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const sectorActualizado = {
            "nombre": nombre
        }
        startLoading()
        const result = await updateSector(id, sectorActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre} = result
            const newCells = [nombre]
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `La sector "${nombre}" se actualizo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onDeleteSector = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteSector(sectorToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const { nombre} = result
            //deleteRow(id, setRows)
            getSectores()
            editCancel()
            handleOpenAlert('success', `La sector "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    const getSector = useCallback(async (id) => {
        startLoading()
        const sector = await getSectorById(id)
        if (sector.error){
            handleOpenAlert('error', sector.error)
        }
        else{
            setNombre(sector.nombre)
        }
        stopLoading()
    }, [getSectorById])

    const getSectores = useCallback(async () => {
        const estado = verDesactivados ? 0 : 1
        startLoading()
        const sectores = await getSectoresByStatus(estado, page)
        if (sectores.error){
            handleOpenAlert('error', sectores.error)
        }
        else{
            setRows([])
            setNumPages(sectores.last_page)
            sectores.data.forEach(({id, nombre}) => {
                const key = id
                const cell = [nombre]
                addRow(key, cell, setRows)
            })
            if(page > sectores.last_page){
                setPage(sectores.last_page)
                history.push(`${window.location.pathname}?page=${sectores.last_page}`)
            }
        }
        stopLoading()
    }, [getSectoresByStatus, history, page, verDesactivados])

    const editClick = (id) => {
        history.push(`/admin/sector/${id}?page=${page}`)
    }

    const editCancel = () => {
        cancel()
        history.push(`/admin/sector?page=${page}`)
    }

    const cancel = () => {
        setNombre("")
        setErrorNombre(false)
    }

    const data = {
        rows,
        nombre,
        errorNombre,
        helperNombre,
        id,
        openEdit,
        openDelete,
        sectorToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        verDesactivados,
        loading
    }

    const actions = {
        onDeleteSector,
        onCreateSector,
        onUpdateSector,
        editClick,
        onChangeNombre,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setSectorToDelete,
        setAlertOpen,
        handlePageChange,
        handleDesactivadosChange
    }

    //useEffect para cargar datos en la tabla
    useEffect(() => {
        getSectores()
    }, [getSectores])
    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getSector(id)
        }
    }, [id, getSector])
    return {data, actions}
}

export default useSectorPage