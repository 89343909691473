import React from 'react'
import Grid from '@material-ui/core/Grid'
import {useState} from 'react'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import LecturasSinIniciarPage from "./LecturasSinIniciarPage";
import LecturasFinalizadasPage from "./LecturasFinalizadasPage";

const LecturasPage = (props) => {

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Grid direction="row" justifyContent="center">
            <Grid item xs={12}>
                    <Tabs value={value} onChange={(event, newValue) => handleChange(event, newValue)}
                          indicatorColor="primary"
                          textColor="primary" centered>
                        <Tab label="Lecturas sin iniciar"/>
                        <Tab label="Lecturas finalizadas"/>
                    </Tabs>
                    <Grid item xs={12}>
                        <TabPanel value={value} index={0}>
                            <LecturasSinIniciarPage />
                        </TabPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={value} index={1}>
                            <LecturasFinalizadasPage />
                        </TabPanel>
                    </Grid>
            </Grid>
        </Grid>
    )
}

export default LecturasPage