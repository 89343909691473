import React from 'react'
import AppFrame from './../../components/AppFrame'
import FormHeader from './../../components/FormHeader'
import Input from './../../components/Input'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import FormDivider from './../../components/FormDivider'
import ComboBox from './../../components/ComboBox'
import DataTable from './../../components/DataTable'
import { makeStyles } from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import ReactSelect from '../../components/ReactSelect'
import useDatosAreasPage from '../../hooks/inscripcion/useDatosAreasPage'
import Dialog from '../../components/Dialog'
import Progress from '../../components/Progress/Progress'
import Alert from '../../components/Alert'
import Pagination from '../../components/Pagination'
import { StepperInscripcion } from './DatosDelegadoPage'
import {MaskField} from "react-mask-field";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    table: {
        marginTop: '2em'
    },
    stepper: {
        backgroundColor: 'transparent'
    }
}))

//TODO preguntar periodos
const listaPeriodos = [
    {
        label: "Cada mes",
        value: "Cada mes"
    },
    {
        label: "Cada dos meses",
        value: "Cada dos meses"
    },
    {
        label: "Cada seis meses",
        value: "Cada seis meses"
    }
]

const encabezado = ["Area", "Telefono", "Celular",
    "Periodo de control"]

function CustomMaskNumberField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="____-____" replacement={{ _: /\d/ }} {...otherProps} />;
}

const DatosAreasPage = props => {
    const classes = useStyles()

    const { data, actions } = useDatosAreasPage()

    const {
        rows,
        nombre,
        errorNombre,
        helperNombre,
        telefono,
        errorTelefono,
        helperTelefono,
        celular,
        errorCelular,
        helperCelular,
        periodoControl,
        id,
        openEdit,
        openDelete,
        areaToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        instalacion,
        idSolicitud
    } = data

    const {
        onCreateArea,
        onUpdateArea,
        onDeleteArea,
        editClick,
        onChangeNombre,
        onChangeTelefono, 
        onChangeCelular, 
        setPeriodoControl,
        setInstalacion,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setAreaToDelete,
        setAlertOpen,
        handlePageChange,
        handleGetInstalaciones,
        handleChangeEstadoDisponible
    } = actions

    return (
        <AppFrame loged = {false}>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm= {8}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Datos internos | Areas">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {10}>
                                <FormDivider text="Datos de la area"/>
                                <Grid className={classes.input} item xs = {12}>
                                    <ReactSelect value = {instalacion}
                                        onChange={setInstalacion}
                                        asyncFunc = {handleGetInstalaciones}
                                        label="Instalacion"
                                        placeholder = "Busque instalacion por nombre"
                                        disabled = {id ? true : false}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <Input type = "text" label="Nombre del 
                                        area" 
                                        placeholder="Ingrese el nombre del 
                                        area"
                                        value = {nombre}
                                        onChange={onChangeNombre}
                                        error = {errorNombre}
                                        helper = {helperNombre}
                                        />
                                </Grid>
                                <Grid className={classes.input} xs = {12} sm={6}>
                                    <ComboBox label = "Periodo de control"
                                        value={periodoControl}
                                        list = {listaPeriodos}
                                        onChange = {(value)=>
                                            setPeriodoControl(value)}/>
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <Input type = "text" label="Telefono" 
                                        placeholder="Ingrese el telefono del area"
                                        value = {telefono}
                                        onChange={onChangeTelefono}
                                        error = {errorTelefono}
                                        helper = {helperTelefono}
                                           InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <Input type = "text" 
                                        label="Telefono celular" 
                                        placeholder="Ingrese el telefono celular 
                                        del area"
                                        value = {celular}
                                        onChange={onChangeCelular}
                                        error = {errorCelular}
                                        helper = {helperCelular}
                                           InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                                <Grid container item direction="row"
                                    justifyContent="center" spacing = {2}>
                                    <Grid className={classes.input} item 
                                        xs={5} sm= {3}>
                                        <Button
                                            func = {
                                                id?
                                                editCancel
                                                :
                                                cancel
                                            }
                                            variant = "outlined"
                                            text="CANCELAR"/>
                                    </Grid>
                                    <Grid className={classes.input} item
                                        xs={5} sm= {3}>
                                        <Button
                                            variant = "contained"
                                            text={id?"EDITAR":"AGREGAR"}
                                            func = {id?
                                                handleClickOpenEdit
                                                :
                                                onCreateArea}
                                            />
                                        <Dialog open={openEdit} 
                                            handleClose = {handleCloseEdit} 
                                            handleAccept = {onUpdateArea} 
                                            title = {"Desea actualizar el registro?"} 
                                            content = {`Los datos del area "${nombre}" se actualizaran.`}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid className={classes.table} container>
                        <Grid item xs = {12}>
                            <DataTable headers={encabezado} rows={rows}
                                editFnc={(key) => editClick(key)}
                                deleteFnc={idSolicitud ? null :(key) => handleClickOpenDelete(key)}
                                disableFnc={idSolicitud ? handleChangeEstadoDisponible : null}/>
                        </Grid>
                        <Grid item xs = {12}>
                            <Pagination page={Number(page)}
                                numPages={Number(numPages)}
                                onChangePage = {handlePageChange}
                                />
                        </Grid>
                        <Dialog open={openDelete} 
                            handleClose = {handleCloseDelete} 
                            handleAccept = {onDeleteArea} 
                            title = {"Desea eliminar el registro?"} 
                            content = { 
                                findRow(areaToDelete, rows) ?
                                `El area con nombre "${ findRow(areaToDelete, rows).cells[0] }" sera eliminado del sistema.`
                                :
                                ""
                                }
                            transitionFunc = {() => 
                                setAreaToDelete(null)}/>
                    </Grid>
                    <Grid item xs = {12} container direction="row"
                        justifyContent="center" spacing = {2}>
                        <Grid className={classes.input} item 
                            xs={5} sm= {3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/instalaciones'}
                                variant = "outlined"
                                text="ANTERIOR"/>
                        </Grid>
                        <Grid className={classes.input} item
                            xs={5} sm= {3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/personal'}
                                variant = "contained"
                                text="SIGUIENTE"
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <StepperInscripcion activeStep={2}/>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default DatosAreasPage
