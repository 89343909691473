import {useCallback, useEffect, useState} from "react"
import useTecnicoPlanificacionService from "../../services/tecnicoPlanificacion"
import useAreaService from "../../services/area"
import useInstalacionService from "../../services/instalacion"
import useInstitucionService from "../../services/instituciones"
import {addRow} from "../../utils/rows"
import {useParams} from "react-router-dom"
import format from "date-fns/format"
import useIsLoged from '../utils/useIsLoged'

const usePlanificacionTecnicoPage = () => {
  useIsLoged()
  const { createEvento, deleteEvento, getEventoById,
    updateEvento, getEventosByStatus } = useTecnicoPlanificacionService()
  const { getAreasByIdInstalacion } = useAreaService()
  const { getInstalacionById } = useInstalacionService()
  const { getInstitucionesByStatus, getInstalacionesByInstitucionId } = useInstitucionService()

  const { id } = useParams()
  const [error, setError] = useState(null)
  const [rows, setRows] = useState([])

  //Estado que almacena todos los eventos
  const [allEvents, setAllEvents] = useState([])

  // Para almacenar el nombre con el id del dato seleccionado en el combobox
  const [selectedInstitucion, setSelectedInstitucion] = useState(null)
  const [selectedInstalacion, setSelectedInstalacion] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)

  // Para la capturar la fecha de inicio y fecha de fin del evento
  const [fechaInicioEvento, setFechaInicioEvento] = useState("")
  const [fechaEvento, setFechaEvento] = useState("")

  // Para la seleccion del tipo de evento
  const [seleccion, setSeleccion] = useState("")

  // Para almacenar el id de un evento a consultar
  const [idEventoConsultar, setIdEventoConsultar] = useState("")

  // Para almacenar el id de un evento a editar
  const [idEventoEditar, setIdEventoEditar] = useState()

  // Para los campos de un evento en especifico
  const [institucion, setInstitucion] = useState("")
  const [instalacion, setInstalacion] = useState("")
  const [area, setArea] = useState("")
  const [personaPresentada, setPersonaPresentada] = useState("")
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [dui, setDui] = useState("")
  const [licencia, setLicencia] = useState("")
  const [pasaporte, setPasaporte] = useState("")

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState("info")
  const [alertMessage, setAlertMessage] = useState("")

  //Estados para dialogos consultar, aditar, creacion y eliminacion
  const [openResearch, setOpenResearch] = useState(false)
  const [show, setShow] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [eventoToDelete, setEventoToDelete] = useState(null)
  const [loading, setLoading] = useState(0)

  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity)
    setAlertMessage(message)
    setAlertOpen(true)
  }

  // Para indicar cuando se está consumiendo la API
  const startLoading = () => {
    setLoading(true)
  }

  const stopLoading = () => {
    setLoading(false)
  }

  // Controlador que abre el modal para consultar los eventos en una fecha
  const handleClickOpenResearch = () => {
    setOpenResearch(!openResearch)
    // Si consultar evento está abierto, lo cierra
    if (show === true) {
      setShow(false)
    }
  }

  // Controlador que abre el modal para crear un evento
  const handleClickOpenCreate = () => {
    setOpenCreate(true)
  }

  // Controlador que cierra el modal para crear un evento
  const handleClickCloseCreate = () => {
    setOpenCreate(false)
    cancel()
  }

  // Controlador que abre el modal de editar un evento
  const handleClickOpenEdit = (id) => {
    setOpenEdit(true)
    getEvento(id)
    setIdEventoEditar(id)
  }
  // Controlador que cierra el modal de editar un evento
  const handleClickCloseEdit = () => {
    setOpenEdit(false)
    cancel()
  }

  // Controlador que abre el modal de eliminar un evento
  const handleClickOpenDelete = (id) => {
    setEventoToDelete(id)
    setOpenDelete(true)
  }

  // Controlador que cierra el modal de eliminar un evento
  const handleClickCloseDelete = () => {
    setOpenDelete(false)
  }

  // Funcion para consular los datos de un evento
  const showMore = (key) => {
    setShow(!show)
    setIdEventoConsultar(key)
  }

  // Para limpiar los estados
  const cancel = () => {
    setSeleccion("")
    setSelectedInstitucion(null)
    setSelectedInstalacion(null)
    setSelectedArea(null)
    setIdEventoConsultar("")
    setIdEventoEditar("")
  }

  // Funcion que se ejecuta al seleccionar una fecha del calendario
  const handleSelect = async ({ start }) => {
    // Opciones para el formato de fecha del modal
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    }
    // console.log("Abriendo modal", start.toLocaleDateString("es", options))
    //Cuando usas un setState, el estado va a cambiar hasta despues de hacer un rerenderizacion
    setFechaInicioEvento(start.toLocaleDateString("es", options))
    setFechaEvento(format(start, "yyyy-MM-dd"))
    //Por en este console.log el estado de fechaEvento va a ser el anterior a la rerenderizacion
    // console.log("Fecha evento 1 ", fechaEvento)
    //await getEventosByDate(fechaEvento)
    //Siempre pone un await en las funciones que usan cosas asincronas, asi el
    //modal se va a abrir hasta que ya haya respuesto el servidor con los datos
    //y no mostrar el modal vacio
    await getEventosByDate(format(start, "yyyy-MM-dd"))
    handleClickOpenResearch() //Abriendo modal de consultar
  }

  // Funcion que devuelve la fecha de inicio de un evento
  const getFechaInicioEvento = () => {
    return fechaInicioEvento
  }

  // Funcion que almacena el id de la seleccion de una institicion
  const handleChangeInstitucion = (value) => {
    if (value == null) {
      // setSelectedValueInstitucion(null)
      setSelectedInstitucion(null)
      setSelectedInstalacion(null)
      setSelectedArea(null)
    } else {
      // setSelectedValueInstitucion(value.value)
      setSelectedInstitucion(value)
      setSelectedInstalacion(null)
      setSelectedArea(null)
    }
  }
  // Funcion que almacena el id de la seleccion de una instalacion
  const handleChangeInstalacion = (value) => {
    if (value == null) {
      // setSelectedValueInstalacion(null)
      setSelectedInstalacion(null)
      setSelectedArea(null)
    } else {
      // setSelectedValueInstalacion(value.value)
      setSelectedInstalacion(value)
      setSelectedArea(null)
    }
  }
  // Funcion que almacena el id de la seleccion de un area
  const handleChangeArea = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedArea(null)
    } else {
      // setSelectedValueArea(value.value)
      setSelectedArea(value)
    }
  }

  // Funcion que filtra los datos de acuerdo a un valor escrito
  const dataFiltrada = useCallback(async (data, value) => {
    // Devuelve los datos filtrados por el nombre
    const results = data.filter((resp) =>
      resp.nombre.toLowerCase().includes(value)
    )
    let options = []
    results.forEach(({ id, nombre }) => {
      const value = id
      const label = nombre
      const resultado = {
        label,
        value: value.toString(),
      }
      options = [...options, resultado]
    })
    // console.log(options)
    return options
  }, [])

  // Funcion que filtra los datos de acuerdo a una fecha
  const dataFiltradaFecha = useCallback(async (data, value) => {
    // Devuelve los datos filtrados por una fecha en especifico
    const results = data.filter((resp) =>
      resp.fecha_asignada.toLowerCase().includes(value)
    )
    return results
  }, [])

  // Funcion que carga las institucions
  const loadInstituciones = useCallback(async (inputValue) => {
    const response = await getInstitucionesByStatus(1)
    // const { data: response} = await getInstitucionesByStatus(1)
    if (inputValue) {
      // Verifica si hay un valor ingresado
      return dataFiltrada(response, inputValue)
    } else {
      let options = []
      response.forEach(({id, nombre}) => {
        const value = id
        const label = nombre
        const resultado = {
          label,
          value: value.toString(),
        }
        options = [...options, resultado]
      })
      // console.log(options)
      return options
    }
  }, [getInstitucionesByStatus])

  // Funcion que carga las instalaciones de acuerdo a la institucion seleccionada
  const loadInstalaciones = useCallback(async (inputValue) => {
    // console.log("Este es un cambio", selectedInstitucion)
    // if (selectedValueInstitucion) {
    if (inputValue) {
      // Verifica si hay un valor ingresado
      const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
      const {instalaciones} = response
      const resultadoFiltrado = dataFiltrada(instalaciones, inputValue)
      return resultadoFiltrado
    } else {
      const response = await getInstalacionesByInstitucionId(selectedInstitucion?.value)
      const {instalaciones} = response
      let optionsCombo = []
      // console.log("loadInstalaciones", instalaciones)
      instalaciones.forEach(({id, nombre}) => {
        const value = id
        const label = nombre
        const resultadoComboBox = {
          label,
          value: value.toString(),
        }
        optionsCombo = [...optionsCombo, resultadoComboBox]
      })
      // console.log(optionsCombo)
      return optionsCombo
    }
    // }
  }, [getInstalacionesByInstitucionId, selectedInstitucion?.value])

  // Funcion que carga las areas de acuerdo a la instalacion seleccionada
  const loadAreas = useCallback(
      async (inputValue) => {
        // Verifica si se ha seleccionado una instalacion
        if (selectedInstalacion) {
          const response = await getAreasByIdInstalacion(selectedInstalacion?.value)
          const {data} = response
          if (inputValue) {
            // Verifica si hay un valor ingresado
            return dataFiltrada(data, inputValue)
          } else {
            let optionsCombo = []
            data?.forEach(({id, nombre}) => {
              const value = id
              const label = nombre
              const resultadoComboBox = {
                label,
                value: value.toString(),
              }
              optionsCombo = [...optionsCombo, resultadoComboBox]
            })
            return optionsCombo
          }
        }
      }, [selectedInstalacion?.value, getAreasByIdInstalacion])

  // Funcion que almacena la seleccion del tipo de evento
  const onChangeSelectionTipoEvento = (text) => {
    // console.log(text)
    setSeleccion(text)
  }

  // Funcion para crear un evento
  const onCreateEvento = async () => {
    // console.log("Valor de selectedValueArea ", selectedArea?.value)
    // const fecha = new Date(fechaEvento)
    // console.log("fecha_asignada ", format(fechaInicioEvento, "yyyy-MM-dd"))
    // console.log("fecha_asignada ", typeof fechaEvento)
    // console.log("fecha_asignada ", fechaEvento)
    // console.log("tipo_evento ", seleccion)
    let tipoEvento = ""
    if (Number(seleccion) === 1) {
      tipoEvento = "ENTREGA DE DOSIMETRO"
    } else {
      tipoEvento = "RECEPCION DE DOSIMETRO"
    }

    //const persona_id = 2 //NO DEJA VALOR NULO *******
    const evento = {
      // fecha_asignada: fecha.toLocaleDateString(),
      fecha_asignada: fechaEvento,
      tipo_evento: tipoEvento,
      estado_evento: Number(seleccion) === 1? "LISTO PARA RETIRAR" : "EN ESPERA DE RECEPCION",
      //persona_id: persona_id,
      area_id: selectedArea?.value,
      estado: true
    }
    startLoading()
    const result = await createEvento(evento)
    if (result.error) {
      handleOpenAlert("error", result.error)
    } else {
      const { tipo_evento } = result
      handleOpenAlert(
        "success",
        `El evento "${tipo_evento}" se creo correctamente.`
      )
      await getEventos()
      await getEventosByDate(fechaEvento)
      setOpenCreate(false)
      cancel()
    }
    stopLoading()
  }

  // Funcion que obtiene todos los eventos
  const getEventos = async () => {
    const estado = 1 //Estado de un evento
    startLoading()
    const eventos = await getEventosByStatus(estado)
    // console.log(eventos)
    if (eventos.error) {
      setError(eventos.error)
    } else {
      //setNewEvent({ id: "", title: "", start: "", end: "" })
      setAllEvents([])
      eventos.forEach((evento) => {
        const fecha_asignada = new Date(evento.fecha_asignada)
        fecha_asignada.setDate(fecha_asignada.getDate() + 1)
        const newEvent = {
          id: evento.id,
          title: evento.tipo_evento,
          start: fecha_asignada,
          end: fecha_asignada,
        }
        setAllEvents((allEvents) => [...allEvents, newEvent])
      })
    }
    stopLoading()
  }

  // Funcion que obtiene los eventos por fecha
  const getEventosByDate = async (date) => {
    // console.log("Fecha evento 2 ", date)
    const estado = 1
    const eventos = await getEventosByStatus(estado)
    if (eventos.error) {
      setError(eventos.error)
    } else {
      // const { data } = eventos
      const resultadoFiltrado = await dataFiltradaFecha(eventos, date)
      setRows([])
      resultadoFiltrado.forEach(({ id, tipo_evento, estado_evento }) => {
        const key = id
        const cell = [tipo_evento, estado_evento]
        addRow(key, cell, setRows)
      })
    }
  }

  // Funcion para eliminar un evento
  const onDeleteEvento = async () => {
    setOpenDelete(false)
    startLoading()
    const result = await deleteEvento(eventoToDelete)
    if (result.error) {
      handleOpenAlert("error", result.error)
    } else {
      const { tipo_evento } = result
      await getEventos()
      await getEventosByDate(fechaEvento)
      cancel()
      handleOpenAlert(
        "success",
        `El evento "${tipo_evento}" se eliminó correctamente.`
      )
    }
    stopLoading()
  }

  // Funcion para obtener un evento por medio de su id
  const getEvento = async (id) => {
    startLoading()
    const evento = await getEventoById(id)
    if (evento.error) {
      handleOpenAlert("error", evento.error)
    } else {
      const { area, persona, tipo_evento } = evento
      const instalacion = await getInstalacionById(area.instalacion_id)
      // console.log("Dato esperado ", instalacion)
      // console.log(area.id)
      const { institucion } = instalacion
      setInstitucion(institucion?.nombre)
      setInstalacion(instalacion?.nombre)
      setArea(area?.nombre)
      setNombre(persona?.nombres)
      setApellido(persona?.apellidos)
      setDui(persona?.dui)
      setLicencia(persona?.licencia)
      setPasaporte(persona?.pasaporte)
      if (tipo_evento === "ENTREGA DE DOSIMETRO") {
        setSeleccion('1')
      } else {
        setSeleccion('2')
      }
      // setSelectedValueInstitucion(institucion.id)
      setSelectedInstitucion({
        label: institucion?.nombre,
        value: institucion?.id,
      })
      // setSelectedValueInstalacion(instalacion.id)
      setSelectedInstalacion({
        label: instalacion?.nombre,
        value: instalacion?.id,
      })
      // setSelectedValueArea(area.id)
      setSelectedArea({ label: area?.nombre, value: area?.id })
    }
    stopLoading()
  }

  // Funcion para editar un evento
  const onUpdateEvento = async () => {
    // console.log("Valor de selectedValueArea ", selectedArea?.value)
    // const fecha = new Date(fechaInicioEvento)
    // console.log("fecha_asignada ", format(fechaInicioEvento, "yyyy-MM-dd"))
    // console.log("fecha_asignada ", fechaEvento)
    // console.log("tipo_evento ", seleccion)
    // console.log("ID DEL EVENTO A EDITAR ", idEventoEditar)
    let tipoEvento = ""
    if (Number(seleccion) === 1) {
      tipoEvento = "ENTREGA DE DOSIMETRO"
    } else {
      tipoEvento = "RECEPCION DE DOSIMETRO"
    }

    //const persona_id = 2
    const eventoUpdate = {
      fecha_asignada: fechaEvento,
      tipo_evento: tipoEvento,
      estado_evento: Number(seleccion) === 1? "LISTO PARA RETIRAR" : "EN ESPERA DE RECEPCION",
      //persona_id: persona_id,
      area_id: selectedArea?.value,
    }
    startLoading()
    const result = await updateEvento(idEventoEditar, eventoUpdate)
    if (result.error) {
      handleOpenAlert("error", result.error)
    } else {
      const { tipo_evento } = result
      handleOpenAlert(
        "success",
        `El evento "${tipo_evento}" se actualizó correctamente.`
      )
      await getEventos()
      await getEventosByDate(fechaEvento)
      setOpenEdit(false)
      cancel()
    }
    stopLoading()
  }

  const data = {
    openResearch,
    openCreate,
    openEdit,
    fechaInicioEvento,
    openDelete,
    institucion,
    instalacion,
    area,
    id,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    eventoToDelete,
    allEvents,
    show,
    rows,
    seleccion,
    personaPresentada,
    nombre,
    apellido,
    dui,
    licencia,
    pasaporte,
    selectedInstitucion,
    selectedInstalacion,
    selectedArea,
    idEventoEditar,
    error
  }

  const actions = {
    handleSelect,
    handleClickOpenResearch,
    handleClickOpenCreate,
    handleClickCloseCreate,
    handleClickOpenEdit,
    handleClickCloseEdit,
    getFechaInicioEvento,
    handleClickOpenDelete,
    showMore,
    handleClickCloseDelete,
    handleChangeInstitucion,
    loadInstituciones,
    handleChangeInstalacion,
    loadInstalaciones,
    handleChangeArea,
    loadAreas,
    onChangeSelectionTipoEvento,
    onCreateEvento,
    onDeleteEvento,
    setAlertOpen,
    onUpdateEvento,
  }

  useEffect(async () => {
    await getEventos()
    // console.log("Fecha evento ", fechaEvento)
  }, [rows, fechaEvento])

  // Para mostrar las instituciones si se ha abierto el modal de crear o editar
  useEffect(() => {
    if (openCreate === true || openEdit === true) {
      loadInstituciones()
    }
  }, [openCreate, openEdit])

  // }, [selectedValueInstalacion])

  // Para consultar el evento mediante su id
  useEffect(() => {
    if (idEventoConsultar) {
      getEvento(idEventoConsultar)
    }
  }, [idEventoConsultar])

  return { data, actions }
}

export default usePlanificacionTecnicoPage
