import axios from 'axios'
import useErrorHandler from '../utils/useErrorHandler'
import headers from '../utils/headers'
import url from "../utils/url"
import { useCallback } from 'react'

const solicitudInscripcionUrl = `${url}inscripcion`

const useSolicitudInscripcionService = () => {
    const { errorHandler } = useErrorHandler()
    const createSolicitudInscripcion = useCallback(
        async (solicitudInscripcion, handleOnUploadProgress) => {
            try {
                const response = await axios.post(solicitudInscripcionUrl, solicitudInscripcion,
                    {
                        headers: {'Content-Type': 'multipart/form-data'},
                        onUploadProgress: (progressEvent) => handleOnUploadProgress(progressEvent.loaded, progressEvent.total)
                    })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getCargosSolicitud = useCallback(
        async () => {
            const requestUrl = `${solicitudInscripcionUrl}/cargos`
            try {
                const response = await axios.get(requestUrl)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getMinisteriosSolicitud = useCallback(
        async () => {
            const requestUrl = `${solicitudInscripcionUrl}/ministerios`
            try {
                const response = await axios.get(requestUrl)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getSectoresSolicitud = useCallback(
        async () => {
            const requestUrl = `${solicitudInscripcionUrl}/sectores`
            try {
                const response = await axios.get(requestUrl)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    //Delegado
    const createDelegado = useCallback(
        async (delegado) => {
            const requestUrl = `${solicitudInscripcionUrl}/institucion/delegado`
            try {
                const response = await axios.post(requestUrl, delegado,
                    {
                        headers: {'Content-Type': 'multipart/form-data', ...headers()}
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getDelegadoById = useCallback(
        async (delegado) => {
            const requestUrl = `${solicitudInscripcionUrl}/institucion/delegado/${delegado}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getArchivoDelegado = useCallback(
        async (delegado, handleOnDownloadProgress) => {
            const requestUrl = `${solicitudInscripcionUrl}/institucion/delegado/file/${delegado}`
            try {
                const response = await axios.get(requestUrl,
                    {
                        headers: {'Content-Disposition': 'attachment'
                        ,...headers()
                        },
                        responseType: 'blob',
                        //onDownloadProgress: (progressEvent) => handleOnDownloadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                return response
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getMisDelegados = useCallback(
        async (page) => {
            const requestUrl = `${solicitudInscripcionUrl}/institucion/delegados?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateDelegado = useCallback(
        async (delegado, cambios) => {
            console.log('id delegado a editar', delegado)
            const requestUrl = `${solicitudInscripcionUrl}/institucion/delegado/${delegado}`
            try {
                const response = await axios.post(requestUrl, cambios,
                    {
                        headers: {'Content-Type': 'multipart/form-data', ...headers()}
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const deleteDelegado = useCallback(
        async (delegado) => {
            const requestUrl = `${solicitudInscripcionUrl}/institucion/delegado/${delegado}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    //Instalacion
    const createInstalacion = useCallback(
        async (instalacion) => {
            const requestUrl = `${solicitudInscripcionUrl}/instalacion`
            try {
                const response = await axios.post(requestUrl, instalacion,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const createInstalacionEdit = useCallback(
        async (instalacion) => {
            const requestUrl = `${solicitudInscripcionUrl}/instalacion/edit`
            try {
                const response = await axios.post(requestUrl, instalacion,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getInstalacionById = useCallback(
        async (instalacion) => {
            const requestUrl = `${solicitudInscripcionUrl}/instalacion/${instalacion}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getMisInstalaciones = useCallback(
        async (page) => {

            const requestUrl = `${solicitudInscripcionUrl}/institucion/instalaciones?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getDelegadosPorNombre = useCallback(
        async (busqueda) => {
            const requestUrl = `${solicitudInscripcionUrl}/institucion/delegados/busqueda`
            try {
                const response = await axios.get(requestUrl, {
                    params: {busqueda},
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateInstalacion = useCallback(
        async (instalacion, cambios) => {
            console.log('id instalacion a editar', instalacion)
            const requestUrl = `${solicitudInscripcionUrl}/instalacion/${instalacion}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateInstalacionEdit = useCallback(
        async (instalacion, cambios) => {
            console.log('id instalacion a editar', instalacion)
            const requestUrl = `${solicitudInscripcionUrl}/instalacion/edit/${instalacion}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const deleteInstalacion = useCallback(
        async (instalacion) => {
            const requestUrl = `${solicitudInscripcionUrl}/instalacion/${instalacion}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateStatusInstalacionEdit = useCallback(
        async (instalacion) => {
            const requestUrl = `${solicitudInscripcionUrl}/instalacion/edit/status/${instalacion}`
            try {
                const response = await axios.put(requestUrl,'',{headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    //Areas
    const createArea = useCallback(
        async (area) => {
            const requestUrl = `${solicitudInscripcionUrl}/areas`
            try {
                const response = await axios.post(requestUrl, area,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const createAreaEdit = useCallback(
        async (area) => {
            const requestUrl = `${solicitudInscripcionUrl}/areas/edit`
            try {
                const response = await axios.post(requestUrl, area,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getAreaById = useCallback(
        async (area) => {
            const requestUrl = `${solicitudInscripcionUrl}/areas/${area}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getAreasByInstalacion = useCallback(
        async (instalacion, page) => {
            const requestUrl = `${url}/areas/instalacion/${instalacion}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getInstalacionesPorNombre = useCallback(
        async (busqueda) => {
            const requestUrl = `${solicitudInscripcionUrl}/instalaciones/busqueda`
            try {
                const response = await axios.get(requestUrl, {
                    params: {busqueda},
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateArea = useCallback(
        async (areas, cambios) => {
            console.log('id area a editar', areas)
            const requestUrl = `${url}areas/${areas}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateAreaEdit = useCallback(
        async (areas, cambios) => {
            console.log('id area a editar->', areas)
            const requestUrl = `${solicitudInscripcionUrl}/areas/edit/${areas}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateStatusArea = useCallback(
        async (areas) => {
            console.log('id area a cambiar el estado', areas)
            const requestUrl = `${solicitudInscripcionUrl}/areas/edit/status/${areas}`
            try {
                const response = await axios.put(requestUrl, '',
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const deleteArea = useCallback(
        async (area) => {
            const requestUrl = `${solicitudInscripcionUrl}/areas/${area}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    //Personal
    const createPersonal = useCallback(
        async (personal) => {
            const requestUrl = `${solicitudInscripcionUrl}/personal`
            try {
                const response = await axios.post(requestUrl, personal,
                    {
                        headers: {'Content-Type': 'multipart/form-data', ...headers()}
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const createPersonalEdit = useCallback(
        async (personal) => {
            const requestUrl = `${solicitudInscripcionUrl}/personal/edit`
            try {
                const response = await axios.post(requestUrl, personal,
                    {
                        headers: {'Content-Type': 'multipart/form-data', ...headers()}
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getPersonalById = useCallback(
        async (personal) => {
            const requestUrl = `${solicitudInscripcionUrl}/personal/${personal}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getArchivoPersonal = useCallback(
        async (personal, handleOnDownloadProgress) => {
            const requestUrl = `${solicitudInscripcionUrl}/personal/file/${personal}`
            try {
                const response = await axios.get(requestUrl,
                    {
                        headers: {'Content-Disposition': 'attachment'
                        ,...headers()
                        },
                        responseType: 'blob',
                        //onDownloadProgress: (progressEvent) => handleOnDownloadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                return response
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getPersonalOfInstalacion = useCallback(
        async (instalacion, page) => {
            const requestUrl = `${solicitudInscripcionUrl}/instalacion/personal/${instalacion}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getAreasPorInstalacionPorNombre = useCallback(
        async (instalacion, nombre) => {
            const requestUrl = `${solicitudInscripcionUrl}/areas/instalacion/busqueda`
            try {
                const response = await axios.get(requestUrl, {
                    params: {busqueda: nombre, instalacion_id: instalacion},
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updatePersonal = useCallback(
        async (personal, cambios) => {
            console.log('id personal a editar', personal)
            const requestUrl = `${solicitudInscripcionUrl}/personal/${personal}`
            try {
                const response = await axios.post(requestUrl, cambios,
                    {
                        params: {method: 'put'},
                        headers: {'Content-Type': 'multipart/form-data', ...headers()}
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updatePersonalEdit = useCallback(
        async (personal, cambios) => {
            console.log('id personal a editar', personal)
            const requestUrl = `${solicitudInscripcionUrl}/personal/edit/${personal}`
            try {
                const response = await axios.post(requestUrl, cambios,
                    {
                        params: {method: 'put'},
                        headers: {'Content-Type': 'multipart/form-data', ...headers()}
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateStatusPersonalEdit = useCallback(
        async (personal) => {
            const requestUrl = `${solicitudInscripcionUrl}/personal/edit/status/${personal}`
            try {
                const response = await axios.put(requestUrl, "", {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const deletePersonal = useCallback(
        async (personal) => {
            const requestUrl = `${solicitudInscripcionUrl}/personal/${personal}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getAllResponsabilidades = useCallback(
        async () => {
            const requestUrl = `${solicitudInscripcionUrl}/responsabilidades`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getCargosByNombre = useCallback(
        async (nombre) => {
            const requestUrl = `${solicitudInscripcionUrl}/cargos`
            try {
                const response = await axios.get(requestUrl, {
                    params: {busqueda: nombre},
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const enviarSolicitudInscripcion = useCallback(
        async () => {
            const requestUrl = `${solicitudInscripcionUrl}/enviar`
            try {
                const response = await axios.post(requestUrl, null, {
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const enviarSolicitudInscripcionEdit = useCallback(
        async (solicitudInscripcionCambios) => {
            const requestUrl = `${solicitudInscripcionUrl}/enviar/edit`
            try {
                const response = await axios.post(requestUrl, solicitudInscripcionCambios, {
                    headers: {'Content-Type': 'multipart/form-data', ...headers()}
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const enviarSolicitudInscripcionEtapa2Edit = useCallback(
        async () => {
            const requestUrl = `${solicitudInscripcionUrl}/enviar/edit`
            try {
                const response = await axios.post(requestUrl, "", {
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const evaluarSolicitud = useCallback(
        async (solicitud, evaluacion) => {
            const requestUrl = `${url}evaluacion/${solicitud}`
            try {
                const response = await axios.post(requestUrl, evaluacion,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }
    , [errorHandler])

    const updateSolicitudInscripcion = useCallback(
        async (solicitudInscripcionCambios, handleOnUploadProgress) => {
            const requestUrl = `${solicitudInscripcionUrl}/edit`
            try {
                const response = await axios.post(requestUrl, solicitudInscripcionCambios,
                    {
                        headers: {'Content-Type': 'multipart/form-data', ...headers()},
                        // onUploadProgress: (progressEvent) => handleOnUploadProgress(progressEvent.loaded, progressEvent.total)
                    })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const evaluarSolicitudEdit = useCallback(
        async (solicitud, evaluacion) => {
            const requestUrl = `${url}evaluacion/edit/${solicitud}`
            try {
                const response = await axios.post(requestUrl, evaluacion,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }
        , [errorHandler])

    return { createSolicitudInscripcion, getCargosSolicitud,
        getMinisteriosSolicitud, getSectoresSolicitud, createDelegado,
        getDelegadoById, getArchivoDelegado, getMisDelegados, updateDelegado,
        deleteDelegado, createInstalacion, createInstalacionEdit, getInstalacionById,
        getMisInstalaciones, getDelegadosPorNombre, updateInstalacion, updateInstalacionEdit,
        deleteInstalacion, updateStatusInstalacionEdit, createArea, createAreaEdit, getAreaById,
        getAreasByInstalacion, getInstalacionesPorNombre, updateArea, updateAreaEdit, updateStatusArea,
        deleteArea, createPersonal, createPersonalEdit, getPersonalById, getArchivoPersonal,
        getPersonalOfInstalacion, getAreasPorInstalacionPorNombre, updatePersonal, updatePersonalEdit,
        updateStatusPersonalEdit, deletePersonal, getAllResponsabilidades, getCargosByNombre,
        enviarSolicitudInscripcion, evaluarSolicitud, updateSolicitudInscripcion, evaluarSolicitudEdit,
        enviarSolicitudInscripcionEdit, enviarSolicitudInscripcionEtapa2Edit }
}

export default useSolicitudInscripcionService
