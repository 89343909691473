import React from "react";
import AppFrame from "../../components/AppFrame";
import Button from "../../components/Button/Button";
import FormDivider from '../../components/FormDivider'
import FormHeader from '../../components/FormHeader'
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '../../components/Accordion'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "react-day-picker/lib/style.css";
import Progress from "../../components/Progress/Progress";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TabPanel from "../../components/TabPanel";
import { List, ListItem, ListItemText, TextField } from '@material-ui/core'
import Alert from "../../components/Alert";
import Input from '../../components/Input'
import Typography from '@material-ui/core/Typography'
import { AiOutlineHome } from "react-icons/ai";
import { CgOrganisation } from "react-icons/cg";
import { CgMenuGridR } from "react-icons/cg";
import MaterialDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import PdfViewer from '../../components/PdfViewer'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IconContext } from "react-icons/lib";
import { MdGroups, MdPerson } from "react-icons/md";
import Pagination from "../../components/Pagination";
import useEvaluacionEtapa2Page from "../../hooks/inscripcion/useEvaluacionEtapa2Page";
import useResumenPage from "../../hooks/inscripcion/useResumenPage";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
  },
  root: {
    flexGrow: 1,
  },
  typography: {
    fontSize: 14,
  },
  divicion:{
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  // root: {
  //   '& .MuiTextField-root': {
  //       paddingTop:'1em',
  //       margin: theme.spacing(1),
  //       width: '100%',
  //   },
  // },
  grid: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: "1em",
    display: 'inline'
  },
}));

export const PrevisualizacionEtapa2 = ({data, actions}) => {
  const classes = useStyles()

  const {
    pestania,
    institucion,
    representanteLegal,
    instalaciones,
    instalacionesPersonal,
    openPersonalFileModal,
    pageNumber,
    fileNumPages,
    archivoPersonal,
    comentario,
    estadoSolicitud
  } = data

  const {
    handleChangePestania,
    handleOnChangeAccordionInstalacion,
    handleOnChangeAccordionArea,
    handleOnChangeAccordionInstalacionPersonal,
    handleOnChangeAccordionPersonal,
    onChangeComentario, handlePersonalFilePageChange,
    handleClosePersonalFileModal,
    onDocumentLoadSuccess,
    openArchivoPersonalFile,
    onChangeRadio,
    handleEvaluarSolicitud
  } = actions

  return (
  <Grid container justifyContent = "center">
    <Grid container justifyContent = "center" item xs = {12}>
      <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={pestania}
          onChange={handleChangePestania}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos de la institucion" />
          <Tab label="Datos de instalacion" />
          <Tab label="Datos del personal de la instalacion" />
          {
            comentario || comentario === '' ?
              <Tab label="Resultado" />
              :
              null
          }
      </Tabs>
    </Grid>
    <Grid item xs = {12}>
      <TabPanel value={pestania} index={0}>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs = {12}>
              <Input type = "text" label="Nombre de la institucion"
                  value={institucion ? institucion.nombre : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Tipo de institucion"
                  value={institucion ? institucion.tipo_institucion.nombre : ''}
                  disabled = {true}
                  />
          </Grid>
          {
            institucion ?
              institucion.tipo_institucion.id === 1 ?
              <Grid item xs = {12} sm={6}>
                  <Input type = "text" label="Ministerio"
                      value={institucion ? institucion.ministerio.nombre : ''}
                      disabled = {true}
                      />
              </Grid>
              :
              null
            :
            null
          }
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Sector"
                  value={institucion ? institucion.sector.nombre : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="NIT"
                  value={institucion ? institucion.nit : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Telefono"
                  value={institucion ? institucion.telefono : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Telefono Celular"
                  value={institucion ? institucion.telefono_celular : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Departamento"
                  value={institucion ? institucion.direccion.municipio.departamento.nombre : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Municipio"
                  value={institucion ? institucion.direccion.municipio.nombre : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Especificacion de la direccion"
                  value={institucion ? institucion.direccion.detalle : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12}>
            <FormDivider text="Datos del representante legal"/>
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Nombres"
                  value={representanteLegal ? representanteLegal.nombres : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Apellidos"
                  value={representanteLegal ? representanteLegal.apellidos : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="DUI"
                  value={representanteLegal ? representanteLegal.dui : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Cargo"
                  value={representanteLegal ? representanteLegal.cargo : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Telefono"
                  value={representanteLegal ? representanteLegal.telefono : ''}
                  disabled = {true}
                  />
          </Grid>
          <Grid item xs = {12} sm={6}>
              <Input type = "text" label="Telefono Celular"
                  value={representanteLegal ? representanteLegal.telefono_celular : ''}
                  disabled = {true}
                  />
          </Grid>
        </Grid>
      </TabPanel>
    </Grid>
    <Grid item xs = {12}>
      <TabPanel value={pestania} index={1}>
          {
            instalaciones && instalaciones.map(
              (instalacion) => {
                return (
                  <Accordion key = {instalacion.id} expanded = {instalacion.open}
                    onChange = {()=>handleOnChangeAccordionInstalacion(instalacion.id)}
                    summary = {
                      <div>
                        <IconContext.Provider value = {{
                          size: '1.5em',
                          style: { verticalAlign: 'middle' }
                          }}>
                          <CgOrganisation/>
                        </IconContext.Provider>
                        <Typography className={classes.heading}>{instalacion.nombre}</Typography>
                      </div>
                    }
                    details = {
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs = {12} sm={6}>
                            <Input type = "text" label="Telefono"
                                value={instalacion.detalle ? instalacion.detalle.telefono : ''}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs = {12} sm={6}>
                            <Input type = "text" label="Telefono Celular"
                                value={instalacion.detalle ? instalacion.detalle.telefono_celular : ''}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs = {12} sm={6}>
                            <Input type = "text" label="Departamento"
                                value={instalacion.detalle ? instalacion.detalle.direccion.municipio.departamento.nombre : ''}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs = {12} sm={6}>
                            <Input type = "text" label="Municipio"
                                value={instalacion.detalle ? instalacion.detalle.direccion.municipio.nombre : ''}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs = {12}>
                            <Input type = "text" label="Especificacion de la direccion"
                                value={instalacion.detalle ? instalacion.detalle.direccion.detalle : ''}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs = {12}>
                          <FormDivider text="Dosimetros solicitados"/>
                        </Grid>
                        <Grid item xs = {12} sm={6}>
                            <Input type = "number" label="Anillo"
                                value={instalacion.detalle ? instalacion.detalle.dosimetros_solicitados.anillo : ''}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs = {12} sm={6}>
                            <Input type = "number" label="PD1"
                                value={instalacion.detalle ? instalacion.detalle.dosimetros_solicitados.pd1 : ''}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs = {12}>
                          <Accordion
                            summary = {
                              <div>
                                  <IconContext.Provider value = {{
                                        size: '1.5em',
                                        style: { verticalAlign: 'middle' }
                                    }}>
                                    <MdPerson/>
                                  </IconContext.Provider>
                                  <Typography className={classes.heading}>Datos del delegado de la instalacion</Typography>
                                </div>
                            }
                            details = {
                              <Grid container direction="row">
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text" label="Nombres"
                                        value={instalacion.detalle ? instalacion.detalle.delegado.user.nombres : ''}
                                        disabled={true}
                                        />
                                </Grid>
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text" label="Telefono"
                                        value={instalacion.detalle ? instalacion.detalle.delegado.user.telefono : ''}
                                        disabled={true}
                                        />
                                </Grid>
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text" label="Telefono celular"
                                        value={instalacion.detalle ? instalacion.detalle.delegado.user.telefono_celular : ''}
                                        disabled={true}
                                        />
                                </Grid>
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text" label="Correo electronico"
                                        value={instalacion.detalle ? instalacion.detalle.delegado.user.email : ''}
                                        disabled={true}
                                        />
                                </Grid>
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text" label="DUI:"
                                        value={instalacion.detalle ? instalacion.detalle.delegado.user.dui : ''}
                                        disabled={true}
                                        />
                                </Grid>
                              </Grid>
                            }
                          />
                          {
                            instalacion.detalle ?
                              instalacion.detalle.responsables.length === 0 ?
                              <Grid container justifyContent="center">
                                <Grid item xs = {12}>
                                  <Typography align="center">
                                    No se encontraron responsables.
                                  </Typography>
                                </Grid>
                              </Grid>
                              :
                              <Accordion summary = {
                                <div>
                                  <IconContext.Provider value = {{
                                      size: '1.5em',
                                      style: { verticalAlign: 'middle' }
                                  }}>
                                    <MdGroups/>
                                  </IconContext.Provider>
                                  <Typography className={classes.heading}>Personal con responsabilidades</Typography>
                                </div>
                                }
                                details = {
                                  <Grid item xs = {12}>
                                    {
                                      instalacion.detalle ?
                                        instalacion.detalle.responsables.map(
                                          (responsable)=>{return(
                                            <Accordion key = {responsable.id}
                                              summary = {
                                                <div>
                                                    <IconContext.Provider value = {{
                                                          size: '1.5em',
                                                          style: { verticalAlign: 'middle' }
                                                      }}>
                                                      <MdPerson/>
                                                    </IconContext.Provider>
                                                    <Typography className={classes.heading}>{responsable.nombres} {responsable.apellidos}</Typography>
                                                  </div>
                                              }
                                              details = {
                                                <Grid container direction="row">
                                                  <Grid item xs = {6}>
                                                      <Input type = "text" label="Telefono"
                                                          value={responsable.telefono}
                                                          disabled={true}
                                                          />
                                                  </Grid>
                                                  <Grid item xs = {6}>
                                                      <Input type = "text" label="Telefono celular"
                                                          value={responsable.telefono_celular}
                                                          disabled={true}
                                                          />
                                                  </Grid>
                                                  <Grid item xs = {6}>
                                                      <Input type = "date" label="Fecha de nacimiento"
                                                          value={responsable.fecha_nacimiento}
                                                          disabled={true}
                                                          />
                                                  </Grid>
                                                  <Grid item xs = {6}>
                                                      <Input type = "text" label="Grado academico"
                                                          value={responsable.grado_academico}
                                                          disabled={true}
                                                          />
                                                  </Grid>
                                                  <Grid item xs = {6}>
                                                      <Input type = "text" label={`${responsable.dui ? 'DUI' : responsable.licencia ? 'Licencia' : responsable.pasaporte ? 'Pasaporte' : 'Error'}`}
                                                          value={`${responsable.dui ? responsable.dui : responsable.licencia ? responsable.licencia : responsable.pasaporte ? responsable.pasaporte : 'Error'}`}
                                                          disabled={true}
                                                          />
                                                  </Grid>
                                                  <Grid item xs = {6}>
                                                      <Input type = "text" label="Nacionalidad"
                                                          value={responsable.nacionalidad}
                                                          disabled={true}
                                                          />
                                                  </Grid>
                                                  <Grid item xs = {12}>
                                                      <Input type = "text" label="Cargo"
                                                          value={responsable.cargo.nombre}
                                                          disabled={true}
                                                          />
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.title}>
                                                      Responsabilidades
                                                    </Typography>
                                                      <List dense={true}>
                                                        {
                                                          responsable.responsabilidades.map(
                                                            (responsabilidad)=>{
                                                              return(
                                                                <ListItem key={responsabilidad.id}>
                                                                  <ListItemText
                                                                    primary={`- ${responsabilidad.responsabilidad.nombre}`}
                                                                  />
                                                                </ListItem>
                                                              )
                                                            }
                                                          )
                                                        }
                                                      </List>
                                                  </Grid>
                                                </Grid>
                                              }
                                            />
                                          )}
                                        )
                                      :
                                      <Grid container justifyContent="center">
                                        <Grid item xs = {12}>
                                          <Typography align="center">
                                            No se encontraron responsables.
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    }
                                  </Grid>
                                }
                              />
                            :
                            null
                          }
                          {
                            instalacion.detalle ?
                              instalacion.detalle.areas.length === 0 ?
                              null
                              :
                              <Accordion summary = {
                                  <div>
                                    <IconContext.Provider value = {{
                                        size: '1.5em',
                                        style: { verticalAlign: 'middle' }
                                    }}>
                                      <AiOutlineHome/>
                                    </IconContext.Provider>
                                    <Typography className={classes.heading}>Datos del Area</Typography>
                                  </div>
                                }
                                details = {
                                  <Grid item xs = {12}>
                                    {
                                      instalacion.detalle ?
                                        instalacion.detalle.areas.map(
                                          (area)=>{
                                            return(
                                              <Accordion key = {area.id}  expanded = {area.open} onChange = {() => handleOnChangeAccordionArea(instalacion.id, area.id)}
                                                summary = {
                                                  <div>
                                                    <IconContext.Provider value = {{
                                                          size: '1.5em',
                                                          style: { verticalAlign: 'middle' }
                                                      }}>
                                                      <CgMenuGridR/>
                                                    </IconContext.Provider>
                                                    <Typography className={classes.heading}>{area.nombre}</Typography>
                                                  </div>
                                                }
                                                details = {
                                                  <Grid container direction="row">
                                                    <Grid item xs = {6}>
                                                        <Input type = "text" label="Telefono"
                                                            value={area.telefono}
                                                            disabled={true}
                                                            />
                                                    </Grid>
                                                    <Grid item xs = {6}>
                                                        <Input type = "text" label="Telefono Celular"
                                                            value={area.telefono_celular}
                                                            disabled={true}
                                                            />
                                                    </Grid>
                                                    <Grid item xs = {6}>
                                                        <Input type = "text" label="Periodo de control"
                                                            value={area.periodo_control}
                                                            disabled={true}
                                                            />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <Typography variant="h6" className={classes.title}>
                                                        Personal que recibe consulta en esta area
                                                      </Typography>
                                                        <List dense={true}>
                                                          {
                                                            area.detalle ?
                                                              area.detalle.consulta_area.length !== 0 ?
                                                                area.detalle.consulta_area.map(
                                                                  (consulta)=>{
                                                                    return(
                                                                      <ListItem key = {consulta.id}>
                                                                        <ListItemText
                                                                          primary={`- ${consulta.personal.nombres} ${consulta.personal.apellidos}`}
                                                                        />
                                                                      </ListItem>
                                                                    )
                                                                  }
                                                                )
                                                              :
                                                              <ListItem>
                                                                <ListItemText
                                                                  primary="No hay personal que reciba consulta en esta area"
                                                                />
                                                              </ListItem>
                                                            :
                                                            <ListItem>
                                                              <ListItemText
                                                                primary="No hay personal que reciba consulta en esta area"
                                                              />
                                                            </ListItem>
                                                          }
                                                        </List>
                                                    </Grid>
                                                  </Grid>
                                                }
                                                />
                                            )
                                          }
                                        )
                                        :
                                        'No se encontraron areas'
                                    }
                                  </Grid>
                                }
                              />
                            :
                            null
                          }
                        </Grid>
                      </Grid>
                    }
                    />
                )
              }
            )
          }
          {
            !instalaciones ?
              <Grid container>
                <Grid item xs = {12}>
                  <Typography align="center">
                    No se encontraron instalaciones.
                  </Typography>
                </Grid>
              </Grid>
            :
              instalaciones.length < 1 ?
              <Grid container>
                <Grid item xs = {12}>
                  <Typography align="center">
                    No se encontraron instalaciones.
                  </Typography>
                </Grid>
              </Grid>
              :
              null
          }
      </TabPanel>
    </Grid>
    <Grid item xs = {12}>
      <TabPanel value={pestania} index={2}>
        {
        //Modal para archivos de personal
        }
        <MaterialDialog
            open={openPersonalFileModal}
            onClose={handleClosePersonalFileModal}
            maxWidth = 'lg'
            >
            <DialogContent>
                <PdfViewer file = {archivoPersonal} onDocumentLoadSuccess = {onDocumentLoadSuccess}
                    pageNumber = {pageNumber} numPages = {fileNumPages} scale={1}/>
                <Pagination page={Number(pageNumber)}
                    numPages={Number(fileNumPages)}
                    onChangePage = {handlePersonalFilePageChange}
                    />
            </DialogContent>
        </MaterialDialog>
        <Grid container >
          <Grid item xs = {12}>
            {
              instalacionesPersonal && instalacionesPersonal.map(
                (instalacion) => {
                  return (
                    <Accordion key = {instalacion.id} expanded = {instalacion.open}
                      onChange = {()=>handleOnChangeAccordionInstalacionPersonal(instalacion.id)}
                      summary = {
                        <div>
                          <IconContext.Provider value = {{
                            size: '1.5em',
                            style: { verticalAlign: 'middle' }
                            }}>
                            <CgOrganisation/>
                          </IconContext.Provider>
                          <Typography className={classes.heading}>{instalacion.nombre}</Typography>
                        </div>
                      }
                      details = {
                        <Grid container direction="row" justifyContent="center" className={classes.root}>
                          <Grid item xs = {12}>
                          {
                            instalacion.personal ?
                              instalacion.personal.length > 0 ?
                              instalacion.personal.map(
                                (persona)=>{return(
                                  <Accordion key = {persona.id}
                                    expanded = {persona.open}
                                    onChange = {() => handleOnChangeAccordionPersonal(instalacion.id, persona.id)}
                                    summary = {
                                      <div>
                                          <IconContext.Provider value = {{
                                                size: '1.5em',
                                                style: { verticalAlign: 'middle' }
                                            }}>
                                            <MdPerson/>
                                          </IconContext.Provider>
                                          <Typography className={classes.heading}>{persona.nombres} {persona.apellidos}</Typography>
                                        </div>
                                      }
                                      details = {
                                        <Grid container direction="row">
                                          <Grid item xs = {6}>
                                              <Input type = "text" label="Telefono"
                                                  value={persona.detalle ? persona.detalle.telefono : ''}
                                                  disabled={true}
                                                  />
                                          </Grid>
                                          <Grid item xs = {6}>
                                              <Input type = "text" label="Telefono celular"
                                                  value={persona.detalle ? persona.detalle.telefono_celular : ''}
                                                  disabled={true}
                                                  />
                                          </Grid>
                                          <Grid item xs = {6}>
                                              <Input type = "date" label="Fecha de nacimiento"
                                                  value={persona.detalle ? persona.detalle.fecha_nacimiento : ''}
                                                  disabled={true}
                                                  />
                                          </Grid>
                                          <Grid item xs = {6}>
                                              <Input type = "text" label="Grado academico"
                                                  value={persona.detalle ? persona.detalle.grado_academico : ''}
                                                  disabled={true}
                                                  />
                                          </Grid>
                                          <Grid item xs = {6}>
                                              <Input type = "text" label={`${ persona.detalle ? persona.detalle.dui ? 'DUI' : persona.detalle.licencia ? 'Licencia' : persona.detalle.pasaporte ? 'Pasaporte' : 'Error' : ''}`}
                                                  value={`${persona.detalle ? persona.detalle.dui ? persona.detalle.dui : persona.detalle.licencia ? persona.detalle.licencia : persona.detalle.pasaporte ? persona.detalle.pasaporte : 'Error' : ''}`}
                                                  disabled={true}
                                                  />
                                          </Grid>
                                          <Grid item xs = {6}>
                                              <Input type = "text" label="Nacionalidad"
                                                  value={persona.detalle ? persona.detalle.nacionalidad : ''}
                                                  disabled={true}
                                                  />
                                          </Grid>
                                          <Grid item xs = {12}>
                                              <Input type = "text" label="Cargo"
                                                  value={persona.detalle ? persona.detalle.cargo.nombre : ''}
                                                  disabled={true}
                                                  />
                                          </Grid>
                                          <Grid className={classes.input} item xs = {3}>
                                            <Button variant="outlined" text="Ver archivo DUI" func={()=>openArchivoPersonalFile(persona.id)} />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography variant="h6" className={classes.title}>
                                              Responsabilidades:
                                            </Typography>
                                              <List dense={true}>
                                                {
                                                  persona.detalle && persona.detalle.responsabilidades.map(
                                                    (responsabilidad)=>{
                                                      return(
                                                        <ListItem key={responsabilidad.id}>
                                                          <ListItemText
                                                            primary={`- ${responsabilidad.responsabilidad.nombre}`}
                                                          />
                                                        </ListItem>
                                                      )
                                                    }
                                                  )
                                                }
                                              </List>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography variant="h6" className={classes.title}>
                                              Trabajador ocupacionalmente expuesto en:
                                            </Typography>
                                              <List dense={true}>
                                                {
                                                  persona.detalle && persona.detalle.toe_area.map(
                                                    (toe)=>{
                                                      return(
                                                        <ListItem key={toe.id}>
                                                          <ListItemText
                                                            primary={`- Area: ${toe.area.nombre}, Dosimetros: ${toe.cantidad_anillo ? `${toe.cantidad_anillo} de Anillo, ` : ''}${toe.cantidad_pd1 ? `${toe.cantidad_pd1} de PD1` : ''}`}
                                                          />
                                                        </ListItem>
                                                      )
                                                    }
                                                  )
                                                }
                                              </List>
                                          </Grid>
                                        </Grid>
                                      }
                                    />
                                  )}
                                )
                              :
                              <Grid container justifyContent="center">
                                <Grid item xs = {12}>
                                  <Typography align="center">
                                    No se encontro personal para la instalacion.
                                  </Typography>
                                </Grid>
                              </Grid>
                            :
                            <Grid container justifyContent="center">
                              <Grid item xs = {12}>
                                <br/>
                                <Typography align="center">
                                  No se encontro personal para la instalacion.
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          </Grid>
                        </Grid>
                        }
                        />
                    )
                  }
                )
            }
            {
                !instalacionesPersonal ?
                <Grid container justifyContent="center">
                  <Grid item xs = {12}>
                    <Typography align="center">
                      No se encontraron instalaciones.
                    </Typography>
                  </Grid>
                </Grid>
                :
                  instalacionesPersonal.length === 0 ?
                    <Grid container justifyContent="center">
                      <Grid item xs = {12}>
                        <Typography align="center">
                          No se encontraron instalaciones.
                        </Typography>
                      </Grid>
                    </Grid>
                  :
                    null
            }
          </Grid>
        </Grid>
      </TabPanel>
    </Grid>
    {
      comentario || comentario === '' ?
      <Grid item xs = {12}>
        <TabPanel value={pestania} index={3}>
            <Grid container item xs = {12} justifyContent="center">
              <Typography className={classes.typography} align="left">
                      Respecto a los datos ingresados por el usuario que esta solicitando el acceso al sistema para ser parte de cliente del CIAN
                      ¿Se aprobara la solicitud del cliente?
                    </Typography>
            </Grid>
            <Grid container direction="row" item xs = {12}>
                <TextField
                    id="standard-multiline-static"
                    multiline rows={15}
                    placeholder="Compártanos acá lo que desea comunicar…"
                    variant="outlined"
                    value = {comentario}
                    onChange= {onChangeComentario}
                    fullWidth
                />
            </Grid>
            <Grid  container direction="row" justifyContent="flex-start" item xs = {12}>
                <Grid item xs={12} >
                    <RadioGroup value = {estadoSolicitud} onChange={onChangeRadio} >
                        {
                            //Los values son los id de los estados en la bd
                        }
                        <FormControlLabel value={4} control={<Radio color="primary" />} label= "Aprobada" />
                        <FormControlLabel value={5} control={<Radio color="primary" />} label= "Rechazada" />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center"  item xs = {12}>
                <Grid  item xs = {3} sm={3} md= {3}>
                        <Button
                            variant = "contained"
                            text={"Guardar"}
                            func={handleEvaluarSolicitud}
                        />
                </Grid>
            </Grid>
        </TabPanel>
      </Grid>
      :
      null
    }
  </Grid>
  )
}

const EvaluacionEtapa2Page = (props) => {
  const classes = useStyles()
  const { data, actions } = useEvaluacionEtapa2Page()
  const {
    loading,
    alertOpen,
    alertSeverity,
    alertMessage,
    comentario,
    estadoSolicitud,
    id
  } = data;
  const {
    setAlertOpen,
    onChangeRadio,
    handleEvaluarSolicitud,
    onChangeComentario
  } = actions

  let {data: dataResumen, actions: actionsResumen} = useResumenPage(id)
  const {loading: loadingResumen} = dataResumen
  dataResumen.comentario = comentario
  dataResumen.estadoSolicitud = estadoSolicitud
  actionsResumen.onChangeRadio = onChangeRadio
  actionsResumen.handleEvaluarSolicitud = handleEvaluarSolicitud
  actionsResumen.onChangeComentario = onChangeComentario

  return (
    <AppFrame>
      <Progress open={loading > 0 || loadingResumen > 0} setOpen={setAlertOpen} />
      <Alert
        open={alertOpen}
        setOpen={setAlertOpen}
        severity={alertSeverity}
        message={alertMessage}
      />
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <FormHeader title="Registro de inscripción"
              subtitle="Evaluacion de la solicitud de creacion de cliente Etapa 2">
            </FormHeader>
            {
              <PrevisualizacionEtapa2 data = {dataResumen} actions = {actionsResumen}/>
            }
          </Paper>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default EvaluacionEtapa2Page
