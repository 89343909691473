import { useState, useEffect } from "react"
import {useHistory, useParams} from 'react-router-dom'
import useIsLoged from '../utils/useIsLoged'
// Services
import useAreaService from "../../services/area"
import useInstitucionService from "../../services/instituciones"
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"

const useAsignacionDosimetriaAreaToePage = () => {
    useIsLoged()
    const history = useHistory()
    // Obteniendo parámetros de la URL
    const { area_id } = useParams()
    //Obteniendo funciones de los services
    const { getAreaById } = useAreaService()
    const { getInstitucionById } = useInstitucionService()
    const { getAllAsignacionesToeByArea, getDosimetrosDisponiblesByTipo,
    asignarDosimetro} = useDosimetriaService()

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([])

    // Almacena los dosímetros que se encuentran disponibles por su tipo
    const [rowsDisponibles, setRowsDisponibles] = useState([])

    // Almacena los dosímetros dispoibles seleccionados
    const [dosimetrosDisponiblesSeleccionados, setDosimetrosDisponiblesSeleccionados] = useState([])

    // Almacena el nombre de los tipos de dosímetros seleccionados
    const [tipoDosimetro, setTipoDosimetro] = useState("")

    const [codigoDosimetro, setCodigoDosimetro] = useState("")

    // Almacena la información del item seleccionado para asignar el dosímetro
    const [item, setItem] = useState("")

    // Almacena el área, instalación y la institución
    const [institucion, setInstitucion] = useState("")
    const [instalacion, setInstalacion] = useState("")
    const [area, setArea] = useState("")

    // Para abrir y cerrar el modal para guardar las recepciones de dosimetro
    const [openModalAsignar, setOpenModalAsignarDosimetro] = useState(false)

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);

    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    if (!page) {
        setPage(1)
        // history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    const encabezado = ["TOE", "Código", "Tipo de dosímetro"]
    const encabezadoDosimetroDisponible = [
        {
            field: 'codigo',
            headerName: 'Codigo',
            resize: true,
            sortable: false,
            editable: false,
            width: 300
        },
        {
            field: 'cantidad_cristales',
            headerName: 'Cantidad de cristales',
            editable: false,
            sortable: false,
            resize: true,
            width: 300
        }
    ]


    // Función que devuelve los toe por medio de su área
    const listToeByArea = async () => {
        const response = await getAllAsignacionesToeByArea({area_id: area_id})
        await getAreaInstalacionInstitucion(response)
        startLoading()
        setRows([])
        let i = 1
        response?.forEach(({id, personal, dosimetro_asignado, cantidad_anillo, cantidad_pd1}) => {
            // Obteniendo datos del personal
            const { nombres: nombreToe, apellidos: apellidoToe } = personal
            let nombres = nombreToe + " " + apellidoToe

            // Verifica si hay dosimetros asignados
            if (dosimetro_asignado?.length > 0) {
                // Filtrando dosimetros asignados por toe_area_id
                // let dosimetros = dosimetro_asignado?.filter(dosimetro => dosimetro?.toe_area_id === personal.id)
                let toe_area_id = id


                if(cantidad_anillo > 0) {
                    let dosimetros_anillo = dosimetro_asignado?.filter(dosimetro => dosimetro?.dosimetro?.tipo_dosimetro === 2)
                    // Verifica si hay dosimetros de tipo anillo
                    if (dosimetros_anillo?.length > 0) {
                        // console.log("dosimetros_anillo", dosimetros_anillo)
                        let codigo = ""
                        dosimetros_anillo?.forEach((dosi) => {
                            codigo = codigo.concat(dosi?.dosimetro.codigo, " / ")
                        })
                        setRows(rows => [...rows, {
                            "key": i,
                            "toe_area_id": toe_area_id,
                            nombres,
                            "codigo": codigo,
                            "tipo_dosimetro": "Anillo",
                            "cantidad_solicitada": cantidad_anillo
                        }])
                        i++
                    } else {
                        setRows(rows => [...rows, {
                            "key": i,
                            "toe_area_id": toe_area_id,
                            nombres,
                            "codigo": "",
                            "tipo_dosimetro": "Anillo",
                            "cantidad_solicitada": cantidad_anillo
                        }])
                        i++
                    }
                }

                if(cantidad_pd1 > 0) {
                    let dosimetros_cuerpo_completo = dosimetro_asignado?.filter(dosimetro => dosimetro?.dosimetro?.tipo_dosimetro === 1)
                    // console.log("dosimetros_cuerpo_completo", dosimetros_cuerpo_completo)
                    let codigo = ""
                    // Verifica si hay dosimetros de tipo Cuerpo completo
                    if (dosimetros_cuerpo_completo?.length > 0) {
                        dosimetros_cuerpo_completo?.forEach((dosi) => {
                            codigo = codigo.concat(dosi?.dosimetro.codigo, " / ")
                        })
                        setRows(rows => [...rows, {
                            "key": i,
                            "toe_area_id": toe_area_id,
                            nombres,
                            "codigo": codigo,
                            "tipo_dosimetro": "Cuerpo completo",
                            "cantidad_solicitada": cantidad_pd1
                        }])
                        i++
                    } else {
                        setRows(rows => [...rows, {
                            "key": i,
                            "toe_area_id": toe_area_id,
                            nombres,
                            "codigo": "",
                            "tipo_dosimetro": "Cuerpo completo",
                            "cantidad_solicitada": cantidad_pd1
                        }])
                        i++
                    }
                }
                // dosimetro_asignado.forEach(({dosimetro}) => {
                //     const codigo = "Código: " +dosimetro.codigo
                //     setRows(rows=>[...rows,  {"key": i, "toe_area_id": id, nombres, codigo, "tipo_dosimetro": "Anillo"}])
                //     i++
                // })
            } else { // No hay dosimetros asignados
                let codigo = undefined
                // Si hay dosimetro solicitado de tipo anillo
                if(cantidad_anillo > 0) {
                    setRows(rows=>[...rows,  {"key": i, "toe_area_id": id, nombres, codigo, "tipo_dosimetro": "Anillo",
                        "cantidad_solicitada": cantidad_anillo}])
                    i++
                }
                // Si hay dosimetro solicitado de tipo cuerpo completo
                if(cantidad_pd1 > 0) {
                    setRows(rows=>[...rows,  {"key": i, "toe_area_id": id, nombres, codigo, "tipo_dosimetro": "Cuerpo completo",
                        "cantidad_solicitada": cantidad_pd1}])
                    i++
                }
            }
        })
        stopLoading()
        // Para la pginación en la URL
        // if (page > response?.last_page) {
        //     setPage(response?.last_page)
        //     history.push(`${window.location.pathname}?page=${response?.last_page}`)
        // }
    }

    // Funcion que obtiene el área, la instalación y la institución del toe seleccionado
    const getAreaInstalacionInstitucion = async (res) => {
        const area = await getAreaById(res[0].area_id)
        setArea(area.nombre)
        setInstalacion(area.instalacion.nombre)
        const institucion = await getInstitucionById(area.instalacion.institucion_id)
        setInstitucion(institucion.nombre)
    }

    // Función que devuelve los tipos de dosímetros que se encuentran disponibles por su tipo
    const listDosimetrosDisponiblesByTipo = async (item) => {
        // startLoading()
        const { tipo_dosimetro } = item
        let tipo = 0
        const params = {}
        tipo_dosimetro === "Cuerpo completo" ? tipo = 1 : tipo = 2
        params.tipo_dosimetro = tipo
        if (codigoDosimetro) params.codigo_dosimetro = codigoDosimetro
        const response = await getDosimetrosDisponiblesByTipo(params)
        setRowsDisponibles([])
        // startLoading()
        let i = 1
        response.forEach(({cantidad_cristales, codigo}) => {
            setRowsDisponibles(rows => [...rows, {"id": i, codigo, cantidad_cristales }])
            i++
        })
        // stopLoading()
    }

    // Función que maneja la selección de los dosimetros disponibles seleccionados
    const handleSelectionRows = (ids) => {
        const selectedIDs = new Set(ids);
        setDosimetrosDisponiblesSeleccionados(
            rowsDisponibles.filter((row) =>
                selectedIDs.has(row.id),
            )
        )
    }

    const handleCodigoDosimetro = (value) => {
        setCodigoDosimetro(value)
    }

    const openModalAsignarDosimetro = async (item) => {
        // Almacenando el item seleccionado
        setItem(item)
        // Asignando el nombre del tipo de dosímetro
        setTipoDosimetro(item.tipo_dosimetro)
        setOpenModalAsignarDosimetro(true)
        setCodigoDosimetro("")
        await listDosimetrosDisponiblesByTipo(item)
    }

    const closeModalAsignarDosimetro = () => {
        limpiarDataAsignarDosimetro()
    }

    // Metodo que guarda los dosimetros seleccionados a asignar
    const saveDosimetroAsignados = async () => {
        // Validando que la cantidad de dosimetros solicitada sea igual a la cantidad de dosimetros seleccionados
        if (item.cantidad_solicitada === dosimetrosDisponiblesSeleccionados.length) {
            // console.log("Dosimetro asignado correctamente", dosimetrosDisponiblesSeleccionados)
            const data = {
                "toe_area_id": item.toe_area_id
            }
            let codigo = []
            dosimetrosDisponiblesSeleccionados.forEach(dosi => {
                codigo.push({"codigo": dosi.codigo})
            })
            data.dosimetros = codigo
            // console.log("data enviada", data)
            const response = await asignarDosimetro(data)
            if (response.error) {
                handleOpenAlert("error", response.error)
            } else {
                handleOpenAlert(
                    "success",
                    `Dosimetros asignados correctamente.`
                )
                await listToeByArea()
                limpiarDataAsignarDosimetro()
            }
        } else {
            handleOpenAlert('error', `Los dosímetros solicitados son ${item.cantidad_solicitada}`)
            // setError(solicitud.error)
            stopLoading()
            return
        }
    }

    // Función que limpia las variables usadas para la selección de los dosímetros disponibles
    const limpiarDataAsignarDosimetro = () => {
        // Limpiando la selección
        setItem("")

        // Limpiando la variable que contiene el nombre de los tipos de dosímetros
        setTipoDosimetro("")

        // Limpia los dosímetros seleccionados a asignar
        setDosimetrosDisponiblesSeleccionados([])

        // Cerrando el modal de los dosímetros disponibles
        setOpenModalAsignarDosimetro(false)
        setCodigoDosimetro("")
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        rows, encabezado,
        openModalAsignar,
        encabezadoDosimetroDisponible, rowsDisponibles,
        tipoDosimetro, codigoDosimetro,
        // Datos del lugar al que pertenece el TOE
        area, institucion, instalacion
    }

    const actions = {
        setAlertOpen,
        handlePageChange,
        openModalAsignarDosimetro,
        closeModalAsignarDosimetro,
        saveDosimetroAsignados,
        handleSelectionRows, handleCodigoDosimetro
    }

    useEffect(() => {
        listToeByArea()
    }, [])

    return { data, actions }
}

export default useAsignacionDosimetriaAreaToePage
