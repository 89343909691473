import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";
import { addRow, findRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import { BsFillChatDotsFill, BsFillChatFill } from "react-icons/bs";
import { MdOutlineBrightnessMedium, MdSearch } from "react-icons/md";
import useUsuarioService from "../../services/administracion/useUsuarioService";
import useSolicitudesRecibidasCCService from "../../services/solicitudes_recibidas/solicitudesRecibidasService";
import useControlAvanceCCService from "../../services/solicitudes_recibidas/controlAvanceService";
import useCCPlanificacionService from "../../services/controlDeCalidad/useActividadesServiceCC";
import usePasoSolicitudesRecibidasService from "../../services/solicitudes_recibidas/pasoService";
import { mensajeCamposNoValidos } from "../../utils/messages";
import useEstadoSolicitudesRecibidasService from "../../services/solicitudes_recibidas/estadoService";
import useClienteServiceCC from "../../services/controlDeCalidad/useClienteServiceCC";
import useReporteEficienciaGlobalService from "../../services/controlDeCalidad/useReporteEficienciaGlobalService";
import ControlAvanceTable from "../../pages/solicitudes_recibidas/tablaControlAvanceCC";
import { fil } from "date-fns/locale";

const useIndicadoresDeCalidadPage = () => {
  const { getReporteIndicadoresDeCalidad } =
    useReporteEficienciaGlobalService();

  const { getControlAvancePorIdCC, createControlAvance } =
    useControlAvanceCCService();

  const history = useHistory();

  useIsLoged();
  const { getMe } = useUsuarioService();
  const [error, setError] = useState(null);
  const [fecha, setFecha] = useState("");
  const [estado, setEstado] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [value, setValue] = useState("Seleccione un rango de fecha");
  const [rows, setRows] = useState([]);

  // Para el rol del usuario
  const [idRol, setIdRol] = useState("");
  //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  //Estado que indica la pagina actua
  const [page, setPage] = useState(queryParams.get("page"));
  //Estado que indica el numero de paginas en total
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(false);

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  //Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [tipos_control_avance, setTipos_control_avance] = useState([]);
  const [fecha_solicitud, setFecha_solicitud] = useState("");
  const [fecha_visita_tecnica, setFecha_visita_tecnica] = useState("");
  const [
    fecha_entrega_de_informe_tecnico,
    setFecha_entrega_de_informe_tecnico,
  ] = useState("");
  const [fecha_entrega_certificado, setFecha_entrega_certificado] =
    useState("");
  const [fecha_notificacion, setFecha_notificacion] = useState("");
  const [fecha_entrega, setfecha_entrega] = useState("");
  const [selectedPaso, setSelectedPasos] = useState(null);

  //   const [tipos_control_avance, setTipos_control_avance] = useState([]);
  //   const [fecha_solicitud, setFecha_solicitud] = useState("");
  //   const [fecha_visita_tecnica, setFecha_visita_tecnica] = useState("");
  //   const [
  //     fecha_entrega_de_informe_tecnico,
  //     setFecha_entrega_de_informe_tecnico,
  //   ] = useState("");
  //   const [fecha_entrega_certificado, setFecha_entrega_certificado] =
  //     useState("");
  //   const [fecha_notificacion, setFecha_notificacion] = useState("");
  //   const [fecha_entrega, setfecha_entrega] = useState("");
  //   const [selectedPaso, setSelectedPasos] = useState(null);
  //   const [selectedPasoToFilter, setSelectedPasosToFilter] = useState(null);
  //   const [selectedEstado, setSelectedPaso] = useState(null);
  //   const [selectedInstituciones, setSelectedInstituciones] = useState(null);
  //   const [actividad_id, setActividad_id] = useState(null);
  //   const [anio, setAnio] = useState("");
  //   const [errorAnio, setErrorAnio] = useState("");
  //   const [helperAnio, setHelperAnio] = useState("");

  const [firstHeaders, setFirstHeaders] = useState([""]);
  const [disablePeriodoSelector, setDisablePeriodoSelector] = useState(false);
  const [enableFechasInputs, setEnableFechasInputs] = useState(true);

  const [secondRowHeaders, setSecondRowHeaders] = useState([""]);
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [selectedPeriodoToFilter, setSelectedPeriodoToFilter] = useState(false);
  const [nombre_institucion, setNombreInstitucion] = useState("");
  const [
    idInstitucionSeleccionadaEnTabla,
    setIdInstitucionSeleccionadaEnTabla,
  ] = useState(null);

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  const checkForErrors = () => {};

  const cancel = () => {
    setFechaInicio("");
    setFechaFin("");
    setNombreInstitucion("");
    setIdInstitucionSeleccionadaEnTabla(null);
    setSelectedPeriodoToFilter(false);
    const load = async () => {
      startLoading();
      //await getReporteIndicadoresDeCalidadCC();
      await await getReporteIndicadoresDeCalidadCC(
        false,
        false,
        false,
        "anual"
      );
      stopLoading();
    };

    load();
  };

  // Para indicar cuando se está consumiendo la API
  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const onChangeInstitucion = (text) => {
    setNombreInstitucion(text);
    // if (!letterNumbersSpaceForAddress(text)) {
    //   setErrorComentario(true);
    //   setHelperComentario(
    //     "La dirección solo debe contener numeros,letras y los caracteres: # - / ,  . ª ° "
    //   );
    // } else {
    //   setErrorComentario(false);
    //   setHelperComentario("");
    // }
  };

  // Funcion que almacena el id del paso para filtro seleccionado
  const handleChangePeriodoToFilter = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedPeriodoToFilter(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedPeriodoToFilter(value);
    }
  };

  //Controlador cambio de pagina
  const handlePageChange = (event, value) => {
    setPage(value);
    //getSolicitudesRecibidasCC();
    history.push(`${window.location.pathname}?page=${value}`);
  };
  const handleClickOpenDialog = (idControlAvance) => {
    //setActividad_id(id);
    getControlAvanceByIdCC(idControlAvance);
    setOpenDialog(true);
  };
  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    setIdInstitucionSeleccionadaEnTabla(null);
    //handleChangePaso(null);
    //cancel();
  };

  useEffect(() => {
    console.log({ msg: "dialogo", openDialog });
  }, [openDialog]);

  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const getReporteIndicadoresDeCalidadCC = useCallback(
    async (
      fecha_inicio = false,
      fecha_fin = false,
      nombre_institucion = false,
      periodo = false
    ) => {
      setLoading(true);

      const { data: response } = await getReporteIndicadoresDeCalidad(
        fecha_inicio,
        fecha_fin,
        nombre_institucion,
        periodo
      );
      if (response.error) {
        handleOpenAlert("error", response.error);
        setError(response.error);
        setRows([]);
        setNumPages(1);
        setLoading(false);
        setFirstHeaders([]);
        setSecondRowHeaders([]);
        return;
      } else {
        setRows([]);
        setNumPages(1);

        //const { indicadores } = response;

        const responsevalidada = Array.isArray(response) ? response : []; 
        const periodo = responsevalidada.map((indicador) => indicador.periodo);
        

        const headers = ["", ...periodo];
        //const secondRowHeaders = ["", "", "", "", "", "", "", "", "", ""];
        const secondRowHeaders = new Array(headers.length).fill("");

        

        const promedio_dias_atender_solicitud = responsevalidada.map(
          (indicador) => indicador.indicadores.promedio_dias_atender_solicitud
        );
        promedio_dias_atender_solicitud.unshift(
          "Promedio de dias para atender solicitud"
        );

        const promedio_dias_generar_reporte = responsevalidada.map(
          (indicador) => indicador.indicadores.promedio_dias_generar_reporte
        );

        promedio_dias_generar_reporte.unshift(
          "Promedio de dias para generar reporte"
        );

        const promedio_dias_generar_certificado = responsevalidada.map(
          (indicador) => indicador.indicadores.promedio_dias_generar_certificado
        );

        promedio_dias_generar_certificado.unshift(
          "Promedio de dias en generar un certificado"
        );

        const promedio_dias_todo_proceso = responsevalidada.map(
          (indicador) => indicador.indicadores.promedio_dias_todo_proceso
        );

        promedio_dias_todo_proceso.unshift("Promedio de todo proceso");

        const filas = [
          promedio_dias_atender_solicitud,
          promedio_dias_generar_reporte,
          promedio_dias_generar_certificado,
          promedio_dias_todo_proceso,
        ];

        setFirstHeaders([]);
        setSecondRowHeaders([]);

        setFirstHeaders(headers);
        setSecondRowHeaders(secondRowHeaders);

        filas.forEach((promedio, i) => {
          const key = i;

          const cell = promedio;
          addRow(key, cell, setRows);
        });
      }
      setLoading(false);
    },
    [getReporteIndicadoresDeCalidad]
  );

  const getControlAvanceByIdCC = useCallback(async (idControlAvance) => {
    const response = await getControlAvancePorIdCC(idControlAvance);
    if (response.error) {
      handleOpenAlert("error", response.error);
      setError(response.error);
      setLoading(false);
      return;
    } else {
      setTipos_control_avance(response.tipos_control_avance);

      //setSelectedPaso({label:response.paso_control_calidad.nombre,value:response.paso_control_calidad.id})
      setFecha_solicitud(response.fecha_solicitud);
      setFecha_visita_tecnica(response.fecha_visita_tecnica);
      setFecha_entrega_de_informe_tecnico(
        response.fecha_entrega_de_informe_tecnico
      );
      setFecha_entrega_certificado(response.fecha_entrega_certificado);
      setFecha_notificacion(response.fecha_notificacion);
      setfecha_entrega(response.fecha_entrega);
      //setSelectedPaso({label:response.paso_control_calidad.nombre,value:response.paso_control_calidad.id})
      setSelectedPasos({
        label: response.paso_control_calidad.nombre,
        value: response.paso_control_calidad.id,
      });
    }
  });

  const onUpdateControlAvanceCC = useCallback(async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const { actividad_id } = await getControlAvancePorIdCC(
      idInstitucionSeleccionadaEnTabla
    );
    startLoading();

    //createControlAvance
    const controlAvance = {
      actividad_id,
      paso_id: selectedPaso.value,
      fecha_visita_tecnica: fecha_visita_tecnica || null,
      fecha_entrega_de_informe_tecnico:
        fecha_entrega_de_informe_tecnico || null,
      fecha_entrega_certificado: fecha_entrega_certificado || null,
      fecha_notificacion: fecha_notificacion || null,
      fecha_entrega: fecha_entrega || null,
      tipos_control_avance: tipos_control_avance.map((tipo) => ({
        tipo_equipo_id: tipo.tipo_equipo_id,
        cantidad_reporte: tipo.cantidad_reporte || 0,
        comentario: tipo.comentario || "",
      })),
    };
    const result = await createControlAvance(controlAvance);
    if (result.error) {
      setErrorMessage(result.error);
      // setErrorMessage(result.error.data.message);
      handleOpenAlert("error", result.error);
    } else {
      const { control_avance } = result;
      handleOpenAlert(
        "success",
        `El control de avance se actualizó correctamente.`
      );
      handleClickCloseDialog();
    }
    stopLoading();
  });

  const loadPeriodoToFilter = async (inputValue) => {
    const response = [
      { id: "anual", name: "anual" },
      { id: "mensual", name: "mensual" },
      { id: "trimestral", name: "trimestral" },
      { id: "semestral", name: "semestral" },
    ];
    //   const response = [{ id: 1, nombre: "juancho" }]
    if (inputValue) {
      // Verifica si hay un valor ingresado
      return dataFiltrada(response, inputValue);
    } else {
      let options = [];
      response?.forEach(({ id, name }) => {
        const value = id;
        const label = name;
        const resultado = {
          label,
          value: value.toString(),
        };
        options = [...options, resultado];
      });
      // console.log(options)
      return options;
    }
  };

  const handleFiltro = () => {
    console.log({ id: "messi", selectedPeriodoToFilter });
    const load = async () =>
      await getReporteIndicadoresDeCalidadCC(
        fecha_inicio || false,
        fecha_fin || false,
        nombre_institucion || false,
        selectedPeriodoToFilter?.value || false
      );
    //if (!anio || anio.length > 3) {
    load();
  };
  useEffect(()=>{
    console.log({id:"noche",selectedPeriodoToFilter})
  },[selectedPeriodoToFilter])

  // Funcion que filtra los datos de acuerdo a un valor escrito
  const dataFiltrada = useCallback(async (data, value) => {
    // Devuelve los datos filtrados por el nombre
    const results = data.filter((resp) =>
      resp.nombre.toLowerCase().includes(value)
    );
    let options = [];
    results.forEach(({ id, nombre }) => {
      const value = id;
      const label = nombre;
      const resultado = {
        label,
        value: value.toString(),
      };
      options = [...options, resultado];
    });
    return options;
  }, []);

  //Carga inical al cargar el componente
  useEffect(() => {
    const load = async () => {
      startLoading();
      await getReporteIndicadoresDeCalidadCC(false, false, false, "anual");
      stopLoading();
    };

    load();
  }, []);

  // useEffect(() => {
  //   const load = async () =>
  //     await getReporteEficienciaGlobalcc(
  //       page,
  //       fecha_inicio || false,
  //       fecha_fin || false,
  //       nombre_institucion || false
  //     );
  //   //if (!anio || anio.length > 3) {
  //   load();
  //   //}
  // }, [page]);

  useEffect(() => {
    if (idInstitucionSeleccionadaEnTabla !== null) {
      handleClickOpenDialog(idInstitucionSeleccionadaEnTabla);
    }
  }, [idInstitucionSeleccionadaEnTabla]);

  useEffect(() => {
    // if (selectedPeriodoToFilter !== false || selectedPeriodoToFilter !== null) {
      setEnableFechasInputs(true);
      setDisablePeriodoSelector(false)
    if (selectedPeriodoToFilter) {
      setEnableFechasInputs(false);
    }
    if (fecha_inicio !== "" || fecha_fin !== "") {
      setDisablePeriodoSelector(true)
    }
  }, [selectedPeriodoToFilter, fecha_inicio, fecha_fin]);

  const data = {
    rows,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    numPages,
    page,
    value,
    idRol,
    MdSearch,
    openDialog,
    secondRowHeaders,
    firstHeaders,
    setFechaFin,
    setFechaInicio,
    fecha_inicio,
    fecha_fin,
    nombre_institucion,
    idInstitucionSeleccionadaEnTabla,
    setIdInstitucionSeleccionadaEnTabla,
    tipos_control_avance,
    fecha_solicitud,
    fecha_visita_tecnica,
    fecha_entrega_de_informe_tecnico,
    fecha_entrega_certificado,
    fecha_notificacion,
    fecha_entrega,
    selectedPaso,
    setTipos_control_avance,
    selectedPeriodoToFilter,
    disablePeriodoSelector,
    enableFechasInputs,
  };
  const actions = {
    handlePageChange,
    handleOpenAlert,
    onChangeInstitucion,
    handleFiltro,
    cancel,
    setAlertOpen,
    handleClickCloseDialog,
    onUpdateControlAvanceCC,
    loadPeriodoToFilter,
    handleChangePeriodoToFilter,
  };

  return { data, actions };
};

export default useIndicadoresDeCalidadPage;
