import AppFrame from "../../components/AppFrame";
import { Link as RouterLink } from "react-router-dom";
import Button from "../../components/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../components/DataTable";
import Alert from "../../components/Alert/Alert";
import FormHeader from "../../components/FormHeader";
import Typography from "@material-ui/core/Typography";
import Progress from "../../components/Progress/Progress";
import DatePickerRange from "../../components/DatePickerRange/DatePickerRange";
import Pagination from "../../components/Pagination";
import { RiGroupLine, RiRestartLine } from "react-icons/ri";
import useSolicitudesPage from "../../hooks/cotizacion/useSolicitudesPage";
import useSolicitudesRecibidasCCPage from "../../hooks/solicitudes_recibidas/useSolicitudesRecibidas";
import Dialog from "../../components/Dialog/Dialog";
import ReactSelect from "../../components/ReactSelect";
import Input from "../../components/Input/Input";
import useHistorialClientCCPage from "../../hooks/historialDeReportes/useclienteHistorial";
import useHistorialActividadCCPage from "../../hooks/historialDeReportes/useActividadHistorial";
import EficienciaGlobalControlAvanceCC from "../controlDeCalidad/EdicionComentarioControlAvance";
import EficienciaGlobalControlAvanceHistorialCC from "./EficienciaGlobalControlAvanceHistorial";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2em",
    paddingBottom: "2em",
    paddingLeft: "10em",
    paddingRight: "10em",
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

const encabezado = ["Reporte", "Fecha"];

const ActividadHistorialPage = () => {
  const classes = useStyles();
  const { data, actions } = useHistorialActividadCCPage();
  const {
    rows,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    numPages,
    page,
    value,
    idRol,
    MdSearch,
    openDialog,
    selectedEstado,
    selectedPasoToFilter,
    selectedInstituciones,
    setErrorAnio,
    setHelperAnio,
    anio,
  } = data;
  const {
    setAlertOpen,
    handleSelectDate,
    handlePageChange,
    botonesDataTable,
    handleLimpiarFechas,
    handleClickOpenDialog,
    handleClickCloseDialog,
    onUpdateControlAvanceCC,
    loadEstados,
    handleChangeEstado,
    loadPasosToFilter,
    handleChangePasoToFilter,
    loadInstituciones,
    handleChangeInstituciones,
    onChangeAnio,
    buscarInstitucion,
    cancelarBusqueda,
    handleVolver,
    onUploadHistorialActivityFile,
  } = actions;

  return (
    <AppFrame loged={true}>
      <Progress open={loading} setOpen={setAlertOpen} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <Paper className={classes.paper}>
            {/* Alerta */}
            <Alert
              open={alertOpen}
              setOpen={setAlertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
            {/* Titulo */}
            <FormHeader
              title="Reporte de Actividad de clientes"
              subtitle="Datos Requeridos *"
            >
              <RiGroupLine />
            </FormHeader>
            <Grid container item xs={12} justify="center" spacing={2}>
            <Grid className={classes.input} item xs={6} sm={3}>
              <Button func={handleVolver} variant="outlined" text="VOLVER" />
            </Grid>
            </Grid>
            {/* DataTable */}
            <Grid className={classes.input} item xs={12}>
              <DataTable
                headers={encabezado}
                rows={rows}
                customBtn={[
                  {
                    func: handleClickOpenDialog,
                    icon: MdSearch,
                    tooltip: "Ver solicitud",
                  },
                ]}
              />
            </Grid>
            {/* Paginación */}
            <Pagination
              page={Number(page)}
              numPages={Number(numPages)}
              onChangePage={handlePageChange}
            />
          </Paper>
        </Grid>
      </Grid>
      {/* MODAL PARA GUARDAR */}
      <Dialog
        open={openDialog}
        handleClose={handleClickCloseDialog}
        handleAccept={onUploadHistorialActivityFile}
        title={"Historial de cliente por evento"}
        content={
          <EficienciaGlobalControlAvanceHistorialCC
            data={data}
            actions={actions}
          />
        }
      />
    </AppFrame>
  );
};

export default ActividadHistorialPage;
