import React from 'react'
import AppFrame from '../../components/AppFrame'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import AppColors from './../../utils/colors'
import ComboBox from '../../components/ComboBox'
import useNuevaSolicitudPage from '../../hooks/cotizacion/useNuevaSolicitudPage'
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import ProgressBar from '../../components/ProgressBar'
import FileInput from '../../components/FileInput'
import Typography from '@material-ui/core/Typography'
import Input from '../../components/Input'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
        paddingRight: '1em',
        paddingLeft: '1em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
    },
    typography: {
        fontSize: 18,
    },
    typo: {
        color: AppColors.blue,
    },
    root: {
        '& .MuiTextField-root': {
            paddingTop: '3em',
            margin: theme.spacing(1),
            width: '100%',
        },
    },
}));

const NuevaSolicitud = () => {
    const classes = useStyles()
    const { data, actions } = useNuevaSolicitudPage()
    const { datestring, tiposDeSolicitudes, tipoSolicitud,
        fecha, comentario, alertOpen, alertSeverity,
        alertMessage, loading, progress, nombre, objArchivo } = data
    const { handleOnChangeArchivo,
        onCreateSolicitud, cancelarSolicitud, setAlertOpen,
        handleOnChangeTipoSolicitud, handleOnChangeComentario } = actions

    return (
        <AppFrame loged={true}>
            <Progress open={loading>0} />
            {
                //1 es el id del permiso gestion ministerio
                //useCheckPermiso(1) ?
                <Grid container spacing={1}>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} md={10}>
                            <Paper className={classes.paper}>
                                <Alert open={alertOpen} setOpen={setAlertOpen}
                                    severity={alertSeverity}
                                    message={alertMessage} />

                                {/**/}
                                <Grid className={classes.input} container spacing={2} columns={16}>
                                    <Grid item xs={8}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Solicitudes Realizadas</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <ComboBox
                                                label="Todas las solicitudes"
                                                list={tiposDeSolicitudes}
                                                value={tipoSolicitud}
                                                onChange={(value) => { handleOnChangeTipoSolicitud(value) }}
                                                // onChange={(value) => { setTipoSolicitud(value) }}
                                                required={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4} >
                                        <Paper width="50%">
                                            <Grid item xs={12} >
                                                <Typography className={classes.typography} align="center">Fecha</Typography>
                                                <Typography className={classes.typo} variant="h6" align="center" value={fecha}>{datestring} </Typography>

                                            </Grid>
                                        </Paper>
                                    </Grid>

                                </Grid>

                                {/*Codigo para la Tabla*/}
                                <Grid className={classes.root} container direction="row" item xs={12}>
                                    <Input
                                        multiline
                                        placeholder="Compártanos acá lo que desea comunicar …"
                                        variant="outlined"
                                        onChange={handleOnChangeComentario}
                                        value={comentario}
                                        label='Comentario'
                                    />
                                </Grid>

                                <Grid className={classes.input} container direction="row" item xs={12}>
                                    {
                                        progress >= 0 ?
                                            <div>
                                                <Typography>{nombre}</Typography>
                                                <ProgressBar value={progress} />
                                            </div>
                                            :
                                            <FileInput label="Adjuntar Archivo (opcional)"
                                                helper="Seleccione el archivo a subir"
                                                placeholder="Seleccione el archivo a subir"
                                                onChange={handleOnChangeArchivo}
                                                required={false}
                                                       files={objArchivo}
                                            />
                                    }
                                </Grid>

                                <Grid container justifyContent="center" spacing={2}
                                    alignItems="center" direction="row" item xs={12}>
                                    <Grid item xs={3} sm={3} md={2}>
                                        <Button
                                            variant="contained"
                                            text={"GUARDAR"}
                                            func={onCreateSolicitud}
                                        />
                                    </Grid>

                                    <Grid item xs={3} sm={3} md={2}>
                                        <Button
                                            func={cancelarSolicitud}
                                            variant="outlined"
                                            text="CANCELAR" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                // :
                // <PermisoDenegado/>
            }
        </AppFrame>
    )
}


export default NuevaSolicitud