import React from 'react'
import AppFrame from './../../components/AppFrame'
import FormHeader from './../../components/FormHeader'
import Input from './../../components/Input'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import FormDivider from './../../components/FormDivider'
import FileInput from './../../components/FileInput'
import ComboBox from './../../components/ComboBox'
import DataTable from './../../components/DataTable'
import { makeStyles } from '@material-ui/core/styles'
import MaterialDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {Link as RouterLink} from 'react-router-dom'
import ReactSelect from '../../components/ReactSelect'
import CheckBox from '../../components/CheckBox'
import Dialog from '../../components/Dialog'
import Pagination from '../../components/Pagination'
import Progress from '../../components/Progress/Progress'
import useDatosPersonalInstitucionPage from '../../hooks/inscripcion/useDatosPersonalInstitucionPage'
import Alert from '../../components/Alert'
import PdfViewer from '../../components/PdfViewer'
import { StepperInscripcion } from './DatosDelegadoPage'
import {MaskField} from "react-mask-field";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    table: {
        marginTop: '2em'
    }
}))

const encabezado = ["Nombres", "Apellidos", "Cargo",  "Identificador"]

function CustomMaskNumberField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="____-____" replacement={{ _: /\d/ }} {...otherProps} />;
}
//Mask para DUI
function CustomMaskDUIField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="________-_" replacement={{ _: /\d/ }} {...otherProps} />;
}
//Mask para licencia
function CustomMaskLicenceField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="________-_" replacement={{ _: /\d/ }} {...otherProps} />;
}
//Mask para Pasaporte
function CustomMaskPassportField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="Ñ_______" replacement={{Ñ: /[A-Za-z]/, _: /\d/ }} {...otherProps} />;
}
function MaskIdentificador(identificarPor){
    switch (identificarPor) {
        case 'dui':
            return { inputComponent: CustomMaskDUIField };

        case 'licencia':
            return { inputComponent: CustomMaskLicenceField };

        case 'pasaporte':
            return { inputComponent: CustomMaskPassportField };
    
        default:
            break;
    }

}
const DatosPersonalInstitucionPage = props => {
    const classes = useStyles() 
    const {data, actions} = useDatosPersonalInstitucionPage()
    const {
        rows,
        nacionalidades,
        nombres,
        errorNombres,
        helperNombres,
        apellidos,
        errorApellidos,
        helperApellidos,
        identificarPor,
        grado,
        errorGrado,
        helperGrado,
        nacimiento,
        errorNacimiento,
        helperNacimiento,
        telefono,
        errorTelefono,
        helperTelefono,
        sexo,
        identificador,
        errorIdentificador,
        helperIdentificador,
        nacionalidad,
        celular,
        errorCelular,
        helperCelular,
        cargo,
        archivoDocumento,
        areasConsulta,
        id,
        openEdit,
        openDelete,
        personalToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        instalacion,
        allResponsabilidades,
        responsabilidades,
        objArchivo,
        openFileModal,
        filePageNumber,
        fileNumPages,
        idSolicitud
    } = data

    const {
        onCreatePersonal,
        onUpdatePersonal,
        onDeletePersonal,
        editClick,
        onChangeNombres,
        onChangeApellidos,
        onChangeGrado,
        onChangeIdentificador,
        onChangeTelefono, 
        onChangeCelular, 
        setCargo,
        setInstalacion,
        setSexo,
        onChangeIdentificarPor,
        setNacionalidad,
        onChangeNacimiento,
        setAreasConsulta,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setPersonalToDelete,
        setAlertOpen,
        handlePageChange,
        handleGetInstalaciones,
        handleGetCargos,
        handleGetAreas,
        handleOnChangeArchivo,
        handleOnChangeCheckBox,
        handleFilePageChange,
        handleCloseFileModal,
        onDocumentLoadSuccess,
        handleChangeEstadoDisponible,
    } = actions

    return (
        <AppFrame loged = {false}>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid container item xs={12} sm= {8}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Datos internos | Personal de la 
                                institucion">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid item xs = {10}>
                                <FormDivider text="Datos del personal de la 
                                institucion"/>
                            </Grid>
                            <Grid className={classes.input} item xs = {10}>
                                <ReactSelect autoFocus = {true} value = {instalacion}
                                    onChange={setInstalacion}
                                    asyncFunc = {handleGetInstalaciones}
                                    label="Instalacion"
                                    placeholder = "Busque instalacion por nombre"
                                    disabled = {id ? true : false}
                                    components = {
                                        instalacion ? 
                                        {
                                            Menu: () => null
                                        }
                                        :
                                        undefined
                                    }
                                    isClearable = {true}
                                    />
                            </Grid>
                            <Grid container item xs = {10} sm={5}>
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text" label="Nombres" 
                                        placeholder="Ingrese los nombres"
                                        value = {nombres}
                                        onChange={onChangeNombres}
                                        error = {errorNombres}
                                        helper = {helperNombres}
                                        disabled = {instalacion ? false : true}
                                        />
                                </Grid>
                                <Grid item xs = {12} sm={6}>
                                    <Input type = "text" label="Apellidos" 
                                        placeholder="Ingrese los apellidos"
                                        value = {apellidos}
                                        onChange={onChangeApellidos}
                                        error = {errorApellidos}
                                        helper = {helperApellidos}
                                        disabled = {instalacion ? false : true}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <ComboBox label = "Identificar por"
                                        value={identificarPor} 
                                        list = {[{
                                            label: "DUI",
                                            value: "dui"
                                        
                                        },
                                        {
                                            label: "Licencia",
                                            value: "licencia",
                                            placeholder :"Ingrese el licencia",
                                        },
                                        {
                                            label: "Pasaporte",
                                            value: "pasaporte",
                                            placeholder:"Ingrese el pasaporte",
                                        }]}
                                        onChange = {(value)=>
                                            onChangeIdentificarPor(value)}
                                        disabled = {instalacion ? false : true}
                                            
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Grado academico" 
                                        placeholder="Ingrese el grado academico"
                                        value = {grado}
                                        onChange={onChangeGrado}
                                        error = {errorGrado}
                                        helper = {helperGrado}
                                        disabled = {instalacion ? false : true}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "date" label="Fecha de 
                                        nacimiento" 
                                        placeholder="Ingrese la fecha de 
                                        nacimiento"
                                        value = {nacimiento}
                                        onChange={onChangeNacimiento}
                                        error = {errorNacimiento}
                                        helper = {helperNacimiento}
                                        disabled = {instalacion ? false : true}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Telefono" 
                                        placeholder="Ingrese el telefono"
                                        value = {telefono}
                                        onChange={onChangeTelefono}
                                        error = {errorTelefono}
                                        helper = {helperTelefono}
                                        disabled = {instalacion ? false : true}
                                           InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                            </Grid>
                            <Grid container item xs = {10} sm={5}>
                                <Grid item xs = {12}>
                                    <ComboBox label = "Sexo"
                                        value={sexo} 
                                        list = {[{
                                            label: "Femenino",
                                            value: false
                                        },
                                        {
                                            label: "Masculino",
                                            value: true
                                        }]}
                                        onChange = {(value)=>
                                            setSexo(value)}
                                        disabled = {instalacion ? false : true}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Identificador" 
                                        placeholder="Ingrese el identificador"
                                        value = {identificador}
                                        onChange={onChangeIdentificador}
                                        error = {errorIdentificador}
                                        helper = {helperIdentificador}
                                        disabled = {instalacion ? false : true}

                                        InputProps={MaskIdentificador(identificarPor)}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12}>
                                    <ReactSelect value = {nacionalidad}
                                        onChange={setNacionalidad}
                                        options = {nacionalidades}
                                        label="Nacionalidad"
                                        placeholder = "Busque nacionalidad"
                                        disabled = {instalacion ? false : true}
                                        
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Telefono 
                                        celular" 
                                        placeholder="Ingrese el telefono 
                                            celular"
                                        value = {celular}
                                        onChange={onChangeCelular}
                                        error = {errorCelular}
                                        helper = {helperCelular}
                                        disabled = {instalacion ? false : true}
                                           InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <ReactSelect value = {cargo}
                                        onChange={setCargo}
                                        asyncFunc = {handleGetCargos}
                                        label="Cargo"
                                        placeholder = "Busque cargo"
                                        disabled = {instalacion ? false : true}
                                        isClearable = {true}
                                        />
                                </Grid>
                            </Grid>
                            <Grid container item xs = {10}>
                                <FileInput label="Archivo con 
                                    documento de identidad" 
                                    placeholder="Ingrese el archivo con 
                                    documento de identidad"
                                    onChange = {handleOnChangeArchivo}
                                    required={true}
                                    files = {objArchivo}
                                    disabled = {instalacion ? false : true}
                                    />
                                <MaterialDialog
                                    open={openFileModal}
                                    onClose={handleCloseFileModal}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    maxWidth = 'lg'
                                >
                                    <DialogContent>
                                        <PdfViewer file = {archivoDocumento} onDocumentLoadSuccess = {onDocumentLoadSuccess}
                                            pageNumber = {filePageNumber} numPages = {fileNumPages} scale={1}/>
                                        <Pagination page={Number(filePageNumber)}
                                            numPages={Number(fileNumPages)}
                                            onChangePage = {handleFilePageChange}
                                            />
                                    </DialogContent>
                                </MaterialDialog>
                            </Grid>
                            <Grid item xs = {10}>
                                <FormDivider text="Responsabilidad (Si el personal posee responsabilidades en otra instalacion o en otra area debe añadir otro registro en dicha instalacion)"/>
                            </Grid>
                            <Grid container item xs = {10}>
                            {
                                allResponsabilidades.map((responsabilidad) => 
                                <Grid className={classes.input} 
                                    item xs = {12} sm={6}
                                    key={responsabilidad.id}>
                                    <CheckBox label={responsabilidad.nombre} 
                                        checked={responsabilidades ? 
                                            responsabilidades.includes(responsabilidad.id) : false} 
                                        onChange={()=>handleOnChangeCheckBox(responsabilidad.id)}
                                        disabled = {instalacion ? false : true}
                                        />
                                </Grid>
                                )
                            }
                            </Grid>
                            <Grid item xs = {10}>
                                {
                                    instalacion ?
                                    <ReactSelect value = {areasConsulta}
                                        onChange={setAreasConsulta}
                                        asyncFunc = {handleGetAreas}
                                        label="Areas"
                                        placeholder = "Busque area por nombre"
                                        isMulti = {true}
                                        isClearable = {true}
                                        />
                                    :
                                    null
                                }
                            </Grid>
                            <Grid container item direction="row"
                                justifyContent="center" spacing = {2}>
                                <Grid className={classes.input} item 
                                    xs={6} sm= {3}>
                                    <Button
                                        func = {
                                            id?
                                            editCancel
                                            :
                                            cancel
                                        }
                                        variant = "outlined"
                                        text="CANCELAR"
                                        disabled = {instalacion ? false : true}
                                        />
                                </Grid>
                                <Grid className={classes.input} item
                                    xs={6} sm= {3}>
                                    <Button
                                        variant = "contained"
                                        text={id?"EDITAR":"AGREGAR"}
                                        func = {id?
                                            handleClickOpenEdit
                                            :
                                            onCreatePersonal}
                                        disabled = {instalacion ? false : true}
                                        />
                                    <Dialog open={openEdit} 
                                        handleClose = {handleCloseEdit} 
                                        handleAccept = {onUpdatePersonal} 
                                        title = {"Desea actualizar el registro?"} 
                                        content = {`Los datos del personal "${nombres}" se actualizaran.`}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid className={classes.table} container>
                        <Grid item xs={12}>
                            <DataTable headers={encabezado} rows={rows}
                                editFnc={(key) => editClick(key)}
                                deleteFnc={idSolicitud ? null : (key) => handleClickOpenDelete(key)}
                                disableFnc={idSolicitud ? handleChangeEstadoDisponible : null}/>
                        </Grid>
                        <Grid item xs = {12}>
                            <Pagination page={Number(page)}
                                numPages={Number(numPages)}
                                onChangePage = {handlePageChange}
                                />
                        </Grid>
                        <Dialog open={openDelete} 
                            handleClose = {handleCloseDelete} 
                            handleAccept = {onDeletePersonal} 
                            title = {"Desea eliminar el registro?"} 
                            content = { 
                                findRow(personalToDelete, rows) ?
                                `El personal con nombres "${ findRow(personalToDelete, rows).cells[0] }" sera eliminado del sistema.`
                                :
                                ""
                                }
                            transitionFunc = {() => 
                                setPersonalToDelete(null)}/>
                    </Grid>
                    <Grid container direction="row"
                        justifyContent="center" spacing = {2}>
                        <Grid className={classes.input} item 
                            xs = {3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/areas'}
                                variant = "outlined"
                                text="ANTERIOR"/>
                        </Grid>
                        <Grid className={classes.input} item
                            xs = {3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/toe'}
                                variant = "contained"
                                text="SIGUIENTE"
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <StepperInscripcion activeStep={3}/>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default DatosPersonalInstitucionPage
