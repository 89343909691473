import axios from "axios";
import url from "../utils/url";
import headers from "../utils/headers";
import useErrorHandler from "../utils/useErrorHandler";
import { useCallback } from "react";

const clienteUrl = `${url}clientes`;
// const clienteUrl = `http://localhost:3001/objetos`

const useClienteServiceCC = () => {
  const { errorHandler } = useErrorHandler();

  const createCliente = useCallback(
    async (cliente) => {
      try {
        //const response = await axios.post(clienteUrl, cliente)

        const response = await axios.post(clienteUrl, cliente, {
          headers: headers(),
        });

        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getClienteById = useCallback(
    async (cliente) => {
      const requestUrl = `${clienteUrl}/${cliente}`

    //   const requestUrl = `http://localhost:3001/instituciones/${cliente}`;
      try {
        // const response = await axios.get(requestUrl)
        //TODO hacer este cambio para incluir los headers
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getClienteByIdNew = useCallback(
    async (cliente) => {
      const requestUrl = `${url}institucion/${cliente}`
    //   const requestUrl = `http://localhost:3001/instituciones/${cliente}`;
      try {
        // const response = await axios.get(requestUrl)
        //TODO hacer este cambio para incluir los headers
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getClientes = useCallback(
    async (page) => {
      const requestUrl = `${clienteUrl}?page=${page}`;
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        // const response = await axios.get(requestUrl)
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getClientesWithoutPagination = useCallback(
    async (page) => {
      const requestUrl = `${url}instituciones`;
      try {
         const response = await axios.get(requestUrl, {headers: headers()})
        //const response = await axios.get(requestUrl);
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getMe = useCallback(async () => {
    const requestUrl = `${clienteUrl}/me`;
    try {
      const response = await axios.get(requestUrl, { headers: headers() });
      const { data } = response;
      return data;
    } catch (error) {
      return errorHandler(error);
    }
  }, [errorHandler]);

  const updateCliente = useCallback(
    async (cliente, cambios) => {
      const requestUrl = `${clienteUrl}/${cliente}`;
      try {
        const response = await axios.put(requestUrl, cambios, {
          headers: headers(),
        });
        // const response = await axios.put(requestUrl, cambios)
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const deleteCliente = useCallback(
    async (cliente, payload) => {
      const requestUrl = `${clienteUrl}/${cliente}`;
      try {
        const response = await axios.delete(requestUrl, { headers: headers() });
        // const response = await axios.delete(requestUrl, { data: payload })
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    createCliente,
    getClienteById,
    getClientes,
    getMe,
    updateCliente,
    deleteCliente,
    getClientesWithoutPagination,
    getClienteByIdNew,
  };
};

export default useClienteServiceCC;
