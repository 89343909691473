import axios from 'axios'
import useErrorHandler from '../utils/useErrorHandler'
import headers from '../utils/headers'
import url from "../utils/url"
import { useCallback } from 'react'

const toeUrl = `${url}inscripcion/toe`

const useTOEService = () => {
    const { errorHandler } = useErrorHandler()

    const createTOE = useCallback(
        async (toe) => {
            const requestUrl = `${toeUrl}`
            try {
                const response = await axios.post(requestUrl, toe,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    const createEditTOE = useCallback(
        async (toe) => {
            const requestUrl = `${toeUrl}/edit`
            try {
                const response = await axios.post(requestUrl, toe,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getTOEById = useCallback(
        async (toe) => {
            const requestUrl = `${toeUrl}/${toe}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getListaDeTOE = useCallback(
        async (page) => {
            const requestUrl = `${toeUrl}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateTOE = useCallback(
        async (toe, cambios) => {
            console.log('id toe a editar', toe)
            const requestUrl = `${toeUrl}/${toe}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const updateEditTOE = useCallback(
        async (toe, cambios) => {
            console.log('id toe a editar', toe)
            const requestUrl = `${toeUrl}/edit/${toe}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {
                        headers: headers()
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const deleteTOE = useCallback(
        async (toe) => {
            const requestUrl = `${toeUrl}/${toe}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const changeStatusTOE = useCallback(
        async (toe) => {
            const requestUrl = `${toeUrl}/edit/status/${toe}`
            try {
                const response = await axios.put(requestUrl, "", {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    //TODO mover al hook del servicio de practicas y subpracticas
    const getPracticas = useCallback(
        async () => {
            //practicas activas
            const requestUrl = `${url}practicas`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getSubpracticaByNombreAndPractica = useCallback(
        async (busqueda, practica) => {
            const requestUrl = `${url}subpracticas/busqueda`
            try {
                const response = await axios.get(requestUrl, {
                    params: {busqueda: busqueda, practica_id: practica},
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    //TODO mover al hook de personal
    const getPersonalByNombreAndInstalacion = useCallback(
        async (busqueda, instalacion) => {
            const requestUrl = `${url}inscripcion/personal/busqueda`
            try {
                const response = await axios.get(requestUrl, {
                    params: {busqueda, instalacion_id: instalacion},
                    headers: headers()
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createTOE, createEditTOE, getTOEById, getListaDeTOE, updateTOE, updateEditTOE, deleteTOE,
        changeStatusTOE ,getPracticas, getSubpracticaByNombreAndPractica, getPersonalByNombreAndInstalacion }
}

export default useTOEService
