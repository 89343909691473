// import * as fs from "fs";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
  AlignmentType,
  convertInchesToTwip,
  ShadingType,
  // HeadingLevel,
  // NumberFormat,
  PageOrientation,
  convertMillimetersToTwip,
  VerticalAlign,
  // TextDirection,
  LevelFormat,
  ImageRun,
  PageNumber
} from "docx";

import imageCIANBase64Data from "./imagesBase64/logoCIAN"
import imageUESBase64Data from "./imagesBase64/logoUES";
import moment from "moment";

const useCrearWord = (res) => {

  const generarWord = (res) => {
    console.log("Generando word", res);
    const {area, dosimetros_asignados, numero_reporte} = res
    const {nombre: nombreArea, periodo_control, instalacion} = area
    const {institucion} = instalacion
    const {nombre: nombreInstitucion, telefono, direccion} = institucion
    const {detalle,  municipio} = direccion
    const {nombre: nombreMunicipio,  departamento} = municipio
    const {nombre: nombreDepartamento} = departamento

    const stylesFont = { font: "Calibri Light", size: 18 }
    const stylesFontObservacion =  {...stylesFont, color: "#BDD6EE"}

    let is_ND1 = false, // No devolvió Dosímetro PD1
        is_NDA = false, // No devolvió Dosímetro anillo
        is_IC = false, // Iniciar control
        is_SC = false, // Sale de control
        is_R = false, // Reposición
        is_DD = false, // Dosímetro dañado
        is_DNU = false, // Dosímetro no utilizado
        is_LNR = false // Lectura no registrada

    const cellText = (text, styles = stylesFont) => {
      return {
        children: [
          new Paragraph({
            children: [
              new TextRun({ text: text, ...styles },)
            ],
            alignment: AlignmentType.CENTER
          })],
        verticalAlign: VerticalAlign.CENTER,
      }
    }

    // const cellTextTable = (text, bold = false, italics = false, alignment = "left", styles = stylesFont) => {
    //   return {
    //     children: [
    //       new Paragraph({
    //         children: [
    //           new TextRun({ text: text, ...styles, bold: bold, italics: italics },)
    //         ],
    //         alignment: alignment
    //       })],
    //     verticalAlign: VerticalAlign.CENTER,
    //   }
    // }

    const cellBackground = {
      fill: "D0CECE",
      type: ShadingType.CLEAR,
      color: "auto",
    }

    const whitoutBorders = {
      top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
      bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
      left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
      right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    }

    const textTableCell = (text, rowSpan = '', columnSpan = '', bold = false, italics = false,
      alignment = "left", width = '', styles = stylesFont, borders = whitoutBorders) => {
      return [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({ text: text, ...styles, bold: bold, italics: italics },)
              ],
              alignment: alignment
            })],
          verticalAlign: VerticalAlign.CENTER,
          rowSpan: rowSpan,
          columnSpan: columnSpan,
          borders: borders,
          width: width
        })
      ]
    }


    const rowsExample = (data) => {
      let array = []
      const estadoDosimetro = ['ND1','NDA','IC','SC','R','DD','DNU','LNR']

      for (let index = 0; index < data?.length; index++) {
        const {toe_area, dosimetro_id, tipo_dosimetro, dosis_efectiva, observaciones} = data[index]
        let {dosis_efectiva_acumulada} = data[index]

        let observacion = ""
        let observacionBold = false

        observaciones?.forEach((ob, index) => {
          index === observaciones.length - 1 ? observacion += ob : observacion+= `${ob}, `

          if (!observacionBold) observacionBold = estadoDosimetro.includes(ob.toString().toUpperCase())

          if (!is_ND1) is_ND1 = ob.toString().toUpperCase().includes('ND1')
          if (!is_NDA) is_NDA = ob.toString().toUpperCase().includes('NDA')
          if (!is_IC) is_IC = ob.toString().toUpperCase().includes('IC')
          if (!is_SC) is_SC = ob.toString().toUpperCase().includes('SC')
          if (!is_R) is_R = ob.toString().toUpperCase().includes('R')
          if (!is_DD) is_DD = ob.toString().toUpperCase().includes('DD')
          if (!is_DNU) is_DNU = ob.toString().toUpperCase().includes('DNU')
          if (!is_LNR) is_LNR = ob.toString().toUpperCase().includes('LNR')
        })

        const styleCellText = {...stylesFont, bold: observacionBold}

        if (dosis_efectiva_acumulada.toString() === 'VMLR') dosis_efectiva_acumulada = ""

        const row = new TableRow({
          children: [
            new TableCell({
              ...cellText((1 + index).toString()),
            }),
            //  Nombre del usuario
            new TableCell({
              ...cellText(toe_area?.nombres  + " " + toe_area?.apellidos),
            }),

            //  * No. Dosímetro *
            //  (PD1)
            new TableCell({
              ...cellText(tipo_dosimetro === 1 ? dosimetro_id.toString() : "", styleCellText),
            }),
            //  (Anillo)
            new TableCell({
              ...cellText(tipo_dosimetro === 2 ? dosimetro_id.toString() : "", styleCellText),
            }),

            //  * Dosis Efectiva (mSv) *
            //  PD Hp(10)
            new TableCell({
              ...cellText(tipo_dosimetro === 1 ? dosis_efectiva.toString() : ""),
            }),
            //  Anillo Hp(0.07)
            new TableCell({
              ...cellText(tipo_dosimetro === 2 ? dosis_efectiva.toString() : ""),
            }),

            //  * Dosis Ac. Anual (mSv) *
            //  PD Hp(10)
            new TableCell({
              ...cellText(tipo_dosimetro === 1 ? dosis_efectiva_acumulada.toString() : ""),
            }),
            //  Anillo Hp(0.07)
            new TableCell({
              ...cellText(tipo_dosimetro === 2 ? dosis_efectiva_acumulada.toString() : ""),
            }),

            new TableCell({
              ...cellText(observacion, styleCellText),
            }),
          ],
        })
        array.push(row)
      }
      return array
    }

    const numberedList = (text, italics = true, bold = false, size = 16) => {
      return [
        new Paragraph({
          children: [
            new TextRun({
              text: text,
              font: "Calibri Light",
              size: size,
              italics: italics,
              bold: bold
            }),
          ],
          numbering: {
            reference: "my-number-numbering-reference",
            level: 0,
          },
        }),
      ]
    }

    const tablaDatosGenerales = new Table({
      rows: [
        // Tabla sin background
        new TableRow({
          children: [
            ...textTableCell(`INFORME NO. ${numero_reporte} Año ${new Date().getFullYear()}`, "", 6, true, false, "center"),
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("CODIGO DE INSTITUCION:", "", "", false, false, "left", {size:18, type: WidthType.PERCENTAGE}),
            ...textTableCell("", "", 3),
            ...textTableCell("FECHA DE EMISION:"),
            ...textTableCell(moment(new Date()).format("DD/MM/YYYY"))
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("INSTITUCION:", "", "",  false, false, 'left', {size:10, type: WidthType.PERCENTAGE}),
            ...textTableCell(nombreInstitucion, "", 3),
            ...textTableCell("AREA:"),
            ...textTableCell(nombreArea)
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("DIRECCION:"),
            ...textTableCell(detalle, "", 5)
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("MUNICIPIO:"),
            ...textTableCell(nombreMunicipio, "", 3),
            ...textTableCell("DEPARTAMENTO:"),
            ...textTableCell(nombreDepartamento)
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("TELEFONO:"),
            ...textTableCell(telefono, ""),
            ...textTableCell("FAX:"),
            ...textTableCell("", ""),
            ...textTableCell("FECHA LECTURA:"),
            ...textTableCell("")
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("PERIODO DE CONTROL:", ""),
            ...textTableCell(periodo_control, "", 3),
            ...textTableCell(""),
            ...textTableCell(""),
            // ...textTableCell(`Página 1 de 1`)
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("PERIODO DE USO:", ""),
            ...textTableCell("", ""),
            ...textTableCell("-"),
            ...textTableCell("", "", 3)
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("", "", 6)
          ]
        })
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });

    const tablaGeneral = new Table({
      rows: [
        // Encabezado de tabla con background
        new TableRow({
          children: [
            new TableCell({
              ...cellText("Nº"),
              rowSpan: 2,
              shading: cellBackground
            }),
            new TableCell({
              ...cellText("NOMBRE DEL USUARIO"),
              rowSpan: 2,
              shading: cellBackground
            }),
            new TableCell({
              ...cellText("No. Dosímetro"),
              columnSpan: 2,
              shading: cellBackground
            }),
            new TableCell({
              ...cellText("Dosis Efectiva (mSv)"),
              columnSpan: 2,
              shading: cellBackground
            }),
            new TableCell({
              ...cellText("Dosis Ac. Anual (mSv)"),
              columnSpan: 2,
              shading: cellBackground
            }),
            new TableCell({
              ...cellText("OBSERVACION"),
              rowSpan: 2,
              shading: cellBackground
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              ...cellText("PD1"),
              shading: cellBackground,
            }),
            new TableCell({
              ...cellText("Anillo"),
              shading: cellBackground,
            }),
            new TableCell({
              ...cellText("PD Hp(10)"),
              shading: cellBackground,
            }),
            new TableCell({
              ...cellText("Anillo Hp(0.07)"),
              shading: cellBackground,
            }),
            new TableCell({
              ...cellText("PD Hp(10)"),
              shading: cellBackground,
            }),
            new TableCell({
              ...cellText("Anillo Hp(0.07)"),
              shading: cellBackground,
            }),
          ],

        }),
        ...rowsExample(dosimetros_asignados),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });

    const test = false
    const test2 = true
    const tablaObservaciones = new Table({
      rows: [
        new TableRow({
          children: [
            ...textTableCell("OBSERVACIONES", "", 8, true, false, "left", "", stylesFont, ""),
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell(" ND1 ", "", "", true, true, "center", "", is_ND1 ? stylesFont : stylesFontObservacion , ""),
            ...textTableCell(" No devolvió Dosímetro PD1 ", "", "", true, true, "left", "", is_ND1 ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" IC ", "", "", true, true, "center", "", is_IC ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" Iniciar control ", "", "", true, true, "left", "", is_IC ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" R ", "", "", true, true, "center", "", is_R ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" Reposición ", "", "", true, true, "left", "", is_R ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" DNU ", "", "", true, true, "center", "", is_DNU ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" Dosímetro no utilizado ", "", "", true, true, "left", "", is_DNU ? stylesFont : stylesFontObservacion, ""),
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell(" NDA ", "", "", true, true, "center", "", is_NDA ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" No devolvió Dosímetro anillo ", "", "", true, true, "left", "", is_NDA ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" SC ", "", "", true, true, "center", "", is_SC ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" Sale de control ", "", "", true, true, "left", "", is_SC ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" DD ", "", "", true, true, "center", "", is_DD ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" Dosímetro dañado ", "", "", true, true, "left", "", is_DD ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" LNR ", "", "", true, true, "center", "", is_LNR ? stylesFont : stylesFontObservacion, ""),
            ...textTableCell(" Lectura no registrada ", "", "", true, true, "left", "", is_LNR ? stylesFont : stylesFontObservacion, ""),
          ]
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      }
    })




    const tableHeader = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: Uint8Array.from(atob(imageCIANBase64Data), c =>
                        c.charCodeAt(0)
                      ),
                      transformation: {
                        width: 100,
                        height: 75,
                      },
                    }),
                  ],
                  alignment: AlignmentType.CENTER
                })],
              verticalAlign: VerticalAlign.CENTER,
              rowSpan: 3,
            }),
            ...textTableCell("INFORME DE RESULTADOS DOSIMETRICOS", "", 2, true, false, "center", "", {...stylesFont, color: "6565ff" }, ""),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: Uint8Array.from(atob(imageUESBase64Data), c =>
                        c.charCodeAt(0)
                      ),
                      transformation: {
                        width: 100,
                        height: 100,
                      },
                    }),
                  ],
                  alignment: AlignmentType.CENTER
                })],
              verticalAlign: VerticalAlign.CENTER,
              rowSpan: 3,
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: "LABORATIORIO DE DOSIMETRIA PERSONAL EXTERNA", ...stylesFont, bold: true, color: "6565ff"}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "CENTRO DE INVESTIGACIONES Y APLICACIONES NUCLEARES", ...stylesFont, bold: true, color: "6565ff"}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "FACULTAD DE INGENIERIA Y ARQUITECTURA", ...stylesFont, bold: true, color: "6565ff"}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "UNIVERSIDAD DE EL SALVADOR", ...stylesFont, bold: true, color: "6565ff"})
                  ],
                  alignment: AlignmentType.CENTER
                })
              ],

              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2,
            })
          ]
        }),
        new TableRow({
          children: [
            ...textTableCell("PT-DA-CIAN", "", "", true, false, "center", "", {...stylesFont, color: "6565ff" }, ""),
            ...textTableCell("Versión 2", "", "", true, false, "center", "", {...stylesFont, color: "6565ff" }, ""),
          ]
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      }
    })

    const tableFooter = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: "____________________________", ...stylesFont}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "Revisó/ Ing. Héctor Chávez", ...stylesFont}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "LABORATORIO DE DOSIMETRIA", ...stylesFont}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "PERSONAL EXTERNA", ...stylesFont})
                  ],
                  alignment: AlignmentType.CENTER
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
              // columnSpan: 2,
              borders: whitoutBorders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: "____________________________", ...stylesFont}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "Elaboró/ Ing. Gabriela Ayala", ...stylesFont}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "TECNICO DE DOSIMETRIA", ...stylesFont}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "PERSONAL EXTERNA", ...stylesFont})
                  ],
                  alignment: AlignmentType.CENTER
                })
              ],
              /// verticalAlign: VerticalAlign.,
              // columnSpan: 2,
              borders: whitoutBorders,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: "NOMBRE DE QUIEN RECIBE POR USUARIOS Y FECHA", ...stylesFont}),
                  ],
                  alignment: AlignmentType.END
                }),
              ],
              verticalAlign: VerticalAlign.BOTTOM,
              // columnSpan: 2,
              borders: whitoutBorders,
            })
          ]
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      }
    })


    const doc = new Document({
      numbering: {
        config: [
          {
            levels: [
              {
                level: 0,
                format: LevelFormat.DECIMAL,
                text: "%1-",
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    italics: true,
                    font: "Calibri Light",
                    size: 16,
                  }
                },
              },
            ],
            reference: "my-number-numbering-reference",
          },
        ],
      },
      sections: [
        {
          properties: {
            page: {
              size: {
                orientation: PageOrientation.PORTRAIT,
                height: convertMillimetersToTwip(279.4),
                width: convertMillimetersToTwip(215.9),
              },
              margin: {
                top: convertInchesToTwip(0.39),
                right: convertInchesToTwip(0.33),
                bottom: convertInchesToTwip(0.39),
                left: convertInchesToTwip(0.69),
              },
            },
          },
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      children: ["Página ", PageNumber.CURRENT,  " de ", PageNumber.TOTAL_PAGES],
                    }),
                  ],
                }),
                tableHeader
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                tableFooter,
                new Paragraph({
                  children: [
                    new TextRun({ text: "________________________________________________________________________________________", ...stylesFont}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: "CONTACTENOS AL 2235-9035 O NUESTRO CORREO ", font: "Times New Roman", size: 18}),
                    new TextRun({ text: "serviciodosimetriacianfia@gmail.com", font: "Times New Roman", size: 18, style: "Hyperlink"}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                  children: [
                    new TextRun({ text: `Final Avenida "Mártires Estudiantes del 30 de Julio", Ciudad Universitaria` , font: "Times New Roman", size: 18}),
                  ],
                  alignment: AlignmentType.CENTER
                }),
                // new Paragraph("Footer on another page")
              ],
            }),
          },
          children: [
            tablaDatosGenerales,
            new Paragraph(""),
            tablaGeneral,
            new Paragraph("__________________________________________________________________________________________________"),
            new Paragraph({
              children: [
                new TextRun({
                  text: "NOTA:",
                  font: "Calibri Light",
                  size: 16,
                  bold: true,
                  italics: true
                })
              ],
            }),
            ...numberedList("Dosis Efectiva= Valor de dosis Hp (10) de cuerpo entero en el periodo utilizado; Dosis Ac. Anual= Dosis Hp (10) acumulada en el año actual a partir de la fecha de inicio"),
            ...numberedList("Límite de Registro= 0.15 mSv (milisievert); Entiéndase por VMLR= Valor menor que el límite de registro"),
            ...numberedList("Límite de dosis establecida en Reglamento Especial de Protección y Seguridad Radiológica (REPSR-DPR-MINSAL)."),
            new Paragraph({
              children: [
                new TextRun({
                  text: "\t\tCuerpo Entero: Mensual 1.67 mSv  y  Anual 20mSv   (Dosis Efectiva)",
                  font: "Calibri Light",
                  size: 16,
                  italics: true
                })
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "\t\tExtremidades: Mensual  40 mSv  y  Anual 500mSv   (Dosis Equivalente:)",
                  font: "Calibri Light",
                  size: 16,
                  italics: true
                })
              ],
            }),
            ...numberedList("La técnica utilizada para generar las dosis ocupacionales es por medio de dosímetros termoluminiscentes (TLD)."),
            tablaObservaciones,
          ],
        },
      ],
    });

    return Packer.toBlob(doc)
    //     .then(blob => {
    //   console.log(blob);
    //   // saveAs(blob, "example.docx");
    //   console.log("Document created successfully");
    //
    // });

    // Done! A file called 'My Document.docx' will be in your file system.
  }


  const actions = { generarWord }

  return { actions }
}

export default useCrearWord
