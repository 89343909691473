import React from 'react';
import { TextField, Box } from '@material-ui/core';

function DateInput({ fecha, setFecha, isEditable, label }) {
  const handleDateChange = (event) => {
    setFecha(event.target.value);
  };

  return (
    <Box display="flex" alignItems="center">
      <label style={{ marginRight: '10px' }}>{label}</label>
      <TextField
        type="date"
        value={fecha}
        onChange={isEditable ? handleDateChange : null}
        disabled={!isEditable}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
}

export default DateInput;
