import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

const ProgressBar = ({value}) => {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value = {value} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    value,
                )}%`}</Typography>
            </Box>
        </Box>
    )
}

ProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
}

export default ProgressBar
