import axios from "axios";
import headers from "../utils/headers";
import { useCallback } from "react";
import useErrorHandler from "../utils/useErrorHandler";
import url from "../utils/url";

const solicitudUrl = `${url}reportes`;

const useReporteEficienciaGlobalService = () => {
  const { errorHandler } = useErrorHandler();

  const getReportesEficienciaGlobal = useCallback(
    async (fecha_inicio, fecha_fin, nombre_institucion, page) => {
      const parametros = [];

      if (page) {
        parametros.push(`page=${page}`);
      }
      if (fecha_inicio) {
        parametros.push(`fecha_inicio=${fecha_inicio}`);
      }
      if (fecha_fin) {
        parametros.push(`fecha_fin=${fecha_fin}`);
      }

      if (nombre_institucion) {
        parametros.push(`nombre_institucion=${nombre_institucion}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}/indicador_eficiencia_global${parametrosString}`;

      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getReporteIndicadoresPromedio = useCallback(
    async (fecha_inicio, fecha_fin, nombre_institucion, page) => {
      const parametros = [];

      if (page) {
        parametros.push(`page=${page}`);
      }
      if (fecha_inicio) {
        parametros.push(`fecha_inicio=${fecha_inicio}`);
      }
      if (fecha_fin) {
        parametros.push(`fecha_fin=${fecha_fin}`);
      }

      if (nombre_institucion) {
        parametros.push(`nombre_institucion=${nombre_institucion}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}/indicador_promedio${parametrosString}`;

      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getReporteCantidadEquipos = useCallback(
    async (fecha_inicio, fecha_fin, nombre_institucion, page) => {
      const parametros = [];

      if (page) {
        parametros.push(`page=${page}`);
      }
      if (fecha_inicio) {
        parametros.push(`fecha_inicio=${fecha_inicio}`);
      }
      if (fecha_fin) {
        parametros.push(`fecha_fin=${fecha_fin}`);
      }

      if (nombre_institucion) {
        parametros.push(`nombre_institucion=${nombre_institucion}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}/tipo_equipo/excel${parametrosString}`;

      
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const descargarReporteCantidadEquipos = useCallback(
    async (fecha_inicio, fecha_fin, nombre_institucion, setResultadoApi) => {
      const parametros = [];

      if (fecha_inicio) {
        parametros.push(`fecha_inicio=${fecha_inicio}`);
      }
      if (fecha_fin) {
        parametros.push(`fecha_fin=${fecha_fin}`);
      }

      if (nombre_institucion) {
        parametros.push(`nombre_institucion=${nombre_institucion}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}/tipo_equipo/exportar${parametrosString}`;

     
      try {
        const response = await axios.get(requestUrl, {
          headers: headers(),
          responseType: "blob",
        });

        // Extraer el nombre del archivo del encabezado "Content-Disposition"
        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch && filenameMatch[1];

        // Crear una URL temporal con la respuesta y abrir una nueva ventana
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename || "archivo.xlsx"); // Usar el nombre del archivo o uno predeterminado
        document.body.appendChild(link);
        link.click();

        setResultadoApi("exito");
        return response.data;
      } catch (error) {
        setResultadoApi("error");
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getReporteIndicadoresDeCalidad = useCallback(
    async (fecha_inicio, fecha_fin, nombre_institucion, periodo) => {
      const parametros = [];

      if (periodo) {
        parametros.push(`periodo=${periodo}`);
      }
      if (fecha_inicio) {
        parametros.push(`fecha_inicio=${fecha_inicio}`);
      }
      if (fecha_fin) {
        parametros.push(`fecha_fin=${fecha_fin}`);
      }

      if (nombre_institucion) {
        parametros.push(`nombre_institucion=${nombre_institucion}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}/indicador_calidad${parametrosString}`;

      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    getReportesEficienciaGlobal,
    getReporteIndicadoresPromedio,
    getReporteCantidadEquipos,
    descargarReporteCantidadEquipos,
    getReporteIndicadoresDeCalidad,
  };
};

export default useReporteEficienciaGlobalService;
