import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from './../AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from './../Drawer'
import { drawerWidth } from './../Drawer/Drawer';
import Footer from './../Footer/Footer'
import Session from 'react-session-api'
import useHasExpired from '../../hooks/utils/useHasExpired'
import useRefreshToken from '../../hooks/utils/useRefreshToken'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingBottom: '6em',
        flexBasis: 0,
    },
    contentLoged: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc(100% - ${drawerWidth}px)`    
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: `calc(100%)`    
        }
    },
    contentNotLoged: {
        maxWidth: `calc(100%)`
    },
}));

const AppFrame = props => {
    useHasExpired()
    useRefreshToken()
    const children = props.children
    const [token, setToken] = useState(Session.get("token"))
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar token = {token} setToken = {setToken} 
                handleDrawerToggle = {handleDrawerToggle}/>
                { token ? <Drawer mobileOpen = {mobileOpen} 
                    handleDrawerToggle = {handleDrawerToggle}/> : 
                    null
                }
            <main className={`${classes.content} ${token ? classes.contentLoged : classes.contentNotLoged}`}>
                <div className={classes.toolbar} />
                {children}
            </main>
            <Footer/>
        </div>
    )
}

AppFrame.propTypes = {
    children: PropTypes.node.isRequired
}

export default AppFrame