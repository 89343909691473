import { useState, useEffect } from "react"
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"
import useIsLoged from '../utils/useIsLoged'
import moment from "moment";

const useLecturasPage = () => {
    useIsLoged()
    const {
        getLecturaLoteByArea
    } = useDosimetriaService()

    // Almacena las lecturas lotes finalizadas
    const [arrayLecturaLotes, setArrayLecturaLotes] = useState([])

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    // Para la fecha
    const [dateInput, setDateInput] = useState(new Date())

    // Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    // const queryParams = new URLSearchParams(window.location.search);

    // Estado que indica la pagina actua
    // const [page, setPage] = useState(queryParams.get('page'))

    // Encabezado de la tabla
    const encabezado = ["Código", "Tipo de dosímetro", "Posición", "Observaciones"]

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    // if (!page) {
    //     setPage(1)
    //     history.push(`${window.location.pathname}?page=1`)
    // }

    //Controlador cambio de pagina
    // const handlePageChange = (event, value) => {
    //     setPage(value)
    //     history.push(`${window.location.pathname}?page=${value}`)
    // }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    const handleSelectDate = (date) => {
        // console.log(date)
        setDateInput(date)
    }

    const getloteLecutasFinalizadasByIdArea = async () => {
        startLoading()
        const params = {}
        if (dateInput) params.fecha = moment(dateInput).format("YYYY-MM-DD")

        // console.log("params", params)
        const response = await getLecturaLoteByArea(params)
        // console.log("response", response)

        setArrayLecturaLotes([])
        response?.forEach(lecturaLote => {
            let detalles_lectura_lote_finalizado = []
            lecturaLote?.detalles_lote?.forEach( detalle_lectura_lote => {
                // Desestructurizando
                const {
                        id,
                        posicion,
                        lote_lectura_id,
                        observacion,
                        dosimetria_control: {
                            dosimetro_asignado: {
                                dosimetro_id,
                                dosimetro: {
                                    tipo_dosimetro
                                }
                            }
                        }
                } = detalle_lectura_lote

                detalles_lectura_lote_finalizado.push({
                    "id": id,
                    "posicion": posicion,
                    "lote_lectura_id": lote_lectura_id,
                    "observacion": observacion,
                    "codigo_dosimetro": dosimetro_id,
                    "tipo_dosimetro": tipo_dosimetro === 2 ? 'Anillo' : 'Cuerpo completo',
                })
            })

            // Construyendo el objeto para almacenarlo en el arrayLecturaLotes
            const data = {
                "codigo": lecturaLote?.codigo,
                "disco": lecturaLote?.disco,
                "tiempo_inicio_lectura": lecturaLote?.tiempo_inicio_lectura,
                "tiempo_fin_lectura": lecturaLote?.tiempo_fin_lectura,
                "detalles_lectura_lote_finalizado": detalles_lectura_lote_finalizado,
            }
            // Almacenando el objeto
            setArrayLecturaLotes(arrayLecturaLotes => [...arrayLecturaLotes, data])
        })
        stopLoading()
    }

    const clearData = () => {
        setArrayLecturaLotes([])
        // setDateInput(new Date())
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        encabezado,
        arrayLecturaLotes,
        dateInput,
    }

    const actions = {
        setAlertOpen,
        handleSelectDate,
        handleOpenAlert,
        clearData,
    }

    // Para cargar los eventos de dosimetría
    useEffect ( () => {
        getloteLecutasFinalizadasByIdArea()
    }, [dateInput])

    return { data, actions }
}

export default useLecturasPage
