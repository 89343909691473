const AppColors = {
    blue: {
        light: '#6dd1ff',
        main: '#18a0fb',
        dark: '#0072c7',
        contrastText: '#ffffff',
    },
    red: {
        light: '#ff795f',
        main: '#f44336',
        dark: '#b9000a',
        contrastText: '#ffffff',
    }
}

export default AppColors