import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Typography, Button, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: "1.2em",
  },
  chip: {
    maxWidth: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const FileInputWithClose = ({
  label,
  placeholder,
  helper,
  onChange,
  error,
  required,
  disabled,
  multiple,
  files,
}) => {
  const classes = useStyles();
  const [resetComponent, setResetComponent] = useState(false);

  const handleFileChange = (event) => {
    onChange(event.target.files);
    setResetComponent(true);
  };

  const handleResetComponent = () => {
    setResetComponent(false);
  };

  useEffect(() => {
    if (resetComponent) {
      // Fuerza el remontaje del componente
      setResetComponent(false);
    }
  }, [resetComponent]);

  return (
    <div>
      {files && files.length > 0 ? (
        <>
          <LinearProgress variant="determinate" value={100} />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleResetComponent}
          >
            Reiniciar
          </Button>
        </>
      ) : (
        <TextField
          label={label}
          style={{ margin: 8 }}
          placeholder={placeholder}
          error={error}
          helperText={helper}
          fullWidth
          type="file"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.input}
          onChange={handleFileChange}
          required={required}
          disabled={disabled}
          inputProps={{ multiple }}
        />
      )}
      {files && files.length > 0 ? (
        <>
          <Typography variant="caption" color="textSecondary" display="block">
            {label}
          </Typography>
          {files.map((file) => (
            <Chip
              label={file.name}
              onDelete={file.deleteFile}
              key={file.name}
              color="primary"
              className={classes.chip}
              onClick={file.onClickChip}
            />
          ))}
        </>
      ) : null}
    </div>
  );
};

FileInputWithClose.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  helper: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      deleteFile: PropTypes.func.isRequired,
      onClickChip: PropTypes.func,
    }).isRequired
  ),
};

export default FileInputWithClose;
