export const allLetter = (inputText) => {
    var letters = /^[A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ\u00C0-\u017F]+$/
    return inputText.match(letters) ? true : false
}

export const allLetterAndSpace = (inputText) => {
    var letters = /^[a-zA-ZA-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ\u00C0-\u017F\s]*$/
    return inputText.match(letters) ? true : false
}

export const letterNumbersAndSpace = (inputText) => {
    var lettersNumbers = /^[a-zA-ZA-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ\u00C0-\u017F0-9\s]*$/
    return inputText.match(lettersNumbers) ? true : false
}

export const letterNumbersSpaceForAddress = (inputText) => {
    var lettersNumbersSpace = /^[a-zA-ZA-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙª°\u00C0-\u017F0-9\s#\/,.-]*$/
    return inputText.match(lettersNumbersSpace) ? true : false
}

export const allNumbers = (inputText) => {
    var numbers = /^[0-9]*$/
    return inputText.match(numbers) ? true : false
}

export const isDUI = (inputText) => {
    var dui = /^\d{8}-\d{1}$/
    return inputText.match(dui) ? true : false
}

export const isNIT = (inputText) => {
    var nit = /^\d{4}-\d{6}-\d{3}-\d{1}$/
    return inputText.match(nit) ? true : false
}

export const isPhone = (inputText) => {
    var phone = /^\d{4}-\d{4}$/
    return inputText.match(phone) ? true : false
}

export const isEmail = (inputText) => {
    // eslint-disable-next-line no-control-regex
    var email = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return inputText.match(email) ? true : false
}

export const validPassword = (inputText) => {
    var password = /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/
    return inputText.match(password) ? true : false
}