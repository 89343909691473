import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import MaterialAppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import {VscMenu} from 'react-icons/vsc'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { FaUserCircle } from 'react-icons/fa'
import Session from 'react-session-api'
import Button from '@material-ui/core/Button'
import useLoginService from '../../services/sesion/useLoginService'
import Alert from './../Alert/Alert'
import useLogoutLocal from '../../hooks/utils/useLogout'

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
    },
	toolbarButton: {
		marginRight: theme.spacing(1),
    }
}));

const AppBar = ({handleDrawerToggle, token, setToken}) => {
	const { logout } = useLoginService()
    const classes = useStyles()
	const history = useHistory()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	//Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
	const {logoutLocal} = useLogoutLocal()

	//Verifica si se ha cerrado la sesion a causa de una segunda sesion iniciada
	useEffect(() => {
		if(Session.get('secondLogin')){
			handleOpenAlert('error', "Se ha iniciado sesion en otro dispositivo.")
			Session.remove('secondLogin')
		}else if(Session.get("expiredSession")){
			handleOpenAlert('error', "La sesion ha expirado por inactividad.")
			Session.remove('expiredSession')
		}
	})

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

	const handleLogout = () => {
		logout()
		setToken(undefined)
		setAnchorEl(null)
		logoutLocal()
		history.push('/iniciar-sesion')
	}
    return (
        <MaterialAppBar color = "inherit" position="fixed"
          	className={classes.appBar}>
			<Alert open = {alertOpen} setOpen={setAlertOpen}
				severity = {alertSeverity} 
				message = {alertMessage}/>
			<Toolbar item xs={12} sm={12}>
				{token ? 
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<VscMenu/>
					</IconButton>
					:
					null
					}
				<Typography variant="h6" noWrap className={classes.title}>
					CIAN
				</Typography>
				{token ?
					<div>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="primary"
						>
							<FaUserCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
							}}
							open={open}
							onClose={handleClose}
						>
							<MenuItem onClick={handleClose}>Perfil</MenuItem>
							<MenuItem onClick={handleLogout}>
								Cerrar sesion
							</MenuItem>
						</Menu>
					</div>
					:
					<div>
						<Button size="small" variant = "outlined"
							className={classes.toolbarButton}
							color = "primary"
							onClick={()=>history.push('/iniciar-sesion')}>
							Iniciar sesion
						</Button>
						<Button  size="small" variant = "contained" 
							color="primary"
							onClick={()=>history.push('/inscripcion/1')}>
							Registrarse
						</Button>
					</div>
          		}
			</Toolbar>
        </MaterialAppBar>
    )
}

AppBar.propTypes = {
	handleDrawerToggle: PropTypes.func.isRequired,
	setToken: PropTypes.func.isRequired,
}

export default AppBar
