import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { addRow, findRow, updateRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
import { allLetterAndSpace, isDUI, isEmail, isPhone} 
    from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import { getFilenameFromContentDisposition } from '../../utils/download'

const useDatosDelegadoPage = () => {
    const history = useHistory()
    const { createDelegado, deleteDelegado, getArchivoDelegado, getDelegadoById, 
        getMisDelegados, updateDelegado } = useSolicitudInscripcionService()
    useIsLoged()
    const {id} = useParams()
    
    //Estado para mensajes de error
    const [error, setError] = useState(null)
    //Estado para las filas de la tabla
    const [rows, setRows] = useState([])
    
    //Estados para controlar los inputs 
    //(errorInput y helperInput sirven para mostrar un error en el input)
    
    //Estados para usuario
    const [nombreUsuario, setNombreUsuario] = useState('')
    const [errorNombreUsuario, setErrorNombreUsuario] = useState(false)
    const [helperNombreUsuario, setHelperNombreUsuario] = useState('')
    const [duiUsuario, setDuiUsuario] = useState('')
    const [errorDuiUsuario, setErrorDuiUsuario] = useState(false)
    const [helperDuiUsuario, setHelperDuiUsuario] = useState('')
    const [archivoUsuario, setArchivoUsuario] = useState(null)
    const [objArchivoUsuario, setObjArchivoUsuario] = useState(null)
    const [fileNumPages, setFileNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    function onDocumentLoadSuccess({ numPages }) {
        setFileNumPages(numPages);
    }
    const [openFileModal, setOpenFileModal] = useState(false)
    const handleCloseFileModal = () => {
        setOpenFileModal(false)
    }
    const [telefonoUsuario, setTelefonoUsuario] = useState('')
    const [errorTelefonoUsuario, setErrorTelefonoUsuario] = useState(false)
    const [helperTelefonoUsuario, setHelperTelefonoUsuario] = useState('')
    const [celularUsuario, setCelularUsuario] = useState('')
    const [errorCelularUsuario, setErrorCelularUsuario] = useState(false)
    const [helperCelularUsuario, setHelperCelularUsuario] = useState('')
    const [correoElectronicoUsuario, setCorreoElectronicoUsuario] = useState('')
    const [errorCorreoElectronicoUsuario, setErrorCorreoElectronicoUsuario] = useState(false)
    const [helperCorreoElectronicoUsuario, setHelperCorreoElectronicoUsuario] = useState('')
    // Estado que almacena el id de la solicitud y servira como variable de control para
    // el proceso de edicion
    const [idSolicitud, setIdSolicitud] = useState('')

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [delegadoToDelete, setDelegadoToDelete] = useState(null)

    //Estado para progress, el setLoading siempre debe ser llamado en funciones 
    //asincronas para funcionar correctamente
    const [loading, setLoading] = useState(0)
    
    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Controladores mensajes confirmacion
    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setDelegadoToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorNombreUsuario || nombreUsuario === "" || errorCelularUsuario || errorTelefonoUsuario || errorDuiUsuario || duiUsuario === "" || archivoUsuario === null || errorCorreoElectronicoUsuario || correoElectronicoUsuario === "")
        {
            return true            
        }
        return false
    }
    
    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Funciones para controlar cambios en inputs de usuario
    const onChangeNombreUsuario = (text)  =>
    {
        setNombreUsuario(text)
        if(!allLetterAndSpace(text)){
            setErrorNombreUsuario(true)
            setHelperNombreUsuario("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombreUsuario(false)
            setHelperNombreUsuario("")
        }
    }

    const onChangeDuiUsuario = (text)=>
    {
        setDuiUsuario(text)
        if(!isDUI(text) && text !== ""){
            setErrorDuiUsuario(true)
            setHelperDuiUsuario("Debe ingresar un DUI valido.")
        }else{
            setErrorDuiUsuario(false)
            setHelperDuiUsuario("")
        }
    }

    const handleOnChangeArchivoUsuario = (file) => {
        setArchivoUsuario(file[0])
        setObjArchivoUsuario([{ name: file[0].name, deleteFile: () => {
            setObjArchivoUsuario(null)
            setArchivoUsuario(null)
        },
        onClickChip: () => {setOpenFileModal(true)}
        }])
    }

    const onChangeTelefonoUsuario = (text)=>
    {
        setTelefonoUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefonoUsuario(true)
            setHelperTelefonoUsuario("Debe ingresar un telefono valido valido.")
        }else{
            setErrorTelefonoUsuario(false)
            setHelperTelefonoUsuario("")
        }
    }

    const onChangeCelularUsuario = (text)=>
    {
        setCelularUsuario(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelularUsuario(true)
            setHelperCelularUsuario("Debe ingresar un telefono valido valido.")
        }else{
            setErrorCelularUsuario(false)
            setHelperCelularUsuario("")
        }
    }

    const onChangeCorreoElectronicoUsuario = (text)=>
    {
        setCorreoElectronicoUsuario(text)
        if(!isEmail(text) && text !== ""){
            setErrorCorreoElectronicoUsuario(true)
            setHelperCorreoElectronicoUsuario("Debe ingresar un correo electronico valido.")
        }else{
            setErrorCorreoElectronicoUsuario(false)
            setHelperCorreoElectronicoUsuario("")
        }
    }

    //Funcion para manejar la creacion de un delegado
    const onCreateDelegado = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }

        let newDelegado = new FormData()
        newDelegado.append("nombres", nombreUsuario)
        newDelegado.append("dui", duiUsuario)
        if(telefonoUsuario){
            newDelegado.append("telefono", telefonoUsuario)
        }
        if(celularUsuario){
            newDelegado.append("telefono_celular", celularUsuario)
        }
        newDelegado.append("email", correoElectronicoUsuario)
        newDelegado.append("archivo_documento_identidad", archivoUsuario)
        // console.log('archivo delegado', archivoUsuario)
        startLoading()
        const result = await createDelegado(newDelegado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {user} = result
            handleOpenAlert('success', `El delegado "${user.nombres}" se creo correctamente.`)
            cancel()
            handleGetDelegados()
        }
        stopLoading()
    }

    //Funcion para manejar una actualizacion en los datos de un delegado
    const onUpdateDelegado = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let delegadoActualizado = new FormData()
        delegadoActualizado.append("nombres", nombreUsuario)
        delegadoActualizado.append("dui", duiUsuario)
        if(telefonoUsuario){
            delegadoActualizado.append("telefono", telefonoUsuario)
        }
        if(celularUsuario){
            delegadoActualizado.append("telefono_celular", celularUsuario)
        }
        delegadoActualizado.append("email", correoElectronicoUsuario)
        delegadoActualizado.append("archivo_documento_identidad", archivoUsuario)
        delegadoActualizado.append("_method", "PUT")
        startLoading()
        const result = await updateDelegado(id, delegadoActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombres, dui, telefono, telefono_celular, email} = result
            const newCells = [nombres, dui, telefono, telefono_celular, email]
            // console.log("id row editada", id)
            // console.log("newCells", newCells)
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `Los datos del delegado "${nombres}" se actualizo correctamente.`)
            editCancel()
        }
        stopLoading()
    }

    //Funcion para manejar el boton eliminar delegado
    const onDeleteDelegado = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteDelegado(delegadoToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombres} = result
            handleGetDelegados()
            editCancel()
            handleOpenAlert('success', `El delegado con nombre "${nombres}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    const handleGetFile = useCallback(async () => {
        const file = await getArchivoDelegado(id)
        if (file.error){
            console.log('Error al cargar los datos', file.error)
        }
        else{
            // console.log('Archivo usuario', file)
            const blob = new Blob([file.data])
            setArchivoUsuario(blob)
            setObjArchivoUsuario([{ name: getFilenameFromContentDisposition
                (file.headers['content-disposition']), deleteFile: () => {
                setObjArchivoUsuario(null)
                setArchivoUsuario(null)
            },
            onClickChip: () => {setOpenFileModal(true)}
            }])
        }
    }, [getArchivoDelegado, id])


    //Funcion para manejar la consulta de los datos de un delegado
    const getDelegado = useCallback(async (id) => {
        startLoading()
        const delegado = await getDelegadoById(id)
        if (delegado.error){
            handleOpenAlert('error', delegado.error)
        }
        else{
            // console.log('Delegado a editar', delegado)
            //Datos delegado
            setNombreUsuario(delegado.nombres)
            setDuiUsuario(delegado.dui)
            //setArchivoUsuario(delegado.)
            setTelefonoUsuario(delegado.telefono)
            setCelularUsuario(delegado.telefono_celular)
            setCorreoElectronicoUsuario(delegado.email)
            await handleGetFile()
        }
        stopLoading()
    }, [getDelegadoById, handleGetFile])

    const handleGetDelegados = useCallback(async () => {
        startLoading()
        const delegados = await getMisDelegados(page)
        if (delegados.error){
            setError(delegados.error)
        }
        else{
            setRows([])
            // console.log("Delegados", delegados)
            setNumPages(delegados.last_page)
            delegados.data.forEach(({id, nombres, dui, telefono, telefono_celular, email, 
                disponible : estadoDisponible}) => {
                const key = id
                const cell = [nombres, dui, telefono, telefono_celular, email]
                const disponible = estadoDisponible
                addRow(key, cell, setRows, disponible)
            })
            if(page > delegados.last_page){
                setPage(delegados.last_page)
                history.push(`${window.location.pathname}?page=${delegados.last_page}`)
            }
        }
        stopLoading()
    }, [getMisDelegados, history, page])

    const handleFilePageChange = (event, value) => {
        setPageNumber(value)
    }

    //Funcion que activa y desactiva los delegados
    const handleChangeEstadoDisponible = (key) => {
        alert(`Presionado ${key.target.id}`)
    }

    //Funcion maneja boton editar
    const editClick = (id) => {
        history.push(`/cliente/delegados/${id}?page=${page}`)
    }

    //Funcion maneja boton cancelar edicion
    const editCancel = () => {
        cancel()
        history.push(`/cliente/delegados?page=${page}`)
    }

    //Funcion cancelar (regresa los estados al valor inicial)
    const cancel = () => {
        setNombreUsuario('')
        setErrorNombreUsuario(false)
        setHelperNombreUsuario('')
        setDuiUsuario('')
        setErrorDuiUsuario(false)
        setHelperDuiUsuario('')
        setArchivoUsuario(null)
        setObjArchivoUsuario(null)
        setFileNumPages(null)
        setPageNumber(1)
        setTelefonoUsuario('')
        setErrorTelefonoUsuario(false)
        setHelperTelefonoUsuario('')
        setCelularUsuario('')
        setErrorCelularUsuario(false)
        setHelperCelularUsuario('')
        setCorreoElectronicoUsuario('')
        setErrorCorreoElectronicoUsuario(false)
        setHelperCorreoElectronicoUsuario('')
    }

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        rows,
        nombreUsuario,
        errorNombreUsuario,
        helperNombreUsuario,
        duiUsuario,
        errorDuiUsuario,
        helperDuiUsuario,
        archivoUsuario,
        telefonoUsuario,
        errorTelefonoUsuario,
        helperTelefonoUsuario,
        celularUsuario,
        errorCelularUsuario,
        helperCelularUsuario,
        correoElectronicoUsuario,
        errorCorreoElectronicoUsuario,
        helperCorreoElectronicoUsuario,
        id,
        openEdit,
        openDelete,
        delegadoToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        openFileModal,
        pageNumber,
        fileNumPages,
        objArchivoUsuario,
        idSolicitud
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        onCreateDelegado,
        onUpdateDelegado,
        onDeleteDelegado,
        editClick,
        onChangeNombreUsuario,
        onChangeDuiUsuario,
        handleOnChangeArchivoUsuario,
        onChangeTelefonoUsuario, 
        onChangeCelularUsuario, 
        onChangeCorreoElectronicoUsuario,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setDelegadoToDelete,
        setAlertOpen,
        handleFilePageChange,
        handleCloseFileModal,
        onDocumentLoadSuccess,
        handlePageChange,
        handleChangeEstadoDisponible
    }


    //useEffect para cargar datos en la tabla
    useEffect(() => {
        handleGetDelegados()
        //Solo se ejecuta cuando cambian las funciones setRows o handleGetDelegados
    }, [setRows, handleGetDelegados])

    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getDelegado(id)
        }
        //Solo se ejecuta cuando cambia el valor de id o getDelegado
    }, [id, getDelegado])
    return {error, data, actions}
}

export default useDatosDelegadoPage