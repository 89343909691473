import useLogoutLocal from '../../hooks/utils/useLogout'
import Session from 'react-session-api'
import { getNowInSeconds } from '../../utils/time'
import { useCallback } from 'react'

const erroresNode = (errores) => {return (
    <div>
        {
            Object.prototype.toString.call(errores) === '[object Array]' ?
            <ul>
                <h4>Errores:</h4>
                {
                    errores && errores.map((item) => <li key={item}>{item}</li>)
                }
            </ul>
            :
            Object.keys(errores).map(e => {    
                return e !== "responsable_existente" ? (<div key = {e}>
                    <h4>{e}</h4>
                    <ul>
                    {
                        errores[e] && errores[e].map((item) => <li key={item}>{item}</li>)
                    }
                    </ul>
                </div>):
                null
                }
            )
        }
    </div>
)}

const useErrorHandler = () => {
    const { logoutLocal } = useLogoutLocal()
    const errorHandler = useCallback(
        (error) => {
            const token = Session.get('token')
            let errorObj = {error: "Ocurrio un error"}
            if(error.response){
                //Errores que nos responde el server
                const {data, status} = error.response
                if(status === 401){
                    if(token){
                        if(token.expire_date <= getNowInSeconds()){
                            logoutLocal()
                            Session.set("expiredSession", true)
                        }else{
                            logoutLocal()
                            Session.set("secondLogin", true)
                        }
                    }else{
                        //TODO pagina 401
                        logoutLocal()
                        console.log('No hay token y se ejecuto una accion')
                    }
                }
                const errores = data.error
                console.log('errores', typeof(errores))
                errorObj.status = status
                errorObj.data = data
                //Se verifica el tipo del error para manejarlo de forma correcta
                switch(typeof(errores)){
                    case 'object':
                        try {
                            errorObj.error = erroresNode(errores)
                        } catch (error) {
                            errorObj.error = JSON.stringify(errores)
                        }
                        break
                    case 'string':
                        errorObj.error = errores
                        break
                    default:
                        errorObj.error = "Ocurrio un error en el servidor."
                }
                console.log('objError', errorObj)
            }else if(error.request){
                //Errores que suceden por no llegar al server
                console.log("Servidor inaccesible o no tengo internet")
                errorObj.error = "No ha sido posible conectarse al servidor."
            }else{
                //Errores imprevistos
                console.log("Errores imprevistos")
                errorObj.error = "Error al cargar los datos."
            }
            return errorObj
        },
        [logoutLocal],
    ) 
    return { errorHandler }
}

export default useErrorHandler