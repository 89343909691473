import React from "react"
import Grid from "@material-ui/core/Grid"
import FormDivider from "../../components/FormDivider"
import { makeStyles } from "@material-ui/core/styles"
import { List } from "@material-ui/core"
import Progress from "../../components/Progress/Progress"
import DataTable from '../../components/DataTable'
import Input from '../../components/Input'
import { MaskField } from 'react-mask-field';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
    //marginBottom: ".5em",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  inputBtn: {
    paddingRight: '1.2em',
  }
}))

// const opciones = [
//   {
//     label: "Entrega de dosímetro",
//     value: 1,
//   },
//   {
//     label: "Recepción de dosímetro",
//     value: 2,
//   },
// ]

function CustomMaskField({ inputRef, ...otherProps }) {
  return <MaskField ref={inputRef} mask="________-_" replacement={{ _: /\d/ }} {...otherProps} />;
}

export const PlanificacionRecepcionesRecibirPage = ({data, actions, entregaDeEventos}) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" justifyContent="center"  item xs={12}>
      <Progress open={data.loading} />
      <FormDivider />
      <Grid container direction="row" spacing={2}>
        <Grid className={classes.input} justifyContent="flex-start" item xs={6}>
          <p>
            <strong>{entregaDeEventos ? 'Entregados' : 'Recibidos'}</strong>
          </p>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" spacing={2}  item xs={12}>
        <Grid className={classes.input} item xs={12}>
          <DataTable headers={data.encabezado} rows={data.selectedRows}/>
        </Grid>
      </Grid>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <FormDivider text="Persona que entrega los dosimetros" />
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <p>
            <strong>Nota: Debe ingresar al menos un documento</strong>
          </p>
          <Grid className={classes.input} item xs={6}>
            {/*<TextField*/}
            {/*    type={"text"}*/}
            {/*    size={"small"}*/}
            {/*    label={"Nombres *"}*/}
            {/*    placeholder={"Nombres"}*/}
            {/*    style={{ margin: 8 }}*/}
            {/*    fullWidth*/}
            {/*    margin="normal"*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*    className={classes.inputBtn}*/}
            {/*    InputProps={{ inputComponent: CustomMaskField }}*/}
            {/*/>*/}
            <Input
              type="text"
              label="Nombres *"
              size="small"
              placeholder="Nombres"
              value={data.nombres}
              onChange={actions.handleOnChangeNombres}
            />
          </Grid>
          <Grid className={classes.input} item xs={6}>
            <Input
              type="text"
              label="Apellidos *"
              size="small"
              placeholder="Apellidos"
              value={data.apellidos}
              onChange={actions.handleOnChangeApellidos}
            />
          </Grid>
          <Grid className={classes.input} item xs={6}>
            <Input
              type="text"
              label="Identificar por DUI "
              size="small"
              placeholder="DUI"
              value={data.DUI}
              onChange={actions.handleOnChangeDUI}
              InputProps={{ inputComponent: CustomMaskField }}
            />
          </Grid>
          <Grid className={classes.input} item xs={6}>
            <Input
              type="text"
              label="Identificar por Licencia "
              size="small"
              placeholder="Licencia"
              value={data.licencia}
              onChange={actions.handleOnChangeLicencia}
            />
          </Grid>
          <Grid className={classes.input} item xs={12}>
            <Input
              type="text"
              label="Identificar por Pasaporte "
              size="small"
              placeholder="Pasaporte"
              value={data.pasaporte}
              onChange={(e) => actions.handleOnChangePasaporte(e)}
            />
          </Grid>
        </Grid>
      </List>
    </Grid>
  )
}

export default PlanificacionRecepcionesRecibirPage
