import {useState, useEffect, useCallback} from "react"
import {useHistory} from 'react-router-dom'
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"
import useIsLoged from '../utils/useIsLoged'
import { mensajeCamposNoValidos } from '../../utils/messages'
import { addRow, findRow, updateRow } from '../../utils/rows'
import { letterNumbersAndSpace } from '../../utils/validations'

const useConfiguracionReporteLecturaPage = () => {
    useIsLoged()
    const history = useHistory()
    const {configuracionReporteLectura} = useDosimetriaService()
    const {getConfiguracionReporteLectura, createConfiguracionReporteLectura} = configuracionReporteLectura

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([]);

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")

    // Estado para mostrar el circular progress
    const [loading, setLoading] = useState(false)

    // Extrayendo los parametros tipo? Parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);

    //Estado que indica la página actual
    const [page, setPage] = useState(queryParams.get('page'))

    //Estado que indica el número de páginas en total
    const [numPages, setNumPages] = useState(0)

    const tiposDosimetro = [
        {
            label: "Cuerpo",
            value: "1"
        },
        {
            label: "Anillo",
            value: "2"
        }
    ]

    const [codigoFactor, setCodigoFactor] = useState('')
    const [errorCodigoFactor, setErrorCodigoFactor] = useState(false)
    const [helperCodigoFactor, setHelperCodigoFactor] = useState('')

    const [nombreFactor, setNombreFactor] = useState('')

    const [tipoFactor, setTipoFactor] = useState('')

    const [valorFactor, setValorFactor] = useState('')

    const [editar, setEditar] = useState(false)

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    if (!page) {
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    // Controlador cambio de página
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => setLoading(true)

    const stopLoading = () => setLoading(false)

    const onChangeCodigoFactor = (text)=>
    {
        setCodigoFactor(text)
        if(!letterNumbersAndSpace(text) && text !== ""){
            setErrorCodigoFactor(true)
            setHelperCodigoFactor("El código del factor debe consistir solo de letras y números.")
        }else{
            setErrorCodigoFactor(false)
            setHelperCodigoFactor("")
        }
    }

    const onChangeNombreFactor = (text)=> setNombreFactor(text)

    const onChangeTipoFactor = (text)=> setTipoFactor(text)

    const onChangeValorFactor = (text)=> setValorFactor(text)

    const checkForErrors = () => errorCodigoFactor || nombreFactor === "" || tipoFactor === "" || valorFactor === "";

    /**
     * @description: Método que sirve para resetear los campos
     */
    const cancel = () => {
        setEditar(false)
        setCodigoFactor('')
        setNombreFactor('')
        setTipoFactor('')
        setValorFactor('')
    }

    const handleOnChangeEditar = (value) => {
        setEditar(true)
        setCodigoFactor('AAA')
        setNombreFactor('Prueba')
        setTipoFactor('1')
        setValorFactor('2.34')
        //history.push(`/configuracion_reporte_lectura/${value}?page=${page}`)
    }

    const saveConfiguracionReporteLectura = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }

        const data = {}
        data.codigo_factor = codigoFactor
        data.nombre_factor = nombreFactor
        data.tipo = tipoFactor
        data.valor = valorFactor

        startLoading()
        const response = await createConfiguracionReporteLectura(data)
        if (response?.error){
            handleOpenAlert('error', response?.error)
        }
        else{
            await getConfiguracionesReporteLectura()
            handleOpenAlert('success', `La configuración del reporte de lectura se creó correctamente.`)
        }
        cancel()
        stopLoading()
    }

    const getConfiguracionesReporteLectura = useCallback(async () => {
        startLoading()
        const params = {}
        if (page) params.page = page

        const response = await getConfiguracionReporteLectura(params)
        if (response?.error){
            handleOpenAlert('error', response?.error)
        } else {
            setRows([])
            setNumPages(response?.last_page)

            response?.forEach(({id, codigo_factor, nombre_factor, tipo, valor}) => {
                const key = id
                const cell = [codigo_factor, nombre_factor, tipo === 1 ? 'Cuerpo' : 'Anillo', valor]
                addRow(key, cell, setRows, true)
            })

            // Para la paginación en la URL
            if (page > response?.last_page) {
                setPage(response?.last_page)
                history.push(`${window.location.pathname}?page=${response?.last_page}`)
            }
        }
        stopLoading()
    }, [getConfiguracionReporteLectura, page, history])


    const data = {
        loading,
        alertOpen,
        alertSeverity,
        alertMessage,
        tiposDosimetro,
        codigoFactor,
        errorCodigoFactor,
        helperCodigoFactor,
        nombreFactor,
        tipoFactor,
        valorFactor,
        rows,
        page,
        numPages,
        editar
        // openDelete,
        // factorToDelete
    }

    const actions = {
        setAlertOpen,
        onChangeCodigoFactor,
        onChangeNombreFactor,
        onChangeTipoFactor,
        onChangeValorFactor,
        handlePageChange,
        handleOnChangeEditar,
        // handleCloseDelete,
        // onDeleteFactor,
        // setFactorToDelete,
        findRow,
        cancel,
        saveConfiguracionReporteLectura
    }

    // Para cargar los archivos docx
    useEffect(async () => {
        await getConfiguracionesReporteLectura()
    }, [])

    return {data, actions}
}

export default useConfiguracionReporteLecturaPage
