import React from 'react'
import Myinput from './borramePage'

const BorrameApp = () => {

    return (
        <div>
            <h1>Aqui va mi componente</h1>
            <Myinput/>
        </div>
    )
}

export default BorrameApp