import axios from "axios"
import { useCallback } from "react"
import url from "../utils/url"
import useErrorHandler from "../utils/useErrorHandler"

const departamentosUrl = `${url}/departamentos`

const useDepartamentosService = () => {
    const {errorHandler} = useErrorHandler()

    const getDepartamentos = useCallback(
        async () => {
            try {
                const response = await axios.get(departamentosUrl)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { getDepartamentos }
}

export default useDepartamentosService