import React from 'react'
import PropTypes from 'prop-types'
import IconButtonMaterial from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

const IconButton = ({color, func, icon, size, tooltip, disabled}) => {
    return (
        <>
            { tooltip ?
                <Tooltip title={tooltip} placement="top-start">
                    <IconButtonMaterial
                        color={color ? color : 'primary'}
                        onClick={func}
                        size={size}
                        aria-label={tooltip}
                        disabled={disabled}
                    >
                        {icon}
                    </IconButtonMaterial>
                </Tooltip>
                :
                <IconButtonMaterial
                    color={color ? color : 'primary'}
                    onClick={func}
                    size={size}
                    disabled={disabled}
                >
                    {icon}
                </IconButtonMaterial>
            }
        </>

    )
}

IconButton.propTypes = {
    color: PropTypes.string,
    func: PropTypes.func,
    size: PropTypes.string,
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    // icon:
}

export default IconButton