import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { createPractica, getPracticaById, getPracticasByStatus, 
    updatePractica, deletePractica } from '../../services/administracion/practica'
import { mensajeCamposNoValidos } from '../../utils/messages'
import { addRow, findRow, updateRow } from '../../utils/rows'
import { allLetterAndSpace } from '../../utils/validations'
import useIsLoged from '../utils/useIsLoged'
import moment from 'moment-timezone'
//import { useHistory } from 'react-router-dom'; // Importa useHistory
const usePracticaPage = () => {
    const {id} = useParams()
    useIsLoged()
    const history = useHistory()//ya estaba agregado
    const [error, setError] = useState(null)
    const [rows, setRows] = useState([])
    const [nombre, setNombre] = useState('')
    const [errorNombre, setErrorNombre] = useState(false)
    const [helperNombre, setHelperNombre] = useState('')
    
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [practicaToDelete, setPracticaToDelete] = useState(null)

    //Estado para ver registros con estado = false (desactivados)
    const [verDesactivados, setVerDesactivados] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    const [loading, setLoading] = useState(0)
    
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    const handleDesactivadosChange = () => {
        setVerDesactivados(
            (verDesactivados) => !verDesactivados
        )
    }

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setPracticaToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const onChangeNombre = (text)=>
    {
        setNombre(text)
        if(!allLetterAndSpace(text) && text !== ""){
            setErrorNombre(true)
            setHelperNombre("El nombre debe consistir solo de letras.")
        }else{
            setErrorNombre(false)
            setHelperNombre("")
        }
    }

    const checkForErrors = () => {
        if (errorNombre || nombre === "")
        {
            return true
        }
        return false
    }

    const onCreatePractica = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const newPractica = {
            "nombre": nombre
        }
        startLoading()
        const result = await createPractica(newPractica)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            //const cell = [nombre]
            //const key = id
            //addRow(key, cell, setRows)
            getPracticas()
            handleOpenAlert('success', `La practica "${nombre}" se creo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onUpdatePractica = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const practicaActualizado = {
            "nombre": nombre
        }
        startLoading()
        const result = await updatePractica(id, practicaActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre} = result
            const newCells = [nombre]
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `La practica "${nombre}" se actualizo correctamente.`)
             // Se agrego la redirección aquí
             history.push('./'); // Cambia '/ruta-deseada' por la ruta a la que deseas redirigir
        }
        stopLoading()
        cancel()
    }

    const onDeletePractica = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deletePractica(practicaToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            //deleteRow(id, setRows)
            getPracticas()
            editCancel()
            handleOpenAlert('success', `La practica "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }


    const getPractica = useCallback(async (id) => {
        startLoading()
        const practica = await getPracticaById(id)
        if (practica.error){
            handleOpenAlert('error', practica.error)
        }
        else{
            setNombre(practica.nombre)
        }
        stopLoading()
    }, [])

    const getPracticas = useCallback(async () => {
        const estado = verDesactivados ? 0 : 1
        startLoading()
        const practicas = await getPracticasByStatus(estado, page)
        if (practicas.error){
            setError(practicas.error)
        }
        else{
            setRows([])
            setNumPages(practicas.last_page)
            practicas.data.forEach(({id, nombre}) => {
                const key = id
                const cell = [nombre]
                addRow(key, cell, setRows)
            })
            if(page > practicas.last_page){
                setPage(practicas.last_page)
                history.push(`${window.location.pathname}?page=${practicas.last_page}`)
            }
        }
        stopLoading()
    }, [history, page, verDesactivados])

    const editClick = (id) => {
        history.push(`/admin/practica/${id}?page=${page}`)
    }

    const editCancel = () => {
        cancel()
        history.push(`/admin/practica?page=${page}`)
    }

    const cancel = () => {
        setNombre("")
        setErrorNombre(false)
    }

    const data = {
        rows,
        nombre,
        errorNombre,
        helperNombre,
        id,
        openEdit,
        openDelete,
        practicaToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        verDesactivados,
        loading
    }

    const actions = {
        onDeletePractica,
        onCreatePractica,
        onUpdatePractica,
        editClick,
        onChangeNombre,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setPracticaToDelete,
        setAlertOpen,
        handlePageChange,
        handleDesactivadosChange
    }

    //useEffect para cargar datos en la tabla
    useEffect(() => {  
        getPracticas()
    }, [getPracticas])
    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getPractica(id)
        }
    }, [id, getPractica])
    const date = moment().tz("America/El_Salvador").format("YYYY-MM-DDTHH:mm:ssZ");
    return {error, data, actions}
}

export default usePracticaPage