import axios from 'axios'
import url from "./utils/url"
import headers from './utils/headers'
import useErrorHandler from './utils/useErrorHandler'
import { useCallback } from 'react'

const solicitudUrl = `${url}cotizacion`
const comentarioUrl = `${url}comentarios`
const institucionUrl = `${url}instituciones`

const useSolicitudCotizacionService = () => {
    const { errorHandler } = useErrorHandler()

    const createSolicitud = useCallback(
        async (solicitud) => {
            try {
                const requestUrl = `${solicitudUrl}/solicitudes`
                const response = await axios.post(requestUrl, solicitud,
                    { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getSolicitudById = useCallback(
        async (solicitud) => {
            const requestUrl = `${solicitudUrl}/solicitudes/${solicitud}`
            // console.log(requestUrl)
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                // console.log(requestUrl)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getSolicitudsByStatus = useCallback(
        async (page) => {
            const requestUrl = `${solicitudUrl}/solicitudes/status/1?page=${page}`
            console.log(requestUrl)
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                console.log(response)
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const updateSolicitud = useCallback(
        async (solicitud, cambios) => {
            const requestUrl = `${solicitudUrl}/solicitudes/${solicitud}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const deleteSolicitud = useCallback(
        async (solicitud) => {
            const requestUrl = `${solicitudUrl}/solicitudes/${solicitud}`
            try {
                const response = await axios.delete(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getTipoSolicitudes = useCallback(
        async () => {
            const requestUrl = `${solicitudUrl}/tiposSolicitud`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getTipoSolicitudById = useCallback(
        async (tipoSolicitud) => {
            const requestUrl = `${solicitudUrl}/tiposSolicitud/${tipoSolicitud}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getSolicitudesByTipoAndFecha = useCallback(
        async (page, filtro) => {
            const requestUrl = `${url}cotizacion/solicitudes/filter?page=${page}`
            try {
                const response = await axios.get(requestUrl,
                    { headers: headers(), params: filtro })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    // Comentarios
    const createComentario = useCallback(
        async (comentario) => {
            try {
                const response = await axios.post(comentarioUrl, comentario,
                    { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getComentariosByIdSolicitud = useCallback(
        async (id, tipo_comentario) => {
            const requestUrl = `${comentarioUrl}/${id}/${tipo_comentario}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    // Institucion
    const institucionByUserId = useCallback(
        async (id) => {
            const requestUrl = `${institucionUrl}/user/${id}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const subirArchivo = useCallback(
        async (file, handleOnUploadProgress) => {
            try {
                const requestUrl = `${comentarioUrl}/historial`
                const response = await axios.post(requestUrl, file,
                    {
                        headers: { 'Content-Type': 'multipart/form-data', ...headers() },
                        onUploadProgress: (progressEvent) => handleOnUploadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getTipoSolicitudSecretaria = useCallback(
        async () => {
            const requestUrl = `${solicitudUrl}/tiposSolicitud/secretaria`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )
    const getSecretariaSolicitudes = useCallback(
        async (page) => {
            const requestUrl = `${solicitudUrl}/solicitudes/secretaria/status/1?page=${page}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    const getLastComentarioByUserAndSolicitud = useCallback(
        async (idSolicitud, idUser) => {
            const requestUrl = `${comentarioUrl}/last/${idSolicitud}/${idUser}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )
    const comprobarEstadoEdicionSolicitud = useCallback(async () => {
            const requestUrl = `${solicitudUrl}/solicitudes/comprobarEstadoEdicion`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    return {
        createSolicitud, getSolicitudById, getSolicitudsByStatus, getTipoSolicitudes,
        getSolicitudesByTipoAndFecha, getTipoSolicitudById, createComentario,
        institucionByUserId, subirArchivo, getTipoSolicitudSecretaria,
        getSecretariaSolicitudes, getComentariosByIdSolicitud, updateSolicitud,
        getLastComentarioByUserAndSolicitud, comprobarEstadoEdicionSolicitud, deleteSolicitud
    }
}

export default useSolicitudCotizacionService
