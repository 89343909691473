import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    input: {
        paddingRight: '1em',
        marginLeft: '.5em'
    }
}));

const ComboBox = ({label, value, helper, list, onChange, error, disabled, required}) => {
    const classes = useStyles()
    return (
        <TextField
        select
        fullWidth
        label={label}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        helperText= {helper}
        error = {error}
        className={classes.input}
        disabled = {disabled}
        required={required}
        >
            {list && list.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                {item.label}
                </MenuItem>
            ))}
        </TextField>
    )
}

ComboBox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool,PropTypes.number,PropTypes.string,]).isRequired,
    helper: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape(
        {
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.bool,PropTypes.number,PropTypes.string,]).isRequired,
        }
    )).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
}

export default ComboBox
