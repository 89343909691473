import React from 'react'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Alert from '../../components/Alert/Alert'
import Progress from '../../components/Progress/Progress'
import {MdFindInPage} from 'react-icons/md'
import ReactSelect from "../../components/ReactSelect"
import Dialog from "../../components/Dialog/Dialog"
import moment from "moment";
import useRecibidosPage from "../../hooks/asignacionDosimetria/useRecibidosPage"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import TableBody from "@material-ui/core/TableBody"
import {AiFillWarning} from 'react-icons/ai'
import IconButton from "../../components/IconButton"
import {IoMdCheckmarkCircle, IoMdCloseCircle} from 'react-icons/io'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
    cell_long: {
        width: 50,
        minWidth: 50
    }
}))


const RecibidosPage = () => {
    const classes = useStyles()
    const {data, actions} = useRecibidosPage()
    const {
        alertOpen, alertSeverity, alertMessage, loading,
        selectedInstitucion, selectedInstalacion, selectedArea,
        rows, encabezado, openModalChequear, dosimetrosChequear, encabezadoDosimetrosChequear
    } = data
    const {
        setAlertOpen, handleChangeArea,
        handleChangeInstalacion, handleChangeInstitucion, loadAreas, loadInstalaciones, loadInstituciones,
        clearData, openCloseModalChequear, handleOnChangeComentario, handleChangeRecibido, handleChangeRecibiraLectura,
        handleChangeDañado, handleChangeExtraviado, handleChangeRecibiraReposicion, saveDosimetrosChequeados
    } = actions

    return (
        <>


            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Progress open={loading > 0}/>
                <Alert open={alertOpen} setOpen={setAlertOpen}
                       severity={alertSeverity}
                       message={alertMessage}/>

                {/* Institucion */}
                <Grid item xs={6}>
                    <ReactSelect
                        placeholder="Seleccione la institución disponible"
                        label="Institución"
                        asyncFunc={loadInstituciones}
                        onChange={handleChangeInstitucion}
                        value={selectedInstitucion}
                        isClearable={true}
                        components={
                            selectedInstitucion ?
                                {
                                    Menu: () => null
                                }
                                :
                                undefined
                        }
                    />
                </Grid>
                {/* Intalacion */}
                <Grid item xs={6}>
                    {
                        selectedInstitucion ?
                            <ReactSelect
                                key={0} value={selectedInstalacion}
                                placeholder="Seleccione la instalación disponible"
                                label="Instalación"
                                asyncFunc={loadInstalaciones}
                                onChange={handleChangeInstalacion}
                                disabled={!selectedInstitucion}
                                isClearable={true}
                                components={
                                    selectedInstalacion ?
                                        {
                                            Menu: () => null
                                        }
                                        :
                                        undefined
                                }
                            /> :
                            <ReactSelect
                                key={1} value={null}
                                placeholder="Seleccione la instalación disponible"
                                label="Instalación"
                                onChange={() => {
                                }}
                                asyncFunc={() => {
                                }}
                                disabled={true}
                                isClearable={true}
                            />
                    }
                </Grid>
                {/* Area */}
                <Grid item xs={6}>
                    {
                        selectedInstalacion ?
                            <ReactSelect
                                key={0} value={selectedArea}
                                placeholder="Seleccione el área disponible"
                                label="Área"
                                asyncFunc={loadAreas}
                                onChange={handleChangeArea}
                                disabled={!selectedInstalacion}
                                isClearable={true}
                                components={
                                    selectedArea ?
                                        {
                                            Menu: () => null
                                        }
                                        :
                                        undefined
                                }
                            /> :
                            <ReactSelect
                                key={1} value={null}
                                placeholder="Seleccione el área disponible"
                                label="Área"
                                onChange={() => {
                                }}
                                asyncFunc={() => {
                                }}
                                disabled={true}
                                isClearable={true}
                            />
                    }
                </Grid>
                <Grid item xs={2}>
                    &nbsp;
                    <Button variant='contained' text='Limpiar' func={clearData}/>
                </Grid>
            </Grid>
            <Grid>

                {/*Codigo para la Tabla*/}
                <Grid item xs={12}>
                    <div style={{width: '100%'}}>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            encabezado && encabezado.map(function (data) {
                                                return (<TableCell>{data}</TableCell>)
                                            })
                                        }
                                        {/*{ encabezados }*/}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows?.length > 0 ?
                                        rows && rows.map(function (row) {
                                            return (
                                                <TableRow className={classes.root}>
                                                    <TableCell>{row.institucion}</TableCell>
                                                    <TableCell>{row.instalacion}</TableCell>
                                                    <TableCell>{row.area}</TableCell>
                                                    <TableCell>{row.estado}</TableCell>
                                                    <TableCell>{row.fechaAsignada}</TableCell>
                                                    <TableCell>{row.fechaRecibido}</TableCell>
                                                    <IconButton
                                                        icon={row.sinCuerentena ? <AiFillWarning color='gray'/> :
                                                            <AiFillWarning color='black'/>}
                                                        tooltip={row.sinCuerentena ? 'Fuera de cuarentena' : 'En cuarentena'}/>
                                                    <IconButton
                                                        icon={row.sinCuerentena ? <MdFindInPage/> :
                                                            <MdFindInPage color='gray'/>}
                                                        func={row.sinCuerentena && (() => openCloseModalChequear(row))}
                                                        tooltip={row.sinCuerentena ? 'Consultar dosímetros' :
                                                            'Dosímetros aún en cuarentena'}/>
                                                </TableRow>
                                            )
                                        })
                                            :
                                            <TableRow>
                                                <TableCell>No hay datos</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
                {/*<Grid className={classes.input} item xs={2}>*/}
                {/*    <Button variant="contained" text="Recibir" func={openModalRecepcionesDosimetro}/>*/}
                {/*</Grid>*/}
                <Dialog
                    open={openModalChequear}
                    handleAccept={saveDosimetrosChequeados}
                    handleClose={openCloseModalChequear}
                    title={`${moment(Date.now()).format("DD MMMM YYYY")}`}
                    maxWidth='lg'
                    content={
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                encabezadoDosimetrosChequear && encabezadoDosimetrosChequear.map(function (data) {
                                                    return (<TableCell>{data}</TableCell>)
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            dosimetrosChequear && dosimetrosChequear.map(function (row) {
                                                return (
                                                    <TableRow className={classes.root}>
                                                        <TableCell>{row.dosimetro_id}</TableCell>
                                                        <TableCell>{row.tipo_dosimetro}</TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                func={() => handleChangeRecibido(row)}
                                                                icon={!row.recibido ?
                                                                    <IoMdCloseCircle color='red'/> :
                                                                    <IoMdCheckmarkCircle color='green'/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                func={() => handleChangeRecibiraLectura(row)}
                                                                icon={!row.apto_lectura ?
                                                                    <IoMdCloseCircle color='red'/> :
                                                                    <IoMdCheckmarkCircle color='green'/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                func={() => handleChangeDañado(row)}
                                                                icon={!row.dañado ?
                                                                    <IoMdCloseCircle color='red'/> :
                                                                    <IoMdCheckmarkCircle color='green'/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                func={() => handleChangeExtraviado(row)}
                                                                icon={!row.extraviado ?
                                                                    <IoMdCloseCircle color='red'/> :
                                                                    <IoMdCheckmarkCircle color='green'/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                func={() => handleChangeRecibiraReposicion(row)}
                                                                icon={!row.reposicion ?
                                                                    <IoMdCloseCircle color='red'/> :
                                                                    <IoMdCheckmarkCircle color='green'/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextareaAutosize
                                                                aria-label="empty textarea"
                                                                placeholder="Observaciones"
                                                                onChange={(value) => handleOnChangeComentario(row, value)}/>
                                                            {/*<Input placeholder="Observaciones"*/}
                                                            {/*onChange={(value) => handleOnChangeComentario(row, value)}/>*/}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                />
            </Grid>
        </>
    )
}


export default RecibidosPage