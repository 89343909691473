import React from "react";
import PropTypes from "prop-types";
import ItemListButton from "./ItemListButton";
import {TableCell} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import { BsCalendar } from "react-icons/bs";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

const renderActionButtons = (consultFnc, editFnc, deleteFnc, customBtn, key) => {
    return (
        <TableCell align="center">
            {
                consultFnc ? <ItemListButton type="consult" func={consultFnc}
                                             idValue={key}/> : null
            }
            {
                editFnc ? <ItemListButton type="edit" func={editFnc}
                                          idValue={key}/> : null
            }
            {
                deleteFnc ? <ItemListButton type="delete" func={deleteFnc}
                                            idValue={key}/> : null
            }
            {
                customBtn && customBtn.map(
                    (button) => {
                        return <ItemListButton key={key} type="edit" func={button.func} idValue={key}
                                               customIcon={button.icon}/>
                    })
            }
        </TableCell>
    )
}

const ListItem1 = ({rows, consultFunc, editFnc, deleteFnc, customBtn}) => {
    return (
        <Table>
            <TableBody>
                {rows && rows.map((row) => (
                    <TableRow key={row.key}>
                        {row.cells && row.cells.map((cell, index) => {
                            return index === 0 ?
                                <TableCell key={`${row.key}-${cell}-${index}`}>
                                    <BsCalendar/>&nbsp; {cell}
                                </TableCell>
                                :
                                <TableCell
                                    key={`${row.key}-${cell}-${index}`}>
                                    Estado: {cell}
                                </TableCell>
                        })}
                        {
                            renderActionButtons(consultFunc, editFnc, deleteFnc, customBtn, row.key)
                        }
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

ListItem1.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            cells: PropTypes.array.isRequired,
        }),
    ).isRequired,
    consultFunc: PropTypes.func,
    editFnc: PropTypes.func,
    deleteFnc: PropTypes.func,
    customBtn: PropTypes.arrayOf(
        PropTypes.shape({
            func: PropTypes.func.isRequired,
            icon: PropTypes.func.isRequired,
        }).isRequired,
    ),
};

export default ListItem1;
