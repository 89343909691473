import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const TipoComponent = ({ setTiposAModificar, tipos, valueTipos, isEdit }) => {
  const [tipoValues, setTipoValues] = useState({});
  const [inputErrors, setInputErrors] = useState({});
  const numericRegex = /^[0-9]*$/; // Expresión regular para validar números

  useEffect(() => {
    // Inicializar los valores del estado con los valores iniciales de "tipos"
    // const initialValues = {};
    // Object.keys(tipos).forEach((tipoKey) => {
    //   initialValues[tipoKey] = 0;
    // });
    // setTipoValues(initialValues);

    // setTipoValues({
    //   brazoC: 1,
    //   mamografico: 2,
    //   dentalPeriapical: 1,
    // });

    const eliminarValoresCero = (objeto) => {
      for (var clave in objeto) {
        if (objeto.hasOwnProperty(clave) && objeto[clave] === 0) {
          delete objeto[clave];
        }
      }
      return objeto;
    };

    let count = 1;

    if (Object.keys(valueTipos).length === 0 || count > 1) {
      const initialValues = {};
      Object.keys(tipos).forEach((tipoKey) => {
        initialValues[tipoKey] = 0;
      });
      setTipoValues(initialValues);
    } else {
      const valueTiposWithoutZeroValues = isEdit ? valueTipos[0] : eliminarValoresCero(valueTipos[0]);
      setTipoValues(valueTiposWithoutZeroValues);
      count++;
    }
  }, [tipos]);

  const handleInputChange = (tipoKey, value) => {
    // Validar si el valor es un número y está dentro del rango
    if (numericRegex.test(value) || value === "") {
      const numericValue = parseInt(value, 10);
      const maxValue = tipos[tipoKey]; // El número más grande permitido
      const newValue = Math.min(numericValue, maxValue); // Limitar al valor máximo

      // Actualizar el valor del tipo modificado
      setTipoValues((prevValues) => ({
        ...prevValues,
        [tipoKey]: newValue,
      }));
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [tipoKey]: false,
      }));
    } else {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [tipoKey]: true,
      }));
    }
  };

  useEffect(() => {
    // Cuando los valores cambian, actualizar "setTiposAModificar"
    setTiposAModificar(tipoValues);
  }, [tipoValues, setTiposAModificar]);

  return (
    <>
      {Object.entries(tipoValues).map(([tipoKey, tipoValue]) => (
        <Grid item xs={12} key={tipoKey}>
          <TextField
            label={`${tipoKey} (máximo ${tipos[tipoKey]})`}
            placeholder={tipoKey}
            value={tipoValue}
            onChange={(e) => handleInputChange(tipoKey, e.target.value)}
            error={inputErrors[tipoKey]}
            helperText={
              inputErrors[tipoKey] ? "Solamente debe ingresar números" : ""
            }
            variant="outlined"
            inputProps={{
              min: 0,
              max: tipos[tipoKey],
            }}
            required
            type="number"
            fullWidth // Ocupa el ancho disponible
          />
        </Grid>
      ))}
    </>
  );
};

export default TipoComponent;
