import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles'
import './InputFile.css'
import { Chip, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    input: {
        paddingRight: '1.2em',
    },
    chip: {
        maxWidth: '100%'
    }
}))

const FileInput = ({label, placeholder, helper, onChange, error, required, 
    disabled, multiple, files}) => {
    const classes = useStyles()
    console.log(files)
    return (
        <div>
            {
                files && files.length > 0 ?
                null
                :    
                <TextField
                    label={label}
                    style={{ margin: 8 }}
                    placeholder={placeholder}
                    error = {error}
                    helperText={helper}
                    fullWidth
                    type = "file"
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={classes.input}
                    onChange={event => {
                        //regresa el array de archivos
                        onChange(event.target.files)
                    }}
                    required = {required}
                    disabled = {disabled}
                    inputProps={{ 'multiple': multiple }}
                    />
            }
            {
                files && files.length > 0 ?
                <Typography variant="caption" color="textSecondary" 
                    display = "block">{label}</Typography>
                :
                null
            }
            {
                files && files.length > 0 && files.map((file) => {
                    return <Chip
                        label={file.name}
                        onDelete={file.deleteFile}
                        key = {file.name}
                        color = "primary"
                        className={classes.chip}
                        onClick = {file.onClickChip}
                        />
                })
            }
        </div>
    )
}

FileInput.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    helper: PropTypes.string,
    error: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    files: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            deleteFile: PropTypes.func.isRequired,
            onClickChip: PropTypes.func,
        }).isRequired,
    ),
}

export default FileInput
