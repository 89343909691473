import axios from "axios"
import url from "./utils/url"
import headers from "./utils/headers"
import useErrorHandler from './utils/useErrorHandler'
import { useCallback } from 'react'

const institucionesUrl = `${url}instituciones`

const useInstitucionService = () => {

  const { errorHandler } = useErrorHandler()

  // const createInstitucion = useCallback(
  //   async (instituciones) => {
  //     try {
  //       const response = await axios.post(institucionesUrl, instituciones, { headers: headers() })
  //       const { data } = response
  //       return data
  //     } catch (error) {
  //       return errorHandler(error)
  //     }
  //   }, [errorHandler],
  // )

  const getInstitucionById = useCallback(
    async (instituciones) => {
      const requestUrl = `${institucionesUrl}/${instituciones}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getAllInstituciones = useCallback(
    async () => {
      const requestUrl = `${institucionesUrl}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getInstitucionesByStatus = useCallback(
    async (status) => {
      const requestUrl = `${institucionesUrl}/status/${status}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getInstalacionesByInstitucionId = useCallback(
    async (id) => {
      const requestUrl = `${institucionesUrl}/instalaciones/${id}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  // const updateInstituciones = useCallback(
  //   async (instituciones, cambios) => {
  //     const requestUrl = `${institucionesUrl}/${instituciones}`
  //     try {
  //       const response = await axios.put(requestUrl, cambios, { headers: headers() })
  //       const { data } = response
  //       return data
  //     } catch (error) {
  //       return errorHandler(error)
  //     }
  //   }, [errorHandler],
  // )

  // const deleteInstituciones = useCallback(
  //   async (instituciones) => {
  //     const requestUrl = `${institucionesUrl}/${instituciones}`
  //     try {
  //       const response = await axios.delete(requestUrl, { headers: headers() })
  //       const { data } = response
  //       return data
  //     } catch (error) {
  //       return errorHandler(error)
  //     }
  //   }, [errorHandler],
  // )

  return {
    getInstitucionById, getAllInstituciones,
    getInstitucionesByStatus, getInstalacionesByInstitucionId
  }

}

export default useInstitucionService