import axios from "axios";
// import url from "./utils/url";
import headers from "../utils/headers";
// import useErrorHandler from "./utils/useErrorHandler";
import { useCallback } from "react";
import useErrorHandler from "../utils/useErrorHandler";
import url from "../utils/url";

const solicitudUrl = `${url}control_avance`;

const useControlAvanceCCService = () => {
  const { errorHandler } = useErrorHandler();

  const getControlAvancePorIdCC = useCallback(
    async (idControlAvance) => {
      const requestUrl = `${solicitudUrl}/${idControlAvance}`;

      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        //   console.log(requestUrl)
        const { data } = response;
        // const dataFiltradaSinTiposConCantidadCero = data.filter(
        //   (ctrl_avnce) => ctrl_avnce.tipos_control_avance.cantidad_equipo > 0
        // );
        const dataFiltradaSinTiposConCantidadCero =
          data.tipos_control_avance.filter(
            (tipoControl) => tipoControl.cantidad_equipo > 0
          );

        data.tipos_control_avance = dataFiltradaSinTiposConCantidadCero
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const createControlAvance = useCallback(
    async (controlAvance) => {
      try {
        const ruta = solicitudUrl;
        const response = await axios.post(ruta, controlAvance);
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return { getControlAvancePorIdCC, createControlAvance };
};

export default useControlAvanceCCService;
