import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useResumenService from '../../services/inscripcion/useResumenService'
import useUsuarioService from '../../services/administracion/useUsuarioService'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import useIsLoged from '../utils/useIsLoged'
import { useParams } from 'react-router-dom'
import useSolicitudCotizacionService from '../../services/solicitudCotizacion'

const useEvaluacionEtapa1Page = () => {
    const { getInstitucionById, getArchivoInstitucion, getArchivoRepresentanteLegal } = useResumenService()
    const { getArchivoUsuario } = useUsuarioService()
    const {evaluarSolicitud, evaluarSolicitudEdit} = useSolicitudInscripcionService()
    const {getSolicitudById} = useSolicitudCotizacionService()

    useIsLoged()
    const history = useHistory()
    const [loading, setLoading] = useState(0)
    const {id} = useParams()
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    // Estado que almacena un valor booleano y servira como variable de control para
    // el proceso de edicion
    const [modoEdicion, setModoEdicion] = useState(false)

    const [solicitud, setSolicitud] = useState(null)
    const [institucion, setInstitucion] = useState(null)
    const [representanteLegal, setRepresentanteLegal] = useState(null)
    //Estados del archivo de usuario
    const [userFile, setUserFile] = useState(null)
    const [userFileNumPages, setUserFileNumPages] = useState(null)
    const [userFilePageNumber, setUserFilePageNumber] = useState(1)
    function onUserDocumentLoadSuccess({ numPages }) {
        setUserFileNumPages(numPages);
    }
    const [openUserFileModal, setOpenUserFileModal] = useState(false)
    const handleCloseUserFileModal = () => {
        setOpenUserFileModal(false)
    }
    const handleUserFilePageChange = (event, value) => {
        setUserFilePageNumber(value)
    }
    //Estados del archivo de la institucion
    const [institucionFile, setInstitucionFile] = useState(null)
    const [institucionFileNumPages, setInstitucionFileNumPages] = useState(null)
    const [institucionFilePageNumber, setInstitucionFilePageNumber] = useState(1)
    function onInstitucionDocumentLoadSuccess({ numPages }) {
        setInstitucionFileNumPages(numPages);
    }
    const [openInstitucionFileModal, setOpenInstitucionFileModal] = useState(false)
    const handleCloseInstitucionFileModal = () => {
        setOpenInstitucionFileModal(false)
    }
    const handleInstitucionFilePageChange = (event, value) => {
        setInstitucionFilePageNumber(value)
    }
    //Estados del archivo del representante legal
    const [representanteLegalFile, setRepresentanteLegalFile] = useState(null)
    const [representanteLegalFileNumPages, setRepresentanteLegalFileNumPages] = useState(null)
    const [representanteLegalFilePageNumber, setRepresentanteLegalFilePageNumber] = useState(1)
    function onRepresentanteLegalDocumentLoadSuccess({ numPages }) {
        setRepresentanteLegalFileNumPages(numPages);
    }
    const [openRepresentanteLegalFileModal, setOpenRepresentanteLegalFileModal] = useState(false)
    const handleCloseRepresentanteLegalFileModal = () => {
        setOpenRepresentanteLegalFileModal(false)
    }
    const handleRepresentanteLegalFilePageChange = (event, value) => {
        setRepresentanteLegalFilePageNumber(value)
    }
    const [estadoSolicitud, setEstadoSolicitud] = useState(5)

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleGetSolicitud = useCallback(async () => {
        startLoading()
        const soli = await getSolicitudById(id)
        if (soli.error){
            handleOpenAlert('error', 'Ocurrio un error al obtener los datos de la solicitud')
        }
        else{
            // console.log('Solicitud', soli)
            if(soli[0].tipo_solicitud_id === 1){
                setSolicitud(soli[0])
                setEstadoSolicitud(Number(soli[0].estado_solicitud_id))
                setModoEdicion(Number(soli[0].estado_solicitud_id))
            }
        }
        stopLoading()
    }, [getSolicitudById, id])

    const handleGetInstitucion = useCallback(async () => {
        startLoading()
        const institucionSolicitud = await getInstitucionById(solicitud.institucion_id)
        if (institucionSolicitud.error){
            handleOpenAlert('error', 'Ocurrio un error al oobtener los datos de la institucion')
        }
        else{
            setInstitucion(institucionSolicitud.institucion)
            setRepresentanteLegal(institucionSolicitud.representanteLegal)
        }
        stopLoading()
    }, [getInstitucionById, solicitud])

    const handleGetUsuarioFile = useCallback(async (usuario) => {
        startLoading()
        const file = await getArchivoUsuario(usuario)
        if (file.error){
            handleOpenAlert('error', 'Ocurrio un error al cargar el archivo')
        }
        else{
            const blob = new Blob([file.data])
            setUserFile(blob)
        }
        stopLoading()
    }, [getArchivoUsuario])

    const handleGetInstitucionFile = useCallback(async (institucion) => {
        startLoading()
        const file = await getArchivoInstitucion(institucion)
        if (file.error){
            handleOpenAlert('error', 'Ocurrió un error al cargar el archivo')
        }
        else{
            const blob = new Blob([file.data])
            setInstitucionFile(blob)
        }
        stopLoading()
    }, [getArchivoInstitucion])

    const handleGetRepresentanteLegalFile = useCallback(async (institucion) => {
        startLoading()
        const file = await getArchivoRepresentanteLegal(institucion)
        if (file.error){
            handleOpenAlert('error', 'Ocurrió un error al cargar el archivo')
        }
        else{
            const blob = new Blob([file.data])
            setRepresentanteLegalFile(blob)
        }
        stopLoading()
    }, [getArchivoRepresentanteLegal])

    const handleOpenUserFile = async (usuario) => {
        await handleGetUsuarioFile(usuario)
        setOpenUserFileModal(true)
    }

    const handleOpenInstitucionFile = async (institucion) => {
        await handleGetInstitucionFile(institucion)
        setOpenUserFileModal(true)
    }

    const handleOpenRepresentanteLegalFile = async (institucion) => {
        await handleGetRepresentanteLegalFile(institucion)
        setOpenUserFileModal(true)
    }

    const onChangeRadio = (event) => {
        setEstadoSolicitud(Number(event.target.value))
    }

    const handleEvaluarSolicitud = async () => {
        startLoading()
        const evaluacion = {
            estado_solicitud: estadoSolicitud
        }
        // Verifica si se habia hecho una modificacion a la solicitud
        const result = Number(modoEdicion) === 6 ? await evaluarSolicitudEdit(id, evaluacion) :
            await evaluarSolicitud(id, evaluacion)
        if (result.error){
            handleOpenAlert('error', 'Ocurrió un error al evaluar la solcitud')
        }
        else{
            handleOpenAlert('success', 'Se evaluó la solicitud con éxito')
            history.push(`/solicitudes-inscripcion-etapa-1`)
        }
        stopLoading()
    }

    const data = {
        loading,
        institucion,
        representanteLegal,
        //archivo usuario
        openUserFileModal,
        userFilePageNumber,
        userFileNumPages,
        userFile,
        //archivo institucion
        openInstitucionFileModal,
        institucionFilePageNumber,
        institucionFileNumPages,
        institucionFile,
        //archivo representante legal
        openRepresentanteLegalFileModal,
        representanteLegalFilePageNumber,
        representanteLegalFileNumPages,
        representanteLegalFile,
        estadoSolicitud,
        alertOpen,
        alertSeverity,
        alertMessage
    }

    const actions = {
        //Archivo usuario
        handleUserFilePageChange,
        handleCloseUserFileModal,
        onUserDocumentLoadSuccess,
        handleOpenUserFile,
        //Archivo institucion
        handleInstitucionFilePageChange,
        handleCloseInstitucionFileModal,
        onInstitucionDocumentLoadSuccess,
        handleOpenInstitucionFile,
        //Archivo representante legal
        handleRepresentanteLegalFilePageChange,
        handleCloseRepresentanteLegalFileModal,
        onRepresentanteLegalDocumentLoadSuccess,
        handleOpenRepresentanteLegalFile,
        onChangeRadio,
        handleEvaluarSolicitud,
        setAlertOpen,
        handleGetInstitucion
    }

    //useEffect para cargar datos de las pestanias
    useEffect(() => {
        if(solicitud){
            handleGetInstitucion()
        }else{
            handleGetSolicitud()
        }
    }, [handleGetInstitucion, handleGetSolicitud, solicitud])

    // // useEffect para verificar si se encuentra en modo de edicion
    // useEffect( async () => {
    //     const edicion = await comprobarEstadoEdicionSolicitud()
    //     setModoEdicion(edicion)
    // })

    return {data, actions}
}

export default useEvaluacionEtapa1Page
