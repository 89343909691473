import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { addRow, findRow, updateRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
// import { allLetterAndSpace, isPhone } from '../../utils/validations'
// import { mensajeCamposNoValidos } from '../../utils/messages'
// import { paisesForSelect } from '../../utils/paises'
// import { getFilenameFromContentDisposition } from '../../utils/download'
import useTOEService from '../../services/inscripcion/useTOEService'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import { getNowInSeconds, stringDateToSeconds } from '../../utils/time'
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";

const useDatosTOEPage = () => {
    const history = useHistory()
    const { createTOE, updateTOE, getTOEById, getListaDeTOE,
        deleteTOE, getPracticas, getSubpracticaByNombreAndPractica,
        getPersonalByNombreAndInstalacion, createEditTOE, updateEditTOE,
        changeStatusTOE } = useTOEService()
    const { getAreasPorInstalacionPorNombre, getInstalacionesPorNombre,
         } = useSolicitudInscripcionService()
    const { comprobarEstadoEdicionSolicitud } = useSolicitudCotizacionService()
    useIsLoged()
    const {id} = useParams()
    //Estado para las filas de la tabla
    const [rows, setRows] = useState([])
    //Estados para controlar los inputs
    //(errorInput y helperInput sirven para mostrar un error en el input)
    const [instalacion, setInstalacion] = useState(null)
    const [area, setArea] = useState(null)
    const [personal, setPersonal] = useState(null)
    const [practicas, setPracticas] = useState([])
    const [practica, setPractica] = useState(null)
    const [subpractica, setSubpractica] = useState(null)
    const listaTiempoContratacion = [
        {label: "Mensual", value: "Mensual"},
        {label: "Trimestral", value: "Trimestral"},
        {label: "Semestral", value: "Semestral"},
        {label: "Anual", value: "Anual"},
    ]
    const [tiempoContratacion, setTiempoContratacion] = useState(null)
    const [fechaIngresoControl, setFechaIngresoControl] = useState(null)
    const [errorFechaIngresoControl, setErrorFechaIngresoControl] = useState(false)
    const [helperFechaIngresoControl, setHelperFechaIngresoControl] = useState('')
    const [anillo, setAnillo] = useState(0)
    const [cuerpoCompleto, setCuerpoCompleto] = useState(0)
    // Estado que almacena un valor booleano y servira como variable de control para
    // el proceso de edicion
    const [idSolicitud, setIdSolicitud] = useState(false)
    // Almacena el booleano de aprobado, como control para el proceso de edicion
    const [aprobado, setAprobado] = useState('')

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [TOEToDelete, setTOEToDelete] = useState(null)

    //Estado para progress, el setLoading siempre debe ser llamado en funciones
    //asincronas para funcionar correctamente
    const [loading, setLoading] = useState(0)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Controladores mensajes confirmacion
    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setTOEToDelete(id)
        setOpenDelete(true)
    }

    const handleCloseDelete = () => {
        setOpenDelete(false)

    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const onChangeFechaIngresoControl = (date) => {
        setFechaIngresoControl(date)
        const nowInSeconds = getNowInSeconds()
        const dateInSeconds = stringDateToSeconds(date)
        const oneYear = 365 * 24 * 60 * 60
        const oneYearFromNow = nowInSeconds + oneYear
        if(dateInSeconds > oneYearFromNow){
            setErrorFechaIngresoControl(true)
            setHelperFechaIngresoControl('La fecha de inicio no puede ser despues de un año.')
        }else{
            setErrorFechaIngresoControl(false)
            setHelperFechaIngresoControl('')
        }
    }

    //Funcion para manejar la creacion de un toe
    const onCreateTOE = async () => {
        const newTOE = {
            personal_id: personal && personal.value,
            area_id: area && area.value,
            sub_practica_id: subpractica && subpractica.value,
            tiempo_contratacion: tiempoContratacion,
            fecha_ingreso_control: fechaIngresoControl,
            cantidad_pd1: cuerpoCompleto > 0 ? cuerpoCompleto : undefined,
            cantidad_anillo: anillo > 0 ? anillo : undefined
        }
        startLoading()
        const result = idSolicitud ? await createEditTOE(newTOE) : await createTOE(newTOE)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            //TODO
            // console.log("guardo", result)
            handleOpenAlert('success', `El toe "${result.personal.nombres} ${result.personal.apellidos}" se creo correctamente.`)
            handleGetTOEOfInstitucion()
            cancel()
        }
        stopLoading()

    }

    //Funcion para manejar una actualizacion en los datos de un personal
    const onUpdateTOE = async () => {
        setOpenEdit(false)
        const TOEActualizado= {
            personal_id: personal && personal.value,
            area_id: area && area.value,
            sub_practica_id: subpractica && subpractica.value,
            tiempo_contratacion: tiempoContratacion,
            fecha_ingreso_control: fechaIngresoControl,
            cantidad_pd1: cuerpoCompleto > 0 ? cuerpoCompleto : undefined,
            cantidad_anillo: anillo > 0 ? anillo : undefined
        }
        startLoading()
        const result = aprobado ? await updateEditTOE(id, TOEActualizado) : await updateTOE(id, TOEActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, personal, area, cantidad_anillo, cantidad_pd1}
                = result
            const newCells = [`${personal.nombres} ${personal.apellidos}`,
            personal.dui ?
            `DUI: ${personal.dui}` : personal.licencia ?
            `Licencia de conduccion: ${personal.licencia}` : personal.pasaporte ?
            `Pasaporte: ${personal.pasaporte}` :
            "No tiene ningun identificador.", area.instalacion.nombre, area.nombre,
                `Anillo: ${cantidad_anillo ? cantidad_anillo : 0} PD1: ${cantidad_pd1 ? cantidad_pd1 : 0}`]
            // console.log("newCells", newCells)
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `Los datos del personal "${personal.nombres} ${personal.apellidos}" se actualizaron correctamente.`)
            editCancel()
        }
        await handleGetTOEOfInstitucion()
        stopLoading()

    }

    //Funcion para manejar el boton eliminar personal
    const onDeleteTOE = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteTOE(TOEToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {personal} = result
            handleGetTOEOfInstitucion()
            editCancel()
            handleOpenAlert('success', `El TOE "${personal.nombres} ${personal.apellidos}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    //Funcion para manejar la consulta de los datos de un personal
    const getTOE = useCallback(async (id) => {
        startLoading()
        const toe = await getTOEById(id)
        if (toe.error){
            handleOpenAlert('error', toe.error)
        }
        else{
            // console.log('TOE a editar', toe)
            //Datos toe
            setInstalacion({label: toe.area.instalacion.nombre, value: toe.area.instalacion.id})
            setPersonal({label: `${toe.personal.nombres} ${toe.personal.apellidos}`, value: toe.personal.id})
            setArea({label: toe.area.nombre, value: toe.area.id})
            setPractica(toe.sub_practica.practica.id)
            setSubpractica({label: toe.sub_practica.nombre, value: toe.sub_practica.id})
            setTiempoContratacion(toe.tiempo_contratacion)
            setFechaIngresoControl(toe.fecha_ingreso_control)
            setAnillo(toe.cantidad_anillo ? toe.cantidad_anillo : 0)
            setCuerpoCompleto(toe.cantidad_pd1 ? toe.cantidad_pd1 : 0)
            setAprobado(toe.aprobado)
        }
        stopLoading()
    }, [getTOEById])

    const handleGetInstalaciones = async (value) => {
        const instalaciones = await getInstalacionesPorNombre(value)
        if(instalaciones.error){
            return []
        }
        else{
            let options = []
            instalaciones.forEach(({id, nombre, disponible}) => {
                const label = nombre
                const value = id
                if (disponible) {
                    const instalacionComboBox = {
                        label,
                        value: value.toString()
                    }
                    options = [...options, instalacionComboBox]
                }
            })
            return options
        }
    }

    const handleGetPersonalOfInstalacionByName = useCallback(async (value) => {
        const personal = await getPersonalByNombreAndInstalacion(value, instalacion.value)
        if (personal.error){
            handleOpenAlert('error', personal.error)
        }
        else{
            let options = []
            personal.forEach(({id, nombres, apellidos, disponible}) => {
                const label = `${nombres} ${apellidos}`
                const value = id
                if (disponible) {
                    const cargoComboBox = {
                        label,
                        value: value.toString()
                    }
                    options = [...options, cargoComboBox]
                }
            })
            return options
        }
    }, [getPersonalByNombreAndInstalacion, instalacion])

    const handleGetTOEOfInstitucion = useCallback(async () => {
        startLoading()
        const toes = await getListaDeTOE(page)
        if (toes.error){
            handleOpenAlert('error', toes.error)
        }
        else{
            setRows([])
            // console.log("TOES de la instalacion", toes)
            setNumPages(toes.last_page)
            toes.data.forEach(({id, personal, area, cantidad_anillo, cantidad_pd1, disponible: estadoDisponible}) => {
                if (estadoDisponible === true) {
                    const key = id
                    const cell = [`${personal.nombres} ${personal.apellidos}`,
                        personal.dui ?
                            `DUI: ${personal.dui}` : personal.licencia ?
                                `Licencia de conducion: ${personal.licencia}` : personal.pasaporte ?
                                    `Pasaporte: ${personal.pasaporte}` :
                                    "No tiene ningun identificador.", area.instalacion.nombre, area.nombre,
                        `Anillo: ${cantidad_anillo ? cantidad_anillo : 0} PD1: ${cantidad_pd1 ? cantidad_pd1 : 0}`]
                    const disponible = estadoDisponible
                    addRow(key, cell, setRows, disponible)
                }
            })
            if(page > toes.last_page){
                setPage(toes.last_page)
                history.push(`${window.location.pathname}?page=${toes.last_page}`)
            }
        }
        stopLoading()
    }, [getListaDeTOE, history, page])

    const handleGetAreasByName = useCallback(async (value) => {
        const areas = await getAreasPorInstalacionPorNombre(instalacion.value, value)
        if(areas.error){
            return []
        }
        else{
            let options = []
            areas.forEach(({id, nombre, disponible}) => {
                const label = nombre
                const value = id
                if (disponible) {
                    const areaComboBox = {
                        label,
                        value: value.toString()
                    }
                    options = [...options, areaComboBox]
                }
            })
            return options
        }
    }, [getAreasPorInstalacionPorNombre, instalacion])

    const handleGetPracticas = useCallback(
        async () => {
            startLoading()
            const todasPracticas = await getPracticas()
            if(todasPracticas.error){
                handleOpenAlert('error', todasPracticas.error)
            }
            else{
                setPracticas([])
                // console.log('practicas', todasPracticas)
                todasPracticas.forEach(({id, nombre}) => {
                    const label = nombre
                    const value = id
                    const practicaComboBox = {
                        label,
                        value: value.toString()
                    }
                    setPracticas((practicas) => [...practicas, practicaComboBox])
                })
            }
            stopLoading()
        },
        [getPracticas],
    )

    const handleGetSubpracticas = useCallback(async (value) => {
        const todasSubpracticas = await getSubpracticaByNombreAndPractica(value, practica)
        if (todasSubpracticas.error){
            handleOpenAlert('error', todasSubpracticas.error)
        }
        else{
            // console.log("Subpracticas", todasSubpracticas)
            let options = []
            todasSubpracticas.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const subpracticaComboBox = {
                    label,
                    value: value.toString()
                }
                options = [...options, subpracticaComboBox]
            })
            return options
        }
    }, [getSubpracticaByNombreAndPractica, practica])

    //Funcion que activa y desactiva las areas
    const handleChangeEstadoDisponible = async (key) => {
        startLoading()
        const toe = await getTOEById(id)
        if (toe.error){
            handleOpenAlert('error', toe.error)
        }
        else {
            const activarDesactivarTOE = toe.aprobado == true ?
                await changeStatusTOE(key.target.id):
                await deleteTOE(key.target.id)
            if (activarDesactivarTOE.error) {
                handleOpenAlert('error', activarDesactivarTOE.error)
            } else {
                handleOpenAlert('success', "Se cambió el estado del personal")
            }
        }
        editCancel()
        await handleGetTOEOfInstitucion()
        stopLoading()
    }

    //Funcion maneja boton editar
    const editClick = (id) => {
        cancel()
        history.push(`/cliente/toe/${id}?page=${page}`)
    }

    //Funcion maneja boton cancelar edicion
    const editCancel = () => {
        cancel()
        history.push(`/cliente/toe?page=${page}`)
    }

    //Funcion cancelar (regresa los estados al valor inicial)
    const cancel = () => {

        setArea(0)
        setPersonal(0)
        setPractica(null)
        setSubpractica(null)
        setTiempoContratacion(null)
        setFechaIngresoControl("")
        setErrorFechaIngresoControl(false)
        setHelperFechaIngresoControl('')
        setAnillo(0)
        setCuerpoCompleto(0)
        //setInstalacion(0)
        setAprobado('')

    }

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        rows,
        instalacion,
        area,
        personal,
        practicas,
        practica,
        subpractica,
        listaTiempoContratacion,
        tiempoContratacion,
        fechaIngresoControl,
        errorFechaIngresoControl,
        helperFechaIngresoControl,
        anillo,
        cuerpoCompleto,
        id,
        openEdit,
        openDelete,
        TOEToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        idSolicitud
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        onCreateTOE,
        onUpdateTOE,
        onDeleteTOE,
        editClick,
        setInstalacion,
        setArea,
        setPersonal,
        setPractica,
        setSubpractica,
        setTiempoContratacion,
        onChangeFechaIngresoControl,
        setAnillo,
        setCuerpoCompleto,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setTOEToDelete,
        setAlertOpen,
        handlePageChange,
        handleGetPersonalOfInstalacionByName,
        handleGetInstalaciones,
        handleGetAreasByName,
        handleGetSubpracticas,
        handleChangeEstadoDisponible
    }

    useEffect(() => {
        if(!id){
            cancel()
        }
    }, [id, instalacion])

    const loadData = useCallback(async () => {
        await handleGetTOEOfInstitucion()
        await handleGetPracticas()
        const edicion = await comprobarEstadoEdicionSolicitud()
        setIdSolicitud(edicion)
    }, [handleGetPracticas, handleGetTOEOfInstitucion])

    //useEffect para cargar datos
    useEffect(() => {
        loadData()
        //Solo se ejecuta cuando cambian las funciones loadData
    }, [loadData])

    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getTOE(id)
        }
        //Solo se ejecuta cuando cambia el valor de id o getArea
    }, [id, getTOE])
    return {data, actions}
}

export default useDatosTOEPage
