import { makeStyles } from "@material-ui/core/styles";
import { RiGroupLine } from "react-icons/ri";
import Alert from "../../components/Alert";
import AppFrame from "../../components/AppFrame";
import Button from "../../components/Button";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import FormHeader from "../../components/FormHeader";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Input";
import Paper from "@material-ui/core/Paper";
import Progress from "../../components/Progress/Progress";
import React from "react";
import useEstadoSolicitudesRecibidasPage from "../../hooks/solicitudes_recibidas/useEstado";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

export const EstadoSolicitudesRecibidasPage = (props) => {
  const classes = useStyles();
  const { data, actions } = useEstadoSolicitudesRecibidasPage();
  const {
    loading,
    alertOpen,
    alertSeverity,
    alertMessage,
    setAlertOpen,
    id,
    openEdit,
    nombre,
    rows,
    openDelete,
    estadoToDelete,
    setEstadoToDelete,
  } = data;

  const {
    cancel,
    editCancel,
    editClick,
    findRow,
    handleClickOpenDelete,
    handleClickOpenEdit,
    handleCloseDelete,
    handleCloseEdit,
    onCreateEstado,
    onDeleteEstado,
    onUpdateEstado,
    onChangeNombre
  } = actions;

  return (
    <AppFrame loged={false}>
      <Progress open={loading > 0} />
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper}>
            <Alert
              open={alertOpen}
              setOpen={setAlertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
            <FormHeader
              title="Estado de Solicitudes Recibidas"
              subtitle="Datos Requeridos *"
            >
              <RiGroupLine />
            </FormHeader>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid container item xs={10}>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    label="Nombre"
                    placeholder="Ingrese el nombre"
                    value={nombre}
                    onChange={onChangeNombre}
                    required={true}
                  />
                </Grid>
                <Grid
                  container
                  item
                  className={classes.button}
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      func={id ? editCancel : cancel}
                      variant="outlined"
                      text="CANCELAR"
                    />
                  </Grid>
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      variant="contained"
                      text={id ? "EDITAR" : "AGREGAR"}
                      func={id ? handleClickOpenEdit : onCreateEstado}
                    />
                    <Dialog
                      open={openEdit}
                      handleClose={handleCloseEdit}
                      handleAccept={onUpdateEstado}
                      title={"Desea actualizar el registro?"}
                      content={`Los datos de "${nombre}" se actualizarán.`}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    headers={["Nombre"]}
                    rows={rows}
                    editFnc={(key) => editClick(key)}
                    deleteFnc={(key) => handleClickOpenDelete(key)}
                  />
                </Grid>
                <Dialog
                  open={openDelete}
                  handleClose={handleCloseDelete}
                  handleAccept={onDeleteEstado}
                  title={"Desea eliminar el registro?"}
                  content={
                    findRow(estadoToDelete, rows)
                      ? `"${findRow(estadoToDelete, rows).cells[0]}" será eliminado del sistema.`
                      : ""
                  }
                  transitionFunc={() => setEstadoToDelete(null)}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default EstadoSolicitudesRecibidasPage;
