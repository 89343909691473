//prueba
import axios from 'axios'
import errorHandler from '../utils/useErrorHandler'
import url from "../utils/url"
import headers from '../utils/headers'

const practicaUrl = `${url}practicas`

export const createPractica = async (practica) => {
    try {
        const response = await axios.post(practicaUrl, practica, {headers: headers()})
        const { data } = response
        return data
    } catch (error) {
        return errorHandler(error)
    }
}

export const getPracticaById = async (practica) => {
    const requestUrl = `${practicaUrl}/${practica}`
    try {
        const response = await axios.get(requestUrl, {headers: headers()})
        const { data } = response
        return data
    } catch (error) {
        return errorHandler(error)
    }
}

export const getPracticasByStatus = async (status, page) => {
    const requestUrl = `${practicaUrl}/status/${status}?page=${page}`
    try {
        const response = await axios.get(requestUrl, {headers: headers()})
        const { data } = response
        return data
    } catch (error) {
        return errorHandler(error)
    }
}

export const updatePractica = async (practica, cambios) => {
    const requestUrl = `${practicaUrl}/${practica}`
    try {
        const response = await axios.put(requestUrl, cambios, {headers: headers()})
        const { data } = response
        
        return data
    } catch (error) {
        return errorHandler(error)
    }
}

export const deletePractica = async (practica) => {
    const requestUrl = `${practicaUrl}/${practica}`
    try {
        const response = await axios.delete(requestUrl, {headers: headers()})
        const { data } = response
        return data
    } catch (error) {
        return errorHandler(error)
    }
}
