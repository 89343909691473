import React from 'react'
import AppFrame from '../../components/AppFrame'
import FormHeader from '../../components/FormHeader'
import FileInput from '../../components/FileInput'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import {RiAdminLine} from 'react-icons/ri'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import useContratoPage from '../../hooks/administracion/useContratoPage'
import Alert from '../../components/Alert'
import { FiDownload } from 'react-icons/fi'
import Pagination from '../../components/Pagination'
import Progress from '../../components/Progress'
import ProgressBar from '../../components/ProgressBar'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    }
}));

const encabezado = ["Nombre del contrato"]

const ContratoPage = () => {
    const classes = useStyles()
    const {data, actions} = useContratoPage()
    const {rows, nombre, id, openDelete, contratoToDelete, alertOpen, 
        alertMessage, alertSeverity, page, numPages, loading, 
        progress} = data
    const {onDeleteContrato, onCreateContrato, handleDownload, cancel, 
        editCancel, handleClickOpenDelete, handleCloseDelete, findRow, 
        setContratoToDelete, setAlertOpen, handlePageChange, 
        handleOnChangeContrato} = actions

    return (
        <AppFrame loged = {true}>
            <Progress open = {loading > 0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm= {7}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Administracion"
                            subtitle="Contrato">
                            <RiAdminLine/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {11}>
                                <Grid className={classes.input} item xs = {12}>
                                    {
                                        progress >= 0 ?
                                        <div>
                                            <Typography>{nombre}</Typography>
                                            <ProgressBar value = {progress}/>
                                        </div>
                                        :
                                        <FileInput label="Contrato" 
                                            placeholder="Seleccione el contrato a subir"
                                            onChange = {handleOnChangeContrato}
                                            required={true}
                                            />
                                    }
                                </Grid>
                                {
                                    progress < 0 ?
                                    <Grid container item>
                                        <Grid container item direction="row"
                                            justifyContent="center" spacing = {2}>
                                            <Grid className={classes.input} item 
                                                xs={5} sm= {3}>
                                                <Button
                                                    func = {
                                                        id?
                                                        editCancel
                                                        :
                                                        cancel
                                                    }
                                                    variant = "outlined"
                                                    text="CANCELAR"/>
                                            </Grid>
                                            <Grid className={classes.input} item
                                                xs={5} sm= {3}>
                                                <Button
                                                    variant = "contained"
                                                    text="AGREGAR"
                                                    func = {onCreateContrato}
                                                    />
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.table} item xs={12}>
                                            <DataTable headers={encabezado} 
                                                rows={rows}
                                                deleteFnc={(key)=>
                                                    handleClickOpenDelete(key)}
                                                customBtn={[{func : (id) => { handleDownload(id) }, icon : FiDownload, tooltip: "Descargar"}]}/>
                                        </Grid>
                                        <Grid item xs = {12}>
                                                <Pagination page={Number(page)}
                                                    numPages={Number(numPages)}
                                                    onChangePage = {handlePageChange}
                                                    />
                                            </Grid>
                                        <Dialog open={openDelete} 
                                            handleClose = {handleCloseDelete} 
                                            handleAccept = {onDeleteContrato} 
                                            title = {"Desea eliminar el registro?"} 
                                            content = { 
                                                findRow(contratoToDelete, rows) ?
                                                `El contrato con nombre "${ findRow(contratoToDelete, rows).cells[0] }" sera desactivado del sistema.`
                                                :
                                                ""
                                                }
                                            transitionFunc = {() => 
                                                setContratoToDelete(null)}/>
                                    </Grid>
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default ContratoPage