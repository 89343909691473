import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const ministerioUrl = `${url}ministerios`

const useMinisterioService = () => {
    const { errorHandler } = useErrorHandler()

    const createMinisterio = useCallback(
        async (ministerio) => {
            try {
                const response = await axios.post(ministerioUrl, ministerio, 
                    {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getMinisterioById = useCallback(
        async (ministerio) => {
            const requestUrl = `${ministerioUrl}/${ministerio}`
            
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getMinisteriosByStatus = useCallback(
        async (status, page) => {
            const requestUrl = `${ministerioUrl}/status/${status}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const updateMinisterio = useCallback(
        async (ministerio, cambios) => {
            const requestUrl = `${ministerioUrl}/${ministerio}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const deleteMinisterio = useCallback(
        async (ministerio) => {
            const requestUrl = `${ministerioUrl}/${ministerio}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createMinisterio, getMinisterioById, getMinisteriosByStatus, 
        updateMinisterio, deleteMinisterio }
}

export default useMinisterioService