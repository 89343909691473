import React from 'react'
import AppFrame from '../../components/AppFrame'
import { Link as RouterLink } from "react-router-dom";
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import DataTable from '../../components/DataTable'
import ComboBox from '../../components/ComboBox'
import useCotizacionPage from '../../hooks/cotizacion/useCotizacionPage'
import Alert from '../../components/Alert/Alert'
import Typography from '@material-ui/core/Typography'
import Progress from '../../components/Progress/Progress'
import DatePickerRange from '../../components/DatePickerRange/DatePickerRange'
import Pagination from '../../components/Pagination'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
}));

const encabezado = ["Solicitud", "Estado", "Hora de ultima modificación"]

const CotizacionHomePage = () => {
    const classes = useStyles()
    const { data, actions } = useCotizacionPage()
    const { rows, tiposDeSolicitudes, tipoSolicitud, alertOpen,
        alertSeverity, alertMessage, loading, page, numPages, value, idRol } = data
    const { setTipoSolicitud, setAlertOpen, handleSelectDate,
        handlePageChange, botonesDataTable } = actions

    return (
        <AppFrame loged={true}>
            <Progress open={loading>0}/>
            <Grid container spacing={1}>
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} md={10}>
                        <Paper className={classes.paper}>
                            <Alert open={alertOpen} setOpen={setAlertOpen}
                                severity={alertSeverity}
                                message={alertMessage} />

                            {/* Titulo y Boton*/}
                            <Grid className={classes.input} container direction="row"
                                alignItems="center">
                                <Grid container direction="row" justifyContent="flex-start"
                                    item xs={8}>
                                    <Typography className={classes.subtitulo} variant="h5">Solicitudes Realizadas</Typography>
                                </Grid>
                                {idRol === 3 || idRol === 2 ? null :
                                    <Grid container direction="row" justifyContent="center"
                                        alignItems="center" item xs={4}>
                                        <Grid item xs={8}>
                                            <Button
                                                component={RouterLink}
                                                to={"/nueva_solicitud"}
                                                variant="contained"
                                                text="Crear Solicitud"
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            {/*Codigo para los Combobox*/}
                            <Grid className={classes.input} container direction="row"
                                alignItems="center" spacing={1} justifyContent="center">
                                <Grid item xs={12} sm={8} md={8}
                                    alignItems="center">
                                    <ComboBox
                                        label="Todas las solicitudes"
                                        list={tiposDeSolicitudes}
                                        value={tipoSolicitud}
                                        onChange={(value) => { setTipoSolicitud(value) }}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}
                                    alignItems="center">
                                    <DatePickerRange
                                        onCallback={handleSelectDate} value={value} />
                                </Grid>
                            </Grid>

                            {/*Codigo para la Tabla*/}
                            <Grid className={classes.input} item xs={12}>
                                <DataTable headers={encabezado} rows={rows} 
                                    customBtn={botonesDataTable(idRol)} />
                            </Grid>
                            <Pagination page={Number(page)}
                                numPages={Number(numPages)}
                                onChangePage={handlePageChange}
                            />

                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </AppFrame>
    )
}


export default CotizacionHomePage