import { makeStyles } from "@material-ui/core/styles";
import { MaskField } from "react-mask-field";
import { RiGroupLine, RiRestartLine } from "react-icons/ri";
import Alert from "../../components/Alert";
import AppFrame from "../../components/AppFrame";
import Button from "../../components/Button";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import FormHeader from "../../components/FormHeader";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Input";
import Paper from "@material-ui/core/Paper";
import Progress from "../../components/Progress/Progress";
import React from "react";
import usePersonalccPage from "../../hooks/controlDeCalidad/usePersonalControlCalidad";
import useTransportePage from "../../hooks/transporte/useTransportePage";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

// function CustomMaskPlaca({ inputRef, ...otherProps }) {
//   return (
//     <MaskField
//       ref={inputRef}
//       mask="___-___"
//       replacement={{ _: /\d/ }}
//       {...otherProps}
//     />
//   );
// }

export const FormCliente = ({ data, actions, classes }) => {
  const {
    alertOpen,
    alertSeverity,
    alertMessage,
    openEdit,
    openDelete,
    transporteToDelete,
    loading,
    numPages,
    page,
    nombre,
    apellido,
    correo,
    errorNombre,
    errorApellido,
    errorCorreo,
    helperNombre,
    helperApellido,
    helperCorreo,
  } = data;

  const {
    handlePageChange,
    handleOpenAlert,
    handleClickOpenEdit,
    handleCloseEdit,
    handleClickOpenDelete,
    handleCloseDelete,
    onChangeNombre,
    onChangeApellido,
    onChangeCorreo,
  } = actions;

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Input
            type="text"
            label="Nombre"
            placeholder="Ingrese el nombre"
            value={nombre}
            onChange={onChangeNombre}
            error={errorNombre}
            helper={helperNombre}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            type="text"
            label="Apellido"
            placeholder="Ingrese el apellido"
            value={apellido}
            onChange={onChangeApellido}
            error={errorApellido}
            helper={helperApellido}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            type="text"
            label="Correo"
            placeholder="Ingrese el correo"
            value={correo}
            onChange={onChangeCorreo}
            error={errorCorreo}
            helper={helperCorreo}
            required={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const encabezado = ["Nombre", "Apellido", "Correo"];

const PersonalCCPage = (props) => {
  const classes = useStyles();
  const { data, actions } = usePersonalccPage();
  const {
    loading,
    alertOpen,
    alertSeverity,
    alertMessage,
    setAlertOpen,
    id,
    openEdit,
    nombre,
    rows,
    openDelete,
    personalToDelete,
    setPersonalToDelete,
    apellido,
  } = data;

  const {
    cancel,
    editCancel,
    editClick,
    findRow,
    handleClickOpenDelete,
    handleClickOpenEdit,
    handleCloseDelete,
    handleCloseEdit,
    onCreatePersonal,
    onDeletePersonal,
    onUpdatePersonal,
  } = actions;

  return (
    <AppFrame loged={false}>
      <Progress open={loading > 0} />
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper}>
            <Alert
              open={alertOpen}
              setOpen={setAlertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
            <FormHeader
              title="Registro de personal"
              subtitle="Datos Requeridos *"
            >
              <RiGroupLine />
            </FormHeader>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid container item xs={10}>
                <FormCliente data={data} actions={actions} classes={classes} />
                <Grid
                  container
                  item
                  className={classes.button}
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      func={id ? editCancel : cancel}
                      variant="outlined"
                      text="CANCELAR"
                    />
                  </Grid>
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      variant="contained"
                      text={id ? "EDITAR" : "AGREGAR"}
                      func={id ? handleClickOpenEdit : onCreatePersonal}
                      //text={"AGREGAR"}
                      //func={onCreateTransporte}
                    />
                    <Dialog
                      open={openEdit}
                      handleClose={handleCloseEdit}
                      handleAccept={onUpdatePersonal}
                      title={"Desea actualizar el registro?"}
                      content={`Los datos de "${nombre} ${apellido}" se actualizaran.`}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    headers={encabezado}
                    rows={rows}
                    editFnc={(key) => editClick(key)}
                    deleteFnc={(key) => handleClickOpenDelete(key)}
                    // editFnc={(key) => console.log(`editaras el id ${key}`)}
                    // deleteFnc={(key) => console.log(`Eliminaras el id ${key}`)}
                  />
                </Grid>
                <Dialog
                  open={openDelete}
                  handleClose={handleCloseDelete}
                  handleAccept={onDeletePersonal}
                  title={"Desea eliminar el registro?"}
                  content={
                    findRow(personalToDelete, rows)
                      ? `"${
                          findRow(personalToDelete, rows).cells[1]
                        }" sera eliminada/o del sistema.`
                      : ""
                  }
                  transitionFunc={() => setPersonalToDelete(null)}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </AppFrame>
  );
};
export default PersonalCCPage;
