import axios from "axios";
import url from "../utils/url";
// import headers from "./utils/headers";

// import useErrorHandler from "./utils/useErrorHandler";
import { useCallback } from "react";
import useErrorHandler from "../utils/useErrorHandler";
import headers from "../utils/headers";

// import useErrorHandler from "../utils/useErrorHandler";
// import headers from "../utils/headers";

// const eventoUrl = `${url}eventosDosimetria`
const actividadUrl = `${url}transportes`;

const useTransporteService = () => {
  const { errorHandler } = useErrorHandler();

  const createTransporte = useCallback(
    async (transporte) => {
      try {
        //const response = await axios.post(clienteUrl, transporte)

        const response = await axios.post(actividadUrl, transporte, {
          headers: headers(),
        });

        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getTransporteById = useCallback(
    async (idTransporte) => {
      const requestUrl = `${actividadUrl}/${idTransporte}`;
      try {
        const response = await axios.get(requestUrl);
        // const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const agregarNombreAArrayDeVehiculos = ({ data: array }) => {
    const newArray = array.map((item) => {
      return {
        ...item,
        nombre: item.placa,
      };
    });

    return newArray;
  };

  const getAllTransporte = useCallback(async () => {
    const requestUrl = `${actividadUrl}`;
    try {
      // const response = await axios.get(requestUrl);
      const response = await axios.get(requestUrl, { headers: headers() });
      const data = response;
      return agregarNombreAArrayDeVehiculos(data);
      // return data;
    } catch (error) {
      return errorHandler(error);
    }
  }, [errorHandler]);

  const deleteTransporte = useCallback(
    async (cliente) => {
      const requestUrl = `${actividadUrl}/${cliente}`;
      try {
        const response = await axios.delete(requestUrl, { headers: headers() });
        // const response = await axios.delete(requestUrl, { data: payload })
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const updateTransporte = useCallback(
    async (transporte, cambios) => {
      const requestUrl = `${actividadUrl}/${transporte}`;
      try {
        const response = await axios.put(requestUrl, cambios, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    getTransporteById,
    getAllTransporte,
    createTransporte,
    deleteTransporte,
    updateTransporte,
  };
};

export default useTransporteService;
