import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const cargoUrl = `${url}cargos`

const useCargoService = () => {
    const { errorHandler } = useErrorHandler()

    const createCargo = useCallback(
        async (cargo) => {
            try {
                const response = await axios.post(cargoUrl, cargo, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    ) 
    
    const getCargoById = useCallback(
        async (cargo) => {
            const requestUrl = `${cargoUrl}/${cargo}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    ) 
    
    const getCargosByStatus = useCallback(
        async (status, page) => {
            const requestUrl = `${cargoUrl}/status/${status}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const updateCargo = useCallback(
        async (cargo, cambios) => {
            const requestUrl = `${cargoUrl}/${cargo}`
            try {
                const response = await axios.put(requestUrl, cambios, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const deleteCargo = useCallback(
        async (cargo) => {
            const requestUrl = `${cargoUrl}/${cargo}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createCargo, getCargoById, getCargosByStatus, updateCargo, 
        deleteCargo }
}

export default useCargoService

