import {useEffect, useState} from 'react'
import useLoginService from '../../services/sesion/useLoginService'
import { mensajeCamposNoValidos } from '../../utils/messages'
import { isEmail, validPassword } from '../../utils/validations'

const useRecuperarPage = ({ mode }) => {

    const { cambioContrasenia, solicitarEnlace, activarCuenta } = useLoginService()

    const [correoElectronico, setCorreoElectronico] = useState('')
    const [errorCorreoElectronico, setErrorCorreoElectronico] = useState(false)
    const [helperCorreoElectronico, setHelperCorreoElectronico] = useState('')
    const [contrasenia1, setContrasenia1] = useState('')
    const [errorContrasenia1, setErrorContrasenia1] = useState(false)
    const [helperContrasenia1, setHelperContrasenia1] = useState('')
    const [contrasenia2, setContrasenia2] = useState('')
    const [errorContrasenia2, setErrorContrasenia2] = useState(false)
    const [helperContrasenia2, setHelperContrasenia2] = useState('')
    const [segundoPaso, setSegundoPaso] = useState(false)

    const queryParams = new URLSearchParams(window.location.search);
    const recuperarToken = queryParams.get('token');
    const email = queryParams.get('email');

    useEffect(() => {
        if(recuperarToken && email){
            setSegundoPaso(true)
            setCorreoElectronico(decodeURI(email))
        }
    }, [email, recuperarToken])

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    //Cookies
    //const setCookie = useCookies(['token'])[1]

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const onChangeCorreoElectronico = (text)=>
    {
        setCorreoElectronico(text)
        if(!isEmail(text) && text !== ""){
            setErrorCorreoElectronico(true)
            setHelperCorreoElectronico("Debe ingresar un correo electronico valido.")
        }else{
            setErrorCorreoElectronico(false)
            setHelperCorreoElectronico("")
        }
    }

    const onChangeContrasenia1 = (text)=>
    {
        setContrasenia1(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia1(true)
            setHelperContrasenia1("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia1(false)
            setHelperContrasenia1("")
        }
    }

    const onChangeContrasenia2 = (text)=>
    {
        setContrasenia2(text)
        if(!validPassword(text) && text !== ""){
            setErrorContrasenia2(true)
            setHelperContrasenia2("La contraseña debe contener una letra mayuscula, tres minusculas, un numero, un caracter especial y tener una longitud minima de 8 caracteres.")
        }else{
            setErrorContrasenia2(false)
            setHelperContrasenia2("")
        }
    }

    const checkForErrors = () => {
        if ( errorCorreoElectronico || correoElectronico === "")
        {
            return true            
        }
        if (segundoPaso && (errorContrasenia1 || contrasenia1 === "" || 
            errorContrasenia2 || contrasenia2  === "") ){
            return true
        }
        return false
    }

    const enviarCorreo = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const correo = {
            "email": correoElectronico
        }
        const result = await solicitarEnlace(correo)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            handleOpenAlert('success', 'Se envio un correo a su correo electronico con un enlace para cambiar su contraseña.')
        }
    }

    const handleCambioContrasenia = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        if(contrasenia1 !== contrasenia2){
            handleOpenAlert('error', "Las contraseñas introducidas no coinciden.")
            return
        }
        const contraseniaNueva = {
            token: recuperarToken,
            email: correoElectronico,
            password: contrasenia1,
            password_confirmation: contrasenia2
        }
        const result = (mode === "recuperar") ? 
            await cambioContrasenia(contraseniaNueva) : 
            await activarCuenta(contraseniaNueva)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const resultado = (mode === "recuperar") ? 'Se ha actualizado la contraseña con exito, puede iniciar sesión con su nueva contraseña.'
                                                    : 'Cuenta activada exitosamente, ahora puede iniciar sesión en la aplicación.'
            handleOpenAlert('success', resultado)
        }
    }

    const data = {
        correoElectronico,
        errorCorreoElectronico,
        helperCorreoElectronico,
        contrasenia1,
        errorContrasenia1,
        helperContrasenia1,
        contrasenia2,
        errorContrasenia2,
        helperContrasenia2,
        alertOpen,
        alertSeverity,
        alertMessage,
        segundoPaso
    }

    const actions = {
        onChangeCorreoElectronico,
        onChangeContrasenia1,
        onChangeContrasenia2,
        setAlertOpen,
        enviarCorreo,
        handleCambioContrasenia
    }


    return {data, actions}
}

export default useRecuperarPage