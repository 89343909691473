import React, {useState} from "react"
import AppFrame from "../../components/AppFrame"
import Button from "../../components/Button/Button"
import Dialog from "../../components/Dialog/Dialog"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import {makeStyles} from "@material-ui/core/styles"
import {Link as RouterLink} from "react-router-dom"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import "react-day-picker/lib/style.css"
import Progress from "../../components/Progress/Progress"
import BigCalendar from "../../components/BigCalendar/BigCalendar"
import usePlanificacionTecnicoPage from "../../hooks/planificacionDosimetria/usePlanificacionTecnicoPage"
import TecnicoPlanificacionConsultar from "./TecnicoPlanificacionConsultar"
import TabPanel from "../../components/TabPanel"
import Alert from "../../components/Alert"
import PlanificacionRecepcionesPage from "./PlanificacionRecepcionesPage"

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: "2em",
        paddingBottom: "2em",
    },
    input: {
        marginTop: ".5em",
        //marginBottom: ".5em",
    },
    root: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
}))

const Planificacion = (props) => {
    const classes = useStyles()

    const [value, setValue] = useState(0)

    const {data, actions} = usePlanificacionTecnicoPage()
    const {
        openResearch, fechaInicioEvento, loading, allEvents,
        alertOpen, alertSeverity, alertMessage
    } = data
    const {
        handleSelect, handleClickOpenResearch, setAlertOpen,
    } = actions

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <AppFrame loged={false}>
            <Progress open={loading > 0}/>
            <Alert
                open={alertOpen}
                setOpen={setAlertOpen}
                severity={alertSeverity}
                message={alertMessage}
            />
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                              centered>
                            <Tab label="Calendario"/>
                            <Tab label="Recepciones"/>
                            <Tab label="Entregas"/>
                        </Tabs>
                        <Grid item xs={12}>
                            <TabPanel value={value} index={0}>
                                <BigCalendar events={allEvents} handleSelect={handleSelect}/>
                                {/* Para los botones */}
                                <Grid container item direction="row" justifyContent="center" spacing={2}>
                                    <Grid className={classes.input} item xs={4}>
                                        <Button
                                            component={RouterLink}
                                            to={"/solicitudes-inscripcion-etapa-1"}
                                            variant="outlined"
                                            text="CANCELAR"
                                        />
                                    </Grid>
                                    <Grid className={classes.input} item xs={4}>
                                        <Button
                                            component={RouterLink}
                                            to={"/solicitudes-inscripcion-etapa-2"}
                                            variant="contained"
                                            text="SIGUIENTE"
                                        />
                                    </Grid>
                                </Grid>
                                {/* Fin Grid de botones */}
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={value} index={1}>
                                <PlanificacionRecepcionesPage entregaDeEventos={false}/>
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={value} index={2}>
                                <PlanificacionRecepcionesPage entregaDeEventos={true}/>
                            </TabPanel>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {/* MODAL PARA CONSULTAR */}
            <Dialog
                open={openResearch}
                handleClose={handleClickOpenResearch}
                title={"Evento del " + fechaInicioEvento}
                content={
                    <TecnicoPlanificacionConsultar data={data} actions={actions}/>
                }
            />
        </AppFrame>
    )
}

export default Planificacion
