import React from 'react'
import AppFrame from './../../components/AppFrame'
import FormHeader from './../../components/FormHeader'
import Input from './../../components/Input'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import FormDivider from './../../components/FormDivider'
import ComboBox from './../../components/ComboBox'
import DataTable from './../../components/DataTable'
import { makeStyles } from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Chip, Typography } from '@material-ui/core';
import ReactSelect from '../../components/ReactSelect'
import useDatosTOEPage from '../../hooks/inscripcion/useDatosTOEPage'
import Dialog from '../../components/Dialog'
import Pagination from '../../components/Pagination'
import Progress from '../../components/Progress/Progress'
import Alert from '../../components/Alert'
import { StepperInscripcion } from './DatosDelegadoPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    table: {
        marginTop: '2em'
    }
}))

const encabezado1 = ["Tipo dosimetro", "Cantidad"]

const encabezado2 = ["Nombre personal", "Identificador", "Instalacion", "Area",
    "Dosimetros"]

const DatosTOEPage = props => {
    const classes = useStyles()
    const {data, actions} = useDatosTOEPage()

    const {
        rows,
        instalacion,
        area,
        personal,
        practicas,
        practica,
        subpractica,
        listaTiempoContratacion,
        tiempoContratacion,
        fechaIngresoControl,
        errorFechaIngresoControl,
        helperFechaIngresoControl,
        anillo,
        cuerpoCompleto,
        id,
        openEdit,
        openDelete,
        TOEToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        idSolicitud
    } = data

    const {
        onCreateTOE,
        onUpdateTOE,
        onDeleteTOE,
        editClick,
        setInstalacion,
        setArea,
        setPersonal,
        setPractica,
        setSubpractica,
        setTiempoContratacion,
        onChangeFechaIngresoControl,
        setAnillo,
        setCuerpoCompleto,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setTOEToDelete,
        setAlertOpen,
        handlePageChange,
        handleGetPersonalOfInstalacionByName,
        handleGetInstalaciones,
        handleGetAreasByName,
        handleGetSubpracticas,
        handleChangeEstadoDisponible
    } = actions

    return (
        <AppFrame loged = {false}>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm= {8}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Datos internos | TOE">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {10}>
                                <FormDivider text="Datos del personal 
                                    ocupacionalmente expuesto"/>
                                <Grid className={classes.input} item xs = {12}>
                                    <ReactSelect value = {instalacion}
                                        onChange={setInstalacion}
                                        asyncFunc = {handleGetInstalaciones}
                                        label="Instalacion"
                                        placeholder = "Busque instalacion por nombre"
                                        disabled = {id ? true : false}
                                        components = {
                                            instalacion ? 
                                            {
                                                Menu: () => null
                                            }
                                            :
                                            undefined
                                        }
                                        isClearable = {true}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12}>
                                    {
                                        instalacion ? 
                                        <ReactSelect key = {0} value = {personal}
                                            onChange={setPersonal}
                                            asyncFunc = {handleGetPersonalOfInstalacionByName}
                                            label="Personal"
                                            placeholder = "Busque personal por nombre"
                                            disabled = {id ? true : false}
                                            isClearable = {true}
                                            />
                                        :
                                        <ReactSelect key = {1} value = {null}
                                            onChange={() => {}}
                                            asyncFunc = {() => {}}
                                            label="Personal"
                                            placeholder = "Busque personal por nombre"
                                            disabled = {true}
                                            isClearable = {true}
                                            />
                                    }
                                </Grid>
                                <Grid className={classes.input} item xs = {12}>
                                    {
                                        instalacion ?
                                        <ReactSelect key={0} value = {area}
                                            onChange={setArea}
                                            asyncFunc = {handleGetAreasByName}
                                            label="Area"
                                            placeholder = "Busque area por nombre"
                                            disabled = {id ? true : false}
                                            isClearable = {true}
                                            />
                                        :
                                        <ReactSelect key={1} value = {null}
                                            onChange={()=>{}}
                                            asyncFunc = {()=>{}}
                                            label="Area"
                                            placeholder = "Busque area por nombre"
                                            disabled = {true}
                                            isClearable = {true}
                                            />
                                    }
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    {
                                        practica ?
                                        <div>
                                            <Typography variant="caption" color="textSecondary">
                                                Practica
                                            </Typography>
                                            <div>
                                                <Chip
                                                    label={practicas.length > 0 && practica ? (practicas.find((practi)=>Number(practi.value) === Number(practica))) ? (practicas.find((practi)=>Number(practi.value) === Number(practica))).label : "Error" : "Error"}
                                                    onDelete={() => setPractica(null)}
                                                    />
                                            </div>
                                        </div>
                                        :
                                        <ComboBox label = "Practica"
                                            value={practica} 
                                            list = {practicas}
                                            onChange = {(value)=>
                                                {
                                                    setPractica(value)
                                                    setSubpractica(null)
                                                }}
                                            />
                                    }
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    {
                                        practica ?
                                        <ReactSelect key={0} value = {subpractica}
                                            onChange={setSubpractica}
                                            asyncFunc = {handleGetSubpracticas}
                                            label="Detalle de actividad"
                                            placeholder = "Busque detalle de actividad por nombre"
                                            //disabled = {id ? true : false}
                                            isClearable = {true}
                                            />
                                        :
                                        <ReactSelect key={1} value = {null}
                                            onChange={()=>{}}
                                            asyncFunc = {()=>{}}
                                            label="Detalle de actividad"
                                            placeholder = "Busque detalle de actividad por nombre"
                                            disabled = {false}
                                            isClearable = {true}
                                            />
                                    }
                                </Grid>
                                <Grid className={classes.input} xs = {12} sm={6}>
                                    <ComboBox label = "Tiempo de contratacion"
                                        value={tiempoContratacion} 
                                        list = {listaTiempoContratacion}
                                        onChange = {(value)=>
                                            setTiempoContratacion(value)}
                                        />
                                </Grid>
                                <Grid className={classes.input} xs = {12} sm={6}>
                                    <Input type = "date" label="Fecha de 
                                        ingreso a control" 
                                        placeholder="Ingrese la fecha"
                                        value = {fechaIngresoControl}
                                        onChange={onChangeFechaIngresoControl}
                                        error = {errorFechaIngresoControl}
                                        helper = {helperFechaIngresoControl}
                                        />
                                </Grid>
                                <FormDivider text="Dosimetros solicitados"/>
                                <Grid className={classes.input} item xs = {12}>               
                                    <Table size = "small">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    encabezado1.map(
                                                        (header, index) => {
                                                            return index === 0 ? 
                                                            <TableCell key = {header}>
                                                                <Typography className={classes.header}>
                                                                    {header}
                                                                </Typography>
                                                            </TableCell>
                                                            :
                                                            <TableCell key = {header} align="right">
                                                                <Typography className={classes.header}>
                                                                    {header}
                                                                </Typography>
                                                            </TableCell>
                                                        }
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    Anillo
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        type="number"
                                                        value={anillo}
                                                        onChange={(value)=>
                                                            setAnillo(value)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Cuerpo completo
                                                </TableCell>
                                                <TableCell>
                                                    <Input
                                                        type="number"
                                                        value={cuerpoCompleto}
                                                        onChange={(value)=>
                                                            setCuerpoCompleto(value)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid container item direction="row"
                                justifyContent="center" spacing = {2}>
                                    <Grid className={classes.input} item 
                                        xs={6} sm= {3}>
                                        <Button
                                            func = {
                                                id?
                                                editCancel
                                                :
                                                cancel
                                            }
                                            variant = "outlined"
                                            text="CANCELAR"
                                            disabled = {instalacion ? false : true}
                                            />
                                    </Grid>
                                    <Grid className={classes.input} item
                                        xs={6} sm= {3}>
                                        <Button
                                            variant = "contained"
                                            text={id?"EDITAR":"AGREGAR"}
                                            func = {id?
                                                handleClickOpenEdit
                                                :
                                                onCreateTOE}
                                            disabled = {instalacion ? false : true}
                                            />
                                        <Dialog open={openEdit} 
                                            handleClose = {handleCloseEdit} 
                                            handleAccept = {onUpdateTOE} 
                                            title = {"Desea actualizar el registro?"} 
                                            content = {`Los datos del toe se actualizaran.`}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid className={classes.table} container>
                        <Grid item xs={12}>
                            <DataTable headers={encabezado2} rows={rows}
                                editFnc={(key) => editClick(key)}
                                deleteFnc={idSolicitud ? null : (key) => handleClickOpenDelete(key)}
                                disableFnc={idSolicitud ? handleChangeEstadoDisponible : null}/>
                        </Grid>
                        <Grid item xs = {12}>
                            <Pagination page={Number(page)}
                                numPages={Number(numPages)}
                                onChangePage = {handlePageChange}
                                />
                        </Grid>
                        <Dialog open={openDelete} 
                            handleClose = {handleCloseDelete} 
                            handleAccept = {onDeleteTOE} 
                            title = {"Desea eliminar el registro?"} 
                            content = { 
                                findRow(TOEToDelete, rows) ?
                                `El toe con nombres "${ findRow(TOEToDelete, rows).cells[0] }" sera eliminado del sistema.`
                                :
                                ""
                                }
                            transitionFunc = {() => 
                                setTOEToDelete(null)}/>
                    </Grid>
                    <Grid container direction="row"
                        justifyContent="center" spacing = {2}>
                        <Grid className={classes.input} item 
                            xs={6} sm= {3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/personal'}
                                variant = "outlined"
                                text="ANTERIOR"/>
                        </Grid>
                        <Grid className={classes.input} item
                            xs={6} sm= {3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/resumen'}
                                variant = "contained"
                                text="SIGUIENTE"
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <StepperInscripcion activeStep={4}/>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default DatosTOEPage
