import axios from "axios"
import url from "./utils/url"
import headers from "./utils/headers"
import useErrorHandler from './utils/useErrorHandler'
import { useCallback } from 'react'

const areaUrl = `${url}areas`

const useAreaService = () => {
  const { errorHandler } = useErrorHandler()

  // const createArea = useCallback(
  //   async (area) => {
  //     try {
  //       const response = await axios.post(areaUrl, area, { headers: headers() })
  //       const { data } = response
  //       return data
  //     } catch (error) {
  //       return errorHandler(error)
  //     }
  //   }, [errorHandler],
  // )

  const getAreaById = useCallback(
    async (area) => {
      const requestUrl = `${areaUrl}/${area}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getAllAreas = useCallback(
    async () => {
      const requestUrl = `${areaUrl}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getAreasByStatus = useCallback(
    async (status, page) => {
      const requestUrl = `${areaUrl}/status/${status}?page=${page}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  const getAreasByIdInstalacion = useCallback(
    async (id) => {
      const requestUrl = `${areaUrl}/instalacion/${id}`
      try {
        const response = await axios.get(requestUrl, { headers: headers() })
        const { data } = response
        return data
      } catch (error) {
        return errorHandler(error)
      }
    }, [errorHandler],
  )

  // const updateArea = useCallback(
  //   async (area, cambios) => {
  //     const requestUrl = `${areaUrl}/${area}`
  //     try {
  //       const response = await axios.put(requestUrl, cambios, { headers: headers() })
  //       const { data } = response
  //       return data
  //     } catch (error) {
  //       return errorHandler(error)
  //     }
  //   }, [errorHandler],
  // )

  // const deleteArea = useCallback(
  //   async (area) => {
  //     const requestUrl = `${areaUrl}/${area}`
  //     try {
  //       const response = await axios.delete(requestUrl, { headers: headers() })
  //       const { data } = response
  //       return data
  //     } catch (error) {
  //       return errorHandler(error)
  //     }
  //   }, [errorHandler],
  // )

  return {
    getAreaById, getAllAreas, getAreasByStatus,
    getAreasByIdInstalacion
  }
}

export default useAreaService