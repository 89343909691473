import React from 'react'
import AppFrame from './../../components/AppFrame'
import FormHeader from './../../components/FormHeader'
import Input from './../../components/Input'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import FormDivider from './../../components/FormDivider'
import ComboBox from './../../components/ComboBox'
import DataTable from './../../components/DataTable'
import { makeStyles } from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import useDatosInstalacionesPage from '../../hooks/inscripcion/useDatosInstalacionesPage'
import Dialog from '../../components/Dialog'
import Pagination from '../../components/Pagination'
import Alert from '../../components/Alert'
import Progress from '../../components/Progress/Progress'
import Switch from '../../components/Switch'
import ReactSelect from '../../components/ReactSelect'
import { StepperInscripcion } from './DatosDelegadoPage'
import Session from 'react-session-api'
import {MaskField} from "react-mask-field";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    table: {
        marginTop: '2em'
    }
}))

const encabezado = ["Nombre", "Telefono", "Celular", "Departamento", 
    "Municipio", "Direccion"]

function CustomMaskNumberField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="____-____" replacement={{ _: /\d/ }} {...otherProps} />;
}

const DatosInstalacionesPage = props => {
    const classes = useStyles()
    
    const { data, actions } = useDatosInstalacionesPage()
    
    const {
        departamentosCombo,
        municipiosCombo,
        rows,
        nombre,
        errorNombre,
        helperNombre,
        telefono,
        errorTelefono,
        helperTelefono,
        celular,
        errorCelular,
        helperCelular,
        departamento,
        municipio,
        direccion,
        fechaInicioControl,
        errorFechaInicioControl,
        helperFechaInicioControl,
        id,
        openEdit,
        openDelete,
        instalacionToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        tieneEncargado,
        delegado, 
        idSolicitud
    } = data

    const {
        onCreateInstalacion,
        onUpdateInstalacion,
        onDeleteInstalacion,
        editClick,
        onChangeNombre,
        onChangeTelefono, 
        onChangeCelular, 
        onChangeDireccion,
        onChangeFechaInicioControl,
        setDepartamento,
        setMunicipio,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setInstalacionToDelete,
        setAlertOpen,
        handlePageChange,
        handleTieneEncargadoChange,
        setDelegado,
        handleGetDelegados,
        handleChangeEstadoDisponible
    } = actions

    return (
        <AppFrame loged = {false}>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm= {8}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Datos internos | Instalaciones">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {10}>
                                <FormDivider text="Datos de instalaciones"/>
                                <Grid className={classes.input} item xs = {12}>
                                    <Input type = "text" label="Nombre de la 
                                    instalacion" 
                                        placeholder="Ingrese el nombre de la 
                                        instalacion"
                                        value = {nombre}
                                        onChange={onChangeNombre}
                                        error = {errorNombre}
                                        helper = {helperNombre}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <Input type = "text" label="Telefono" 
                                        placeholder="Ingrese el telefono de 
                                        la instalacion"
                                        value = {telefono}
                                        onChange={onChangeTelefono}
                                        error = {errorTelefono}
                                        helper = {helperTelefono}
                                           InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <Input type = "text" 
                                        label="Telefono celular" 
                                        placeholder="Ingrese el telefono celular 
                                        de la instalacion"
                                        value = {celular}
                                        onChange={onChangeCelular}
                                        error = {errorCelular}
                                        helper = {helperCelular}
                                           InputProps={{ inputComponent: CustomMaskNumberField }}
                                        />
                                </Grid>
                                <FormDivider text="Ubicacion"/>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <ComboBox label = "Departamento"
                                        value={departamento} list = {departamentosCombo}
                                        onChange = {(value)=>{setDepartamento(value)}}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <ComboBox label = "Municipio"
                                        value={municipio} list = {municipiosCombo}
                                        onChange = {(value)=>{setMunicipio(value)}}/>
                                </Grid>
                                <Grid className={classes.input} item xs = {12} >
                                    <Input type = "text" label="Especificacion 
                                        de la direccion" 
                                        placeholder="Ingrese la direccion de la 
                                        instalacion"
                                        value = {direccion}
                                        onChange={onChangeDireccion}
                                        />
                                </Grid>
                                {
                                    Session.get('rol') ?
                                        Session.get('rol').id !== 3 ?
                                        <Grid className={classes.input} item xs = {12}>
                                            <Switch checked={tieneEncargado} 
                                                handleChange = {handleTieneEncargadoChange} 
                                                label = "¿Tiene un encargado?"
                                                />
                                            <ReactSelect value = {delegado}
                                                onChange={setDelegado} 
                                                asyncFunc = {handleGetDelegados}
                                                label="Encargado"
                                                placeholder = "Busque delegado por nombre"
                                                disabled = {!tieneEncargado}
                                                />
                                        </Grid>
                                        :
                                        null
                                    :
                                    null
                                }
                                <FormDivider text="Contratacion de servicio"/>
                                <Grid className={classes.input} item xs = {12} sm={6}>
                                    <Input type = "date" label="Fecha inicio de 
                                        control" 
                                        placeholder="Ingrese la fecha"
                                        value = {fechaInicioControl}
                                        onChange={onChangeFechaInicioControl}
                                        error = {errorFechaInicioControl}
                                        helper = {helperFechaInicioControl}
                                        />
                                </Grid>
                                {
                                    Session.get('rol') ?
                                        Session.get('rol').id !== 3 || id ?
                                        <Grid container item direction="row"
                                            justifyContent="center" spacing = {2}>
                                            <Grid className={classes.input} item 
                                                xs = {5} sm={3}>
                                                <Button
                                                    func = {
                                                        id?
                                                        editCancel
                                                        :
                                                        cancel
                                                    }
                                                    variant = "outlined"
                                                    text="CANCELAR"/>
                                            </Grid>
                                            <Grid className={classes.input} item
                                                xs = {5} sm={3}>
                                                <Button
                                                    variant = "contained"
                                                    text={id?"EDITAR":"AGREGAR"}
                                                    func = {id?
                                                        handleClickOpenEdit
                                                        :
                                                        onCreateInstalacion}
                                                    />
                                                <Dialog open={openEdit} 
                                                    handleClose = {handleCloseEdit} 
                                                    handleAccept = {onUpdateInstalacion} 
                                                    title = {"Desea actualizar el registro?"} 
                                                    content = {
                                                        findRow(Number(id), rows) ?
                                                        `Los datos de la instalacion "${nombre}" se actualizaran.`
                                                        :
                                                        "Error al cargar datos."
                                                    }/>
                                            </Grid>
                                        </Grid>
                                    :
                                    null
                                :
                                null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid className={classes.table} container>
                        <Grid item xs={12}>
                            <DataTable headers={encabezado} 
                                rows={rows}
                                editFnc={(key)=>
                                    editClick(key)}
                                deleteFnc={idSolicitud ? null : (key)=>
                                    handleClickOpenDelete(key)}
                                disableFnc={idSolicitud ? handleChangeEstadoDisponible : null}
                                />
                        </Grid>
                        <Grid item xs = {12}>
                            <Pagination page={Number(page)}
                                numPages={Number(numPages)}
                                onChangePage = {handlePageChange}
                                />
                        </Grid>
                        <Dialog open={openDelete} 
                            handleClose = {handleCloseDelete} 
                            handleAccept = {onDeleteInstalacion} 
                            title = {"Desea eliminar el registro?"} 
                            content = { 
                                findRow(instalacionToDelete, rows) ?
                                `La instalacion con nombre "${ findRow(instalacionToDelete, rows).cells[0] }" sera desactivada del sistema.`
                                :
                                ""
                                }
                            transitionFunc = {() => 
                                setInstalacionToDelete(null)}/>
                    </Grid>
                    <Grid container direction="row"
                        justifyContent="center" spacing = {2}>
                        <Grid className={classes.input} item 
                            xs = {5} sm={3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/delegados'}
                                variant = "outlined"
                                text="ANTERIOR"/>
                        </Grid>
                        <Grid className={classes.input} item
                            xs = {5} sm={3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/areas'}
                                variant = "contained"
                                text="SIGUIENTE"
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <StepperInscripcion activeStep={1}/>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default DatosInstalacionesPage
