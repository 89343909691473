import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import { IconContext } from 'react-icons'
import { MdCancel, MdEdit, MdFindInPage } from "react-icons/md";
import { createTheme , ThemeProvider}
    from '@material-ui/core/styles'
import { red, green, cyan } from '@material-ui/core/colors'

const theme = createTheme({
    palette: {
        primary: cyan,
        secondary: green,
        third: red,
    },
});

export const validTypes = [
    'consult',
    'edit',
    'delete'
]

const color = {
    consult: 'primary',
    edit: 'secondary',
    delete: 'third'
}

const icons = {
    consult: MdFindInPage,
    edit: MdEdit,
    delete: MdCancel
}

const ItemListButton = ({type, func, idValue, customIcon}) => {
    const Icon = customIcon ? customIcon : icons[type]
    return (
        <ThemeProvider theme = {theme}>
            <IconButton variant="contained" color = {color[type]} aria-label="delete" edge="end" onClick = {
                () => {
                    func(idValue)
                }
            }>
                <IconContext.Provider value = {{
                    size: '.7em',
                }} >
                    <Icon/>
                </IconContext.Provider>
            </IconButton>
        </ThemeProvider>)
}

ItemListButton.propTypes = {
    type: PropTypes.string.isRequired,
    func: PropTypes.func
}

export default ItemListButton
