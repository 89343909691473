import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'//s r

const rolUrl = `${url}roles`

const useRolService = () => {
    const history = useHistory(); //s r Definir history aquí
    const { errorHandler } = useErrorHandler()
    const createRol = useCallback(
        async (rol) => {
            try {
                const response = await axios.post(rolUrl, rol, 
                    {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getRolById = useCallback(
        async (rol) => {
            const requestUrl = `${rolUrl}/${rol}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getRoles = useCallback(
        async (page) => {
            const requestUrl = `${rolUrl}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getAllRoles = useCallback(
        async (page) => {
            const requestUrl = `${rolUrl}/all`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const updateRol = useCallback(
        async (rol, cambios) => {
            const requestUrl = `${rolUrl}/${rol}`
            try {
                const response = await axios.put(requestUrl, cambios,
                    {headers: headers()})
                const { data } = response
                 // Redireccionar a otra página después de la actualización exitosa
                 history.push('./'); //s r Cambia '/ruta-deseada' por la ruta a la que quieres redireccionar
                return data
            } catch (error) {
                 // Redireccionar a otra página después de la actualización exitosa
                 history.push('./'); //s r Cambia '/ruta-deseada' por la ruta a la que quieres redireccionar
                return errorHandler(error)
            }
        },
        [errorHandler,history],
    )
    
    const deleteRol = useCallback(
        async (rol) => {
            const requestUrl = `${rolUrl}/${rol}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createRol, getRolById, getRoles, getAllRoles, updateRol, deleteRol }
}

export default useRolService