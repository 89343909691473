import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MaterialPagination from '@material-ui/lab/Pagination'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
    pagination: {
        marginTop: '.5em', 
        marginBottom: '.5em'
    }
})

const Pagination = ({ page, numPages, onChangePage }) => {
    const classes = useStyles()
    return (
        <Grid container justifyContent="center">
            <Grid item>
                <MaterialPagination className={classes.pagination} 
                    count={numPages} 
                    shape="rounded" 
                    showFirstButton 
                    showLastButton
                    color = "primary"
                    boundaryCount = {1}
                    page = {page}
                    onChange = {onChangePage}
                    />
            </Grid>
        </Grid>
    )
}

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
}

export default Pagination
