import React from "react";
import Grid from "@material-ui/core/Grid";
import FormDivider from "../../components/FormDivider";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, List } from "@material-ui/core";
import { BsCalendar } from "react-icons/bs";
import ComboBox from "../../components/ComboBox";
import ReactSelect from "../../components/ReactSelect";
import Progress from "../../components/Progress/Progress";
import Input from "../../components/Input/Input";
import TipoComponent from "../../components/ControlDeCalidad/TipoComponent";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import HorarioComponent from "../../components/ControlDeCalidad/HorarioComponent";
import ControlAvanceTable from "./tablaControlAvanceCC";
import DateInput from "../../components/Date/DateInput";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
    //marginBottom: ".5em",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export const GuardarControlAvanceCC = (props) => {
  const classes = useStyles();

  return (
    <>
      {/* Paso */}
      <Grid
        className={classes.input}
        item
        xs={12}
        style={{ marginBottom: "16px" }}
      >
        {/* Nombre del representante de la institucion */}
        <Grid className={classes.input} item xs={12}>
          <Input
            label="Nombre del representante"
            size="small"
            disabled
            defaultValue="Nombre representante"
            variant="outlined"
            // value={"Juan Perez"}
            value={props.data.nombrePersonaRepresentante}
          />
        </Grid>

        <ReactSelect
          placeholder="Seleccione el paso"
          label="Paso*"
          asyncFunc={props?.actions?.loadPasos}
          onChange={props?.actions?.handleChangePaso}
          value={props?.data?.selectedPaso}
          isClearable={true}
          components={
            props?.data?.selectedPaso
              ? {
                  Menu: () => null,
                }
              : undefined
          }
        />
      </Grid>

      <ControlAvanceTable
        tipos_control_avance={props.data.tipos_control_avance}
        setTipos_control_avance={props.data.setTipos_control_avance}
        // style={{ marginTop: '100px' }}
      />

      <DateInput
        fecha={props.data.fecha_solicitud}
        setFecha={props.data.setFecha_solicitud}
        isEditable={false}
        label="Fecha de Solicitud"
        //style={{ marginTop: '16px' }}
      />
      <DateInput
        fecha={props.data.fecha_visita_tecnica}
        setFecha={props.data.setFecha_visita_tecnica}
        isEditable={true}
        label="Fecha de Visita Tecnica"
      />
      <DateInput
        fecha={props.data.fecha_entrega_de_informe_tecnico}
        setFecha={props.data.setFecha_entrega_de_informe_tecnico}
        isEditable={true}
        label="Fecha de Entrega De Informe Tecnico"
      />
      <DateInput
        fecha={props.data.fecha_entrega_certificado}
        setFecha={props.data.setFecha_entrega_certificado}
        isEditable={true}
        label="Fecha de Entrega de Certificado"
      />
      <DateInput
        fecha={props.data.fecha_notificacion}
        setFecha={props.data.setFecha_notificacion}
        isEditable={true}
        label="Fecha de Notificacion"
      />
      <DateInput
        fecha={props.data.fecha_entrega}
        setFecha={props.data.setfecha_entrega}
        isEditable={true}
        label="Fecha de Entrega"
      />
    </>
  );
};

export default GuardarControlAvanceCC;

// <Grid container direction="row" justifyContent="center">
// <Progress open={props.data.loading} />
// <FormDivider />
// <List component="nav" aria-labelledby="nested-list-subheader">
//   <FormDivider text="Detalles" />
//   <Grid container direction="row" justifyContent="center" spacing={2}>
//     {/*Nombre actividad **/}
//     <Grid item xs={12}>
//       <Input
//         type="text"
//         label="Nombre actividad"
//         placeholder="Ingrese el nombre de la actividad"
//         value={props.data.nombreActividad}
//         onChange={props.actions.onChangeNombreActividad}
//         error={props.data.errorNombreActividad}
//         helper={props.data.helperNombreActividad}
//         required={true}
//       />
//     </Grid>

//     {/* Estado */}
//     <Grid className={classes.input} item xs={12}>
//       <ReactSelect
//         placeholder="Seleccione el estado"
//         label="Estado*"
//         asyncFunc={props?.actions?.loadEstados}
//         onChange={props?.actions?.handleChangeEstado}
//         value={props?.data?.selectedStatus}
//         isClearable={true}
//         components={
//           props?.data?.selectedStatus
//             ? {
//                 Menu: () => null,
//               }
//             : undefined
//         }
//       />
//     </Grid>
//     {/* Instituciones */}
//     <Grid className={classes.input} item xs={12}>
//       <ReactSelect
//         placeholder="Seleccione la institucion"
//         label="Institucion*"
//         asyncFunc={props?.actions?.loadInstituciones}
//         onChange={props?.actions?.handleChangeInstituciones}
//         value={props?.data?.selectedInstituciones}
//         isClearable={true}
//         components={
//           props?.data?.selectedInstituciones
//             ? {
//                 Menu: () => null,
//               }
//             : undefined
//         }
//       />
//     </Grid>

//     {/* Fecha solicitada */}
//     <Grid className={classes.input} item xs={12}>
//       <Input
//         label="Fecha solicitada*"
//         size="small"
//         disabled
//         defaultValue="Fecha solicitada"
//         variant="outlined"
//         value={props.data.fechaInicioEvento}
//       />
//     </Grid>

//     {/* Departamento */}
//     <Grid className={classes.input} item xs={12}>
//       <Input
//         label="Departamento"
//         size="small"
//         disabled
//         defaultValue="Hello World"
//         variant="outlined"
//         value={props.data.departamento}
//       />
//     </Grid>

//     {/* Municipio */}
//     <Grid className={classes.input} item xs={12}>
//       <Input
//         label="Municipio"
//         size="small"
//         disabled
//         defaultValue="Hello World"
//         variant="outlined"
//         value={props.data.municipio}
//       />
//     </Grid>

//     {/* Detalle de direccion */}
//     <Grid className={classes.input} item xs={12}>
//       <Input
//         label="Detalle de direccion"
//         size="small"
//         disabled
//         defaultValue="Hello World"
//         variant="outlined"
//         value={props.data.detalleDireccion}
//       />
//     </Grid>

//     {/* tipos a modificar */}
//     <TipoComponent
//       setTiposAModificar={props.data.setTiposAModificar}
//       tipos={props.data.tiposDiferentesDeCero}
//       valueTipos={props.data.valueTipos}
//     />

//     {/* Transporte */}
//     <Grid className={classes.input} item xs={12}>
//       <ReactSelect
//         placeholder="Seleccione el transporte"
//         label="Transporte*"
//         asyncFunc={props?.actions?.loadTransporte}
//         onChange={props?.actions?.handleChangeTransporte}
//         value={props?.data?.selectedTransporte}
//         isClearable={true}
//         components={
//           props?.data?.selectedTransporte
//             ? {
//                 Menu: () => null,
//               }
//             : undefined
//         }
//       />
//     </Grid>

//     {/* Asignar personal */}
//     <Grid className={classes.input} item xs={12}>
//       <SelectComponent
//         // data={[
//         //   {
//         //     id: 1,
//         //     name: "Juan Perez",
//         //   },
//         //   {
//         //     id: 2,
//         //     name: "Pedro Alvarez",
//         //   },
//         // ]}
//         data={props?.data?.personalList}
//         // data={[]}
//         placeholder={"Seleccionar personal"}
//         label={"Personal*"}
//         setListaSeleccionados={props?.data?.setPersonal}
//         //valueSet={[{ id: 5, nombres: "Manuel", apellidos: "Villalobos" }]}
//         valueSet={props?.data?.personalListToUpdate}
//       />
//     </Grid>

//     {/*Comentario **/}
//     <Grid item xs={12}>
//       <Input
//         type="text"
//         label="Comentario"
//         placeholder="Ingrese el comentario"
//         value={props.data.comentario}
//         onChange={props.actions.onChangeComentario}
//         error={props.data.errorComentario}
//         helper={props.data.helperComentario}
//       />
//     </Grid>

//     {/* Hora seleccionada */}
//     <Grid className={classes.input} item xs={12}>
//       <Input
//         label="Horario*"
//         size="small"
//         disabled
//         defaultValue="Mañana"
//         variant="outlined"
//         value={props.data.nombreHorario}
//       />
//     </Grid>
//     {/* Horario */}
//     <Grid className={classes.input} item xs={12}>
//       <HorarioComponent
//         setHorario={props?.data?.setHorario}
//         horario={props?.data?.horario}
//       />
//     </Grid>
//   </Grid>
// </List>
// </Grid>
