import axios from "axios";
// import url from "./utils/url";
import headers from "../utils/headers";
// import useErrorHandler from "./utils/useErrorHandler";
import { useCallback } from "react";
import useErrorHandler from "../utils/useErrorHandler";
import url from "../utils/url";

const solicitudUrl = `${url}actividadesHistorial`;
const filesUrl = `${url}historiales`;

const useActividadDeReportesCCService = () => {
  const { errorHandler } = useErrorHandler();

  const actividadesHistorialCC = useCallback(
    async (id = 1, page) => {
      const parametros = [];

      if (page) {
        parametros.push(`page=${page}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}/${id}${parametrosString}`;

      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const uploadArchivoToHistorialActividad = useCallback(
    async (archivo, handleOnUploadProgress, idActividad) => {
      const finalUrl = `${filesUrl}/${idActividad}`;
      console.log({
        id: "mia",
        finalUrl,
      });
      console.log(idActividad);
      try {
        const response = await axios.post(`${finalUrl}`, archivo, {
          headers: { "Content-Type": "multipart/form-data", ...headers() },
          onUploadProgress: (progressEvent) =>
            handleOnUploadProgress(progressEvent.loaded, progressEvent.total),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getArchivoToHistorialActividad = useCallback(
    async (id) => {
      const requestUrl = `${filesUrl}/${id}`;

      try {
        const response = await axios.get(`${requestUrl}`, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const descargarArchivosHistorialActividad = useCallback(
    async (id) => {
      const requestUrl = `${filesUrl}/descargar/${id}`;
      try {
        // throw new Error("Este es un error personalizado.");
        const response = await axios.get(requestUrl, {
          headers: headers(),
          responseType: "blob",
        });

        // Extraer el nombre del archivo del encabezado "Content-Disposition"
        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch && filenameMatch[1];
        const newfilename = response.headers["content-disposition"].slice(
          response.headers["content-disposition"].indexOf("=") + 1
        );

        console.log({
          id: "sp",
          response,
        });

        // Crear una URL temporal con la respuesta y abrir una nueva ventana
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename || "archivo.xlsx"); // Usar el nombre del archivo o uno predeterminado
        document.body.appendChild(link);
        link.click();

        //setResultadoApi("exito");
        return response.data;
      } catch (error) {
        //setResultadoApi("error");
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const deleteArchivosHistorialActividad = useCallback(
    async (idActividadArchivo) => {
      const requestUrl = `${filesUrl}/${idActividadArchivo}`;
      try {
        const response = await axios.delete(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    actividadesHistorialCC,
    uploadArchivoToHistorialActividad,
    getArchivoToHistorialActividad,
    descargarArchivosHistorialActividad,
    deleteArchivosHistorialActividad,
  };
};

export default useActividadDeReportesCCService;
