import React from 'react'
import Button from '../../../components/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Alert from '../../../components/Alert/Alert'
import Progress from '../../../components/Progress/Progress'
import moment from "moment";
import useLecturasPage from "../../../hooks/asignacionDosimetria/useLecturasPage"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import TableBody from "@material-ui/core/TableBody"
import Typography from "@material-ui/core/Typography"
import IconButton from '@material-ui/core/IconButton';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AiOutlineClose} from 'react-icons/ai'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
    cell_long: {
        width: 50,
        minWidth: 50
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: "primary",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))


const LecturasPage = () => {
    const classes = useStyles()
    const {data, actions} = useLecturasPage()
    const {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        rows,
        encabezado,
        discoPosicionLectura,
        openIniciarLecturaModal,
        datosModalIniciarLectura,
        lecturaProgress,
    } = data
    const {
        setAlertOpen,
        openModalIniciarLectura,
        closeModalIniciarLectura,
        iniciarTerminarLecturaProgress,
        handleObservaciones,
        finalizarLectura,
    } = actions


    return (
        <>
            <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                <Progress open={loading}/>
                <Alert open={alertOpen} setOpen={setAlertOpen}
                       severity={alertSeverity}
                       message={alertMessage}/>
            </Grid>
            <Grid>

                {
                    rows.length > 0 ?
                        rows && rows.map(row => {
                            return (
                                row && row.map(function (tablaLectura, index) {
                                    return (
                                        <Grid item xs={12}>
                                            <div style={{width: '100%'}}>

                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={`Datos-${index}`}>
                                                                {
                                                                    encabezado && encabezado.map(function (data) {
                                                                        return (<TableCell>{data}</TableCell>)
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {/*<pre>*/}
                                                            {/*    {JSON.stringify(tablaLectura)}*/}
                                                            {/*</pre>*/}

                                                            {
                                                                tablaLectura && tablaLectura?.map(function (datosTablaDetalles) {
                                                                    return (
                                                                        <TableRow className={classes.root} key={`Datos-${index}-${datosTablaDetalles?.codigo}`}>
                                                                            <TableCell>{datosTablaDetalles?.codigo}</TableCell>
                                                                            <TableCell>{datosTablaDetalles?.tipo_dosimetro}</TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    datosTablaDetalles?.detalles && datosTablaDetalles?.detalles.map(function (posicionLectura) {
                                                                                        return (
                                                                                            <p>{posicionLectura?.posicion}</p>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </TableCell>

                                                                            <TableCell>
                                                                                <TextareaAutosize
                                                                                    aria-label="empty textarea"
                                                                                    placeholder="Observaciones"
                                                                                    onChange={(observacion) => handleObservaciones(observacion, index, datosTablaDetalles)}/>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            }


                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            <br/>
                                            <Grid container direction="row" xs={12} md={12} lg={12}
                                                  alignContent={"center"}>
                                                <Grid item xs={8} md={8} lg={8}>
                                                    <Typography component={"div"} variant="h6" paragraph={false}>
                                                        Disco: {discoPosicionLectura[index]?.disco}
                                                    </Typography>&nbsp;&nbsp;&nbsp;
                                                    <Typography component={"div"} variant="h6" paragraph={false}>
                                                        Posición de
                                                        lectura: {discoPosicionLectura[index]?.posicionLectura}
                                                    </Typography>
                                                </Grid>
                                                <Grid justifyContent="flex-end" xs={3}>
                                                    <Button variant="contained" text='Iniciar lectura' func={
                                                        () => openModalIniciarLectura(rows, tablaLectura, index)}/>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    )
                                })
                            )
                        })
                        :
                        <Grid item xs={12}>
                            <div style={{width: '100%'}}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    encabezado && encabezado.map(data => {
                                                        return (<TableCell>{data}</TableCell>)
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={'Sin datos'}>
                                                <TableCell>No hay datos</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                }
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    maxWidth={"lg"}
                    open={openIniciarLecturaModal}
                    onClose={closeModalIniciarLectura}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title">{`Lecturas para el ${moment(Date.now()).format("DD MMMM YYYY")}`}
                        <IconButton aria-label="close" className={classes.closeButton}
                                    onClick={closeModalIniciarLectura}>
                            <AiOutlineClose/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container item xs={12} md={12} lg={12} justifyContent="center"
                                  alignItems="center" alignContent="center">
                                <div style={{width: '100%'}}>
                                    <Typography component={"div"} variant="h5" paragraph={false}>
                                        Disco: {datosModalIniciarLectura.disco}
                                    </Typography>
                                    <Typography component={"div"} variant="h5" paragraph={false}>
                                        Posición de lectura: {datosModalIniciarLectura.posicionLectura}
                                    </Typography>
                                    {lecturaProgress &&
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <CircularProgress size={50}/>
                                        </div>
                                    }

                                    <Typography component={"div"} variant="body2" paragraph={false} color={"error"}>
                                        La posición elegida debe ser la colocada en el equipo TLD-HARSHAW 5500-L2
                                    </Typography>
                                    <Typography component={"div"} variant="body2" paragraph={false} color={"error"}>
                                        Iniciar la lectura antes de ingresarlos al equipo y finalizarlas al sacar el
                                        equipo
                                    </Typography>
                                </div>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.wrapper} style={{width: '100%'}}>
                            <Button func={iniciarTerminarLecturaProgress} color="primary" text={"Iniciar"}
                                    variant={"contained"} disabled={lecturaProgress}/>
                            {lecturaProgress && <CircularProgress size={24} className={classes.buttonProgress}/>}
                        </div>

                        {lecturaProgress &&
                            <Button func={finalizarLectura} color="primary" text={"Finalizar"} variant={"outlined"}/>
                        }
                    </DialogActions>
                </Dialog>
            </Grid>
        </>
    )
}


export default LecturasPage