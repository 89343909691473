import React from 'react'
import PropTypes from 'prop-types'
import MaterialButton from "@material-ui/core/Button"

const style = {
    fontWeight: "bold",
    width: '100%'
}

const Button = ({color, text, variant, func, component, to, disabled}) => {
    return (
        <MaterialButton size = "large" variant={variant} color={color ? color : "primary"}
            onClick={func} style = {style}
            component={component}
            to={to}
            disabled = {disabled}>
        {text}
        </MaterialButton>
    )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  func: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Button
