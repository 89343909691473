import React from 'react'
import AppFrame from '../../components/AppFrame'
import FormHeader from '../../components/FormHeader'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import {RiAdminLine} from 'react-icons/ri'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import useRolPage from '../../hooks/administracion/useRolPage'
import Alert from '../../components/Alert'
import FormDivider from '../../components/FormDivider'
import CheckBox from '../../components/CheckBox'
import Progress from '../../components/Progress/Progress'
import Pagination from '../../components/Pagination'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    }
}));

const encabezado = ["Nombre del rol", "Permisos del rol"]

const RolPage = () => {
    const classes = useStyles()
    const {data, actions} = useRolPage()
    const {rows, nombre, errorNombre, helperNombre, id, validPermisos, 
        openDelete, openEdit, rolToDelete, alertOpen, alertMessage, 
        alertSeverity, page, numPages, loading} = data
    const {onDeleteRol, onCreateRol, onUpdateRol,
        editClick, onChangeNombre, handleOnChangeCheckBox, cancel, editCancel, 
        handleClickOpenDelete, handleClickOpenEdit, handleCloseDelete, 
        handleCloseEdit, findRow, setRolToDelete, setAlertOpen, handlePageChange} = actions
    
    return (
        <AppFrame loged = {true}>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm={10}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Administracion"
                            subtitle="Rol">
                            <RiAdminLine/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {11}>
                                <Grid className={classes.input} item xs = {12}>
                                    <Input type = "text" label="Nombre del rol" 
                                        placeholder="Ingrese el nombre del rol"
                                        value = {nombre}
                                        onChange={(value) => 
                                            onChangeNombre(value)}
                                        error = {errorNombre}
                                        helper = {helperNombre}
                                        required={true}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {12}>
                                    <FormDivider text="Permisos"/>
                                </Grid>
                                {
                                    validPermisos.map((validPermiso) => 
                                    <Grid className={classes.input} 
                                        item xs = {12} sm={6}
                                        key={validPermiso.id}>
                                        <CheckBox label={validPermiso.nombre} 
                                        checked={validPermiso.state} 
                                        onChange={()=>handleOnChangeCheckBox(validPermiso.id)}
                                        />
                                    </Grid>
                                    )
                                }
                                <Grid container item direction="row"
                                    justifyContent="center" spacing = {2}>
                                    <Grid className={classes.input} item 
                                        xs = {5} sm={3}>
                                        <Button
                                            func = {
                                                id?
                                                editCancel
                                                :
                                                cancel
                                            }
                                            variant = "outlined"
                                            text="CANCELAR"/>
                                    </Grid>
                                    <Grid className={classes.input} item
                                         xs = {5} sm={3}>
                                        <Button
                                            variant = "contained"
                                            text={id?"EDITAR":"AGREGAR"}
                                            func = {id?
                                                handleClickOpenEdit
                                                :
                                                onCreateRol}
                                            />
                                        <Dialog open={openEdit} 
                                            handleClose = {handleCloseEdit} 
                                            handleAccept = {onUpdateRol} 
                                            title = {"Desea actualizar el registro?"} 
                                            content = {`Los permisos del rol se actualizaran por los seleccionados.`}/>
                                    </Grid>
                                </Grid>
                                <Grid className={classes.table} item xs={12}>
                                    <DataTable headers={encabezado} 
                                        rows={rows}
                                        editFnc={(key)=>
                                            editClick(key)}
                                        deleteFnc={(key)=>
                                            handleClickOpenDelete(key)}/>
                                </Grid>
                                <Grid item xs = {12}>
                                        <Pagination page={Number(page)}
                                            numPages={Number(numPages)}
                                            onChangePage = {handlePageChange}
                                            />
                                    </Grid>
                                <Dialog open={openDelete} 
                                    handleClose = {handleCloseDelete} 
                                    handleAccept = {onDeleteRol} 
                                    title = {"Desea eliminar el registro?"} 
                                    content = { 
                                        findRow(rolToDelete, rows) ?
                                        `El rol con nombre "${ findRow(rolToDelete, rows).cells[0] }" sera desactivado del sistema.`
                                        :
                                        ""
                                        }
                                    transitionFunc = {() => 
                                        setRolToDelete(null)}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default RolPage