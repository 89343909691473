import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Dialog = ({open, handleClose, handleAccept, title, content, transitionFunc, maxWidth}) => {
    return (
        <MaterialDialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionProps={{
                onExited: transitionFunc
            }}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    handleClose &&
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                }
                {
                    handleAccept &&
                    <Button onClick={handleAccept} color="primary" autoFocus>
                        Aceptar
                    </Button>
                }
            </DialogActions>
        </MaterialDialog>
    )
}

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    handleAccept: PropTypes.func,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    transitionFunc: PropTypes.func,
    maxWidth: PropTypes.string,
}

export default Dialog
