import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { addRow, findRow, updateRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
import { allLetterAndSpace, isPhone } from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import useDepartamentosService from '../../services/inscripcion/useDepartamentosService'
import { getNowInSeconds, stringDateToSeconds } from '../../utils/time'
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";

const useDatosInstalacionesPage = () => {
    const { getDepartamentos } = useDepartamentosService()

    const history = useHistory()
    const { createInstalacion, deleteInstalacion, getDelegadosPorNombre, getInstalacionById,
        getMisInstalaciones, updateInstalacion, createInstalacionEdit, updateInstalacionEdit,
        updateStatusInstalacionEdit } = useSolicitudInscripcionService()
    const { comprobarEstadoEdicionSolicitud } = useSolicitudCotizacionService()
    useIsLoged()
    const {id} = useParams()
    //Estado para las filas de la tabla
    const [rows, setRows] = useState([])
    //Estados para controlar los inputs
    //(errorInput y helperInput sirven para mostrar un error en el input)
    //Estados institucion
    const [departamentos, setDepartamentos] = useState([])
    const [departamentosCombo, setDepartamentosCombo] = useState([])
    const [municipiosCombo, setMunicipiosCombo] = useState([])
    const [nombre, setNombre] = useState('')
    const [errorNombre, setErrorNombre] = useState(false)
    const [helperNombre, setHelperNombre] = useState('')
    const [fechaInicioControl, setFechaInicioControl] = useState("")
    const [errorFechaInicioControl, setErrorFechaInicioControl] = useState(false)
    const [helperFechaInicioControl, setHelperFechaInicioControl] =
        useState('')
    const [telefono, setTelefono] = useState('')
    const [errorTelefono, setErrorTelefono] = useState(false)
    const [helperTelefono, setHelperTelefono] = useState('')
    const [celular, setCelular] = useState('')
    const [errorCelular, setErrorCelular] = useState(false)
    const [helperCelular, setHelperCelular] = useState('')
    const [departamento, setDepartamento] = useState('')
    const [municipio, setMunicipio] = useState('')
    const [direccion, setDireccion] = useState("")
    const [tieneEncargado, setTieneEncargado] = useState(false)
    const [delegado, setDelegado] = useState(null)
    const [errorDireccion, setErrorDireccion] = useState(false)
    const [helperDireccion, setHelperDireccion] = useState('')
    // Estado que almacena un valor booleano y servira como variable de control para
    // el proceso de edicion
    const [idSolicitud, setIdSolicitud] = useState(false)
    // Almacena el booleano de aprobado, como control para el proceso de edicion
    const [aprobado, setAprobado] = useState('')

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [instalacionToDelete, setInstalacionToDelete] = useState(null)

    //Estado para progress, el setLoading siempre debe ser llamado en funciones
    //asincronas para funcionar correctamente
    const [loading, setLoading] = useState(0)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    const handleTieneEncargadoChange = () => {
        setTieneEncargado(
            (tieneEncargado) => !tieneEncargado
        )
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Controladores mensajes confirmacion
    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setInstalacionToDelete(id)
        setOpenDelete(true)
    }

    const handleCloseDelete = () => {
        setOpenDelete(false)

    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorNombre || nombre === "" || errorCelular || errorTelefono || errorDireccion || direccion === "")
        {
            return true
        }
        return false
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Funciones para controlar cambios en inputs
    const onChangeNombre = (text)  =>
    {
        setNombre(text)
        if(!allLetterAndSpace(text)){
            setErrorNombre(true)
            setHelperNombre("El nombre solo debe consistir de letras.")
        }else{
            setErrorNombre(false)
            setHelperNombre("")
        }
    }

    const onChangeTelefono = (text)=>
    {
        setTelefono(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefono(true)
            setHelperTelefono("Debe ingresar un telefono valido valido.")
        }else{
            setErrorTelefono(false)
            setHelperTelefono("")
        }
    }

    const onChangeCelular = (text)=>
    {
        setCelular(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelular(true)
            setHelperCelular("Debe ingresar un telefono valido valido.")
        }else{
            setErrorCelular(false)
            setHelperCelular("")
        }
    }

    const onChangeDireccion = (text)  =>
    {
        setDireccion(text)
    }

    const onChangeFechaInicioControl = (date) => {
        setFechaInicioControl(date)
        const nowInSeconds = getNowInSeconds()
        const dateInSeconds = stringDateToSeconds(date)
        const oneYear = 365 * 24 * 60 * 60
        const oneYearFromNow = nowInSeconds + oneYear
        if(dateInSeconds > oneYearFromNow){
            setErrorFechaInicioControl(true)
            setHelperFechaInicioControl('La fecha de inicio no puede ser despues de un año.')
        }else{
            setErrorFechaInicioControl(false)
            setHelperFechaInicioControl('')
        }
    }

    //Funcion para manejar la creacion de una instalacion
    const onCreateInstalacion = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let newInstalacion = {
            nombre: nombre,
            telefono: telefono,
            telefono_celular: celular,
            direccion: {
                detalle: direccion,
                municipio_id: municipio
            },
            fecha_inicio_control: fechaInicioControl,
            delegado: tieneEncargado ? delegado && delegado.value : undefined
        }
        startLoading()
        const result = idSolicitud ? await createInstalacionEdit(newInstalacion) :
            await createInstalacion(newInstalacion)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            handleOpenAlert('success', `La instalacion "${nombre}" se creo correctamente.`)
            cancel()
            handleGetInstalaciones()
        }
        stopLoading()
    }

    //Funcion para manejar una actualizacion en los datos de una instalacion
    const onUpdateInstalacion = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let instalacionActualizada = {
            nombre: nombre,
            telefono: telefono,
            telefono_celular: celular,
            direccion: {
                detalle: direccion,
                municipio_id: municipio
            },
            fecha_inicio_control: fechaInicioControl,
            delegado: tieneEncargado ? delegado && delegado.value : undefined
        }
        startLoading()
        const result = (aprobado === true)? await updateInstalacionEdit(id, instalacionActualizada) :
            await updateInstalacion(id, instalacionActualizada)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre, telefono, telefono_celular, direccion, disponible: estadoDisponible}
                = result
            const newCells = [nombre, telefono, telefono_celular, direccion.municipio.departamento.nombre, direccion.municipio.nombre, direccion.detalle]
            const disponible = estadoDisponible
            updateRow(id, newCells, setRows, disponible)
            handleOpenAlert('success', `Los datos de la instalacion "${nombre}" se actualizo correctamente.`)
            editCancel()
            handleGetInstalaciones()
        }
        stopLoading()
    }

    //Funcion para manejar el boton eliminar instalacion
    const onDeleteInstalacion = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteInstalacion(instalacionToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            handleGetInstalaciones()
            editCancel()
            handleOpenAlert('success', `La instalacion "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    //Funcion para manejar la consulta de los datos de una instalacion
    const getInstalacion = useCallback(async (id) => {
        startLoading()
        const instalacion = await getInstalacionById(id)
        if (instalacion.error){
            handleOpenAlert('error', instalacion.error)
        }
        else{
            //Datos instalacion
            setNombre(instalacion.nombre)
            setTelefono(instalacion.telefono)
            setCelular(instalacion.telefono_celular)
            setDepartamento(instalacion.direccion.municipio.departamento_id)
            setMunicipio(instalacion.direccion.municipio_id)
            setDireccion(instalacion.direccion.detalle)
            setFechaInicioControl(instalacion.fecha_solicitud_instalacion[0].fecha_inicio_control)
            if(instalacion.delegado){
                setTieneEncargado(true)
                setDelegado({value: instalacion.delegado.user_id, label: instalacion.delegado.user.nombres})
            }else{
                setTieneEncargado(false)
                setDelegado(null)
            }
            setAprobado(instalacion.aprobado)
        }
        stopLoading()
    }, [getInstalacionById])

    const handleGetDelegados = async (value) => {
        const delegados = await getDelegadosPorNombre(value)
        if(delegados.error){
            return []
        }
        else{
            let options = []
            delegados.forEach(({id, nombres}) => {
                const label = nombres
                const value = id
                const delegadoComboBox = {
                    label,
                    value: value.toString()
                }
                options = [...options, delegadoComboBox]
            })
            return options
        }
    }

    const handleGetDepartamentos = useCallback(async () => {
        startLoading()
        // console.log("Tercero")
        const departamentos = await getDepartamentos()
        if(departamentos.error){
            handleOpenAlert('error', departamentos.error)
        }
        else{
            setDepartamentos(departamentos)
            setDepartamentosCombo([])
            departamentos.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const departamentoComboBox = {
                    label,
                    value: value.toString()
                }
                setDepartamentosCombo((departamentosCombo) => [...departamentosCombo, departamentoComboBox])
            })
        }
        stopLoading()
        // console.log("Cuarto")
    }, [getDepartamentos])

    const handleGetInstalaciones = useCallback(async () => {
        startLoading()
        // console.log("Primero")
        const instalaciones = await getMisInstalaciones(page)
        if (instalaciones.error){
            handleOpenAlert('error', instalaciones.error)
        }
        else{
            setRows([])
            setNumPages(instalaciones.last_page)
            instalaciones.data.forEach(({id, nombre, telefono,
                telefono_celular, direccion, disponible : estadoDisponible}) => {
                const { detalle, municipio } = direccion
                const key = id
                const cell = [nombre, telefono, telefono_celular, municipio.departamento.nombre, municipio.nombre, detalle]
                const disponible = estadoDisponible
                addRow(key, cell, setRows, disponible)
            })
            if(page > instalaciones.last_page){
                setPage(instalaciones.last_page)
                history.push(`${window.location.pathname}?page=${instalaciones.last_page}`)
            }
        }
        stopLoading()
        // console.log("Segundo")
    }, [getMisInstalaciones, history, page])

    //Funcion que activa y desactiva las instalaciones
    const handleChangeEstadoDisponible = async(key) => {
        startLoading()
        const instalacion = await getInstalacionById(key.target.id)
        if (instalacion.error){
            handleOpenAlert('error', instalacion.error)
        }
        else {
            const activarDesactivarInstalacion = instalacion.aprobado === true ?
                await updateStatusInstalacionEdit(key.target.id) :
                await deleteInstalacion(key.target.id)
            if (activarDesactivarInstalacion.error) {
                handleOpenAlert('error', activarDesactivarInstalacion.error)
            }
        }
        editCancel()
        await handleGetInstalaciones()
        stopLoading()
    }

    //Funcion maneja boton editar
    const editClick = (id) => {
        history.push(`/cliente/instalaciones/${id}?page=${page}`)
    }

    //Funcion maneja boton cancelar edicion
    const editCancel = () => {
        cancel()
        history.push(`/cliente/instalaciones?page=${page}`)
    }

    //Funcion cancelar (regresa los estados al valor inicial)
    const cancel = () => {
        setNombre("")
        setErrorNombre(false)
        setHelperNombre("")
        setTelefono("")
        setErrorTelefono(false)
        setHelperTelefono("")
        setCelular("")
        setFechaInicioControl("")
        setErrorCelular(false)
        setHelperCelular("")
        setDepartamento("")
        setMunicipio("")
        setDireccion("")
        setErrorDireccion(false)
        setHelperDireccion("")
        setAprobado('')
    }

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        departamentosCombo,
        municipiosCombo,
        rows,
        nombre,
        errorNombre,
        helperNombre,
        telefono,
        errorTelefono,
        helperTelefono,
        celular,
        errorCelular,
        helperCelular,
        departamento,
        municipio,
        direccion,
        errorDireccion,
        helperDireccion,
        fechaInicioControl,
        errorFechaInicioControl,
        helperFechaInicioControl,
        id,
        openEdit,
        openDelete,
        instalacionToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        tieneEncargado,
        delegado,
        idSolicitud
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        onCreateInstalacion,
        onUpdateInstalacion,
        onDeleteInstalacion,
        editClick,
        onChangeNombre,
        onChangeTelefono,
        onChangeCelular,
        onChangeDireccion,
        onChangeFechaInicioControl,
        setDepartamento,
        setMunicipio,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setInstalacionToDelete,
        setAlertOpen,
        handlePageChange,
        handleTieneEncargadoChange,
        setDelegado,
        handleGetDelegados,
        handleChangeEstadoDisponible
    }

    //useEffect para cargar combobox de municipios al seleccionar departamento
    useEffect(() => {
        setMunicipiosCombo([])
        // console.log('departamento seleccionado', Number(departamento))
        // console.log('departamentos', departamentos)
        const municipiosDepartamento = departamentos.find( departamentoSeleccionado => departamentoSeleccionado.id === Number(departamento) )
        // console.log('municipiosDepartamento', municipiosDepartamento)
        municipiosDepartamento && municipiosDepartamento.municipios.forEach(({id, nombre}) => {
            const label = nombre
            const value = id
            const municipioComboBox = {
                label,
                value: value.toString()
            }
            setMunicipiosCombo((municipiosCombo) => [...municipiosCombo, municipioComboBox])
        })

    }, [departamentos, departamento])

    const loadData = useCallback(async () => {
        await handleGetInstalaciones()
        await handleGetDepartamentos()
        const edicion = await comprobarEstadoEdicionSolicitud()
        setIdSolicitud(edicion)
    }, [handleGetDepartamentos, handleGetInstalaciones])

    //useEffect para cargar datos en la tabla y combobox
    useEffect(() => {
        loadData()
        //Solo se ejecuta cuando cambian las funciones
    }, [loadData])

    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getInstalacion(id)
        }
        //Solo se ejecuta cuando cambia el valor de id o getInstalacion
    }, [id, getInstalacion])
    return {data, actions}
}

export default useDatosInstalacionesPage
