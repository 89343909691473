import React from 'react'

const Error404Page = props => {
    return (
        <div>
            <h1>No se ha encontrado la pagina solicitada.</h1>
        </div>
    )
}

export default Error404Page
