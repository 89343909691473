import React from 'react'
import Button from '../../../components/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Alert from '../../../components/Alert/Alert'
import Progress from '../../../components/Progress/Progress'
import useLecturasFinalizadasPage from "../../../hooks/asignacionDosimetria/useLecturasFinalizadasPage"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Paper from "@material-ui/core/Paper"
import TableBody from "@material-ui/core/TableBody"
import Typography from "@material-ui/core/Typography"
import Input from "../../../components/Input"


const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em',
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.9em',
        marginRight: '.9em',
    },
    cell_long: {
        width: 50,
        minWidth: 50
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: "primary",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))


const LecturasPage = () => {
    const classes = useStyles()
    const {data, actions} = useLecturasFinalizadasPage()
    const {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        encabezado,
        arrayLecturaLotes,
        dateInput,
    } = data
    const {
        setAlertOpen,
        handleSelectDate,
        clearData,
    } = actions


    return (
        <div>

            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Progress open={loading}/>
                <Alert open={alertOpen} setOpen={setAlertOpen}
                       severity={alertSeverity}
                       message={alertMessage}/>
                <Grid container item xs={4}>
                    <Input label="Fecha" type="date" placeholder="MM/DD/YYYY" onChange={handleSelectDate} value={dateInput}/>
                </Grid>
                <Grid item xs={2}>
                    &nbsp;
                    <Button variant='outlined' text='Limpiar' func={clearData}/>
                </Grid>
            </Grid>
            <Grid>
                {
                    arrayLecturaLotes.length > 0 ?
                        arrayLecturaLotes && arrayLecturaLotes.map(function (lecturaLote, index) {
                            return (

                                <Grid item xs={12}>
                                    <div style={{width: '100%'}}>

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {
                                                            encabezado && encabezado.map(function (data) {
                                                                return (<TableCell>{data}</TableCell>)
                                                            })
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {
                                                        lecturaLote?.detalles_lectura_lote_finalizado &&
                                                        lecturaLote?.detalles_lectura_lote_finalizado.map(detalle_lectura_lote => {
                                                            return (<TableRow className={classes.root}>
                                                                <TableCell>{detalle_lectura_lote?.codigo_dosimetro}</TableCell>
                                                                <TableCell>{detalle_lectura_lote?.tipo_dosimetro}</TableCell>
                                                                <TableCell>{detalle_lectura_lote?.posicion}</TableCell>
                                                                <TableCell>{detalle_lectura_lote?.observacion}</TableCell>
                                                            </TableRow>)
                                                        })
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <br/>
                                    <Grid container direction="row" xs={12} md={12} lg={12} alignContent={"center"}>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <Typography component={"div"} variant="h6" paragraph={false}>
                                                Lote: {lecturaLote?.codigo}
                                            </Typography>&nbsp;&nbsp;&nbsp;
                                            <Typography component={"div"} variant="h6" paragraph={false}>
                                                Posición de lectura: {lecturaLote?.disco}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} md={8} lg={8}>
                                            <Typography component={"div"} variant="body2" paragraph={false}>
                                                Fecha y hora inicio de control: {lecturaLote?.tiempo_inicio_lectura}
                                            </Typography>
                                            <Typography component={"div"} variant="body2" paragraph={false}>
                                                Fecha y hora de lectura iniciada: {lecturaLote?.tiempo_inicio_lectura}
                                            </Typography>
                                            <Typography component={"div"} variant="body2" paragraph={false}>
                                                Fecha y hora de lectura finalizada: {lecturaLote?.tiempo_fin_lectura}
                                            </Typography>
                                        </Grid>
                                        {/*<Grid justifyContent="flex-end" xs={3}>*/}
                                        {/*    <Button variant="contained" text='Iniciar lectura' func={*/}
                                        {/*        () => openModalIniciarLectura(rows, tablaLectura, index)}/>*/}
                                        {/*</Grid>*/}
                                    </Grid>
                                </Grid>
                            )
                        })
                        :
                        <Grid item xs={12}>
                            <div style={{width: '100%'}}>

                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    encabezado && encabezado.map(function (data) {
                                                        return (<TableCell>{data}</TableCell>)
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>No hay datos</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                }
            </Grid>
        </div>
    )
}


export default LecturasPage