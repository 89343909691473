import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import useRolService from "../../services/administracion/useRolService";
import { addRow, findRow, updateRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import {
  allLetterAndSpace,
  allNumbers,
  isDUI,
  isEmail,
  isPhone,
  letterNumbersAndSpace,
  letterNumbersSpaceForAddress,
  validPassword,
} from "../../utils/validations";
import { mensajeCamposNoValidos } from "../../utils/messages";
import Session from "react-session-api";
import { useCookies } from "react-cookie";
import { getNowInSeconds } from "../../utils/time";
import useLoginService from "../../services/sesion/useLoginService";
import useTransporteService from "../../services/controlDeCalidad/useTransporteService";
import { checkGridRowIdIsValid } from "@material-ui/data-grid";

// import { getFilenameFromContentDisposition } from '../../utils/download'

const useTransportePage = () => {
  const {
    createTransporte,
    getAllTransporte,
    deleteTransporte,
    getTransporteById,
    updateTransporte,
  } = useTransporteService();

  const ruta = `/transporte/administrar`;
  const history = useHistory();
  useIsLoged();
  const { id } = useParams();
  //Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);
  //Estado para las filas de la tabla
  const [rows, setRows] = useState([]);

  //Estados para controlar los inputs
  //(errorInput y helperInput sirven para mostrar un error en el input)

  //Estado de cuantos registros se estan mostrando en la tabla
  const [registrosEnTabla, setRegistrosEnTabla] = useState(0);
  const [lastPagePagination, setLastPagePagination] = useState(0);

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  //Estados para dialogos confirmar actualizacion, creacion y eliminacion
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [transporteToDelete, setTransporteToDelete] = useState(null);

  //Estado para progress, el setLoading siempre debe ser llamado en funciones
  //asincronas para funcionar correctamente,aqui nos quedamos
  const [loading, setLoading] = useState(0);

  //Cookies
  const [cookies, setCookie, removeCookie] = useCookies(["token", "rol", "id"]);

  //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  //Estado que indica la pagina actua
  const [page, setPage] = useState(queryParams.get("page"));
  //Estado que indica el numero de paginas en total
  const [numPages, setNumPages] = useState(0);

  //Hooks del modulo
  const [placa, setPlaca] = useState("");
  const [errorPlaca, setErrorPlaca] = useState(false);
  const [helperPlaca, setHelperPlaca] = useState("");

  const [color, setColor] = useState("");
  const [errorColor, setErrorColor] = useState(false);
  const [helperColor, setHelperColor] = useState("");

  const [marca, setMarca] = useState("");
  const [errorMarca, setErrorMarca] = useState(false);
  const [helperMarca, setHelperMarca] = useState("");

  const [modelo, setModelo] = useState("");
  const [errorModelo, setErrorModelo] = useState(false);
  const [helperModelo, setHelperModelo] = useState("");

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  //Controlador cambio de pagina
  const handlePageChange = (event, value) => {
    setPage(value);
    history.push(`${window.location.pathname}?page=${value}`);
  };

  //Controladores alerta
  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  //Controladores mensajes confirmacion
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenDelete = (id) => {
    setTransporteToDelete(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const startLoading = () => {
    setLoading((loading) => ++loading);
  };

  const stopLoading = () => {
    setLoading((loading) => --loading);
  };

  //Funciones para controlar cambios en inputs
  const onChangePlaca = (text) => {
    setPlaca(text);
    if (!letterNumbersSpaceForAddress(text)) {
      setErrorPlaca(true);
      setHelperPlaca("La placa solo debe contener numeros y letras");
    } else {
      setErrorPlaca(false);
      setHelperPlaca("");
    }
  };

  const onChangeColor = (text) => {
    setColor(text);
    if (!letterNumbersSpaceForAddress(text)) {
      setErrorColor(true);
      setHelperColor("La placa solo debe contener numeros y letras");
    } else {
      setErrorColor(false);
      setHelperColor("");
    }
  };

  const onChangeMarca = (text) => {
    setMarca(text);
    if (!letterNumbersSpaceForAddress(text)) {
      setErrorMarca(true);
      setHelperMarca("La placa solo debe contener numeros y letras");
    } else {
      setErrorMarca(false);
      setHelperMarca("");
    }
  };

  const onChangeModelo = (text) => {
    setModelo(text);
    if (!letterNumbersSpaceForAddress(text)) {
      setErrorModelo(true);
      setHelperModelo("La placa solo debe contener numeros y letras");
    } else {
      setErrorModelo(false);
      setHelperModelo("");
    }
  };

  const editClick = (id) => {
    history.push(`${ruta}/${id}?page=${page}`);
  };

  const editCancel = () => {
    cancel();
    history.push(`${ruta}?page=${page}`);
    //setcleanCombobox(!cleanCombobox);
    cancel();
  };

  const cancel = () => {
    setPlaca("");
    setColor("");
    setMarca("");
    setModelo("");
  };

  const cancelEdit = () => {
    setPlaca("");
    setColor("");
    setMarca("");
    setModelo("");
    history.push(`${ruta}?page=1`);
  };

  const checkForErrors = () => {
    if (
      placa &&
      placa !== "" &&
      color &&
      color !== "" &&
      marca &&
      marca !== "" &&
      modelo &&
      modelo !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleGetTransportes = useCallback(async () => {
    startLoading();
    const transporte = await getAllTransporte();
    if (transporte.error) {
      setErrorMessage(transporte.error);
    } else {
      setRows([]);
      transporte.forEach(({ id, placa, color, marca, modelo }) => {
        const key = id;
        const cell = [placa, color, marca, modelo];
        addRow(key, cell, setRows);
      });
    }
    stopLoading();
  }, [getAllTransporte]);

  const getTransporte = useCallback(
    async (id) => {
      startLoading();
      const transporte = await getTransporteById(id);
      if (transporte.error) {
        handleOpenAlert("error", transporte.error);
      } else {
        const { placa, color, marca, modelo } = transporte;

        setPlaca(placa);
        setColor(color);
        setMarca(marca);
        setModelo(modelo);
      }
      stopLoading();
    },
    [getTransporteById]
  );

  const onUpdateCliente = async () => {
    setOpenEdit(false);
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const transporteAactualizar = {
      placa,
      color,
      marca,
      modelo,
      disponible: true,
    };
    startLoading();
    const result = await updateTransporte(id, transporteAactualizar);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { placa, color, marca, modelo } = result;
      const newCells = [placa, color, marca, modelo];
      updateRow(id, newCells, setRows);
      await handleGetTransportes();
      handleOpenAlert(
        "success",
        `El transporte con placa "${placa}" se actualizo correctamente.`
      );
    }
    cancelEdit();
    stopLoading();
  };

  const onCreateTransporte = async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const nuevoTransporte = {
      placa,
      color,
      marca,
      modelo,
      disponible: true,
    };

    startLoading();
    const result = await createTransporte(nuevoTransporte);
    if (result.error) {
      setErrorMessage(result.error);
      handleOpenAlert("error", result.error);
    } else {
      const { placa, color, marca, modelo, disponibilidad, id } = result;
      cancel();
      history.push(`${ruta}?page=${page}`);
      handleOpenAlert(
        "success",
        `El vehículo con placa "${placa}" se creo correctamente.`
      );
      await handleGetTransportes();
    }
    stopLoading();
  };

  const onDeleteTransporte = async () => {
    setOpenDelete(false);
    startLoading();
    const result = await deleteTransporte(transporteToDelete);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { placa } = result;
      //deleteRow(id, setRows)
      await handleGetTransportes();
      cancel();
      handleOpenAlert(
        "success",
        `El vehiculo con placa "${placa}" se elimino correctamente.`
      );
    }
    stopLoading();
  };

  useEffect(() => {
    const loadData = async () => {
      await handleGetTransportes();
    };

    loadData();
  }, [handleGetTransportes]);

  useEffect(() => {
    if (id) {
      cancel();
      getTransporte(id);
    }
    //Solo se ejecuta cuando cambia el valor de id o getUsuario
  }, [id, getTransporte]);
  //Objeto con los datos del custom hook que se usaran en la pagina
  const data = {
    alertOpen,
    alertSeverity,
    alertMessage,
    setAlertOpen,
    openEdit,
    openDelete,
    transporteToDelete,
    loading,
    numPages,
    page,
    placa,
    errorPlaca,
    helperPlaca,
    color,
    errorColor,
    helperColor,
    marca,
    errorMarca,
    helperMarca,
    modelo,
    errorModelo,
    helperModelo,
    id,
    rows,
    openDelete,
    transporteToDelete,
    setTransporteToDelete,
    openEdit,
  };

  //Objeto con las acciones que se usaran en la pagina
  const actions = {
    handlePageChange,
    handleOpenAlert,
    handleClickOpenEdit,
    handleCloseEdit,
    handleClickOpenDelete,
    handleCloseDelete,
    onChangePlaca,
    onChangeColor,
    onChangeMarca,
    onChangeModelo,
    cancel,
    cancelEdit,
    onCreateTransporte,
    onDeleteTransporte,
    findRow,
    editCancel,
    editClick,
    onUpdateCliente,
  };

  return { errorMessage, data, actions };
};

export default useTransportePage;
