import React from 'react'
import AppFrame from '../../components/AppFrame'
import FormHeader from '../../components/FormHeader'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import {RiAdminLine} from 'react-icons/ri'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import usePracticaPage from '../../hooks/administracion/usePracticaPage'
import Alert from '../../components/Alert'
import PermisoDenegado from '../../components/PermisoDenegado'
import useCheckPermiso from '../../hooks/utils/useCheckPermiso'
import Progress from '../../components/Progress/Progress'
import Pagination from '../../components/Pagination'
import Switch from '../../components/Switch'
import { FaTrashRestoreAlt } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    }
}));

const encabezado = ["Nombre de la practica"]

const PracticaPage = () => {
    const classes = useStyles()
    const {data, actions} = usePracticaPage()
    const {rows, nombre, errorNombre, helperNombre, id, openDelete, openEdit, practicaToDelete,
        alertOpen, alertMessage, alertSeverity, verDesactivados, page, numPages,
        loading} = data
    const {onDeletePractica, onCreatePractica, onUpdatePractica,
        editClick, onChangeNombre, cancel, editCancel, handleClickOpenDelete,
        handleClickOpenEdit, handleCloseDelete, handleCloseEdit, 
        findRow, setPracticaToDelete, setAlertOpen, handleDesactivadosChange, 
        handlePageChange} = actions

    return (
        <AppFrame loged = {true}>
            <Progress open = {loading>0}/>
            {
                useCheckPermiso(3) ?
                <Grid container direction = "row" justifyContent = "center">
                    <Grid item xs={12} sm= {7}>
                        <Paper className={classes.paper}>
                            <Alert open = {alertOpen} setOpen={setAlertOpen}
                                severity = {alertSeverity} 
                                message = {alertMessage}/>
                            <FormHeader title="Administracion"
                                subtitle="Practica">
                                <RiAdminLine/>
                            </FormHeader>
                            <Grid container direction="row" justifyContent="center"
                                spacing = {2}>
                                <Grid container item xs = {11}>
                                    <Grid className={classes.input} item xs = {12}>
                                        <Input type = "text" 
                                            label="Nombre de la practica" 
                                            placeholder="Ingrese el nombre de la practica"
                                            value = {nombre}
                                            onChange={(value) => 
                                                onChangeNombre(value)}
                                            error = {errorNombre}
                                            helper = {helperNombre}
                                            required={true}
                                            />
                                    </Grid>
                                    <Grid container item direction="row"
                                        justifyContent="center" spacing = {2}>
                                        <Grid className={classes.input} item 
                                            xs={6} sm= {3}>
                                            <Button
                                                func = {
                                                    id?
                                                    editCancel
                                                    :
                                                    cancel
                                                }
                                                variant = "outlined"
                                                text="CANCELAR"/>
                                        </Grid>
                                        <Grid className={classes.input} item
                                            xs={6} sm= {3}>
                                            <Button
                                                variant = "contained"
                                                text={id?"EDITAR":"AGREGAR"}
                                                func = {id?
                                                    handleClickOpenEdit
                                                    :
                                                    onCreatePractica}
                                                />
                                            <Dialog open={openEdit} 
                                                handleClose = {handleCloseEdit} 
                                                handleAccept = {onUpdatePractica} 
                                                title = {"¿Desea actualizar el registro?"} 
                                                content = {`El nombre de la practica se actualizara por "${nombre}".`}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction = "row" 
                                        justifyContent = "flex-end" item xs={12}>
                                        <Grid item>
                                            <Switch checked={verDesactivados} 
                                                handleChange = {handleDesactivadosChange} 
                                                label = "Registros desactivados"
                                                />
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.table} item xs={12}>
                                        <DataTable headers={encabezado} 
                                            rows={rows}
                                            editFnc={ !verDesactivados ?
                                                (key)=>
                                                editClick(key)
                                                :
                                                undefined
                                            }
                                            deleteFnc={ !verDesactivados ?
                                                (key)=>
                                                handleClickOpenDelete(key)
                                                : 
                                                undefined
                                            }
                                            customBtn = {
                                                verDesactivados ?
                                                [
                                                    {
                                                        func: (key)=>
                                                            handleClickOpenDelete(key),
                                                        icon: FaTrashRestoreAlt,
                                                        tooltip: "Reactivar"
                                                    }
                                                ]
                                                : 
                                                undefined   
                                            }
                                            />
                                    </Grid>
                                    <Grid item xs = {12}>
                                        <Pagination page={Number(page)}
                                            numPages={Number(numPages)}
                                            onChangePage = {handlePageChange}
                                            />
                                    </Grid>
                                    <Dialog open={openDelete} 
                                        handleClose = {handleCloseDelete} 
                                        handleAccept = {onDeletePractica} 
                                        title = {`Desea ${verDesactivados ? "activar" : "eliminar"} el registro?`} 
                                        content = { 
                                            findRow(practicaToDelete, rows) ?
                                            `La practica con nombre "${ findRow(practicaToDelete, rows).cells[0] }" sera ${ verDesactivados ? "activada" : "desactivada"} en el sistema.`
                                            :
                                            ""
                                            }
                                        transitionFunc = {() => 
                                            setPracticaToDelete(null)}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                :
                <PermisoDenegado/>
            }
        </AppFrame>
    )
}

export default PracticaPage