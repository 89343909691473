import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { makeStyles, Paper, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    page: {
        width: '100%',
    }
}));

const PdfViewer = ({file, pageNumber, onDocumentLoadSuccess, scale}) => {
    const classes = useStyles()
    return (
        <Grid container justifyContent = 'center'>
            <Document
                loading="Cargando archivo PDF" 
                noData = "No se ha cargado un archivo PDF"
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                >
                <Paper>
                    <Page scale = {scale} error = "No se pudo cargar el PDF" className = {classes.page} pageNumber={pageNumber} />
                </Paper>
            </Document>
        </Grid>
    )
}

PdfViewer.propTypes = {
    pageNumber: PropTypes.number.isRequired,
    onDocumentLoadSuccess: PropTypes.func.isRequired,
    scale: PropTypes.number,
}

export default PdfViewer
