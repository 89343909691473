import React, {useState} from "react"
import AppFrame from "../../components/AppFrame"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import {makeStyles} from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import "react-day-picker/lib/style.css"
import TabPanel from "../../components/TabPanel"
import AsignacionDosimetrosPage from "./AsignacionDosimetrosPage";
import RecibidosPage from "./RecibidosPage";
import LecturasPage from "./Lecturas/LecturasPage";
import ReportesPage from "./ReportesPage";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: "2em",
        paddingBottom: "2em",
    },
    input: {
        marginTop: ".5em",
        //marginBottom: ".5em",
    },
    root: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
}))

const DosimetriaControlPage = (props) => {
    const classes = useStyles()

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <AppFrame loged={false}>
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Tabs value={value} onChange={(event, newValue) => handleChange(event, newValue)} indicatorColor="primary"
                              textColor="primary" centered>
                            <Tab label="Asignación"/>
                            <Tab label="Recibidos"/>
                            <Tab label="Lecturas"/>
                            <Tab label="Reportes"/>
                        </Tabs>
                        <Grid item xs={12}>
                            <TabPanel value={value} index={0}>
                                <AsignacionDosimetrosPage/>
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={value} index={1}>
                                <RecibidosPage/>
                            </TabPanel>
                        </Grid>
                        <TabPanel value={value} index={2}>
                            <LecturasPage />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <ReportesPage />
                        </TabPanel>
                    </Paper>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default DosimetriaControlPage
