import { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";
import { addRow, findRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import { BsFillChatDotsFill, BsFillChatFill } from "react-icons/bs";
import { MdSearch } from "react-icons/md";
import useUsuarioService from "../../services/administracion/useUsuarioService";
import useSolicitudesRecibidasCCService from "../../services/solicitudes_recibidas/solicitudesRecibidasService";
import useControlAvanceCCService from "../../services/solicitudes_recibidas/controlAvanceService";
import useCCPlanificacionService from "../../services/controlDeCalidad/useActividadesServiceCC";
import usePasoSolicitudesRecibidasService from "../../services/solicitudes_recibidas/pasoService";
import { mensajeCamposNoValidos } from "../../utils/messages";
import useEstadoSolicitudesRecibidasService from "../../services/solicitudes_recibidas/estadoService";
import useClienteServiceCC from "../../services/controlDeCalidad/useClienteServiceCC";
import useHistorialDeReportesCCService from "../../services/historialDeReportes/clienteHistorialService";
import useActividadDeReportesCCService from "../../services/historialDeReportes/actividadHistorialService";

const useHistorialActividadCCPage = () => {
  const {
    actividadesHistorialCC,
    uploadArchivoToHistorialActividad,
    getArchivoToHistorialActividad,
    descargarArchivosHistorialActividad,
    deleteArchivosHistorialActividad,
  } = useActividadDeReportesCCService();

  const { getControlAvancePorIdCC, createControlAvance } =
    useControlAvanceCCService();

  const { getTiposYcantidadesDeActividadById } = useCCPlanificacionService();

  const { getAllPasos, getPasoById } = usePasoSolicitudesRecibidasService();

  const { getAllEstados } = useEstadoSolicitudesRecibidasService();

  const { getClientesWithoutPagination } = useClienteServiceCC();

  const history = useHistory();
  const { id } = useParams();

  const [idInstitucionH, setIdInstitucionH] = useState("");

  useIsLoged();
  const { getMe } = useUsuarioService();
  const [error, setError] = useState(null);
  const [fecha, setFecha] = useState("");
  const [estado, setEstado] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [value, setValue] = useState("Seleccione un rango de fecha");
  const [rows, setRows] = useState([]);

  const [queryFecha, setQueryFecha] = useState(null);
  const [queryEstado, setQueryEstado] = useState(null);
  const [queryPaso, setQueryPaso] = useState(null);
  const [archivo, setArchivo] = useState(null);
  const [listArchivos, setListArchivos] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [updateTablaArchivosHistorial, setUpdateTablaArchivosHistorial] =
    useState(false);

  //Estado para ProgressBar para subir/descargar archivos
  const [progress, setProgress] = useState(-1);
  // Para el rol del usuario
  const [idRol, setIdRol] = useState("");
  //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  //Estado que indica la pagina actua
  const [page, setPage] = useState(queryParams.get("page"));
  //Estado que indica el numero de paginas en total
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [archivoToDelete, setArchivoToDelete] = useState(false);

  const [first_page_url, setFirst_page_url] = useState("");
  const [last_page_url, setLast_page_url] = useState("");
  const [next_page_url, setNext_page_url] = useState("");
  const [prev_page_url, setPrev_page_url] = useState("");

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  //Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [tipos_control_avance, setTipos_control_avance] = useState([]);
  const [fecha_solicitud, setFecha_solicitud] = useState("");
  const [fecha_visita_tecnica, setFecha_visita_tecnica] = useState("");
  const [
    fecha_entrega_de_informe_tecnico,
    setFecha_entrega_de_informe_tecnico,
  ] = useState("");
  const [fecha_entrega_certificado, setFecha_entrega_certificado] =
    useState("");
  const [fecha_notificacion, setFecha_notificacion] = useState("");
  const [fecha_entrega, setfecha_entrega] = useState("");
  const [selectedPaso, setSelectedPasos] = useState(null);
  const [selectedPasoToFilter, setSelectedPasosToFilter] = useState(null);
  const [selectedEstado, setSelectedPaso] = useState(null);
  const [selectedInstituciones, setSelectedInstituciones] = useState(null);
  const [actividad_id, setActividad_id] = useState(0);
  const [anio, setAnio] = useState("");
  const [errorAnio, setErrorAnio] = useState("");
  const [helperAnio, setHelperAnio] = useState("");
  const [isSearching, setIsSearching] = useState(true);

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  const checkForErrors = () => {
    return !(
      selectedPaso ||
      (typeof selectedPaso === "number" && selectedPaso > 0)
    );
  };

  const recortarDespuesDe3000 = (url) => {
    const indice3000 = url.indexOf("3000") + "3000".length;
    const resultado = url.substring(indice3000);
    return resultado;
  };

  const handleVolver = () => {
    // Volver a la página anterior
    //window.history.back();
    // Luego, cuando necesites obtener la URL anterior
    const previousURL = sessionStorage.getItem("previousURL");

    const urlToRedirect = recortarDespuesDe3000(previousURL);

    // Construir la URL de redirección
    const redirectUrl = urlToRedirect;

    // Redirigir a la nueva URL
    history.push(redirectUrl);
  };

  const descargarReporteHistorialActividad = useCallback(
    async (id) => {
      setLoading(true);

      await descargarArchivosHistorialActividad(id);

      setLoading(false);
    },
    [descargarArchivosHistorialActividad]
  );

  const onDeleteContrato = async () => {
    setOpenDelete(false);
    startLoading();
    const result = await deleteArchivosHistorialActividad(archivoToDelete);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { nombre } = result;
      //deleteRow(id, setRows)
      // handleGetContratos();
      await getHistorialDeActividadesDialog(actividad_id);
      //await getArchivoToHistorialActividad();
      handleOpenAlert(
        "success",
        `El archivo se eliminó correctamente.`
      );
    }
    stopLoading();
  };

  const cancel = () => {
    setFecha_solicitud("");
    setFecha_visita_tecnica("");
    setFecha_entrega_de_informe_tecnico("");
    setFecha_entrega_certificado("");
    setFecha_notificacion("");
    setfecha_entrega("");
    setTipos_control_avance([]);
    //setActividad_id(null);
  };
  const buscarInstitucion = () => {
    setIsSearching(!isSearching);
  };
  const cancelarBusqueda = () => {
    const load = async () => await getHistorialDeClientes(1, "");
    load();
    setAnio("");
  };

  // Para indicar cuando se está consumiendo la API
  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const onChangeAnio = (text) => {
    setAnio(text);
    // if (!letterNumbersSpaceForAddress(text)) {
    //   setErrorComentario(true);
    //   setHelperComentario(
    //     "La dirección solo debe contener numeros,letras y los caracteres: # - / ,  . ª ° "
    //   );
    // } else {
    //   setErrorComentario(false);
    //   setHelperComentario("");
    // }
  };

  const handleOnProgress = (loaded, total) => {
    const progress = (loaded / total) * 100;
    setProgress(progress);
  };

  //Controlador cambio de pagina
  const handlePageChange = (event, value) => {
    setPage(value);
    //getHistorialDeClientes();
    history.push(`${window.location.pathname}?page=${value}`);
  };

  const handleClickOpenDialog = (id) => {
    setProgress(-1);
    setArchivo(null);
    setActividad_id(id);
    getControlAvanceNuevoByIdCC(id);
    getHistorialDeActividadesDialog(id);
    //handleClickOpenDialog(actividad_id);
    setOpenDialog(true);
  };
  // useEffect(() => {
  //   console.log({
  //     msg: "Cambio el actividad_id",
  //     actividad_id,
  //   });
  // }, [actividad_id]);
  const handleClickCloseDialog = () => {
    setProgress(-1);
    setArchivo(null);
    setOpenDialog(false);
    handleChangePaso(null);
    cancel();
  };

  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleClickOpenDelete = (id) => {
    // setContratoToDelete(id)
    setArchivoToDelete(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Funcion que almacena el id del paso seleccionado
  const handleChangePaso = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedPasos(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedPasos(value);
    }
  };

  // Funcion que almacena el id del paso para filtro seleccionado
  const handleChangePasoToFilter = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedPasosToFilter(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedPasosToFilter(value);
    }
  };

  // Funcion que almacena el id del estado seleccionado
  const handleChangeEstado = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedPaso(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedPaso(value);
    }
  };

  const handleChangeInstituciones = (value) => {
    if (value == null) {
      // setSelectedValueArea(null)
      setSelectedInstituciones(null);
    } else {
      // setSelectedValueArea(value.value)
      setSelectedInstituciones(value);
    }
  };

  const handleRedirect = (id) => {
    // Supongamos que `id` es el número que deseas agregar a la URL

    // Construir la URL de redirección
    const redirectUrl = `/control_de_calidad/reportes/actividad_clientes/${id}`;

    // Redirigir a la nueva URL
    history.push(redirectUrl);
  };

  const handleOnChangeHistorialActividad = (file) => {
    setArchivo(file[0]);
  };

  const onUpdateControlAvanceCC = useCallback(async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }
    //createControlAvance
    const controlAvance = {
      actividad_id,
      paso_id: selectedPaso.value,
      fecha_visita_tecnica: fecha_visita_tecnica || null,
      fecha_entrega_de_informe_tecnico:
        fecha_entrega_de_informe_tecnico || null,
      fecha_entrega_certificado: fecha_entrega_certificado || null,
      fecha_notificacion: fecha_notificacion || null,
      fecha_entrega: fecha_entrega || null,
      tipos_control_avance: tipos_control_avance.map((tipo) => ({
        tipo_equipo_id: tipo.tipo_equipo_id,
        cantidad_reporte: tipo.cantidad_reporte || 0,
        comentario: tipo.comentario || "",
      })),
    };
    startLoading();
    const result = await createControlAvance(controlAvance);
    if (result.error) {
      setErrorMessage(result.error);
      // setErrorMessage(result.error.data.message);
      handleOpenAlert("error", result.error);
    } else {
      const { control_avance } = result;
      handleOpenAlert(
        "success",
        `El control de avance se actualizó correctamente.`
      );
      handleClickCloseDialog();
    }
    stopLoading();
  });

  const getControlAvanceNuevoByIdCC = useCallback(async (idActividad) => {
    const response = await getTiposYcantidadesDeActividadById(idActividad);
    if (response.error) {
      handleOpenAlert("error", response.error);
      setError(response.error);
      setLoading(false);
      return;
    }
    if (response.tieneControl) {
      //SI la actividad ya esta asociado a control de avance pasara al sigiuente metodo.
      getControlAvanceByIdCC(response.idControlAvance);
      return;
    } else {
      setTipos_control_avance(response.tipos_control_avance);

      setFecha_solicitud(response.fecha_solicitud);
      setFecha_visita_tecnica("");
      setFecha_entrega_de_informe_tecnico("");
      setFecha_entrega_certificado("");
      setFecha_notificacion("");
      setfecha_entrega("");
    }
  });

  const getControlAvanceByIdCC = useCallback(async (idControlAvance) => {
    const response = await getControlAvancePorIdCC(idControlAvance);
    if (response.error) {
      handleOpenAlert("error", response.error);
      setError(response.error);
      setLoading(false);
      return;
    } else {
      setTipos_control_avance(response.tipos_control_avance);

      //setSelectedPaso({label:response.paso_control_calidad.nombre,value:response.paso_control_calidad.id})
      setFecha_solicitud(response.fecha_solicitud);
      setFecha_visita_tecnica(response.fecha_visita_tecnica);
      setFecha_entrega_de_informe_tecnico(
        response.fecha_entrega_de_informe_tecnico
      );
      setFecha_entrega_certificado(response.fecha_entrega_certificado);
      setFecha_notificacion(response.fecha_notificacion);
      setfecha_entrega(response.fecha_entrega);
      //setSelectedPaso({label:response.paso_control_calidad.nombre,value:response.paso_control_calidad.id})
      handleChangePaso({
        label: response.paso_control_calidad.nombre,
        value: response.paso_control_calidad.id,
      });
    }
  });

  const onUploadHistorialActivityFile = async () => {
    // if(checkForErrors()){
    //     handleOpenAlert('warning', "Debe elegir un archivo para subir.")
    //     return
    // }
    if (archivo === null) {
      handleOpenAlert("warning", "Debe elegir un archivo para subir.");
      return;
    }
    let formData = new FormData();
    formData.append("archivo", archivo);

    // console.log(archivo)
    const result = await uploadArchivoToHistorialActividad(
      formData,
      handleOnProgress,
      actividad_id
    );
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { nombre } = result;

      // await getHistorialDeActividadesDialog(id);
      await getHistorialDeActividadesDialog(actividad_id);
      setProgress(-1);

      // handleClickCloseDialog();
      // handleClickOpenDelete();
      //handleGetContratos();
      handleOpenAlert("success", `El archivo  se creo correctamente.`);
      setArchivo(null);
    }
    // cancel();
  };

  const getHistorialDeClientes = useCallback(
    async (pagination) => {
      setLoading(true);

      const response = await actividadesHistorialCC(id, pagination);
      if (response.error) {
        handleOpenAlert("error", response.error);
        setError(response.error);
        setRows([]);
        setNumPages(1);
        setLoading(false);
        return;
      } else {
        setRows([]);
        setNumPages(response.last_page);

        setFirst_page_url(response.first_page_url);
        setLast_page_url(response.last_page_url);
        setNext_page_url(response.next_page_url);
        setPrev_page_url(response.prev_page_url);

        const { data } = response;
        data?.forEach(
          ({
            actividad_id,
            control_avance_id,
            nombre,
            fecha_entrega,
            estado,
            cliente,
            updated_at,
          }) => {
            const key = actividad_id;
            const nombreInstitucion = nombre;
            const update = updated_at;
            const cell = [nombreInstitucion, update];
            addRow(key, cell, setRows);
          }
        );
      }
      setLoading(false);
    },
    [actividadesHistorialCC]
  );
  const getHistorialDeActividadesDialog = useCallback(
    async (id_act) => {
      setLoading(true);

      const response = await getArchivoToHistorialActividad(id_act);
      if (response.error) {
        handleOpenAlert("error", response.error);
        setError(response.error);
        setRows([]);
        setNumPages(1);
        setLoading(false);
        return;
      } else {
        setListArchivos(response.data);
        console.log({
          id: "arg",
          msg: "Fui llamado yo el metood getHistorialDeActividadesDialog",
        });
      }
      setLoading(false);
    },
    [getArchivoToHistorialActividad]
  );

  // Funcion que filtra los datos de acuerdo a un valor escrito
  const dataFiltrada = useCallback(async (data, value) => {
    // Devuelve los datos filtrados por el nombre
    const results = data.filter((resp) =>
      resp.nombre.toLowerCase().includes(value)
    );
    let options = [];
    results.forEach(({ id, nombre }) => {
      const value = id;
      const label = nombre;
      const resultado = {
        label,
        value: value.toString(),
      };
      options = [...options, resultado];
    });
    return options;
  }, []);

  const loadPasos = useCallback(
    async (inputValue) => {
      const response = await getAllPasos();
      if (inputValue) {
        // Verifica si hay un valor ingresado
        return dataFiltrada(response, inputValue);
      } else {
        let options = [];
        response?.forEach(({ id, name }) => {
          const value = id;
          const label = name;
          const resultado = {
            label,
            value: value.toString(),
          };
          options = [...options, resultado];
        });
        // console.log(options)
        return options;
      }
    },
    [getAllPasos]
  );

  const loadEstados = useCallback(
    async (inputValue) => {
      const response = await getAllEstados();
      if (inputValue) {
        // Verifica si hay un valor ingresado
        return dataFiltrada(response, inputValue);
      } else {
        let options = [];
        response?.forEach(({ id, name }) => {
          const value = id;
          const label = name;
          const resultado = {
            label,
            value: value.toString(),
          };
          options = [...options, resultado];
        });
        // console.log(options)
        return options;
      }
    },
    [getAllPasos]
  );

  const loadPasosToFilter = useCallback(
    async (inputValue) => {
      const response = await getAllPasos();
      //   const response = [{ id: 1, nombre: "juancho" }]
      if (inputValue) {
        // Verifica si hay un valor ingresado
        return dataFiltrada(response, inputValue);
      } else {
        let options = [];
        response?.forEach(({ id, name }) => {
          const value = id;
          const label = name;
          const resultado = {
            label,
            value: value.toString(),
          };
          options = [...options, resultado];
        });
        // console.log(options)
        return options;
      }
    },
    [getAllPasos]
  );

  const loadInstituciones = useCallback(
    async (inputValue) => {
      //   const response = await getAllTransporte();
      const { data: response } = await getClientesWithoutPagination();

      if (inputValue) {
        // Verifica si hay un valor ingresado
        return dataFiltrada(response, inputValue);
      } else {
        let options = [];
        response?.forEach(({ id, nombre }) => {
          const value = id;
          const label = nombre;
          const resultado = {
            label,
            value: value.toString(),
          };
          options = [...options, resultado];
        });
        // console.log(options)
        return options;
      }
    },
    [getClientesWithoutPagination]
  );

  // useEffect(() => {
  //   console.log({
  //     first_page_url,
  //     last_page_url,
  //     next_page_url,
  //     prev_page_url,
  //   });
  // }, [first_page_url, last_page_url, next_page_url, prev_page_url]);

  //Carga inical al cargar el componente
  useEffect(() => {
    const load = async () => await getHistorialDeClientes(idInstitucionH);
    load();
  }, [idInstitucionH, getHistorialDeClientes]);

  //   //Cargar cada vez que un filtro cambie
  //   useEffect(() => {
  //     const load = async () =>
  //       await getHistorialDeClientes(
  //         1,
  //         anio,
  //         selectedEstado?.value || false,
  //         selectedPasoToFilter?.value || false,
  //         selectedInstituciones?.value || false
  //       );
  //     if (!anio || anio.length > 3) {
  //       load();
  //     }
  //   }, [selectedEstado, selectedPasoToFilter, selectedInstituciones, anio]);

  //Cargar cada vez que un filtro cambie
  useEffect(() => {
    const load = async () => await getHistorialDeClientes(1, anio);
    if (!anio || anio.length > 0) {
      load();
    }
  }, [isSearching]);

  useEffect(() => {
    const load = async () =>
      await getHistorialDeClientes(
        page,
        anio,
        selectedEstado?.value || false,
        selectedPasoToFilter?.value || false,
        selectedInstituciones?.value || false
      );
    if (!anio || anio.length > 3) {
      load();
    }
  }, [page]);

  useEffect(() => {
    // Actualiza el estado con el valor de id
    setIdInstitucionH(id);
    // Puedes realizar otras acciones con el valor de id si es necesario
  }, [id]);

  useEffect(() => {
    // Actualiza el estado con el valor de id
    setIdInstitucionH(id);
  }, []);

  useEffect(() => {
    console.log({
      id: "listarchivos",
      listArchivos,
    });
  }, [listArchivos]);

  const data = {
    alertMessage,
    alertOpen,
    alertSeverity,
    anio,
    fecha_entrega_certificado,
    fecha_entrega_de_informe_tecnico,
    fecha_entrega,
    fecha_notificacion,
    fecha_solicitud,
    fecha_visita_tecnica,
    idRol,
    loading,
    MdSearch,
    numPages,
    openDialog,
    page,
    rows,
    selectedEstado,
    selectedInstituciones,
    selectedPaso,
    selectedPasoToFilter,
    setErrorAnio,
    setFecha_entrega_certificado,
    setFecha_entrega_de_informe_tecnico,
    setfecha_entrega,
    setFecha_notificacion,
    setFecha_solicitud,
    setFecha_visita_tecnica,
    setHelperAnio,
    setTipos_control_avance,
    tipos_control_avance,
    value,
    progress,
    listArchivos,
    openDelete,
    archivoToDelete,
    updateTablaArchivosHistorial,
  };
  const actions = {
    handlePageChange,
    handleOpenAlert,
    getHistorialDeClientes,
    handleClickOpenDialog,

    handleClickCloseDialog,
    onUpdateControlAvanceCC,
    loadPasos,
    handleChangePaso,
    setAlertOpen,
    loadEstados,
    handleChangeEstado,
    loadPasosToFilter,
    handleChangePasoToFilter,
    loadInstituciones,
    handleChangeInstituciones,
    onChangeAnio,
    buscarInstitucion,
    cancelarBusqueda,
    handleVolver,
    setArchivo,
    handleOnChangeHistorialActividad,
    onUploadHistorialActivityFile,
    descargarReporteHistorialActividad,
    onDeleteContrato,
    handleCloseDelete,
    handleClickOpenDelete,
  };

  return { data, actions };
};

export default useHistorialActividadCCPage;
