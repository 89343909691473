import AppFrame from "../../components/AppFrame";
import { Link as RouterLink } from "react-router-dom";
import Button from "../../components/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../components/DataTable";
import Alert from "../../components/Alert/Alert";
import Typography from "@material-ui/core/Typography";
import Progress from "../../components/Progress/Progress";
import DatePickerRange from "../../components/DatePickerRange/DatePickerRange";
import Pagination from "../../components/Pagination";
import useSolicitudesPage from "../../hooks/cotizacion/useSolicitudesPage";
import useSolicitudesRecibidasCCPage from "../../hooks/solicitudes_recibidas/useSolicitudesRecibidas";
import Dialog from "../../components/Dialog/Dialog";
import ReactSelect from "../../components/ReactSelect";
import Input from "../../components/Input/Input";
import DataTableReportes from "./DataTableReportes";
import { sectionMarginDefaults } from "docx";
import DateInput from "../../components/Date/DateInput";
import useReporteEficienciaGlobalPage from "../../hooks/controlDeCalidad/useReporteEficienciaGlobalPage";
import GuardarControlAvanceCC from "../solicitudes_recibidas/guardarControlAvance";
import EficienciaGlobalControlAvanceCC from "./EdicionComentarioControlAvance";
import FormHeader from "../../components/FormHeader";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
    paddingLeft: "8em",
    paddingRight: "8em"
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
    marginLeft: ".9em",
    marginRight: ".9em",
  },
}));

const encabezado = ["Institucion", "Estado", "Paso"];

const ReporteEficienciaGlobalPage = () => {
  // const headers = [
  //   "Tiempo promedio de atencion de solicitudes (dias habiles)",
  //   "20",
  //   "9",
  //   "10",
  //   "15",
  //   "8",
  // ];
  // const secondRowHeaders = [
  //   "Cliente",
  //   "Fecha de solicitud",
  //   "Fecha de entrega de informe tecnico",
  //   "Fecha de entrega certificada",
  //   "Fecha de notificacion",
  //   "Fecha de entrega",
  // ];
  // const rows = [
  //   {
  //     key: "1",
  //     cells: ["Nombre cliente 1", "$5000", "$3000", "$2000", "$2000", "$2000"],
  //   },
  //   {
  //     key: "2",
  //     cells: ["Nombre cliente 2", "$6000", "$3500", "$2500", "$2500", "$2500"],
  //   },
  //   {
  //     key: "3",
  //     cells: ["Nombre cliente 3", "$5500", "$3200", "$2300", "$2300", "$2300"],
  //   },
  //   {
  //     key: "4",
  //     cells: ["Nombre cliente 4", "$5500", "$3200", "$2300", "$2300", "$2300"],
  //   },
  // ];

  // Define colors for each column
  const columnColors = ["#FFFFFF", "#F0D7F5", "#F0D7F5", "#F0D7F5","#F0D7F5","#F0D7F5","#F0D7F5"];

  const classes = useStyles();
  const { data, actions } = useReporteEficienciaGlobalPage();
  const {
    rows,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    numPages,
    page,
    selectedEstado,
    selectedPasoToFilter,
    selectedInstituciones,
    anio,
    secondRowHeaders,
    firstHeaders,
    setFechaFin,
    setFechaInicio,
    fecha_inicio,
    fecha_fin,
    institucion,
    nombre_institucion,
    idInstitucionSeleccionadaEnTabla,
    setIdInstitucionSeleccionadaEnTabla,
    openDialog,
  } = data;
  const {
    setAlertOpen,
    handlePageChange,
    onChangeInstitucion,
    handleFiltro,
    cancel,
    handleClickCloseDialog,
    onUpdateControlAvanceCC,
  } = actions;

  return (
    <AppFrame loged={true}>
      <Progress open={loading} setOpen={setAlertOpen} />
      <Grid container spacing={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} md={10}>
            <Paper className={classes.paper}>
              <Alert
                open={alertOpen}
                setOpen={setAlertOpen}
                severity={alertSeverity}
                message={alertMessage}
              />

              {/* Titulo y Boton*/}
              <Grid
                className={classes.input}
                container
                direction="row"
                alignItems="center"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  item
                  xs={20}
                >
                  <FormHeader
              title="Reporte de Eficiencia Global"
            >
            </FormHeader>
                  {/* <Typography className={classes.subtitulo} variant="h5">
                    Eficiencia Global
                  </Typography> */}
                </Grid>
              </Grid>

              {/*Codigo para los filtros*/}

              <Grid container item xs={12} justify="center">
              {/* Fecha inicio */}
              <Grid item xs={6} md={3}>
                <DateInput
                  fecha={fecha_inicio}
                  setFecha={setFechaInicio}
                  isEditable={true}
                  label="Fecha de inicio"
                />
              </Grid>

              {/* Fecha fin */}
              <Grid item xs={6} md={3}>
                <DateInput
                  fecha={fecha_fin}
                  setFecha={setFechaFin}
                  isEditable={true}
                  label="Fecha de fin"
                />
              </Grid>
                </Grid>

              {/* Instituciones */}
              <Grid item xs={12}>
                <Input
                  type="text"
                  label="Institucion"
                  placeholder="Ingrese el nombre de la institucion"
                  value={nombre_institucion}
                  onChange={onChangeInstitucion}
                  required={true}
                />
              </Grid>

             <Grid container item xs={12} justify="center" spacing={2}>
              {/* Botón Cancelar */}
               <Grid item xs={6} md={3}>
                 <Button func={cancel} variant="outlined" text="CANCELAR" />
                </Grid>

               {/* Botón Filtrar */}
                <Grid item xs={6} md={3}>
                  <Button func={handleFiltro} variant="outlined" text="FILTRAR" />
               </Grid>
                  </Grid>

              {/*Codigo para la Tabla*/}
              <Grid className={classes.input} item xs={12}>
                <DataTableReportes
                  headers={firstHeaders}
                  secondRowHeaders={secondRowHeaders}
                  rows={rows}
                  columnColors={columnColors}
                  secondRowHeight={325}
                  //setIdTableSelected={setIdInstitucionSeleccionadaEnTabla}
                />
              </Grid>
              <Pagination
                page={Number(page)}
                numPages={Number(numPages)}
                onChangePage={handlePageChange}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {/* MODAL PARA GUARDAR */}
      <Dialog
        open={openDialog}
        handleClose={handleClickCloseDialog}
        handleAccept={onUpdateControlAvanceCC}
        title={"Guardar control de avance"}
        content={<EficienciaGlobalControlAvanceCC data={data} actions={actions} />}
      />
    </AppFrame>
  );
};

export default ReporteEficienciaGlobalPage;