import axios from 'axios';
import url from "../utils/url";
import headers from '../utils/headers';
import useErrorHandler from '../utils/useErrorHandler';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const usuarioUrl = `${url}users`;

const useUserProfileService = () => {
    const history = useHistory();
    const { errorHandler } = useErrorHandler();

    const createUsuario = useCallback(
        async (usuario) => {
            try {
                const response = await axios.post(usuarioUrl, usuario, 
                    {
                        headers: {'Content-Type': 'multipart/form-data', ...headers()}
                    }
                );
                const { data } = response;
                return data;
            } catch (error) {
                return errorHandler(error);
            }
        },
        [errorHandler],
    );
    
    const getUsuarioById = useCallback(
        async (usuario) => {
            const requestUrl = `${usuarioUrl}/${usuario}`;
            try {
                const response = await axios.get(requestUrl, { headers: headers() });
                const { data } = response;
                return data;
            } catch (error) {
                return errorHandler(error);
            }
        },
        [errorHandler],
    );

    const getArchivoUsuario = useCallback(
        async (usuario, handleOnDownloadProgress) => {
            const requestUrl = `${usuarioUrl}/file/${usuario}`;
            try {
                const response = await axios.get(requestUrl, 
                    {
                        headers: { 'Content-Disposition': 'attachment', ...headers() },
                        responseType: 'blob',
                        // onDownloadProgress: (progressEvent) => handleOnDownloadProgress(progressEvent.loaded, progressEvent.total)
                    }
                );
                return response;
            } catch (error) {
                return errorHandler(error);
            }
        },
        [errorHandler],
    );
    
    const getUsuarios = useCallback(
        async (page) => {
            const requestUrl = `${usuarioUrl}?page=${page}`;
            try {
                const response = await axios.get(requestUrl, { headers: headers() });
                const { data } = response;
                return data;
            } catch (error) {
                return errorHandler(error);
            }
        },
        [errorHandler],
    );
    
    const getMe = useCallback(
        async () => {
            const requestUrl = `${usuarioUrl}/me`;
            try {
                const response = await axios.get(requestUrl, { headers: headers() });
                const { data } = response;
                return data;
            } catch (error) {
                return errorHandler(error);
            }
        },
        [errorHandler],
    );
    
    const updateUsuario = useCallback(
        async (usuario, cambios) => {
            const requestUrl = `${usuarioUrl}/${usuario}`;
            try {
                const response = await axios.post(requestUrl, cambios,
                    {
                        headers: { 'Content-Type': 'multipart/form-data', ...headers() }
                    }
                );
                const { data } = response;
                // Redireccionar a otra página después de la actualización exitosa
                history.push('./'); // Cambia './' por la ruta a la que quieres redireccionar
                return data;
            } catch (error) {
                // Redireccionar a otra página después de la actualización exitosa
                history.push('./'); // Cambia './' por la ruta a la que quieres redireccionar
                return errorHandler(error);
            }
        },
        [errorHandler, history],
    );
    
    const deleteUsuario = useCallback(
        async (usuario) => {
            const requestUrl = `${usuarioUrl}/${usuario}`;
            try {
                const response = await axios.delete(requestUrl, { headers: headers() });
                const { data } = response;
                return data;
            } catch (error) {
                return errorHandler(error);
            }
        },
        [errorHandler],
    );
      //actualizar contraseña
    const updatePassword = useCallback(
        async (usuario, contrasenia1, contrasenia2) => {
            const requestUrl = `${usuarioUrl}/${usuario}/password`;
            try {
                const response = await axios.post(requestUrl, { contrasenia1, contrasenia1_confirmation: contrasenia2 },
                    {
                        headers: { 'Content-Type': 'application/json', ...headers() }
                    }
                );
                const { data } = response;
                return data;
            } catch (error) {
                return errorHandler(error);
            }
        },
        [errorHandler],
    );

    return { createUsuario, getUsuarioById, getArchivoUsuario, getUsuarios, getMe, updateUsuario, deleteUsuario, updatePassword };
};

export default useUserProfileService;