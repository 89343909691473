import React from 'react'
import AppFrame from './../../components/AppFrame'
import FormHeader from './../../components/FormHeader'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {Link as RouterLink} from 'react-router-dom'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Progress from '../../components/Progress/Progress'
import useResumenPage from '../../hooks/inscripcion/useResumenPage'
import { PrevisualizacionEtapa2 } from './EvaluacionEtapa2Page'
import { StepperInscripcion } from './DatosDelegadoPage'
import Alert from '../../components/Alert'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    table: {
        marginTop: '2em'
    }
}))

const ResumenPage = props => {
    const classes = useStyles()

    const { data, actions } = useResumenPage()

    const {
        loading, 
        alertOpen,
        alertSeverity,
        alertMessage
    } = data

    const {
        handleEnviarSolicitud,
        setAlertOpen
    } = actions

    return (
        <AppFrame>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm= {8}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Evaluacion de la solicitud de cliente">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {11}>
                                <PrevisualizacionEtapa2 data = {data} actions = {actions}/>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container direction="row"
                        justifyContent="center" spacing = {2}>
                        <Grid className={classes.input} item 
                            xs={6} sm= {3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/toe'}
                                variant = "outlined"
                                text="ANTERIOR"/>
                        </Grid>
                        <Grid className={classes.input} item
                            xs={6} sm= {3}>
                            <Button
                                variant = "contained"
                                text="Enviar"
                                func={handleEnviarSolicitud}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <StepperInscripcion activeStep={5}/>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default ResumenPage

