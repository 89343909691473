import { useState, useMemo, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useRolService from '../../services/administracion/useRolService'
import { mensajeCamposNoValidos } from '../../utils/messages'
import { addRow, findRow, updateRow } from '../../utils/rows'
import { allLetterAndSpace } from '../../utils/validations'
import useIsLoged from '../utils/useIsLoged'
import Session from 'react-session-api'
import { useCookies } from 'react-cookie'
import useUsuarioService from '../../services/administracion/useUsuarioService'
import { getNowInSeconds } from '../../utils/time'

const useRolPage = () => {
    const { getMe } = useUsuarioService()

    const { createRol, getRolById, getRoles, updateRol, 
        deleteRol } = useRolService()

    const history = useHistory()
    useIsLoged()
    const {id} = useParams()
    
    const [error, setError] = useState(null)
    const [rows, setRows] = useState([])
    const [nombre, setNombre] = useState('')
    const [errorNombre, setErrorNombre] = useState(false)
    const [helperNombre, setHelperNombre] = useState('')
    const [permisos, setPermisos] = useState([])
    
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    const [rolToDelete, setRolToDelete] = useState(null)

    //estados checkbox permisos
    const [gestionMinisterio, setGestionMinisterio] = useState(false)
    const [gestionCargo, setGestionCargo] = useState(false)
    const [gestionPractica, setGestionPractica] = useState(false)
    const [gestionResponsabilidad, setGestionResponsabilidad] = useState(false)
    const [gestionSector, setGestionSector] = useState(false)
    const [gestionTipoTOE, setGestionTipoTOE] = useState(false)

    //Cookies
    const [cookies, setCookie, removeCookie] = useCookies(['token','rol', 'id'])

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    const [loading, setLoading] = useState(0)
    
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    //Permisos validos, deben ser iguales al de la BD
    const validPermisos = useMemo(() => {
        return [
            {
                nombre: "Gestion ministerio",
                //El id necesita ser igual al de la BD
                id: 1,
                state: gestionMinisterio,
                setState: setGestionMinisterio
            },
            {
                nombre: "Gestion cargos",
                id: 2,
                state: gestionCargo,
                setState: setGestionCargo
            },
            {
                nombre: "Gestion practicas",
                id: 3,
                state: gestionPractica,
                setState: setGestionPractica
            },
            {
                nombre: "Gestion responsabilidades",
                id: 4,
                state: gestionResponsabilidad,
                setState: setGestionResponsabilidad
            },
            {
                nombre: "Gestion tipos TOE",
                id: 5,
                state: gestionTipoTOE,
                setState: setGestionTipoTOE
            },
            {
                nombre: "Gestion sectores",
                id: 6,
                state: gestionSector,
                setState: setGestionSector
            },
        ]
    }, [gestionCargo, gestionMinisterio, gestionPractica, 
        gestionResponsabilidad, gestionSector, gestionTipoTOE, 
        setGestionCargo, setGestionMinisterio, setGestionPractica, 
        setGestionResponsabilidad, setGestionSector, setGestionTipoTOE])

    const addPermisoToPermisos = (permiso) => {
        setPermisos((current) => {
            let newPermisos = current
            newPermisos.push(permiso)
            return newPermisos
        })
    }

    const deletePermisoFromPermisos = (permiso) => {
        setPermisos((current) => {
            let newPermisos = current.filter(
                (value) => {
                    //newPermisos se conforma solo por los valores que son diferentes a permiso
                    return value !== permiso
                }
            )
            return newPermisos
        })
    }

    //Funcion para agregar o eliminar permisos dependiendo del nuevo estado del checkbox
    const updatePermisos = (newState, permiso) => {
        //Si se activo el permiso y no existe en el arreglo de permisos se agrega al arreglo
        if(newState && !permisos.includes(permiso)){
            addPermisoToPermisos(permiso)
        }
        //Caso contrario lo elimina del array permisos
        else{
            deletePermisoFromPermisos(permiso)
        }
    }

    const handleOnChangeCheckBox = (permiso) => {
        //permiso es el id del permiso en la BD
        validPermisos.forEach((validPermiso)=>{
            if(validPermiso.id === permiso){
                validPermiso.setState((current) => {
                    const newState = !current
                    updatePermisos(newState, permiso)
                    return newState
                })
            }
        })
    }
    
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setRolToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const onChangeNombre = (text)=>
    {
        setNombre(text)
        if(!allLetterAndSpace(text) && text !== ""){
            setErrorNombre(true)
            setHelperNombre("El nombre debe consistir solo de letras.")
        }else{
            setErrorNombre(false)
            setHelperNombre("")
        }
    }

    const checkForErrors = () => {
        if (errorNombre || nombre === "" || !permisos.length)
        {
            return true
        }
        return false
    }

    const onCreateRol = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const newRol = {
            "nombre": nombre,
            "permissions": permisos
        }
        startLoading()
        const result = await createRol(newRol)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            //const permiso = permissionsToString(permissions_role)
            //const cell = [nombre, permiso]
            //const key = id
            //addRow(key, cell, setRows)
            handleGetRoles()
            handleOpenAlert('success', `El rol "${nombre}" se creo correctamente.`)
            cancel()
        }
        stopLoading()
        cancel()
    }

    const onUpdateRol = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const rolActualizado = {
            "nombre": nombre,
            "permissions": permisos
        }
        startLoading()
        const result = await updateRol(id, rolActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre, permissions_role} = result
            const permiso = permissionsToString(permissions_role)
            const newCells = [nombre, permiso]
            updateRow(id, newCells, setRows)
            const currentRole = Session.get("rol")
            if(currentRole && currentRole.id === Number(id)){
                const resultMe = await getMe()
                if (resultMe.error){
                    handleOpenAlert('error', resultMe.error)
                }else{
                    console.log('se actualizo este usuario', resultMe)
                    console.log('Token en update', cookies.token)
                    Session.set("rol", resultMe.role)
                    removeCookie('rol', {path: '/'})
                    setCookie('rol', resultMe.role, {path: '/',
                        maxAge: (cookies['token'].expire_date - getNowInSeconds())})
                }
            }else{
                console.log(currentRole ? currentRole.id : "No role found in session")
            }
            handleOpenAlert('success', `El rol "${nombre}" se actualizo correctamente.`)
            cancel()
        }
        stopLoading()
    }

    const onDeleteRol = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteRol(rolToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {id, nombre} = result
            //deleteRow(id, setRows)
            handleGetRoles()
            editCancel()
            if(Session.get("rol").id === Number(id)){
                Session.clear()
                removeCookie('token', {path: '/'})
                removeCookie('rol', {path: '/'})
                removeCookie('id', {path: '/'})
                history.push('/iniciar-sesion')
            }
            handleOpenAlert('success', `El rol "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }


    const getRol = useCallback(async (id) => {
        startLoading()
        const rol = await getRolById(id)
        if (rol.error){
            handleOpenAlert('error', rol.error)
        }
        else{
            setNombre(rol.nombre)
            let onlyIds = []
            
            rol.permissions_role.forEach((permission_role) => {
                //se obtiene el id del rol por editar y agrega a un arreglo 
                //de ids
                onlyIds.push(permission_role.permission_id)
            })
            validPermisos.forEach((validPermiso)=>{
                //Se verifica si uno de los permisos validos forma parte de 
                //los permisos del rol a editar
                if(onlyIds.includes(validPermiso.id)){
                    //Se cambia el estado del checkbox de ese permiso a activo
                    validPermiso.setState(true)
                }
                //Caso contrario se desactiva el checkbox
                else{
                    validPermiso.setState(false)
                }
            })
            setPermisos(onlyIds)
        }
        stopLoading()
    }, [getRolById, validPermisos])

    const handleGetRoles = useCallback(async () => {
        startLoading()
        const roles = await getRoles(page)
        if (roles.error){
            setError(roles.error)
        }
        else{
            setRows([])
            setNumPages(roles.last_page)
            roles.data.forEach(({id, nombre, permissions_role}) => {
                const key = id
                const permisos = permissionsToString(permissions_role)
                const cell = [nombre, permisos]
                addRow(key, cell, setRows)
            })
            if(page > roles.last_page){
                setPage(roles.last_page)
                history.push(`${window.location.pathname}?page=${roles.last_page}`)
            }
        }
        stopLoading()
    }, [getRoles, history, page])

    const editClick = (id) => {
        const currentRole = Session.get("rol")
        if (currentRole && (currentRole.nombre === 'Administrador' || currentRole.nombre === 'Secretaria CIAN')) {
            if (currentRole.nombre === 'Secretaria CIAN' && id === 1) { // Assuming 1 is the admin role ID
                handleOpenAlert('warning', 'La secretaria no puede editar el rol de administrador.')
            } else {
                history.push(`/admin/rol/${id}?page=${page}`)
            }
        } else {
            handleOpenAlert('warning', 'No tienes permisos para editar roles.')
        }
    }

    const editCancel = () => {
        const currentRole = Session.get("rol")
        if (currentRole && (currentRole.nombre === 'Administrador' || currentRole.nombre === 'Secretaria CIAN')) {
            if (currentRole.nombre === 'Secretaria' && id === 1) { // Assuming 1 is the admin role ID
                handleOpenAlert('warning', 'La secretaria no puede editar el rol de administrador.')
            } else {
                history.push(`/admin/rol/${id}?page=${page}`)
            }
        } else {
            handleOpenAlert('warning', 'No tienes permisos para editar roles.')
        }
    }

    const cancel = () => {
        setNombre("")
        setPermisos([])
        //Se desactivan todos los permisos validos al cancelar o editar un rol
        validPermisos.forEach((permiso) => {
            permiso.setState(false)
        })
        setErrorNombre(false)
    }

    const permissionsToString = (permissions_role) => {
        let permisos = ""
        permissions_role.forEach((permission, index) => {
            permisos = `${permisos}${permission.permission.nombre}`
            if(index+1 < permissions_role.length){
                permisos = `${permisos}, `
            }
        })
        return permisos
    }

    const data = {
        rows,
        nombre,
        errorNombre,
        helperNombre,
        permisos,
        id,
        openEdit,
        openDelete,
        rolToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        validPermisos,
        page,
        numPages,
        loading
    }

    const actions = {
        onDeleteRol,
        onCreateRol,
        onUpdateRol,
        editClick,
        onChangeNombre,
        setPermisos,
        handleOnChangeCheckBox,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setRolToDelete,
        setAlertOpen,
        handlePageChange,
    }

    //useEffect para cargar datos en la tabla
    useEffect(() => {
        handleGetRoles()
    }, [handleGetRoles])
    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getRol(id)
        }
    //El siguiente comentario es para desactivar el warning sobre
    //dependencias faltantes (getRol), nunca usar, solo si esta 100% seguro que
    //es la forma mas segura de volver a usar el efecto
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return {error, data, actions}
}

export default useRolPage