import {useState, useEffect, useCallback} from "react"
import {useHistory} from 'react-router-dom'
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"
import useIsLoged from '../utils/useIsLoged'

const useLecturasPageLeido = () => {
    useIsLoged()
    const history = useHistory()
    const {
        getArchivosReporteLecturaDocx,
        deleteArchivosReporteLecturaDocx,
        descargarArchivosReporteLecturaDocx,
        updateEstadoReporteLecturaDocx,
        subirArchivoEditadoReporteLecturaDocx,
        reinicioReporteLecturaDocx,
    } = useDosimetriaService()

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([]);

    // Almacena los archivos .docx
    const [archivos_DOCX, setArchivos_DOCX] = useState([])

    // Para mostrar y ocultar el input de subir archivo word a editar
    const [openCloseInputFile, setOpenCloseInputFile] = useState(false)

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    // Para el archivo de los reportes
    const [archivoDOCX, setArchivoDOCX] = useState(null)
    const [objArchivoDOCX, setObjArchivoDOCX] = useState(null)

    const [idArchivoEditarDOCX, setIdArchivoEditarDOCX] = useState(null)

    const [openCloseModalReinicioReporte, setOpenCloseModalReinicioReporte] = useState(null)

    const [progress, setProgress] = useState(-1)

    // Para el rango de fecha
    const [dateRange, setDateRange] = useState({
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString()
    })
    const [value, setValue] = useState("Seleccione un rango de fechas")

    // Extrayendo los parametros tipo? Parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);

    //Estado que indica la página actual
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el número de páginas en total
    const [numPages, setNumPages] = useState(0)

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    if (!page) {
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    // Controlador cambio de página
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)

        setIdArchivoEditarDOCX(null)
        setOpenCloseInputFile(false)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }


    const handleLimpiarFechas = () => {
        setDateRange({
            startDate: "",
            endDate: ""
        })
        setValue('Seleccione un rango de fecha')

        setIdArchivoEditarDOCX(null)
        setOpenCloseInputFile(false)
    }

    const handleSelectDate = (start, end) => {
        // Obteniendo la fecha del componente del rango de fecha
        const {_d: fechaInicio} = start
        const {_d: fechaFin} = end
        setDateRange({
            startDate: new Date(fechaInicio).toISOString(),
            endDate: new Date(fechaFin).toISOString()
        })
        // Texto que aparecerá en el componente del rango de fecha
        setValue(start.format('DD-MMM-YYYY') + "  -  " + end.format('DD-MMM-YYYY'))

        setIdArchivoEditarDOCX(null)
        setOpenCloseInputFile(false)
    }


    const handleOnChangeArchivoDocx = (file) => {
        setArchivoDOCX(file[0])
        setObjArchivoDOCX([{
            name: file[0].name, deleteFile: () => {
                setObjArchivoDOCX(null)
                setArchivoDOCX(null)
            }
        }])
    }

    const handleOnProgress = (loaded, total) => {
        const progress = (loaded / total) * 100
        setProgress(progress)
    }

    const getArchivosDocx = useCallback(async () => {
        startLoading()
        const params = {}
        if (page) params.page = page
        if (dateRange?.startDate) params.fechaIni = dateRange?.startDate
        if (dateRange?.endDate) params.fechaFin = dateRange?.endDate

        try {
            const response = await getArchivosReporteLecturaDocx(params)
            // console.log("REPONSE ARCHIVO WORD", response)
            setNumPages(response.last_page)
            setArchivos_DOCX(response?.data)
            // Para la paginación en la URL
            if (page > response.last_page) {
                setPage(response.last_page)
                history.push(`${window.location.pathname}?page=${response.last_page}`)
            }
        } catch (e) {
            handleOpenAlert('error',
                e?.error ? e?.error : "Ocurrió un error, intente de nuevo más tarde")
        }
        stopLoading()
    }, [page, history, dateRange])

    const descargarArchivosDocx = async (archivo) => {
        const {id} = archivo
        try {
            await descargarArchivosReporteLecturaDocx(id)
        } catch (e) {
            handleOpenAlert('error',
                e?.error ? e?.error : "Ocurrió un error, intente de nuevo más tarde")
        }
    }

    const eliminarArchivosDocx = async (archivo) => {
        const {id} = archivo
        try {
            await deleteArchivosReporteLecturaDocx(id)
            await getArchivosDocx()

            if (id === idArchivoEditarDOCX){
                setIdArchivoEditarDOCX(null)
                setOpenCloseInputFile(false)
            }
        } catch (e) {
            handleOpenAlert('error',
                e?.error ? e?.error : "Ocurrió un error, intente de nuevo más tarde")
        }
    }

    // Función que sube el archivo word editado al servidor
    const uploadWord = async () => {
        if (!archivoDOCX) {
            handleOpenAlert('warning', "Debe seleccionar un archivo .docx")
            return
        }
        startLoading()
        let data = new FormData()
        data.append('reporte', archivoDOCX)

        try {
            const response = await subirArchivoEditadoReporteLecturaDocx(idArchivoEditarDOCX, data, handleOnProgress)
            // console.log("response", response)
            if (response?.status === 404 || response?.status === 422) {
                handleOpenAlert('error', response?.error)
            } else {
                await getArchivosDocx()
                handleOpenAlert('success', "Reporte .docx actualizado correctamente")
            }
        } catch (e) {
            // console.error(e)
            handleOpenAlert('error', "Ocurrió un error, intente de nuevo en otro momento.")
        }
        stopLoading()
    }

    const actualizarEstadoReporteDocx = async (archivo, id_estado) => {
        // Obteniendo el id del archivo
        const { id: id_archivo_docx } = archivo
        // Agregando los query params
        const params = {}
        params.estado_reporte_lectura_id = id_estado

        await updateEstadoReporteLecturaDocx(id_archivo_docx, params)
            .then(async res => {
                handleOpenAlert('success', "Estado del reporte actualizado con éxito")
                await getArchivosDocx()
            })
            .catch(error => {
                // console.log(error)
                handleOpenAlert('error', error?.error ? error?.error:
                    "Ocurrió un error en la petición, intente de nuevo mas tarde")
            })
    }

    const openCloseInputToEditWord = (archivo) => {
        setIdArchivoEditarDOCX(archivo?.id)
        setOpenCloseInputFile(!openCloseInputFile)
    }

    const openCloseModalResetearReporte = (archivo) => {
        // if (!openCloseModalReinicioReporte) if (archivo) setIdArchivoEditarDOCX(archivo?.id)
        // else setIdArchivoEditarDOCX(null)
        !openCloseModalReinicioReporte ? setIdArchivoEditarDOCX(archivo?.id) : setIdArchivoEditarDOCX(null)
        setOpenCloseModalReinicioReporte(!openCloseModalReinicioReporte)
    }

    const reinicioReporte = async () => {
        const response = await reinicioReporteLecturaDocx(idArchivoEditarDOCX)
        if (response?.error) {
            handleOpenAlert('error', response?.error ? response?.error:
                "Ocurrió un error al reiniciar los reportes")
        } else {
            handleOpenAlert('success', "Se reinició el reporte con éxito")
            setOpenCloseModalReinicioReporte(false)
            await getArchivosDocx()
        }
    }

    const clearData = () => {
        setRows([])
        setDateRange({
            startDate: null,
            endDate: null
        })
        setValue('Seleccione un rango de fecha')
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        value,
        rows,
        dateRange,
        archivos_DOCX,
        page,
        numPages,
        openCloseInputFile,
        objArchivoDOCX,
        progress,
        openCloseModalReinicioReporte,
    }

    const actions = {
        setAlertOpen,
        handleSelectDate,
        handleLimpiarFechas,
        handlePageChange,
        clearData,
        // Archivo (.docx)
        descargarArchivosDocx,
        eliminarArchivosDocx,
        actualizarEstadoReporteDocx,
        openCloseInputToEditWord,
        handleOnChangeArchivoDocx,
        uploadWord,
        openCloseModalResetearReporte,
        reinicioReporte,
    }

    // Para cargar los archivos docx
    useEffect(async () => {
        await getArchivosDocx()
    }, [dateRange])

    return {data, actions}
}

export default useLecturasPageLeido
