import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const sectorUrl = `${url}sectores`

const useSectorService = () => {
    const { errorHandler } = useErrorHandler()

    const createSector = useCallback(
        async (sector) => {
            try {
                const response = await axios.post(sectorUrl, sector, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getSectorById = useCallback(
        async (sector) => {
            const requestUrl = `${sectorUrl}/${sector}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getSectoresByStatus = useCallback(
        async (status, page) => {
            const requestUrl = `${sectorUrl}/status/${status}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const updateSector = useCallback(
        async (sector, cambios) => {
            const requestUrl = `${sectorUrl}/${sector}`
            try {
                const response = await axios.put(requestUrl, cambios, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const deleteSector = useCallback(
        async (sector) => {
            const requestUrl = `${sectorUrl}/${sector}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createSector, getSectorById, getSectoresByStatus, updateSector, 
        deleteSector }
}

export default useSectorService