import axios from "axios"
import url from "../utils/url"
import headers from "../utils/headers"
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const useDosimetriaService = () => {
    const { errorHandler } = useErrorHandler()

    /**
     * @description: Asigna los dosimetros a los TOE
     * @param: dosimetro_id, toe_area_id
     * @return: response
    */
    const asignarDosimetro = useCallback(
        async (params) => {
            const requestUrl = `${url}dosimetroasignados`
            try {
                const response = await axios.post(requestUrl, params, {
                    headers: headers(),
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Obtiene los dosimetros disponibles por su tipo para ser asignados por
     * el técnico de dosimetria
     * @param: tipo_dosimetro
     * @return: response
     */
    const getDosimetrosDisponiblesByTipo = useCallback(
        async (params) => {
            const requestUrl = `${url}/dosimetros/disponibles/tipo`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params: params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Obtiene todas las áreas con los dosimetros solicitados para ser asignados
     * el técnico de dosimetria
     * @param: N/A
     * @return: response
     */
    const getAllAsignacionesByAreas = useCallback(
        async (params) => {
            const requestUrl = `${url}asignacion/areas`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Obtiene los TOE del área con dosímetros solicitas para ser asignados por
     * el técnico de dosimetria
     * @param: area_id
     * @return: response
     */
    const getAllAsignacionesToeByArea = useCallback(
        async (params) => {
            const requestUrl = `${url}asignacion/toe`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Obtiene los TOE del área con dosímetros solicitas para ser asignados por
     * el técnico de dosimetria
     * @param: area_id
     * @return: response
     */
    const getDosimetrosAsignadosByArea = useCallback(
        async (params) => {
            const requestUrl = `${url}dosimetroasignados/area`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Asigna los dosimetros chequeados
     * @param: params
     * @return: response
     */
    const chequearDosimetro = useCallback(
        async (params) => {
            const requestUrl = `${url}dosimetriacontrol`
            try {
                const response = await axios.post(requestUrl, params, {
                    headers: headers(),
                })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Obtiene los dosímetros que recibirán control de lectura
     * @param: area_id
     * @return: response
     */
    const getDosimetrosControlLecturaByArea = useCallback(
        async () => {
            const requestUrl = `${url}dosimetriacontrol/lectura`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Finaliza la lectura de los lotes
     * @param: area_id
     * @return: response
     */
    const finalizarLoteLectura = useCallback(
        async (params) => {
            const requestUrl = `${url}lotelecturas`
            try {
                const response = await axios.post(requestUrl, params, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Obtiene la lectura de los lotes por área
     * @param: area_id
     * @return: response
     */
    const getLecturaLoteByArea = useCallback(
        async (params) => {
            const requestUrl = `${url}lotelecturas/area`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Sube el archivo de reporte .asc generado por la máquina
     * @param: file
     * @return: response
     */
    const subirArchivoReporteASC = useCallback(
        async (file, handleOnUploadProgress) => {
            try {
                const requestUrl = `${url}lotelecturas/detalles/archivo`
                const response = await axios.post(requestUrl, file,
                    {
                        headers: { 'Content-Type': 'multipart/form-data', ...headers() },
                        onUploadProgress: (progressEvent) => handleOnUploadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    /**
     * @description: Obtiene los archivos .ASC subidos
     * @param: fechaIni
     * @param: fechaFin
     * @return: response
     */
    const getArchivosLectura = useCallback(
        async (params) => {
            const requestUrl = `${url}lotelecturas/detalles/archivos`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Elimina el archivo .ASC subido
     * @param: id_archivo
     * @return: response
     */
    const deleteArchivosLectura = useCallback(
        async (id_Archivo) => {
            const requestUrl = `${url}lotelecturas/detalles/archivo/${id_Archivo}`
            try {
                const response = await axios.delete(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Descargar el archivo .ASC subido
     * @param: id_archivo
     * @return: response
     */
    const descargarArchivosLectura = useCallback(
        async (id_Archivo) => {
            const requestUrl = `${url}lotelecturas/detalles/archivo/${id_Archivo}`
            try {
                const response = await axios.get(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Sube el archivo de reporte .docx
     * @param: file
     * @return: response
     */
    const subirArchivoReporteLecturaDocx = useCallback(
        async (file, handleOnUploadProgress) => {
            try {
                const requestUrl = `${url}reportelecturas`
                const response = await axios.post(requestUrl, file,
                    {
                        headers: { 'Content-Type': 'multipart/form-data', ...headers() },
                        onUploadProgress: (progressEvent) => handleOnUploadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    /**
     * @description: Sube el archivo de reporte .docx editado
     * @param: file
     * @return: response
     */
    const subirArchivoEditadoReporteLecturaDocx = useCallback(
        async (id, file, handleOnUploadProgress) => {
            try {
                const requestUrl = `${url}reportelecturas/${id}`
                const response = await axios.post(requestUrl, file,
                    {
                        headers: { 'Content-Type': 'multipart/form-data', ...headers() },
                        onUploadProgress: (progressEvent) => handleOnUploadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    /**
     * @description: Obtiene los archivos .docx subidos
     * @param: fechaIni
     * @param: fechaFin
     * @param: page
     * @return: response
     */
    const getArchivosReporteLecturaDocx = useCallback(
        async (params) => {
            const requestUrl = `${url}reportelecturas`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Elimina el archivo .docx subido
     * @param: id_archivo
     * @return: response
     */
    const deleteArchivosReporteLecturaDocx = useCallback(
        async (id_Archivo) => {
            const requestUrl = `${url}reportelecturas/delete/${id_Archivo}`
            try {
                const response = await axios.delete(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Reinicia proceso de lectura
     * @param: id_archivo
     * @return: response
     */
    const reinicioReporteLecturaDocx = useCallback(
        async (id_Archivo) => {
            const requestUrl = `${url}reportelecturas/${id_Archivo}`
            try {
                const response = await axios.delete(requestUrl, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Descargar el archivo .docx subido
     * @param: id_archivo
     * @return: response
     */
    const descargarArchivosReporteLecturaDocx = useCallback(
        async (id_Archivo) => {
            const requestUrl = `${url}reportelecturas/${id_Archivo}`
            // window.open(requestUrl, "_blank")
            try {
                window.open(requestUrl, "_blank")
                // const response = await axios.get(requestUrl, { headers: headers() })
                // const { data } = response
                // return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    /**
     * @description: Actualizar esto del reporte
     * @param: id_archivo_docx
     * @query: estado_reporte_lectura_id
     * @return: response
     */
    const updateEstadoReporteLecturaDocx = useCallback(
        async (id_archivo_docx, params) => {
            const requestUrl = `${url}reportelecturas/status/${id_archivo_docx}`
            try {
                const response = await axios.put(requestUrl, "", {headers: headers(), params: params})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    /**
     * @description: Obtiene las configuraciones del reporte de lectura (docx)
     * @return: response
     */
    const getConfiguracionReporteLectura = useCallback(
        async (params) => {
            const requestUrl = `${url}configuracion/reportelectura`
            try {
                const response = await axios.get(requestUrl, { headers: headers(), params })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    /**
     * @description: Guarda la configuración del reporte de lectura (.docx)
     * @param: params
     * @return: response
     */
    const createConfiguracionReporteLectura = useCallback(
        async (params) => {
            const requestUrl = `${url}configuracion/reportelectura`
            try {
                const response = await axios.post(requestUrl, params, { headers: headers() })
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        }, [errorHandler],
    )

    return {
        asignarDosimetro,
        getDosimetrosDisponiblesByTipo,
        getAllAsignacionesByAreas,
        getAllAsignacionesToeByArea,
        getDosimetrosAsignadosByArea,
        chequearDosimetro,
        getDosimetrosControlLecturaByArea,
        finalizarLoteLectura,
        getLecturaLoteByArea,

        // Archivo .ASC
        subirArchivoReporteASC,
        getArchivosLectura,
        deleteArchivosLectura,
        descargarArchivosLectura,

        // Reporte lecturas (.docx)
        subirArchivoReporteLecturaDocx,
        getArchivosReporteLecturaDocx,
        deleteArchivosReporteLecturaDocx,
        descargarArchivosReporteLecturaDocx,
        updateEstadoReporteLecturaDocx,
        subirArchivoEditadoReporteLecturaDocx,
        reinicioReporteLecturaDocx,

        // Configuración del reporte de lectura (.docx)
        configuracionReporteLectura: {
            getConfiguracionReporteLectura,
            createConfiguracionReporteLectura,
        }
    }
}

export default useDosimetriaService
