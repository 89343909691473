import React from "react"
import AppFrame from "../../../components/AppFrame"
import Paper from "@material-ui/core/Paper"
import {makeStyles} from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import "react-day-picker/lib/style.css"
import TabPanel from "../../../components/TabPanel"
import ReportesLeidoPage from "../ReportesLeidoPage"

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: "2em",
        paddingBottom: "2em",
    },
}))

const DosimetriaControlCoordinadorPage = () => {

    const classes = useStyles()

    return (
        <AppFrame loged={false}>
            <Paper className={classes.paper}>
                <Tabs value={0} indicatorColor="primary" textColor="primary" centered>
                    <Tab label="Reportes"/>
                </Tabs>
                <TabPanel value={0} index={0}>
                    <ReportesLeidoPage/>
                </TabPanel>

            </Paper>
        </AppFrame>
    )
}

export default DosimetriaControlCoordinadorPage
