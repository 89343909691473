import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { Typography } from '@material-ui/core'

const fontFamily = 'Roboto'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        'fontFamily': fontFamily
    }),
    input: (provided, state) => {
        return { ...provided, 'fontFamily': fontFamily,}
    },
    singleValue: (provided, state) => {
        return { ...provided, 'fontFamily': fontFamily,}
    },
    multiValue: (provided, state) => {
        return { ...provided, 'fontFamily': fontFamily,}
    },
    placeholder: (provided, state) => {
        return { ...provided, 'fontFamily': fontFamily,}
    },
    loadingMessage: (provided, state) => {
        return { ...provided, 'fontFamily': fontFamily,}
    },
}

const ReactSelect = ({label, asyncFunc, onChange, value, isMulti, options, placeholder, disabled, isLoading, isClearable, components, autoFocus}) => {
    return (
        <div>
            <Typography variant="caption" color="textSecondary">{label}</Typography>
            {
                asyncFunc && !options ?
                <AsyncSelect
                    //cacheOptions
                    defaultOptions
                    loadOptions={asyncFunc}
                    options = {options}
                    onChange={onChange}
                    value = {value}
                    isMulti = {isMulti}
                    styles = {customStyles}
                    placeholder = {placeholder ? placeholder : "Seleccione..."}
                    loadingMessage = {(value) => {
                        return `Buscando "${value.inputValue}""`
                    }}
                    noOptionsMessage = {(value) => `No se encontraron datos para "${value.inputValue}"`}
                    isDisabled = {disabled}
                    isClearable = {isClearable}
                    components = {components}
                    autoFocus = {autoFocus}
                    />
                :
                <Select
                    value = {value}
                    isMulti = {isMulti}
                    styles = {customStyles}
                    isDisabled={disabled}
                    isLoading={isLoading}
                    isSearchable={true}
                    options={options}
                    onChange = {onChange}
                    placeholder = {placeholder ? placeholder : "Seleccione..."}
                    noOptionsMessage = {(value) => `No se encontraron datos para "${value.inputValue}"`}
                    isClearable = {true}
                    components = {components}
                    />
            }
            
        </div>
    )
}

ReactSelect.propTypes = {
    label: PropTypes.string,
    //Funcion asyncrona que recibe un parametro escrito por el usuario y retorna un arreglo de opciones
    asyncFunc: PropTypes.func,
    //Funcion que recibe el valor seleccionado
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
    }), PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
    })),]),
    isMulti: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
    })),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    autoFocus: PropTypes.bool,
}

export default ReactSelect
