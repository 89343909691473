import React from 'react';
import AppFrame from '../components/AppFrame';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        fontSize: '1.2rem',
        textAlign: 'center', 
    },
    sectionContent: {
        marginBottom: theme.spacing(2),
        fontSize: '1rem',
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
}));

const AcercaNosotros = () => {
    const classes = useStyles();

    return (
        <AppFrame loged={false}>
            <Container maxWidth="md">
                <Paper className={classes.paper}>
                    <div className={classes.sectionTitle}>Visión</div>
                    <Divider className={classes.divider} />
                    <div className={classes.sectionContent}>
                        {"Promover e incentivar el buen uso de la Tecnología Nuclear en El Salvador, ofreciendo servicios de calidad como apoyo al desarrollo de la actividad productiva y de servicios. Esto utilizando la Tecnología Nuclear y sus métodos analíticos, en las áreas de salud, medio ambiente y evaluación de recursos naturales, industria y educación."}
                    </div>
                </Paper>
                <Paper className={classes.paper}>
                    <div className={classes.sectionTitle}>Misión</div>
                    <Divider className={classes.divider} />
                    <div className={classes.sectionContent}>
                        {"Ser un Centro líder en las investigaciones y en el fomento del buen uso de las aplicaciones de la tecnología nuclear de interés en la región Centroamericana, con personal altamente calificado en docencia, investigación y proyección social que promueva y fortalezca el progreso, bienestar y competitividad en el país; garantizando la cultura de seguridad en las aplicaciones de la energía nuclear."}
                    </div>
                </Paper>
                <Paper className={classes.paper}>
                    <div className={classes.sectionTitle}>¿Qué es el CIAN?</div>
                    <Divider className={classes.divider} />
                    <div className={classes.sectionContent}>
                        {"El Centro de Investigaciones y Aplicaciones Nucleares (CIAN) es una unidad de investigación, desarrollo y servicio en los campos de seguridad y protección radiológica, control de calidad, pruebas y ensayos analíticos. En el CIAN se trabaja en coordinación con la Unidad Reguladora y Asesora de Radiaciones del Ministerio de Salud (UNRA-MINSAL), con el objetivo de brindar acompañamiento en el desarrollo, difusión y transferencia de tecnologías con el uso de las radiaciones para las áreas médicas e industria."}
                    </div>
                </Paper>
                <Paper className={classes.paper}>
                    <div className={classes.sectionTitle}>Breve Historia del CIAN</div>
                    <Divider className={classes.divider} />
                    <div className={classes.sectionContent}>
                        {"Fue creado en 1986 mediante convenio entre el Gobierno de la República de El Salvador y el Organismo Internacional de Energía atómica (OIEA), con la finalidad de ejecutar acciones de transferencia tecnológica sobre los usos pacíficos del átomo en sus diferentes campos: Industria, agricultura, salud y medio ambiente. Más de 30 años respaldan el servicio profesional realizado por el personal altamente capacitado del CIAN."}
                    </div>
                </Paper>
                <Paper className={classes.paper}>
                    <div className={classes.sectionTitle}>Servicios que ofrece el CIAN</div>
                    <Divider className={classes.divider} />
                    <div className={classes.sectionContent}>
                        {"El CIAN brinda servicios especializados en varias áreas relacionadas al uso pacífico de las radiaciones ionizantes utilizadas en áreas médicas e industria. Además el CIAN, bajo su labor social-científica realiza mediciones de contaminantes en diversas matrices mediante técnicas analíticas rutinarias y nucleares. Nuestros servicios están enfocados para contribuir a la cultura de protección radiológica en El Salvador, mediante el uso de las radiaciones ionizantes. Consulte la información detallada de nuestros servicios y conozca las áreas especializadas con las que cuenta el CIAN."}
                    </div>
                </Paper>
            </Container>
        </AppFrame>
    );
};

export default AcercaNosotros;
