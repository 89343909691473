import axios from "axios";
// import url from "./utils/url";
import headers from "../utils/headers";
// import useErrorHandler from "./utils/useErrorHandler";
import { useCallback } from "react";
import useErrorHandler from "../utils/useErrorHandler";
import url from "../utils/url";

const solicitudUrl = `${url}clientesHistorial`;

const useHistorialDeReportesCCService = () => {
  const { errorHandler } = useErrorHandler();

  const clientesHistorialCC = useCallback(
    async (nombreInstitucion, page) => {
      const parametros = [];

      if (page) {
        parametros.push(`page=${page}`);
      }
      if (nombreInstitucion) {
        parametros.push(`nombreInstitucion=${nombreInstitucion}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}${parametrosString}`;

      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );




  return { clientesHistorialCC };
};

export default useHistorialDeReportesCCService;