import React from "react"
import Grid from "@material-ui/core/Grid"
import FormDivider from "../../components/FormDivider"
import { makeStyles } from "@material-ui/core/styles"
import { List } from "@material-ui/core"
import { BsCalendar } from "react-icons/bs"
import ComboBox from "../../components/ComboBox"
import ReactSelect from "../../components/ReactSelect"
import Progress from "../../components/Progress/Progress"

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
    //marginBottom: ".5em",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}))

const opciones = [
  {
    label: "Entrega de dosímetro",
    value: 1,
  },
  {
    label: "Recepción de dosímetro",
    value: 2,
  },
]

export const TecnicoPlanificacionCrearEditar = (props) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" justifyContent="center">
      <Progress open={props.data.loading} />
      <FormDivider />
      <Grid container direction="row" spacing={2}>
        <Grid className={classes.input} justifyContent="flex-start" item xs={6}>
          <p>
            <strong>Evento</strong>
          </p>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" spacing={2}>
        <Grid className={classes.input} item>
          <BsCalendar />{" "}
        </Grid>
        <Grid className={classes.input} item xs={6}>
          <ComboBox
            label={"Seleccione una opción"}
            list={opciones}
            value={props.data.seleccion}
            onChange={props.actions.onChangeSelectionTipoEvento}
          />
        </Grid>
      </Grid>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <FormDivider text="Detalles" />

        <Grid container direction="row" justifyContent="center" spacing={2}>
          {/* Institucion */}
          <Grid className={classes.input} item xs={12}>
            <ReactSelect
              placeholder="Seleccione la institución disponible"
              label="Institución"
              asyncFunc={props?.actions?.loadInstituciones}
              onChange={props?.actions?.handleChangeInstitucion}
              value={props?.data?.selectedInstitucion}
              isClearable={true}
              components={
                props?.data?.selectedInstitucion ?
                    {
                      Menu: () => null
                    }
                    :
                    undefined
              }
            />
          </Grid>
          {/* Intalacion */}
          <Grid className={classes.input} item xs={12}>
            {
              props?.data?.selectedInstitucion ?
                  <ReactSelect
                      key={0}
                      placeholder="Seleccione la instalación disponible"
                      label="Instalación"
                      asyncFunc={props?.actions?.loadInstalaciones}
                      onChange={props?.actions?.handleChangeInstalacion}
                      value={props?.data?.selectedInstalacion}
                      disabled={!props?.data?.selectedInstitucion}
                      isClearable={true}
                      components={
                        props?.data?.selectedInstalacion ?
                            {
                              Menu: () => null
                            }
                            :
                            undefined
                      }
                  /> :
                  <ReactSelect
                      key={1} value={null}
                      placeholder="Seleccione la instalación disponible"
                      label="Instalación"
                      onChange={() => {
                      }}
                      asyncFunc={() => {
                      }}
                      disabled={true}
                      isClearable={true}
                  />
            }
          </Grid>
          {/* Area */}
          <Grid className={classes.input} item xs={12}>
            {
              props?.data?.selectedInstalacion ?
                  <ReactSelect
                      key={0}
                      placeholder="Seleccione el área disponible"
                      label="Área"
                      asyncFunc={props?.actions?.loadAreas}
                      onChange={props?.actions?.handleChangeArea}
                      value={props?.data?.status}
                      disabled={!props?.data?.selectedInstalacion}
                      isClearable={true}
                      components={
                        props?.data?.selectedArea ?
                            {
                              Menu: () => null
                            }
                            :
                            undefined
                      }
                  /> :
                  <ReactSelect
                      key={1} value={null}
                      placeholder="Seleccione el área disponible"
                      label="Área"
                      onChange={() => {
                      }}
                      asyncFunc={() => {
                      }}
                      disabled={true}
                      isClearable={true}
                  />
            }
          </Grid>
        </Grid>
      </List>
    </Grid>
  )
}

export default TecnicoPlanificacionCrearEditar
