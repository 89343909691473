import React  from "react"
import Button from "../../components/Button/Button"
import Input from "../../components/Input/Input"
import Grid from "@material-ui/core/Grid"
import FormDivider from "../../components/FormDivider"
import Dialog from "../../components/Dialog/Dialog"
import { makeStyles } from "@material-ui/core/styles"
import { List, ListSubheader, Collapse, ListItem } from "@material-ui/core"
import TecnicoPlanificacionCrearEditar from "./TecnicoPlanificacionCrearEditar"
import ItemList from "../../components/ItemList"
import { findRow } from "../../utils/rows"

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  input: {
    marginTop: ".5em",
    //marginBottom: ".5em",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}))

const TecnicoPlanificacionConsultar = (props) => {
  const classes = useStyles()
  return (
    <Grid container direction="row" justifyContent="center">
      <FormDivider />
      <Grid container direction="row" spacing={2}>
        <Grid className={classes.input} justifyContent="flex-start" item xs={6}>
          <p>
            <strong>Actividades</strong>
          </p>
        </Grid>
        {/* Boton de nuevo evento */}
        <Grid className={classes.input} justifyContent="flex-end" item xs={6}>
          <Button
            func={props.actions.handleClickOpenCreate}
            variant="contained"
            text="Nuevo evento"
          />
        </Grid>
        {/* Fin Boton de nuevo evento */}
      </Grid>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <strong>Planeados</strong>
          </ListSubheader>
        }
      >
        <ItemList
          rows={props.data.rows}
          consultFunc={(key) => props.actions.showMore(key)}
          editFnc={(key) => props.actions.handleClickOpenEdit(key)}
          deleteFnc={(key) => props.actions.handleClickOpenDelete(key)}
        />
        <Collapse in={props.data.show} timeout="auto">
          <FormDivider text="Detalles" />
          <List component="div" disablePadding>
            <ListItem className={classes.nested}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                {/* Institucion */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Institución"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.institucion}
                  />
                </Grid>
                {/* Instalacion */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Instalación"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.instalacion}
                  />
                </Grid>
                {/* Area */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Área"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.area}
                  />
                </Grid>
                {/* Persona que se presento */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Persona que se presentó"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.personaPresentada}
                  />
                </Grid>
                {/* Nombres */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Nombres"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.nombre}
                  />
                </Grid>
                {/* Apellidos */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Apellidos"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.apellido}
                  />
                </Grid>
                {/* Identificar por DUI */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Identificar por DUI"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.dui}
                  />
                </Grid>
                {/* Identificar por licencia */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Identificar por licencia"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.licencia}
                  />
                </Grid>
                {/* Identificar por pasaporte */}
                <Grid className={classes.input} item xs={12}>
                  <Input
                    label="Identificar por pasaporte"
                    size="small"
                    disabled
                    defaultValue="Hello World"
                    variant="outlined"
                    value={props.data.pasaporte}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Collapse>
      </List>
      {/* MODAL PARA CREAR/EDITAR */}
      <Dialog
        open={
          props.data.idEventoEditar
            ? props.data.openEdit
            : props.data.openCreate
        }
        handleClose={
          props.data.idEventoEditar
            ? props.actions.handleClickCloseEdit
            : props.actions.handleClickCloseCreate
        }
        handleAccept={
          props.data.idEventoEditar
            ? props.actions.onUpdateEvento
            : props.actions.onCreateEvento
        }
        title={
          props.data.idEventoEditar
            ? `Editar evento para ${props.data.fechaInicioEvento}`
            : `Nuevo evento para ${props.data.fechaInicioEvento}`
        }
        content={
          <TecnicoPlanificacionCrearEditar
            data={props.data}
            actions={props.actions}
          />
        }
      />
      {/* MODAL PARA ELIMINAR */}
      <Dialog
        open={props.data.openDelete}
        handleAccept={props.actions.onDeleteEvento}
        handleClose={props.actions.handleClickCloseDelete}
        title={"Eliminación del evento"}
        content={
          findRow(props.data.eventoToDelete, props.data.rows)
            ? `El evento con nombre "${findRow(props.data.eventoToDelete, props.data.rows).cells[0]
            }" sera eliminado del sistema.`
            : ""
        }
      />
    </Grid>
  )
}

export default TecnicoPlanificacionConsultar
