import React from 'react'
import AppFrame from '../../components/AppFrame'
import FormHeader from '../../components/FormHeader'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import useRecuperarPage from '../../hooks/sesion/useRecuperarPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    button: {
        marginTop: '1em',
        marginBottom: '1em'
    }
}))

const RecuperarPage = ({ mode }) => {
    
    const classes = useStyles()
    const {data, actions} = useRecuperarPage({ mode })
    const {correoElectronico, errorCorreoElectronico, helperCorreoElectronico, 
        contrasenia1, errorContrasenia1, helperContrasenia1, contrasenia2, 
        errorContrasenia2, helperContrasenia2, alertOpen, alertSeverity, 
        alertMessage, segundoPaso} = data
    const {onChangeCorreoElectronico, onChangeContrasenia1, 
        onChangeContrasenia2, setAlertOpen, enviarCorreo, 
        handleCambioContrasenia} = actions
    const encabezado = (mode === "recuperar") ? "Recuperar contraseña" : "Activar cuenta"
    return (
        <AppFrame loged = {false}>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={6}>
                    <Paper className = {classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="CIAN" 
                            subtitle={encabezado}>
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {10}>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Correo electronico" 
                                        placeholder="Ingrese su correo electronico"
                                        value = {correoElectronico}
                                        onChange={(text)=>{onChangeCorreoElectronico(text)}}
                                        error = {errorCorreoElectronico}
                                        helper = {helperCorreoElectronico}
                                        disabled = {segundoPaso}
                                        />
                                </Grid>
                                {
                                    segundoPaso ? 
                                    <Grid container item xs = {12}>
                                        <Grid item xs = {6}>
                                            <Input type = "password" 
                                                label="Nueva contraseña" 
                                                placeholder="Ingrese su nueva contraseña"
                                                value = {contrasenia1}
                                                onChange={(text)=>{onChangeContrasenia1(text)}}
                                                error = {errorContrasenia1}
                                                helper = {helperContrasenia1}
                                                />
                                        </Grid>
                                        <Grid item xs = {6}>
                                            <Input type = "password" label="Confirme su nueva contraseña" 
                                                placeholder="Ingrese su nueva contraseña de nuevo"
                                                value = {contrasenia2}
                                                onChange={(text)=>{onChangeContrasenia2(text)}}
                                                error = {errorContrasenia2}
                                                helper = {helperContrasenia2}
                                                />
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }
                                <div className = {classes.button}>
                                    <Button 
                                        func={
                                            segundoPaso ? 
                                            handleCambioContrasenia 
                                            : 
                                            enviarCorreo
                                        }
                                        variant = "contained" text="Enviar"/>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default RecuperarPage
