import axios from "axios";
// import url from "./utils/url"
import url from "../utils/url";
// import headers from "./utils/headers"
// import useErrorHandler from './utils/useErrorHandler'
import { useCallback } from "react";
import headers from "../utils/headers";
import useErrorHandler from "../utils/useErrorHandler";

// const eventoUrl = `${url}eventosDosimetria`

const actividadUrl = `http://localhost:3001`;
const actividadURL = `${url}actividad`;

const useCCPlanificacionService = () => {
  const { errorHandler } = useErrorHandler();

  const createActividad = useCallback(
    async (actividad) => {
      try {
        const ruta = `${url}actividades`;
        const response = await axios.post(ruta, actividad);
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getActividadById = useCallback(
    async (idActividad) => {
      const requestUrl = `${url}actividades/${idActividad}`;
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getActividadesByDate = useCallback(
    async (fecha) => {
      //const requestUrl = `${actividadUrl}/planificacion_control_calidad_fecha?fecha_solicitado=${fecha}`;
      // const requestUrl = `${url}actividades/fecha?fecha_solicitado=${fecha}`;
      const requestUrl = `${url}planificacion_control_calidad_fecha?fecha_solicitado=${fecha}`;
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getAllActividades = useCallback(async () => {
    //const requestUrl = `${actividadUrl}/planificacion_control_calidad`;
    const requestUrl = `${url}actividades`;
    console.log({requestUrl,msg:"requestservice"})
    try {
      const response = await axios.get(requestUrl, { headers: headers() });
      const { data } = response;
      console.log({requestUrl,msg:"dataservice"})
      return data;
    } catch (error) {
      return errorHandler(error);
    }
  }, [errorHandler]);

  const agregarNombreAArrayDeEstados = ({ data: array }) => {
    const newArray = array.map((item) => {
      return {
        ...item,
        nombre: item.estado,
      };
    });

    return newArray;
  };

  const getStatusFromActividades = useCallback(async () => {
    const requestUrl = `${actividadURL}/estados`;
    try {
      const response = await axios.get(requestUrl);
      const { data } = response;
      // const resultado = agregarNombreAArrayDeEstados(data);

      // return [{ id: 1, nombre: "juancho" }];
      return data;
    } catch (error) {
      return errorHandler(error);
    }
  }, [errorHandler]);

  const updateActividad = useCallback(
    async (actividadId, cambios) => {
      const requestUrl = `${url}actividades/${actividadId}`;
      try {
        const response = await axios.put(requestUrl, cambios, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const deleteActividad = useCallback(
    async (actividadId) => {
      const requestUrl = `${url}actividades/${actividadId}`;
      try {
        const response = await axios.delete(requestUrl, { headers: headers() });
        //const { data } = response;
        return response;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getActividadesDosimetriaByTipo = useCallback(
    async (params) => {
      const requestUrl = `${actividadUrl}/tipo`;
      try {
        const response = await axios.get(requestUrl, {
          headers: headers(),
          params: params,
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const recibirActividadesDosimetria = useCallback(
    async (params) => {
      const requestUrl = `${actividadUrl}/recibir`;
      try {
        const response = await axios.post(requestUrl, params, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const entregarActividadesDosimetria = useCallback(
    async (params) => {
      const requestUrl = `${actividadUrl}/entregar`;
      try {
        const response = await axios.post(requestUrl, params, {
          headers: headers(),
        });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  const getTiposYcantidadesDeActividadById = useCallback(
    async (idActividad) => {
      const requestUrl = `${url}actividades/solicitudes/${idActividad}`;
      console.log({msg:"esta es la url",requestUrl})
      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        // if (data.tieneControl) {
        //   const requestUrlControlAvance = `${url}control_avance`;
        //   const response = await axios.get(requestUrlControlAvance, {
        //     headers: headers(),
        //   });
        //   const { data: { data: controlAvanceArray } } = response;
        //   console.log({msg:"Este es el id de la actividad",idActividad})
        //   const idControlAvance = controlAvanceArray.find(
        //     (controlAvance) => controlAvance.actividad_id === idActividad
        //     );
        //     console.log({msg:"Probando un id",controlAvanceArray})
        //   data.idControlAvance = idControlAvance.id;
        // }
        console.log({msg: "Data desde service",data})
        return data;
        
      } catch (error) {
        console.log({msg:"Estoy en el error",error})
        return errorHandler(error);
      }
    },
    [errorHandler]
  );

  return {
    createActividad,
    updateActividad,
    getActividadById,
    getAllActividades,
    getStatusFromActividades,
    deleteActividad,
    getActividadesDosimetriaByTipo,
    recibirActividadesDosimetria,
    entregarActividadesDosimetria,
    getActividadesByDate,
    getTiposYcantidadesDeActividadById,
  };
};

export default useCCPlanificacionService;
