import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useContratoService from '../../services/administracion/useContratoService'
import { addRow, findRow } from '../../utils/rows'
import { getFilenameFromContentDisposition } from '../../utils/download'
import useIsLoged from '../utils/useIsLoged'

const useContratoPage = () => {
    const { createContrato, getContratos, deleteContrato, 
        downloadContratoById } = useContratoService()
    const history = useHistory()
    useIsLoged()
    const {id} = useParams()
    
    const [error, setError] = useState(null)
    const [rows, setRows] = useState([])
    const [nombre, setNombre] = useState("")
    const [archivo, setArchivo] = useState(null)
    
    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openDelete, setOpenDelete] = useState(false)
    const [contratoToDelete, setContratoToDelete] = useState(null)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)
    
    const [loading, setLoading] = useState(0)

    //Estado para ProgressBar para subir/descargar archivos
    const [progress, setProgress] = useState(-1)

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const handleOnProgress = (loaded, total) => {
        const progress = (loaded/total)*100
        setProgress(progress)
    }

    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleClickOpenDelete = (id) => {
        setContratoToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const checkForErrors = () => {
        if(archivo === null)
        {
            return true
        }
        return false
    }

    const handleOnChangeContrato = (file) => {
        setArchivo(file[0])
    }

    const onCreateContrato = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', "Debe elegir un archivo para subir.")
            return
        }
        let formData = new FormData()
        formData.append("nombre", archivo.name)
        formData.append("contrato", archivo)
        setNombre(`Subiendo archivo: ${archivo.name}`)
        // console.log(archivo)
        const result = await createContrato(formData, handleOnProgress)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            handleGetContratos()
            handleOpenAlert('success', `El contrato "${nombre}" se creo correctamente.`)
        }
        cancel()
    }

    const onDeleteContrato = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteContrato(contratoToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombre} = result
            //deleteRow(id, setRows)
            handleGetContratos()
            handleOpenAlert('success', `El contrato "${nombre}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    const handleDownload = async (id) => {
        setNombre("Descargando archivo")
        const contrato = await downloadContratoById(id, handleOnProgress)
        // console.log(contrato)
        if (contrato.error){
            handleOpenAlert('error', contrato.error)
        }
        else{
            const url = window.URL.createObjectURL(new Blob([contrato.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', getFilenameFromContentDisposition
                (contrato.headers['content-disposition']))
            link.target = "_blank"
            link.click()
            window.URL.revokeObjectURL(url)
        }
        cancel()
    }

    const handleGetContratos = useCallback(async () => {
        startLoading()
        const contratos = await getContratos(page)
        if (contratos.error){
            setError(contratos.error)
        }
        else{
            // console.log(contratos)
            setRows([])
            setNumPages(contratos.last_page)
            contratos.data.forEach(({id, nombre}) => {
                // console.log(id)
                const key = id
                const cell = [nombre]
                addRow(key, cell, setRows)
            })
            if(page > contratos.last_page){
                setPage(contratos.last_page)
                history.push(`${window.location.pathname}?page=${contratos.last_page}`)
            }
        }
        stopLoading()
    }, [getContratos, history, page])

    const editClick = (id) => {
        history.push(`/admin/contrato/${id}?page=${page}`)
    }

    const editCancel = () => {
        history.push(`/admin/contrato?page=${page}`)
        setNombre("")
        cancel()
    }

    const cancel = () => {
        setNombre("")
        setArchivo(null)
        setProgress(-1)
    }

    const data = {
        rows,
        nombre,
        id,
        openDelete,
        contratoToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        progress
    }

    const actions = {
        onDeleteContrato,
        onCreateContrato,
        handleDownload,
        editClick,
        setNombre,
        setArchivo,
        cancel,
        editCancel,
        handleClickOpenDelete,
        handleCloseDelete,
        findRow,
        setContratoToDelete,
        setAlertOpen,
        handlePageChange,
        handleOnChangeContrato
    }

    //useEffect para cargar datos en la tabla
    useEffect(() => {
        handleGetContratos()
    }, [handleGetContratos])
    return {error, data, actions}
}

export default useContratoPage