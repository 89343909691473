import React from 'react'
import PropTypes from 'prop-types'
import ReactBarcode from 'react-barcode'

const Barcode = ({codigo}) => {
    return (
        <ReactBarcode value={codigo}/>
    )
}

Barcode.propTypes = {
    codigo: PropTypes.string.isRequired,
}

export default Barcode
