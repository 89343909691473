import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { addRow, findRow, updateRow } from '../../utils/rows'
import useIsLoged from '../utils/useIsLoged'
import { allLetterAndSpace, isDUI, isNIT, isPhone } from '../../utils/validations'
import { mensajeCamposNoValidos } from '../../utils/messages'
import useSolicitudInscripcionService from '../../services/inscripcion/useSolicitudInscripcionService'
import { paisesForSelect } from '../../utils/paises'
import { getFilenameFromContentDisposition } from '../../utils/download'
import { stringDateToSeconds, getNowInSeconds } from '../../utils/time'
import useSolicitudCotizacionService from "../../services/solicitudCotizacion";

const useDatosPersonalInstitucionPage = () => {
    const history = useHistory()
    const { createPersonal, deletePersonal, getAreasPorInstalacionPorNombre,
        getCargosByNombre, getAllResponsabilidades, getInstalacionesPorNombre,
        getPersonalById, getPersonalOfInstalacion, updatePersonal, getArchivoPersonal,
        createPersonalEdit, updatePersonalEdit, updateStatusPersonalEdit } = useSolicitudInscripcionService()
    const { comprobarEstadoEdicionSolicitud } = useSolicitudCotizacionService()
    useIsLoged()
    const {id} = useParams()
    //Estado para las filas de la tabla
    const [rows, setRows] = useState([])
    //Estados para controlar los inputs
    //(errorInput y helperInput sirven para mostrar un error en el input)
    //Estados personal
    const [instalacion, setInstalacion] = useState(null)
    const [nombres, setNombres] = useState('')
    const [errorNombres, setErrorNombres] = useState(false)
    const [helperNombres, setHelperNombres] = useState('')
    const [apellidos, setApellidos] = useState('')
    const [errorApellidos, setErrorApellidos] = useState(false)
    const [helperApellidos, setHelperApellidos] = useState('')
    const [sexo, setSexo] = useState(false)
    const [grado, setGrado] = useState('')
    const [errorGrado, setErrorGrado] = useState(false)
    const [helperGrado, setHelperGrado] = useState('')
    const [cargo, setCargo] = useState(null)
    const [cargos, setCargos] = useState([])
    const [nacimiento, setNacimiento] = useState('')
    const [errorNacimiento, setErrorNacimiento] = useState(false)
    const [helperNacimiento, setHelperNacimiento] = useState('')
    const [nacionalidad, setNacionalidad] = useState({label: 'El Salvador', value: 'El Salvador'})
    const listaPaises = paisesForSelect()
    const [nacionalidades, setNacionalidades] = useState(listaPaises)
    const [identificarPor, setIdentificarPor] = useState('dui')
    const [identificador, setIdentificador] = useState('')
    const [errorIdentificador, setErrorIdentificador] = useState(false)
    const [helperIdentificador, setHelperIdentificador] = useState('')
    const [telefono, setTelefono] = useState('')
    const [errorTelefono, setErrorTelefono] = useState(false)
    const [helperTelefono, setHelperTelefono] = useState('')
    const [celular, setCelular] = useState('')
    const [errorCelular, setErrorCelular] = useState(false)
    const [helperCelular, setHelperCelular] = useState('')
    //Estado para guardar el archivo a enviar
    const [archivoDocumento, setArchivoDocumento] = useState(null)
    //Obj para hacer el fileInput controlado (mostrar chip con nombre de archivo)
    const [objArchivo, setObjArchivo] = useState(null)
    //Estado para controlar el modal para mostrar el pdfViewer
    const [openFileModal, setOpenFileModal] = useState(false)
    const handleCloseFileModal = () => {
        setOpenFileModal(false)
    }
    const [filePageNumber, setFilePageNumber] = useState(1)
    const [fileNumPages, setFileNumPages] = useState(null)
    function onDocumentLoadSuccess({ numPages }) {
        setFileNumPages(numPages);
    }
    const [responsabilidades, setResponsabilidades] = useState([])
    const [allResponsabilidades, setAllResponsabilidades] = useState([])
    const [areasConsulta, setAreasConsulta] = useState([])
    // Estado que almacena un valor booleano y servira como variable de control para
    // el proceso de edicion
    const [idSolicitud, setIdSolicitud] = useState(false)
    // Almacena el booleano de aprobado, como control para el proceso de edicion
    const [aprobado, setAprobado] = useState('')

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //Estados para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [personalToDelete, setPersonalToDelete] = useState(null)

    //Estado para progress, el setLoading siempre debe ser llamado en funciones
    //asincronas para funcionar correctamente
    const [loading, setLoading] = useState(0)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)
    // console.log("Numero de pagina", page)
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    //Controladores alerta
    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    //Controladores mensajes confirmacion
    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setPersonalToDelete(id)
        setOpenDelete(true)
    }

    const handleCloseDelete = () => {
        setOpenDelete(false)

    }

    //Funcion para comprobar errores en los campos
    const checkForErrors = () => {
        if (errorNombres || nombres === "" || errorCelular || errorTelefono || !instalacion)
        {
            // console.log("Se encontraron errores.")
            // console.log('errorNombres', errorNombres)
            // console.log('nombres', nombres)
            // console.log('errorCelular', errorCelular)
            // console.log('errorTelefono', errorTelefono)
            // console.log('instalacion', instalacion)
            return true
        }
        return false
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    //Funciones para controlar cambios en inputs
    const onChangeNombres = (text)  =>
    {
        setNombres(text)
        if(!allLetterAndSpace(text)){
            setErrorNombres(true)
            setHelperNombres("Los nombres solo deben consistir de letras.")
        }else{
            setErrorNombres(false)
            setHelperNombres("")
        }
    }

    const onChangeApellidos = (text)  =>
    {
        setApellidos(text)
        if(!allLetterAndSpace(text)){
            setErrorApellidos(true)
            setHelperApellidos("Los apellidos solo deben consistir de letras.")
        }else{
            setErrorApellidos(false)
            setHelperApellidos("")
        }
    }

    const onChangeIdentificador = (text)  =>
    {
        setIdentificador(text)
        switch(identificarPor){
            case 'dui':
                if(!isDUI(text)){
                    setErrorIdentificador(true)
                    setHelperIdentificador("Debe ingresar un numero de dui valido. Ejemplo: 99999999-9.")
                }else{
                    setErrorIdentificador(false)
                    setHelperIdentificador("")
                }
                break;
            case 'licencia':
                if(!isNIT(text)){
                    setErrorIdentificador(true)
                    setHelperIdentificador("Debe ingresar un numero de licencia valido. Ejemplo: 9999-999999-999-9.")
                }else{
                    setErrorIdentificador(false)
                    setHelperIdentificador("")
                }
                break;
            default:
                setErrorIdentificador(false)
                setHelperIdentificador("")
        }
    }

    const onChangeIdentificarPor = (text)  =>
    {
        setIdentificarPor(text)
        switch(text){
            case 'dui':
                if(!isDUI(identificador)){
                    setErrorIdentificador(true)
                    setHelperIdentificador("Debe ingresar un numero de dui valido. Ejemplo: 99999999-9.")
                }else{
                    setErrorIdentificador(false)
                    setHelperIdentificador("")
                }
                break;
            case 'licencia':
                if(!isNIT(identificador)){
                    setErrorIdentificador(true)
                    setHelperIdentificador("Debe ingresar un numero de licencia valido. Ejemplo: 9999-999999-999-9.")
                }else{
                    setErrorIdentificador(false)
                    setHelperIdentificador("")
                }
                break;
            default:
                setErrorIdentificador(false)
                setHelperIdentificador("")
        }
    }

    const onChangeGrado = (text)  =>
    {
        setGrado(text)
        if(!allLetterAndSpace(text)){
            setErrorGrado(true)
            setHelperGrado("El grado academico solo debe consistir de letras.")
        }else{
            setErrorGrado(false)
            setHelperGrado("")
        }
    }

    const onChangeTelefono = (text)=>
    {
        setTelefono(text)
        if(!isPhone(text) && text !== ""){
            setErrorTelefono(true)
            setHelperTelefono("Debe ingresar un telefono valido valido.")
        }else{
            setErrorTelefono(false)
            setHelperTelefono("")
        }
    }

    const onChangeCelular = (text)=>
    {
        setCelular(text)
        if(!isPhone(text) && text !== ""){
            setErrorCelular(true)
            setHelperCelular("Debe ingresar un telefono valido valido.")
        }else{
            setErrorCelular(false)
            setHelperCelular("")
        }
    }

    const onChangeNacimiento = (date) => {
        const dateInSeconds = stringDateToSeconds(date)
        const todayInSeconds = getNowInSeconds()
        const TenYearInSeconds = 10*365*24*60*60
        const TenYearsBackFromNowInSeconds = todayInSeconds - TenYearInSeconds
        // console.log('Fecha en segundos', dateInSeconds)
        // console.log('10 años atras', TenYearsBackFromNowInSeconds)
        setNacimiento(date)
        if(dateInSeconds > TenYearsBackFromNowInSeconds){
            setErrorNacimiento(true)
            setHelperNacimiento('El personal debe tener una fecha de nacimiento de al menos hace 10 años.')
        }else{
            setErrorNacimiento(false)
            setHelperNacimiento('')
        }
    }

    const handleOnChangeArchivo = (file) => {
        setArchivoDocumento(file[0])
        setObjArchivo([{ name: file[0].name, deleteFile: () => {
            setObjArchivo(null)
            setArchivoDocumento(null)
        },
        onClickChip: () => {setOpenFileModal(true)}
        }])
    }

    const handleOnChangeCheckBox = (idResponsabilidad) => {
        if(responsabilidades.includes(idResponsabilidad)){
            setResponsabilidades((responsabilidades)=>{
                let newResponsabilidades = responsabilidades
                const index = newResponsabilidades.indexOf(idResponsabilidad)
                if (index > -1) {
                    newResponsabilidades.splice(index, 1)
                }
                return [...newResponsabilidades]
            })
        }else{
            setResponsabilidades((responsabilidades)=>{
                return [...responsabilidades, idResponsabilidad]
            })
        }
    }

    //Funcion para manejar la creacion de un personal
    const onCreatePersonal = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let newPersonal = new FormData()
        newPersonal.append("nombres", nombres)
        newPersonal.append("apellidos", apellidos)
        newPersonal.append("sexo", sexo)
        newPersonal.append("grado_academico", grado)
        newPersonal.append("cargo_id", cargo && cargo.value)
        newPersonal.append("instalacion_id", instalacion && instalacion.value)
        newPersonal.append("fecha_nacimiento", nacimiento)
        if(telefono){
            newPersonal.append("telefono", telefono)
        }
        if(celular){
            newPersonal.append("telefono_celular", celular)
        }
        newPersonal.append("nacionalidad", nacionalidad && nacionalidad.value)
        newPersonal.append("identificar_por", identificarPor)
        newPersonal.append("identificador", identificador)
        newPersonal.append("archivo_documento_identidad", archivoDocumento)

        areasConsulta && areasConsulta.forEach((area) => {
            newPersonal.append("areas[]", area.value)
        })
        responsabilidades && responsabilidades.forEach((responsabilidad) => {
            newPersonal.append("responsabilidades[]", responsabilidad)
        })
        startLoading()
        const result = idSolicitud ? await createPersonalEdit(newPersonal) : await createPersonal(newPersonal)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            handleOpenAlert('success', `El personal "${nombres} ${apellidos}" se creo correctamente.`)
            cancel()
            handleGetPersonalOfInstalacion()
        }
        stopLoading()
    }

    //Funcion para manejar una actualizacion en los datos de un personal
    const onUpdatePersonal = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        let personalActualizado = new FormData()
        personalActualizado.append("nombres", nombres)
        personalActualizado.append("apellidos", apellidos)
        personalActualizado.append("sexo", sexo)
        personalActualizado.append("grado_academico", grado)
        personalActualizado.append("cargo_id", cargo && cargo.value)
        personalActualizado.append("fecha_nacimiento", nacimiento)
        if(telefono){
            personalActualizado.append("telefono", telefono)
        }
        if(celular){
            personalActualizado.append("telefono_celular", celular)
        }
        personalActualizado.append("nacionalidad", nacionalidad && nacionalidad.value)
        personalActualizado.append("identificar_por", identificarPor)
        personalActualizado.append("identificador", identificador)
        personalActualizado.append("archivo_documento_identidad", archivoDocumento)
        personalActualizado.append("_method", "PUT")
        areasConsulta && areasConsulta.forEach((area) => {
            personalActualizado.append("areas[]", area && area.value)
        })
        responsabilidades && responsabilidades.forEach((responsabilidad) => {
            personalActualizado.append("responsabilidades[]", responsabilidad)
        })
        startLoading()
        // console.log('ppppp', personalActualizado)
        const result = aprobado? await updatePersonalEdit(id, personalActualizado) :
            await updatePersonal(id, personalActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            // console.log('Se edito', result)
            const {id, nombres, apellidos, cargo, dui, licencia, pasaporte}
                = result
            const newCells = [nombres, apellidos, cargo.nombre, dui ?
                `DUI: ${dui}` : licencia ?
                `Licencia de conducion: ${licencia}` : pasaporte ?
                `Pasaporte: ${pasaporte}` :
                "No tiene ningun identificador."]
            // console.log("newCells", newCells)
            updateRow(id, newCells, setRows)
            handleOpenAlert('success', `Los datos del personal "${nombres} ${apellidos}" se actualizaron correctamente.`)
            editCancel()
            await handleGetPersonalOfInstalacion()
        }
        stopLoading()
    }

    //Funcion para manejar el boton eliminar personal
    const onDeletePersonal = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deletePersonal(personalToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {nombres, apellidos} = result
            handleGetPersonalOfInstalacion()
            editCancel()
            handleOpenAlert('success', `El personal "${nombres} ${apellidos}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    const handleGetFile = useCallback(async () => {
        const file = await getArchivoPersonal(id)
        if (file.error){
            console.log('Error al cargar los datos', file.error)
        }
        else{
            const blob = new Blob([file.data])
            setArchivoDocumento(blob)
            setObjArchivo([{ name: getFilenameFromContentDisposition
                (file.headers['content-disposition']), deleteFile: () => {
                setObjArchivo(null)
                setArchivoDocumento(null)
            },
            onClickChip: () => {setOpenFileModal(true)}
            }])
        }
    }, [getArchivoPersonal, id])

    //Funcion para manejar la consulta de los datos de un personal
    const getPersonal = useCallback(async (id) => {
        startLoading()
        const personal = await getPersonalById(id)
        if (personal.error){
            handleOpenAlert('error', personal.error)
        }
        else{
            // console.log('Personal a editar', personal)
            //Datos personal
            setInstalacion({label: personal.instalacion.nombre, value: personal.instalacion.id})
            setNombres(personal.nombres)
            setApellidos(personal.apellidos)
            setSexo(personal.sexo)
            //Dependiendo de que campo exista sera el valor de identificarPor e identificador
            setIdentificarPor( personal.dui ? 'dui' : personal.licencia ? 'licencia' : 'pasaporte' )
            setIdentificador( personal.dui ? personal.dui : personal.licencia ? personal.licencia : personal.pasaporte )
            setGrado(personal.grado_academico)
            setNacionalidad({label: personal.nacionalidad, value: personal.nacionalidad})
            setNacimiento(personal.fecha_nacimiento)
            setCelular(personal.telefono_celular)
            setTelefono(personal.telefono)
            setCargo({label: personal.cargo.nombre, value: personal.cargo.id})
            await handleGetFile()
            let responsabilidadesPersonal = []
            personal.responsabilidades.forEach((respo)=>{
                responsabilidadesPersonal = [...responsabilidadesPersonal, respo.responsabilidad_id]
            })
            setResponsabilidades(responsabilidadesPersonal)
            let areasPersonal = []
            personal.consulta_area.forEach((areaPersonal) => {
                areasPersonal = [...areasPersonal, {label: areaPersonal.area.nombre, value: areaPersonal.area.id}]
            })
            setAreasConsulta(areasPersonal)
            setAprobado(personal.aprobado)
        }
        stopLoading()
    }, [getPersonalById, handleGetFile])

    const handleFilePageChange = (event, value) => {
        setFilePageNumber(value)
    }


    const handleGetInstalaciones = async (value) => {
        const instalaciones = await getInstalacionesPorNombre(value)
        if(instalaciones.error){
            return []
        }
        else{
            let options = []
            instalaciones.forEach(({id, nombre, disponible}) => {
                const label = nombre
                const value = id
                if (disponible) {
                    const instalacionComboBox = {
                        label,
                        value: value.toString()
                    }
                    options = [...options, instalacionComboBox]
                }
            })
            return options
        }
    }

    const handleGetCargos = async (value) => {
        const cargos = await getCargosByNombre(value)
        if(cargos.error){
            return []
        }
        else{
            let options = []
            cargos.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const cargoComboBox = {
                    label,
                    value: value.toString()
                }
                options = [...options, cargoComboBox]
            })
            return options
        }
    }

    const handleGetAreas = useCallback(async (value) => {
        const areas = await getAreasPorInstalacionPorNombre(instalacion.value, value)
        if(areas.error){
            return []
        }
        else{
            let options = []
            areas.forEach(({id, nombre, disponible}) => {
                const label = nombre
                const value = id
                if (disponible) {
                    const areaComboBox = {
                        label,
                        value: value.toString()
                    }
                    options = [...options, areaComboBox]
                }
            })
            return options
        }
    }, [getAreasPorInstalacionPorNombre, instalacion])

    const handleGetPersonalOfInstalacion = useCallback(async () => {
        startLoading()
        const personal = await getPersonalOfInstalacion(instalacion.value, page)
        if (personal.error){
            handleOpenAlert('error', personal.error)
        }
        else{
            setRows([])
            // console.log("Personal de la instalacion", personal)
            setNumPages(personal.last_page)
            personal?.data?.forEach(({id, nombres, apellidos, cargo, dui, licencia, pasaporte,
                disponible: estadoDisponible}) => {
                if (estadoDisponible === true) {
                    const key = id
                    const cell = [nombres, apellidos, cargo?.nombre, dui ?
                        `DUI: ${dui}` : licencia ?
                            `Licencia de conduccion: ${licencia}` : pasaporte ?
                                `Pasaporte: ${pasaporte}` :
                                "No tiene ningun identificador."]
                    const disponible = estadoDisponible
                    addRow(key, cell, setRows, disponible)
                }
            })
            if(page > personal.last_page){
                setPage(personal.last_page)
                history.push(`${window.location.pathname}?page=${personal.last_page}`)
            }
        }
        stopLoading()
    }, [getPersonalOfInstalacion, history, instalacion, page])

    const handleGetAllResponsabilidades = useCallback(async () => {
        startLoading()
        const responsabilidades = await getAllResponsabilidades()
        if (responsabilidades.error){
            handleOpenAlert('error', responsabilidades.error)
        }
        else{
            setAllResponsabilidades(responsabilidades)
        }
        stopLoading()
    }, [getAllResponsabilidades])

    //Funcion que activa y desactiva las areas
    const handleChangeEstadoDisponible = async(key) => {
        startLoading()
        const personal = await getPersonalById(personalToDelete)
        if (personal.error){
            handleOpenAlert('error', personal.error)
        }
        else{
            const activarDesactivarPersonal = personal.aprobado === true ?
                await updateStatusPersonalEdit(key.target.id) :
                await deletePersonal(key.target.id)
            if (activarDesactivarPersonal.error){
                handleOpenAlert('error', activarDesactivarPersonal.error)
            } else {
                handleOpenAlert('success', "Se cambió el estado del personal")
            }
        }
        editCancel()
        await handleGetPersonalOfInstalacion()
        stopLoading()
    }

    //Funcion maneja boton editar
    const editClick = (id) => {
        cancel()
        history.push(`/cliente/personal/${id}?page=${page}`)
    }

    //Funcion maneja boton cancelar edicion
    const editCancel = () => {
        cancel()
        history.push(`/cliente/personal?page=${page}`)
    }

    //Funcion cancelar (regresa los estados al valor inicial)
    const cancel = () => {
        setNombres("")
        setErrorNombres(false)
        setHelperNombres("")
        setApellidos("")
        setErrorApellidos(false)
        setHelperApellidos("")
        setSexo(false)
        setIdentificarPor('dui')
        setIdentificador("")
        setGrado('')
        setNacionalidad({label: 'El Salvador', value: 'El Salvador'})
        setNacimiento('')
        setTelefono("")
        setErrorTelefono(false)
        setHelperTelefono("")
        setCelular("")
        setErrorCelular(false)
        setHelperCelular("")
        setCargo(null)
        setArchivoDocumento(null)
        setObjArchivo(null)
        setResponsabilidades([])
        setAreasConsulta([])
        setAprobado('')
    }

    //Objeto con los datos del custom hook que se usaran en la pagina
    const data = {
        rows,
        nacionalidades,
        nombres,
        errorNombres,
        helperNombres,
        apellidos,
        errorApellidos,
        helperApellidos,
        identificarPor,
        grado,
        errorGrado,
        helperGrado,
        nacimiento,
        errorNacimiento,
        helperNacimiento,
        telefono,
        errorTelefono,
        helperTelefono,
        sexo,
        identificador,
        errorIdentificador,
        helperIdentificador,
        nacionalidad,
        celular,
        errorCelular,
        helperCelular,
        cargo,
        cargos,
        archivoDocumento,
        areasConsulta,
        id,
        openEdit,
        openDelete,
        personalToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        instalacion,
        allResponsabilidades,
        responsabilidades,
        objArchivo,
        openFileModal,
        filePageNumber,
        fileNumPages,
        idSolicitud
    }

    //Objeto con las acciones que se usaran en la pagina
    const actions = {
        onCreatePersonal,
        onUpdatePersonal,
        onDeletePersonal,
        editClick,
        onChangeNombres,
        onChangeApellidos,
        onChangeGrado,
        onChangeIdentificador,
        onChangeTelefono,
        onChangeCelular,
        setCargo,
        setInstalacion,
        setSexo,
        onChangeIdentificarPor,
        setNacionalidad,
        onChangeNacimiento,
        setArchivoDocumento,
        setAreasConsulta,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setPersonalToDelete,
        setAlertOpen,
        handlePageChange,
        handleGetInstalaciones,
        handleGetCargos,
        handleGetAreas,
        handleOnChangeArchivo,
        handleOnChangeCheckBox,
        handleFilePageChange,
        handleCloseFileModal,
        onDocumentLoadSuccess,
        handleChangeEstadoDisponible
    }

    useEffect(() => {
        if(!id){
            cancel()
        }
    }, [id, instalacion])

    const loadData = useCallback(async () => {
        const edicion = await comprobarEstadoEdicionSolicitud()
        setIdSolicitud(edicion)
        if(instalacion){
            await handleGetPersonalOfInstalacion()
        }else{
            setRows([])
        }
        if(allResponsabilidades.length === 0){
            await handleGetAllResponsabilidades()
        }
    }, [allResponsabilidades, handleGetAllResponsabilidades,
        handleGetPersonalOfInstalacion, instalacion])

    //useEffect para cargar datos al editar
    useEffect(() => {
        if(id){
            cancel()
            getPersonal(id)
        }
        //Solo se ejecuta cuando cambia el valor de id o getArea
    }, [id, getPersonal])

    //useEffect para cargar datos en la tabla y los checkbuttons
    useEffect(() => {
        loadData()
        //Solo se ejecuta cuando cambian las funciones setRows, instalacion,
        //handleGetAreas, allResponsabilidades o handleGetAllResponsabilidades
    }, [loadData])

    return {data, actions}
}

export default useDatosPersonalInstitucionPage
