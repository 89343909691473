import React from 'react'
import FormHeader from './../../components/FormHeader'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import { FormUsuario } from '../administracion/UsuarioPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    button: {
        marginTop: '5em'
    }
}))

const DatosUsuarioPage = ({data, actions}) => {
    const classes = useStyles()

    return (
        <Grid container direction = "row" justifyContent = "center">
            <Grid item xs={12} sm= {5}>
                <Paper className = {classes.paper}>
                    <FormHeader title="Registro de inscripcion"
                        subtitle="Datos de usuario">
                        <FaRegUserCircle/>
                    </FormHeader>
                    <Grid container direction="row" justifyContent="center"
                        spacing = {2}>
                        <Grid container item xs = {10}>
                            <FormUsuario data = {data} actions = {actions} classes = {classes}/>
                            <Grid container item 
                                className = {classes.button}
                                direction="row"
                                justifyContent="center" spacing = {2}>
                                <Grid item xs={6} sm= {5}>
                                    <Button
                                        component={RouterLink}
                                        to={data.idSolicitud ? '/solicitudes_edicion' :'/'}
                                        variant = "outlined"
                                        text="Cancelar"/>
                                </Grid>
                                <Grid item xs={6} sm= {5}>
                                    <Button
                                        component={RouterLink}
                                        to={data.idSolicitud ? '/inscripcion/2/edit/'+ data.idSolicitud : '/inscripcion/2/'}
                                        variant = "contained"
                                        text="Siguiente"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default DatosUsuarioPage
