import axios from "axios";
// import url from "./utils/url";
import headers from "../utils/headers";
// import useErrorHandler from "./utils/useErrorHandler";
import { useCallback } from "react";
import useErrorHandler from "../utils/useErrorHandler";
import url from "../utils/url";

const solicitudUrl = `${url}solicitudes`;

const useSolicitudesRecibidasCCService = () => {
  const { errorHandler } = useErrorHandler();

  const getSolRecibidasCC = useCallback(
    async (anio, estado, paso, isntitucion, page) => {
      const parametros = [];

      if (page) {
        parametros.push(`page=${page}`);
      }
      if (isntitucion) {
        parametros.push(`cliente=${isntitucion}`);
      }
      if (anio) {
        parametros.push(`anio=${anio}`);
      }

      if (estado) {
        parametros.push(`estado=${estado}`);
      }

      if (paso) {
        parametros.push(`paso=${paso}`);
      }

      let parametrosString =
        parametros.length > 0 ? `?${parametros.join("&")}` : "";

      const requestUrl = `${solicitudUrl}${parametrosString}`;

      try {
        const response = await axios.get(requestUrl, { headers: headers() });
        const { data } = response;
        return data;
      } catch (error) {
        return errorHandler(error);
      }
    },
    [errorHandler]
  );




  return { getSolRecibidasCC };
};

export default useSolicitudesRecibidasCCService;
