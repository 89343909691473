import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import MaterialGrid from '@material-ui/core/Grid'
import {IconContext} from 'react-icons/lib'
import AppColors from './../../utils/colors'
import { withStyles } from "@material-ui/core/styles";

const BlueTextTypography = withStyles({
    root: {
      color: AppColors.blue.main,
    }
})(Typography);

const Grid = withStyles({
    root: {
        marginBottom: '1em'
    }
})(MaterialGrid);

const FormHeader = ({title, subtitle, children}) => {
    return (
        <Grid 
            container
            direction = "column"
            justifyContent = "center"
            alignItems = "center"
            >
            <BlueTextTypography variant = "h3" align="center">
                {title}
            </BlueTextTypography>
            <BlueTextTypography variant = "h5" align="center">
                {subtitle}
            </BlueTextTypography>
            <IconContext.Provider value = {{
                size: '7em',
                color: AppColors.blue.main
            }} >
                {children}
            </IconContext.Provider>
        </Grid>
    )
}

FormHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.node
}

export default FormHeader
