import React, { useState } from "react";
import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ComboBox from "../../components/ComboBox";

const MunicipioSelector = ({
  departamentos,
  onChangeIdDepartamento,
  onChangeIdMunicipio,
  classes,
  isIdSelected,
  idDepartamento,
  idMunicipio,
  cleanCombobox,
}) => {
  const [selectedDepartamentoId, setSelectedDepartamentoId] = useState(null);
  const [selectedMunicipioId, setSelectedMunicipioId] = useState(null);
  const [listaDeMunicipios, setlistaDeMunicipios] = useState([]);

  const obtenerMunicipiosPorIdDepartamento = (idDepartamento) => {
    const departamento = departamentos.find((dep) => dep.id === idDepartamento);
    if (departamento) {
      const listaMunicipiosFormateados = departamento.municipios.map(
        (municipio) => ({
          label: municipio.nombre,
          value: municipio.id,
        })
      );
      setlistaDeMunicipios(listaMunicipiosFormateados);
    } else {
      setlistaDeMunicipios([]);
    }
  };

  const handleDepartamentoChange = (value) => {
    const departamentoId = parseInt(value);
    setSelectedDepartamentoId(departamentoId);
    setSelectedMunicipioId(null);
    obtenerMunicipiosPorIdDepartamento(departamentoId);
  };

  const handleMunicipioChange = (value) => {
    const municipioId = parseInt(value);
    setSelectedMunicipioId(municipioId);
  };

  useEffect(() => {
    if (isIdSelected) {
      setSelectedDepartamentoId(idDepartamento);
      obtenerMunicipiosPorIdDepartamento(idDepartamento);
      setSelectedMunicipioId(idMunicipio);
    }
  }, [isIdSelected, idDepartamento, idMunicipio]);

  useEffect(() => {
    onChangeIdDepartamento(selectedDepartamentoId);
    onChangeIdMunicipio(selectedMunicipioId);
  }, [selectedMunicipioId]);

  const selectedDepartamento = departamentos.find(
    (departamento) => departamento.id === selectedDepartamentoId
  );

  useEffect(() => {
    setSelectedDepartamentoId(null);
    setSelectedMunicipioId(null);
  }, [cleanCombobox]);
  
  return (
    <div>
      <Grid container>
        <Grid className={classes.input} item xs={6} sm={6}>
          <ComboBox
            label="Departamento"
            list={departamentos.map((dep) => ({
              label: dep.nombre,
              value: dep.id,
            }))}
            value={selectedDepartamentoId || ""}
            onChange={handleDepartamentoChange}
            required={true}
          />
        </Grid>

        {selectedDepartamento && (
          <Grid className={classes.input} item xs={6} sm={6}>
            <ComboBox
              label="Municipio"
              list={listaDeMunicipios}
              value={selectedMunicipioId || ""}
              onChange={handleMunicipioChange}
              required={true}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MunicipioSelector;
// const MunicipioSelector = ({
//   departamentos,
//   onChangeIdDepartamento,
//   onChangeIdMunicipio,
//   classes
// }) => {
//   const [selectedDepartamentoId, setSelectedDepartamentoId] = useState(null);
//   const [selectedMunicipioId, setSelectedMunicipioId] = useState(null);
//   const [listaDeMunicipios, setlistaDeMunicipios] = useState([]);

//   const handleDepartamentoChange = (value) => {
//     const departamentoId = parseInt(value);
//     setSelectedDepartamentoId(departamentoId);
//     setSelectedMunicipioId(null);
//     obtenerMunicipiosPorIdDepartamento(departamentoId);
//   };

//   const handleMunicipioChange = (value) => {
//     const municipioId = parseInt(value);
//     setSelectedMunicipioId(municipioId);
//   };

//   useEffect(() => {
//     onChangeIdDepartamento(selectedDepartamentoId);
//     onChangeIdMunicipio(selectedMunicipioId);
//   }, [selectedMunicipioId]);

//   const obtenerMunicipiosPorIdDepartamento = (idDepartamento) => {
//     const departamento = departamentos.find((dep) => dep.id === idDepartamento);
//     if (departamento) {
//       const listaMunicipiosFormateados = departamento.municipios.map(
//         (municipio) => ({
//           label: municipio.nombre,
//           value: municipio.id,
//         })
//       );
//       setlistaDeMunicipios(listaMunicipiosFormateados);
//     } else {
//       setlistaDeMunicipios([]);
//     }
//   };

//   const selectedDepartamento = departamentos.find(
//     (departamento) => departamento.id === selectedDepartamentoId
//   );

//   return (
//     <div>
//     <Grid container>
//       <Grid className={classes.input} item xs={6} sm={6}>
//         <ComboBox
//           label="Departamento"
//           list={departamentos.map((dep) => ({
//             label: dep.nombre,
//             value: dep.id,
//           }))}
//           value={selectedDepartamentoId || ""}
//           onChange={handleDepartamentoChange}
//           required={true}
//         />
//       </Grid>

//       {selectedDepartamento && (
//           <Grid className={classes.input} item xs={6} sm={6}>
//             <ComboBox
//               label="Municipio"
//               list={listaDeMunicipios}
//               value={selectedMunicipioId || ""}
//               onChange={handleMunicipioChange}
//               required={true}
//             />
//           </Grid>

//       )}
//       </Grid>
//     </div>
//   );
// };

// export default MunicipioSelector;
