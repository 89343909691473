import AppFrame from "../../components/AppFrame";
import { Link as RouterLink } from "react-router-dom";
import Button from "../../components/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../components/DataTable";
import Alert from "../../components/Alert/Alert";
import FormHeader from "../../components/FormHeader";
import Typography from "@material-ui/core/Typography";
import Progress from "../../components/Progress/Progress";
import DatePickerRange from "../../components/DatePickerRange/DatePickerRange";
import Pagination from "../../components/Pagination";
import { RiGroupLine, RiRestartLine } from "react-icons/ri";
import useSolicitudesPage from "../../hooks/cotizacion/useSolicitudesPage";
import useSolicitudesRecibidasCCPage from "../../hooks/solicitudes_recibidas/useSolicitudesRecibidas";
import Dialog from "../../components/Dialog/Dialog";
import GuardarControlAvanceCC from "./guardarControlAvance";
import ReactSelect from "../../components/ReactSelect";
import Input from "../../components/Input/Input";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2em",
    paddingBottom: "2em",
    paddingLeft: "10em",
    paddingRight: "10em"
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

const encabezado = ["Institucion", "Estado", "Paso"];

const SolicitudesPage = () => {
  const classes = useStyles();
  const { data, actions } = useSolicitudesRecibidasCCPage();
  const {
    rows,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    numPages,
    page,
    value,
    idRol,
    MdSearch,
    openDialog,
    selectedEstado,
    selectedPasoToFilter,
    selectedInstituciones,
    setErrorAnio,
    setHelperAnio,
    anio,
  } = data;
  const {
    setAlertOpen,
    handleSelectDate,
    handlePageChange,
    botonesDataTable,
    handleLimpiarFechas,
    handleClickOpenDialog,
    handleClickCloseDialog,
    onUpdateControlAvanceCC,
    loadEstados,
    handleChangeEstado,
    loadPasosToFilter,
    handleChangePasoToFilter,
    loadInstituciones,
    handleChangeInstituciones,
    onChangeAnio,
  } = actions;

  return (
    <AppFrame loged={true}>
      <Progress open={loading} setOpen={setAlertOpen} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <Paper className={classes.paper}>
            {/* Alerta */}
            <Alert
              open={alertOpen}
              setOpen={setAlertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
            {/* Titulo */}
            <FormHeader
              title="Solicitudes recibidas"
              subtitle="Datos Requeridos *"
            >
              <RiGroupLine />
            </FormHeader>
            
            {/* Combobox Estado */}
            <Grid container className={classes.input} item xs={12}>
              <Grid item xs={12}>
                <Grid item xs={12}>
              <ReactSelect
                placeholder="Seleccione un estado"
                label="Estado*"
                asyncFunc={loadEstados}
                onChange={handleChangeEstado}
                value={selectedEstado}
                isClearable={true}
                components={selectedEstado ? { Menu: () => null } : undefined}
              />
            </Grid>
            {/* Combobox Paso */}
            <Grid className={classes.input} item xs={12}>
              <ReactSelect
                placeholder="Seleccione un paso"
                label="Paso*"
                asyncFunc={loadPasosToFilter}
                onChange={handleChangePasoToFilter}
                value={selectedPasoToFilter}
                isClearable={true}
                components={
                  selectedPasoToFilter ? { Menu: () => null } : undefined
                }
              />
            </Grid>
            {/* Combobox Instituciones */}
            <Grid className={classes.input} item xs={12}>
              <ReactSelect
                placeholder="Seleccione la institucion"
                label="Institucion*"
                asyncFunc={loadInstituciones}
                onChange={handleChangeInstituciones}
                value={selectedInstituciones}
                isClearable={true}
                components={
                  selectedInstituciones ? { Menu: () => null } : undefined
                }
              />
            </Grid>
            {/* Input Año */}
            <Grid item xs={12}>
              <Input
                type="number"
                label="Año"
                placeholder="Ingrese el año a consultar"
                value={anio}
                onChange={onChangeAnio}
                required={true}
              />
            </Grid>
            </Grid>
            </Grid>
            {/* DataTable */}
            <Grid className={classes.input} item xs={12}>
              <DataTable
                headers={encabezado}
                rows={rows}
                customBtn={[
                  {
                    func: handleClickOpenDialog,
                    icon: MdSearch,
                    tooltip: "Ver solicitud",
                  },
                ]}
              />
            </Grid>
            {/* Paginación */}
            <Pagination
              page={Number(page)}
              numPages={Number(numPages)}
              onChangePage={handlePageChange}
            />
          </Paper>
        </Grid>
      </Grid>
      {/* Modal para guardar */}
      <Dialog
        open={openDialog}
        handleClose={handleClickCloseDialog}
        handleAccept={onUpdateControlAvanceCC}
        title={"Guardar control de avance"}
        content={<GuardarControlAvanceCC data={data} actions={actions} />}
      />
    </AppFrame>
  );
};

export default SolicitudesPage;