import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const resumenUrl = `${url}resumen`

const useResumenService = () => {
    const { errorHandler } = useErrorHandler()
    
    const getInstitucion = useCallback(
        async () => {
            const requestUrl = `${resumenUrl}/institucion`
            
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getInstitucionById = useCallback(
        async (institucion) => {
            const requestUrl = `${resumenUrl}/institucion/${institucion}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getInstalaciones = useCallback(
        async () => {
            const requestUrl = `${resumenUrl}/institucion/instalaciones`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getInstalacionesByInstitucion = useCallback(
        async (institucion) => {
            const requestUrl = `${resumenUrl}/institucion/instalaciones/${institucion}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getInstalacion = useCallback(
        async (id) => {
            const requestUrl = `${resumenUrl}/instalaciones/${id}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getArea = useCallback(
        async (id) => {
            const requestUrl = `${resumenUrl}/areas/${id}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getPersonal = useCallback(
        async (instalacion) => {
            const requestUrl = `${resumenUrl}/personal/${instalacion}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getDatosPersonal = useCallback(
        async (personal) => {
            const requestUrl = `${resumenUrl}/personal/data/${personal}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getArchivoInstitucion = useCallback(
        async (institucion, handleOnDownloadProgress) => {
            const requestUrl = `${resumenUrl}/institucion/file/${institucion}`
            try {
                const response = await axios.get(requestUrl, 
                    {
                        headers: {'Content-Disposition': 'attachment' 
                        ,...headers()
                        },
                        responseType: 'blob',
                        //onDownloadProgress: (progressEvent) => handleOnDownloadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                return response
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    const getArchivoRepresentanteLegal = useCallback(
        async (institucion, handleOnDownloadProgress) => {
            const requestUrl = `${resumenUrl}/representante/file/${institucion}`
            try {
                const response = await axios.get(requestUrl, 
                    {
                        headers: {'Content-Disposition': 'attachment' 
                        ,...headers()
                        },
                        responseType: 'blob',
                        //onDownloadProgress: (progressEvent) => handleOnDownloadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                return response
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { getInstitucion, getInstitucionById, getInstalaciones, getInstalacionesByInstitucion, getInstalacion, getArea, 
        getPersonal, getDatosPersonal, getArchivoInstitucion, getArchivoRepresentanteLegal }
}

export default useResumenService