import {useState, useEffect, useCallback} from "react"
import {useHistory} from 'react-router-dom'
import useDosimetriaService from "../../services/dosimetria/useDosimetriaService"
import useIsLoged from '../utils/useIsLoged'
import moment from "moment";
import useCrearWord from "../../components/ConvertToWord/AppWord"
import {saveAs} from "file-saver";

const useLecturasPage = () => {
    useIsLoged()
    const history = useHistory()
    const {
        // Archivo lote lectura (.ASC)
        subirArchivoReporteASC,
        getArchivosLectura,
        deleteArchivosLectura,
        descargarArchivosLectura,
        // Archivo ReporteLectura (.docx)
        subirArchivoReporteLecturaDocx,
    } = useDosimetriaService()

    const {actions: accionesWord} = useCrearWord()
    const {generarWord} = accionesWord

    // Almacena la información a mostrar en la tabla
    const [rows, setRows] = useState([]);

    // Variable que controla la apertura y cierre del modal procesar reporte
    const [openProcesarReporteModal, setOpenProcesarReporteModal] = useState(false)

    // Para abrir el componente CircleProgress que indica que se están procesando el reporte
    const [reporteProgress, setReporteProgress] = useState(false)

    // Almacena los archivos .ASC
    const [archivos_ASC, setArchivos_ASC] = useState([])

    //Estados para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("info")
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(false)

    // Para el archivo de los reportes
    const [archivoASC, setArchivoASC] = useState(null)
    const [objArchivoASC, setObjArchivoASC] = useState(null)

    const [progress, setProgress] = useState(-1)

    // Para el rango de fecha
    const [dateRange, setDateRange] = useState({
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString()
    })
    const [value, setValue] = useState("Seleccione un rango de fechas")

    // Extrayendo los parametros tipo? Parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);

    //Estado que indica la página actual
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el número de páginas en total
    const [numPages, setNumPages] = useState(0)

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    if (!page) {
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }

    // Controlador cambio de página
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    // Para indicar cuando se está consumiendo la API
    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }


    const handleLimpiarFechas = () => {
        setDateRange({
            startDate: null,
            endDate: null
        })
        setValue('Seleccione un rango de fecha')
    }

    const handleSelectDate = (start, end) => {
        // Obteniendo la fecha del componente del rango de fecha
        const {_d: fechaInicio} = start
        const {_d: fechaFin} = end
        setDateRange({
            startDate: new Date(fechaInicio).toISOString(),
            endDate: new Date(fechaFin).toISOString()
        })
        // Texto que aparecerá en el componente del rango de fecha
        setValue(start.format('DD-MMM-YYYY') + "  -  " + end.format('DD-MMM-YYYY'))
    }


    const openModalProcesarReporte = () => {
        setOpenProcesarReporteModal(true)
        setReporteProgress(true)
    }
    const closeModalProcesarReporte = () => {
        setOpenProcesarReporteModal(false)
    }

    // Método que ejecuta el CircleProgress indicando que se está realizando la lectura
    const stopReporteProgress = () => {
        setReporteProgress(false)
    }

    const handleOnChangeArchivoASC = (file) => {
        setArchivoASC(file[0])
        setObjArchivoASC([{
            name: file[0].name, deleteFile: () => {
                setObjArchivoASC(null)
                setArchivoASC(null)
            }
        }])
    }

    const handleOnProgress = (loaded, total) => {
        const progress = (loaded / total) * 100
        setProgress(progress)
    }

    // Método que sube el archivo .asc
    const subirArchivoReporte = async () => {
        if (!archivoASC) {
            handleOpenAlert('warning', "Debe seleccionar un archivo .asc")
            return
        }
        let data = new FormData()
        data.append('lecturas', archivoASC)
        openModalProcesarReporte()
        // try {
        const response = await subirArchivoReporteASC(data, handleOnProgress)
        if (response?.error) {
            console.log(response.error)
            handleOpenAlert('error', response?.error)
            closeModalProcesarReporte()
            stopReporteProgress()
        } else {
            const {name} = archivoASC
            const fechaLectura = name.toLowerCase().replace('.asc', '')
            // console.log("**************", fechaLectura)
            handleOpenAlert('success', "Lectura realizada correctamente")
            // stopReporteProgress()
            await getArchivosASC()
            try {
                uploadWord(response, fechaLectura) // Funcion que sube el archivo WORD al servidor
            } catch (e) {
                console.log(e)
                handleOpenAlert('error', e?.error ? e?.error : "Ocurrió un error al genererar el archivo .docx")
                closeModalProcesarReporte()
                stopReporteProgress()
            }
        }
    }

    const getArchivosASC = useCallback(async () => {
        startLoading()
        const params = {}
        if (page) params.page = page
        if (dateRange?.startDate) params.fechaIni = dateRange?.startDate
        if (dateRange?.endDate) params.fechaFin = dateRange?.endDate
        setArchivos_ASC([])
        try {
            const response = await getArchivosLectura(params)
            // console.log("response", response)
            setNumPages(response.last_page)
            if (response?.data) {
                setArchivos_ASC(response?.data)
            }
            // Para la paginación en la URL
            if (page > response.last_page) {
                setPage(response.last_page)
                history.push(`${window.location.pathname}?page=${response.last_page}`)
            }
        } catch (e) {
            // console.log(e)
            handleOpenAlert("error", e?.error ? e.error : "Lectura realizada correctamente")
        }
        stopLoading()
    }, [dateRange, page, history])


    const deleteASC = async (archivo) => {
        const {id} = archivo
        try {
            const responseDeleteFileASC = await deleteArchivosLectura(id)
            // console.log(responseDeleteFileASC)
            await getArchivosASC()
        } catch (e) {
            handleOpenAlert('error',
                e?.error ? e?.error : "Ocurrió un error, intente de nuevo más tarde")
            closeModalProcesarReporte()
            stopReporteProgress()
        }
    }

    const descargarASC = async (archivo) => {
        const {id, fecha_lectura} = archivo
        try {
            const responseDownloadFileASC = await descargarArchivosLectura(id)
            const blob = new Blob([responseDownloadFileASC], {type: 'text/plain'});
            saveAs(blob, `${fecha_lectura}.asc`)
        } catch (e) {
            handleOpenAlert('error',
                e?.error ? e?.error : "Ocurrió un error, intente de nuevo más tarde")
        }
    }

    // Función que sube el archivo word al servidor
    const uploadWord = (data, fechaLectura) => {
        // console.log(data)
        data?.forEach(res => {
            generarWord(res)
                .then(async blob => {
                    const fecha_lectura = moment(fechaLectura, "DD-MM-YYYY").format("YYYY-MM-DD")
                    const newBlob = new File([blob], `${fecha_lectura}.docx`, {type: "application/msword"})
                    // console.log(newBlob);
                    // console.log(moment(fechaLectura, "DD-MM-YYYY").format("YYYY/MM/DD"));
                    // saveAs(blob, "example.docx");
                    let sendData = new FormData()
                    sendData.append('reporte', newBlob)
                    sendData.append('area_id', res?.area.id)
                    sendData.append('fecha_lectura', fecha_lectura)
                    // try {
                    const response = await subirArchivoReporteLecturaDocx(sendData, handleOnProgress)
                    if (response?.error) {
                        handleOpenAlert('error', "No se pudo generar el reporte .docx correctamente," +
                            "intente de nuevo más tarde")
                        closeModalProcesarReporte()
                    }
                    // if (response?.status === 404 || response?.status === 422 || response?.status === 500) {
                    //     handleOpenAlert('error', response?.error)
                    // } else {
                    // console.log("Document created successfully", response);
                    handleOpenAlert('success', "Reporte generado correctamente")
                    // }
                    // } catch (e) {
                    //     handleOpenAlert('error', "No se pudo generar el reporte .docx correctamente," +
                    //         "intente de nuevo más tarde")
                    //     closeModalProcesarReporte()
                    // }
                })
                .catch(e => {
                    handleOpenAlert('error', "No se pudo generar el reporte .docx correctamente," +
                        "intente de nuevo más tarde")
                    closeModalProcesarReporte()
                });
        })
        setTimeout(() => {
            // closeModalProcesarReporte()
            stopReporteProgress()
        }, 1000)
    }




    const clearData = () => {
        setRows([])
        setDateRange({
            startDate: null,
            endDate: null
        })
        setValue('Seleccione un rango de fecha')
        setArchivos_ASC([])
    }

    const data = {
        alertOpen,
        alertSeverity,
        alertMessage,
        loading,
        value,
        rows,
        openProcesarReporteModal,
        reporteProgress,
        objArchivoASC,
        archivos_ASC,
        page,
        numPages,
        progress
    }

    const actions = {
        setAlertOpen,
        handleSelectDate,
        handleLimpiarFechas,
        handlePageChange,
        clearData,
        openModalProcesarReporte,
        closeModalProcesarReporte,
        subirArchivoReporte,
        handleOnChangeArchivoASC,
        deleteASC,
        descargarASC,
    }

    // Para cargar los archivos de lectura lote dependiendo de la fecha seleccionada
    useEffect(async () => {
        await getArchivosASC()
    }, [dateRange])

    return {data, actions}
}

export default useLecturasPage
