import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const responsabilidadUrl = `${url}responsabilidades`

const useResponsabilidadService = () => {
    const { errorHandler } = useErrorHandler()

    const createResponsabilidad = useCallback(
        async (responsabilidad) => {
            try {
                const response = await axios.post(responsabilidadUrl, responsabilidad, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getResponsabilidadById = useCallback(
        async (responsabilidad) => {
            const requestUrl = `${responsabilidadUrl}/${responsabilidad}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getResponsabilidadesByStatus = useCallback(
        async (status, page) => {
            const requestUrl = `${responsabilidadUrl}/status/${status}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const updateResponsabilidad = useCallback(
        async (responsabilidad, cambios) => {
            const requestUrl = `${responsabilidadUrl}/${responsabilidad}`
            try {
                const response = await axios.put(requestUrl, cambios, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const deleteResponsabilidad = useCallback(
        async (responsabilidad) => {
            const requestUrl = `${responsabilidadUrl}/${responsabilidad}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createResponsabilidad, getResponsabilidadById, 
        getResponsabilidadesByStatus, updateResponsabilidad, 
        deleteResponsabilidad }
}

export default useResponsabilidadService