import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import useRolService from "../../services/administracion/useRolService";
import { addRow, findRow, updateRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import {
  allLetterAndSpace,
  allNumbers,
  isEmail,
  isPhone,
  letterNumbersSpaceForAddress,
} from "../../utils/validations";
import { mensajeCamposNoValidos } from "../../utils/messages";
import Session from "react-session-api";
import { useCookies } from "react-cookie";
import { getNowInSeconds } from "../../utils/time";
import useLoginService from "../../services/sesion/useLoginService";
import useClienteServiceCC from "../../services/controlDeCalidad/useClienteServiceCC";
import axios from "axios";
import useDepartamentosService from "../../services/inscripcion/useDepartamentosService";
// import { getFilenameFromContentDisposition } from '../../utils/download'

const useClientePage = () => {
  const {
    createCliente,
    getClienteById,
    getClientes,
    updateCliente,
    deleteCliente,
    getMe,
  } = useClienteServiceCC();

  const { getDepartamentos } = useDepartamentosService();

  const history = useHistory();
  useIsLoged();
  const { id } = useParams();
  //Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);
  //Estado para las filas de la tabla
  const [rows, setRows] = useState([]);

  //Estados para controlar los inputs
  //(errorInput y helperInput sirven para mostrar un error en el input)
  const [nombreCliente, setNombreCliente] = useState("");
  const [errorNombreCliente, setErrorNombreCliente] = useState(false);
  const [helperNombreCliente, setHelperNombreCliente] = useState("");

  const [telefonoCliente, setTelefonoCliente] = useState("");
  const [errorTelefonoCliente, setErrorTelefonoCliente] = useState(false);
  const [helperTelefonoCliente, setHelperTelefonoCliente] = useState("");

  const [correoCliente, setCorreoCliente] = useState("");
  const [errorCorreoCliente, setErrorCorreoCliente] = useState(false);
  const [helperCorreoCliente, setHelperCorreoCliente] = useState("");

  const [detalleDireccion, setDetalleDireccion] = useState("");
  const [errorDetalleDireccion, setErrorDetalleDireccion] = useState(false);
  const [helperDetalleDireccion, setHelperDetalleDireccion] = useState("");

  const [nombrePersonaRepresentante, setNombrePersonaRepresentante] =
    useState("");
  const [errorPersonaRepresentante, setErrorPersonaRepresentante] =
    useState(false);
  const [helperPersonaRepresentante, setHelperPersonaRepresentante] =
    useState("");

  const [observacion, setObservacion] = useState("");
  const [errorObservacion, setErrorObservacion] = useState(false);
  const [helperobservacion, setHelperObservacion] = useState("");

  const [idDepartamento, setIdDepartamento] = useState(-1);
  const [errorIdDepartamento, setErrorIdDepartamento] = useState(false);
  const [helperIdDepartamento, setHelperIdDepartamento] = useState("");

  const [idMunicipio, setIdMunicipio] = useState(-1);
  const [errorIdMunicipio, setErrorIdMunicipio] = useState(false);
  const [helperIdMunicipio, setHelperIdMunicipio] = useState("");

  const [cleanCombobox, setcleanCombobox] = useState(false);

  const [departamentos, setDepartamentos] = useState([]);

  const [convencionalMovil, setConvencionalMovil] = useState(0);
  const [errorConvencionalMovil, setErrorConvencionalMovil] = useState(false);
  const [helperConvencionalMovil, setHelperConvencionalMovil] = useState("");

  const [convencionalFijo, setConvencionalFijo] = useState(0);
  const [errorConvencionalFijo, setErrorConvencionalFijo] = useState(false);
  const [helperConvencionalFijo, setHelperConvencionalFijo] = useState("");

  const [convencionalCflouro, setConvencionalCflouro] = useState(0);
  const [errorConvencionalCflouro, setErrorConvencionalCflouro] =
    useState(false);
  const [helperConvencionalCflouro, setHelperConvencionalCflouro] =
    useState("");

  const [brazoC, setBrazoC] = useState(0);
  const [errorBrazoC, setErrorBrazoC] = useState(false);
  const [helperBrazoC, setHelperBrazoC] = useState("");

  const [mamografico, setMamografico] = useState(0);
  const [errorMamografico, setErrorMamografico] = useState(false);
  const [helperMamografico, setHelperMamografico] = useState("");

  const [dentalPeriapical, setDentalPeriapical] = useState(0);
  const [errorDentalPeriapical, setErrorDentalPeriapical] = useState(false);
  const [helperDentalPeriapical, setHelperDentalPeriapical] = useState("");

  const [dentalPanoramico, setDentalPanoramico] = useState(0);
  const [errorDentalPanoramico, setErrorDentalPanoramico] = useState(false);
  const [helperDentalPanoramico, setHelperDentalPanoramico] = useState("");

  const [tomografiaComputarizada, setTomografiaComputarizada] = useState(0);
  const [errorTomografiaComputarizada, setErrorTomografiaComputarizada] =
    useState(false);
  const [helperTomografiaComputarizada, setHelperTomografiaComputarizada] =
    useState("");

  //Estado de cuantos registros se estan mostrando en la tabla
  const [registrosEnTabla, setRegistrosEnTabla] = useState(0);
  const [lastPagePagination, setLastPagePagination] = useState(0);

  //Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  //Estados para dialogos confirmar actualizacion, creacion y eliminacion
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [clienteToDelete, setClienteToDelete] = useState(null);

  //Estado para progress, el setLoading siempre debe ser llamado en funciones
  //asincronas para funcionar correctamente,aqui nos quedamos
  const [loading, setLoading] = useState(0);

  //Cookies
  const [cookies, setCookie, removeCookie] = useCookies(["token", "rol", "id"]);

  //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  //Estado que indica la pagina actua
  const [page, setPage] = useState(queryParams.get("page"));
  //Estado que indica el numero de paginas en total
  const [numPages, setNumPages] = useState(0);

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  //Controlador cambio de pagina
  const handlePageChange = (event, value) => {
    setPage(value);
    history.push(`${window.location.pathname}?page=${value}`);
  };

  //Controladores alerta
  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  //Controladores mensajes confirmacion
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenDelete = (id) => {
    setClienteToDelete(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const startLoading = () => {
    setLoading((loading) => ++loading);
  };

  const stopLoading = () => {
    setLoading((loading) => --loading);
  };

  const checkIfTipoIsValid = (tipo) => {
    tipo = parseInt(tipo);
    return Number.isInteger(tipo) && tipo >= 0 ? false : true;
  };

  //Funcion para comprobar errores en los campos
  const checkForErrors = () => {
    if (
      errorNombreCliente ||
      nombreCliente === "" ||
      errorDetalleDireccion ||
      detalleDireccion === "" ||
      errorConvencionalMovil ||
      checkIfTipoIsValid(convencionalMovil) ||
      errorConvencionalFijo ||
      checkIfTipoIsValid(convencionalFijo) ||
      errorConvencionalCflouro ||
      checkIfTipoIsValid(convencionalCflouro) ||
      errorBrazoC ||
      checkIfTipoIsValid(brazoC) ||
      errorMamografico ||
      checkIfTipoIsValid(mamografico) ||
      errorDentalPeriapical ||
      checkIfTipoIsValid(dentalPeriapical) ||
      errorDentalPanoramico ||
      checkIfTipoIsValid(dentalPanoramico) ||
      errorTomografiaComputarizada ||
      checkIfTipoIsValid(tomografiaComputarizada) ||
      !idDepartamento ||
      checkIfTipoIsValid(idDepartamento) ||
      !idMunicipio ||
      checkIfTipoIsValid(idMunicipio)
    ) {
      return true;
    }
    //TODO Ver para que sirve este if, dado que cuando se crea este metodo se recorre a priori
    //no deberia haber un id entonces no entiendo para que esta
    // if (!id) {
    //   return true;
    // }
    return false;
  };

  //Funciones para controlar cambios en inputs
  const onChangeNombreCliente = (text) => {
    setNombreCliente(text);
    if (!allLetterAndSpace(text)) {
      setErrorNombreCliente(true);
      setHelperNombreCliente("El nombre solo debe consistir de letras.");
    } else {
      setErrorNombreCliente(false);
      setHelperNombreCliente("");
    }
  };

  const onChangeCorreoCliente = (text) => {
    setCorreoCliente(text);
    if (!isEmail(text) && text !== "") {
      setErrorCorreoCliente(true);
      setHelperCorreoCliente("Debe ingresar un correo electronico valido.");
    } else {
      setErrorCorreoCliente(false);
      setHelperCorreoCliente("");
    }
  };

  const onChangeTelefonoCliente = (text) => {
    setTelefonoCliente(text);
    if (!isPhone(text) && text !== "") {
      setErrorTelefonoCliente(true);
      setHelperTelefonoCliente("Debe ingresar un telefono valido valido.");
    } else {
      setErrorTelefonoCliente(false);
      setHelperTelefonoCliente("");
    }
  };

  const onChangeDetalleDireccion = (text) => {
    setDetalleDireccion(text);
    if (!letterNumbersSpaceForAddress(text)) {
      setErrorDetalleDireccion(true);
      setHelperDetalleDireccion(
        "La dirección solo debe contener numeros,letras y los caracteres: # - / ,  . ª ° "
      );
    } else {
      setErrorDetalleDireccion(false);
      setHelperDetalleDireccion("");
    }
  };

  const onChangeObservacion = (text) => {
    setObservacion(text);
    if (!letterNumbersSpaceForAddress(text)) {
      setErrorObservacion(true);
      setHelperObservacion(
        "La observacion solo debe contener numeros,letras y los caracteres: # - / ,  . ª ° "
      );
    } else {
      setErrorObservacion(false);
      setHelperObservacion("");
    }
  };

  const onChangePersonaRepresentante = (text) => {
    setNombrePersonaRepresentante(text);
    if (!allLetterAndSpace(text)) {
      setErrorPersonaRepresentante(true);
      setHelperPersonaRepresentante(
        "El nombre solo debe contener letras y espacios"
      );
    } else {
      setErrorPersonaRepresentante(false);
      setHelperPersonaRepresentante("");
    }
  };

  const onChangeConvencionalMovil = (text) => {
    setConvencionalMovil(+text);
    if (!allNumbers(text)) {
      setErrorConvencionalMovil(true);
      setHelperConvencionalMovil("El tipo1 solo debe contener numeros.");
    } else {
      setErrorConvencionalMovil(false);
      setHelperConvencionalMovil("");
    }
  };

  const onChangeConvencionalFijo = (text) => {
    setConvencionalFijo(+text);
    if (!allNumbers(text)) {
      setErrorConvencionalFijo(true);
      setHelperConvencionalFijo("El tipo2 solo debe contener numeros.");
    } else {
      setErrorConvencionalFijo(false);
      setHelperConvencionalFijo("");
    }
  };

  const onChangeConvencionalCflouro = (text) => {
    setConvencionalCflouro(+text);
    if (!allNumbers(text)) {
      setErrorConvencionalCflouro(true);
      setHelperConvencionalCflouro("El tipo3 solo debe contener numeros.");
    } else {
      setErrorConvencionalCflouro(false);
      setHelperConvencionalCflouro("");
    }
  };

  const onChangeBrazoC = (text) => {
    setBrazoC(+text);
    if (!allNumbers(text)) {
      setErrorBrazoC(true);
      setHelperBrazoC("El tipo4 solo debe contener numeros.");
    } else {
      setErrorBrazoC(false);
      setHelperBrazoC("");
    }
  };

  const onChangeMamografico = (text) => {
    setMamografico(+text);
    if (!allNumbers(text)) {
      setErrorMamografico(true);
      setHelperMamografico("El tipo5 solo debe contener numeros.");
    } else {
      setErrorMamografico(false);
      setHelperMamografico("");
    }
  };

  const onChangeDentalPeriapical = (text) => {
    setDentalPeriapical(+text);
    if (!allNumbers(text)) {
      setErrorDentalPeriapical(true);
      setHelperDentalPeriapical("El tipo6 solo debe contener numeros.");
    } else {
      setErrorDentalPeriapical(false);
      setHelperDentalPeriapical("");
    }
  };

  const onChangeDentalPanoramico = (text) => {
    setDentalPanoramico(+text);
    if (!allNumbers(text)) {
      setErrorDentalPanoramico(true);
      setHelperDentalPanoramico("El tipo7 solo debe contener numeros.");
    } else {
      setErrorDentalPanoramico(false);
      setHelperDentalPanoramico("");
    }
  };

  const onChangeTomograficaComputarizada = (text) => {
    setTomografiaComputarizada(+text);
    if (!allNumbers(text)) {
      setErrorTomografiaComputarizada(true);
      setHelperTomografiaComputarizada("El tipo8 solo debe contener numeros.");
    } else {
      setErrorTomografiaComputarizada(false);
      setHelperTomografiaComputarizada("");
    }
  };

  function verificarEnteroPositivo(numero) {
    // Verificar si es un número
    if (typeof numero !== "number") {
      return false;
    }

    // Verificar si es entero
    if (!Number.isInteger(numero)) {
      return false;
    }

    // Verificar si es positivo
    if (numero <= 0) {
      return false;
    }

    // Si pasa todas las verificaciones, es un entero positivo
    return true;
  }

  const onChangeIdDepartamento = (text) => {
    text = parseInt(text);
    setIdDepartamento(text);
    if (!verificarEnteroPositivo(text)) {
      setErrorIdDepartamento(true);
      setHelperIdDepartamento("El idDepartamento solo debe contener numeros.");
    } else {
      setErrorIdDepartamento(false);
      setHelperIdDepartamento("");
    }
  };

  const onChangeIdMunicipio = (text) => {
    setIdMunicipio(text);
    if (!verificarEnteroPositivo(text)) {
      setErrorIdMunicipio(true);
      setHelperIdMunicipio("El idMunicipio solo debe contener numeros.");
    } else {
      setErrorIdMunicipio(false);
      setHelperIdMunicipio("");
    }
  };

  //funcion que verifica si por lo menos algun tipo tiene dastos
  const verificarTipos = () => {
    const tipos = [
      convencionalMovil,
      convencionalFijo,
      convencionalCflouro,
      brazoC,
      mamografico,
      dentalPeriapical,
      dentalPanoramico,
      tomografiaComputarizada,
    ];

    for (let tipo of tipos) {
      if (tipo > 0) {
        return false;
      }
    }

    return true;
  };

  //Funcion para manejar la creacion de un usuario
  const onCreateCliente = async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }
    const newCliente = {
      nombre: nombreCliente,
      nombreRepresentante: nombrePersonaRepresentante,
      departamento: idDepartamento,
      municipio: idMunicipio,
      detalle_direccion: detalleDireccion,
      tipo: {
        convencionalMovil,
        convencionalFijo,
        convencionalCflouro,
        brazoC,
        mamografico,
        dentalPeriapical,
        dentalPanoramico,
        tomografiaComputarizada,
      },
    };

    newCliente.telefono = telefonoCliente ? telefonoCliente : "";
    newCliente.correo = correoCliente ? correoCliente : "";
    newCliente.observacion = observacion ? observacion : "";

    if (verificarTipos()) {
      handleOpenAlert("warning", `Por favor registrar algun tipo`);

      return;
    }

    startLoading();

    //const result = await createCliente(newCliente);
    const result = await createCliente(newCliente);
    if (result.error) {
      setErrorMessage(result.error);
      handleOpenAlert("error", result.error);
    } else {
      const {
        nombre,
        departamento,
        municipio,
        detalle_direccion,
        tipo: {
          convencionalMovil,
          convencionalFijo,
          convencionalCflouro,
          brazoC,
          mamografico,
          dentalPeriapical,
          dentalPanoramico,
          tomografiaComputarizada,
        },
        telefono = "N/A",
        correo = "N/A",
        id,
      } = result;

      const key = id;

      const newCells = [
        nombre,
        // departamento,
        // municipio,
        // detalle_direccion,
        telefono,
        correo,
        // convencionalMovil,
        // convencionalFijo,
        // convencionalCflouro,
        // brazoC,
        // mamografico,
        // dentalPeriapical,
        // dentalPanoramico,
        // tomografiaComputarizada,
      ];
      addRow(key, newCells, setRows);
      if (registrosEnTabla === 10) {
        setRegistrosEnTabla(1);
        // setPage(lastPagePagination + 1)
        setLastPagePagination(lastPagePagination + 1);
        handlePageChange(null, lastPagePagination + 1);
        //history.push(`/cliente/administrar?page=${page}`);
      }
      setRegistrosEnTabla(registrosEnTabla + 1);
      cancel();
      history.push(`/cliente/administrar?page=${page}`);
      handleOpenAlert(
        "success",
        `El cliente "${nombre}" se creo correctamente.`
      );
      // se debe crear este me todo -> handleGetUsuarios()
    }
    stopLoading();
  };

  const onUpdateCliente = async () => {
    setOpenEdit(false);
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const clienteActualizado = {
      nombre: nombreCliente,
      nombreRepresentante: nombrePersonaRepresentante,
      departamento: idDepartamento,
      municipio: idMunicipio,
      detalle_direccion: detalleDireccion,
      tipo: {
        convencionalMovil,
        convencionalFijo,
        convencionalCflouro,
        brazoC,
        mamografico,
        dentalPeriapical,
        dentalPanoramico,
        tomografiaComputarizada,
      },
    };

    // if (telefonoCliente) clienteActualizado.telefono = telefonoCliente;
    // if (correoCliente) clienteActualizado.correo = correoCliente;

    clienteActualizado.telefono = telefonoCliente ? telefonoCliente : "";
    clienteActualizado.correo = correoCliente ? correoCliente : "";
    clienteActualizado.observacion = observacion ? observacion : "";

    if (verificarTipos()) {
      handleOpenAlert("warning", `Por favor registrar algun tipo`);

      return;
    }

    startLoading();
    const result = await updateCliente(id, clienteActualizado);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const {
        nombre,
        departamento,
        municipio,
        detalle_direccion,
        tipo: {
          convencionalMovil,
          convencionalFijo,
          convencionalCflouro,
          brazoC,
          mamografico,
          dentalPeriapical,
          dentalPanoramico,
          tomografiaComputarizada,
        },
        telefono,
        correo,
        id,
      } = result;

      const newCells = [
        nombre,
        // departamento,
        // municipio,
        // detalle_direccion,
        telefono,
        correo,
        // convencionalMovil,
        // convencionalFijo,
        // convencionalCflouro,
        // brazoC,
        // mamografico,
        // dentalPeriapical,
        // dentalPanoramico,
        // tomografiaComputarizada,
      ];
      updateRow(id, newCells, setRows);
      handleOpenAlert(
        "success",
        `El cliente "${nombre}" se actualizo correctamente.`
      );
    }
    cancel();
    history.push(`/cliente/administrar?page=${page}`);
    stopLoading();
  };

  const getCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const onDeleteCliente = async () => {
    setOpenDelete(false);
    startLoading();
    const idUserActived = Session.get("id");
    const deleteDate = getCurrentDateTime(); //TODO cambiar formato de fecha

    const payload = {
      id: clienteToDelete,
      deletedBy: idUserActived,
      deleteDate,
    };
    const result = await deleteCliente(clienteToDelete, payload);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { nombre } = result;
      //deleteRow(id, setRows)
      handleGetClientes();
      editCancel();
      handleOpenAlert(
        "success",
        `El cliente "${nombre}" se elimino correctamente.`
      );
    }
    stopLoading();
  };

  const handleGetClientes = useCallback(async () => {
    startLoading();
    console.log(`Este es el token
    ${Session.get("token").token_type} ${Session.get("token").access_token}`);
    const clientes = await getClientes(page);
    if (clientes.error) {
      setErrorMessage(clientes.error);
    } else {
      setRegistrosEnTabla(clientes.data.length);
      setLastPagePagination(clientes.last_page);
      //Hay que descomentar lo comentado en este ele cuando este la api oficial
      setRows([]);
      setNumPages(clientes.last_page);
      clientes.data.forEach(
        ({
          id,
          nombre,
          departamento,
          municipio,
          detalle_direccion,
          telefono = "N/A",
          correo = "N/A",
          tipo: {
            convencionalMovil,
            convencionalFijo,
            convencionalCflouro,
            brazoC,
            mamografico,
            dentalPeriapical,
            dentalPanoramico,
            tomografiaComputarizada,
          },
        }) => {
          const key = id;
          const cell = [
            nombre,
            // departamento,
            // municipio,
            // detalle_direccion,
            telefono,
            correo,
            // convencionalMovil,
            // convencionalFijo,
            // convencionalCflouro,
            // brazoC,
            // mamografico,
            // dentalPeriapical,
            // dentalPanoramico,
            // tomografiaComputarizada,
          ];
          addRow(key, cell, setRows);
        }
      );
      if (page > clientes.last_page) {
        setPage(clientes.last_page);
        history.push(`${window.location.pathname}?page=${clientes.last_page}`);
      }
    }
    stopLoading();
  }, [getClientes, history, page]);

  const getCliente = useCallback(
    async (id) => {
      startLoading();
      const { data: cliente } = await getClienteById(id);
      if (cliente.error) {
        handleOpenAlert("error", cliente.error);
      } else {
        // const telefonoClienteWithoutNA = telefonoCliente === "N/A" ? "" : telefonoCliente;
        // const correoClienteWithoutNA = correoCliente === "N/A" ? "" : correoCliente;
        setNombreCliente(cliente.nombre);
        setIdDepartamento(cliente.departamento);
        setIdMunicipio(cliente.municipio);
        setCorreoCliente(cliente.correo === "N/A" ? "lechuga" : cliente.correo);
        setTelefonoCliente(
          cliente.telefono === "N/A" ? "lechuga" : cliente.telefono
        );
        setDetalleDireccion(cliente.detalle_direccion);
        setNombrePersonaRepresentante(cliente.nombreRepresentante);
        setObservacion(cliente.observacion);
        setConvencionalMovil(cliente.tipo.convencionalMovil);
        setConvencionalFijo(cliente.tipo.convencionalFijo);
        setConvencionalCflouro(cliente.tipo.convencionalCflouro);
        setBrazoC(cliente.tipo.brazoC);
        setMamografico(cliente.tipo.mamografico);
        setDentalPeriapical(cliente.tipo.dentalPeriapical);
        setDentalPanoramico(cliente.tipo.dentalPanoramico);
        setTomografiaComputarizada(cliente.tipo.tomografiaComputarizada);
      }
      stopLoading();
    },
    [getClienteById]
  );

  const editClick = (id) => {
    history.push(`/cliente/administrar/${id}?page=${page}`);
  };

  const editCancel = () => {
    cancel();
    history.push(`/cliente/administrar?page=${page}`);
    setcleanCombobox(!cleanCombobox);
  };

  //Funcion cancelar (regresa los estados al valor inicial)
  const cancel = () => {
    setNombreCliente("");
    setIdDepartamento(0);
    setIdMunicipio(0);
    setCorreoCliente("");
    setTelefonoCliente("");
    setDetalleDireccion("");
    setObservacion("");
    setNombrePersonaRepresentante("");
    setIdDepartamento(0);
    setIdMunicipio(0);
    setConvencionalMovil(0);
    setConvencionalFijo(0);
    setConvencionalCflouro(0);
    setBrazoC(0);
    setMamografico(0);
    setDentalPeriapical(0);
    setDentalPanoramico(0);
    setTomografiaComputarizada(0);
    setErrorNombreCliente(false);
    setErrorDetalleDireccion(false);
    setErrorIdDepartamento(false);
    setErrorIdMunicipio(false);
    setErrorTelefonoCliente(false);
    setErrorCorreoCliente(false);
    setErrorConvencionalMovil(false);
    setErrorConvencionalFijo(false);
    setErrorConvencionalCflouro(false);
    setErrorBrazoC(false);
    setErrorMamografico(false);
    setErrorDentalPeriapical(false);
    setErrorDentalPanoramico(false);
    setErrorTomografiaComputarizada(false);
    if (!id) setcleanCombobox(!cleanCombobox);
  };

  const handleGetDepartamentos = useCallback(async () => {
    startLoading();
    const departamentos = await getDepartamentos();
    if (departamentos.error) {
      handleOpenAlert("error", departamentos.error);
    } else {
      setDepartamentos(departamentos);
    }
    stopLoading();
  }, [getDepartamentos]);

  useEffect(() => {
    handleGetDepartamentos();
    //Solo se ejecuta cuando cambian las funciones
  }, []);

  //Objeto con los datos del custom hook que se usaran en la pagina
  const data = {
    alertMessage,
    alertOpen,
    alertSeverity,
    brazoC,
    cleanCombobox,
    clienteToDelete,
    convencionalCflouro,
    convencionalFijo,
    convencionalMovil,
    correoCliente,
    dentalPanoramico,
    dentalPeriapical,
    departamentos,
    detalleDireccion,
    errorBrazoC,
    errorConvencionalCflouro,
    errorConvencionalFijo,
    errorConvencionalMovil,
    errorCorreoCliente,
    errorDentalPanoramico,
    errorDentalPeriapical,
    errorDetalleDireccion,
    errorIdDepartamento,
    errorIdMunicipio,
    errorMamografico,
    errorMessage,
    errorNombreCliente,
    errorTelefonoCliente,
    errorTomografiaComputarizada,
    helperBrazoC,
    helperConvencionalCflouro,
    helperConvencionalFijo,
    helperConvencionalMovil,
    helperCorreoCliente,
    helperDentalPanoramico,
    helperDentalPeriapical,
    helperDetalleDireccion,
    helperIdDepartamento,
    helperIdMunicipio,
    helperMamografico,
    helperNombreCliente,
    helperTelefonoCliente,
    helperTomografiaComputarizada,
    id,
    idDepartamento,
    idMunicipio,
    loading,
    mamografico,
    nombreCliente,
    numPages,
    openDelete,
    openEdit,
    page,
    rows,
    telefonoCliente,
    tomografiaComputarizada,
    errorPersonaRepresentante,
    helperPersonaRepresentante,
    nombrePersonaRepresentante,
    observacion,
    errorObservacion,
    helperobservacion,
  };

  //Objeto con las acciones que se usaran en la pagina
  const actions = {
    onDeleteCliente,
    onCreateCliente,
    onUpdateCliente,
    onChangePersonaRepresentante,
    onChangeObservacion,
    onChangeNombreCliente,
    onChangeCorreoCliente,
    onChangeTelefonoCliente,
    onChangeDetalleDireccion,
    onChangeIdDepartamento,
    onChangeIdMunicipio,
    onChangeConvencionalMovil,
    onChangeConvencionalFijo,
    onChangeConvencionalCflouro,
    onChangeBrazoC,
    onChangeMamografico,
    onChangeDentalPeriapical,
    onChangeDentalPanoramico,
    onChangeTomograficaComputarizada,
    handleClickOpenEdit,
    handleClickOpenDelete,
    handleCloseDelete,
    handleCloseEdit,
    findRow,
    setClienteToDelete,
    setAlertOpen,
    handlePageChange,
    editClick,
    cancel,
    editCancel,
  };

  useEffect(() => {
    const loadData = async () => {
      await handleGetClientes();
    };

    loadData();
  }, [handleGetClientes]);

  useEffect(() => {
    if (id) {
      cancel();
      getCliente(id);
    }
    //Solo se ejecuta cuando cambia el valor de id o getUsuario
  }, [id, getCliente]);

  return { errorMessage, data, actions };
};

export default useClientePage;
