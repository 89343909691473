import Alert from "../../components/Alert";
import AppFrame from "../../components/AppFrame";
import Button from "../../components/Button";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import FormHeader from "../../components/FormHeader";
import Grid from "@material-ui/core/Grid";
import Input from "../../components/Input";
import Paper from "@material-ui/core/Paper";
import Progress from "../../components/Progress/Progress";
import { RiGroupLine, RiRestartLine } from "react-icons/ri";
import { makeStyles } from "@material-ui/core/styles";
import { MaskField } from "react-mask-field";
import React from "react";
import useTransportePage from "../../hooks/transporte/useTransportePage";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  button: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

// function CustomMaskPlaca({ inputRef, ...otherProps }) {
//   return (
//     <MaskField
//       ref={inputRef}
//       mask="___-___"
//       replacement={{ _: /\d/ }}
//       {...otherProps}
//     />
//   );
// }

export const FormTransporte = ({ data, actions, classes }) => {
  const {
    alertOpen,
    alertSeverity,
    alertMessage,
    openEdit,
    openDelete,
    transporteToDelete,
    loading,
    numPages,
    page,
    placa,
    errorPlaca,
    helperPlaca,
    color,
    errorColor,
    helperColor,
    marca,
    errorMarca,
    helperMarca,
    modelo,
    errorModelo,
    helperModelo,
  } = data;

  const {
    handlePageChange,
    handleOpenAlert,
    handleClickOpenEdit,
    handleCloseEdit,
    handleClickOpenDelete,
    handleCloseDelete,
    onChangePlaca,
    onChangeColor,
    onChangeMarca,
    onChangeModelo,
  } = actions;

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Input
          type="text"
          label="Placa"
          placeholder="Ingrese la placa"
          value={placa}
          onChange={onChangePlaca}
          error={errorPlaca}
          helper={helperPlaca}
          required={true}
          //   InputProps={{ inputComponent: CustomMaskPlaca }}
        />

        <Input
          type="text"
          label="Color"
          placeholder="Ingrese el color"
          value={color}
          onChange={onChangeColor} //ya esta pero aca se usa lo del hook 
          error={errorColor}
          helper={helperColor}
          required={true}
          //   InputProps={{ inputComponent: CustomMaskPlaca }}
        />

        <Input
          type="text"
          label="Marca"
          placeholder="Ingrese la marca"
          value={marca}
          onChange={onChangeMarca} //ya esta pero aca se usa lo del hook 
          error={errorMarca}
          helper={helperMarca}
          required={true}
          //   InputProps={{ inputComponent: CustomMaskPlaca }}
        />

        <Input
          type="text"
          label="Modelo"
          placeholder="Ingrese el modelo"
          value={modelo}
          onChange={onChangeModelo}
          error={errorModelo}
          helper={helperModelo}
          required={true}
          //   InputProps={{ inputComponent: CustomMaskPlaca }}
        />
      </Grid>
    </Grid>
  );
};

const encabezado = ["Placa", "Color", "Marca", "Modelo"];

const TransportePage = (props) => {
  const classes = useStyles();
  const { data, actions } = useTransportePage();
  const {
    loading,
    alertOpen,
    alertSeverity,
    alertMessage,
    setAlertOpen,
    rows,
    openDelete,
    transporteToDelete,
    setTransporteToDelete,
    id,
    openEdit,
    placa,
  } = data;

  const {
    cancel,
    onCreateTransporte,
    handleClickOpenDelete,
    handleCloseDelete,
    onDeleteTransporte,
    findRow,
    editClick,
    cancelEdit,
    handleCloseEdit,
    onUpdateCliente,
    handleClickOpenEdit,
  } = actions;

  return (
    <AppFrame loged={false}>
      <Progress open={loading > 0} />
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={12} sm={11}>
          <Paper className={classes.paper}>
            <Alert
              open={alertOpen}
              setOpen={setAlertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
            <FormHeader
              title="Registro de transportes"
              subtitle="Datos Requeridos *"
            >
              <RiGroupLine />
            </FormHeader>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid container item xs={10}>
                <FormTransporte
                  data={data}
                  actions={actions}
                  classes={classes}
                />
                <Grid
                  container
                  item
                  className={classes.button}
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      func={id ? cancelEdit : cancel}
                      variant="outlined"
                      text="CANCELAR"
                    />
                  </Grid>
                  <Grid className={classes.input} item xs={5} sm={3}>
                    <Button
                      variant="contained"
                      text={id ? "EDITAR" : "AGREGAR"}
                      func={id ? handleClickOpenEdit : onCreateTransporte}
                      //text={"AGREGAR"}
                      //func={onCreateTransporte}
                    />
                    <Dialog
                      open={openEdit}
                      handleClose={handleCloseEdit}
                      handleAccept={onUpdateCliente}
                      title={"Desea actualizar el registro?"}
                      content={`Los datos del transporte con placa "${placa}" se actualizaran.`}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    headers={encabezado}
                    rows={rows}
                    editFnc={(key) => editClick(key)}
                    deleteFnc={(key) => handleClickOpenDelete(key)}
                    //editFnc={(key) => console.log(`editaras el id ${key}`)}
                    // deleteFnc={(key) => console.log(`Eliminaras el id ${key}`)}
                  />
                </Grid>
                <Dialog
                  open={openDelete}
                  handleClose={handleCloseDelete}
                  handleAccept={onDeleteTransporte}
                  title={"Desea eliminar el registro?"}
                  content={
                    findRow(transporteToDelete, rows)
                      ? `El transporte con placa "${
                          findRow(transporteToDelete, rows).cells[1]
                        }" sera eliminado del sistema.`
                      : ""
                  }
                  transitionFunc={() => setTransporteToDelete(null)}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </AppFrame>
  );
};
export default TransportePage;
