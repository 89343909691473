import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const DataTableReportes = ({
  headers,
  secondRowHeaders,
  rows,
  columnColors,
  secondRowHeight,
  setIdTableSelected,
}) => {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
    headerHorizontal: {
      minHeight: "80px",
      transform: "rotate(0)",
    },
    headerVertical: {
      transform: "rotate(-90deg)",
      whiteSpace: "nowrap",
      width: "40px",
    },
    table: {
      width: "100%",
      overflowX: "auto",
    },
    secondRowVertical: {
      "& > *": {
        writingMode: "vertical-lr",
        transform: "rotate(180deg)",
        width: "auto",
        height: `${secondRowHeight}px`,
      },
    },
    centeredCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    nowrapCell: {
      whiteSpace: "nowrap",
    },
  }));

  const classes = useStyles();

  return (
    <Box component={Paper}>
      <TableContainer style={{ width: "100%", overflow: "auto" }}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={header}
                  className={`${classes.header} ${
                    index === 0 ? classes.headerHorizontal : ""
                  }`}
                  style={{ backgroundColor: columnColors[index] }} // Apply color to header
                >
                  <Typography className={classes.centeredCell}>{header}</Typography>
                </TableCell>
              ))}
              {setIdTableSelected && (
                <TableCell className={classes.header} style={{ backgroundColor: "inherit" }}>
                  Acción
                </TableCell>
              )}
            </TableRow>

            <TableRow className={classes.secondRowVertical}>
              {secondRowHeaders.map((header, index) => (
                <TableCell
                  key={header}
                  className={`${classes.header} ${classes.headerVertical}`}
                  style={{ backgroundColor: columnColors[index] }} // Apply color to second-row header
                >
                  <Typography>{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={row.key}>
                {row.cells.map((cell, cellIndex) => (
                  <TableCell
                    key={`${row.key}-${cell}-${cellIndex}`}
                    style={{
                      backgroundColor: columnColors[cellIndex],
                      textAlign: cellIndex >= 1 ? "center" : "left",
                    }}
                    className={classes.nowrapCell}
                  >
                    {cell}
                  </TableCell>
                ))}
                {setIdTableSelected && (
                  <TableCell>
                    <Button
                      onClick={() => setIdTableSelected(row.key)}
                      variant="contained"
                      color="primary"
                    >
                      Seleccionar
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

DataTableReportes.propTypes = {
  headers: PropTypes.array.isRequired,
  secondRowHeaders: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      cells: PropTypes.array.isRequired,
    })
  ).isRequired,
  columnColors: PropTypes.array.isRequired, // Make sure columnColors is required
  secondRowHeight: PropTypes.number,
  setIdTableSelected: PropTypes.func,
};

export default DataTableReportes;






// import React from "react";
// import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
// import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";

// const DataTableReportes = ({
//   headers,
//   secondRowHeaders,
//   rows,
//   columnColors,
//   secondRowHeight,
//   setIdTableSelected, // Nuevo parámetro opcional
// }) => {
//   const useStyles = makeStyles((theme) => ({
//     header: {
//       fontWeight: "bold",
//       whiteSpace: "nowrap",
//     },
//     headerHorizontal: {
//       minHeight: "80px",
//       transform: "rotate(0)",
//     },
//     headerVertical: {
//       transform: "rotate(-90deg)",
//       whiteSpace: "nowrap",
//       width: "40px",
//     },
//     table: {
//       width: "100%",
//       overflowX: "auto",
//     },
//     secondRowVertical: {
//       "& > *": {
//         writingMode: "vertical-lr",
//         transform: "rotate(180deg)",
//         width: "auto",
//         height: `${secondRowHeight}px`,
//       },
//     },
//     centeredCell: {
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//     },
//   }));

//   const classes = useStyles();

//   return (
//     <Box component={Paper}>
//       <TableContainer style={{ width: "100%", overflow: "auto" }}>
//         <Table className={classes.table} size="small">
//           <TableHead>
//             <TableRow>
//               {headers.map((header, index) => (
//                 <TableCell
//                   key={header}
//                   className={`${classes.header} ${
//                     index === 0 ? classes.headerHorizontal : ""
//                   }`}
//                 >
//                   <Typography className={classes.centeredCell}>{header}</Typography>
//                 </TableCell>
//               ))}
//               {setIdTableSelected && <TableCell className={classes.header}>Acción</TableCell>}
//             </TableRow>

//             <TableRow className={classes.secondRowVertical}>
//               {secondRowHeaders.map((header, index) => (
//                 <TableCell
//                   key={header}
//                   className={`${classes.header} ${classes.headerVertical}`}
//                 >
//                   <Typography>{header}</Typography>
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows.map((row, rowIndex) => (
//               <TableRow key={row.key}>
//                 {row.cells.map((cell, cellIndex) => (
//                   <TableCell
//                     key={`${row.key}-${cell}-${cellIndex}`}
//                     style={{ backgroundColor: columnColors[cellIndex] }}
//                   >
//                     {cell}
//                   </TableCell>
//                 ))}
//                 {setIdTableSelected && (
//                   <TableCell>
//                     <Button
//                       onClick={() => setIdTableSelected(row.key)}
//                       variant="contained"
//                       color="primary"
//                     >
//                       Seleccionar
//                     </Button>
//                   </TableCell>
//                 )}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// };

// DataTableReportes.propTypes = {
//   headers: PropTypes.array.isRequired,
//   secondRowHeaders: PropTypes.array.isRequired,
//   rows: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//       cells: PropTypes.array.isRequired,
//     })
//   ).isRequired,
//   columnColors: PropTypes.array,
//   secondRowHeight: PropTypes.number,
//   setIdTableSelected: PropTypes.func, // Nuevo prop opcional
// };

// export default DataTableReportes;
