import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';

const FileTable = ({ files, onDownload, onDelete }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  useEffect(() => {
    // Aquí puedes realizar cualquier acción que necesites cuando el estado `files` cambie.
    // En este caso, podrías hacer algo si es necesario.
    console.log('Files updated:', files);
  }, [files]); // Este efecto se ejecutará cada vez que `files` cambie.

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Archivo</TableCell>
            <TableCell>Fecha de Creación</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files?.map((file) => (
            <TableRow key={file.id}>
              <TableCell>{file.archivo}</TableCell>
              <TableCell>{formatDate(file.creado)}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={() => onDownload(file.id)}>
                  Descargar
                </Button>
                <Button variant="contained" color="secondary" onClick={() => onDelete(file.id)}>
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileTable;





// import React from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';

// const FileTable = ({ files, onDownload, onDelete }) => {
//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
//   };

//   return (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Archivo</TableCell>
//             <TableCell>Fecha de Creación</TableCell>
//             <TableCell>Acciones</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {files?.map((file) => (
//             <TableRow key={file.id}>
//               <TableCell>{file.archivo}</TableCell>
//               <TableCell>{formatDate(file.creado)}</TableCell>
//               <TableCell>
//                 <Button variant="contained" color="primary" onClick={() => onDownload(file.id)}>
//                   Descargar
//                 </Button>
//                 <Button variant="contained" color="secondary" onClick={() => onDelete(file.id)}>
//                   Eliminar
//                 </Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default FileTable;
