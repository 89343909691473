import React from 'react'
import AppFrame from '../../components/AppFrame'
import FormHeader from '../../components/FormHeader'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import {Link} from 'react-router-dom'
import useLoginPage from '../../hooks/sesion/useLoginPage'
// import Session from 'react-session-api'
import Progress from '../../components/Progress/Progress'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    button: {
        marginTop: '1em',
        marginBottom: '1em'
    }
}))

const LoginPage = props => {
    const classes = useStyles()
    const {data, actions} = useLoginPage()
    const {email, password, alertOpen, alertSeverity, alertMessage,
        loading} = data
    const {setEmail, setPassword, setAlertOpen, onLogin} = actions
    return (
        <AppFrame loged = {false}>
            <Progress open = {loading}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm={8} md={4}>
                    <Paper className = {classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Log in">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid item xs = {10} sm={8}>
                                <Input type = "text" label="Correo electronico" 
                                    placeholder="Ingrese su correo electronico"
                                    value = {email}
                                    onChange={(text)=>{setEmail(text)}}
                                    />
                                <Input type = "password" label="Contraseña" 
                                    placeholder="Ingrese su contraseña"
                                    value = {password}
                                    onChange={(text)=>{setPassword(text)}}
                                    />
                                <div className = {classes.button}>
                                    <Button 
                                        func={onLogin}
                                        variant = "contained" text="Iniciar sesion"/>
                                </div>
                                <Link to="/iniciar-sesion/recuperar">¿Olvidaste tu contraseña?</Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default LoginPage
