import React from 'react'
import AppFrame from './../../components/AppFrame'
import FormHeader from './../../components/FormHeader'
import Button from './../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import DataTable from './../../components/DataTable'
import { makeStyles } from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import Dialog from '../../components/Dialog'
import Pagination from '../../components/Pagination'
import Alert from '../../components/Alert'
import { Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core'
import Progress from '../../components/Progress/Progress'
import useDatosDelegadoPage from '../../hooks/inscripcion/useDatosDelegadoPage'
import Session from 'react-session-api'
import { FormUsuario } from '../administracion/UsuarioPage'



const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    table: {
        marginTop: '2em'
    },
    stepper: {
        backgroundColor: 'transparent'
    },

}))

const encabezado = ["Nombres", "DUI", "Telefono", "Celular", 
    "Correo electronico"]

//Componente que funciona como pasos verticales para la solicitud de inscripcion/edicion

export const StepperInscripcion = ({activeStep}) => {
    const classes = useStyles()
    const pasos = ["Delegados", "Instalaciones", "Areas", "Personal", "T.O.E.", 
        "Resumen"]
    const rol = Session.get("rol") ? Session.get("rol").id : null
    return (
    <Grid   container direction="row" justifyContent="flex-start" alignItems="center" xs={12} sm= {3}>
       <Stepper className = {classes.stepper} nonLinear activeStep={activeStep + (rol === 3 ? -1 : 0)}
            orientation='vertical'>
                {
                    pasos.map(
                        (paso, index) => (
                            rol === 3 && index === 0 ?
                            null
                            :
                            <Step key = {paso}>
                                <StepLabel>Paso {index + (rol === 3 ? 0 : 1)}</StepLabel>
                                <StepContent>
                                    <Typography>{paso}</Typography>
                                </StepContent>
                            </Step>
                        )
                    )
                }
        </Stepper>
    </Grid>
    )
}


const DatosDelegadoPage = props => {
    const classes = useStyles()
    
    const { data, actions } = useDatosDelegadoPage()

    const {
        rows,
        id,
        openEdit,
        openDelete,
        delegadoToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        loading,
        idSolicitud
    } = data
    const {
        onCreateDelegado,
        onUpdateDelegado,
        onDeleteDelegado,
        editClick,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setDelegadoToDelete,
        setAlertOpen,
        handlePageChange,
        // handleFilePageChange,
        // handleCloseFileModal,
        // onDocumentLoadSuccess,
        handleChangeEstadoDisponible,
    } = actions

    /*
                
    */

    return (
        <AppFrame loged = {false}>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid xs={12} sm= {8}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Datos internos | Personal encargado de suministrar datos de Instalaciones">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {10}>
                                <FormUsuario data = {data} actions = {actions} classes = {classes}/>
                            </Grid>
                            <Grid className={classes.input} item 
                                        xs = {5} sm={3}>
                                        <Button
                                            func = {
                                                id?
                                                editCancel
                                                :
                                                cancel
                                            }
                                            variant = "outlined"
                                            text="CANCELAR"/>
                                    </Grid>
                            <Grid className={classes.input} item
                                xs = {5} sm={3}>
                                <Button
                                    variant = "contained"
                                    text={id?"EDITAR":"AGREGAR"}
                                    func = {id?
                                        handleClickOpenEdit
                                        :
                                        onCreateDelegado}
                                    />
                                <Dialog open={openEdit} 
                                    handleClose = {handleCloseEdit} 
                                    handleAccept = {onUpdateDelegado} 
                                    title = {"Desea actualizar el registro?"} 
                                    content = {
                                        findRow(Number(id), rows) ?
                                        `Los datos del delegado "${data.nombreUsuario}" se actualizaran.`
                                        :
                                        "Error al cargar datos."
                                    }/>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid className={classes.table} container>
                        <Grid item xs={12}>
                            <DataTable headers={encabezado} 
                                rows={rows}
                                editFnc={(key)=>
                                    editClick(key)}
                                deleteFnc={idSolicitud ? null : (key)=>
                                    handleClickOpenDelete(key)}
                                disableFnc={idSolicitud ? handleChangeEstadoDisponible : null}
                                />
                        </Grid>
                        <Grid item xs = {12}>
                            <Pagination page={Number(page)}
                                numPages={Number(numPages)}
                                onChangePage = {handlePageChange}
                                />
                        </Grid>
                        <Dialog open={openDelete} 
                            handleClose = {handleCloseDelete} 
                            handleAccept = {onDeleteDelegado} 
                            title = {"Desea eliminar el registro?"} 
                            content = { 
                                findRow(delegadoToDelete, rows) ?
                                `El delegado con nombre "${ findRow(delegadoToDelete, rows).cells[0] }" sera eliminado del sistema.`
                                :
                                ""
                                }
                            transitionFunc = {() => 
                                setDelegadoToDelete(null)}/>
                    </Grid>
                    <Grid container direction="row"
                        justifyContent="center" spacing = {2}>
                        <Grid className={classes.input} item 
                            xs = {5} sm={3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/delegados'}
                                variant = "outlined"
                                text="CANCELAR"/>
                        </Grid>
                        <Grid className={classes.input} item
                            xs = {5} sm={3}>
                            <Button
                                component={RouterLink}
                                to={'/cliente/instalaciones'}
                                variant = "contained"
                                text="SIGUIENTE"
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <StepperInscripcion activeStep={0}/>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default DatosDelegadoPage
