import React from 'react'
import Alert from '../../components/Alert'
import AppFrame from '../../components/AppFrame'
import ComboBox from '../../components/ComboBox'
import FormHeader from '../../components/FormHeader'
import Input from '../../components/Input'
import Button from '../../components/Button'
import DataTable from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import Pagination from '../../components/Pagination'
import Grid from '@material-ui/core/Grid'
import { RiAdminLine, RiRestartLine } from 'react-icons/ri'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import useUsuarioPage from '../../hooks/administracion/useUsuarioPage'
import Progress from '../../components/Progress/Progress'
import FileInput from '../../components/FileInput'
import MaterialDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import PdfViewer from '../../components/PdfViewer'
import {MaskField} from "react-mask-field";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    },
    button: {
        marginTop: '1em',
        marginBottom: '1em'
    },
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
}))

function CustomMaskField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="________-_" replacement={{ _: /\d/ }} {...otherProps} />;
}

function CustomMaskNumberField({ inputRef, ...otherProps }) {
    return <MaskField ref={inputRef} mask="____-____" replacement={{ _: /\d/ }} {...otherProps} />;
}

export const FormUsuario = ({data, actions, classes}) => {
    const { id, nombreUsuario,
        errorNombreUsuario,
        helperNombreUsuario,
        duiUsuario,
        objArchivoUsuario,
        errorDuiUsuario,
        helperDuiUsuario,
        telefonoUsuario,
        errorTelefonoUsuario,
        helperTelefonoUsuario,
        celularUsuario,
        errorCelularUsuario,
        helperCelularUsuario,
        correoElectronicoUsuario,
        errorCorreoElectronicoUsuario,
        helperCorreoElectronicoUsuario,
        roles,
        rol,
        contrasenia1Usuario,
        errorContrasenia1Usuario,
        helperContrasenia1Usuario,
        contrasenia2Usuario,
        errorContrasenia2Usuario,
        helperContrasenia2Usuario,
        openFileModal,
        pageNumber,
        fileNumPages,
        archivoUsuario,
        idSolicitud} = data

    const { onChangeNombreUsuario, onChangeDuiUsuario,
        onChangeTelefonoUsuario, onChangeCelularUsuario,
        onChangeCorreoElectronicoUsuario, onChangeContrasenia1Usuario,
        onChangeContrasenia2Usuario, handleOnChangeArchivoUsuario,
        setRol, handleFilePageChange,
        handleCloseFileModal,
        onDocumentLoadSuccess} = actions

    return <Grid container item xs = {12}>
        <Grid item xs = {12}>
            <Input type = "text" label="Nombre"
                placeholder="Ingrese el nombre"
                value={nombreUsuario}
                onChange={onChangeNombreUsuario}
                error = {errorNombreUsuario}
                helper = {helperNombreUsuario}
                required = {true}
                />
        </Grid>
        <Grid item xs = {12}>
            <Input type = "text" label="DUI"
                placeholder="Ingrese el DUI"
                value={duiUsuario}
                onChange={onChangeDuiUsuario}
                error = {errorDuiUsuario}
                helper = {helperDuiUsuario}
                required={true}
                   InputProps={{ inputComponent: CustomMaskField }}
                />
        </Grid>
        {
            handleOnChangeArchivoUsuario &&
            <Grid className={classes.input} item xs={12}>
                <FileInput label="Comprobante DUI (ambos lados)"
                    placeholder="Seleccione el archivo a subir"
                    onChange={handleOnChangeArchivoUsuario}
                    required={true}
                    files={objArchivoUsuario}
                />
                <MaterialDialog
                    open={openFileModal}
                    onClose={handleCloseFileModal}
                    maxWidth='lg'
                >
                    <DialogContent>
                        <PdfViewer file={archivoUsuario} onDocumentLoadSuccess={onDocumentLoadSuccess}
                            pageNumber={pageNumber} numPages={fileNumPages} scale={1} />
                        <Pagination page={Number(pageNumber)}
                            numPages={Number(fileNumPages)}
                            onChangePage={handleFilePageChange}
                        />
                    </DialogContent>
                </MaterialDialog>
            </Grid>
        }
        <Grid item xs = {12} sm={6}>
            <Input type = "text" label="Telefono"
                placeholder="Ingrese el telefono"
                value={telefonoUsuario}
                onChange={
                    (text)=>{onChangeTelefonoUsuario(text)}
                }
                error = {errorTelefonoUsuario}
                helper={helperTelefonoUsuario}
                required={true}
                   InputProps={{ inputComponent: CustomMaskNumberField }}
                />
        </Grid>
        <Grid item xs = {12} sm={6}>
            <Input type = "text" label="Telefono celular"
                placeholder="Ingrese el telefono celular"
                value={celularUsuario}
                onChange={
                    (text)=>{onChangeCelularUsuario(text)}
                }
                error = {errorCelularUsuario}
                helper = {helperCelularUsuario}
                required={true}
                   InputProps={{ inputComponent: CustomMaskNumberField }}
                />
        </Grid>
        <Grid item xs = {12}>
            <Input type = "text" label="Correo electronico"
                placeholder="Ingrese el correo electronico"
                value={correoElectronicoUsuario}
                onChange={(text)=>
                    {onChangeCorreoElectronicoUsuario(text)}}
                error = {errorCorreoElectronicoUsuario}
                helper = {helperCorreoElectronicoUsuario}
                required={true}
                />
        </Grid>
        {
            roles !== undefined && rol !== undefined ?
            <Grid className={classes.input} item xs = {12} sm={6}>
                <ComboBox
                    label="Rol"
                    list={roles}
                    value={rol}
                    onChange={(value)=>{setRol(value)}}
                    required={true}
                    />
            </Grid>
            :
            null
        }
        {
            //Verifica que existen los datos necesarios para renderizar las contraseñas
            contrasenia1Usuario !== undefined && contrasenia2Usuario !== undefined ?
            <Grid container>
                {
                    id ?
                    null
                    : idSolicitud ? null :
                    <Grid item xs = {12} sm={6}>
                        <Input type = "password" label="Contraseña"
                            placeholder="Ingrese la contraseña"
                            value={contrasenia1Usuario}
                            onChange={onChangeContrasenia1Usuario}
                            error = {errorContrasenia1Usuario}
                            helper = {helperContrasenia1Usuario}
                            required={true}
                            />
                    </Grid>
                }
                {
                    id ?
                    null
                    : idSolicitud ? null :
                    <Grid item xs = {12} sm={6}>
                        <Input type = "password" label="Contraseña de
                            nuevo"
                            placeholder="Ingrese la contraseña de nuevo"
                            value={contrasenia2Usuario}
                            onChange={onChangeContrasenia2Usuario}
                            error = {errorContrasenia2Usuario}
                            helper = {helperContrasenia2Usuario}
                            required={true}
                            />
                    </Grid>
                }
            </Grid>
            :
            null
        }
    </Grid>
}

const encabezado = ["Nombre", "DUI", "Telefono", "Celular",
    "Correo electronico"]

const UsuarioPage = props => {
    const classes = useStyles()
    const { data, actions } = useUsuarioPage()
    const { rows, nombreUsuario, id, openDelete, openEdit, usuarioToDelete, alertOpen,
        alertMessage, alertSeverity, page, numPages, loading } = data
    const {onDeleteUsuario, onCreateUsuario, onUpdateUsuario,
        editClick, enviarCorreoRecuperacion, cancel, editCancel, handleClickOpenDelete,
        handleClickOpenEdit, handleCloseDelete, handleCloseEdit, findRow,
        setUsuarioToDelete, setAlertOpen, handlePageChange} = actions

    return (
        <AppFrame loged = {false}>
            <Progress open = {loading > 0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={12} sm={11}>
                    <Paper className = {classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity}
                            message = {alertMessage}/>
                        <FormHeader title="Administracion"
                            subtitle="Gestion de usuarios">
                            <RiAdminLine/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {10}>
                                <FormUsuario data = {data} actions = {actions} classes = {classes}/>
                                <Grid container item
                                    className = {classes.button}
                                    direction="row"
                                    justifyContent="center" spacing = {2}>
                                    <Grid className={classes.input} item
                                        xs = {5} sm={3}>
                                        <Button
                                            func = {
                                                id?
                                                editCancel
                                                :
                                                cancel
                                            }
                                            variant = "outlined"
                                            text="CANCELAR"/>
                                    </Grid>
                                    <Grid className={classes.input} item
                                        xs = {5} sm={3}>
                                        <Button
                                            variant = "contained"
                                            text={id?"EDITAR":"AGREGAR"}
                                            func = {id?
                                                handleClickOpenEdit
                                                :
                                                onCreateUsuario}
                                            />
                                        <Dialog open={openEdit}
                                            handleClose = {handleCloseEdit}
                                            handleAccept = {onUpdateUsuario}
                                            title = {"Desea actualizar el registro?"}
                                            content = {`Los datos del usuario "${nombreUsuario}" se actualizaran.`}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable headers={encabezado}
                                        rows={rows}
                                        editFnc={(key)=>
                                            editClick(key)}
                                        deleteFnc={(key)=>
                                            handleClickOpenDelete(key)}
                                        customBtn={[{func : (id) => { enviarCorreoRecuperacion(id) }, icon : RiRestartLine}]}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Pagination page={Number(page)}
                                        numPages={Number(numPages)}
                                        onChangePage = {handlePageChange}
                                        />
                                </Grid>
                                <Dialog open={openDelete}
                                    handleClose = {handleCloseDelete}
                                    handleAccept = {onDeleteUsuario}
                                    title = {"Desea eliminar el registro?"}
                                    content = {
                                        findRow(usuarioToDelete, rows) ?
                                        `El usuario con nombre "${ findRow(usuarioToDelete, rows).cells[0] }" sera desactivado del sistema.`
                                        :
                                        ""
                                        }
                                    transitionFunc = {() =>
                                        setUsuarioToDelete(null)}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default UsuarioPage
