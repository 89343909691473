import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useDosimetroService from '../../services/administracion/useDosimetroService'
import { mensajeCamposNoValidos } from '../../utils/messages'
import { addRow, findRow, updateRow } from '../../utils/rows'
import { allNumbers, letterNumbersAndSpace } from '../../utils/validations'
import useIsLoged from '../utils/useIsLoged'

const useDosimetroPage = () => {
    const { createDosimetro, getDosimetroById, getDosimetrosByStatus, 
        updateDosimetro, deleteDosimetro, 
        getEstadosDosimetros } = useDosimetroService()
    const history = useHistory()
    useIsLoged()
    const {codigoEditar} = useParams()
    const tiposDosimetro = [
        {
            label: "Cuerpo",
            value: "1"
        },
        {
            label: "Anillo",
            value: "2"
        }
    ]

    const [estadosDosimetro, setEstadosDosimetro] = useState([])
    const [rows, setRows] = useState([])
    const [codigo, setCodigo] = useState('')
    const [errorCodigo, setErrorCodigo] = useState(false)
    const [helperCodigo, setHelperCodigo] = useState('')
    const [tipoDosimetro, setTipoDosimetro] = useState('')
    const [cantidadCristales, setCantidadCristales] = useState('1')
    const [errorCantidadCristales, setErrorCantidadCristales] = useState(false)
    const [helperCantidadCristales, setHelperCantidadCristales] = useState('')
    const [estadoDosimetro, setEstadoDosimetro] = useState('')
    
    //estado_dosimetro_ids para el alert
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //estado_dosimetro_ids para dialogos confirmar actualizacion, creacion y eliminacion
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [dosimetroToDelete, setDosimetroToDelete] = useState(null)

    //Estado para ver registros con estado = false (desactivados)
    const [verDesactivados, setVerDesactivados] = useState(false)

    //Extrayendo los parametros tipo ?parametro=valorParametro de la URL
    const queryParams = new URLSearchParams(window.location.search);
    //Estado que indica la pagina actua
    const [page, setPage] = useState(queryParams.get('page'))
    //Estado que indica el numero de paginas en total
    const [numPages, setNumPages] = useState(0)

    const [loading, setLoading] = useState(0)
    
    if(!page){
        setPage(1)
        history.push(`${window.location.pathname}?page=1`)
    }
    
    //Controlador cambio de pagina
    const handlePageChange = (event, value) => {
        setPage(value)
        history.push(`${window.location.pathname}?page=${value}`)
    }

    const handleDesactivadosChange = () => {
        setVerDesactivados(
            (verDesactivados) => !verDesactivados
        )
    }

    const handleOpenAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
        setAlertOpen(true)
    }

    const handleClickOpenEdit = () => {
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    }

    const handleClickOpenDelete = (id) => {
        setDosimetroToDelete(id)
        setOpenDelete(true)
    }    
    
    const handleCloseDelete = () => {
        setOpenDelete(false)
        
    }

    const startLoading = () => {
        setLoading((loading) => ++loading)
    }

    const stopLoading = () => {
        setLoading((loading) => --loading)
    }

    const onChangeCodigo = (text)=>
    {
        setCodigo(text)
        if(!letterNumbersAndSpace(text) && text !== ""){
            setErrorCodigo(true)
            setHelperCodigo("El Tipo Dosimetro debe consistir solo de letras y numeros.")
        }else{
            setErrorCodigo(false)
            setHelperCodigo("")
        }
    }

    const onChangeCantidadCristales = (text)=>
    {
        setCantidadCristales(text)
        if(!allNumbers(text) && text !== ""){
            setErrorCantidadCristales(true)
            setHelperCantidadCristales("La cantidad de cristales debe consistir solo de números")
        } else if (Number(text) <= 0 || Number(text) > 2) {
            setErrorCantidadCristales(true)
            setHelperCantidadCristales("La cantidad de cristales debe mayor a 0 y menor a 3.")
        }
        else{
            setErrorCantidadCristales(false)
            setHelperCantidadCristales("")
        }
    }

    const checkForErrors = () => {
        return errorCodigo || codigo === "" || errorCantidadCristales;
    }

    const onCreateDosimetro = async () => {
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const newDosimetro = {
            "codigo": codigo,
            "cantidad_cristales": cantidadCristales,
            "tipo_dosimetro": tipoDosimetro,
            "estado_dosimetro_id": estadoDosimetro
        }
        startLoading()
        const result = await createDosimetro(newDosimetro)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        //Preguntar
        else{
            const { codigo } = result
            getDosimetros()
            handleOpenAlert('success', `El dosimetro "${codigo}" se creo correctamente.`)
            console.log(estadoDosimetro)
        }
        stopLoading()
        cancel()
    }

    const onUpdateDosimetro = async () => {
        setOpenEdit(false)
        if(checkForErrors()){
            handleOpenAlert('warning', mensajeCamposNoValidos)
            return
        }
        const dosimetroActualizado = {
            "codigo": codigo,
            "tipo_dosimetro": tipoDosimetro,
            "cantidad_cristales": cantidadCristales,
            "estado_dosimetro_id": estadoDosimetro
        }
        startLoading()
        const result = await updateDosimetro(codigoEditar, dosimetroActualizado)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {codigo, tipo_dosimetro, cantidad_cristales, estado_dosimetro_id} = result
            const newCells = [codigo, tipo_dosimetro, cantidad_cristales, estado_dosimetro_id]
            console.log(estadoDosimetro)

            updateRow(codigo, newCells, setRows)
            getDosimetros()
            handleOpenAlert('success', `El dosimetro "${codigo}" se actualizo correctamente.`)
        }
        stopLoading()
        cancel()
    }

    const onDeleteDosimetro = async () => {
        setOpenDelete(false)
        startLoading()
        const result = await deleteDosimetro(dosimetroToDelete)
        if (result.error){
            handleOpenAlert('error', result.error)
        }
        else{
            const {codigo} = result
            getDosimetros()
            editCancel()
            handleOpenAlert('success', `El dosimetro "${codigo}" se desactivo correctamente.`)
        }
        stopLoading()
    }

    const getDosimetros = useCallback(async () => {
        const estado = verDesactivados ? 0 : 1
        startLoading()
        const dosimetro = await getDosimetrosByStatus(estado, page)
        if (dosimetro.error){
            handleOpenAlert('error', dosimetro.error)
        }
        else{
            setRows([])
            setNumPages(dosimetro.last_page)
            dosimetro.data.forEach(({codigo, cantidad_cristales, tipo_dosimetro, estado_dosimetro}) => {
                const key = codigo
                // console.log(key)
                const cell = [codigo, cantidad_cristales, Number(tipo_dosimetro) === 1 ? 'De cuerpo' : Number(tipo_dosimetro) === 2 ? 'De anillo' : 'Existe un error en el tipo de dosimetro.', estado_dosimetro.nombre]
                addRow(key, cell, setRows)
            })
            if(page > dosimetro.last_page){
                setPage(dosimetro.last_page)
                history.push(`${window.location.pathname}?page=${dosimetro.last_page}`)
            }
        }
        stopLoading()
    }, [getDosimetrosByStatus, history, page, verDesactivados])

    const getDosimetro = useCallback(async (id) => {
        startLoading()
        const dosimetro = await getDosimetroById(id)
        if (dosimetro.error){
            handleOpenAlert('error', dosimetro.error)
        }
        else{
            setCodigo(dosimetro.codigo)
            setTipoDosimetro(dosimetro.tipo_dosimetro)
            setCantidadCristales(dosimetro.cantidad_cristales)
            setEstadoDosimetro(dosimetro.estado_dosimetro_id)
        }
        stopLoading()
    }, [getDosimetroById])

    const editClick = (id) => {
        history.push(`/admin/dosimetro/${id}?page=${page}`)
    }

    const editCancel = () => {
        cancel()
        history.push(`/admin/dosimetro?page=${page}`)
    }

    const cancel = () => {
        setCodigo("")
        setTipoDosimetro("")
        setCantidadCristales("")
        setEstadoDosimetro("")
        
    }

    const data = {
        rows,
        tiposDosimetro,
        estadosDosimetro,
        codigo,
        errorCodigo,
        helperCodigo,
        tipoDosimetro,
        cantidadCristales,
        errorCantidadCristales,
        helperCantidadCristales,
        estadoDosimetro,
        codigoEditar,
        openEdit,
        openDelete,
        dosimetroToDelete,
        alertOpen,
        alertSeverity,
        alertMessage,
        page,
        numPages,
        verDesactivados,
        loading
    }

    const actions = {
        onDeleteDosimetro,
        onCreateDosimetro,
        onUpdateDosimetro,
        editClick,
        onChangeCodigo,
        onChangeCantidadCristales,
        setTipoDosimetro,
        setEstadoDosimetro,
        cancel,
        editCancel,
        handleClickOpenEdit,
        handleClickOpenDelete,
        handleCloseDelete,
        handleCloseEdit,
        findRow,
        setDosimetroToDelete,
        setAlertOpen,
        handlePageChange,
        handleDesactivadosChange
    }
    
    const handleGetEstadosDosimetros = useCallback(async () => {
        startLoading()
        const estados = await getEstadosDosimetros()
        if (estados.error){
            handleOpenAlert('error', estados.error)
        }
        else{
            setEstadosDosimetro([])
            estados.forEach(({id, nombre}) => {
                const label = nombre
                const value = id
                const estadoComboBox = {
                    label,
                    value: value.toString()
                }
                setEstadosDosimetro((estadosDosimetro) => [...estadosDosimetro, estadoComboBox])
            })
        }
        stopLoading()
    }, [getEstadosDosimetros])

    const loadData = useCallback(async () => {
        await getDosimetros()
        await handleGetEstadosDosimetros()
    }, [getDosimetros, handleGetEstadosDosimetros])

    //useEffect para cargar datos en la tabla
    useEffect(() => {
        loadData()
    }, [loadData])
    //useEffect para cargar datos al editar
    useEffect(() => {
        if(codigoEditar){
            cancel()
            getDosimetro(codigoEditar)
        }
    }, [codigoEditar, getDosimetro])
    return {data, actions}
}

export default useDosimetroPage