import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import useRolService from "../../services/administracion/useRolService";
import { addRow, findRow, updateRow } from "../../utils/rows";
import useIsLoged from "../utils/useIsLoged";
import {
  allLetterAndSpace,
  allNumbers,
  isDUI,
  isEmail,
  isPhone,
  letterNumbersAndSpace,
  letterNumbersSpaceForAddress,
  validPassword,
} from "../../utils/validations";
import { mensajeCamposNoValidos } from "../../utils/messages";
import Session from "react-session-api";
import { useCookies } from "react-cookie";
import { getNowInSeconds } from "../../utils/time";
import useLoginService from "../../services/sesion/useLoginService";
import usePasoSolicitudesRecibidasService from "../../services/solicitudes_recibidas/pasoService";

const usePasoSolicitudesRecibidasPage = () => {
  const {
    createPaso,
    getAllPasos,
    deletePaso,
    getPasoById,
    updatePaso,
  } = usePasoSolicitudesRecibidasService();

  const ruta = `/control_de_calidad/solicitudes_recibidas/pasos`;
  const history = useHistory();
  useIsLoged();
  const { id } = useParams();
  // Estado para mensajes de error
  const [errorMessage, setErrorMessage] = useState(null);
  // Estado para las filas de la tabla
  const [rows, setRows] = useState([]);

  // Estados para controlar los inputs

  // Estado de cuántos registros se están mostrando en la tabla
  const [registrosEnTabla, setRegistrosEnTabla] = useState(0);
  const [lastPagePagination, setLastPagePagination] = useState(0);

  // Estados para el alert
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  // Estados para diálogos de confirmación de actualización, creación y eliminación
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [pasoToDelete, setPasoToDelete] = useState(null);

  // Estado para progress
  const [loading, setLoading] = useState(0);

  // Cookies
  const [cookies, setCookie, removeCookie] = useCookies(["token", "rol", "id"]);

  // Extrayendo los parámetros tipo ?parametro=valorParametro de la URL
  const queryParams = new URLSearchParams(window.location.search);
  // Estado que indica la página actual
  const [page, setPage] = useState(queryParams.get("page"));
  // Estado que indica el número de páginas en total
  const [numPages, setNumPages] = useState(0);

  const [nombre, setNombre] = useState("");
  const [errorNombre, setErrorNombre] = useState(false);
  const [helperNombre, setHelperNombre] = useState("");

  if (!page) {
    setPage(1);
    history.push(`${window.location.pathname}?page=1`);
  }

  // Controlador cambio de página
  const handlePageChange = (event, value) => {
    setPage(value);
    history.push(`${window.location.pathname}?page=${value}`);
  };

  // Controladores alerta
  const handleOpenAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  // Controladores mensajes confirmación
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenDelete = (id) => {
    setPasoToDelete(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const startLoading = () => {
    setLoading((loading) => ++loading);
  };

  const stopLoading = () => {
    setLoading((loading) => --loading);
  };

  // Funciones para controlar cambios en inputs
  const onChangeNombre = (text) => {
    setNombre(text);
    if (!allLetterAndSpace(text)) {
      setErrorNombre(true);
      setHelperNombre("El nombre solo debe contener letras y espacios");
    } else {
      setErrorNombre(false);
      setHelperNombre("");
    }
  };

  const cancel = () => {
    setNombre("");
  };

  const editClick = (id) => {
    history.push(`${ruta}/${id}?page=${page}`);
  };

  const editCancel = () => {
    cancel();
    history.push(`${ruta}?page=${page}`);
  };

  const checkForErrors = () => {
    if (nombre && nombre !== "") {
      return false;
    } else {
      return true;
    }
  };

  const getPaso = useCallback(
    async (id) => {
      startLoading();
      const paso = await getPasoById(id);
      if (paso.error) {
        handleOpenAlert("error", paso.error);
      } else {
        const { nombre } = paso;

        setNombre(nombre);
      }
      stopLoading();
    },
    [getPasoById]
  );

  const handleGetPasos = useCallback(async () => {
    startLoading();
    const pasos = await getAllPasos();
    if (pasos.error) {
      setErrorMessage(pasos.error);
    } else {
      setRows([]);
      pasos.forEach(({ id, name }) => {
        const key = id;
        const cell = [name];
        addRow(key, cell, setRows);
      });
    }
    stopLoading();
  }, [getAllPasos, id]);

  const onCreatePaso = async () => {
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const nuevoPaso = {
      nombre,
      disponible: true,
    };

    startLoading();
    const result = await createPaso(nuevoPaso);
    if (result.error) {
      setErrorMessage(result.error);
      handleOpenAlert("error", result.error);
    }
    else {
      const { nombre } = result;
      cancel();
      history.push(`${ruta}?page=${page}`);
      handleOpenAlert(
        "success",
        `El paso "${nombre}" se creó correctamente.`
      );
      await handleGetPasos();
    }
    stopLoading();
  };

  const onDeletePaso = async () => {
    setOpenDelete(false);
    startLoading();
    const result = await deletePaso(pasoToDelete);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { nombre } = result;
      await handleGetPasos();
      cancel();
      handleOpenAlert(
        "success",
        `"${nombre}" se eliminó correctamente.`
      );
    }
    stopLoading();
  };

  const onUpdatePaso = async () => {
    setOpenEdit(false);
    if (checkForErrors()) {
      handleOpenAlert("warning", mensajeCamposNoValidos);
      return;
    }

    const pasoAActualizar = {
      nombre,
      disponible: true,
    };
    startLoading();
    const result = await updatePaso(id, pasoAActualizar);
    if (result.error) {
      handleOpenAlert("error", result.error);
    } else {
      const { nombre } = result;
      const newCells = [nombre];
      updateRow(id, newCells, setRows);
      await handleGetPasos();
      handleOpenAlert(
        "success",
        `"${nombre}" se actualizó correctamente.`
      );
    }
    editCancel();
    stopLoading();
  };

  useEffect(() => {
    const loadData = async () => {
      await handleGetPasos();
    };

    loadData();
  }, [handleGetPasos]);

  useEffect(() => {
    if (id) {
      cancel();
      getPaso(id);
    }
    // Solo se ejecuta cuando cambia el valor de id o getPaso
  }, [id, getPaso]);

  // Objeto con los datos del custom hook que se usarán en la página
  const data = {
    alertMessage,
    alertOpen,
    alertSeverity,
    errorMessage,
    errorNombre,
    helperNombre,
    id,
    loading,
    nombre,
    numPages,
    openDelete,
    openEdit,
    page,
    pasoToDelete,
    rows,
    setAlertOpen,
    setPasoToDelete,
  };

  // Objeto con las acciones que se usarán en la página
  const actions = {
    handlePageChange,
    handleOpenAlert,
    handleClickOpenEdit,
    handleCloseEdit,
    handleClickOpenDelete,
    handleCloseDelete,
    onChangeNombre,
    cancel,
    editCancel,
    editClick,
    onCreatePaso,
    findRow,
    onDeletePaso,
    onUpdatePaso,
  };

  return { errorMessage, data, actions };
};

export default usePasoSolicitudesRecibidasPage;
