import React from 'react'
import Grid from '@material-ui/core/Grid'
import {useState} from 'react'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel";
// import {DataGrid, esES} from "@material-ui/data-grid";
import ReportesLeidoPage from "./ReportesLeidoPage"
import ReportesSubirPage from "./ReportesSubirPage"

const ReportesPage = () => {

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    // const renderDetailsButton = (params) => {
    //     return (
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 size="small"
    //                 style={{ marginLeft: 16 }}
    //                 onClick={() => {
    //                     alert("Hola")
    //                 }}
    //             >
    //                 More Info
    //             </Button>
    //     )
    // }
    // const columnsFake = [
    //     { field: "id", headerName: "ID", flex: 1,sortable: false,},
    //     { field: "fechaLectura", headerName: "Nombre", sortable: false, flex: 1},
    //     {
    //         field: "click",
    //         headerName: "Acciones",
    //         sortable: false,
    //         renderCell: (params) => {
    //             return (
    //                 <Button
    //                     func={()=>{
    //                         console.log(params)}}
    //                     text={"TEST"}
    //                 />
    //             )
    //         },
    //
    //     },
    //     { field: "estado", headerName: "Estado", sortable: false, flex: 1},
    //     { field: "comentario", headerName: "Comentario", sortable: false, flex: 1},
    // ];
    //
    //
    //
    // const [rowsFake, setRowsFake] = useState([
    //     {
    //         id: 1,
    //         fechaLectura: "Snow.docx",
    //         firstName: "<a>hello</a>",
    //         age: 35,
    //         //click: <button>Hello</button>,   /*given a button to first row*/
    //     },
    //     { id: 2, fechaLectura: "Lannister.docx", firstName: "Cersei", age: 42 },
    //     { id: 3, fechaLectura: "Lannister.docx", firstName: "Jaime", age: 45 },
    //     { id: 4, fechaLectura: "Stark.docx", firstName: "Arya", age: 16 },
    //     { id: 5, fechaLectura: "Targaryen", firstName: "Daenerys", age: null },
    //     { id: 6, fechaLectura: "Melisandre", firstName: null, age: 150 },
    //     { id: 7, fechaLectura: "Clifford", firstName: "Ferrara", age: 44 },
    //     { id: 8, fechaLectura: "Frances", firstName: "Rossini", age: 36 },
    //     { id: 9, fechaLectura: "Roxie", firstName: "Harvey", age: 65 },
    // ]);


    return (
        <Grid container direction="row" justifyContent="center">
            <Grid item xs={12}>
                    <Tabs value={value} onChange={(event, newValue) => handleChange(event, newValue)} indicatorColor="primary"
                          textColor="primary" centered>
                        <Tab label="Subir Reporte"/>
                        <Tab label="Reporte leído"/>
                    </Tabs>
                    <Grid item xs={12}>

                        {/* Primer tab */}

                        <TabPanel value={value} index={0}>
                            {/*<DataGrid*/}
                            {/*    localeText={esES.props.MuiDataGrid.localeText}*/}
                            {/*    autoHeight*/}
                            {/*    rows={rowsFake}*/}
                            {/*    columns={columnsFake}*/}
                            {/*    pageSize={25}*/}
                            {/*    disableSelectionOnClick*/}
                            {/*    disableColumnMenu={true}*/}
                            {/*    //disableExtendRowFullWidth={true}*/}
                            {/*    disableColumnFilter={true}*/}
                            {/*    disableColumnSelector={true}*/}
                            {/*    disableDensitySelector={true}*/}
                            {/*    rowsPerPageOptions={[5, 10, 15, 25]}*/}
                            {/*    autoPageSize={true}*/}
                            {/*/>*/}
                            <ReportesSubirPage handleChange={handleChange}/>
                        </TabPanel>
                    </Grid>

                    <Grid item xs={12}>

                        {/* Segundo tab */}

                        <TabPanel value={value} index={1}>
                           <ReportesLeidoPage />
                        </TabPanel>
                    </Grid>
            </Grid>
        </Grid>
    )
}


export default ReportesPage