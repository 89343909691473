import AppFrame from "../../components/AppFrame";
import { Link as RouterLink } from "react-router-dom";
import Button from "../../components/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../components/DataTable";
import Alert from "../../components/Alert/Alert";
import FormHeader from "../../components/FormHeader";
import Typography from "@material-ui/core/Typography";
import Progress from "../../components/Progress/Progress";
import DatePickerRange from "../../components/DatePickerRange/DatePickerRange";
import Pagination from "../../components/Pagination";
import { RiGroupLine, RiRestartLine } from "react-icons/ri";
import useSolicitudesPage from "../../hooks/cotizacion/useSolicitudesPage";
import useSolicitudesRecibidasCCPage from "../../hooks/solicitudes_recibidas/useSolicitudesRecibidas";
import Dialog from "../../components/Dialog/Dialog";
import ReactSelect from "../../components/ReactSelect";
import Input from "../../components/Input/Input";
import useHistorialClientCCPage from "../../hooks/historialDeReportes/useclienteHistorial";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2em",
    paddingBottom: "2em",
    paddingLeft: "10em",
    paddingRight: "10em",
  },
  input: {
    marginTop: ".5em",
    marginBottom: ".5em",
  },
}));

const encabezado = ["Institucion", "Ultima fecha de actualizacion"];

const ClienteHistorialPage = () => {
  const classes = useStyles();
  const { data, actions } = useHistorialClientCCPage();
  const {
    rows,
    alertOpen,
    alertSeverity,
    alertMessage,
    loading,
    numPages,
    page,
    value,
    idRol,
    MdSearch,
    openDialog,
    selectedEstado,
    selectedPasoToFilter,
    selectedInstituciones,
    setErrorAnio,
    setHelperAnio,
    anio,
  } = data;
  const {
    setAlertOpen,
    handleSelectDate,
    handlePageChange,
    botonesDataTable,
    handleLimpiarFechas,
    handleClickOpenDialog,
    handleClickCloseDialog,
    onUpdateControlAvanceCC,
    loadEstados,
    handleChangeEstado,
    loadPasosToFilter,
    handleChangePasoToFilter,
    loadInstituciones,
    handleChangeInstituciones,
    onChangeAnio,
    buscarInstitucion,
    cancelarBusqueda,
    handleRedirect,
  } = actions;

  return (
    <AppFrame loged={true}>
      <Progress open={loading} setOpen={setAlertOpen} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <Paper className={classes.paper}>
            {/* Alerta */}
            <Alert
              open={alertOpen}
              setOpen={setAlertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
            {/* Titulo */}
            <FormHeader
              title="Reporte de Historial de clientes"
              subtitle="Datos Requeridos *"
            >
              <RiGroupLine />
            </FormHeader>

            {/* Input Buscar */}
            <Grid item xs={12}>
              <Input
                type="text"
                label="Institucion"
                placeholder="Ingrese la institucion a consultar"
                value={anio}
                onChange={onChangeAnio}
              />
            </Grid>
            <Grid container item xs={12} justify="center" spacing={2}>
            <Grid className={classes.input} item xs={6} sm={3}>
              <Button
                func={cancelarBusqueda}
                variant="outlined"
                text="CANCELAR"
              />
            </Grid>
            <Grid className={classes.input} item xs={6} sm={3}>
              <Button
                variant="contained"
                text={"BUSCAR"}
                func={buscarInstitucion}
                //text={"AGREGAR"}
                //func={onCreateTransporte}
              />
            </Grid>
            </Grid>
            {/* DataTable */}
            <Grid className={classes.input} item xs={12}>
              <DataTable
                headers={encabezado}
                rows={rows}
                customBtn={[
                  {
                    // func: handleClickOpenDialog,
                    func: handleRedirect,
                    icon: MdSearch,
                    tooltip: "Ver solicitud",
                  },
                ]}
              />
            </Grid>
            {/* Paginación */}
            <Pagination
              page={Number(page)}
              numPages={Number(numPages)}
              onChangePage={handlePageChange}
            />
          </Paper>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default ClienteHistorialPage;
