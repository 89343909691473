import React from 'react'
import AppFrame from '../../components/AppFrame'
import FormHeader from '../../components/FormHeader'
import Button from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import {FaRegUserCircle} from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Progress from '../../components/Progress/Progress'
import useEvaluacionEtapa1Page from '../../hooks/inscripcion/useEvaluacionEtapa1Page'
import Input from '../../components/Input'
import FormDivider from '../../components/FormDivider'
import MaterialDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import PdfViewer from '../../components/PdfViewer'
import Pagination from '../../components/Pagination'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import Alert from '../../components/Alert'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: '2em',
        paddingBottom: '2em'
    }, 
    input: {
        marginTop: '.5em',
        marginBottom: '.5em'
    },
    table: {
        marginTop: '2em'
    }
}))

const EvaluacionEtapa1Page = props => {
    const classes = useStyles()

    const { data, actions } = useEvaluacionEtapa1Page()

    const {
        loading,
        institucion,
        representanteLegal,
        //archivo usuario
        openUserFileModal,
        userFilePageNumber,
        userFileNumPages,
        userFile,
        //archivo institucion
        openInstitucionFileModal,
        institucionFilePageNumber,
        institucionFileNumPages,
        institucionFile,
        //archivo representante legal
        openRepresentanteLegalFileModal,
        representanteLegalFilePageNumber,
        representanteLegalFileNumPages,
        representanteLegalFile,
        estadoSolicitud,
        alertOpen,
        alertMessage,
        alertSeverity
    } = data

    const {
        //Archivo usuario
        handleUserFilePageChange,
        handleCloseUserFileModal,
        onUserDocumentLoadSuccess,
        handleOpenUserFile,
        //Archivo institucion
        handleInstitucionFilePageChange,
        handleCloseInstitucionFileModal,
        onInstitucionDocumentLoadSuccess,
        handleOpenInstitucionFile,
        //Archivo representante legal
        handleRepresentanteLegalFilePageChange,
        handleCloseRepresentanteLegalFileModal,
        onRepresentanteLegalDocumentLoadSuccess,
        handleOpenRepresentanteLegalFile,
        onChangeRadio,
        handleEvaluarSolicitud,
        setAlertOpen
    } = actions

    return (
        <AppFrame>
            <Progress open = {loading>0}/>
            <Grid container direction = "row" justifyContent = "center">
                <Grid item xs={10}>
                    <Paper className={classes.paper}>
                        <Alert open = {alertOpen} setOpen={setAlertOpen}
                            severity = {alertSeverity} 
                            message = {alertMessage}/>
                        <FormHeader title="Registro de inscripcion"
                            subtitle="Evaluacion de la solicitud de cliente etapa 1">
                            <FaRegUserCircle/>
                        </FormHeader>
                        <Grid container direction="row" justifyContent="center"
                            spacing = {2}>
                            <Grid container item xs = {10}>
                                <Grid item xs = {12}>
                                    <FormDivider text="Datos del usuario"/>
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Nombres del usuario" 
                                        value={institucion ? institucion.usuario.nombres : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="DUI" 
                                        value={institucion ? institucion.usuario.dui : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Correo electronico" 
                                        value={institucion ? institucion.usuario.email : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Telefono" 
                                        value={institucion ? institucion.usuario.telefono : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Telefono Celular" 
                                        value={institucion ? institucion.usuario.telefono_celular : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {4}>
                                    <Button variant="outlined" text="Ver archivo DUI" func={()=>handleOpenUserFile(institucion.usuario.id)} />
                                </Grid>
                                <MaterialDialog
                                    open={openUserFileModal}
                                    onClose={handleCloseUserFileModal}
                                    maxWidth = 'lg'
                                    >
                                    <DialogContent>
                                        <PdfViewer file = {userFile} onDocumentLoadSuccess = {onUserDocumentLoadSuccess}
                                            pageNumber = {userFilePageNumber} numPages = {userFileNumPages} scale={1}/>
                                        <Pagination page={Number(userFilePageNumber)}
                                            numPages={Number(userFileNumPages)}
                                            onChangePage = {handleUserFilePageChange}
                                            />
                                    </DialogContent>
                                </MaterialDialog>
                                <Grid item xs = {12}>
                                    <FormDivider text="Datos de la institucion"/>
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Nombre de la institucion" 
                                        value={institucion ? institucion.nombre : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Tipo de institucion" 
                                        value={institucion ? institucion.tipo_institucion.nombre : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                {
                                    institucion ? 
                                    institucion.tipo_institucion.id === 1 ?
                                    <Grid item xs = {6}>
                                        <Input type = "text" label="Ministerio" 
                                            value={institucion ? institucion.ministerio.nombre : ''}
                                            disabled = {true}
                                            />
                                    </Grid>
                                    :
                                    null
                                    :
                                    null
                                }
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Sector" 
                                        value={institucion ? institucion.sector.nombre : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="NIT" 
                                        value={institucion ? institucion.nit : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Telefono" 
                                        value={institucion ? institucion.telefono : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Telefono Celular" 
                                        value={institucion ? institucion.telefono_celular : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Departamento" 
                                        value={institucion ? institucion.direccion.municipio.departamento.nombre : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Municipio" 
                                        value={institucion ? institucion.direccion.municipio.nombre : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {12}>
                                    <Input type = "text" label="Especificacion de la direccion" 
                                        value={institucion ? institucion.direccion.detalle : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {4}>
                                    <Button variant="outlined" text="Ver archivo NIT" func={()=>handleOpenInstitucionFile(institucion.id)} />
                                </Grid>
                                <MaterialDialog
                                    open={openInstitucionFileModal}
                                    onClose={handleCloseInstitucionFileModal}
                                    maxWidth = 'lg'
                                    >
                                    <DialogContent>
                                        <PdfViewer file = {institucionFile} onDocumentLoadSuccess = {onInstitucionDocumentLoadSuccess}
                                            pageNumber = {institucionFilePageNumber} numPages = {institucionFileNumPages} scale={1}/>
                                        <Pagination page={Number(institucionFilePageNumber)}
                                            numPages={Number(institucionFileNumPages)}
                                            onChangePage = {handleInstitucionFilePageChange}
                                            />
                                    </DialogContent>
                                </MaterialDialog>
                                <Grid item xs = {12}>
                                    <FormDivider text="Datos del representante legal"/>
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Nombres" 
                                        value={representanteLegal ? representanteLegal.nombres : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Apellidos" 
                                        value={representanteLegal ? representanteLegal.apellidos : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="DUI" 
                                        value={representanteLegal ? representanteLegal.dui : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Cargo" 
                                        value={representanteLegal ? representanteLegal.cargo : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Telefono" 
                                        value={representanteLegal ? representanteLegal.telefono : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid item xs = {6}>
                                    <Input type = "text" label="Telefono Celular" 
                                        value={representanteLegal ? representanteLegal.telefono_celular : ''}
                                        disabled = {true}
                                        />
                                </Grid>
                                <Grid className={classes.input} item xs = {4}>
                                    <Button variant="outlined" text="Ver archivo DUI" func={()=>handleOpenRepresentanteLegalFile(institucion.id)} />
                                </Grid>
                                <MaterialDialog
                                    open={openRepresentanteLegalFileModal}
                                    onClose={handleCloseRepresentanteLegalFileModal}
                                    maxWidth = 'lg'
                                    >
                                    <DialogContent>
                                        <PdfViewer file = {representanteLegalFile} onDocumentLoadSuccess = {onRepresentanteLegalDocumentLoadSuccess}
                                            pageNumber = {representanteLegalFilePageNumber} numPages = {representanteLegalFileNumPages} scale={1}/>
                                        <Pagination page={Number(representanteLegalFilePageNumber)}
                                            numPages={Number(representanteLegalFileNumPages)}
                                            onChangePage = {handleRepresentanteLegalFilePageChange}
                                            />
                                    </DialogContent>
                                </MaterialDialog>
                            </Grid>
                            <Grid container item xs = {10}>
                                <Grid item xs = {12}>
                                        <Typography className={classes.typography} align="left">
                                            Respecto a los datos ingresados por el usuario que esta solicitando el acceso al sistema para ser parte de cliente del CIAN
                                            ¿Se aprobara la solicitud del cliente?
                                        </Typography>
                                </Grid>
                                <Grid  container direction="row" justifyContent="flex-start" item xs = {12}>
                                    <Grid item xs={12} >
                                        <RadioGroup value = {estadoSolicitud} onChange={onChangeRadio} >
                                            {
                                                //Los values son los id de los estados en la bd
                                            }
                                            <FormControlLabel value={4} control={<Radio color="primary" />} label= "Aprobada" />
                                            <FormControlLabel value={5} control={<Radio color="primary" />} label= "Rechazada" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justifyContent="center"  item xs = {12}>
                                    <Grid  item xs = {3} sm={3} md= {3}>
                                        <Button
                                            variant = "contained"
                                            text={"Guardar"}     
                                            func = {handleEvaluarSolicitud}      
                                            />
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </AppFrame>
    )
}

export default EvaluacionEtapa1Page

