import React from 'react'
//import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CookieConsent from 'react-cookie-consent'

const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        color: 'black',
        textAlign: 'center',
        paddingBottom: '.8em',
        zIndex: theme.zIndex.drawer + 1,
        height: '4em',
        paddingTop: '1.2em',
        paddingLeft: '2.5em',
        paddingRight: '2.5em'
    },
    leftText: {
        float: 'left',
    },
    rightText: {
        float: 'right',
        color: 'gray'
    }
}));

const Footer = props => {
    const classes = useStyles();
    return (
        <div className={classes.footer}>
            <span className={classes.leftText}>
                <a href='/acerca-nosotros'>Acerca de nosotros</a>
            </span>
            <span className={classes.rightText}>
                2024 | Centro de Investigaciones y Aplicaciones Nucleares
            </span>
            <CookieConsent
                location="bottom"
                buttonText="Entendido"
                cookieName="consetCookie"
                style={{
                    marginBottom: '4em',
                }}
                >
                Este sitio utiliza cookies para su funcionamiento, al seguir utilizandolo o dar clic en el boton 'Entendido' acepta el uso de cookies.
            </CookieConsent>
        </div>
    )
}

/*Footer.propTypes = {

}*/

export default Footer
