import axios from 'axios'
import url from "../utils/url"
import headers from '../utils/headers'
import useErrorHandler from '../utils/useErrorHandler'
import { useCallback } from 'react'

const contratoUrl = `${url}contratos`

const useContratoService = () => {
    const { errorHandler } = useErrorHandler()

    const createContrato = useCallback(
        async (contrato, handleOnUploadProgress) => {
            try {
                const response = await axios.post(contratoUrl, contrato, 
                    {
                        headers: {'Content-Type': 'multipart/form-data', ...headers()},
                        onUploadProgress: (progressEvent) => handleOnUploadProgress(progressEvent.loaded, progressEvent.total)}
                    )
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    ) 
    
    const downloadContratoById = useCallback(
        async (contrato, handleOnDownloadProgress) => {
            const requestUrl = `${contratoUrl}/download/${contrato}`
            try {
                const response = await axios.get(requestUrl, 
                    {
                        headers: {'Content-Disposition': 'attachment' ,...headers()},
                        responseType: 'blob',
                        onDownloadProgress: (progressEvent) => handleOnDownloadProgress(progressEvent.loaded, progressEvent.total)
                    }
                )
                return response
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const getContratos = useCallback(
        async (page) => {
            const requestUrl = `${contratoUrl}?page=${page}`
            try {
                const response = await axios.get(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )
    
    const deleteContrato = useCallback(
        async (contrato) => {
            const requestUrl = `${contratoUrl}/${contrato}`
            try {
                const response = await axios.delete(requestUrl, {headers: headers()})
                const { data } = response
                return data
            } catch (error) {
                return errorHandler(error)
            }
        },
        [errorHandler],
    )

    return { createContrato, downloadContratoById, getContratos, 
        deleteContrato }
}

export default useContratoService