import Session from 'react-session-api'

const useCheckPermiso = (permiso) => {
    const rol = Session.get('rol')
    let existe = false
    //Verifica si en los permisos del usuario esta el permiso recibido
    rol && rol.permissions_role.forEach((permisoRol)=>{
        if(permisoRol.permission_id === permiso){
            //Si existe retorna verdadero
            existe = true
        }
    })
    //Si no retorna falso
    return existe
}

export default useCheckPermiso